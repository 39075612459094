export const documentColors = [
    {
        id: 0,
        backgroundColor: '#4A5057',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 1,
        backgroundColor: '#53B67C',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 2,
        backgroundColor: '#737E8B',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 3,
        backgroundColor: '#B958D7',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 4,
        backgroundColor: '#E03C23',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 5,
        backgroundColor: '#CF423B',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 6,
        backgroundColor: '#C6436C',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 7,
        backgroundColor: '#A14CC3',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 8,
        backgroundColor: '#604AD1',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 9,
        backgroundColor: '#3F60D3',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 10,
        backgroundColor: '#4496AA',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 11,
        backgroundColor: '#4BA37B',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 12,
        backgroundColor: '#6979DB',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 13,
        backgroundColor: '#5EAE59',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 14,
        backgroundColor: '#84B53C',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 15,
        backgroundColor: '#EAA239',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 16,
        backgroundColor: '#E76F2E',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    }
];

export const tagColors = [
    {
        id: 0,
        backgroundColor: '#94A3B8',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 1,
        backgroundColor: '#64748B',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 2,
        backgroundColor: '#306EA9',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 3,
        backgroundColor: '#1074D5',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 4,
        backgroundColor: '#23787B',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 5,
        backgroundColor: '#15C188',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 6,
        backgroundColor: '#2C2E9B',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 7,
        backgroundColor: '#672C9B',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 8,
        backgroundColor: '#7C19AE',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 9,
        backgroundColor: '#9B2C85',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 10,
        backgroundColor: '#BB1B1B',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 11,
        backgroundColor: '#B16625',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 12,
        backgroundColor: '#BB991B',
        textColor: '#FFFFFF',
        stroke: 'transparent'
    },
    {
        id: 13,
        backgroundColor: '#FFFFFF',
        textColor: '#334155',
        stroke: '#E0E5EE'
    },
    {
        id: 14,
        backgroundColor: '#E0E5EE',
        textColor: '#334155',
        stroke: 'transparent'
    },
    {
        id: 15,
        backgroundColor: '#E1ECF7',
        textColor: '#245481',
        stroke: 'transparent'
    },
    {
        id: 16,
        backgroundColor: '#B8D9FA',
        textColor: '#0D60AF',
        stroke: 'transparent'
    },
    {
        id: 17,
        backgroundColor: '#ACF6DE',
        textColor: '#119C6E',
        stroke: 'transparent'
    },
    {
        id: 18,
        backgroundColor: '#ACADE7',
        textColor: '#23247B',
        stroke: 'transparent'
    },
    {
        id: 19,
        backgroundColor: '#CBACE7',
        textColor: '#52237B',
        stroke: 'transparent'
    },
    {
        id: 20,
        backgroundColor: '#D7A1F2',
        textColor: '#63148A',
        stroke: 'transparent'
    },
    {
        id: 21,
        backgroundColor: '#E7ACDB',
        textColor: '#7B236A',
        stroke: 'transparent'
    },
    {
        id: 22,
        backgroundColor: '#F4AFAF',
        textColor: '#981616',
        stroke: 'transparent'
    },
    {
        id: 23,
        backgroundColor: '#EFCFB3',
        textColor: '#8F531E',
        stroke: 'transparent'
    },
    {
        id: 24,
        backgroundColor: '#F4E5AF',
        textColor: '#987C16',
        stroke: 'transparent'
    }
];
