import { getCurrentDateString } from '@/util/dateHelpers';
import { createHash } from '@/util/createHash';

export const defaultProduct = {
    lineId: 0,
    quantity: 1,
    description: '',
    taxes: [],
    discounts: [],
    price: 0
};

// NOTE: Recurring Invoice data is handled within the state slice.

export const defaultDocument = {
    // The form instance hash is used to identify the form instance for newly created forms.
    // It helps with cleaning out old data.
    formInstanceHash: createHash(),
    products: [defaultProduct],
    documentState: 'draft',
    selectedInvoiceProduct: defaultProduct,
    issuedDate: getCurrentDateString(),
    dueDate: getCurrentDateString(),
    terms: { name: 'None', value: 0 },
    customLabel: '',
    details: '',
    totals: {
        total: 0,
        subtotal: 0,
        discount: 0,
        tax: 0,
        shipping: 0
    },
    errors: {},
    reminders: {
        upcomingDue30Days: false,
        upcomingDue14Days: false,
        upcomingDue3Days: false,
        pastDue7Days: false,
        pastDue30Days: false,
        upcomingDue1Days: false,
        pastDue90Days: false,
        pastDue60Days: false,
        pastDue14Days: false,
        pastDue1Days: false,
        upcomingDue7Days: false,
        pastDue3Days: false,
        pastDue180Days: false
    },
    tags: []
};
