/* eslint import/no-cycle: "off"  */
import store from '@/state/store';

import { globalPageCount } from '@/config';

import {
    fetchListingItems,
    noListingItems,
    overwriteListingItems
} from '@/state/actions';

import { filterArgFlattener, getActivityConstant } from '@/util';

import axiosAuthenticated from '../authenticatedRequestor';

export const getActivities = async (args) => {
    let parentType = args?.parentType;
    // Check if args.parentType is an object. If it is, we need to convert it to an array.
    // This is because of the difference between how URLSearchParams handles arrays, and how
    // the API requires them.
    // For more info, look at filterArgFlattener, and how the filter drawers handle arrays.
    if (typeof args?.parentType === 'object' && args?.parentType !== null) {
        parentType = Object.keys(args.parentType).join();
    } else if (Array.isArray(args?.parentType)) {
        parentType = args.parentType.join();
    }

    const thisParentType = args?.allActivities || parentType;

    const activityString = getActivityConstant(thisParentType);

    if (store.getState().user.temporaryUser) {
        store.dispatch(noListingItems({ type: activityString }));
        return;
    }

    store.dispatch(fetchListingItems({ type: activityString }));

    const { businessId } = store.getState().user.activeBusiness;

    const allArgs = { pageSize: globalPageCount, ...filterArgFlattener(args) };

    const response = await axiosAuthenticated.get(
        `/businesses/${businessId}/activities`,
        { params: allArgs }
    );

    const { items, itemsMatchingRequest, pageSize, totalItems } = response.data;

    const totalPages = Math.ceil(itemsMatchingRequest / pageSize);

    store.dispatch(
        overwriteListingItems({
            type: activityString,
            items,
            totalPages,
            totalItems
        })
    );
};
