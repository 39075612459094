import React from 'react';
import PropTypes from 'prop-types';
import { CurrencyValueInput } from '@/components/rhf-mui';
import Grid from '@mui/material/Unstable_Grid2';
import { useCurrency } from '@/hooks';
import Divider from '../Divider';
import ItemLabel from '../ItemLabel';
import makeStyles from './styles';

const Price = ({ heading, id, maxTitle, minTitle }) => {
    const classes = makeStyles();
    const currency = useCurrency();

    return (
        <Grid item container direction="column">
            <ItemLabel>{heading}</ItemLabel>
            <Grid item container spacing={4} className={classes.wrap}>
                <Grid item xs={6}>
                    <CurrencyValueInput
                        currency={currency}
                        label={minTitle}
                        name={`${id}[min]`}
                        id={`${id}[min]`}
                    />
                </Grid>
                <Divider />
                <Grid item xs={6}>
                    <CurrencyValueInput
                        currency={currency}
                        label={maxTitle}
                        name={`${id}[max]`}
                        id={`${id}[max]`}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

Price.propTypes = {
    heading: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    minTitle: PropTypes.string.isRequired,
    maxTitle: PropTypes.string.isRequired
};

export default Price;
