import React from 'react';
import PropTypes from 'prop-types';
import { Button, Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import { useCheckAuthState } from '@/hooks';
import { DeleteIcon } from '@/resources/icons';

import useStyles from '../../styles';

const ReceiptDelete = ({ onClick }) => {
    const classes = useStyles();
    const { loadingUser } = useCheckAuthState();
    const documentState = useSelector(
        (state) => state?.makeInvoice?.documentState
    );
    const documentId = useSelector((state) => state?.makeInvoice?.documentId);

    const isDraft = documentState === 'draft' && Boolean(documentId);

    if (isDraft) {
        return (
            <div className={classes.section}>
                {!loadingUser ? (
                    <Button
                        fullWidth
                        className={`${classes.button} ${classes.negativeButton}`}
                        variant="outlined"
                        endIcon={
                            <DeleteIcon className={classes.buttonDeleteSvg} />
                        }
                        size="large"
                        onClick={onClick}
                    >
                        Delete
                    </Button>
                ) : (
                    <Skeleton variant="rectangular" className={classes.sizer} />
                )}
            </div>
        );
    }

    return null;
};

ReceiptDelete.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default ReceiptDelete;
