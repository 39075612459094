import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
    remindersDrawerContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: 'calc(100% - 60px)'
    },
    remindersDrawerBody: {
        overflowX: 'hidden',
        height: '100%'
    },
    reminderSubTitle: {
        marginTop: 40,
        marginBottom: 10
    },
    checkboxOptions: {
        '& p': {
            fontSize: 18
        },
        '&.Mui-disabled p': {
            color: '#ccc'
        }
    },
    alert: {
        fontSize: 14,
        color: '#B45309',
        width: '100%',
        backgroundColor: '#FEF3C7',
        padding: '4px 5px',
        lineHeight: '16px',
        marginTop: 10
    }
}));
