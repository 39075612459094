import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
    buttonRoot: {
        backgroundColor: '#C20101',
        '&:hover': {
            backgroundColor: '#8F0101'
        }
    },
    buttonText: {
        color: '#fff',
        fontSize: 16
    }
}));
