import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    notRegisteredScreenContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2,
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        pointerEvents: 'auto',
        width: '100%',
        height: '100%'
    },
    gridContainer: {
        height: '65%',
        padding: theme.typography.pxToRem(10),
        [theme.breakpoints.up('sm')]: {
            height: '90%'
        }
    },
    contentContainer: {
        maxWidth: '100%'
    },
    isPaymentMethods: {
        display: 'contents'
    },
    loginButton: {
        paddingLeft: theme.typography.pxToRem(16),
        paddingRight: theme.typography.pxToRem(16),
        marginBottom: theme.typography.pxToRem(24),
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.typography.pxToRem(48),
            paddingRight: theme.typography.pxToRem(48)
        }
    },
    notRegisteredText: {
        fontWeight: 600
    },
    textLink: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
        padding: 0,
        paddingLeft: 8,
        margin: '0 auto',
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            textDecoration: 'underline'
        }
    }
}));
