import React from 'react';
import { Grid, SvgIcon } from '@mui/material';
import {
    TableActionsWrap,
    TagAmountUsed,
    PureColorSwatch
} from '@/components/common';
import { openDrawer } from '@/state/actions';
import store from '@/state/store';

// Process array of objects into a stripped down version
// that only has required data so is faster to process.
export const dataProcessor = (data) =>
    data.map(({ backgroundColor, tagId, name, usageByCategory }) => ({
        id: tagId,
        tagName: name,
        backgroundColor,
        usageByCategory: {
            invoice: usageByCategory?.invoice || 0,
            estimate: usageByCategory?.estimate || 0,
            receipt: usageByCategory?.receipt || 0,
            client: usageByCategory?.client || 0,
            product: usageByCategory?.product || 0,
            service: usageByCategory?.service || 0,
            tax: usageByCategory?.tax || 0,
            discount: usageByCategory?.discount || 0,
            recurringInvoice: usageByCategory?.recurringInvoice || 0
        }
    }));

export const generateComponents = ({ array, classes }) => {
    const { activeBusiness } = store.getState().user;

    const handleClick = (id) => {
        const tag = activeBusiness?.tags?.find(
            (element) => element.tagId === id
        );

        if (tag) {
            store.dispatch(
                openDrawer({
                    id: 'createTags',
                    tag,
                    edit: true
                })
            );
        }
    };

    return array.map(({ id, tagName, usageByCategory, backgroundColor }) => ({
        id,
        tagName: (
            <span className={classes.swatchSpan}>
                <SvgIcon className={classes.swatch}>
                    <PureColorSwatch
                        backgroundColor={backgroundColor}
                        color="#E0E5EE"
                    />
                </SvgIcon>
                {tagName}
            </span>
        ),
        tagAmount: (
            <Grid container className={classes.tagAmountUsedContainer}>
                {Object.entries(usageByCategory).map(([type, amountUsed]) => (
                    <TagAmountUsed
                        amountUsed={amountUsed}
                        tagId={id}
                        type={type}
                        key={`${id}-${type}`}
                    />
                ))}
                <button
                    className={classes.openDrawerButton}
                    type="button"
                    onClick={() => handleClick(id)}
                >
                    <span className={classes.openDrawerSpan}>Open Drawer</span>
                </button>
            </Grid>
        ),
        actions: tagName && (
            <TableActionsWrap
                id={id}
                open="editTag"
                rest={['deleteTag']}
                toPass={{ tag: tagName }}
            />
        ),
        primaryClick: () => handleClick(id)
    }));
};
