import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { trimFloat } from '@/util';
import { useDocumentFormData } from '@/hooks';
import { updateDocumentTotals } from '@/state/actions';
import { REFUND_TYPE } from '@/constants';
import TotalsDisplay from '@/components/common/TotalsDisplay';

const Totals = ({ currency, documentType, locale }) => {
    const dispatch = useDispatch();

    const {
        discount,
        products,
        shippingFee,
        subTotal,
        taxes,
        total,
        transactions
    } = useDocumentFormData();

    const amountPaid = transactions.reduce(
        (accum, currentTransaction) =>
            currentTransaction.type === REFUND_TYPE
                ? accum - currentTransaction.amountAvailable
                : accum + currentTransaction.amountAvailable,
        0
    );

    const balanceDue = total - amountPaid;

    useEffect(() => {
        dispatch(
            updateDocumentTotals({
                total: trimFloat(total),
                subTotal,
                discount: trimFloat(discount),
                tax: trimFloat(taxes),
                shipping: shippingFee
            })
        );
    }, [dispatch, total, subTotal, discount, taxes, shippingFee]);

    return (
        <TotalsDisplay
            balanceDue={balanceDue}
            currency={currency}
            documentType={documentType}
            locale={locale}
            products={products}
            shippingFee={shippingFee}
            subTotal={subTotal}
        />
    );
};

Totals.propTypes = {
    currency: PropTypes.string,
    documentType: PropTypes.string,
    locale: PropTypes.string
};

Totals.defaultProps = {
    currency: 'USD',
    documentType: 'invoice',
    locale: 'en-US'
};

export default Totals;
