import loadable from '@loadable/component';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import {
    Grid,
    Dialog,
    Typography,
    TextField,
    MenuItem,
    Button,
    Divider
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import GenericDatePicker from '@/components/common/GenericDatePicker';
import { NotesField } from '@/components/common/NotesField';
import CloseButton from '@/components/common/CloseButton';
import ButtonLoading from '@/components/common/ButtonLoading';
import PaymentIntentModal from '@/components/common/PaymentIntentModal';
import { PaymentsActive } from '@/resources/icons';
import {
    currencySymbolAdornment,
    delayAwait,
    forcePositiveNumberInput,
    formatCurrency,
    toIntegerCurrency,
    toWholeCurrency
} from '@/util';
import { resetDocument, setDocumentState } from '@/state/actions';
import {
    useCheckAuthState,
    useCurrency,
    useLocale,
    usePaymentConnections
} from '@/hooks';
import {
    sendTransaction,
    getDocumentById,
    createManualStripePayment
} from '@/modules/dataWrangler/dataWrangler';
import { paymentMethods, thirdPartyConnectionLimits } from '@/config';
import {
    PAYMENT_INTENT,
    PAYMENT_INPUT,
    PAYMENT_ERROR,
    PAYMENT_SUCCESS
} from '@/constants';
import useStyles from './styles';

const SquarePaymentModal = loadable(() =>
    import('@/components/common/SquarePaymentModal')
);
const SignUpLoginModal = loadable(() => import(`@/components/Login`));
const PaymentStepModal = loadable(() =>
    import('@/components/common/PaymentStepModal')
);

/*
    This is the manual PaymentModal as seen on manage invoice view.
    Link: /invoice/jwt

    This is not used in PayInvoice @ /link/jwt
*/
const PaymentModal = ({
    open,
    onClose: handleClose,
    currentDocument,
    triggerAnimation,
    isDesktop,
    onPreSuccess,
    onPostSuccess,
    preselectPaymentMethod
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const locale = useLocale();
    const navigate = useNavigate();
    const { code, symbol, position } = useCurrency({
        isInvoice: true
    });
    const [selectOpen, setSelectOpen] = useState(false);

    const { registeredUser } = useCheckAuthState();

    const { connections } = usePaymentConnections();

    const locked =
        useSelector((state) => state?.makeInvoice?.viewMode) === 'edit';

    const availablePaymentMethods = connections.filter(
        (connection) =>
            connection.enabled &&
            connection.applicationType === 'paymentProcessor'
    );

    const creditCardPaymentMethods = [];

    const recordedPaymentMethods =
        // Remove first two paymentMethods (Stripe and Square) to conditionally replace below
        // and sort the rest to show merchant methods first
        paymentMethods.slice(2).sort((a, b) => {
            if (a.isMerchant && b.isMerchant) {
                return 0;
            }

            if (a.isMerchant) {
                return -1;
            }

            return 1;
        });

    // Unshift to show this as the first method.
    recordedPaymentMethods.unshift({
        slug: 'genericCreditCard',
        name: 'Credit Card',
        icon: <PaymentsActive />,
        id: 13
    });

    const getPaymentMethodByName = (slug) => {
        if (slug) {
            return availablePaymentMethods.find((method) =>
                slug === 'zelle'
                    ? method.application?.includes(slug)
                    : method.application === slug
            );
        }
        return false;
    };

    const stripe = getPaymentMethodByName('stripe');
    const square = getPaymentMethodByName('square');

    const checkConnectionCapabilities = (connection) => {
        if (connection?.application !== 'stripe') {
            return true;
        }

        return connection?.capabilities?.card_payments === 'active';
    };

    const stripeCreditCardsActive =
        stripe && checkConnectionCapabilities(stripe);

    // If Square and/or Stripe are connected, show each as an individual option
    // with their own label. Unshift is used to make sure these options are
    // always shown at the top of the list.
    if (square) {
        creditCardPaymentMethods.unshift({
            slug: 'square',
            name: 'Credit Card (Square)',
            icon: <PaymentsActive />,
            isMerchant: true,
            canBeConnected: true,
            id: 11
        });
    }

    if (stripeCreditCardsActive) {
        creditCardPaymentMethods.unshift({
            slug: 'stripe',
            name: 'Credit Card (Stripe)',
            icon: <PaymentsActive />,
            isMerchant: true,
            canBeConnected: true,
            id: 12
        });
    }

    let remainingBalance = 0;

    if (locked) {
        remainingBalance = currentDocument?.balanceDue;
    } else if (
        currentDocument?.totals?.total ||
        currentDocument?.totals?.total === 0
    ) {
        remainingBalance = currentDocument?.totals?.total;
    } else {
        remainingBalance = currentDocument?.total;
    }

    // If the currentDocument does not have a documentType,
    // it is in a draft state and will be a receipt.
    // Invoices will always have a documentType
    const documentType = currentDocument?.documentType || 'receipt';

    const [loginView, setLoginView] = useState('');

    // Find the payment method that matches the preselectedPaymentMethod
    // Find preselectPaymentMethod in availablePaymentMethods
    const preselectedPaymentMethod = availablePaymentMethods.find(
        (availablePaymentMethod) =>
            availablePaymentMethod.application === preselectPaymentMethod &&
            checkConnectionCapabilities(availablePaymentMethod)
    );

    // Find default provider.
    const defaultProvider =
        paymentMethods.find((method) =>
            availablePaymentMethods.find(
                (availablePaymentMethod) =>
                    method.slug === availablePaymentMethod.application &&
                    availablePaymentMethod.enabled &&
                    checkConnectionCapabilities(availablePaymentMethod)
            )
        ) || '';

    // Set the initial payment method to be the preselectedPaymentMethod if it exists
    // otherwise set it to the defaultProvider
    const [paymentMethod, setPaymentMethod] = useState(
        preselectedPaymentMethod || defaultProvider
    );

    const amountTooLow =
        (defaultProvider.slug === 'stripe' ||
            defaultProvider.slug === 'square') &&
        remainingBalance <
            thirdPartyConnectionLimits[`creditCard-${defaultProvider.slug}`].min
            ? `Must be larger than ${formatCurrency({
                  amount: toWholeCurrency(
                      thirdPartyConnectionLimits[
                          `creditCard-${defaultProvider.slug}`
                      ].min
                  ),
                  locale,
                  currency: code
              })}`
            : false;
    const amountTooHigh =
        (defaultProvider.slug === 'stripe' ||
            defaultProvider.slug === 'square') &&
        remainingBalance >
            thirdPartyConnectionLimits[`creditCard-${defaultProvider.slug}`].max
            ? `Cannot exceed ${formatCurrency({
                  amount: toWholeCurrency(
                      thirdPartyConnectionLimits[
                          `creditCard-${defaultProvider.slug}`
                      ].max
                  ),
                  locale,
                  currency: code
              })}`
            : false;

    // Set the initial value to be shown in the PaymentAmount to be
    // the total amount left to be paid.
    // This can then be overwritten by the user.
    const [paymentAmount, setPaymentAmount] = useState(
        toWholeCurrency(remainingBalance).toFixed(2)
    );
    const [paymentDate, setPaymentDate] = useState(new Date().toISOString());
    const [paymentNotes, setPaymentNotes] = useState('');
    const [paymentStage, setPaymentStage] = useState(null);
    const [paymentErrorMessage, setPaymentErrorMessage] = useState('');
    const [stripeIntentSecret, setStripeIntentSecret] = useState('');
    const [stripePromise, setStripePromise] = useState(null);
    const [amountError, setAmountError] = useState(
        amountTooHigh || amountTooLow || ''
    );
    const [dateError, setDateError] = useState(false);
    const [documentId, setDocumentId] = useState(currentDocument?.documentId);
    const [addPaymentButtonLoading, setAddPaymentButtonLoading] =
        useState(false);

    const checkIfIsStripeOrSquare = (method) =>
        method.slug === 'stripe' || method.slug === 'square';

    const verifyMinMax = (method) => {
        if (!checkIfIsStripeOrSquare(method)) {
            return true;
        }

        const amountToPay = toIntegerCurrency(paymentAmount);

        return (
            amountToPay >=
                thirdPartyConnectionLimits[`creditCard-${method?.slug}`]?.min &&
            amountToPay <=
                thirdPartyConnectionLimits[`creditCard-${method?.slug}`]?.max
        );
    };

    const handleAmountErrorChecks = (amount, method = paymentMethod) => {
        const merchantMax = toWholeCurrency(
            thirdPartyConnectionLimits[`creditCard-${method.slug}`]?.max
        );
        const merchantMin = toWholeCurrency(
            thirdPartyConnectionLimits[`creditCard-${method.slug}`]?.min
        );
        const isStripeOrSquare = checkIfIsStripeOrSquare(method);

        if (toIntegerCurrency(amount) > remainingBalance) {
            setAmountError('Cannot exceed amount due');
        }
        if (isStripeOrSquare && amount < merchantMin) {
            setAmountError(
                `Must be larger than ${formatCurrency({
                    amount: merchantMin,
                    locale,
                    currency: code
                })}`
            );
        }
        if (isStripeOrSquare && amount > merchantMax) {
            setAmountError(
                `Cannot exceed ${formatCurrency({
                    amount: merchantMax,
                    locale,
                    currency: code
                })}`
            );
        }
        if (
            documentType === 'receipt' &&
            toIntegerCurrency(amount) < remainingBalance
        ) {
            setAmountError('Receipts must be paid in full');
        }
    };

    const handleSetPaymentMethod = (item) => {
        if (item?.slug !== 'stripe') {
            setAmountError('');
        }
        handleAmountErrorChecks(paymentAmount, item);
        setPaymentMethod(item);
        setSelectOpen(false);
    };

    useEffect(() => {
        const setupStripePromise = async () => {
            if (stripe && currentDocument?.documentId && !stripePromise) {
                const stripeRes = await loadStripe(
                    process.env.REACT_APP_STRIPE_PUB_KEY,
                    {
                        stripeAccount: stripe.applicationAccountId
                    }
                );
                setStripePromise(stripeRes);
            }
        };

        setupStripePromise();
    }, [currentDocument, stripe, stripePromise]);

    useEffect(() => {
        if (open) {
            setPaymentNotes('');
        }
    }, [open, setPaymentNotes]);

    const updateAndDispatchPayment = async (newDocumentId) => {
        const newTransaction = {
            documentId: newDocumentId || documentId,
            amount: toIntegerCurrency(paymentAmount),
            method: paymentMethod?.slug,
            notes: paymentNotes,
            date: paymentDate
        };

        const updatedDocument = await sendTransaction(
            newDocumentId || documentId,
            newTransaction
        );

        if (updatedDocument) {
            const document = await getDocumentById(updatedDocument?.documentId);

            dispatch(setDocumentState({ document, locked: true }));

            return document;
        }

        return false;
    };

    const tryCreateStripePaymentIntent = async (newDocumentId) => {
        const startTime = Date.now();
        setPaymentStage(PAYMENT_INTENT);

        const paymentIntent = await createManualStripePayment({
            documentId: newDocumentId || documentId,
            amount: toIntegerCurrency(paymentAmount),
            notes: paymentNotes
        });

        setStripeIntentSecret(paymentIntent);

        await delayAwait(startTime, 2000);

        if (paymentIntent) {
            setPaymentStage(PAYMENT_INPUT);
        } else {
            setPaymentStage(PAYMENT_ERROR);
            setPaymentErrorMessage('Your payment was unable to be processed.');
        }
    };

    const handlePayment = async (newDocumentId) => {
        const startTime = Date.now();

        triggerAnimation();

        const updatedDocumentWithPayment =
            paymentMethod?.slug !== 'stripe' && paymentMethod?.slug !== 'square'
                ? await updateAndDispatchPayment(newDocumentId)
                : await getDocumentById(documentId);

        if (!updatedDocumentWithPayment) {
            setPaymentStage(PAYMENT_ERROR);
            setPaymentErrorMessage('Your payment was unable to be processed.');
            return { updatedDocumentWithPayment, startTime };
        }

        if (
            paymentMethod?.slug === 'stripe' ||
            paymentMethod?.slug === 'square'
        ) {
            dispatch(
                setDocumentState({
                    document: updatedDocumentWithPayment,
                    locked: true
                })
            );
        }

        handleClose();

        return { updatedDocumentWithPayment, startTime };
    };

    const resetPaymentStage = () => {
        setPaymentStage(null);
    };

    const handleRetryPayment = () => {
        if (paymentMethod?.slug === 'stripe') {
            tryCreateStripePaymentIntent();
        } else {
            setPaymentStage(null);
        }
    };

    const setDocumentAsPaid = async () => {
        resetPaymentStage();

        const { updatedDocumentWithPayment, startTime } = await handlePayment();

        await delayAwait(startTime, 2000);

        if (onPostSuccess) {
            onPostSuccess(updatedDocumentWithPayment);
        }
    };

    const [squareModalOpen, setSquareModalOpen] = useState(false);

    const squareModalOnClose = async () => {
        setSquareModalOpen(false);
    };

    const openSquareModal = async () => {
        setPaymentStage(null);
        setSquareModalOpen(true);
    };

    const handleSquarePaymentResult = async (isValid) => {
        if (isValid) {
            setPaymentStage(PAYMENT_SUCCESS);
            setSquareModalOpen(false);
        } else {
            setPaymentStage(PAYMENT_ERROR);
            setPaymentErrorMessage('Your payment was unable to be processed.');
        }
    };

    const getStartTime = async () => {
        const startTime = await Date.now();
        return { startTime };
    };

    const handleSubmitPayment = async () => {
        let newDocumentId;

        // If we have an onPreSuccess function to lock a receipt, run it and update data with the created documentId
        if (onPreSuccess) {
            setAddPaymentButtonLoading(true);

            const newDocument = await onPreSuccess();
            setDocumentId(newDocument?.documentId);
            newDocumentId = newDocument?.documentId;

            setAddPaymentButtonLoading(false);
        }

        if (paymentMethod?.slug === 'stripe') {
            await tryCreateStripePaymentIntent(newDocumentId);
            return;
        }

        if (paymentMethod?.slug === 'square') {
            setPaymentStage(PAYMENT_INTENT);

            const { startTime } = await getStartTime();

            // Just gives the modal time to be displayed and also load square sdk
            await delayAwait(startTime, 2000);
            openSquareModal();

            return;
        }

        const { updatedDocumentWithPayment, startTime } = await handlePayment(
            newDocumentId
        );

        await delayAwait(startTime, 2000);

        if (onPostSuccess) {
            onPostSuccess(updatedDocumentWithPayment);
        }
    };

    const methodsList = (list) =>
        list.map((item) => (
            <div key={item.id}>
                <MenuItem
                    key={item.slug}
                    value={item.slug}
                    className={classes.menuItemOptions}
                    onClick={() => handleSetPaymentMethod(item)}
                    selected={item.name === paymentMethod.name}
                >
                    <Grid item container direction="row" alignItems="center">
                        <Grid item className={classes.paymentSelectorIcon}>
                            {item.icon}
                        </Grid>
                        <Grid item>
                            <Typography>{item.name}</Typography>
                        </Grid>
                    </Grid>
                </MenuItem>
            </div>
        ));

    return (
        <>
            <Dialog open={open} classes={{ paper: classes.dialogPaper }}>
                <CloseButton handleClose={handleClose} />

                <Grid item className={classes.modal} container spacing={2}>
                    <Grid
                        item
                        xs={12}
                        container
                        direction="row"
                        alignItems="center"
                        className={classes.modalHeader}
                    >
                        <PaymentsActive className={classes.headerIcon} />
                        <Typography variant="h1">
                            Accept/Record Payment
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            name="payment-amount"
                            id="payment-amount"
                            label="Payment Amount"
                            InputProps={currencySymbolAdornment({
                                position,
                                symbol
                            })}
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                            inputProps={{
                                type: 'number',
                                step: '0.01',
                                min: '0'
                            }}
                            value={paymentAmount}
                            onChange={(e) => {
                                setAmountError('');
                                setPaymentAmount(e.target.value);
                            }}
                            onBlur={() => {
                                setPaymentAmount(
                                    parseFloat(paymentAmount || 0).toFixed(2)
                                );
                                handleAmountErrorChecks(paymentAmount);
                            }}
                            helperText={amountError}
                            onKeyDown={forcePositiveNumberInput}
                            error={Boolean(amountError)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <GenericDatePicker
                            id="payment-date"
                            label="Payment Date"
                            selectionReturn={(selectedDate) =>
                                setPaymentDate(selectedDate.toISOString())
                            }
                            margin="none"
                            disablePast={false}
                            value={String(paymentDate)}
                            size="small"
                            setDateError={(isError) => setDateError(isError)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            select
                            fullWidth
                            id="payment-method"
                            label="Payment Method"
                            variant="filled"
                            data-cy="invoice-manual-payment-dialog-select"
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{
                                open: selectOpen,
                                onOpen: () => {
                                    setSelectOpen(true);
                                },
                                onClose: () => {
                                    setSelectOpen(false);
                                },
                                displayEmpty: true,
                                renderValue: () => (
                                    <Typography data-testid="payment-method-dropdown">
                                        {
                                            // Handle default provider case
                                            paymentMethod?.name === 'Stripe' ||
                                            paymentMethod?.name === 'Square'
                                                ? `Credit Card (${paymentMethod?.name})`
                                                : paymentMethod?.name ||
                                                  'Select Payment Method'
                                        }
                                    </Typography>
                                )
                            }}
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    {(creditCardPaymentMethods.length > 0 ||
                                        !registeredUser) && (
                                        <>
                                            <MenuItem
                                                value=""
                                                disabled
                                                classes={{
                                                    disabled:
                                                        classes.disabledListItem
                                                }}
                                            >
                                                <Typography
                                                    className={
                                                        classes.previousPaymentListItem
                                                    }
                                                    variant="h3"
                                                >
                                                    Accept Payment
                                                </Typography>
                                            </MenuItem>
                                            <Divider
                                                className={classes.titleDivider}
                                            />
                                        </>
                                    )}
                                    {!registeredUser ? (
                                        <Grid item xs={12}>
                                            <Button
                                                className={classes.loginButton}
                                                color="primary"
                                                variant="contained"
                                                onClick={() =>
                                                    setLoginView('login')
                                                }
                                            >
                                                Login to setup electronic
                                                payments
                                            </Button>
                                        </Grid>
                                    ) : (
                                        methodsList(creditCardPaymentMethods)
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <MenuItem
                                        value=""
                                        disabled
                                        classes={{
                                            disabled: classes.disabledListItem
                                        }}
                                    >
                                        <Typography
                                            className={
                                                classes.previousPaymentListItem
                                            }
                                            variant="h3"
                                        >
                                            Record Payment
                                        </Typography>
                                    </MenuItem>
                                    <Divider className={classes.titleDivider} />
                                    {methodsList(recordedPaymentMethods)}
                                </Grid>
                            </Grid>
                        </TextField>
                    </Grid>

                    <Grid item xs={12}>
                        <NotesField
                            label="Internal Payment Note"
                            defaultRows={isDesktop ? 6 : 3}
                            maxRows={isDesktop ? 6 : 3}
                            maxCharacters={250}
                            onBlur={(e) => setPaymentNotes(e.target.value)}
                            classOverride={classes.notesField}
                            defaultNotes={paymentNotes}
                            isPayment
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        container
                        direction="row"
                        justifyContent="space-between"
                        className={classes.buttonContainer}
                    >
                        <Grid item xs={5}>
                            <Button
                                fullWidth
                                variant="outlined"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={5}>
                            <ButtonLoading
                                fullWidth
                                disabled={
                                    !paymentMethod ||
                                    !verifyMinMax(paymentMethod) ||
                                    Boolean(amountError) ||
                                    addPaymentButtonLoading ||
                                    dateError
                                }
                                variant="contained"
                                color="primary"
                                onClick={handleSubmitPayment}
                                loading={addPaymentButtonLoading}
                            >
                                Add {isDesktop ? 'Payment' : ''}
                            </ButtonLoading>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>

            {/* This conditional is needed to unmount the Square payment modal so it 
                can be detached and re-attached if the user clicks the 'Back' button */}
            {squareModalOpen && (
                <SquarePaymentModal
                    isModal
                    currentDocument={currentDocument}
                    open={squareModalOpen}
                    onClose={(instance) => squareModalOnClose(instance)}
                    paymentCallback={(isValid) =>
                        handleSquarePaymentResult(isValid)
                    }
                    paymentAmount={{
                        displayValue: paymentAmount,
                        integerValue: toIntegerCurrency(paymentAmount)
                    }}
                    paymentDate={paymentDate}
                    paymentNotes={paymentNotes}
                    paymentType="creditCard-square"
                    containerId="card-container"
                    purchaseType="manual"
                />
            )}

            <PaymentStepModal
                amount={formatCurrency({
                    amount: paymentAmount,
                    locale,
                    currency: code
                })}
                handleRetry={handleRetryPayment}
                handleRedirect={setDocumentAsPaid}
                paymentStage={paymentStage}
                paymentErrorMessage={paymentErrorMessage}
                cancelProcess={resetPaymentStage}
                paymentMethodName={paymentMethod.name}
            />
            <Elements stripe={stripePromise}>
                <PaymentIntentModal
                    address={currentDocument?.to?.billingAddress}
                    amountToPay={toIntegerCurrency(paymentAmount) || 0}
                    currency={code}
                    email={currentDocument?.to?.emailAddress || ''}
                    locale={locale}
                    name={currentDocument?.to?.name || ''}
                    open={paymentStage === PAYMENT_INPUT}
                    referenceNumber={currentDocument?.referenceNumber}
                    setPaymentStage={setPaymentStage}
                    setPaymentErrorMessage={setPaymentErrorMessage}
                    stripeIntentSecret={stripeIntentSecret}
                />
                <SignUpLoginModal
                    setLoginView={setLoginView}
                    view={loginView}
                    onSuccess={(login) => {
                        if (login) {
                            dispatch(resetDocument());
                            handleClose();
                            navigate('/');
                        }
                    }}
                />
            </Elements>
        </>
    );
};

PaymentModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    currentDocument: PropTypes.object.isRequired,
    triggerAnimation: PropTypes.func.isRequired,
    isDesktop: PropTypes.bool,
    onPreSuccess: PropTypes.func,
    onPostSuccess: PropTypes.func,
    preselectPaymentMethod: PropTypes.string
};

PaymentModal.defaultProps = {
    isDesktop: true,
    onPreSuccess: null,
    onPostSuccess: null,
    preselectPaymentMethod: ''
};

export default PaymentModal;
