import React from 'react';
import { Grid, Typography } from '@mui/material';
import {
    CashAppCodeIcon,
    CashAppExportIcon,
    CashAppProfileIcon
} from '@/resources/images/cashapp';
import useStyles from '../styles';

const CashAppUploadInstructions = () => {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="column"
            className={classes.merchantInstructionsListContainer}
        >
            <Grid item>
                <Typography variant="body2" color="textSecondary">
                    To export code image from Cash App
                </Typography>
            </Grid>
            <Grid item>
                <ol className={classes.instructionList}>
                    <Typography
                        component="li"
                        variant="body2"
                        color="textSecondary"
                        className={classes.uploadInstructions}
                    >
                        Tap the profile icon in the top right.
                        <CashAppProfileIcon className={classes.cashAppIcon} />
                    </Typography>
                    <Typography
                        component="li"
                        variant="body2"
                        color="textSecondary"
                        className={classes.uploadInstructions}
                    >
                        Tap the code icon in the top left.
                        <CashAppCodeIcon className={classes.cashAppIcon} />
                    </Typography>
                    <Typography
                        component="li"
                        variant="body2"
                        color="textSecondary"
                        className={classes.uploadInstructions}
                    >
                        Tap the export icon in the top right.
                        <CashAppExportIcon className={classes.cashAppIcon} />
                    </Typography>
                    <Typography
                        component="li"
                        variant="body2"
                        color="textSecondary"
                        className={classes.uploadInstructions}
                    >
                        Choose where you want to save the image to.
                    </Typography>
                    <Typography
                        component="li"
                        variant="body2"
                        color="textSecondary"
                        className={classes.uploadInstructions}
                    >
                        Upload your saved code image on this page.
                    </Typography>
                </ol>
            </Grid>
        </Grid>
    );
};

export default CashAppUploadInstructions;
