/* eslint import/no-cycle: "off"  */
import {
    fetchDocument,
    documentStoppedLoading,
    setDocumentTags,
    setDocumentReminders
} from '@/state/actions';
import store from '@/state/store';
import {
    convertDocumentFromApi,
    convertDocumentFromApiSetLocalization
} from '@/util';
import axiosAuthenticated from '../authenticatedRequestor';
import axiosUnauthenticated from '../unauthenticatedRequestor';

/**
 * Get document PDF from public endpoint using JWT.
 * @param  {string} jwt JWT associated with this invoice.
 * @return {Promise} Will return invoice PDF or error.
 */
export const downloadDocumentPdf = async (jwt) => {
    try {
        const res = await axiosUnauthenticated.get(
            `/public/documents/${jwt}/download`
        );

        return res?.data;
    } catch (err) {
        return false;
    }
};

export const getDocumentById = async (documentId) => {
    if (store.getState().user.temporaryUser) {
        return false;
    }

    store.dispatch(fetchDocument());

    const { businessId } = store.getState().user?.activeBusiness;

    const res = await axiosAuthenticated.get(
        `/businesses/${businessId}/documents/${documentId}`
    );

    const { documentState } = res.data;

    if (documentState === 'deleted') {
        store.dispatch(documentStoppedLoading());
        // eslint-disable-next-line no-throw-literal
        throw 'document has been deleted';
    }

    const newDocument = convertDocumentFromApiSetLocalization(res.data);

    store.dispatch(documentStoppedLoading());

    return newDocument;
};

/**
 * Get document data form public endpoint using JWT.
 * @param  {string} jwt JWT associated with this document.
 * @return {Promise} Will return document object or error.
 */
export const getDocumentPublic = async (jwt) => {
    try {
        const res = await axiosUnauthenticated.get(`/public/documents/${jwt}`);

        const convertedCurrencyDocument = convertDocumentFromApi({
            currentObject: { ...res.data, ...res.data.document }
        });

        return convertedCurrencyDocument;
    } catch (err) {
        return false;
    }
};

/**
 * @param {string} documentId Id for this document
 * @param {string} documentType invoice, estimate, or receipt
 * @param {array} tags array of tags associated with this document
 */

export const updateLockedDocumentTags = async (
    documentId,
    documentType,
    tags
) => {
    const tagIds = tags.map((tag) => tag.tagId);

    const { businessId } = store.getState().user.activeBusiness;

    await axiosAuthenticated.put(
        `/businesses/${businessId}/${documentType}s/${documentId}/tags`,
        { tagIds }
    );

    store.dispatch(setDocumentTags(tags));
};

/**
 * @param {string} documentId Id for this document
 * @param {array} reminders object of reminders associated with this document
 */
export const updateLockedDocumentReminders = async ({
    documentId,
    reminders
}) => {
    const { businessId } = store.getState().user.activeBusiness;

    await axiosAuthenticated.put(
        `/businesses/${businessId}/invoices/${documentId}/reminders`,
        reminders
    );

    store.dispatch(setDocumentReminders(reminders));
};
