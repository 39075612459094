import React from 'react';
import { Grid, Paper, Skeleton } from '@mui/material';
import useStyles from './styles';

const SettingsSkeleton = () => {
    const classes = useStyles();
    return (
        <Grid item xs={12}>
            <Paper className={classes.loaderSettings}>
                <Skeleton
                    className={classes.loaderSettingsSkeletonTitle}
                    width="250px"
                    height="60px"
                    variant="rectangular"
                    data-testid="title-skeleton"
                />
                <Skeleton
                    className={classes.loaderSettingsSkeletonBar}
                    width="100%"
                    height="60px"
                    variant="rectangular"
                    data-testid="bar-skeleton"
                />
                <Skeleton
                    width="100%"
                    height="700px"
                    variant="rectangular"
                    data-testid="content-skeleton"
                />
            </Paper>
        </Grid>
    );
};

export default SettingsSkeleton;
