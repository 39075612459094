import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import TableCell from '../TableCell';
import useStyles from './styles';

/**
 * Here we need to make sure that if an action icon is present, the cell
 * itself is not wrapped in a link.
 * @param  {string} action Item to pass back if correct row.
 * @param  {string} key Which cell the loop is generating.
 * @return {string} Link or null
 */
export const filterRowAction = ({ action, key }) => {
    const removeList = ['tagAmount', 'tags', 'actions'];

    if (!removeList.includes(key)) {
        return action;
    }
    return null;
};

/**
 * Individual table row for the TableMaker component.
 * @param {array} actionCols Array of numbers that set which columns are actions.
 * @param {string} id String to be used as the item ID.
 * @param {object} row An object including all the date required for a row. It
 * must include an ID for the row.
 * @param {string} tableSlug The name of the table in a slugified form.
 */
const TableItem = ({ actionCols, id, row, tableSlug }) => {
    // Remove ID, PrimaryLink, PrimaryClick from cell object so it isn't displayed.
    const cleanCells = { ...row };
    const link = row.primaryLink;
    const onClick = row.primaryClick;

    const classes = useStyles({ hover: link || onClick });

    delete cleanCells.id;
    delete cleanCells.primaryLink;
    delete cleanCells.primaryClick;

    // Map out each cell and create a unique key reference place within the
    // table, and the table name.
    const cells = Object.keys(cleanCells).map((key, index) => {
        if (typeof cleanCells[key] === 'undefined') {
            return null;
        }
        return (
            <TableCell
                key={`${tableSlug}-${id}-${key}`}
                thin={actionCols.includes(index + 1)}
                to={filterRowAction({ action: link, key })}
                onClick={filterRowAction({ action: onClick, key })}
            >
                {cleanCells[key]}
            </TableCell>
        );
    });

    return <TableRow className={classes.row}>{cells}</TableRow>;
};

TableItem.propTypes = {
    actionCols: PropTypes.instanceOf(Array),
    id: PropTypes.string.isRequired,
    row: PropTypes.object.isRequired,
    tableSlug: PropTypes.string.isRequired
};

TableItem.defaultProps = {
    actionCols: []
};

export default TableItem;
