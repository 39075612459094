import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    radio: {
        color: theme.palette.common.white,
        backgroundColor: (active) =>
            active
                ? theme.palette.actionColors.send
                : theme.palette.text.secondary,
        borderRadius: 4,
        height: 24,
        width: 24,
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'center'
    }
}));
