import React from 'react';
import PropTypes from 'prop-types';

import {
    ApprovedIcon,
    ConvertedIcon,
    CreatedIcon,
    DeclinedIcon,
    DownloadedIcon,
    EmailSentIcon,
    LinkCreatedIcon,
    PaidIcon,
    RefundedIcon,
    RecurringCancelledIcon,
    RecurringSkippedIcon,
    RecurringCompleteIcon,
    SentIcon,
    StartedIcon,
    UpdatedIcon,
    VoidedIcon,
    MarkedPaidIcon,
    MarkedUnpaidIcon
} from '@/resources/icons/activity';

import useStyles from './styles';

const icons = {
    approved: ApprovedIcon,
    converted: ConvertedIcon,
    created: CreatedIcon,
    deleted: DeclinedIcon,
    downloaded: DownloadedIcon,
    'email-sent': EmailSentIcon,
    'link-created': LinkCreatedIcon,
    paid: PaidIcon,
    refunded: RefundedIcon,
    'recurring-started': StartedIcon,
    'recurring-created': CreatedIcon,
    'recurring-stopped': RecurringCancelledIcon,
    'recurring-skipped': RecurringSkippedIcon,
    'recurring-complete': RecurringCompleteIcon,
    sent: SentIcon,
    updated: UpdatedIcon,
    voided: VoidedIcon,
    'marked-paid': MarkedPaidIcon,
    'marked-unpaid': MarkedUnpaidIcon
};

const ActivityIconPicker = ({ activity }) => {
    const classes = useStyles();

    if (!icons[activity]) {
        return null;
    }

    const Icon = icons[activity];
    return <Icon className={classes.svg} />;
};

ActivityIconPicker.propTypes = {
    activity: PropTypes.string.isRequired
};

export default ActivityIconPicker;
