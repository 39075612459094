// Data to set the head of the table.
const tableHead = [
    {
        id: 'name',
        label: 'NAME',
        sort: true
    },
    {
        id: 'amount',
        label: 'AMOUNT USED'
    },
    {
        id: 'actions',
        label: '',
        colSpan: 2
    }
];

export default tableHead;
