import {
    Dialog,
    Grid,
    Typography,
    TextField,
    FormControlLabel,
    Checkbox,
    Button
} from '@mui/material';

import PropTypes from 'prop-types';
import React, { useState, memo } from 'react';
import { SendIcon } from '@/resources/icons';
import { emailRegexPattern } from '@/util';
import useStyles from './styles';
import ButtonLoading from '../ButtonLoading';

const NoEmailModal = memo(({ open, onClose, type }) => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [saveToClient, setSaveToClient] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = () => {
        if (emailRegexPattern.test(email)) {
            onClose(email, saveToClient, setLoading);
        } else {
            setError(true);
        }
    };

    const onEmailChange = (e) => {
        setEmail(e.target.value);
        if (error) if (emailRegexPattern.test(e.target.value)) setError(false);
    };

    return (
        <Dialog open={open} data-testid="noemailmodal">
            <Grid
                container
                direction="column"
                className={classes.modal}
                justifyContent="center"
                alignItems="center"
            >
                <Grid item>
                    <SendIcon className={classes.sendIcon} />
                </Grid>
                <Grid item>
                    <Typography variant="body1" className={classes.text}>
                        In order to send this {type}, you need to enter an email
                        address for the client.
                    </Typography>
                </Grid>
                <Grid item className={classes.email}>
                    <TextField
                        fullWidth
                        id="clientEmailAddress"
                        variant="filled"
                        name="emailAddress"
                        label="Email Address"
                        value={email}
                        onChange={onEmailChange}
                        error={error}
                        helperText={
                            error && 'Please enter a valid email address'
                        }
                    />
                </Grid>
                <Grid item className={classes.checkboxContainer}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={saveToClient}
                                name="saveToClient"
                                onChange={(e) => {
                                    setSaveToClient(e.target.checked);
                                }}
                            />
                        }
                        label="Save as client's email address"
                        classes={{ label: classes.saveText }}
                    />
                </Grid>
                <Grid
                    container
                    spacing={2}
                    className={classes.buttons}
                    justifyContent="center"
                >
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => {
                                onClose();
                            }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ButtonLoading
                            color="primary"
                            variant="contained"
                            fullWidth
                            className={classes.saveButton}
                            onClick={handleSubmit}
                            disableOnLoading
                            loading={loading}
                        >
                            Send {type}
                        </ButtonLoading>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
});

NoEmailModal.defaultProps = {
    open: false,
    onClose: () => {},
    type: 'invoice'
};

NoEmailModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    type: PropTypes.string
};
export default NoEmailModal;
