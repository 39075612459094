import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { List } from '@mui/material';
import { transaction as transactionModel } from '@/models';
import {
    getDocumentById,
    sendRefund
} from '@/modules/dataWrangler/dataWrangler';
import { setDocumentState } from '@/state/actions';
import { useCurrency, useTimezone, useLocale } from '@/hooks';
import RefundDialog from './RefundDialog';
import PaymentViewItem from './PaymentViewItem';

const DocumentPayments = memo(({ transactions }) => {
    const { code, symbol } = useCurrency({ isInvoice: true });
    const dispatch = useDispatch();
    const locale = useLocale();
    const timezone = useTimezone();

    const connections =
        useSelector((state) => state?.user?.activeBusiness?.connections) || [];

    const invoiceId = useSelector((state) => state.makeInvoice?.documentId);

    const [activeRefund, setActiveRefund] = useState({
        transactionId: '',
        amountAvailable: 0,
        paymentMethod: {
            type: '',
            last4: ''
        }
    });

    const availablePaymentMethods = connections.filter(
        (connection) => connection.applicationType === 'paymentProcessor'
    );

    const shallowClonedReversedTransactions = [...transactions]
        .filter((transaction) => {
            const transactionState = transaction.state?.toLowerCase();

            return (
                transactionState === 'succeeded' ||
                transactionState === 'failed' ||
                transactionState === 'completed' ||
                transactionState === 'pending'
            );
        })
        .reverse();

    const [showRefundDialog, setShowRefundDialog] = useState(false);

    const getPaymentViewItem = (transaction, isRefund) => {
        const paymentMethodConnected = availablePaymentMethods.find(
            (method) =>
                transaction.processor === method.application &&
                transaction.connectionId === method?.connectionId
        );

        const toolTip =
            transaction.state.toLowerCase() === 'pending'
                ? 'This bank transfer payment is pending and a refund will not be available until the payment is processed. Please allow up to 4 days for bank processing.'
                : `It looks like you disconnected
            your ${transaction.processor} account. You will
            need to submit a refund through
            your ${transaction.processor} dashboard.`;

        return (
            <PaymentViewItem
                key={transaction.refundId || transaction.paymentId}
                transaction={transaction}
                onSelectForRefund={() => {
                    setActiveRefund(transaction);
                    setShowRefundDialog(true);
                }}
                locale={locale}
                timezone={timezone}
                currency={code}
                refundAvailable={
                    transaction.amountCollected !==
                        transaction.amountRefunded &&
                    transaction.state?.toLowerCase() !== 'failed'
                }
                refundDisabled={
                    !paymentMethodConnected &&
                    transaction.processor !== 'manual'
                }
                toolTip={toolTip}
                isRefund={isRefund}
            />
        );
    };

    return (
        <List disablePadding data-testid="payments-list">
            {shallowClonedReversedTransactions.map((transaction) => [
                getPaymentViewItem(transaction),
                ...transaction?.refunds
                    ?.filter((refund) => {
                        const refundState = refund?.state?.toLowerCase();

                        return (
                            refundState === 'succeeded' ||
                            refundState === 'completed' ||
                            refundState === 'failed'
                        );
                    })
                    ?.map((refund) => getPaymentViewItem(refund, true))
            ])}
            {showRefundDialog && (
                <RefundDialog
                    amount={activeRefund.amountAvailable}
                    currency={symbol}
                    last4={
                        activeRefund.paymentMethod.last4 ||
                        activeRefund.paymentMethod.data
                    }
                    method={activeRefund.paymentMethod?.type}
                    show
                    onClose={() => setShowRefundDialog(false)}
                    onSubmit={async (refund) => {
                        await sendRefund(
                            activeRefund.paymentId,
                            refund,
                            activeRefund.processor
                        );
                        const invoice = await getDocumentById(invoiceId);
                        dispatch(
                            setDocumentState({
                                document: invoice,
                                locked: true
                            })
                        );
                    }}
                />
            )}
        </List>
    );
});

DocumentPayments.propTypes = {
    transactions: PropTypes.arrayOf(transactionModel)
};

DocumentPayments.defaultProps = {
    transactions: []
};

export default DocumentPayments;
