import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { ButtonBase } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { TagsIconGrey } from '@/resources/icons';
import { openDrawer } from '@/state/actions';
import useStyles from './styles';

export const TableTagPills = ({ backgroundColor, color, name }) => {
    const classes = useStyles({ backgroundColor, color });
    return (
        <div className={classes.mobileItemTagPill}>
            <Typography variant="h5" component="span">
                {name}
            </Typography>
        </div>
    );
};

TableTagPills.propTypes = {
    backgroundColor: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
};

export const MobileItemTags = ({ type, itemId, tags }) => {
    const classes = useStyles({ tags });
    const dispatch = useDispatch();

    const listingItems = useSelector((state) => state.listing?.items);

    const getId = () => {
        if (type === 'service') {
            return 'productId';
        }

        if (
            type === 'recurring-invoice' ||
            type === 'invoice' ||
            type === 'estimate' ||
            type === 'receipt'
        ) {
            return 'documentId';
        }

        return `${type}Id`;
    };

    const item = listingItems?.find(
        (itemData) => itemData?.[getId()] === itemId
    );

    const handleClick = () => {
        dispatch(
            openDrawer({
                id: 'tags',
                type,
                item,
                edit: true
            })
        );
    };

    return (
        <ButtonBase
            className={`${classes.itemTagsContainer} ${
                tags.length > 0 ? classes.itemTagTopSpacing : ''
            }`}
            onClick={handleClick}
            disableRipple
        >
            {tags.length > 0 && (
                <TagsIconGrey className={classes.itemTagIcon} />
            )}
            <div className={classes.itemTagPillContainer}>
                {tags.map((tag) => (
                    <TableTagPills
                        backgroundColor={tag.backgroundColor}
                        color={tag.color}
                        name={tag.name}
                        tagId={tag.tagId}
                        key={tag.tagId}
                    />
                ))}
            </div>
        </ButtonBase>
    );
};

MobileItemTags.propTypes = {
    type: PropTypes.string.isRequired,
    itemId: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.object)
};

MobileItemTags.defaultProps = {
    tags: []
};
