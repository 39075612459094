import { useEffect } from 'react';

/**
 * Bypasses the react-hooks/exhaustive-deps lint issue by moving the useEffect
 * into its own hook when needing to replicate componentDidMount.
 * @param  {function} fun Function required to be run on componentDidMount.
 * @return {function}     Function wrapped in useEffect.
 * @see https://stackoverflow.com/questions/53120972/how-to-call-loading-function-with-react-useeffect-only-once#answer-56767883
 */
// eslint-disable-next-line react-hooks/exhaustive-deps
export const useMountEffect = (fun) => useEffect(fun, []);
