import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
    amountUsedContainer: {
        padding: '5px 15px',
        border: '1px solid #E0E5EE',
        borderRadius: 24,
        height: 34,
        width: 75,
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            background: '#E0E5EE'
        }
    },
    typeIcon: {
        height: 18,
        width: 18,
        marginRight: 5,
        '& svg': {
            height: '100%',
            width: '100%'
        }
    },
    amountUsedText: {
        fontWeight: 600,
        color: '#334155'
    }
}));
