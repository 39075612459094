/* eslint import/no-cycle: "off"  */
import axiosAuthenticated from '../authenticatedRequestor';
import axiosUnauthenticated from '../unauthenticatedRequestor';

export const requestGetUserSubscription = (businessId) =>
    axiosAuthenticated.get(`/businesses/${businessId}/subscription`);

export const requestUpdateChargifyUser = (businessId, userInfo) =>
    axiosAuthenticated.put(
        `/businesses/${businessId}/subscription/chargify/customer`,
        userInfo
    );

export const requestCreateSubscription = (
    businessId,
    paymentType,
    productHandle,
    paymentToken,
    recaptchaToken,
    couponCode,
    urlParams
) => {
    // Create query params from params object.
    // Remove any params that are null or undefined.
    const queryParams = Object?.keys(urlParams)
        .filter((key) => urlParams[key] != null)
        .map((key) => `${key}=${urlParams[key]}`)
        .join('&');

    return axiosAuthenticated.post(
        `/businesses/${businessId}/subscription/chargify/subscription?${queryParams}`,
        {
            paymentType,
            productHandle,
            paymentToken,
            recaptchaToken,
            couponCode
        }
    );
};

export const requestUpdateSubscriptionPaymentMethod = (
    businessId,
    paymentToken,
    paymentType,
    recaptchaToken
) =>
    axiosAuthenticated.put(
        `/businesses/${businessId}/subscription/chargify/subscription/payment-method`,
        { paymentToken, paymentType, recaptchaToken }
    );

export const requestUpdateSubscriptionProduct = (businessId, productHandle) =>
    axiosAuthenticated.put(
        `/businesses/${businessId}/subscription/chargify/subscription/product`,
        { productHandle }
    );

export const requestCancelSubscription = (businessId) =>
    axiosAuthenticated.post(
        `/businesses/${businessId}/subscription/chargify/subscription/cancel`
    );

export const requestUndoCancelSubscription = (businessId) =>
    axiosAuthenticated.delete(
        `/businesses/${businessId}/subscription/chargify/subscription/cancel`
    );

export const requestReactivateSubscription = (businessId) =>
    axiosAuthenticated.put(
        `/businesses/${businessId}/subscription/chargify/subscription/reactivate`
    );

export const requestGetAvailableSubscriptions = () =>
    axiosUnauthenticated.get(`public/subscriptions/chargify/products`);

export const requestValidateCouponCode = (businessId, couponCode) =>
    axiosAuthenticated.get(
        `/businesses/${businessId}/subscription/chargify/coupons/validate?code=${couponCode}`
    );

export const requestAddCouponCodeToSubscription = (businessId, couponCode) =>
    axiosAuthenticated.put(
        `/businesses/${businessId}/subscription/chargify/subscription/coupons`,
        { couponCode }
    );

export const requestAcceptCancellationOffer = (businessId) =>
    axiosAuthenticated.post(
        `/businesses/${businessId}/subscription/chargify/subscription/cancellationOffer/accept`
    );
