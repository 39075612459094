import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    modal: {
        padding: theme.typography.pxToRem(16),
        maxWidth: 580,
        height: 'auto',
        [theme.breakpoints.up('sm')]: {
            padding: theme.typography.pxToRem(50)
        }
    },
    sendIcon: {
        '& path': {
            fill: theme.palette.primary.main
        },
        display: 'block',
        height: 107,
        marginBottom: 30,
        width: 107,
        [theme.breakpoints.down('sm')]: {
            width: 85,
            height: 'auto',
            marginTop: 30
        }
    },
    text: {
        marginBottom: 30
    },
    email: {
        marginBottom: 5,
        width: '100%'
    },
    checkboxContainer: {
        width: '100%',
        marginBottom: 30
    },
    saveText: theme.typography.body2,
    buttons: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            '& div': {
                width: '100%'
            }
        }
    },
    saveButton: {
        textTransform: 'capitalize'
    }
}));
