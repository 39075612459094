import React from 'react';
import Lottie from 'lottie-react';
import downloadLoading from './download-loading.json';
import useStyles from './styles';

const LottieDownloadLoading = () => {
    const classes = useStyles();

    return (
        <div className={classes.wrap} data-testid="lottie-download">
            <Lottie animationData={downloadLoading} />
        </div>
    );
};

export default LottieDownloadLoading;
