import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getLocalizationSettings } from '@/modules/dataWrangler/dataWrangler';

/**
 * @returns Object of arrays with all possible localization options from the backend along with a loading state.
 * (countries, currencies, languages, timezones)
 */

export const useLocalization = () => {
    const [loading, setLoading] = useState(false);
    const localizationData = useSelector((state) => state?.localization);

    const getData = useCallback(async () => {
        // If we don't have any data, and this function has never been called.
        if (Object.entries(localizationData || {}).length === 0 && !loading) {
            setLoading(true);
            await getLocalizationSettings();
            setLoading(false);
        }
    }, [localizationData, loading]);

    useEffect(() => {
        getData();
    }, [getData]);

    return { loading, ...localizationData };
};
