import React from 'react';
import { useDispatch } from 'react-redux';
import { useToastNotification } from '@/hooks';
import { closeModal, setModal, setDocumentStatus } from '@/state/actions';
import { shareReceipt } from '@/modules/dataWrangler/dataWrangler';
import { SendIcon } from '@/resources/icons';
import { delayAwait } from '@/util';

/**
 * Receipt Hook
 * Sends an receipt, where data is passed into the hook.
 * Handles redirection logic if receipts limited, and
 * creating or updating functionality.
 *
 * Returns false, or clean receipt data.
 */
export const useSendReceipt = () => {
    const dispatch = useDispatch();
    const { triggerToast } = useToastNotification();

    const sendReceipt = async (
        receiptData,
        emailOverwrite,
        emailSubject,
        emailBody
    ) => {
        const startTime = Date.now();
        dispatch(setModal({ slug: 'loader-receipt-send' }));

        let savedData = { ...receiptData };

        const documentId = savedData?.documentId;

        if (documentId !== null) {
            savedData = await shareReceipt(
                documentId,
                'email',
                emailOverwrite,
                emailSubject,
                emailBody
            );

            if (savedData?.status === 429) {
                await delayAwait(startTime, 2000);

                dispatch(closeModal('loader-receipt-send'));

                triggerToast({
                    message: 'Please wait before sending again',
                    action: 'delete',
                    icon: <SendIcon />
                });

                return false;
            }

            // If draft, and API returned correctly - update receipt state.
            if (
                savedData?.documentState &&
                savedData?.documentState === 'draft'
            ) {
                dispatch(setDocumentStatus(savedData.documentState));
            }

            await delayAwait(startTime, 2000);

            dispatch(closeModal('loader-receipt-send'));

            triggerToast({
                message: 'Receipt Sent',
                action: 'send',
                icon: <SendIcon />
            });

            return savedData;
        }

        return false;
    };

    return sendReceipt;
};
