/* eslint-disable camelcase */
import {
    Dialog,
    Grid,
    Typography,
    Button,
    TextField,
    InputAdornment
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import {
    forcePositiveNumberInput,
    toWholeCurrency,
    toIntegerCurrency
} from '@/util';
import { paymentMethods } from '@/config';
import {
    RefundIcon,
    CreditCardIcon,
    OtherPaymentIcon,
    CashPaymentIcon,
    CheckPaymentIcon,
    ACHSmall,
    AfterpayLogo,
    AffirmLogo,
    KlarnaRound,
    VenmoIcon,
    CashAppIcon,
    ZelleIcon,
    WarningIcon
} from '@/resources/icons';
import { CloseButton, ButtonLoading } from '@/components/common';
import useStyles from './styles';

/**
 * Renders a particular payment icon, depenant on the method string passed in.
 * @param {string} method Method of payment: 'card', 'stripe', 'cash', 'check'.
 * Anything else falls back to 'Other'.
 */
const PaymentIcon = ({ method }) => {
    const classes = useStyles();

    switch (method) {
        case 'card':
        case 'genericCreditCard':
        case 'square':
        case 'stripe': {
            return <CreditCardIcon className={classes.paymentIcon} />;
        }
        case 'cash': {
            return <CashPaymentIcon className={classes.paymentIcon} />;
        }
        case 'check': {
            return <CheckPaymentIcon className={classes.paymentIcon} />;
        }
        case 'bank_account':
        case 'directdebit': {
            return <ACHSmall className={classes.paymentIcon} />;
        }
        case 'afterpay_clearpay': {
            return <AfterpayLogo className={classes.paymentIcon} />;
        }
        case 'affirm': {
            return <AffirmLogo className={classes.paymentIcon} />;
        }
        case 'klarna': {
            return <KlarnaRound className={classes.paymentIcon} />;
        }
        case 'venmo': {
            return <VenmoIcon className={classes.paymentIcon} />;
        }
        case 'wallet':
        case 'cashapp': {
            return <CashAppIcon className={classes.paymentIcon} />;
        }
        case 'zelle': {
            return <ZelleIcon className={classes.paymentIcon} />;
        }
        default: {
            return <OtherPaymentIcon className={classes.paymentIcon} />;
        }
    }
};

PaymentIcon.propTypes = {
    method: PropTypes.string.isRequired
};

const RefundDialog = memo(
    ({ amount, currency, method, last4, show, onClose, onSubmit }) => {
        const classes = useStyles();
        const maxLength = 250;

        const [refundAmount, setRefundAmount] = useState(
            toWholeCurrency(amount).toFixed(2)
        );

        const [amountError, setAmountError] = useState('');
        const [notes, setNotes] = useState('');
        const [refundButtonLoading, setRefundButtonLoading] = useState(false);

        const getThirdPartyMethodName = (name) => {
            switch (name) {
                case 'afterpay_clearpay':
                    return 'Afterpay';
                case 'affirm':
                    return 'Affirm';
                case 'klarna':
                    return 'Klarna';
                case 'bank_account':
                    return 'Bank Transfer';
                case 'wallet':
                    return 'Cash App';
                case 'genericCreditCard':
                    return 'Card';

                default:
                    return 'Other';
            }
        };

        const methodIsCard =
            method === 'card' || method === 'stripe' || method === 'square';
        const methodName = methodIsCard
            ? method
            : paymentMethods.find((meth) => meth.slug === method)?.name;

        const thirdPartyMethodName = getThirdPartyMethodName(method);

        const internalNonPaymentMethods = [
            'genericCreditCard',
            'directdebit',
            'venmo',
            'cashapp',
            'zelle'
        ];

        const showWarningText = internalNonPaymentMethods.includes(method);

        return (
            <Dialog open={show} classes={{ paper: classes.dialogPaper }}>
                <CloseButton
                    handleClose={() => {
                        if (!refundButtonLoading) {
                            onClose();
                        }
                    }}
                />

                <Grid
                    id="process-refund-modal"
                    container
                    className={classes.modal}
                    spacing={2}
                >
                    <Grid item>
                        <div className={classes.cardHeader}>
                            <RefundIcon className={classes.refundIcon} />
                            <Typography variant="h1">
                                {showWarningText ? 'Record' : 'Enter'} Refund
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item container>
                        <Grid container item spacing={2}>
                            <Grid item xs={7} sm>
                                <TextField
                                    fullWidth
                                    name="refund-amount"
                                    id="refund-amount"
                                    label="Refund Amount"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Typography>
                                                    {currency}
                                                </Typography>
                                            </InputAdornment>
                                        )
                                    }}
                                    // eslint-disable-next-line react/jsx-no-duplicate-props
                                    inputProps={{
                                        type: 'number',
                                        step: '0.01',
                                        min: '0'
                                    }}
                                    value={refundAmount}
                                    onChange={(e) => {
                                        setAmountError('');
                                        setRefundAmount(e.target.value);
                                    }}
                                    onBlur={() => {
                                        setRefundAmount(
                                            parseFloat(refundAmount).toFixed(2)
                                        );
                                        if (
                                            toIntegerCurrency(refundAmount) >
                                            amount
                                        ) {
                                            setAmountError(
                                                'Cannot exceed amount remaining'
                                            );
                                        }
                                        if (
                                            toIntegerCurrency(refundAmount) <= 0
                                        ) {
                                            setAmountError(
                                                'Refunds must be greater than zero'
                                            );
                                        }
                                    }}
                                    helperText={amountError}
                                    onKeyDown={forcePositiveNumberInput}
                                    error={Boolean(amountError)}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={4}
                                sm={4}
                                className={classes.methodTextContainer}
                            >
                                <Typography
                                    className={classes.cardInfoHeaderText}
                                >
                                    Method
                                </Typography>
                                <div
                                    className={
                                        classes.paymentMethodLast4Container
                                    }
                                >
                                    <PaymentIcon method={method} />

                                    <Typography>
                                        {`${
                                            methodName || thirdPartyMethodName
                                        }${last4 ? ` ****${last4}` : ''}`}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.textFieldContainer}>
                        <TextField
                            id="refund-notes"
                            multiline
                            aria-label="Internal Refund Note"
                            label="Refund Note"
                            inputProps={{ maxLength }}
                            fullWidth
                            onChange={(event) => setNotes(event.target.value)}
                            minRows={5}
                        />
                    </Grid>
                    <Grid
                        item
                        style={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            marginLeft: 'auto'
                        }}
                    >
                        <Typography className={classes.characterText}>
                            {`${notes.length}/${maxLength}`}
                        </Typography>
                    </Grid>
                    {showWarningText && (
                        <Grid
                            item
                            container
                            className={classes.refundWarningContainer}
                        >
                            <div>
                                <WarningIcon
                                    className={classes.refundWarningIcon}
                                />
                            </div>
                            <Typography
                                variant="h5"
                                className={classes.refundWarningText}
                            >
                                This payment was processed outside of Invoice
                                Maker. Entering this information is for
                                accounting purposes only and will not refund the
                                card.
                            </Typography>
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={12}
                        container
                        direction="row"
                        justifyContent="space-between"
                        className={classes.buttonContainer}
                    >
                        <Grid item xs={5}>
                            <Button
                                fullWidth
                                variant="outlined"
                                onClick={() => {
                                    if (!refundButtonLoading) {
                                        onClose();
                                    }
                                }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={5}>
                            <ButtonLoading
                                color="primary"
                                variant="contained"
                                fullWidth
                                disabled={Boolean(amountError)}
                                loading={refundButtonLoading}
                                disableOnLoading
                                onClick={async () => {
                                    setRefundButtonLoading(true);
                                    await onSubmit({
                                        amount: toIntegerCurrency(refundAmount),
                                        notes
                                    });
                                    setRefundButtonLoading(false);
                                    onClose();
                                }}
                            >
                                <span className={classes.withProcess}>
                                    {showWarningText ? 'Record' : 'Process'}{' '}
                                    Refund
                                </span>
                                <span className={classes.withoutProcess}>
                                    Refund
                                </span>
                            </ButtonLoading>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        );
    }
);

RefundDialog.defaultProps = {
    last4: '',
    currency: '$',
    show: false,
    amount: 0,
    method: '',
    onClose: () => {},
    onSubmit: () => {}
};

RefundDialog.propTypes = {
    last4: PropTypes.string,
    currency: PropTypes.string,
    show: PropTypes.bool,
    method: PropTypes.string,
    amount: PropTypes.number,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func
};

export default RefundDialog;
