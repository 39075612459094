import React from 'react';
import { useLocation } from 'react-router-dom-v5-compat';
import { Typography, Grid } from '@mui/material';
import { useCheckAuthState } from '@/hooks/useCheckAuthState';
import InlineLink from '@/components/common/InlineLink';
import styles from './styles';

const Footer = () => {
    const location = useLocation();
    const { registeredUser } = useCheckAuthState();
    const useAdjustedPaper =
        location?.pathname === '/' ||
        (location?.pathname.includes('/settings') && registeredUser);
    const classes = styles(useAdjustedPaper);

    return (
        <div className={classes.wrap} data-testid="footer">
            <Grid>
                <Typography variant="body2">
                    <InlineLink
                        disableUnderline
                        href="https://invoicemaker.com/policies/terms-of-use/"
                    >
                        Terms of Use
                    </InlineLink>
                    {` | `}
                    <InlineLink
                        disableUnderline
                        href="https://invoicemaker.com/policies/privacy-policy/"
                    >
                        Privacy Policy
                    </InlineLink>
                </Typography>
                <Typography variant="body2">
                    © {new Date().getFullYear()} - Invoice Maker, LLC - All
                    Rights Reserved
                </Typography>
            </Grid>
        </div>
    );
};

export default Footer;
