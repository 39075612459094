import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { ReminderBell } from '@/resources/icons';
import { openDrawer } from '@/state/actions';
import { MakeDocumentAccordion } from '@/components/common';
import { reminderOptions } from '@/config';
import { useSubscription } from '@/hooks';

import useStyles from './styles';

const ReminderPill = ({ label, after }) => {
    const classes = useStyles({
        after
    });

    return (
        <div className={classes.pillBase}>
            <span className={classes.pillName}>{label}</span>
        </div>
    );
};

ReminderPill.propTypes = {
    label: PropTypes.string.isRequired,
    after: PropTypes.bool
};

ReminderPill.defaultProps = {
    after: false
};

const RemindersAccordion = ({ locked }) => {
    const classes = useStyles();
    const document = useSelector((state) => state?.makeInvoice);
    const documentType = document?.documentType || '';
    const reminders = document?.reminders || [];
    const subscription = useSubscription();

    const remindersEnabled = subscription?.components?.reminders?.enabled;

    const filteredReminders = Object.keys(reminders).filter((reminder) =>
        Boolean(reminders[reminder])
    );

    // Find the reminder objects that match the selected options.
    const currentUpcomingReminders = filteredReminders
        .map((reminder) =>
            reminderOptions.upcoming.find((option) => option.slug === reminder)
        )
        .filter(Boolean);

    const currentPastDueReminders = filteredReminders
        .map((reminder) =>
            reminderOptions.pastDue.find((option) => option.slug === reminder)
        )
        .filter(Boolean);

    const reminderNumber = filteredReminders?.length || 0;
    const dispatch = useDispatch();

    const handleOpenDrawer = (e) => {
        e.stopPropagation();
        dispatch(
            openDrawer({
                id: 'reminders',
                type: documentType,
                item: document,
                locked
            })
        );
    };

    return (
        <MakeDocumentAccordion
            buttonName="Add/Edit Reminders"
            title="Reminders"
            handleAddButtonClick={handleOpenDrawer}
            count={reminderNumber}
            icon={<ReminderBell className={classes.iconContainer} />}
            upgrade={!remindersEnabled}
        >
            <Grid container>
                {currentUpcomingReminders.length > 0 &&
                    currentUpcomingReminders.map(({ name, slug }) => (
                        <Grid item key={slug}>
                            <ReminderPill label={name} />
                        </Grid>
                    ))}
                {currentPastDueReminders.length > 0 &&
                    currentPastDueReminders.map(({ name, slug }) => (
                        <Grid item key={slug}>
                            <ReminderPill after label={name} />
                        </Grid>
                    ))}
            </Grid>
        </MakeDocumentAccordion>
    );
};

RemindersAccordion.propTypes = {
    locked: PropTypes.bool
};

RemindersAccordion.defaultProps = {
    locked: false
};

export default RemindersAccordion;
