import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    deleteProduct,
    getPaginatedProducts
} from '@/modules/dataWrangler/dataWrangler';
import { DeleteIcon } from '@/resources/icons';
import { openDrawer } from '@/state/actions';
import { useToastNotification, useUrlParams } from '@/hooks';

import WarnConfirmDialog from '../../WarnConfirmDialog';
import ActionIconWrapper from '../ActionIconWrapper';

/**
 * Edit Icon for Product with dispatch attached
 * @param {string} id ID of the product to edit.
 */
export const EditProductIcon = ({ id }) => {
    const dispatch = useDispatch();

    const currentProduct = useSelector((state) =>
        state.user?.activeBusiness?.products?.find(
            (element) => element.productId === id
        )
    );

    const handleClick = () => {
        if (currentProduct)
            dispatch(
                openDrawer({
                    id: 'product',
                    product: currentProduct,
                    edit: true
                })
            );
    };

    return (
        <ActionIconWrapper
            action="edit"
            label="Edit"
            onClick={handleClick}
            tooltip="Edit Product"
        />
    );
};

EditProductIcon.propTypes = {
    id: PropTypes.string.isRequired
};

/**
 * Delete Icon for Product with dispatch attached
 * @param {string} id ID of the product to delete.
 */
export const DeleteProductIcon = ({ id, product }) => {
    const [dialogActive, setDialogAction] = useState(false);
    const { triggerToast } = useToastNotification();
    const { getUrlParams } = useUrlParams();

    const handleClick = () => {
        setDialogAction(true);
    };

    const handleConfirmation = async () => {
        const currentUrlParams = getUrlParams();
        setDialogAction(false);
        await deleteProduct(id);
        getPaginatedProducts({ type: 'product', args: currentUrlParams });
        triggerToast({
            message: `"${product}" Deleted`,
            action: 'delete',
            icon: <DeleteIcon />
        });
    };

    const handleCancellation = () => {
        setDialogAction(false);
    };

    return (
        <>
            <ActionIconWrapper
                action="delete"
                label="Delete"
                onClick={() => {
                    handleClick();
                }}
                tooltip="Delete Product"
            />
            <WarnConfirmDialog
                onCancel={() => handleCancellation()}
                onConfirm={() => handleConfirmation()}
                message={`Are you sure you want to delete "${product}"?`}
                cancelText="Cancel"
                confirmText="Delete Product"
                open={dialogActive}
            />
        </>
    );
};

DeleteProductIcon.propTypes = {
    id: PropTypes.string.isRequired,
    product: PropTypes.string.isRequired
};
