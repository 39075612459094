/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';
import { Typography } from '@mui/material';
import ButtonLoading from '../ButtonLoading';
import useStyles from './styles';

/**
 * Pure component that creates a styled 'error' or 'warning' button. Think
 * red and scary to stop a user from deleting their account.
 * @param {object} buttonProps Any props passed through to the button.
 * @param {string} children Text to show within the button.
 * @param {object} className Custom classes to pass to the button.
 * @param {string} color button color override.
 * @param {bool} loading If needed, show the loader component on the button
 */
const WarningButton = ({ children, className, color, loading, ...rest }) => {
    const classes = useStyles();
    return (
        <ButtonLoading
            {...rest}
            className={className}
            style={color ? { backgroundColor: color } : {}}
            classes={{
                root: classes.buttonRoot
            }}
            loading={loading}
        >
            <Typography className={classes.buttonText}>{children}</Typography>
        </ButtonLoading>
    );
};

WarningButton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    className: PropTypes.string,
    color: PropTypes.string,
    rest: PropTypes.object,
    loading: PropTypes.bool
};

WarningButton.defaultProps = {
    children: '',
    className: '',
    color: null,
    rest: {},
    loading: false
};

export default WarningButton;
