import React, { useState } from 'react';
import { Tooltip as MaterialTooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { InfoIcon } from '@/resources/icons';
import makeStyles from './styles';

const TooltipRef = React.forwardRef(
    ({ showTooltip, content, handleOnClick, ...props }, ref) => {
        const classes = makeStyles(showTooltip);

        return (
            <div
                {...props} // eslint-disable-line
                ref={ref}
                data-testid="tooltip"
                onClick={handleOnClick}
                role="presentation"
            >
                {content || (
                    <div className={classes.tooltipIcon}>
                        <InfoIcon />
                    </div>
                )}
            </div>
        );
    }
);

TooltipRef.propTypes = {
    showTooltip: PropTypes.bool,
    content: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
    handleOnClick: PropTypes.func.isRequired
};

TooltipRef.defaultProps = {
    showTooltip: false,
    content: null
};

const Tooltip = ({
    children,
    placement,
    trueChildren,
    title,
    hideTooltip,
    classSettings,
    disableArrow,
    disableClickableTooltip
}) => {
    const showTooltipState = useSelector(
        (state) => state?.user?.settings?.showToolTips
    );
    const showTooltip = !hideTooltip && showTooltipState;
    const classes = makeStyles(showTooltip);

    const [open, setOpen] = useState(false);

    const toolTipText = trueChildren ? title : children;

    const handleOnClick = () => {
        if (!disableClickableTooltip) {
            setOpen(!open);
        }
    };

    return (
        <MaterialTooltip
            arrow={!disableArrow}
            classes={{
                tooltip: classes.commonTooltipContent,
                arrow: classes.tooltipArrow,
                ...classSettings
            }}
            placement={placement}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            title={
                typeof toolTipText === 'string' ? (
                    <Typography
                        variant="h4"
                        component="p"
                        style={{ fontWeight: 500 }}
                    >
                        {toolTipText}
                    </Typography>
                ) : (
                    toolTipText
                )
            }
        >
            <TooltipRef
                content={trueChildren ? children : null}
                showTooltip={showTooltip}
                handleOnClick={handleOnClick}
            />
        </MaterialTooltip>
    );
};

Tooltip.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
        .isRequired,
    placement: PropTypes.string,
    trueChildren: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    hideTooltip: PropTypes.bool,
    classSettings: PropTypes.object,
    disableArrow: PropTypes.bool,
    disableClickableTooltip: PropTypes.bool
};

Tooltip.defaultProps = {
    placement: 'bottom',
    trueChildren: false,
    title: '',
    hideTooltip: false,
    classSettings: {},
    disableArrow: false,
    disableClickableTooltip: false
};

export default Tooltip;
