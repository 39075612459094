// Data to set the head of the table.
const tableHead = [
    {
        id: 'name',
        label: 'SERVICE NAME',
        sort: true
    },
    {
        id: 'quantity',
        label: 'HOURS',
        sort: true
    },
    {
        id: 'price',
        label: 'SERVICE RATE',
        sort: true
    },
    {
        id: 'tags',
        label: 'TAGS'
    },
    {
        id: 'actions',
        label: '',
        colSpan: 2
    }
];

export default tableHead;
