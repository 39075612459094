import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    creditCardIcon: {
        width: theme.typography.pxToRem(24),
        height: theme.typography.pxToRem(24),
        marginRight: theme.typography.pxToRem(10)
    },
    cardHeader: {
        display: 'flex',
        alignItems: 'center'
    },
    cardAligner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    cardContainer: {
        display: 'flex'
    },
    methodTypography: {
        textTransform: 'capitalize'
    }
}));
