import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, useTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import {
    ThemeProvider,
    StyledEngineProvider,
    createTheme
} from '@mui/material/styles';
import {
    useStripe,
    useElements,
    CardElement,
    PaymentRequestButtonElement
} from '@stripe/react-stripe-js';
import { useForm, FormProvider } from 'react-hook-form';

import { PAYMENT_ERROR, PAYMENT_SUCCESS } from '@/constants';
import { TextField } from '@/components/rhf-mui';
import { StripeLogo } from '@/resources/images';
import { emailRegexPattern, formatCurrency, toWholeCurrency } from '@/util';
import mainTheme from '@/theme';

import { AddressInputBlock } from '@/components/common/AddressInputBlock';
import ButtonLoading from '@/components/common/ButtonLoading';
import CloseButton from '@/components/common/CloseButton';
import DividerWithText from '@/components/common/DividerWithText';
import Link from '@/components/common/Link';

import formTheme from './form.theme';
import useStyles from './styles';

const PaymentIntentModal = ({
    address,
    amountToPay,
    currency,
    locale,
    name,
    email,
    open,
    referenceNumber,
    setPaymentErrorMessage,
    setPaymentStage,
    showInAppPayments,
    stripeIntentSecret
}) => {
    const classes = useStyles();
    const stripe = useStripe();
    const elements = useElements();
    const theme = useTheme();

    const overrideTheme = createTheme(deepmerge(mainTheme, formTheme));

    const [paymentRequest, setPaymentRequest] = useState(null);
    const [cardError, setCardError] = useState('');

    const methods = useForm({
        validateCriteriaMode: 'all',
        mode: 'onChange',
        defaultValues: {
            name,
            email,
            address: {
                line1: '',
                line2: '',
                country: 'US',
                city: '',
                state: '',
                postalCode: '',
                ...address
            }
        }
    });

    const { handleSubmit, setValue, watch } = methods;

    const [paymentPending, setPaymentPending] = useState(false);

    const handleCardChange = (event) => {
        // Listen for changes in the CardElement and display any
        // errors as the customer types their card details.
        setCardError(event.error ? event.error.message : '');
    };

    const handleConfirmCardPayment = useCallback(
        (result) => {
            setPaymentPending(false);
            // Handle validation card error
            if (result?.error?.type === 'validation_error') {
                handleCardChange(result);
                return false;
            }

            if (
                result?.error?.message &&
                result?.error?.type !== 'validation_error'
            ) {
                setPaymentStage(PAYMENT_ERROR);
                setPaymentErrorMessage(result?.error?.message);
                return false;
            }

            setPaymentStage(PAYMENT_SUCCESS);
            return true;
        },
        [setPaymentErrorMessage, setPaymentStage]
    );

    useEffect(() => {
        let unmounted = false;

        if (stripe) {
            const pr = stripe.paymentRequest({
                country: address.country,
                currency: currency.toLowerCase(),
                total: {
                    label: referenceNumber,
                    amount: amountToPay
                },
                requestPayerName: true,
                requestPayerEmail: true
            });

            // Check the availability of the Payment Request API.
            pr.canMakePayment().then((result) => {
                if (result && !unmounted) {
                    if (stripeIntentSecret) {
                        pr.on('paymentmethod', async (ev) => {
                            const ccpResult = await stripe.confirmCardPayment(
                                stripeIntentSecret,
                                { payment_method: ev.paymentMethod.id },
                                { handleActions: false }
                            );

                            ev.complete(ccpResult.error ? 'fail' : 'success');

                            handleConfirmCardPayment(ccpResult);
                        });
                    }
                    setPaymentRequest(pr);
                }
            });
        }
        return () => {
            unmounted = true;
        };
    }, [
        address.country,
        currency,
        stripe,
        amountToPay,
        referenceNumber,
        handleConfirmCardPayment,
        stripeIntentSecret
    ]);

    const CARD_OPTIONS = {
        iconStyle: 'solid',
        style: {
            base: {
                iconColor: '#c4f0ff',
                color: theme.palette.text.primary,
                fontWeight: 500,
                fontFamily: 'Gilroy, Roboto, Helvetica, Arial, sans-serif',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                ':-webkitAutofill': {
                    color: '#fce883'
                },
                '::placeholder': {
                    color: theme.palette.text.primary
                }
            },
            invalid: {
                iconColor: theme.palette.error.main,
                color: theme.palette.error.main
            }
        }
    };

    const onSubmit = async (event) => {
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.0
            return false;
        }

        setPaymentPending(true);

        const result = await stripe.confirmCardPayment(stripeIntentSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    name: event.name,
                    email: event.email,
                    address: {
                        line1: event.address.line1,
                        line2: event.address.line2,
                        city: event.address.city,
                        country: event.address.country,
                        postal_code: event.address.postalCode,
                        state: event.address.state
                    }
                }
            }
        });

        return handleConfirmCardPayment(result);
    };

    const handleAddress = (newAddress) => setValue('address', newAddress);

    return (
        <Dialog
            open={open}
            aria-labelledby="payment-input-modal-id"
            classes={{ paper: classes.paper, container: classes.muiContainer }}
        >
            <CloseButton
                handleClose={() => {
                    setPaymentStage(null);
                }}
            />

            <div className={classes.mainWrap}>
                <h2 className={classes.heading} id="payment-input-modal-id">
                    Pay via Credit Card
                </h2>
                <h3 className={classes.subHeading}>
                    Shipping & Billing Information
                </h3>

                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={classes.form}>
                            <StyledEngineProvider injectFirst>
                                <ThemeProvider theme={overrideTheme}>
                                    <div>
                                        <TextField
                                            fullWidth
                                            name="name"
                                            InputProps={{
                                                classes: {
                                                    root: classes.input,
                                                    input: classes.input
                                                }
                                            }}
                                            label="Name"
                                            rules={{
                                                required: 'A name is required'
                                            }}
                                            placeholder="Jenny Rosen"
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            fullWidth
                                            name="email"
                                            label="Email"
                                            InputProps={{
                                                classes: {
                                                    root: classes.input,
                                                    input: classes.input
                                                }
                                            }}
                                            rules={{
                                                required:
                                                    'An email is required',
                                                pattern: {
                                                    value: emailRegexPattern,
                                                    message:
                                                        'Must be a valid email'
                                                }
                                            }}
                                            placeholder="jenny@example.com"
                                        />
                                    </div>
                                    <AddressInputBlock
                                        address={watch('address')}
                                        allowFreeForm={false}
                                        setAddress={handleAddress}
                                    />
                                </ThemeProvider>
                            </StyledEngineProvider>
                        </div>
                        <h3 className={classes.subHeading}>
                            Payment Information
                        </h3>
                        <div className={classes.form}>
                            <div className={classes.stripeWrap}>
                                <CardElement
                                    options={CARD_OPTIONS}
                                    onChange={(event) =>
                                        handleCardChange(event)
                                    }
                                    id="stripe-card-element"
                                />
                            </div>
                        </div>
                        {cardError && (
                            <div className={classes.cardError} role="alert">
                                {cardError}
                            </div>
                        )}
                        <ButtonLoading
                            fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                            className={classes.button}
                            disableOnLoading
                            loading={paymentPending}
                            disabled={!stripe}
                        >
                            <>
                                Pay{' '}
                                {formatCurrency({
                                    amount: toWholeCurrency(amountToPay),
                                    currency,
                                    locale
                                })}
                            </>
                        </ButtonLoading>
                        {paymentRequest && showInAppPayments && (
                            <>
                                <DividerWithText>Or Pay With</DividerWithText>
                                <PaymentRequestButtonElement
                                    options={{ paymentRequest }}
                                />
                            </>
                        )}
                    </form>
                </FormProvider>
            </div>

            <div className={classes.footer}>
                <Link className={classes.link} to="https://stripe.com/gb">
                    Powered by <StripeLogo className={classes.stripeLogo} />
                </Link>
                <div className={classes.linkSplit} />
                <Link
                    className={classes.link}
                    to="https://invoicemaker.com/policies/terms-of-use/"
                >
                    Terms
                </Link>
                <Link
                    className={classes.link}
                    to="https://invoicemaker.com/policies/privacy-policy/"
                >
                    Privacy
                </Link>
            </div>
        </Dialog>
    );
};

PaymentIntentModal.propTypes = {
    amountToPay: PropTypes.number.isRequired,
    address: PropTypes.shape({
        city: PropTypes.string,
        country: PropTypes.string,
        line1: PropTypes.string,
        line2: PropTypes.string,
        postalCode: PropTypes.string,
        state: PropTypes.string
    }),
    currency: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    locale: PropTypes.string,
    open: PropTypes.bool,
    referenceNumber: PropTypes.string,
    setPaymentErrorMessage: PropTypes.func.isRequired,
    setPaymentStage: PropTypes.func.isRequired,
    showInAppPayments: PropTypes.bool,
    stripeIntentSecret: PropTypes.string
};

PaymentIntentModal.defaultProps = {
    address: {
        city: '',
        country: 'US',
        line1: '',
        line2: '',
        postalCode: '',
        state: ''
    },
    currency: 'USD',
    email: '',
    name: '',
    locale: 'en-US',
    open: false,
    referenceNumber: '',
    showInAppPayments: false,
    stripeIntentSecret: ''
};

export default PaymentIntentModal;
