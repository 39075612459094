import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@mui/material';

import useStyles from './styles';

const FormHeader = ({ title, subtitle }) => {
    const classes = useStyles();

    return (
        <Grid item>
            <Typography variant="h1" className={classes.formHeader}>
                {title}
            </Typography>
            <Typography variant="body2">{subtitle}</Typography>
        </Grid>
    );
};

FormHeader.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string
};

FormHeader.defaultProps = { title: '', subtitle: '' };

export default FormHeader;
