import React from 'react';
import { Grid, Typography } from '@mui/material';
import useStyles from '../styles';

const ZelleUploadInstructions = () => {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="column"
            className={classes.merchantInstructionsListContainer}
        >
            <Grid item>
                <Typography variant="body2" color="textSecondary">
                    Clients can pay you by scanning your Zelle QR code attached
                    to your invoice. Please refer to your banking application or
                    website to display the Zelle QR code you would like to use.
                </Typography>
            </Grid>

            <Grid item>
                <Typography variant="body2" color="textSecondary">
                    Once you have the code, follow these steps to upload your
                    payment code from Zelle:
                </Typography>
            </Grid>
            <ol className={classes.instructionList}>
                <Typography
                    component="li"
                    variant="body2"
                    color="textSecondary"
                    className={classes.uploadInstructions}
                >
                    Click Connect from this screen.
                </Typography>
                <Typography
                    component="li"
                    variant="body2"
                    color="textSecondary"
                    className={classes.uploadInstructions}
                >
                    Upload your payment image to this page. If the file moves
                    from your iPhone to mac and is saved as a .heic, you will
                    need to convert the image to .jpeg
                </Typography>
            </ol>

            <Grid item>
                <Typography variant="body2" color="textSecondary">
                    {`*note - to convert an image to .jpeg from .heic, navigate to the file in your finder, click more, and click "convert image".`}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default ZelleUploadInstructions;
