import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import { settingsMenuItems } from '@/config/menuItems';
import { TabPanel } from '@/components/common';
import useStyles from './styles';
import CompanyProfile from './CompanyProfile';
import DocumentDefaults from './DocumentDefaults';
import Subscription from './Subscription';
import PaymentMethods from './PaymentMethods';
import NotRegisteredBlocker from './NotRegisteredBlocker';

const SettingsRoute = ({
    activeBusiness,
    registeredUser,
    pathname,
    userSettings
}) => {
    const classes = useStyles();
    const isSubscriptionPage = /subscription/.test(pathname);

    return !registeredUser && !isSubscriptionPage ? (
        <Paper variant="outlined" className={classes.blockerContainer}>
            <NotRegisteredBlocker
                activeView={pathname.replace('/settings/', '')}
            />
        </Paper>
    ) : (
        <>
            <TabPanel currentTab={pathname} id={settingsMenuItems[0].path}>
                <CompanyProfile
                    activeBusiness={activeBusiness}
                    userSettings={userSettings}
                />
            </TabPanel>
            <TabPanel currentTab={pathname} id={settingsMenuItems[1].path}>
                <PaymentMethods activeBusiness={activeBusiness} />
            </TabPanel>
            <TabPanel currentTab={pathname} id={settingsMenuItems[2].path}>
                <DocumentDefaults />
            </TabPanel>
            <TabPanel currentTab={pathname} id={settingsMenuItems[3].path}>
                <Subscription />
            </TabPanel>
        </>
    );
};

SettingsRoute.propTypes = {
    activeBusiness: PropTypes.object,
    registeredUser: PropTypes.bool,
    pathname: PropTypes.string,
    userSettings: PropTypes.object
};

SettingsRoute.defaultProps = {
    activeBusiness: {},
    registeredUser: false,
    pathname: undefined,
    userSettings: {}
};

export default SettingsRoute;
