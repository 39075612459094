import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2';
import AutocompleteTextField from '@/components/rhf-mui/AutocompleteTextField';
import ItemLabel from '../ItemLabel';
import makeStyles from './styles';

const ClientInput = () => {
    const classes = makeStyles();

    const clientList =
        useSelector((state) => state.user?.activeBusiness?.clients) || [];

    return (
        <Grid item xs={12}>
            <ItemLabel>Client</ItemLabel>
            <AutocompleteTextField
                options={clientList}
                optionLabels={['name', 'companyName']}
                name="client"
                label="Search Client"
                fullWidth
                className={classes.clientInput}
                controlWithUrlParams
                urlParamOptions={{
                    targetParam: 'client',
                    inputValueSelectionParameter: 'name',
                    submitValueSelectionParameter: 'clientId'
                }}
                allowSearchableSecondaryLabel
            />
        </Grid>
    );
};

export default ClientInput;
