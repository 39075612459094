import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { GenericDatePicker } from '@/components/rhf-mui';
import { getCurrentDateString } from '@/util';
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '../Divider';
import ItemLabel from '../ItemLabel';
import makeStyles from './styles';

const Date = ({ disableFuture, heading, id }) => {
    const classes = makeStyles();

    const businessCreationDate = useSelector(
        (state) => state.user?.activeBusiness?.createdAt
    );
    const currentDate = getCurrentDateString();

    return (
        <Grid item container direction="column">
            <ItemLabel>{heading}</ItemLabel>
            <Grid item container spacing={4} className={classes.wrap}>
                <Grid item xs={6}>
                    <GenericDatePicker
                        name={`${id}[min]`}
                        label="Start"
                        minDate={businessCreationDate || currentDate}
                        disableFuture={disableFuture}
                    />
                </Grid>
                <Divider />
                <Grid item xs={6}>
                    <GenericDatePicker
                        name={`${id}[max]`}
                        label="End"
                        minDate={businessCreationDate || currentDate}
                        disableFuture={disableFuture}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

Date.propTypes = {
    disableFuture: PropTypes.bool,
    heading: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
};

Date.defaultProps = {
    disableFuture: false
};

export default Date;
