import React from 'react';

import PropTypes from 'prop-types';
import useStyles from './styles';

/**
 * Creates label to only be shown in mobile table items.
 * @param {string} label The label for the item
 */
const TableMobileLabel = ({ label }) => {
    const classes = useStyles();
    return <span className={classes.span}>{label}</span>;
};

TableMobileLabel.propTypes = {
    label: PropTypes.string.isRequired
};

export default TableMobileLabel;
