import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Radio, TextField } from '@mui/material';

import { VALUE_PERCENTAGE_TYPE, VALUE_FLAT_RATE_TYPE } from '@/constants';
import { useCurrency } from '@/hooks';
import { forcePositiveNumberInput, isPercent } from '@/util';
import { getInputProps } from '@/components/common/ValueModifier';

import RadioIcon from './RadioIcon';
import makeStyles from './styles';

const DiscountValueModifier = ({
    error,
    helperText,
    id,
    inputRef,
    onBlur,
    onUpdate,
    showAdornment,
    title,
    type,
    value
}) => {
    const classes = makeStyles();

    const handleOnChange = ({ target }) =>
        onUpdate({
            value,
            type: target.value
        });

    const { symbol, position } = useCurrency({ isInvoice: true });

    let radioSymbol = symbol;
    if (radioSymbol.length > 1) {
        radioSymbol = '$';
    }

    return (
        <>
            <Grid
                container
                item
                md={2}
                sm={3}
                xs={4}
                className={classes.radioContainer}
            >
                <Grid item xs={12}>
                    <Radio
                        checked={isPercent(type)}
                        onChange={handleOnChange}
                        value={VALUE_PERCENTAGE_TYPE}
                        name={`${id}-value-modifier-radio-button`}
                        id={`${id}-percent-radio-button`}
                        inputProps={{ 'aria-label': 'Percent' }}
                        icon={<RadioIcon>Percent (%)</RadioIcon>}
                        checkedIcon={<RadioIcon checked>Percent (%)</RadioIcon>}
                        className={classes.radioButtonTop}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Radio
                        checked={!isPercent(type)}
                        onChange={handleOnChange}
                        value={VALUE_FLAT_RATE_TYPE}
                        name={`${id}-value-modifier-radio-button`}
                        id={`${id}-flat-radio-button`}
                        inputProps={{ 'aria-label': 'Flat Rate' }}
                        icon={<RadioIcon>{`Flat (${radioSymbol})`}</RadioIcon>}
                        checkedIcon={
                            <RadioIcon checked>
                                {`Flat (${radioSymbol})`}
                            </RadioIcon>
                        }
                        className={classes.radioButtonBottom}
                    />
                </Grid>
            </Grid>
            <Grid item md={3} sm={7} xs={6} className={classes.valueField}>
                <TextField
                    id={`${id}-value-input`}
                    fullWidth
                    error={error}
                    helperText={helperText}
                    value={value || ''}
                    className={classes.inputField}
                    label={title}
                    name="value"
                    InputProps={
                        showAdornment
                            ? getInputProps(symbol, position, type)
                            : undefined
                    }
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{
                        type: 'number',
                        step: '0.01',
                        min: '0'
                    }}
                    onChange={(e) =>
                        onUpdate({
                            value: e.target.value
                                ? parseFloat(e.target.value)
                                : '',
                            type
                        })
                    }
                    onKeyDown={forcePositiveNumberInput}
                    onBlur={onBlur}
                    inputRef={inputRef}
                    placeholder="0.00"
                />
            </Grid>
        </>
    );
};

DiscountValueModifier.defaultProps = {
    error: false,
    helperText: '',
    inputRef: null,
    onBlur: () => {},
    showAdornment: true,
    value: ''
};

DiscountValueModifier.propTypes = {
    error: PropTypes.bool,
    helperText: PropTypes.string,
    id: PropTypes.string.isRequired,
    inputRef: PropTypes.object,
    onBlur: PropTypes.func,
    onUpdate: PropTypes.func.isRequired,
    showAdornment: PropTypes.bool,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default DiscountValueModifier;
