import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { Grid } from '@mui/material';
import { useDrawers } from '@/hooks';
import { INVOICE_BASE_PARAM } from '@/constants';
import {
    ClientInput,
    Date,
    FilterForm,
    Price,
    StatusCheckbox,
    Tags
} from '../formComponents';

const InvoiceFilterDrawer = () => {
    const { isDrawerActive } = useDrawers();
    const drawerOpen = isDrawerActive('invoiceFilter');
    const { clientId: isClientPage } = useParams();

    const documentStates = ['paid', 'unpaid', 'void', 'draft'];

    const defaultValues = {
        status: {
            paid: false,
            unpaid: false,
            void: false,
            draft: false
        },
        client: '',
        total: {
            min: '',
            max: ''
        },
        dueDate: {
            min: '',
            max: ''
        },
        issuedDate: {
            min: '',
            max: ''
        },
        tags: []
    };

    return (
        <FilterForm
            defaultValues={defaultValues}
            id="FilterInvoices"
            open={drawerOpen}
            paramBase={INVOICE_BASE_PARAM}
            title="Invoices"
            type="invoice"
        >
            <Grid item>
                <StatusCheckbox documentStates={documentStates} />
                {!isClientPage && <ClientInput />}
                <Price
                    heading="Price"
                    id="total"
                    maxTitle="Max"
                    minTitle="Min"
                />
                <Date heading="Due Date" id="dueDate" />
                <Date heading="Created Date" id="issuedDate" disableFuture />
                <Tags heading="Tags" id="tags" />
            </Grid>
        </FilterForm>
    );
};

export default InvoiceFilterDrawer;
