import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, Grid } from '@mui/material';
import { useLocalization } from '@/hooks';

import PostalCode from '../PostalCode';
import State from '../State';
import AddressAutofill from '../../AddressAutofill';
import CountrySelector from '../../CountrySelector';

const PureAddressBlock = ({ address, addressFormat, setAddress, disabled }) => {
    const [customAddressError, setCustomAddressError] = useState('');

    const handleAddressAutofill = (selectedAddress) => {
        const newAddress = {
            line1: selectedAddress.primary_line,
            city: selectedAddress.city,
            state: selectedAddress.state,
            postalCode: selectedAddress.zip_code
        };

        setAddress({ ...address, ...newAddress });
    };

    const { countries } = useLocalization();

    const handleCountrySelect = (value) => {
        let { addressFormat: selectedAddressFormat } = countries.find(
            ({ code }) => value === code
        );
        selectedAddressFormat = Number(selectedAddressFormat);

        let updatedAddress;

        if (selectedAddressFormat === 0) {
            updatedAddress = {
                ...address,
                line1: '',
                line2: '',
                city: '',
                state: '',
                postalCode: '',
                country: value
            };
        }

        if (selectedAddressFormat === 1 || selectedAddressFormat === 3) {
            updatedAddress = {
                ...address,
                country: value,
                customAddress: ''
            };
        }

        if (selectedAddressFormat === 2) {
            updatedAddress = {
                ...address,
                state: '',
                country: value,
                customAddress: ''
            };
        }

        setAddress(updatedAddress);
    };

    const handleCustomAddressChange = (e) => {
        const newAddress = e.target.value;
        const isValidNumOfRows = newAddress.split(/\r\n|\r|\n/).length <= 5;
        if (isValidNumOfRows) {
            setCustomAddressError('');
            setAddress({
                ...address,
                customAddress: newAddress
            });
        } else {
            setCustomAddressError('Custom address must be five lines or less');
        }
    };

    const showCustomAddress = addressFormat === 0;
    const showSetAddress = addressFormat !== 0;
    const showState = addressFormat === 1 || addressFormat === 3;

    return (
        <>
            <Grid item xs={12} sm={12}>
                <CountrySelector
                    country={address.country}
                    setCountry={handleCountrySelect}
                    disabled={disabled}
                    key={address.country}
                />
            </Grid>

            {showCustomAddress && (
                <Grid item xs={12}>
                    <TextField
                        multiline
                        id="customAddress"
                        name="address.customAddress"
                        label="Address"
                        fullWidth
                        minRows={5}
                        value={address.customAddress}
                        onChange={handleCustomAddressChange}
                        onBlur={handleCustomAddressChange}
                        disabled={disabled}
                        error={Boolean(customAddressError)}
                        helperText={customAddressError}
                    />
                </Grid>
            )}

            {showSetAddress && (
                <>
                    <Grid item xs={12}>
                        <AddressAutofill
                            country={address?.country}
                            onAddressSelect={handleAddressAutofill}
                            id="line1"
                            name="address.line1"
                            label="Address 1"
                            initialValue={address?.line1}
                            updatedValue={(value) => {
                                setAddress({
                                    ...address,
                                    line1: value
                                });
                            }}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="line2"
                            name="address.line2"
                            label="Address 2"
                            fullWidth
                            value={address.line2}
                            onChange={(e) => {
                                setAddress({
                                    ...address,
                                    line2: e.target.value
                                });
                            }}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="city"
                            name="address.city"
                            label="City"
                            fullWidth
                            value={address.city}
                            onChange={(e) => {
                                setAddress({
                                    ...address,
                                    city: e.target.value
                                });
                            }}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item container spacing={2}>
                        {showState && (
                            <Grid item xs={6}>
                                <State
                                    addressFormat={addressFormat}
                                    id="state"
                                    name="address.state"
                                    fullWidth
                                    value={address.state}
                                    onChange={(e) => {
                                        setAddress({
                                            ...address,
                                            state: e.target.value
                                        });
                                    }}
                                    disabled={disabled}
                                />
                            </Grid>
                        )}
                        <Grid item xs={showState ? 6 : 12}>
                            <PostalCode
                                addressFormat={addressFormat}
                                id="postalCode"
                                fullWidth
                                name="address.postalCode"
                                value={address.postalCode}
                                onChange={(e) => {
                                    setAddress({
                                        ...address,
                                        postalCode: e.target.value
                                    });
                                }}
                                disabled={disabled}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

PureAddressBlock.propTypes = {
    address: PropTypes.shape({
        city: PropTypes.string,
        country: PropTypes.string.isRequired,
        customAddress: PropTypes.string,
        line1: PropTypes.string,
        line2: PropTypes.string,
        postalCode: PropTypes.string,
        state: PropTypes.string
    }),
    addressFormat: PropTypes.number,
    disabled: PropTypes.bool,
    setAddress: PropTypes.func.isRequired
};

PureAddressBlock.defaultProps = {
    address: {
        city: '',
        customAddress: '',
        line1: '',
        line2: '',
        postalCode: '',
        state: ''
    },
    addressFormat: 1,
    disabled: false
};

export default PureAddressBlock;
