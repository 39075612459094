import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import makeStyles from './styles';

const PaymentTypeTag = ({ type }) => {
    const classes = makeStyles({ type });

    const getTypeLabel = () => {
        switch (type) {
            case 'payment':
                return 'Payment';
            case 'declined':
                return 'Declined';
            default:
                return 'Refund';
        }
    };

    return (
        <div className={classes.paymentTypeTag}>
            <Typography variant="body2">{getTypeLabel()}</Typography>
        </div>
    );
};

PaymentTypeTag.propTypes = {
    type: PropTypes.string.isRequired
};

export default PaymentTypeTag;
