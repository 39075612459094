import { useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { delayAwait } from '@/util';
import { useCheckHasBusinessLoaded } from './useCheckHasBusinessLoaded';

/**
 * @param {string} type - The specific document type to load. e.g. 'invoices'
 * @returns  {Object}
 * @property {Array} items - The items to display.
 * @property {Boolean} loadingItems - Whether the items are loading.
 * @property {Boolean} loadingPaper - Whether the paper is loading. This will be longer on first load to handle initial loading animation.
 * @property {Number} totalPages - The total number of pages.
 * @property {Boolean} hasData - Whether there is data to display.
 * @property {Boolean} zeroResultsReturned - Whether the filters have returned zero results.
 */
export const useListingState = (type) => {
    const previousItems = useRef(null);
    const previousTotalItems = useRef(null);
    const previousTotalPages = useRef(null);

    const [firstRenderComplete, setFirstRenderComplete] = useState(
        previousTotalItems.current !== null
    );

    const hasBusinessLoaded = useCheckHasBusinessLoaded();

    const listingData = useSelector(
        (state) => state.listing?.[type],
        shallowEqual
    );

    if (!listingData) return {};

    const {
        items,
        totalPages,
        loading,
        totalItems,
        typeExists = true
    } = listingData || {
        items: [],
        totalPages: 0,
        loading: false,
        totalItems: 0,
        typeExists: false
    };

    const handlePaperLoading = async () => {
        const startTime = new Date().getTime();
        // Add 500ms delay to stop so much animation glitch on those speedy pages!
        await delayAwait(startTime, 500);
        setFirstRenderComplete(true);
    };

    // If we have loaded the business, and the document type is the same as the current document, then we can start the loading animation.
    if (!loading && hasBusinessLoaded && typeExists && !firstRenderComplete) {
        handlePaperLoading(setFirstRenderComplete);
    }

    const loadingPaper =
        !hasBusinessLoaded || !typeExists || !firstRenderComplete;

    if (!loadingPaper && !loading) {
        previousItems.current = items;
        previousTotalItems.current = totalItems;
        previousTotalPages.current = totalPages;
    }

    const hasData =
        (totalItems > 0 || previousTotalItems.current > 0) && typeExists;

    let itemsToReturn;
    if (items?.length > 0) {
        // If we have fresh documents, return them.
        itemsToReturn = items;
    } else if (previousItems?.current?.length > 0) {
        // Otherwise, if we have old documents - return them whilst loading the new ones. Stops the page glitching.
        itemsToReturn = previousItems?.current;
    } else {
        // Otherwise - nothing
        itemsToReturn = [];
    }

    // If we have data but nothing to return, filters have returned zero results
    const zeroResultsReturned = hasData && itemsToReturn.length === 0;

    return {
        items: itemsToReturn,
        loadingItems: loading || loadingPaper,
        loadingPaper,
        totalPages: totalPages || previousTotalPages?.current,
        hasData,
        zeroResultsReturned
    };
};
