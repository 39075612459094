import React from 'react';
import { ButtonBase } from '@mui/material';
import { ClosePillIcon } from '@/resources/icons';
import PropTypes from 'prop-types';
import useStyles from './styles';

const Close = ({ name, color, onClick }) => {
    const classes = useStyles({ color });

    return (
        <ButtonBase
            component="button"
            className={classes.close}
            onClick={onClick}
        >
            <span className={classes.closeLabel}>{`Remove ${name}`}</span>
            <ClosePillIcon className={classes.closeX} />
        </ButtonBase>
    );
};

Close.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

const TagPill = ({ name, color, backgroundColor, onClick }) => {
    const classes = useStyles({
        color,
        backgroundColor
    });

    return (
        <div className={classes.pill}>
            <Close name={name} color={color} onClick={onClick} />
            <span className={classes.name}>{name}</span>
        </div>
    );
};

TagPill.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

export default TagPill;
