import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    pill: ({ color, backgroundColor }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: 12,
        margin: `0 ${theme.typography.pxToRem(5)} 
        ${theme.typography.pxToRem(5)} 0`,
        borderRadius: 20,
        color,
        backgroundColor,
        width: 'fit-content',
        height: 'fit-content',
        border: backgroundColor === '#FFFFFF' && '1px solid #E0E5EE'
    }),
    name: {
        display: 'flex',
        alignItems: 'center',
        wordBreak: 'break-all',
        fontSize: theme.typography.pxToRem(14),
        margin: `${theme.typography.pxToRem(5)} 0`,
        minHeight: 24,
        lineHeight: '1rem'
    },
    close: {
        borderRadius: '50%',
        padding: 9,
        marginLeft: 3
    },
    closeLabel: theme.visuallyHidden,
    closeX: ({ color }) => ({
        stroke: color,
        height: 10,
        width: 10
    })
}));
