/* eslint import/no-cycle: "off"  */
import store from '@/state/store';

import { globalPageCount } from '@/config';

import {
    addProduct as addProductAction,
    deleteProduct as deleteProductAction,
    fetchListingItems,
    fetchProducts as fetchProductsAction,
    noListingItems,
    overwriteListingItems,
    overwriteProducts as overwriteProductsAction,
    updateProduct as updateProductAction
} from '@/state/actions';
import { convertTemporaryUserToUnregisteredUser } from '@/modules/setup';
import { filterArgFlattener } from '@/util';
import axios from '../authenticatedRequestor';

export const overwriteProducts = async () => {
    store.dispatch(fetchProductsAction());

    const { activeBusiness } = store.getState().user;
    const { businessId } = activeBusiness;

    const items = [];

    let morePages = true;
    let pageCount = 1;

    while (morePages) {
        // This returns 100 items as default.
        // eslint-disable-next-line no-await-in-loop
        const response = await axios.get(`/businesses/${businessId}/products`, {
            params: { pageNumber: pageCount }
        });

        const products =
            response?.data?.items?.map((product) => ({
                ...product,
                discounts: product.discounts || []
            })) || [];

        items.push(...products);

        pageCount += 1;

        // There are more pages if totalPages is larger than current page count.
        // Minus one page so it will fail if the same or smaller correctly.
        morePages =
            response.data.totalItems / response.data.pageSize > pageCount - 1;
    }

    store.dispatch(overwriteProductsAction(items));

    return items;
};

export const addProduct = async (product) => {
    if (store.getState().user.temporaryUser) {
        await convertTemporaryUserToUnregisteredUser();
    }

    const { businessId } = store.getState().user.activeBusiness;

    const response = await axios.post(
        `/businesses/${businessId}/products`,
        product
    );

    store.dispatch(addProductAction(response.data));

    return response.data;
};

export const getProductById = async (productId) => {
    const { businessId } = store.getState().user.activeBusiness;

    const response = await axios.get(
        `/businesses/${businessId}/products/${productId}`
    );

    return response.data;
};

export const updateProduct = async (updatedProduct) => {
    const { businessId } = store.getState().user.activeBusiness;

    const response = await axios.put(
        `/businesses/${businessId}/products/${updatedProduct.productId}`,
        updatedProduct
    );

    // Tags are sent to the backend differently then they are stored on the object
    const { tagIds, ...product } = updatedProduct;
    store.dispatch(
        updateProductAction({
            ...product,
            tags: response?.data?.tags
        })
    );
    return {
        ...product,
        tags: response?.data?.tags
    };
};

export const deleteProduct = async (productId) => {
    const { businessId } = store.getState().user.activeBusiness;

    await axios.delete(`/businesses/${businessId}/products/${productId}`);

    store.dispatch(deleteProductAction(productId));
};

export const getPaginatedProducts = async ({ args, type }) => {
    if (store.getState().user.temporaryUser) {
        store.dispatch(noListingItems({ type }));
        return;
    }

    store.dispatch(fetchListingItems({ type }));

    const { businessId } = store.getState().user.activeBusiness;

    const allArgs = {
        pageSize: globalPageCount,
        type,
        ...filterArgFlattener(args)
    };

    const response = await axios.get(`/businesses/${businessId}/products`, {
        params: {
            sortOrder: 'desc',
            sortType: 'createdAt',
            ...allArgs
        }
    });

    const { items, itemsMatchingRequest, pageSize, totalItems } = response.data;

    const totalPages = Math.ceil(itemsMatchingRequest / pageSize);

    store.dispatch(
        overwriteListingItems({ type, items, totalPages, totalItems })
    );
};
