import React from 'react';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '@/state/actions';

import { CheckmarkOutlined } from '@/resources/icons';

import useStyles from './styles';

// USED FOR ZELLE/VENMO/CASHAPP PAYMENTS
const InvoiceMarkAsPaid = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const markedPaidByClientMethod = useSelector(
        (state) => state?.makeInvoice?.markedPaidByClientMethod
    );

    const handleOnClick = async () => {
        dispatch(
            setModal({
                slug: 'process-invoice-payment-options',
                preselectPaymentMethod: markedPaidByClientMethod
            })
        );
    };

    return (
        <Button
            fullWidth
            className={classes.button}
            variant="contained"
            color="secondary"
            size="medium"
            onClick={handleOnClick}
            startIcon={<CheckmarkOutlined className={classes.svg} />}
        >
            Received
        </Button>
    );
};

export default InvoiceMarkAsPaid;
