import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { App } from '@capacitor/app';

/**
 * This hook checks if the user is on a webapp, and if so
 * grabs the URI endpoints and converts into the internal
 * app routing.
 *
 * @link https://capacitorjs.com/docs/guides/deep-links#react
 */
export const useDeepLinkListener = () => {
    const navigate = useNavigate();
    useEffect(() => {
        App.addListener('appUrlOpen', (data) => {
            // Example url: https://app.invoicemaker.com/tabs/tab2
            // slug = /tabs/tab2
            // Note that the split needs to include "app.invoicemaker" due to email redirects
            // that also include ".com" or "invoicemaker.com" in the slug
            const slug = data.url.split('app.invoicemaker.com').pop();

            if (slug) {
                navigate(slug);
            }
            // If no match, do nothing - let regular routing
            // logic take over
        });
    }, [navigate]);

    return null;
};
