import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '@/util/formatDateTime';
import { addDaysToDate } from '@/util/dateHelpers';
import {
    Grid,
    Paper,
    Button,
    Typography,
    FormControl,
    InputLabel,
    Radio,
    RadioGroup,
    Select,
    MenuItem,
    TextField
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import GenericDatePicker from '@/components/common/GenericDatePicker';
import { RecurringIcon, UpgradeIcon } from '@/resources/icons';
import { ctaClicked as segmentCtaClicked } from '@/modules/segment/segmentManager';
import Divider from '@/components/common/Divider';
import { DisabledInputField } from '@/components/common';
import { setRecurringDetails } from '@/state/actions';
import { forcePositiveNumberInput } from '@/util';
import useStyles from './styles';

const ErrorMessaging = ({ errors }) => {
    const classes = useStyles();

    const hasErrors = errors?.length > 0;

    return (
        <ul role="alert" className={classes.errorListUlStyle}>
            {hasErrors &&
                errors.map((err) => (
                    <li className={classes.errorListLiStyle} key={err}>
                        {err}
                    </li>
                ))}
        </ul>
    );
};

ErrorMessaging.propTypes = {
    errors: PropTypes.arrayOf(PropTypes.string)
};

ErrorMessaging.defaultProps = {
    errors: []
};

const RecurringBlock = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const recurringReduxSelector = (state) => ({
        recurringInterval: state.makeInvoice.recurringInterval,
        recurringIntervalUnit: state.makeInvoice.recurringIntervalUnit,
        firstSendDate: state.makeInvoice.firstSendDate,
        lastSendDate: state.makeInvoice.lastSendDate,
        repeats: state.makeInvoice.repeats,
        termInterval: state.makeInvoice.termInterval,
        termIntervalUnit: state.makeInvoice.termIntervalUnit
    });

    const recurringData = useSelector(recurringReduxSelector);

    const recurringErrorsReduxSelector = (state) => ({
        firstSendDate: state.makeInvoice.errors?.firstSendDate,
        lastSendDate: state.makeInvoice.errors?.lastSendDate,
        recurringInterval: state.makeInvoice.errors?.recurringInterval,
        recurringIntervalUnit: state.makeInvoice.errors?.recurringIntervalUnit,
        termInterval: state.makeInvoice.errors?.termInterval,
        termIntervalUnit: state.makeInvoice.errors?.termIntervalUnit
    });

    const recurringErrorsData = useSelector(recurringErrorsReduxSelector);

    // Turn object of errors into array of values
    const recurringErrorArray = Object.values(recurringErrorsData || {}).filter(
        (value) => value !== undefined
    );

    const [radio, setRadio] = useState('never');

    useEffect(() => {
        if (recurringData?.lastSendDate) {
            setRadio('on_date');
        } else {
            setRadio('never');
        }
    }, [recurringData?.lastSendDate]);

    const handleRadioChange = (value) => {
        setRadio(value);

        if (value === 'never') {
            dispatch(
                setRecurringDetails({
                    ...recurringData,
                    repeats: null,
                    lastSendDate: null
                })
            );
        } else {
            dispatch(
                setRecurringDetails({
                    ...recurringData,
                    lastSendDate: new Date(
                        addDaysToDate(
                            recurringData?.firstSendDate ||
                                new Date().toISOString(),
                            1
                        )
                    ).toISOString()
                })
            );
        }
    };

    // Update if need to show upgrade button.
    const recurringEnabled = true;

    const navigate = useNavigate();

    const recurringTypes = ['day', 'month'];

    return (
        <Paper elevation={0} variant="outlined" className={classes.container}>
            <Grid
                container
                item
                alignItems="center"
                className={classes.innerPadding}
            >
                <Grid item container xs={12} direction="row" rowSpacing={1}>
                    <Grid
                        container
                        item
                        direction="row"
                        alignItems="center"
                        xs={12}
                        sm={4}
                    >
                        <RecurringIcon className={classes.iconHeight} />
                        <Typography component="h5" className={classes.title}>
                            Recurring Invoice
                        </Typography>
                    </Grid>
                    {!recurringEnabled && (
                        <Grid item xs={12} sm container direction="row-reverse">
                            <Button
                                classes={{ root: classes.upgradeButton }}
                                onClick={() => {
                                    segmentCtaClicked({
                                        destination: '/settings/subscription',
                                        text: 'Upgrade to Pro',
                                        location: 'Reminders Accordion',
                                        type: 'button',
                                        url: window.location.href
                                    });

                                    navigate(`/settings/subscription`);
                                }}
                                startIcon={
                                    <UpgradeIcon
                                        className={classes.iconHeight}
                                    />
                                }
                            >
                                Upgrade to Pro
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Divider />
            <Grid
                container
                item
                className={classes.innerPadding}
                spacing={2}
                alignItems="start"
            >
                {/** Date Block */}
                <Grid
                    container
                    item
                    xs={12}
                    sm={5}
                    lg={2}
                    className={classes.innerPaddingDate}
                >
                    <Typography component="h6" className={classes.subtitle}>
                        Starts
                    </Typography>
                    <GenericDatePicker
                        label="Start"
                        id="recurring-invoice-start-date"
                        className={classes.inputField}
                        selectionReturn={(value) =>
                            dispatch(
                                setRecurringDetails({
                                    ...recurringData,
                                    firstSendDate: new Date(value).toISOString()
                                })
                            )
                        }
                        margin="none"
                        value={
                            recurringData?.firstSendDate ||
                            new Date().toISOString()
                        }
                        disablePast
                        helperText={recurringErrorsData?.firstSendDate}
                    />
                </Grid>
                <Grid container item xs={12} sm={7} lg={3}>
                    <Typography component="h6" className={classes.subtitle}>
                        Ends
                    </Typography>
                    <Grid
                        container
                        item
                        direction="row"
                        alignItems="start"
                        spacing={2}
                    >
                        <Grid
                            container
                            item
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            xs={12}
                            sm={3}
                            lg={5}
                        >
                            <RadioGroup
                                aria-label="Recurring ends"
                                name="endType"
                                value={radio}
                                classes={{ root: classes.radioGroup }}
                                onChange={(e) => {
                                    handleRadioChange(e.target.value);
                                }}
                            >
                                <FormControlLabel
                                    value="never"
                                    control={
                                        <Radio
                                            color="primary"
                                            variant="filled"
                                            className={classes.radioInput1}
                                        />
                                    }
                                    label="Never"
                                    classes={{ label: classes.radioLabel }}
                                    aria-label="Never"
                                />
                                <FormControlLabel
                                    value="on_date"
                                    control={
                                        <Radio
                                            color="primary"
                                            variant="filled"
                                            className={classes.radioInput2}
                                        />
                                    }
                                    label="On"
                                    classes={{ label: classes.radioLabel }}
                                />
                            </RadioGroup>
                        </Grid>
                        <Grid
                            container
                            item
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            xs={12}
                            sm={8}
                            md={7}
                        >
                            {radio === 'never' ? (
                                <DisabledInputField>Never</DisabledInputField>
                            ) : (
                                <GenericDatePicker
                                    label="End"
                                    id="recurring-invoice-end-date"
                                    className={classes.inputField}
                                    value={recurringData?.lastSendDate}
                                    minDate={new Date(
                                        addDaysToDate(
                                            recurringData?.firstSendDate ||
                                                new Date().toISOString(),
                                            1
                                        )
                                    ).toISOString()}
                                    selectionReturn={(value) =>
                                        dispatch(
                                            setRecurringDetails({
                                                ...recurringData,
                                                lastSendDate: new Date(
                                                    value
                                                ).toISOString()
                                            })
                                        )
                                    }
                                    margin="none"
                                    disablePast
                                    error={Boolean(
                                        recurringErrorsData?.lastSendDate
                                    )}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {/**   Invoice Recurrence */}
                <Grid
                    container
                    item
                    xs={12}
                    sm={4}
                    lg={3}
                    className={classes.innerPaddingSub}
                >
                    <Typography component="h6" className={classes.subtitle}>
                        Repeats every
                    </Typography>
                    <Grid
                        container
                        item
                        direction="row"
                        spacing={2}
                        alignItems="start"
                    >
                        <Grid container item direction="row" xs={5}>
                            <TextField
                                id="recurring-interval"
                                fullWidth
                                value={recurringData?.recurringInterval || ''}
                                className={classes.inputField}
                                label="Unit"
                                name="recurrance-interval-unit"
                                inputProps={{
                                    type: 'number',
                                    step: '1',
                                    min: '1'
                                }}
                                onChange={(e) =>
                                    dispatch(
                                        setRecurringDetails({
                                            ...recurringData,
                                            recurringInterval: e.target.value
                                                ? parseFloat(
                                                      e.target.value
                                                  ).toFixed(0)
                                                : null
                                        })
                                    )
                                }
                                onKeyDown={forcePositiveNumberInput}
                                error={Boolean(
                                    recurringErrorsData?.recurringInterval
                                )}
                            />
                        </Grid>
                        <Grid container item direction="row" xs={7}>
                            <FormControl
                                fullWidth
                                variant="filled"
                                margin="none"
                                error={Boolean(
                                    recurringErrorsData?.recurringIntervalUnit
                                )}
                            >
                                <InputLabel
                                    id="invoice-recurrence-type"
                                    className={classes.hiddenLabel}
                                >
                                    Type
                                </InputLabel>
                                <Select
                                    disableUnderline
                                    displayEmpty
                                    labelId="invoice-recurrence-type"
                                    value={
                                        recurringData?.recurringIntervalUnit ||
                                        recurringTypes[0]
                                    }
                                    className={classes.inputPadding}
                                >
                                    {recurringTypes.map((value) => (
                                        <MenuItem
                                            onClick={() => {
                                                dispatch(
                                                    setRecurringDetails({
                                                        ...recurringData,
                                                        recurringIntervalUnit:
                                                            value
                                                    })
                                                );
                                            }}
                                            value={value}
                                            key={value}
                                        >
                                            {value.charAt(0).toUpperCase() +
                                                value.slice(1)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                {/** Terms */}
                <Grid
                    container
                    item
                    xs={12}
                    sm={4}
                    lg={3}
                    className={classes.innerPaddingSub}
                >
                    <Typography component="h6" className={classes.subtitle}>
                        Payment Terms (Due Date)
                    </Typography>
                    <Grid
                        container
                        item
                        direction="row"
                        spacing={2}
                        alignItems="start"
                    >
                        <Grid container item direction="row" xs={5}>
                            <TextField
                                id="terms-interval"
                                fullWidth
                                value={recurringData?.termInterval || ''}
                                className={classes.inputField}
                                label="Unit"
                                name="terms-interval-unit"
                                inputProps={{
                                    type: 'number',
                                    step: '1',
                                    min: '1'
                                }}
                                onChange={(e) =>
                                    dispatch(
                                        setRecurringDetails({
                                            ...recurringData,
                                            termInterval: e.target.value
                                                ? parseFloat(
                                                      e.target.value
                                                  ).toFixed(0)
                                                : null
                                        })
                                    )
                                }
                                onKeyDown={forcePositiveNumberInput}
                                error={Boolean(
                                    recurringErrorsData?.termInterval
                                )}
                            />
                        </Grid>
                        <Grid container item direction="row" xs={7}>
                            <FormControl
                                fullWidth
                                variant="filled"
                                margin="none"
                                error={Boolean(
                                    recurringErrorsData?.termIntervalUnit
                                )}
                            >
                                <InputLabel
                                    id="invoice-terms-type"
                                    className={classes.hiddenLabel}
                                >
                                    Type
                                </InputLabel>
                                <Select
                                    disableUnderline
                                    displayEmpty
                                    className={classes.inputPadding}
                                    labelId="invoice-terms-type"
                                    value={
                                        recurringData?.termIntervalUnit ||
                                        recurringTypes[0]
                                    }
                                >
                                    {recurringTypes.map((value) => (
                                        <MenuItem
                                            onClick={() => {
                                                dispatch(
                                                    setRecurringDetails({
                                                        ...recurringData,
                                                        termIntervalUnit: value
                                                    })
                                                );
                                            }}
                                            value={value}
                                            key={value}
                                        >
                                            {value.charAt(0).toUpperCase() +
                                                value.slice(1)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ErrorMessaging errors={recurringErrorArray} />
        </Paper>
    );
};

export const RecurringBlockLocked = ({ locale, timezone }) => {
    const classes = useStyles();

    const recurringReduxSelector = (state) => ({
        recurringInterval: state.makeInvoice.recurringInterval,
        recurringIntervalUnit: state.makeInvoice.recurringIntervalUnit,
        firstSendDate: state.makeInvoice.firstSendDate,
        lastSendDate: state.makeInvoice.lastSendDate,
        nextSendDate: state.makeInvoice.nextSendDate,
        repeats: state.makeInvoice.repeats,
        termInterval: state.makeInvoice.termInterval,
        termIntervalUnit: state.makeInvoice.termIntervalUnit,
        stoppedDate: state.makeInvoice.stoppedDate
    });

    const recurringData = useSelector(recurringReduxSelector);

    const recurrenceSentence = `Every ${
        recurringData.recurringInterval > 1
            ? `${recurringData.recurringInterval} `
            : ''
    }${recurringData.recurringIntervalUnit}${
        recurringData.recurringInterval > 1 ? 's' : ''
    }`;

    const endSentence = recurringData.lastSendDate
        ? formatDate({
              isoDate: recurringData.lastSendDate,
              locale,
              timezone
          })
        : 'Never';

    const cancelledSentence = recurringData?.stoppedDate && (
        <span style={{ color: '#B91C1C', fontWeight: 600 }}>
            Stopped{' '}
            {formatDate({
                isoDate: recurringData?.stoppedDate,
                locale,
                timezone
            })}
        </span>
    );

    return (
        <Paper elevation={0} variant="outlined" className={classes.container}>
            <Grid
                container
                item
                alignItems="center"
                className={classes.innerPadding}
            >
                <Grid item container xs={12} direction="row" rowSpacing={1}>
                    <Grid
                        container
                        item
                        direction="row"
                        alignItems="center"
                        xs={12}
                        sm={4}
                    >
                        <RecurringIcon className={classes.iconHeight} />
                        <Typography component="h5" className={classes.title}>
                            Recurring Invoice
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Divider />
            <Grid
                container
                item
                className={classes.innerPaddingLocked}
                spacing={1}
            >
                <Grid container item xs={12} sm="auto">
                    <Typography
                        component="h6"
                        className={classes.lockedSubtitle}
                    >
                        Invoice Recurrence
                    </Typography>
                    <Grid container item direction="row" xs={12} sm={6}>
                        <Typography
                            component="p"
                            className={classes.lockedValue}
                        >
                            {recurrenceSentence}
                        </Typography>
                    </Grid>
                </Grid>
                {/** Date Block */}
                <Grid container item xs={6} sm="auto">
                    <Typography
                        component="h6"
                        className={classes.lockedSubtitle}
                    >
                        Start/End
                    </Typography>

                    <Grid container item direction="row">
                        <Typography
                            component="p"
                            className={classes.lockedValue}
                        >
                            {formatDate({
                                isoDate: recurringData.firstSendDate,
                                locale,
                                timezone
                            })}{' '}
                            - {cancelledSentence || endSentence}
                        </Typography>
                    </Grid>
                </Grid>
                {!recurringData?.stoppedDate && (
                    <Grid container item xs={6} sm="auto">
                        <Typography
                            component="h6"
                            className={classes.lockedSubtitle}
                        >
                            Next Send Date
                        </Typography>

                        <Grid container item direction="row">
                            <Typography
                                component="p"
                                className={classes.lockedValue}
                            >
                                {formatDate({
                                    isoDate: recurringData.nextSendDate,
                                    locale,
                                    timezone
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
};

RecurringBlockLocked.propTypes = {
    locale: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired
};

export default RecurringBlock;
