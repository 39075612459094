import {
    AuthenticationDetails,
    CognitoUserPool,
    CognitoUser,
    CognitoUserSession,
    CognitoAccessToken,
    CognitoRefreshToken,
    CognitoIdToken
} from 'amazon-cognito-identity-js';

import { USER_POOL_DATA } from '@/config';

/**
 * Helpful docs to save you Googling for the amazon-cognito-identity-js package.
 * Link: https://www.npmjs.com/package/amazon-cognito-identity-js
 */

/**
 * Create AWS user pool.
 * @return {function} User pool.
 */
export const getUserPool = () => new CognitoUserPool(USER_POOL_DATA);

/**
 * Create a new cognito user with session and set as signed in.
 * @param {string} idToken Token passed back by cognito endpoint.
 * @param {string} refreshToken Token passed back by cognito endpoint.
 * @param {string} accessToken Token passed back by cognito endpoint.
 * @return {object} New user session
 */
export const createNewCognitoUserSession = (
    idToken,
    refreshToken,
    accessToken
) => {
    const cognitoSession = new CognitoUserSession({
        IdToken: new CognitoIdToken({ IdToken: idToken }),
        RefreshToken: new CognitoRefreshToken({
            RefreshToken: refreshToken
        }),
        AccessToken: new CognitoAccessToken({
            AccessToken: accessToken
        })
    });

    // idToken.payload.sub is used as it follows the format of the standard
    // cognito login with ID structure. Sub never changes and removes the need
    // for personal data in local storage.
    // example: https://stackoverflow.com/questions/55484977/aws-amplify-currentauthenticateduser-is-null-after-fb-login-in-react-native-but
    const cognitoUser = new CognitoUser({
        Username: cognitoSession.idToken.payload.sub,
        Pool: getUserPool()
    });

    cognitoUser.setSignInUserSession(cognitoSession);

    return cognitoSession;
};

/**
 * Resend a confirmation code to a user
 * @param {string} Username The username to resend the confirmation code to
 */
export const resendConfirmationCode = (Username) => {
    const user = new CognitoUser({
        Pool: getUserPool(),
        Username
    });

    user.resendConfirmationCode(() => {});
};

/**
 * Create a new password for a user with a temporary password. This user will
 * have been generated by an admin account. Probably used when migrating data.
 * @param {string} password Users password.
 * @param {object} userAttr Object created by newPasswordRequired within
 * loginWithUsernameAndPassword function.
 * @param {object} userSession Object created by CognitoUser within
 * loginWithUsernameAndPassword function.
 * @return {promise} Success(jwt string)/failure.
 */
export const updateTemporaryPassword = (password, userAttr, userSession) =>
    new Promise((resolve, reject) => {
        // User was signed up by an admin and must provide new
        // password and required attributes, to complete
        // authentication.

        // The api doesn't accept these fields.
        /* eslint-disable no-param-reassign */
        delete userAttr.email_verified;
        delete userAttr.phone_number_verified;

        // https://stackoverflow.com/questions/71667989/aws-cognito-respond-to-new-password-required-challenge-returns-cannot-modify-an
        delete userAttr.email;

        // Create new password for user.
        userSession.completeNewPasswordChallenge(password, userAttr, {
            onSuccess: (result) => {
                resolve(result);
            },
            onFailure: (err) => {
                reject(err);
            }
        });
    });

/**
 * Login user with username and password.
 * @param {string} username Users username.
 * @param {string} password Users password.
 * @return {promise} Success/newPasswordRequired/failure.
 */
export const loginWithUsernameAndPassword = (username, password) =>
    new Promise((resolve, reject) => {
        const authenticationDetails = new AuthenticationDetails({
            Username: username,
            Password: password
        });

        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: getUserPool()
        });

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {
                resolve({ status: 'success', session: result });
            },
            newPasswordRequired: (userAttr) =>
                resolve({
                    status: 'newPasswordRequired',
                    userSession: cognitoUser,
                    userAttr
                }),
            onFailure: (err) => {
                reject(err);
            }
        });
    });

export const beginForgotPassword = (username) =>
    new Promise((resolve, reject) => {
        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: getUserPool()
        });

        cognitoUser.forgotPassword({
            inputVerificationCode: (data) => {
                resolve(data.CodeDeliveryDetails.Destination);
            },
            onFailure: (err) => {
                reject(err);
            }
        });
    });

/**
 * Reset a users password using a verification code.
 * @param {string} code Code provided by AWS.
 * @param {string} username Users username.
 * @param {string} password Users password.
 */
export const resetPasswordWithVerificationCode = (code, username, password) =>
    new Promise((resolve, reject) => {
        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: getUserPool()
        });

        cognitoUser.confirmPassword(code, password, {
            onSuccess: () => resolve(true),
            onFailure: (data) => {
                // TODO: Handle errors.
                reject(data);
            }
        });
    });
