import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    paymentInfoHeader: {
        padding: `${theme.spacing(2)} 0`
    },
    paymentInfoPaper: {
        backgroundColor: '#F8F8FC',
        padding: theme.spacing(2)
    },
    payInfoContainer: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    inAppText: {
        paddingTop: theme.spacing(2)
    },
    payInfoButtonContainer: {
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
}));
