import React from 'react';
import PropTypes from 'prop-types';
import MakeDocument from '../MakeDocument';

const MakeInvoice = ({ isLoading }) => (
    // future logic to be passed down here
    <MakeDocument documentType="invoice" isLoading={isLoading} />
);

MakeInvoice.propTypes = {
    isLoading: PropTypes.bool
};

MakeInvoice.defaultProps = {
    isLoading: false
};

export default MakeInvoice;
