import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';

import TableBody from '@mui/material/TableBody';
import { slugify } from '@/util';
import TableHeader from './TableHeader';
import TableItem from './TableItem';
import useStyles from './styles';

/**
 * Pure component that takes a set of information and easily displays it in a
 * filterable table.
 * @param {array} actionCols Array of numbers that set which columns are actions.
 * @param {string} ariaTableName The name of the table for screen readers.
 * @param {func} handleRequestSort Function which when triggered, sorts the data
 * within the TableMaker component.
 * @param {array} headCells An array of objects that set the ID of a column as
 * a keyhead and a visible label.
 * @param {array} rowData An array of objects that holds all the data for the
 * body of the table. This can be as much as needed, but must be as key pairs.
 * Each object MUST include an unique ID for that row. This will not be shown.
 * @param {string} order Which way the column is ordered - asc or desc.
 * @param {string} orderBy Which column the data is sorted by.
 */
const TableMaker = memo(
    ({
        actionCols,
        ariaTableName,
        handleRequestSort,
        headCells,
        rowData,
        order,
        orderBy
    }) => {
        const classes = useStyles();
        const tableSlug = slugify(ariaTableName);

        return (
            <TableContainer>
                <Table className={classes.table} aria-label={ariaTableName}>
                    <TableHeader
                        headCells={headCells}
                        order={order}
                        orderBy={orderBy}
                        handleRequestSort={handleRequestSort}
                        rowCount={rowData.length}
                    />
                    <TableBody>
                        {rowData.map((row) => (
                            <TableItem
                                actionCols={actionCols}
                                id={row.id.toString()}
                                row={row}
                                tableSlug={tableSlug}
                                key={`${tableSlug}-${row.id}`}
                                to={row.primaryLink}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
);

TableMaker.propTypes = {
    actionCols: PropTypes.instanceOf(Array),
    ariaTableName: PropTypes.string.isRequired,
    handleRequestSort: PropTypes.func.isRequired,
    headCells: PropTypes.instanceOf(Array).isRequired,
    rowData: PropTypes.instanceOf(Array).isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired
};

TableMaker.defaultProps = {
    actionCols: []
};

export default TableMaker;
