import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom-v5-compat';
import { updatedocumentIds, setTemporaryStorage } from '@/state/actions';
import {
    createInvoice,
    updateInvoice
} from '@/modules/dataWrangler/dataWrangler';
import store from '@/state/store';

/**
 * Invoice Hook
 * Saves an invoice, where data is passed into the hook.
 * Handles redirection logic if documents are limited, and
 * creating or updating functionality.
 *
 * Returns false, or clean invoice data.
 */
export const useSaveInvoice = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const saveInvoice = async (invoiceData, action) => {
        const documentId = invoiceData?.documentId || null;

        const subscriptionData =
            store.getState()?.user?.activeBusiness?.subscription;
        const subInfo = subscriptionData?.components?.document;

        const allowedActions = ['download', 'preview', 'save'];
        const restrictedActions = ['send', 'share'];

        const blockAction =
            (subscriptionData?.productId === 'free' &&
                !allowedActions.includes(action)) ||
            (restrictedActions.includes(action) &&
                subscriptionData?.productId === 'free');

        const blockInvoiceCreation =
            !subInfo?.enabled ||
            (subInfo?.limited && subInfo?.amountRemaining < 1);

        const tagIds = invoiceData?.tags?.map((tag) => tag.tagId);

        const saveTempDataAndRedirectToSubscription = () => {
            // Remove product ids to allow easy re-insertion into
            // form. Otherwise product values are not injected.
            const cleanTempData = {
                ...invoiceData,
                products: invoiceData.products.map((prod) => ({
                    ...prod,
                    productId: ''
                }))
            };

            dispatch(
                setTemporaryStorage({
                    component: 'invoice',
                    data: cleanTempData
                })
            );

            navigate('/settings/subscription');
        };

        if (subscriptionData?.productId === 'free' && blockAction) {
            saveTempDataAndRedirectToSubscription();
            return false;
        }

        if (documentId === null) {
            // If the user has no more documents remaining, save current invoice object
            // in Redux and force them to subscription page.
            if (blockInvoiceCreation) {
                saveTempDataAndRedirectToSubscription();
                return false;
            }

            const newInvoiceObject = await createInvoice({
                ...invoiceData,
                tagIds
            });

            if (newInvoiceObject?.documentId) {
                dispatch(
                    updatedocumentIds({
                        referenceNumber: newInvoiceObject.referenceNumber,
                        documentId: newInvoiceObject.documentId,
                        customLabel: newInvoiceObject.customLabel
                    })
                );
            }
            return newInvoiceObject;
        }

        await updateInvoice({ ...invoiceData, tagIds });

        return invoiceData;
    };

    return saveInvoice;
};
