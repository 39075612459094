/* eslint-disable camelcase */
import { useSelector } from 'react-redux';

export const getCountryData = ({ country, countryList }) => {
    if (countryList) {
        const countryData = countryList.find((entry) => entry.code === country);

        if (countryData) {
            return countryData;
        }
    }

    return {
        code: country
    };
};

const getFromObject = (appState) => {
    const user = appState.user || {};
    const businessProfile = user.activeBusiness?.businessProfile || {};
    const address = businessProfile.address || {};
    const { line1, line2, city, state, postalCode, country, customAddress } =
        address;
    const { emailAddress, phoneNumber, website, companyName } = businessProfile;

    const countryList = appState.localization?.countries || [];
    return {
        name: companyName,
        line1,
        line2,
        city,
        state,
        country: getCountryData({ country, countryList }),
        postalCode,
        email: emailAddress,
        phone: phoneNumber,
        website,
        customAddress
    };
};

export const getEditModeFromObject = (appState) => {
    const makeInvoice = appState.makeInvoice || {};
    const makeInvoiceFrom = makeInvoice.from || {};
    const address = makeInvoiceFrom.address || {};

    const { line1, line2, city, state, postalCode, country, customAddress } =
        address;
    const { emailAddress, phoneNumber, website, name } = makeInvoiceFrom;

    let formattedCountry;
    if (makeInvoice.locked) {
        const { country: code, countryName, addressFormat } = address;

        formattedCountry = {
            code,
            name: countryName,
            addressFormat
        };
    } else {
        formattedCountry = getCountryData({
            country,
            countryList: appState.localization?.countries || []
        });
    }

    return {
        name,
        line1,
        line2,
        city,
        state,
        country: formattedCountry,
        postalCode,
        email: emailAddress,
        phone: phoneNumber,
        website,
        customAddress
    };
};

export const getToObject = (appState) => {
    const makeInvoice = appState.makeInvoice || {};
    const makeInvoiceTo = makeInvoice.to || {};
    const address = makeInvoiceTo.billingAddress || {};

    const { line1, line2, city, state, postalCode, country, customAddress } =
        address;

    let formattedCountry;
    if (makeInvoice.locked) {
        const { country: code, countryName, addressFormat } = address;

        formattedCountry = {
            code,
            name: countryName,
            addressFormat
        };
    } else {
        formattedCountry = getCountryData({
            country,
            countryList: appState.localization?.countries || []
        });
    }

    const {
        emailAddress,
        phoneNumber,
        name: clientName,
        companyName
    } = makeInvoiceTo;

    return {
        name: companyName,
        name2: clientName,
        line1,
        line2,
        city,
        state,
        country: formattedCountry,
        postalCode,
        email: emailAddress,
        phone: phoneNumber,
        customAddress
    };
};

export const useToAddressObject = () =>
    useSelector((state) => getToObject(state));

export const useFromAddressObject = (editing) =>
    useSelector((state) =>
        editing ? getEditModeFromObject(state) : getFromObject(state)
    );
