import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { List } from '@mui/material';
import { updateDocumentProducts } from '@/state/actions';
import { useDocumentFormData } from '@/hooks';
import useStyles from './styles';
import LineItem from './Item';

const LineItemEditable = memo(() => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { products } = useDocumentFormData();

    const reorderLineItems = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        // remove at source location and store reference
        const [removed] = result.splice(startIndex, 1);
        // insert at destination without deletion
        result.splice(endIndex, 0, removed);
        return result;
    };

    const handleLineItemDragEnd = (lineResult) => {
        // dropped outside the list
        if (!lineResult.destination) {
            return;
        }

        dispatch(
            updateDocumentProducts(
                reorderLineItems(
                    products,
                    lineResult.source.index,
                    lineResult.destination.index
                )
            )
        );
    };

    return (
        <DragDropContext onDragEnd={handleLineItemDragEnd}>
            <Droppable droppableId="droppable">
                {(droppableProvided) => (
                    <List
                        className={classes.lineItemList}
                        ref={droppableProvided.innerRef}
                    >
                        {products.map((item, index) => (
                            <Draggable
                                key={item.lineId}
                                draggableId={`${item.lineId}`}
                                index={index}
                            >
                                {(draggableProvided, snapshot) => (
                                    <LineItem
                                        item={item}
                                        isDragging={snapshot.isDragging}
                                        dragHandleProps={{
                                            ...draggableProvided.dragHandleProps
                                        }}
                                        listItemProps={{
                                            ...draggableProvided.draggableProps,
                                            ref: draggableProvided.innerRef
                                        }}
                                        allowDeletion={products.length > 1}
                                    />
                                )}
                            </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                    </List>
                )}
            </Droppable>
        </DragDropContext>
    );
});

export default LineItemEditable;
