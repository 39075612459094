import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import ButtonLoading from '@/components/common/ButtonLoading';
import { useSelector } from 'react-redux';
import {
    useCheckAuthState,
    useToastNotification,
    useDocumentFormData,
    useSubscription
} from '@/hooks';
import { useSaveInvoice, useValidateInvoice } from '@/hooks/useSidebarActions';
import { SaveIcon } from '@/resources/icons';
import { documentSaved as segmentDocumentSaved } from '@/modules/segment/segmentManager';

import useStyles from '../../styles';

const InvoiceSave = ({ onNegativeInvoice, onLoginBlocker }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const { loadingUser, registeredUser } = useCheckAuthState();
    const saveInvoice = useSaveInvoice();
    const { documentLoading, total, locked } = useDocumentFormData();
    const validateInvoice = useValidateInvoice();
    const { triggerToast } = useToastNotification();
    const subscriptionData = useSubscription();

    // Used for validation.
    const invoice = useSelector((state) => state?.makeInvoice);

    const subInfo = subscriptionData?.components?.document;
    const blockDocumentCreation =
        !subInfo?.enabled || (subInfo?.limited && subInfo?.amountRemaining < 1);

    const handleOnClick = async () => {
        setLoading(true);
        const invoiceData = await validateInvoice(invoice);

        if (!invoiceData) {
            setLoading(false);
            return;
        }

        if (total < 0) {
            onNegativeInvoice();
            setLoading(false);
            return;
        }

        const completeSaveProcess = async () => {
            await saveInvoice(invoiceData, 'save');

            if (!blockDocumentCreation) {
                triggerToast({
                    message: 'Invoice Saved',
                    action: 'saved',
                    icon: <SaveIcon />
                });
            }

            segmentDocumentSaved(invoiceData);
            setLoading(false);
        };

        // Check is a user is registered, if they are complete the process.
        // If not, see if we can get them to register.
        if (registeredUser) {
            completeSaveProcess();
        } else {
            onLoginBlocker(completeSaveProcess);
        }
    };

    const loadingButton = loadingUser || documentLoading;

    if (!locked) {
        return (
            <div className={classes.section}>
                {!loadingButton ? (
                    <ButtonLoading
                        fullWidth
                        className={classes.button}
                        variant="contained"
                        color="secondary"
                        size="large"
                        endIcon={<SaveIcon className={classes.buttonSaveSvg} />}
                        onClick={handleOnClick}
                        disableOnLoading
                        loading={loading}
                    >
                        Save
                    </ButtonLoading>
                ) : (
                    <Skeleton variant="rectangular" className={classes.sizer} />
                )}
            </div>
        );
    }
    return null;
};

InvoiceSave.propTypes = {
    onNegativeInvoice: PropTypes.func.isRequired,
    onLoginBlocker: PropTypes.func.isRequired
};

export default InvoiceSave;
