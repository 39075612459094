import loadable from '@loadable/component';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom-v5-compat';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
    Skeleton
} from '@mui/material';
import { Capacitor } from '@capacitor/core';
import {
    CouponAppliedBanner,
    SettingsHeader,
    WarnConfirmDialog
} from '@/components/common';
import { prepareTemporaryStorage } from '@/state/actions';
import {
    useCheckAuthState,
    useCouponData,
    useUrlParams,
    useSubscription,
    useSubscriptionProducts,
    useToastNotification
} from '@/hooks';
import {
    updateSubscriptionProduct,
    cancelSubscription,
    undoCancelSubscription
} from '@/modules/subscriptionManager';
import { SubscriptionActive, ChevronGrey } from '@/resources/icons';
import PlanSummary from './PlanSummary';
import BillingSwitch from './BillingSwitch';
import PlanChangeDialog from './PlanChangeDialog';
import InAppPurchases from './InAppPurchases';
import PaymentInfo from './PaymentInfo';
import SubscriptionCard from './SubscriptionCard';
import settingStyles from '../styles';
import CancelSubscriptionButton from './CancelSubscriptionButton';
import ThreeCardSkeleton from './ThreeCardSkeleton';
import styles from './styles';

const SignUpLoginModal = loadable(() => import(`@/components/Login`));

const Subscription = () => {
    const settingsClasses = settingStyles();
    const classes = styles();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        productId: currentPlan,
        chargify,
        subscriptionSource,
        nextRefreshDate,
        userSubscriptionState,
        managementURL,
        revenueCat,
        components
    } = useSubscription();

    const recurringInvoiceEnabled = components?.recurring?.enabled || false;

    const hasRcData = Object.keys(revenueCat || {})?.length > 1;
    const hasChargifyData = Object.keys(chargify || {})?.length > 1;

    const { loading, products } = useSubscriptionProducts();
    const { triggerToast } = useToastNotification();
    const isIos = Capacitor.getPlatform() === 'ios';

    const showCancelButton =
        currentPlan !== 'free' &&
        chargify &&
        !chargify.cancelAtEndOfPeriod &&
        !isIos &&
        subscriptionSource !== 'revenueCat';

    const { productId: chargifyId } = chargify?.product || {};

    const { getUrlParams, setUrlParams } = useUrlParams();
    const param = getUrlParams({ watch: ['coupon', 'ref'] });

    const { couponLoading, couponIsValid, couponData } = useCouponData(
        param?.coupon,
        true
    );
    const { registeredUser } = useCheckAuthState() || {};

    const [plansToShow, setPlansToShow] = useState('monthly');
    const [loginView, setLoginView] = useState('');
    const [selectedPlan, setSelectedPlan] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [expanded, setExpanded] = useState(true);
    const [showMigrateButton, setShowMigrateButton] = useState(false);

    const allLoadings = loading && modalLoading && couponLoading;

    const loginLoaded = useRef('');

    let currentPlanWeight = 0;
    let currentPlanPrice = 0;
    let currentPlanUnitInterval;

    if (currentPlan !== 'free') {
        const plan = products.find(
            (product) => product?.productId === chargifyId
        );

        currentPlanWeight = plan?.rank;
        currentPlanPrice = plan?.price;
        currentPlanUnitInterval =
            plan?.interval === 1 && plan?.intervalUnit === 'month'
                ? 'monthly'
                : 'yearly';
    }

    const switchPlans = () =>
        setPlansToShow((prev) => (prev === 'monthly' ? 'yearly' : 'monthly'));

    const handlePlanChangeModal = (plan) => {
        setSelectedPlan(plan);
        setShowConfirmModal(true);
    };

    useEffect(() => {
        // If the user has been referred from the recurring invoices page
        // and they are on a paid plan
        // and they don't have recurring invoices enabled
        // and they haven't already cancelled their subscription
        // show the plan change modal.
        if (
            !isIos &&
            !allLoadings &&
            param?.ref === 'recurring-invoices' &&
            currentPlan !== 'free' &&
            !chargify?.cancelAtEndOfPeriod &&
            !recurringInvoiceEnabled &&
            subscriptionSource !== 'revenueCat'
        ) {
            // Get the pro plan for the current interval
            const planToOpenModalFor = products.find(
                (product) =>
                    product.productHandle === `pro-${currentPlanUnitInterval}`
            );

            handlePlanChangeModal(planToOpenModalFor);

            // Remove the ref param from the url
            setUrlParams({ ref: null });
        }
    }, [
        isIos,
        param?.ref,
        allLoadings,
        currentPlan,
        chargify?.cancelAtEndOfPeriod,
        recurringInvoiceEnabled,
        products,
        currentPlanUnitInterval,
        subscriptionSource,
        setUrlParams
    ]);

    const isUpgrading = () => {
        if (!selectedPlan) return false;
        return selectedPlan?.rank > currentPlanWeight;
    };

    const handleSelectPlanButtonClick = async (planData, discountedPrice) => {
        if (!registeredUser) {
            setLoginView('login');
            setSelectedPlan(planData);
            return;
        }

        // If the user has an active subscription, or is trialing a subscription
        // Not being assignd means they are on a free plan - not yet gone through the "start trial" flow.
        if (
            userSubscriptionState === 'active' ||
            (userSubscriptionState === 'trialing' &&
                subscriptionSource !== 'unassigned')
        ) {
            setModalLoading(true);
            if (planData.name === 'Free') {
                await cancelSubscription();
                setModalLoading(false);
                setShowConfirmModal(false);
                triggerToast({
                    message: 'Subscription Plan Update Pending',
                    action: 'saved'
                });
                return;
            }

            await updateSubscriptionProduct(planData?.productHandle);

            const message = isUpgrading()
                ? 'Subscription Plan Updated'
                : 'Subscription Plan Update Pending';

            if (chargify?.cancelAtEndOfPeriod) {
                await undoCancelSubscription();
            }

            setModalLoading(false);

            if (showConfirmModal) {
                setShowConfirmModal(false);
            }

            triggerToast({
                message,
                action: 'saved'
            });
        } else if (userSubscriptionState === 'canceled') {
            navigate('/update', {
                state: {
                    ...planData,
                    userSubscriptionState,
                    userCanUpdate: true
                }
            });
        } else {
            navigate('/checkout', {
                state: {
                    ...planData,
                    discountedPrice,
                    couponData
                }
            });
        }
    };

    const handleLoginSuccess = () => {
        setLoginView('');
        if (selectedPlan && selectedPlan.name !== 'Free') {
            navigate('/checkout', {
                state: {
                    ...selectedPlan,
                    redirect: '/subscription'
                }
            });
        }
    };

    useEffect(() => {
        if (loginView && !loginLoaded.current) {
            loginLoaded.current = true;
        }
    }, [loginView]);

    useEffect(() => {
        dispatch(prepareTemporaryStorage());
    }, [dispatch]);

    return (
        <>
            <Grid
                item
                container
                className={settingsClasses.sectionContainerMobile}
                xs={12}
            >
                <SettingsHeader id="subscription-status" label="Plan Details" />

                <Grid
                    item
                    xs={12}
                    sm={5}
                    container
                    justifyContent="flex-end"
                    alignItems="flex-start"
                >
                    {!couponLoading && couponIsValid && couponData && (
                        <CouponAppliedBanner
                            customStyles={classes.couponBanner}
                        />
                    )}
                </Grid>

                <Grid item xs={12}>
                    <Accordion
                        expanded={expanded}
                        onChange={() => setExpanded(!expanded)}
                        className={classes.subscriptionAccordionContainer}
                        disableGutters
                        elevation={0}
                    >
                        <AccordionSummary
                            classes={{
                                expandIconWrapper: classes.expandIconWrapper
                            }}
                            expandIcon={
                                <ChevronGrey className={classes.expandIcon} />
                            }
                        >
                            <PlanSummary currentPlanPrice={currentPlanPrice} />
                        </AccordionSummary>

                        <AccordionDetails
                            className={classes.subscriptionDetailsContainer}
                        >
                            <Grid
                                item
                                container
                                direction="row"
                                xs={12}
                                justifyContent="flex-start"
                                columnSpacing={2}
                            >
                                {!loading ? (
                                    <BillingSwitch
                                        loading={loading}
                                        plansToShow={plansToShow}
                                        switchPlans={switchPlans}
                                    />
                                ) : (
                                    <Skeleton
                                        variant="text"
                                        width="100%"
                                        height={120}
                                        className={
                                            classes.billingSwitchSkeletonContainer
                                        }
                                    />
                                )}

                                {isIos && !hasChargifyData ? (
                                    <InAppPurchases
                                        loading={allLoadings}
                                        plansToShow={plansToShow}
                                        setShowMigrateButton={
                                            setShowMigrateButton
                                        }
                                    />
                                ) : (
                                    !loading &&
                                    products &&
                                    products?.map((plan) => {
                                        const currentPlanType =
                                            plan?.interval === 1 &&
                                            plan?.intervalUnit === 'month'
                                                ? 'monthly'
                                                : 'yearly';

                                        if (
                                            currentPlanType === plansToShow &&
                                            plan.name !== 'Free' &&
                                            plan.name !== 'Lifetime' &&
                                            plan.productHandle !==
                                                'pro-lifetime' &&
                                            plan.productHandle !==
                                                'lite-lifetime' &&
                                            plan.productHandle !==
                                                'plus-lifetime' &&
                                            plan.productHandle !==
                                                'pro-lifetime-appsumo'
                                        ) {
                                            const upgrade =
                                                currentPlanWeight ===
                                                    undefined ||
                                                currentPlanWeight === 0
                                                    ? null
                                                    : plan.rank >
                                                      currentPlanWeight;

                                            return (
                                                <SubscriptionCard
                                                    key={`${plan.name}-${plan.interval}-${plan.intervalUnit}`}
                                                    cancelling={
                                                        chargify?.cancelAtEndOfPeriod
                                                    }
                                                    couponData={couponData}
                                                    couponIsValid={
                                                        couponIsValid
                                                    }
                                                    description={
                                                        plan.description
                                                    }
                                                    hasChargifyData={
                                                        hasChargifyData
                                                    }
                                                    hasRcData={hasRcData}
                                                    isIos={isIos}
                                                    isPending={
                                                        chargify?.cancelAtEndOfPeriod
                                                            ? plan.name ===
                                                              'Free'
                                                            : chargify?.nextProductId ===
                                                              plan?.productId
                                                    }
                                                    isUpgrade={upgrade}
                                                    managementURL={
                                                        managementURL
                                                    }
                                                    planType={currentPlanType}
                                                    price={plan.price}
                                                    product={plan}
                                                    subscriptionSource={
                                                        subscriptionSource
                                                    }
                                                    subscribed={
                                                        (plan?.productId ===
                                                            chargifyId &&
                                                            userSubscriptionState !==
                                                                'canceled') ||
                                                        (currentPlan ===
                                                            'free' &&
                                                            (plan?.productId ===
                                                                0 ||
                                                                plan?.productId ===
                                                                    1))
                                                    }
                                                    title={plan.name}
                                                    showMigrateButton={
                                                        showMigrateButton
                                                    }
                                                    onButtonClick={(
                                                        discountedPrice
                                                    ) =>
                                                        userSubscriptionState ===
                                                        'active'
                                                            ? handlePlanChangeModal(
                                                                  plan
                                                              )
                                                            : handleSelectPlanButtonClick(
                                                                  plan,
                                                                  discountedPrice
                                                              )
                                                    }
                                                />
                                            );
                                        }

                                        return null;
                                    })
                                )}

                                {loading && <ThreeCardSkeleton />}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                {!isIos && subscriptionSource === 'revenueCat' && (
                    <Grid item className={classes.iosSubscribeText}>
                        <Typography variant="h4" component="p">
                            You can check your Invoice Maker subscription status
                            or cancel automatic renewal from the following
                            location on an iOS device:
                        </Typography>
                        <Typography
                            variant="h4"
                            component="p"
                            className={classes.iosSettingsPath}
                        >
                            Settings &gt; Apple ID &gt; Subscriptions
                        </Typography>
                        <Typography variant="h4" component="p">
                            Note: The above location varies based on the version
                            of the operating system that you are running per
                            device. Please contact the Apple App Store if you
                            are in need of further assistance.
                        </Typography>
                    </Grid>
                )}

                <Grid item className={classes.cancelAnytimeText}>
                    <Typography variant="h4" component="p">
                        All pricing in USD. Cancel anytime.
                    </Typography>
                </Grid>

                {showCancelButton && <CancelSubscriptionButton />}
            </Grid>

            {(loginView || loginLoaded.current) && (
                <SignUpLoginModal
                    disableRedirect
                    setLoginView={setLoginView}
                    view={loginView}
                    onSuccess={handleLoginSuccess}
                    onClose={() => setSelectedPlan('')}
                />
            )}

            {!isIos && showConfirmModal && (
                <WarnConfirmDialog
                    onCancel={() => setShowConfirmModal(false)}
                    onConfirm={() => handleSelectPlanButtonClick(selectedPlan)}
                    customIcon={
                        <SubscriptionActive
                            className={classes.confirmModalIcon}
                        />
                    }
                    confirmColor="#357abc"
                    title="Confirm Subscription Changes"
                    message={
                        <PlanChangeDialog
                            currentPlan={
                                chargify || {
                                    name: currentPlan,
                                    priceInCents: 0
                                }
                            }
                            newPlan={selectedPlan}
                            isUpgrading={isUpgrading()}
                        />
                    }
                    cancelText="Cancel"
                    confirmText="Confirm"
                    buttonLoading={modalLoading}
                    open={showConfirmModal}
                />
            )}
            {currentPlan !== 'free' && (
                <PaymentInfo
                    chargify={chargify}
                    nextRefreshDate={nextRefreshDate}
                    subscriptionSource={subscriptionSource}
                />
            )}
        </>
    );
};

export default Subscription;
