import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, useMediaQuery, Skeleton } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useSelector } from 'react-redux';
import { useCheckAuthState, useDocumentFormData } from '@/hooks';
import { useValidateEstimate } from '@/hooks/useSidebarActions';

import useStyles from '../../styles';

const handleOnClick = async ({
    validateEstimate,
    estimate,
    onClick,
    onLoginBlocker,
    registeredUser
}) => {
    const estimateData = await validateEstimate(estimate);

    if (!estimateData) return;

    if (registeredUser) {
        onClick(estimateData);
    } else {
        onLoginBlocker(() => onClick(estimateData));
    }
};

export const EstimateDecline = ({ onLoginBlocker, onClick }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { documentState, documentLoading } = useDocumentFormData();
    const { loadingUser, registeredUser } = useCheckAuthState();
    const mdDown = useMediaQuery(theme.breakpoints.down('xl'));
    const validateEstimate = useValidateEstimate();

    // Used for validation.
    const estimate = useSelector((state) => state?.makeInvoice);

    const declineAvailable =
        !documentLoading &&
        (documentState === 'unapproved' || documentState === 'draft');

    if (declineAvailable) {
        return (
            <div className={classes.section} hidden={mdDown}>
                {!loadingUser ? (
                    <Button
                        fullWidth
                        className={`${classes.button} ${classes.negativeButton}`}
                        variant="outlined"
                        size="large"
                        onClick={() => {
                            handleOnClick({
                                validateEstimate,
                                estimate,
                                onClick,
                                onLoginBlocker,
                                registeredUser
                            });
                        }}
                    >
                        Decline
                    </Button>
                ) : (
                    <Skeleton variant="rectangular" className={classes.sizer} />
                )}
            </div>
        );
    }

    return null;
};

EstimateDecline.propTypes = {
    onLoginBlocker: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired
};

export const EstimateDeclineMobile = ({ onLoginBlocker, onClick }) => {
    const classes = useStyles();
    const { documentState, documentLoading } = useDocumentFormData();
    const { loadingUser, registeredUser } = useCheckAuthState();
    const validateEstimate = useValidateEstimate();

    // Used for validation.
    const estimate = useSelector((state) => state?.makeInvoice);

    const declineAvailable =
        !documentLoading &&
        (documentState === 'unapproved' || documentState === 'draft');

    if (declineAvailable) {
        return (
            <Grid item>
                {!loadingUser ? (
                    <Button
                        fullWidth
                        className={classes.negativePopOverButton}
                        variant="outlined"
                        size="large"
                        onClick={() => {
                            handleOnClick({
                                validateEstimate,
                                estimate,
                                onClick,
                                onLoginBlocker,
                                registeredUser
                            });
                        }}
                        id="estimate-decline-sidebar"
                    >
                        Decline
                    </Button>
                ) : (
                    <Skeleton variant="rectangular" className={classes.sizer} />
                )}
            </Grid>
        );
    }

    return null;
};

EstimateDeclineMobile.propTypes = {
    onLoginBlocker: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired
};
