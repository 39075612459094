import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Grid, useMediaQuery, useTheme, Divider } from '@mui/material';
import Paper from '@mui/material/Paper';
import { CreateTagsImage, ZeroResultsImage } from '@/resources/images';
import {
    TableCard,
    TableMaker,
    TableMobileLabel,
    PageEmptyState,
    Pagination,
    PageTitle,
    SearchInput,
    SEO,
    SortButton,
    WrapWithPaper,
    TableSkeleton
} from '@/components/common';
import { tagsSortMenuItems } from '@/config';
import { TAG_BASE_PARAM } from '@/constants';
import {
    useCheckHasBusinessLoaded,
    useListingState,
    useUrlParams
} from '@/hooks';
import { getPaginatedTags } from '@/modules/dataWrangler/dataWrangler';
import { openDrawer, fetchListingItems } from '@/state/actions';
import { Container } from '@/containers';
import { TagsIconBlue } from '@/resources/icons';
import tableHead from './tableHead';
import { dataProcessor, generateComponents } from './dataProcessors';
import useStyles from '../styles';

const Tags = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const hasBusinessLoaded = useCheckHasBusinessLoaded();
    const { setUrlParams, getUrlParams } = useUrlParams();

    const {
        hasData,
        items,
        loadingItems,
        loadingPaper,
        totalPages,
        zeroResultsReturned
    } = useListingState('tags');

    const classes = useStyles({ hasData });

    const dispatch = useDispatch();

    const currentUrlParams = getUrlParams({ base: TAG_BASE_PARAM });
    const stringifiedParams = JSON.stringify(currentUrlParams);

    const setOrder = (property) => {
        const sortOrder =
            currentUrlParams?.sort?.type === property &&
            currentUrlParams?.sort?.order === 'asc'
                ? 'desc'
                : 'asc';

        const newUrlParams = {
            ...currentUrlParams,
            sort: {
                type: property,
                order: sortOrder
            }
        };

        setUrlParams({ base: TAG_BASE_PARAM, params: newUrlParams });
    };

    useEffect(() => {
        if (hasBusinessLoaded) {
            getPaginatedTags(JSON.parse(stringifiedParams));
        }
    }, [hasBusinessLoaded, stringifiedParams]);

    return (
        <>
            <SEO title="All Tags" />
            <Container>
                {!hasData && !loadingItems && (
                    <PageEmptyState
                        image={<CreateTagsImage />}
                        content="Create Your First Tag"
                        buttonText="Create New Tag"
                        onClick={() => {
                            dispatch(openDrawer('createTags'));
                        }}
                    />
                )}
                {hasData && !loadingPaper && (
                    <>
                        {!isMobile && (
                            <Paper elevation={0} variant="outlined">
                                <Grid
                                    container
                                    direction="row"
                                    spacing={1}
                                    wrap="nowrap"
                                    alignItems="center"
                                >
                                    <Grid item md={2}>
                                        <PageTitle
                                            icon={<TagsIconBlue />}
                                            title="Tags"
                                        />
                                    </Grid>
                                    <Grid item md={4}>
                                        <div className={classes.searchWrap}>
                                            <SearchInput
                                                base={TAG_BASE_PARAM}
                                                id="dropdownSearch"
                                                label="Search"
                                                ariaLabel="Search"
                                            />
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        justifyContent="flex-end"
                                        md={6}
                                        className={classes.newButtonContainer}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={() =>
                                                dispatch(
                                                    openDrawer('createTags')
                                                )
                                            }
                                            className={classes.newButton}
                                        >
                                            New Tag
                                        </Button>
                                    </Grid>
                                </Grid>

                                <Divider />

                                <TableMaker
                                    actionCols={[4, 5]}
                                    ariaTableName="Tags Table"
                                    handleRequestSort={setOrder}
                                    headCells={tableHead}
                                    rowData={
                                        hasData && !zeroResultsReturned
                                            ? generateComponents({
                                                  array: dataProcessor(items),
                                                  classes
                                              })
                                            : []
                                    }
                                    order={
                                        currentUrlParams?.sort?.order || 'asc'
                                    }
                                    orderBy={currentUrlParams?.sort?.type || ''}
                                />
                                {zeroResultsReturned && (
                                    <PageEmptyState
                                        image={<ZeroResultsImage />}
                                        content="The selected filters returned 0 results."
                                        buttonText="Reset filters"
                                        onClick={() => {
                                            dispatch(
                                                fetchListingItems({
                                                    type: 'tags'
                                                })
                                            );
                                            setUrlParams({
                                                base: TAG_BASE_PARAM,
                                                params: null
                                            });
                                        }}
                                        hidePaper
                                    />
                                )}
                                <Pagination
                                    base={TAG_BASE_PARAM}
                                    totalPages={totalPages}
                                />
                            </Paper>
                        )}

                        {isMobile && (
                            <>
                                <Grid
                                    container
                                    direction="row"
                                    spacing={1}
                                    wrap="nowrap"
                                    alignItems="center"
                                >
                                    <Grid item xs={6}>
                                        <PageTitle
                                            icon={<TagsIconBlue />}
                                            title="Tags"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        justifyContent="flex-end"
                                        xs={6}
                                        className={classes.newButtonContainer}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            className={classes.newButton}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                onClick={() =>
                                                    dispatch(
                                                        openDrawer('createTags')
                                                    )
                                                }
                                            >
                                                New Tag
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <div className={classes.searchWrap}>
                                            <SearchInput
                                                base={TAG_BASE_PARAM}
                                                id="dropdownSearch"
                                                label="Search"
                                                ariaLabel="Search"
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="center">
                                    <Grid
                                        item
                                        container
                                        spacing={1}
                                        xs={12}
                                        direction="row"
                                        wrap="nowrap"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid
                                            item
                                            container
                                            xs={12}
                                            className={classes.sortButtonWrap}
                                        >
                                            <SortButton
                                                base={TAG_BASE_PARAM}
                                                isLoading={loadingItems}
                                                sortMenuItems={
                                                    tagsSortMenuItems
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {hasData &&
                                    !zeroResultsReturned &&
                                    generateComponents({
                                        array: dataProcessor(items),
                                        classes,
                                        isMobile
                                    }).map((item) => (
                                        <TableCard
                                            key={item.id}
                                            title={item.tagName}
                                            onClick={item.primaryClick}
                                            left={
                                                <TableMobileLabel label="Amount Used" />
                                            }
                                            full={item.tagAmount}
                                            actions={item.actions}
                                        />
                                    ))}
                                {zeroResultsReturned && (
                                    <PageEmptyState
                                        image={<ZeroResultsImage />}
                                        content="The selected filters returned 0 results."
                                        buttonText="Reset filters"
                                        onClick={() => {
                                            dispatch(
                                                fetchListingItems({
                                                    type: 'tags'
                                                })
                                            );

                                            setUrlParams({
                                                base: TAG_BASE_PARAM,
                                                params: null
                                            });
                                        }}
                                    />
                                )}
                                {totalPages > 1 && (
                                    <Paper elevation={0} variant="outlined">
                                        <Pagination
                                            base={TAG_BASE_PARAM}
                                            totalPages={totalPages}
                                        />
                                    </Paper>
                                )}
                            </>
                        )}
                    </>
                )}
                {loadingPaper && (
                    <WrapWithPaper removeWrap={isMobile}>
                        <TableSkeleton isMobile={isMobile} />
                    </WrapWithPaper>
                )}
            </Container>
        </>
    );
};

export default Tags;
