import { useEffect, useState } from 'react';
import { getDatabaseVersionNumber } from '@/modules/dataWrangler/dataWrangler';
import { getMajorSemverNumber } from '@/util';
import { APP_VERSION } from '../version';

/**
 * Checks if the frontend needs to update to be in sync with the backend.
 * @returns {bool} true if the frontend needs to update.
 */
export const useCheckIfAppNeedUpdate = () => {
    const [updateState, setUpdateState] = useState({
        inMaintenance: false,
        update: false
    });

    // On first render, run query.
    useEffect(() => {
        const versionCheck = async () => {
            const databaseVersion = await getDatabaseVersionNumber();

            if (databaseVersion) {
                // Check if backend is currently updating.
                if (databaseVersion === '0.0.0') {
                    setUpdateState({
                        inMaintenance: true,
                        update: false
                    });
                } else {
                    // Otherwise check if app needs updating.
                    // Split versions into major numbers.
                    const frontendMajorVersion =
                        getMajorSemverNumber(APP_VERSION);

                    const databaseMajorVersion =
                        getMajorSemverNumber(databaseVersion);

                    // Compare and return result.
                    if (frontendMajorVersion < databaseMajorVersion) {
                        setUpdateState({
                            inMaintenance: false,
                            update: true
                        });
                    } else {
                        setUpdateState({
                            inMaintenance: false,
                            update: false
                        });
                    }
                }
            }
        };
        versionCheck();
    }, []);

    return updateState;
};
