export const supportSubjects = [
    'Cancelation',
    'Feature Request',
    'General Inquiry',
    'Pricing Inquiry',
    'Password Recovery Issue',
    'SPAM',
    'Technical Difficulty',
    'Upgrade/Downgrade Subscription',
    'Other'
];
