import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    title: {
        fontWeight: theme.typography.fontWeightMediumBold,
        marginLeft: theme.typography.pxToRem(10),
        color: theme.palette.imBrand.purple,
        fontSize: theme.typography.pxToRem(25),
        letterSpacing: '-0.32px',

        [theme.breakpoints.down(360)]: {
            marginLeft: theme.typography.pxToRem(7)
        }
    },
    appBar: {
        borderBottom: '1px solid #DBDFEA',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: theme.palette.common.white,
        textAlign: 'center',
        padding: theme.spacing(0, 3),
        [theme.breakpoints.only('xs')]: {
            top: 0,
            left: 0,
            paddingTop: 'env(safe-area-inset-top)',
            padding: theme.spacing(0, 1)
        },
        [theme.breakpoints.down('lg')]: {
            zIndex: theme.zIndex.drawer + 1
        },
        [theme.breakpoints.up('xl')]: {
            padding: theme.spacing(0)
        }
    },
    appBarIos: {
        [theme.breakpoints.up('sm')]: {
            height: theme.typography.pxToRem(69)
        },
        [theme.breakpoints.between('sm', 'xl')]: {
            paddingTop: theme.spacing(2.5),
            paddingBottom: theme.spacing(1)
        }
    },
    toolbar: {
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: 1600,
        minHeight: theme.titleBarHeight,
        margin: '0 auto',
        '& a': {
            textDecoration: 'none'
        }
    },
    mobileBarSpacer: {
        display: 'none',
        [theme.breakpoints.only('xs')]: {
            height: theme.titleBarHeight,
            width: '100%',
            display: 'block',
            position: 'relative'
        }
    },
    headerLogoSvg: {
        width: theme.typography.pxToRem(24)
    },
    headerLogo: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.only('xs')]: {
            paddingLeft: theme.typography.pxToRem(10)
        },
        [theme.breakpoints.between('sm', 'lg')]: {
            paddingLeft: theme.typography.pxToRem(20)
        }
    },
    removeClickFocus: {
        '-webkit-tap-highlight-color': 'transparent',
        '&:focus': {
            outline: 'none !important'
        },
        [theme.breakpoints.only('xs')]: {
            transform: 'scale(0.7)',
            marginLeft: '-20px'
        }
    },
    makeInvoiceButton: {
        marginRight: theme.spacing(2),
        fontWeight: 600,

        [theme.breakpoints.only('xs')]: {
            minWidth: 'auto',
            padding: theme.typography.pxToRem(6),
            borderRadius: '50%',
            marginRight: theme.typography.pxToRem(5),
            '& span': {
                margin: 0
            }
        }
    },
    makeInvoiceButtonText: {
        [theme.breakpoints.only('xs')]: theme.visuallyHidden
    },
    burgerLogoGrid: {
        display: 'flex',
        alignItems: 'center'
    },
    AccountButtonContainer: {
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        }
    }
}));
