import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
    wrap: {
        marginBottom: theme.typography.pxToRem(10),
        [theme.breakpoints.up('xl')]: {
            borderTop: '1px solid #DEDEE7',
            marginTop: theme.typography.pxToRem(9),
            marginBottom: 0,
            paddingTop: theme.typography.pxToRem(24),
            width: '100%'
        }
    },
    wrapRecurring: {
        marginBottom: theme.typography.pxToRem(10),
        [theme.breakpoints.up('xl')]: {
            marginTop: 0,
            marginBottom: 0,
            width: '100%'
        }
    },
    section: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('xl')]: {
            justifyContent: 'space-between',
            '&:nth-child(2)': {
                marginTop: theme.typography.pxToRem(24)
            }
        }
    },
    sectionPad: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexShrink: 0,
            marginTop: theme.typography.pxToRem(24),
            width: '100%'
        },
        [theme.breakpoints.up('xl')]: {
            flexShrink: 0,
            marginTop: theme.typography.pxToRem(24),
            width: '100%'
        }
    },
    status: {
        display: 'flex',
        marginRight: theme.typography.pxToRem(20)
    },
    tooltip: {
        marginRight: theme.typography.pxToRem(4)
    },
    label: {
        marginRight: theme.typography.pxToRem(10)
    },
    paidName: {
        display: 'flex',
        alignItems: 'center'
    },
    svgWrap: {
        width: theme.typography.pxToRem(24),
        height: theme.typography.pxToRem(24),
        marginRight: 8,
        '& svg': {
            height: '100%',
            width: '100%'
        }
    },
    container: {
        border: '1px solid rgba(0, 0, 0, 0.12)',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.secondary.main,
        borderRadius: theme.typography.pxToRem(4),
        padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
            15
        )}`,
        [theme.breakpoints.up('xl')]: {
            border: 'none',
            flexDirection: 'column',
            padding: `${theme.typography.pxToRem(
                20
            )} ${theme.typography.pxToRem(15)}`
        }
    }
}));
