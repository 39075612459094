import React, { useEffect } from 'react';
import { Link } from 'react-router-dom-v5-compat';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { ButtonBase } from '@mui/material';
import { getNotificationUnreadCount } from '@/modules/dataWrangler/dataWrangler';

import useStyles from './styles';

const NotificationBell = () => {
    const classes = useStyles();

    const temporaryUser = useSelector((state) => state.user.temporaryUser);

    useEffect(() => {
        const getCount = async () => {
            await getNotificationUnreadCount();
        };

        getCount();
    }, [temporaryUser]);

    const count = useSelector((state) => state.user.notificationCount) || 0;

    return (
        <ButtonBase
            to="/notifications"
            className={classes.buttonBase}
            component={Link}
        >
            <Badge
                color="secondary"
                overlap="circular"
                badgeContent={count > 0 ? count : null}
                className={classes.badge}
                classes={{
                    badge: count > 0 ? classes.dot : classes.noDot
                }}
            >
                <Box component="span" className={classes.circleStyle}>
                    <NotificationsIcon className={classes.bellIcon} />
                </Box>
            </Badge>
        </ButtonBase>
    );
};

export default NotificationBell;
