import React from 'react';
import PropTypes from 'prop-types';
import { ButtonLoading } from '@/components/common';
import useStyles from './styles';

const Button = ({ id, loading }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <ButtonLoading
                id={id}
                className={classes.button}
                color="primary"
                loading={loading}
                disableOnLoading
                fullWidth
                type="submit"
                variant="contained"
            >
                Next
            </ButtonLoading>
        </div>
    );
};

Button.propTypes = {
    id: PropTypes.string,
    loading: PropTypes.bool
};

Button.defaultProps = { id: '', loading: false };

export default Button;
