import React from 'react';
import AutocompleteMultiselect from '@/components/rhf-mui/AutocompleteMultiselect';
import { activityTypes } from '@/config';
import useStyles from './styles';

const ActivityTypeMultiSelect = () => {
    const classes = useStyles();

    // Map over and add color:#fff to each action
    const styledActions = activityTypes.map((action) => {
        const newAction = action;
        newAction.color = '#35373D';
        newAction.backgroundColor = '#fff';
        return newAction;
    });

    return (
        <AutocompleteMultiselect
            fullWidth
            classes={{
                inputRoot: classes.multiselectInput,
                noOptions: classes.listbox,
                listbox: classes.listbox
            }}
            rhfName="activities"
            options={styledActions}
            labelIdentifier="name"
            id="activity-type-multiselect"
            inputLabel="Activity"
        />
    );
};

export default ActivityTypeMultiSelect;
