import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Button, Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { cropLogo, setQrCodeURL, toggleCropperModal } from '@/actions';
import { DocumentIcon } from '@/resources/icons';
import ButtonLoading from '../ButtonLoading';
import useStyles from './styles';

const ImageUploadButton = ({
    deletionPending,
    imageAlt,
    imageSrc,
    label,
    onDelete,
    isLogo,
    isMakeDocumentPage,
    loadQrReader,
    qrMerchant
}) => {
    const classes = useStyles({ isLogo, isMakeDocumentPage });
    const dispatch = useDispatch();

    const onDrop = useCallback(
        (acceptedFiles) => {
            loadQrReader();

            acceptedFiles.forEach((file) => {
                const imageURL = URL.createObjectURL(file);
                if (isLogo || isMakeDocumentPage) {
                    dispatch(cropLogo(imageURL));
                    dispatch(toggleCropperModal(true));
                } else {
                    dispatch(setQrCodeURL(imageURL));
                }
            });
        },
        [dispatch, isLogo, isMakeDocumentPage, loadQrReader]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        multiple: false
    });

    const rootProps = getRootProps({
        container: true,
        direction: 'column',
        className: classes.inner
    });

    const inputProps = getInputProps();

    return (
        <Grid item container spacing={0} direction="column">
            {!isMakeDocumentPage && (isLogo || !imageSrc) && (
                <Grid item>
                    <Typography
                        component="h3"
                        variant="body1"
                        className={classes.label}
                    >
                        {label}
                    </Typography>
                </Grid>
            )}
            <Grid
                item
                className={classes.logoDropper}
                id="logo-dropper"
                {...rootProps} // eslint-disable-line react/jsx-props-no-spreading
            >
                <input
                    {...inputProps} // eslint-disable-line react/jsx-props-no-spreading
                />
                <button
                    type="button"
                    id="logo-dropper-button"
                    className={`${classes.logoDropperButton} ${
                        imageSrc ? classes.removeBorder : ''
                    }`}
                >
                    {!imageSrc ? (
                        <div
                            className={`${classes.logoDropperContent} ${
                                isDragActive ? classes.dropZoneDragOver : ''
                            }`}
                            id="logo-dropper-content"
                        >
                            {!isMakeDocumentPage && (
                                <DocumentIcon
                                    className={classes.documentIcon}
                                />
                            )}
                            <Typography
                                component="p"
                                variant="body2"
                                className={classes.uploadPrompt}
                            >
                                <u>
                                    Click to upload
                                    {isMakeDocumentPage && ' logo'}
                                </u>{' '}
                                or drag and drop.
                            </Typography>
                            <Typography
                                component="p"
                                variant="body2"
                                className={classes.uploadSubPrompt}
                            >
                                Maximum File size 25MB.
                            </Typography>
                        </div>
                    ) : (
                        <img
                            className={classes.logoImage}
                            src={imageSrc}
                            alt={imageAlt}
                            id="logo-dropper-content"
                        />
                    )}
                </button>
            </Grid>
            {!isMakeDocumentPage && imageSrc && (
                <Grid
                    container
                    item
                    direction="row"
                    justifyContent="space-between"
                >
                    <Button
                        className={classes.editButton}
                        onClick={rootProps?.onClick}
                        fullWidth
                    >
                        Edit
                    </Button>
                    <ButtonLoading
                        className={classes.deleteButton}
                        disableOnLoading
                        loading={deletionPending}
                        onClick={onDelete}
                        fullWidth
                    >
                        {isLogo
                            ? 'Delete'
                            : `Remove All ${qrMerchant} Information`}
                    </ButtonLoading>
                </Grid>
            )}
        </Grid>
    );
};

ImageUploadButton.propTypes = {
    deletionPending: PropTypes.bool,
    imageAlt: PropTypes.string,
    imageSrc: PropTypes.string,
    label: PropTypes.string,
    onDelete: PropTypes.func,
    isLogo: PropTypes.bool,
    isMakeDocumentPage: PropTypes.bool,
    onEdit: PropTypes.func,
    loadQrReader: PropTypes.func,
    qrMerchant: PropTypes.string
};

ImageUploadButton.defaultProps = {
    deletionPending: false,
    imageAlt: '',
    imageSrc: '',
    label: '',
    onDelete: () => {},
    isLogo: false,
    isMakeDocumentPage: false,
    onEdit: () => {},
    loadQrReader: () => {},
    qrMerchant: ''
};

export default ImageUploadButton;
