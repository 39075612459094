import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    buttonContainer: ({ hasData }) => ({
        paddingBottom: hasData && theme.typography.pxToRem(60),
        [theme.breakpoints.up('sm')]: {
            paddingBottom: 0
        }
    }),
    newButtonContainer: {
        marginRight: theme.spacing(2)
    },
    newButton: {
        maxWidth: theme.typography.pxToRem(250),
        width: theme.typography.pxToRem(250),
        [theme.breakpoints.up('md')]: {
            marginRight: theme.spacing(2)
        }
    },
    searchContainer: {
        marginBottom: theme.typography.pxToRem(-110),
        [theme.breakpoints.up('sm')]: {
            margin: theme.typography.pxToRem(-4)
        }
    },
    searchWrap: {
        margin: theme.spacing(0.5, 0, 1, 0),
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(1)
        }
    },
    priceSpan: {
        color: theme.palette.actionColors.positive
    },
    valueType: {
        textTransform: 'capitalize'
    },
    filterButtonWrap: {
        [theme.breakpoints.up('md')]: {
            marginRight: theme.spacing(2)
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(1),
            paddingRight: '0px !important'
        }
    },
    sortButtonWrap: {
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(1)
        }
    },
    filterButton: {
        backgroundColor: '#fff',
        border: '1px solid #EDEEF0',
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.typography.pxToRem(131)
        }
    },
    sortMenu: {
        width: 228
    },
    filterIcon: {
        width: 24,
        height: 24
    },
    tagAmountUsedContainer: {
        display: 'flex',
        padding: 0,
        gap: `${theme.spacing(0.5)}`,
        [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing(1)} 0 ${theme.spacing(1)} ${theme.spacing(
                2.5
            )}`
        }
    },
    openDrawerButton: {
        flex: 1,
        cursor: 'pointer',
        backgroundColor: 'inherit',
        border: 0,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    },
    openDrawerSpan: theme.visuallyHidden,
    swatchSpan: {
        display: 'flex',
        alignItems: 'center'
    },
    swatch: {
        marginRight: 5
    },
    cio_message: {
        width: '100%'
    }
}));
