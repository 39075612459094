import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom-v5-compat';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Grid,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { ChevronGrey, AddIcon, UpgradeIcon } from '@/resources/icons';
import { ctaClicked as segmentCtaClicked } from '@/modules/segment/segmentManager';
import useStyles from './styles';

const MakeDocumentAccordion = ({
    buttonName,
    title,
    handleAddButtonClick,
    count,
    icon,
    hideAddButton,
    children,
    upgrade
}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const classes = useStyles({ title });
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [expanded, setExpanded] = useState(false);
    const hasContent = count > 0;

    return (
        <Accordion
            expanded={hasContent && expanded}
            onChange={() => setExpanded(!expanded)}
            className={`${classes.accordionContainer} ${
                hasContent ? '' : classes.noContentPointer
            }`}
            elevation={0}
        >
            <AccordionSummary
                expandIcon={
                    <ChevronGrey
                        className={`${
                            hasContent
                                ? classes.expandIcon
                                : classes.noContentExpandIcon
                        }`}
                    />
                }
            >
                <Grid container item alignItems="center">
                    <Grid
                        item
                        container
                        xs={12}
                        direction="row"
                        rowSpacing={1}
                        justifyContent="space-between"
                    >
                        <Grid
                            container
                            item
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            xs={8}
                            s={4}
                            md="auto"
                        >
                            {icon}
                            <Typography
                                component="h5"
                                className={classes.accordionTitle}
                            >
                                {title}
                            </Typography>
                            {count > 0 && (
                                <Grid item>
                                    <Button
                                        disableRipple
                                        className={classes.previewButton}
                                    >
                                        {count}
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                        {!hideAddButton && !upgrade && (
                            <Grid item>
                                {isMobile && (
                                    <IconButton
                                        className={classes.smScreenButton}
                                        color="midBlue"
                                        variant="secondary"
                                        onClick={handleAddButtonClick}
                                    >
                                        <AddIcon
                                            className={classes.iconHeight}
                                        />
                                    </IconButton>
                                )}
                                {!isMobile && (
                                    <Button
                                        classes={{
                                            label: classes.buttonText,
                                            root: classes.addButton
                                        }}
                                        color="midBlue"
                                        variant="secondary"
                                        onClick={handleAddButtonClick}
                                        startIcon={
                                            <div className={classes.iconStroke}>
                                                <AddIcon
                                                    className={
                                                        classes.iconHeight
                                                    }
                                                />
                                            </div>
                                        }
                                    >
                                        {buttonName}
                                    </Button>
                                )}
                            </Grid>
                        )}
                        {upgrade && (
                            <Grid
                                item
                                xs={12}
                                sm
                                container
                                direction="row-reverse"
                            >
                                <Button
                                    classes={{ root: classes.upgradeButton }}
                                    onClick={() => {
                                        segmentCtaClicked({
                                            destination:
                                                '/settings/subscription',
                                            text: 'Upgrade to Pro',
                                            location: 'Reminders Accordion',
                                            type: 'button',
                                            url: window.location.href
                                        });

                                        navigate(`/settings/subscription`);
                                    }}
                                    startIcon={
                                        <UpgradeIcon
                                            className={classes.iconHeight}
                                        />
                                    }
                                >
                                    Upgrade to Pro
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetail}>
                {children}
            </AccordionDetails>
        </Accordion>
    );
};

MakeDocumentAccordion.propTypes = {
    buttonName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    count: PropTypes.number,
    handleAddButtonClick: PropTypes.func.isRequired,
    icon: PropTypes.node.isRequired,
    hideAddButton: PropTypes.bool,
    children: PropTypes.node.isRequired,
    upgrade: PropTypes.bool
};

MakeDocumentAccordion.defaultProps = {
    count: 0,
    hideAddButton: false,
    upgrade: false
};

export default MakeDocumentAccordion;
