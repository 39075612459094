import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom-v5-compat';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';

import {
    updateClient,
    shareReceipt,
    duplicateReceipt,
    getDocumentById,
    getReceipts
} from '@/modules/dataWrangler/dataWrangler';
import {
    useToastNotification,
    useSubscription,
    useModals,
    useUrlParams
} from '@/hooks';
import {
    SendIcon,
    DuplicateIcon,
    DuplicateIconPopup,
    DownloadIcon
} from '@/resources/icons';
import {
    LottieDownloadLoading,
    LottieSendingLoading,
    LottiePiggyBank
} from '@/lottie';
import { delayAwait, downloadPdf } from '@/util';
import { setModal as openModal, closeModal } from '@/state/actions';
import { PDF_CREATED } from '@/constants';
import { clientModel, clientDefaultModel } from '@/models';
import PaymentModal from '@/components/PaymentModal';

import NoEmailModal from '../../NoEmailModal';
import LottieModal from '../../LottieModal';
import WarnConfirmDialog from '../../WarnConfirmDialog';
import ActionIconWrapper from '../ActionIconWrapper';

const useStyles = makeStyles(() => ({
    send: {
        width: 19,
        height: 16
    },
    cleanIcon: { fill: 'none !important' },
    convertModalIcon: { width: 91, height: 91 }
}));

/**
 * Send receipt with dispatch attached
 * @param {object} client Client object of client to send to.
 * @param {string} id ID of the receipt to send.
 */
export const SendReceiptIcon = ({ client, id, receipt }) => {
    let timeoutManager;
    const [modalActive, setModal] = useState(false);
    const [showNoEmailModal, setShowNoEmailModal] = useState(false);
    const [showOnDraftWarning, setShowOnDraftWarning] = useState(false);
    const [onDraftSuccess, setOnDraftSuccess] = useState(() => {});
    const [piggyBankModalActive, setPiggyBankModalActive] = useState(false);

    const { triggerToast } = useToastNotification();
    const params = useParams();
    const { getUrlParams } = useUrlParams();
    const receiptData = { ...receipt, documentId: id };

    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { isModalActive } = useModals();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => clearTimeout(timeoutManager), [timeoutManager]);

    const manageEmailSend = async (email, piggyBankStartTime) => {
        if (piggyBankStartTime) {
            await delayAwait(piggyBankStartTime, 2000);
            setPiggyBankModalActive(false);
        }

        const startTime = Date.now();
        const currentUrlParams = getUrlParams();
        setModal(true);
        const success = await shareReceipt(id, 'email', email);
        await delayAwait(startTime, 2000);
        if (success) {
            setModal(false);

            // If we're on the client page, we only want to return the receipts
            // for that client.
            if (params?.clientId) {
                currentUrlParams.client = params.clientId;
                currentUrlParams.prefilters = 'clientId';
            }

            getReceipts(currentUrlParams);
            triggerToast({
                message: `Receipt #${
                    success.document?.customLabel || success.referenceNumber
                } Sent`,
                action: 'send',
                icon: <SendIcon className={classes.send} />
            });
        }
    };

    const sendReceipt = async () => {
        if (!client.emailAddress) {
            setShowNoEmailModal(true);
        } else if (receiptData?.documentState === 'draft') {
            setOnDraftSuccess(
                () => (piggyBankStartTime) =>
                    manageEmailSend(undefined, piggyBankStartTime)
            );
            setShowOnDraftWarning(true);
        } else {
            manageEmailSend();
        }
    };

    return (
        <>
            <ActionIconWrapper
                action="send"
                label="Send"
                listOnTablet
                onClick={() => {
                    sendReceipt(id);
                }}
                tooltip="Send Receipt"
            />
            <LottieModal
                title="Sending Receipt..."
                backgroundColor="#F5F9FF"
                open={modalActive}
            >
                <LottieSendingLoading />
            </LottieModal>
            <LottieModal
                title="Adding payment..."
                backgroundColor="#F5F9FF"
                open={piggyBankModalActive}
            >
                <LottiePiggyBank />
            </LottieModal>
            <NoEmailModal
                open={showNoEmailModal}
                onClose={async (emailAddress, saveToClient) => {
                    if (emailAddress === undefined) {
                        setShowNoEmailModal(false);
                        return;
                    }
                    setShowNoEmailModal(false);

                    if (saveToClient) {
                        const updatedClient = {
                            ...client,
                            emailAddress
                        };
                        updateClient(updatedClient);
                    }

                    if (receiptData?.documentState === 'draft') {
                        setOnDraftSuccess(
                            () => (piggyBankStartTime) =>
                                manageEmailSend(
                                    emailAddress,
                                    piggyBankStartTime
                                )
                        );
                        setShowOnDraftWarning(true);
                    } else {
                        manageEmailSend(emailAddress);
                    }
                }}
                type="receipt"
            />
            <WarnConfirmDialog
                onCancel={() => setShowOnDraftWarning(false)}
                onConfirm={() => {
                    setShowOnDraftWarning(false);
                    dispatch(
                        openModal({
                            slug: `process-receipt-payment-options-send-${id}`
                        })
                    );
                }}
                confirmColor={theme.palette.primary.main}
                message="In order to send, you must add a payment. You will no longer be able to edit this receipt."
                cancelText="Cancel"
                confirmText="Add Payment"
                open={showOnDraftWarning}
            />
            {isModalActive(`process-receipt-payment-options-send-${id}`) && (
                <PaymentModal
                    open
                    onClose={() =>
                        dispatch(
                            closeModal(
                                `process-receipt-payment-options-send-${id}`
                            )
                        )
                    }
                    currentDocument={receiptData}
                    isDesktop={isDesktop}
                    triggerAnimation={async () => {
                        setPiggyBankModalActive(true);
                    }}
                    onPreSuccess={async () => {
                        const savedData = await getDocumentById(id);
                        if (!savedData) {
                            return false;
                        }
                        return savedData;
                    }}
                    onPostSuccess={() => {
                        const piggyBankStartTime = Date.now();
                        onDraftSuccess(piggyBankStartTime);
                    }}
                />
            )}
        </>
    );
};

SendReceiptIcon.propTypes = {
    id: PropTypes.string.isRequired,
    client: clientModel,
    receipt: PropTypes.object.isRequired
};

SendReceiptIcon.defaultProps = clientDefaultModel;

/**
 * View Icon with link attached.
 * @param {string} id ID of the receipt to view.
 */
export const ViewReceiptIcon = ({ id }) => (
    <ActionIconWrapper
        action="view"
        label="View"
        link={`/receipt/${id}`}
        tooltip="View Receipt"
    />
);

ViewReceiptIcon.propTypes = {
    id: PropTypes.string.isRequired
};

/**
 * Download Icon with dispatch attached
 * @param {string} id ID of the receipt to download.
 * @param {string} label Name to append to "receipt-XXX.pdf".
 */
export const DownloadReceiptIcon = ({ id, label, receipt }) => {
    const [modalActive, setModal] = useState(false);
    const [showOnDraftWarning, setShowOnDraftWarning] = useState(false);
    const [onDraftSuccess, setOnDraftSuccess] = useState(() => {});
    const [piggyBankModalActive, setPiggyBankModalActive] = useState(false);
    const { triggerToast } = useToastNotification();
    const params = useParams();
    const { getUrlParams } = useUrlParams();

    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { isModalActive } = useModals();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    let timeoutManager;
    const receiptData = { ...receipt, documentId: id };

    useEffect(() => clearTimeout(timeoutManager), [timeoutManager]);

    const processDownloadReceipt = async (piggyBankStartTime) => {
        if (piggyBankStartTime) {
            await delayAwait(piggyBankStartTime, 2000);
            setPiggyBankModalActive(false);
        }

        const startTime = Date.now();
        const currentUrlParams = getUrlParams();
        setModal(true);
        const res = await shareReceipt(id, 'download');
        await delayAwait(startTime, 2000);
        setModal(false);
        if (res?.status === PDF_CREATED) {
            downloadPdf({ name: `receipt-${label}`, base64: res.pdf });

            // If we're on the client page, we only want to return the receipts
            // for that client.
            if (params?.clientId) {
                currentUrlParams.client = params.clientId;
                currentUrlParams.prefilters = 'clientId';
            }

            getReceipts(currentUrlParams);
            triggerToast({
                message: `Receipt Downloaded`,
                action: 'saved',
                icon: <DownloadIcon className={classes.cleanIcon} />
            });
        }
    };

    const downloadReceipt = async () => {
        if (receiptData?.documentState === 'draft') {
            setOnDraftSuccess(
                () => (piggyBankStartTime) =>
                    processDownloadReceipt(piggyBankStartTime)
            );
            setShowOnDraftWarning(true);
        } else {
            processDownloadReceipt();
        }
    };

    return (
        <>
            <ActionIconWrapper
                action="download"
                label="Download"
                listOnTablet
                onClick={() => {
                    downloadReceipt();
                }}
                tooltip="Download Receipt"
            />
            <LottieModal
                title="Downloading Receipt..."
                backgroundColor="#F5F9FF"
                open={modalActive}
            >
                <LottieDownloadLoading />
            </LottieModal>
            <LottieModal
                title="Adding payment..."
                backgroundColor="#F5F9FF"
                open={piggyBankModalActive}
            >
                <LottiePiggyBank />
            </LottieModal>
            <WarnConfirmDialog
                onCancel={() => setShowOnDraftWarning(false)}
                onConfirm={() => {
                    setShowOnDraftWarning(false);
                    dispatch(
                        openModal({
                            slug: `process-receipt-payment-options-download-${id}`
                        })
                    );
                }}
                confirmColor={theme.palette.primary.main}
                message="In order to download, you must add a payment. You will no longer be able to edit this receipt."
                cancelText="Cancel"
                confirmText="Add Payment"
                open={showOnDraftWarning}
            />
            {isModalActive(
                `process-receipt-payment-options-download-${id}`
            ) && (
                <PaymentModal
                    open
                    onClose={() =>
                        dispatch(
                            closeModal(
                                `process-receipt-payment-options-download-${id}`
                            )
                        )
                    }
                    currentDocument={receiptData}
                    isDesktop={isDesktop}
                    triggerAnimation={async () => {
                        setPiggyBankModalActive(true);
                    }}
                    onPreSuccess={async () => {
                        const savedData = await getDocumentById(id);
                        if (!savedData) {
                            return false;
                        }
                        return savedData;
                    }}
                    onPostSuccess={() => {
                        const piggyBankStartTime = Date.now();
                        onDraftSuccess(piggyBankStartTime);
                    }}
                />
            )}
        </>
    );
};

DownloadReceiptIcon.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    receipt: PropTypes.object.isRequired
};

/**
 * Edit Icon with dispatch attached
 * @param {string} id ID of the receipt to edit.
 */
export const EditReceiptIcon = ({ id }) => (
    <ActionIconWrapper
        action="edit"
        label="Edit"
        link={`/receipt/${id}`}
        tooltip="Edit Receipt"
    />
);

EditReceiptIcon.propTypes = {
    id: PropTypes.string.isRequired
};

/**
 * Duplicate Icon with dispatch attached
 * @param {string} id ID of the receipt to duplicate.
 * @param {string} label Receipt number or custom label to duplicate
 */
export const DuplicateReceiptIcon = ({ id, label }) => {
    const theme = useTheme();
    const classes = useStyles();
    const navigate = useNavigate();
    const subscriptionData = useSubscription();

    const [duplicateDialogActive, setDuplicateDialogActive] = useState(false);

    const handleClick = () => {
        const subInfo = subscriptionData?.components?.document;

        const blockDocumentCreation =
            !subInfo?.enabled ||
            (subInfo?.limited && subInfo?.amountRemaining < 1);

        if (blockDocumentCreation) {
            // If the user has no more documents, remaining force them
            // to subscription page.
            navigate('/settings/subscription');
        } else {
            setDuplicateDialogActive(true);
        }
    };

    const { triggerToast } = useToastNotification();

    const handleConfirmation = async () => {
        setDuplicateDialogActive(false);

        const newReceipt = await duplicateReceipt(id);

        if (newReceipt) {
            triggerToast({
                message: `Receipt ${label} Duplicated`,
                action: 'duplicate',
                icon: <DuplicateIcon className={classes.cleanIcon} />
            });

            setTimeout(() => {
                navigate(`/receipt/${newReceipt.documentId}`);
            }, 1000);
        }
    };

    const handleCancellation = () => {
        setDuplicateDialogActive(false);
    };

    return (
        <>
            <ActionIconWrapper
                action="duplicate"
                label="Duplicate"
                listOnTablet
                onClick={() => {
                    handleClick(id);
                }}
                tooltip="Duplicate Receipt"
            />
            <WarnConfirmDialog
                onCancel={() => handleCancellation()}
                onConfirm={() => handleConfirmation()}
                customIcon={<DuplicateIconPopup />}
                confirmColor={theme.palette.primary.main}
                message="Are you sure you want to duplicate this receipt?"
                cancelText="Cancel"
                confirmText="Duplicate Receipt"
                open={duplicateDialogActive}
            />
        </>
    );
};

DuplicateReceiptIcon.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};
