export const activityTypes = [
    {
        name: 'Created',
        slug: 'created'
    },
    {
        name: 'Updated',
        slug: 'updated'
    },
    { name: 'Deleted', slug: 'deleted' },
    { name: 'Voided', slug: 'voided' },
    { name: 'Approved', slug: 'approved' },
    { name: 'Declined', slug: 'declined' },
    { name: 'Email Sent', slug: 'email-sent' },
    { name: 'Downloaded', slug: 'downloaded' },
    { name: 'Link Created', slug: 'link-created' },
    { name: 'Marked as Paid', slug: 'marked-paid' },
    { name: 'Marked as Unpaid', slug: 'marked-unpaid' },
    { name: 'Paid', slug: 'paid' },
    { name: 'Refunded', slug: 'refunded' },
    { name: 'Recurring Created', slug: 'recurring-created' },
    { name: 'Recurring Started', slug: 'recurring-started' },
    { name: 'Recurring Stopped', slug: 'recurring-stopped' },
    { name: 'Recurring Skipped', slug: 'recurring-skipped' },
    { name: 'Recurring Complete', slug: 'recurring-complete' }
];
