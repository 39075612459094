import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom-v5-compat';
import { setModal, resetDocument } from '@/state/actions';
import { useCheckAuthState } from '@/hooks';
import { MakeDocumentAccordion } from '@/components/common';
import DocumentPayments from '@/components/DocumentPayments';
import { PaymentsIconLarge } from '@/resources/icons';
import useStyles from './styles';

const SignUpLoginModal = loadable(() => import('@/components/Login'));

const PaymentsAccordion = ({ transactions, hideAddPaymentButton }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { registeredUser } = useCheckAuthState();
    const navigate = useNavigate();

    const [loadLoginModal, setLoadLoginModal] = useState(false);
    const [loginModalOptions, setLoginModalOptions] = useState({
        view: '',
        onClose: () => {},
        onSuccess: () => {},
        onNoSave: () => {}
    });

    const handleSetLoginView = (view) => {
        setLoginModalOptions({ ...loginModalOptions, view });
    };

    const onLoginBlocker = (handleFunc) => {
        setLoginModalOptions({
            ...loginModalOptions,
            view: 'login',
            onSuccess: (login) => {
                if (login) {
                    dispatch(resetDocument());
                    navigate('/');
                } else {
                    handleFunc();
                }
            },
            onNoSave: () => handleFunc()
        });
    };

    useEffect(() => {
        if (loginModalOptions.view && !loadLoginModal) {
            setLoadLoginModal(true);
        }
    }, [loginModalOptions.view, loadLoginModal]);

    const handleAddPaymentClick = (e) => {
        e.stopPropagation();
        if (registeredUser) {
            dispatch(setModal({ slug: 'process-invoice-payment-options' }));
        } else {
            onLoginBlocker(() =>
                dispatch(setModal({ slug: 'process-invoice-payment-options' }))
            );
        }
    };

    return (
        <>
            <MakeDocumentAccordion
                buttonName="Add Payments"
                title="Payments"
                handleAddButtonClick={handleAddPaymentClick}
                count={transactions.length}
                icon={<PaymentsIconLarge className={classes.icon} />}
                hideAddButton={hideAddPaymentButton}
            >
                <DocumentPayments transactions={transactions} />
            </MakeDocumentAccordion>

            {loadLoginModal && (
                <SignUpLoginModal
                    setLoginView={handleSetLoginView}
                    view={loginModalOptions.view}
                    onClose={loginModalOptions.onClose}
                    onSuccess={loginModalOptions.onSuccess}
                    onNoSave={loginModalOptions.onNoSave}
                    inAction
                />
            )}
        </>
    );
};

PaymentsAccordion.propTypes = {
    transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
    hideAddPaymentButton: PropTypes.bool
};

PaymentsAccordion.defaultProps = {
    hideAddPaymentButton: false
};

export default PaymentsAccordion;
