import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    buttonWrap: {
        display: 'flex',
        padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(
            8
        )} ${theme.typography.pxToRem(8)} 0`,
        marginLeft: theme.typography.pxToRem(-2),
        [theme.breakpoints.up('sm')]: {
            padding: `${theme.typography.pxToRem(
                12
            )} 0  ${theme.typography.pxToRem(12)} 0`
        },
        [theme.breakpoints.between(600, 768)]: {
            maxWidth: '50%',
            flexBasis: '10%'
        }
    },
    deleteButton: {
        color: '#fff',
        height: theme.typography.pxToRem(58),
        width: theme.typography.pxToRem(30),
        minWidth: '100%',
        padding: 0
    },
    inputBar: {
        marginBottom: 0
    },
    nameField: {
        [theme.breakpoints.between(600, 768)]: {
            maxWidth: '50%'
        }
    },
    valueField: {
        [theme.breakpoints.between(600, 768)]: {
            maxWidth: '40%'
        }
    }
}));
