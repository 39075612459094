/* eslint import/no-cycle: "off"  */
import store from '@/state/store';
import { batch } from 'react-redux';

import {
    getCountryLocaleData,
    getAllLocalizationData,
    setGtmDataLayer,
    setHotjarUser,
    setSentryUser
} from '@/util';

import { convertTemporaryUserToUnregisteredUser } from '@/modules/setup';

import {
    createBusiness as createBusinessAction,
    loggedIn,
    setActiveBusiness,
    setActiveBusinessPending,
    updateUser,
    setTemporaryUser,
    setActiveBusinessComplete
} from '@/state/actions';

import * as connections from './connections';
import * as localizationApi from './localization';
import * as subscription from '../subscriptionManager';

import axiosAuthenticated from '../authenticatedRequestor';
import axiosUnauthenticated from '../unauthenticatedRequestor';

export const createBusiness = async (business) => {
    const response = await axiosAuthenticated.post('/businesses', business);
    store.dispatch(createBusinessAction(response.data));
    store.dispatch(setActiveBusiness(response.data));
};

export const overwriteBusiness = async () => {
    const { user } = store.getState();
    const businessId = user.businesses[0];
    const { localization } = store.getState();

    setSentryUser({ businessId, email: user.email });
    setHotjarUser({ email: user.email });
    setGtmDataLayer(user.userId, businessId);

    try {
        store.dispatch(setActiveBusinessPending());

        const businessResult = await axiosAuthenticated.get(
            `/businesses/${businessId}`
        );
        const userLocaleData = getAllLocalizationData(
            localization,
            businessResult?.data?.businessSettings?.country,
            businessResult?.data?.businessSettings?.currency?.toUpperCase(),
            businessResult?.data?.businessSettings?.locale
        );
        const businessCountry = getCountryLocaleData(
            localization,
            businessResult?.data?.businessProfile?.address?.country
        );
        store.dispatch(
            setActiveBusiness({
                ...businessResult?.data,
                localeData: { ...userLocaleData, businessCountry }
            })
        );

        await Promise.all([
            connections.getAllThirdPartyConnections(businessId),
            subscription.getUserSubscription(businessId)
        ]);
    } finally {
        store.dispatch(setTemporaryUser(false));
        store.dispatch(setActiveBusinessComplete());
    }

    return store.getState().user.activeBusiness;
};

export const getTemporaryBusiness = async () => {
    const response = await axiosUnauthenticated.get(`businesses/temporary`);

    if (Object.entries(store.getState().localization).length === 0) {
        await localizationApi.getLocalizationSettings();
    }

    const allLocalizationData = getAllLocalizationData(
        store.getState().localization,
        response?.data.businessSettings?.country,
        response?.data.businessSettings?.currency,
        response?.data.businessSettings?.locale
    );

    const businessCountry = getCountryLocaleData(
        store.getState().localization,
        'US'
    );

    const temporaryUserSubscription = {
        components: {
            document: {
                componentType: 'document',
                amountStartedWith: 1,
                limited: true,
                enabled: true,
                amountPerRefresh: 1,
                amountRemaining: 1
            }
        },
        chargify: {},
        productId: 'visitor'
    };

    batch(() => {
        store.dispatch(
            setActiveBusiness({
                ...response.data,
                localeData: { ...allLocalizationData, businessCountry },
                subscription: temporaryUserSubscription,
                businessId: 'temporary'
            })
        );

        // There are hooks that look at all types of stuff to make everything work
        // The following set all of this up so componets will work with out modificaiton

        store.dispatch(loggedIn(true));
        store.dispatch(updateUser({ ...store.getState().user }));
    });
};

export const updateBusinessSettings = async (updatedSettings) => {
    if (store.getState().user.temporaryUser) {
        await convertTemporaryUserToUnregisteredUser();
    }

    const originalSettings = store.getState().user.activeBusiness;

    const response = await axiosAuthenticated.put(
        `/businesses/${originalSettings.businessId}`,
        {
            ...originalSettings,
            ...updatedSettings
        }
    );

    const { localization } = store.getState();

    const userLocaleData = getAllLocalizationData(
        localization,
        response.data.businessSettings.country,
        response.data.businessSettings.currency.toUpperCase(),
        response.data.businessSettings.locale
    );

    const businessCountry = getCountryLocaleData(
        localization,
        response?.data?.businessProfile?.address?.country
    );

    store.dispatch(
        setActiveBusiness({
            ...store.getState().user.activeBusiness,
            ...response.data,
            localeData: { ...userLocaleData, businessCountry }
        })
    );
    return true;
};
