export const documentSortMenuItems = [
    {
        name: 'Document # - Lowest',
        type: 'customLabel',
        order: 'asc'
    },
    {
        name: 'Document # - Highest',
        type: 'customLabel',
        order: 'desc'
    },
    {
        name: 'Date - Oldest',
        type: 'issuedDate',
        order: 'asc'
    },
    {
        name: 'Date - Newest',
        type: 'issuedDate',
        order: 'desc'
    },
    {
        name: 'Client - A-Z',
        type: 'client',
        order: 'asc'
    },
    {
        name: 'Client - Z-A',
        type: 'client',
        order: 'desc'
    },
    {
        name: 'Total - Lowest',
        type: 'total',
        order: 'asc'
    },
    {
        name: 'Total - Highest',
        type: 'total',
        order: 'desc'
    },
    {
        name: 'Amount Due - Lowest',
        type: 'balanceDue',
        order: 'asc'
    },
    {
        name: 'Amount Due - Highest',
        type: 'balanceDue',
        order: 'desc'
    }
];

export const clientSortMenuItems = [
    {
        name: 'Name - A-Z',
        type: 'name',
        order: 'asc'
    },
    {
        name: 'Name - Z-A',
        type: 'name',
        order: 'desc'
    },
    {
        name: 'Business Name - A-Z',
        type: 'businessName',
        order: 'asc'
    },
    {
        name: 'Business Name - Z-A',
        type: 'businessName',
        order: 'desc'
    },
    {
        name: 'Balance Paid - Lowest',
        type: 'balancePaid',
        order: 'asc'
    },
    {
        name: 'Balance Paid - Highest',
        type: 'balancePaid',
        order: 'desc'
    },
    {
        name: 'Balance Due - Lowest',
        type: 'balanceDue',
        order: 'asc'
    },
    {
        name: 'Balance Due - Highest',
        type: 'balanceDue',
        order: 'desc'
    }
];

export const clientDocsSortMenuItems = [
    {
        name: 'Document # - Lowest',
        type: 'customLabel',
        order: 'asc'
    },
    {
        name: 'Document # - Highest',
        type: 'customLabel',
        order: 'desc'
    },
    {
        name: 'Date - Oldest',
        type: 'issuedDate',
        order: 'asc'
    },
    {
        name: 'Date - Newest',
        type: 'issuedDate',
        order: 'desc'
    },
    {
        name: 'Balance Paid - Lowest',
        type: 'balancePaid',
        order: 'asc'
    },
    {
        name: 'Balance Paid - Highest',
        type: 'balancePaid',
        order: 'desc'
    },
    {
        name: 'Balance Due - Lowest',
        type: 'balanceDue',
        order: 'asc'
    },
    {
        name: 'Balance Due - Highest',
        type: 'balanceDue',
        order: 'desc'
    }
];

export const productServiceSortMenuItems = [
    {
        name: 'Name - A-Z',
        type: 'name',
        order: 'asc'
    },
    {
        name: 'Name - Z-A',
        type: 'name',
        order: 'desc'
    },
    {
        name: 'Price - Lowest',
        type: 'price',
        order: 'asc'
    },
    {
        name: 'Price - Highest',
        type: 'price',
        order: 'desc'
    }
];

export const taxDiscountSortMenuItems = [
    {
        name: 'Name - A-Z',
        type: 'name',
        order: 'asc'
    },
    {
        name: 'Name - Z-A',
        type: 'name',
        order: 'desc'
    },
    {
        name: 'Amount - Lowest',
        type: 'value',
        order: 'asc'
    },
    {
        name: 'Amount - Highest',
        type: 'value',
        order: 'desc'
    }
];

export const tagsSortMenuItems = [
    {
        name: 'Name - A-Z',
        type: 'name',
        order: 'asc'
    },
    {
        name: 'Name - Z-A',
        type: 'name',
        order: 'desc'
    }
];

export const recurringSortMenuItems = [
    {
        name: 'Client - A-Z',
        type: 'client',
        order: 'asc'
    },
    {
        name: 'Client - Z-A',
        type: 'client',
        order: 'desc'
    },
    {
        name: 'Next Send - Oldest',
        type: 'nextSendDate',
        order: 'asc'
    },
    {
        name: 'Next Send - Newest',
        type: 'nextSendDate',
        order: 'desc'
    },
    {
        name: 'Total - Lowest',
        type: 'total',
        order: 'asc'
    },
    {
        name: 'Total - Highest',
        type: 'Total',
        order: 'desc'
    },
    {
        name: 'Sent - Lowest',
        type: 'amountSent',
        order: 'asc'
    },
    {
        name: 'Sent - Highest',
        type: 'amountSent',
        order: 'desc'
    },
    { name: 'Status - A-Z', type: 'state', order: 'asc' },
    { name: 'Status - Z-A', type: 'state', order: 'desc' }
];

export const clientRecurringSortMenuItems = [
    {
        name: 'Client - Z-A',
        type: 'client',
        order: 'desc'
    },
    {
        name: 'Next Send - Oldest',
        type: 'nextSendDate',
        order: 'asc'
    },
    {
        name: 'Next Send - Newest',
        type: 'nextSendDate',
        order: 'desc'
    },
    {
        name: 'Total - Lowest',
        type: 'total',
        order: 'asc'
    },
    {
        name: 'Total - Highest',
        type: 'Total',
        order: 'desc'
    },
    {
        name: 'Sent - Lowest',
        type: 'amountSent',
        order: 'asc'
    },
    {
        name: 'Sent - Highest',
        type: 'amountSent',
        order: 'desc'
    },
    { name: 'Status - A-Z', type: 'state', order: 'asc' },
    { name: 'Status - Z-A', type: 'state', order: 'desc' }
];

export const activitySortMenuItems = [
    {
        name: 'Client - A-Z',
        type: 'client',
        order: 'asc'
    },
    {
        name: 'Client - Z-A',
        type: 'client',
        order: 'desc'
    },
    {
        name: 'Frequency - Lowest',
        type: 'frequency',
        order: 'asc'
    },
    {
        name: 'Frequency - Highest',
        type: 'frequency',
        order: 'desc'
    },
    {
        name: 'Next Send - Oldest',
        type: 'nextSendDate',
        order: 'asc'
    },
    {
        name: 'Next Send - Newest',
        type: 'nextSendDate',
        order: 'desc'
    }
];
