import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

/**
 * Renders a Material-UI TextField that trims the whitespace from the start/end of it's value on blur
 *
 * @param {string} id A custom id to use for the textfield.
 * @param {string} label The label for the textfield.
 * @param {onBlur} func A function to handle the formatted return value from this email component.
 */

const EmailTextField = ({ id, label, onBlur, ...rest }) => (
    <TextField
        id={id}
        label={label}
        onBlur={(e) => {
            onBlur(e.target.value.trim());
        }}
        {...rest}
    />
);

EmailTextField.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    onBlur: PropTypes.func
};

EmailTextField.defaultProps = {
    id: 'email-address-textfield',
    label: 'Email',
    onBlur: () => {}
};

export default EmailTextField;
