export const dateTerms = [
    { name: 'None', value: 0 },
    { name: 'Custom', value: '' },
    { name: 'Next Day', value: 1 },
    { name: '7 Days', value: 7 },
    { name: '14 Days', value: 14 },
    { name: '30 Days', value: 30 },
    { name: '60 Days', value: 60 },
    { name: '90 Days', value: 90 },
    { name: '180 Days', value: 180 },
    { name: '365 Days', value: 365 }
];
