import React from 'react';
import Lottie from 'lottie-react';
import piggyBank from './piggy-bank.json';
import useStyles from './styles';

const LottiePiggyBank = () => {
    const classes = useStyles();

    return (
        <div className={classes.wrap} data-testid="lottie-piggy-bank">
            <Lottie animationData={piggyBank} />
        </div>
    );
};

export default LottiePiggyBank;
