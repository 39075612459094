import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
    labelOne: {
        fontWeight: 600,
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    labelTwo: {
        fontWeight: 400,
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    labelWrap: {
        flexWrap: 'nowrap'
    },
    labelOneWrap: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingRight: 6
    },
    labelTwoWrap: {
        maxWidth: '40%',
        whiteSpace: 'nowrap'
    }
}));
