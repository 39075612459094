import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Divider } from '@mui/material';
import styles from './styles';

const SettingsHeader = ({ id, label, divider }) => {
    const classes = styles();

    return (
        <Grid item xs={12}>
            <Typography
                id={id}
                component="h2"
                color="textSecondary"
                variant="body1"
            >
                {label}
            </Typography>

            {divider && <Divider className={classes.headingDivider} />}
        </Grid>
    );
};

SettingsHeader.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    divider: PropTypes.bool
};

SettingsHeader.defaultProps = {
    divider: true
};
export default SettingsHeader;
