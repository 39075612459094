import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';

import { ButtonLoading } from '@/components/common';
import ReactivateButton from '@/pageComponents/Settings/Subscription/ReactivateButton';
import CancelDowngradeButton from '@/pageComponents/Settings/Subscription/CancelDowngradeButton';
import { ctaClicked as segmentCtaClicked } from '@/modules/segment/segmentManager';
import styles from './styles';

const SubscriptionCardButton = ({
    cancelling,
    discountedPrice,
    iosIsPurchasing,
    isPending,
    isIos,
    isUpgrade,
    onButtonClick,
    subscribed,
    title,
    product,
    subscriptionSource,
    managementURL
}) => {
    const classes = styles();
    const [loading, setLoading] = useState(false);

    // Handles only making this button show loading,
    // rather than buttons on every card.
    if (!iosIsPurchasing && loading) {
        setLoading(false);
    }

    if (subscribed && cancelling) {
        return <ReactivateButton />;
    }

    if (subscribed) {
        return (
            <Typography
                variant="h4"
                component="p"
                color="primary"
                className={classes.buttonHeight}
            >
                Subscription Active
            </Typography>
        );
    }

    if (managementURL) {
        return (
            <Button
                fullWidth
                variant="contained"
                color={title.toLowerCase() === 'plus' ? 'primary' : 'grey'}
                href={managementURL}
                disabled={isIos && iosIsPurchasing}
            >
                Manage Subscription
            </Button>
        );
    }

    if (
        subscriptionSource === 'unassigned' &&
        product?.trialInterval &&
        !isIos
    ) {
        return (
            <ButtonLoading
                fullWidth
                variant="contained"
                color={title.toLowerCase() === 'plus' ? 'primary' : 'grey'}
                loading={loading}
                onClick={async (e) => {
                    e.preventDefault();
                    setLoading(true);

                    segmentCtaClicked({
                        destination: 'Subscription',
                        text: 'Subscribe',
                        location: 'Subscription Card',
                        type: 'button',
                        url: window.location.href
                    });

                    await onButtonClick(0);
                }}
            >
                {`Start Free ${product?.trialInterval}-${product?.trialIntervalUnit} Trial`}
            </ButtonLoading>
        );
    }

    if (isUpgrade === null && !subscribed) {
        return (
            <ButtonLoading
                fullWidth
                variant="contained"
                color={title.toLowerCase() === 'plus' ? 'primary' : 'grey'}
                loading={loading}
                disabled={isIos && iosIsPurchasing}
                onClick={async (e) => {
                    e.preventDefault();
                    setLoading(true);

                    segmentCtaClicked({
                        destination: 'Subscription',
                        text: 'Subscribe',
                        location: 'Subscription Card',
                        type: 'button',
                        url: window.location.href
                    });

                    await onButtonClick(discountedPrice);
                }}
            >
                Subscribe
            </ButtonLoading>
        );
    }

    if (isPending) {
        return (
            <div style={{ width: '100%' }}>
                <Typography
                    variant="h4"
                    component="p"
                    color="primary"
                    className={classes.buttonHeight}
                >
                    Pending
                </Typography>
                <CancelDowngradeButton isUpgrade={isUpgrade} />
            </div>
        );
    }

    return (
        <ButtonLoading
            fullWidth
            variant={
                title.toLowerCase() === 'plus' && !subscribed
                    ? 'contained'
                    : 'secondary'
            }
            color={
                title.toLowerCase() === 'plus' && !subscribed
                    ? 'primary'
                    : 'midBlue'
            }
            loading={loading}
            onClick={async (e) => {
                e.preventDefault();
                setLoading(true);

                segmentCtaClicked({
                    destination: 'Subscription',
                    text: isUpgrade ? 'Upgrade' : 'Downgrade',
                    location: 'Subscription Card',
                    type: 'button',
                    url: window.location.href
                });

                await onButtonClick(discountedPrice);
                setLoading(false);
            }}
        >
            {isUpgrade ? 'Upgrade' : 'Downgrade'}
        </ButtonLoading>
    );
};

SubscriptionCardButton.propTypes = {
    cancelling: PropTypes.bool,
    discountedPrice: PropTypes.number,
    iosIsPurchasing: PropTypes.bool,
    isIos: PropTypes.bool.isRequired,
    isPending: PropTypes.bool,
    isUpgrade: PropTypes.bool,
    product: PropTypes.object.isRequired,
    onButtonClick: PropTypes.func.isRequired,
    subscribed: PropTypes.bool.isRequired,
    subscriptionSource: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    managementURL: PropTypes.string
};

SubscriptionCardButton.defaultProps = {
    cancelling: false,
    discountedPrice: null,
    iosIsPurchasing: false,
    isPending: null,
    isUpgrade: null,
    managementURL: null
};

export default SubscriptionCardButton;
