import { useSelector } from 'react-redux';

export const useModals = () => {
    const modals = useSelector((state) => state?.modals) || [];

    const isModalActive = (modal) =>
        Boolean(modals.find(({ modalSlug }) => modalSlug === modal));

    const getModal = (modal) =>
        modals.find(({ modalSlug }) => modalSlug === modal);

    return { modals, isModalActive, getModal };
};
