import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    button: {
        backgroundColor: theme.palette.imBrand.lightBlue,
        color: theme.palette.imBrand.darkBlue,
        padding: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(
            20
        )}`
    }
}));
