import PropTypes from 'prop-types';

export const transactionMethod = PropTypes.oneOf([
    'cash',
    'check',
    'card',
    'stripe',
    'square',
    'manual',
    'cashapp',
    'venmo',
    'zelle',
    'directdebit',
    'other',
    'afterpay_clearpay',
    'affirm',
    'bank_account',
    'klarna',
    'wallet',
    'genericCreditCard'
]);

export const transactionLast4 = PropTypes.string;

export const defaultTransaction = {
    paymentId: '',
    businessId: '',
    documentId: '',
    connectionId: '',
    state: '',
    type: '',
    processor: '',
    processorAccountId: '',
    processorPaymentId: '',
    amountCollected: '',
    amountRefunded: '',
    amountAvailable: 0,
    currency: '',
    paymentMethod: {
        type: 'other',
        brand: '',
        last4: '****'
    },
    documentReferenceNumber: '',
    clientName: '',
    clientCompanyName: '',
    refunds: [
        {
            refundId: '',
            businessId: '',
            documentId: '',
            connectionId: '',
            paymentId: '',
            state: '',
            type: '',
            processor: '',
            processorAccountId: '',
            processorRefundId: '',
            processorPaymentId: '',
            amountRefunded: '',
            timestamp: ''
        }
    ],
    timestamp: ''
};

export const transaction = PropTypes.shape({
    paymentId: PropTypes.string,
    businessId: PropTypes.string,
    documentId: PropTypes.string,
    connectionId: PropTypes.string,
    state: PropTypes.string,
    type: PropTypes.string,
    processor: PropTypes.string,
    processorAccountId: PropTypes.string,
    processorPaymentId: PropTypes.string,
    amountCollected: PropTypes.number,
    amountRefunded: PropTypes.number,
    amountAvailable: PropTypes.number,
    currency: PropTypes.string,
    paymentMethod: PropTypes.shape({
        type: transactionMethod,
        brand: PropTypes.string,
        last4: PropTypes.string
    }),
    documentReferenceNumber: PropTypes.string,
    clientName: PropTypes.string,
    clientCompanyName: PropTypes.string,
    refunds: PropTypes.arrayOf(
        PropTypes.shape({
            refundId: PropTypes.string,
            businessId: PropTypes.string,
            documentId: PropTypes.string,
            connectionId: PropTypes.string,
            paymentId: PropTypes.string,
            state: PropTypes.string,
            type: PropTypes.string,
            processor: PropTypes.string,
            processorAccountId: PropTypes.string,
            processorRefundId: PropTypes.string,
            processorPaymentId: PropTypes.string,
            amountRefunded: PropTypes.number,
            timestamp: PropTypes.string
        })
    ),
    timestamp: PropTypes.string
});
