import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import { ManBrokenBike } from '@/resources/images';
import useStyles from './styles';
import { GenericDialog } from '..';

const NoInternetModal = () => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const open =
        useSelector((state) => !state.networkStatus?.connected) || false;

    return (
        <GenericDialog
            onConfirm={() => {
                window.location.reload(true); // Hard reload the page.
            }}
            customIcon={<ManBrokenBike className={classes.icon} />}
            confirmColor={theme.palette.primary.main}
            title="You are not connected to the internet"
            confirmText="Retry Now"
            open={open}
        />
    );
};

export default NoInternetModal;
