import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { TextField, InputAdornment } from '@mui/material';
import { useUrlParams } from '@/hooks';
import useStyles from './styles';

/**
 * Drop in search form for listing pages.
 * @param {string} ariaLabel Label for screen readers.
 * @param {string} base Base URL for the search params.
 * @param {string} label Label to use above the input.
 * @param {string} id ID for the input field.
 */
const SearchInput = ({ ariaLabel, base, label, id }) => {
    const classes = useStyles();
    const previousSearch = useRef();
    const { getUrlParams, setUrlParams } = useUrlParams();
    const [searchValue, setSearchValue] = useState('');

    const currentSearchParam = getUrlParams({ base, watch: ['s'] })?.s;

    useEffect(() => {
        if (previousSearch.current !== currentSearchParam) {
            setSearchValue(currentSearchParam || '');
            previousSearch.current = currentSearchParam;
        }
    }, [searchValue, currentSearchParam]);

    const handleOnChange = (e) => {
        setSearchValue(e.target.value);
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();
        const currentUrlParams = getUrlParams({ base });
        const currentSortParams = currentUrlParams?.sort;

        const searchParams = searchValue
            ? {
                  ...currentUrlParams,
                  s: searchValue,
                  p: undefined,
                  sort: { type: 'relevance', order: 'asc' }
              }
            : {
                  ...currentUrlParams,
                  s: searchValue,
                  sort:
                      currentSortParams?.type === 'relevance'
                          ? undefined
                          : currentSortParams
              };

        setUrlParams({ base, params: searchParams });
    };

    return (
        <form noValidate autoComplete="off" onSubmit={handleOnSubmit}>
            <TextField
                onChange={handleOnChange}
                value={searchValue}
                className={classes.searchBox}
                name="searchInput"
                id={id}
                label={label}
                variant="outlined"
                margin="dense"
                size="small"
                aria-label={ariaLabel}
                type="search"
                InputProps={{
                    classes: {
                        notchedOutline: classes.searchBoxOutline,
                        input: classes.input
                    },
                    endAdornment: (
                        <InputAdornment position="end" tabIndex="-1">
                            <IconButton
                                aria-label="Submit search"
                                className={classes.disableRipple}
                                type="submit"
                                edge="end"
                                size="large"
                            >
                                <SearchIcon viewBox="0 0 26 26" />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </form>
    );
};

SearchInput.propTypes = {
    ariaLabel: PropTypes.string.isRequired,
    base: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
};

export default SearchInput;
