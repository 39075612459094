import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { AlertIcon } from '@/resources/icons';
import WarningButton from '../WarningButton';
import useStyles from './styles';

/**
 * Pure component to handle dangerous actions such as deleting invoices.
 * Can take a single title, message, or title and message. Managing formatting
 * depending on what is passed in.
 * @param {string} cancelText Text to show in cancel button.
 * @param {string} confirmColor Color override for the confirm button.
 * @param {string} confirmText Text to show in confirm button.
 * @param {string} confirmButtonStyles Extra classNames to pass to the confirm button.
 * @param {string} wrapperStyles Extra classNames to pass to the wrapper component.
 * @param {elem} customIcon React component icon to replace default icon.
 * @param {bool} iconTop If the icon is to be positioned at the very to of
 * the component.
 * @param {bool} disableOutside Disable close when click outside/press escape key.
 * @param {bool} large Increase size of Dialog.
 * @param {string/elem} message Message to show in dialog box. Can also take
 * React components to allow formatting. Custom components must be styled externally.
 * @param {func} onCancel Function to be run on cancel click.
 * @param {func} onConfirm Function to be run on confirm click.
 * @param {bool} open If the dialog is open or closed.
 * @param {string} title title to show in dialog box.
 * @param {bool} buttonLoading Bool that tells whether or not WarningButton is in loading state
 */
const WarnConfirmDialog = ({
    cancelText,
    confirmColor,
    confirmText,
    confirmButtonStyles,
    wrapperStyles,
    customIcon,
    iconTop,
    disableOutside,
    large,
    message,
    onCancel,
    onConfirm,
    open,
    title,
    buttonLoading
}) => {
    const classes = useStyles({ large, iconTop });

    const titleString = title || message || 'Are you sure you want to do this?';

    return (
        <Dialog
            open={open}
            onClose={(_, reason) => {
                if (
                    (reason !== 'backdropClick' && disableOutside) ||
                    (reason !== 'escapeKeyDown' && disableOutside)
                ) {
                    onCancel();
                }
            }}
            aria-labelledby="alert-dialog-title"
            maxWidth="md"
        >
            <div className={`${classes.wrap} ${wrapperStyles}`}>
                <div className={classes.icon}>
                    {customIcon || <AlertIcon />}
                </div>

                <div className={classes.contentWrap}>
                    <h2
                        id="alert-dialog-title"
                        className={
                            (title && !message) || (message && !title)
                                ? classes.message
                                : classes.title
                        }
                    >
                        {titleString}
                    </h2>
                    {title && message && (
                        <div className={classes.messageWrap}>
                            <div
                                data-testid="warnconfirmdialog-message"
                                className={classes.message}
                            >
                                {message}
                            </div>
                        </div>
                    )}
                </div>

                <DialogActions className={classes.buttons}>
                    {cancelText && (
                        <Button
                            onClick={onCancel}
                            color="grey"
                            variant="outlined"
                            className={classes.button}
                        >
                            {cancelText}
                        </Button>
                    )}
                    <WarningButton
                        onClick={onConfirm}
                        className={`${classes.button} ${confirmButtonStyles}`}
                        color={confirmColor}
                        loading={buttonLoading}
                        disableOnLoading
                        data-testid="warn-modal-confirm-button"
                        autoFocus
                    >
                        {confirmText}
                    </WarningButton>
                </DialogActions>
            </div>
        </Dialog>
    );
};

WarnConfirmDialog.propTypes = {
    cancelText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    confirmColor: PropTypes.string,
    confirmText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    confirmButtonStyles: PropTypes.string,
    wrapperStyles: PropTypes.string,
    customIcon: PropTypes.node,
    iconTop: PropTypes.bool,
    disableOutside: PropTypes.bool,
    large: PropTypes.bool,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    open: PropTypes.bool,
    title: PropTypes.string,
    buttonLoading: PropTypes.bool
};

WarnConfirmDialog.defaultProps = {
    cancelText: 'Cancel',
    confirmColor: null,
    confirmText: 'Confirm',
    confirmButtonStyles: '',
    wrapperStyles: '',
    customIcon: null,
    iconTop: false,
    disableOutside: false,
    large: false,
    message: null,
    open: false,
    title: null,
    buttonLoading: false
};

export default WarnConfirmDialog;
