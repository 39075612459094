import store from '@/state/store';
import axiosAuthenticated from './authenticatedRequestor';

export const testNetworkConnection = async () => {
    try {
        const res = await axiosAuthenticated.options('/user'); // If network is bad, the network status/retry mechanism will automatically kick in
        return res.data;
    } catch (err) {
        return false;
    }
};

export const startNetworkChecks = async () => {
    setInterval(() => {
        if (
            store.getState()?.networkStatus?.connected === true &&
            store.getState()?.user?.userLoggedIn
        ) {
            testNetworkConnection();
        }
    }, 30 * 1000);
};
