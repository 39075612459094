import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mui/material';
import { v4 as generateUuid } from 'uuid';
import { AddIcon } from '@/resources/icons';
import makeStyles from './styles';

const TaxAndDiscountButtons = ({
    applyFieldToItem,
    selectedDiscounts,
    selectedTaxes
}) => {
    const classes = makeStyles();

    const handleTaxButtonClick = () => {
        const tax = {
            name: '',
            value: '',
            type: 'percent',
            taxId: `tax-${generateUuid()}`
        };

        const newTaxes = [...selectedTaxes, tax];

        applyFieldToItem('taxes', newTaxes);
    };

    const handleDiscountButtonClick = () => {
        const discount = {
            name: '',
            value: '',
            type: 'percent',
            discountId: `discount-${generateUuid()}`
        };

        const newDiscounts = [...selectedDiscounts, discount];

        applyFieldToItem('discounts', newDiscounts);
    };

    return (
        <Grid container spacing={2} className={classes.taxDiscountContainer}>
            <Grid item xs={6}>
                <Button
                    fullWidth
                    variant="outlined"
                    onClick={handleTaxButtonClick}
                    className={classes.button}
                >
                    <AddIcon className={classes.addIcon} />
                    <span className={classes.accessibleWord}>Add </span>
                    <span className={classes.add}>Tax</span>
                </Button>
            </Grid>
            <Grid item xs={6}>
                <Button
                    fullWidth
                    variant="outlined"
                    onClick={handleDiscountButtonClick}
                    className={classes.button}
                >
                    <AddIcon className={classes.addIcon} />
                    <span className={classes.accessibleWord}>Add </span>
                    <span className={classes.add}>Discount</span>
                </Button>
            </Grid>
        </Grid>
    );
};

export default TaxAndDiscountButtons;

TaxAndDiscountButtons.propTypes = {
    applyFieldToItem: PropTypes.func.isRequired,
    selectedDiscounts: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
                .isRequired,
            name: PropTypes.string.isRequired
        })
    ),
    selectedTaxes: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
                .isRequired,
            name: PropTypes.string.isRequired
        })
    )
};

TaxAndDiscountButtons.defaultProps = {
    selectedDiscounts: [],
    selectedTaxes: []
};
