import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    plusSvg: {
        maxWidth: 10,
        maxHeight: 10,
        marginRight: theme.typography.pxToRem(3)
    },
    plusText: {
        fontSize: theme.typography.pxToRem(11)
    },
    container: {
        background: '#EAEAF2',
        color: theme.palette.text.secondary,
        minWidth: 44,
        maxHeight: 22,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 16,
        padding: '1px 0 1px 0'
    }
}));
