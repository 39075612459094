import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    deleteClient,
    getPaginatedClients
} from '@/modules/dataWrangler/dataWrangler';
import { DeleteIcon } from '@/resources/icons';
import { openDrawer } from '@/state/actions';
import { useToastNotification, useUrlParams } from '@/hooks';

import WarnConfirmDialog from '../../WarnConfirmDialog';
import ActionIconWrapper from '../ActionIconWrapper';

/**
 * Edit Icon for Client with dispatch attached
 * @param {string} id ID of the client to edit.
 */
export const EditClientIcon = ({ id }) => {
    const dispatch = useDispatch();

    const activeBusiness = useSelector((state) => state.user.activeBusiness);

    const handleClick = () => {
        const client = activeBusiness.clients.find(
            (element) => element.clientId === id
        );

        // @TODO If there is no client - pass error to sentry & handle silently
        if (client) {
            dispatch(
                openDrawer({
                    id: 'client',
                    title: 'Edit Client',
                    client,
                    updating: true
                })
            );
        }
    };

    return (
        <ActionIconWrapper
            action="edit"
            label="Edit"
            onClick={() => {
                handleClick();
            }}
            tooltip="Edit Client"
        />
    );
};

EditClientIcon.propTypes = {
    id: PropTypes.string.isRequired
};

/**
 * Delete Icon for Client with dispatch attached
 * @param {string} id ID of the client to delete.
 */
export const DeleteClientIcon = ({ id, client }) => {
    const [dialogActive, setDialogAction] = useState(false);
    const { triggerToast } = useToastNotification();

    const { getUrlParams } = useUrlParams();

    const handleClick = () => {
        setDialogAction(true);
    };

    const handleConfirmation = async () => {
        const currentUrlParams = getUrlParams();
        setDialogAction(false);
        await deleteClient(id);
        getPaginatedClients(currentUrlParams);
        triggerToast({
            message: `Client "${client}" Deleted `,
            action: 'delete',
            icon: <DeleteIcon />
        });
    };

    const handleCancellation = () => {
        setDialogAction(false);
    };

    return (
        <>
            <ActionIconWrapper
                action="delete"
                label="Delete"
                onClick={() => {
                    handleClick();
                }}
                tooltip="Delete Client"
            />
            <WarnConfirmDialog
                onCancel={() => handleCancellation()}
                onConfirm={() => handleConfirmation()}
                message={`Are you sure you want to delete "${client}"?`}
                cancelText="Cancel"
                confirmText="Delete Client"
                open={dialogActive}
            />
        </>
    );
};

DeleteClientIcon.propTypes = {
    id: PropTypes.string.isRequired,
    client: PropTypes.string.isRequired
};
