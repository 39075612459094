import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { tagTypeIcons } from '@/config';
import Link from '../Link';
import useStyles from './styles';

const TagAmountUsed = ({ amountUsed, tagId, type }) => {
    const classes = useStyles();

    const truncatedAmountUsed = Math.floor(amountUsed / 1000);

    let url =
        type === 'product' ||
        type === 'service' ||
        type === 'discount' ||
        type === 'tax'
            ? `/items/${type}${type === 'tax' ? 'es' : 's'}?tagIds=${tagId}`
            : `/${type}s?tagIds=${tagId}`;

    if (type === 'recurringInvoice') {
        url = `/recurring-invoices?tagIds=${tagId}`;
    }

    return (
        <Link
            to={url}
            className={classes.amountUsedContainer}
            id={`tag-amount-used-${type}`}
            onClick={(e) => e.stopPropagation()}
        >
            <div className={classes.typeIcon}>{tagTypeIcons[type]}</div>
            <Typography variant="body2" className={classes.amountUsedText}>
                {amountUsed >= 1000 ? `${truncatedAmountUsed}k+` : amountUsed}
            </Typography>
        </Link>
    );
};

export default TagAmountUsed;

TagAmountUsed.propTypes = {
    amountUsed: PropTypes.number,
    tagId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
};

TagAmountUsed.defaultProps = {
    amountUsed: 0
};
