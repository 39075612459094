import React from 'react';
import { Skeleton } from '@mui/material';
import styles from './styles';

const ThreeCardSkeleton = () => {
    const classes = styles();
    return (
        <div className={classes.skeletonWrap} data-testid="three-loading-cards">
            <Skeleton className={classes.subscriptionCardSkeleton} />
            <Skeleton className={classes.subscriptionCardSkeleton} />
            <Skeleton className={classes.subscriptionCardSkeleton} />
        </div>
    );
};

export default ThreeCardSkeleton;
