import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { validateCouponCode } from '@/modules/subscriptionManager';

export const useCouponData = (
    couponCode,
    isSubscriptionPage,
    cancelValidation
) => {
    const cleanedUpCoupon = couponCode?.toUpperCase();

    const [couponLoading, setCouponLoading] = useState(false);
    const couponCodeRef = useRef(cleanedUpCoupon);
    const couponIsIncorrect = useRef(false);

    // Let coupon checker reset if coupon code changes.
    if (couponCodeRef.current !== cleanedUpCoupon) {
        couponCodeRef.current = cleanedUpCoupon;
        couponIsIncorrect.current = false;
    }

    const subscriptionSource = useSelector(
        (state) => state.user?.activeBusiness?.subscription?.subscriptionSource
    );

    const couponData = useSelector((state) => state?.couponData);

    useEffect(() => {
        const validateAndSetCoupon = async () => {
            if (
                cleanedUpCoupon &&
                !couponLoading &&
                couponData?.code !== couponCodeRef.current &&
                subscriptionSource &&
                (subscriptionSource === 'unassigned' || !isSubscriptionPage) &&
                !couponIsIncorrect.current &&
                !cancelValidation
            ) {
                setCouponLoading(true);

                const couponRes = await validateCouponCode(cleanedUpCoupon);

                couponIsIncorrect.current = Boolean(!couponRes);

                setCouponLoading(false);
            }
        };

        validateAndSetCoupon();
    }, [
        cleanedUpCoupon,
        couponData?.code,
        couponLoading,
        subscriptionSource,
        isSubscriptionPage,
        cancelValidation
    ]);

    return {
        couponLoading,
        couponData,
        couponIsIncorrect: couponIsIncorrect.current,
        couponIsValid: Boolean(
            !couponLoading && Object.entries(couponData)?.length > 0
        )
    };
};
