export const voidInvoiceModalOptions = {
    message: 'Are you sure you want to void this invoice?',
    confirmText: 'Void Invoice',
    cancelText: 'Cancel',
    onCancel: () => {},
    onConfirm: () => {}
};

export const voidEstimateModalOptions = {
    message: 'Are you sure you want to void this estimate?',
    confirmText: 'Void Estimate',
    cancelText: 'Cancel',
    onCancel: () => {},
    onConfirm: () => {},
    customIcon: null
};

export const voidWithPaidDocumentStateOptions = {
    message:
        'You must refund any payments on this invoice before you can void it',
    confirmText: 'Ok',
    cancelText: null,
    onCancel: () => {},
    onConfirm: () => {}
};

export const deleteInvoiceModalOptions = {
    message: 'Are you sure you want to delete this invoice?',
    confirmText: 'Delete Invoice',
    cancelText: 'Cancel',
    onCancel: () => {},
    onConfirm: () => {}
};

export const deleteRecurringInvoiceModalOptions = {
    message: 'Are you sure you want to delete this recurring invoice?',
    confirmText: 'Delete Invoice',
    cancelText: 'Cancel',
    onCancel: () => {},
    onConfirm: () => {}
};

export const deleteEstimateModalOptions = {
    message: 'Are you sure you want to delete this estimate?',
    confirmText: 'Delete Estimate',
    cancelText: 'Cancel',
    onCancel: () => {},
    onConfirm: () => {},
    customIcon: null
};

export const saveNegativeInvoiceTotalModalOptions = {
    message:
        'This invoice has a negative total and cannot be saved. Please enter a positive total before saving.',
    confirmText: 'Ok',
    cancelText: null,
    onCancel: () => {},
    onConfirm: () => {}
};

export const saveNegativeEstimateTotalModalOptions = {
    message:
        'This estimate has a negative total and cannot be saved. Please enter a positive total before saving.',
    confirmText: 'Ok',
    cancelText: null,
    onCancel: () => {},
    onConfirm: () => {},
    customIcon: null
};

export const sendNegativeInvoiceTotalModalOptions = {
    message:
        'This invoice has a negative total and cannot be sent. Please enter a positive total before sending.',
    confirmText: 'Ok',
    cancelText: null,
    onCancel: () => {},
    onConfirm: () => {}
};

export const saveNegativeRecurringInvoiceTotalModalOptions = {
    message:
        'This invoice has a negative total and cannot be sent. Please enter a positive total before sending.',
    confirmText: 'Ok',
    cancelText: null,
    onCancel: () => {},
    onConfirm: () => {}
};

export const sendNegativeEstimateTotalModalOptions = {
    message:
        'This estimate has a negative total and cannot be sent. Please enter a positive total before sending.',
    confirmText: 'Ok',
    cancelText: null,
    onCancel: () => {},
    onConfirm: () => {},
    customIcon: null
};

export const downloadInvoiceNegativeTotalModalOptions = {
    message:
        'This invoice has a negative total and cannot be downloaded. Please enter a positive total before downloading.',
    confirmText: 'Ok',
    cancelText: null,
    onCancel: () => {},
    onConfirm: () => {}
};

export const downloadEstimateNegativeTotalModalOptions = {
    message:
        'This estimate has a negative total and cannot be downloaded. Please enter a positive total before downloading.',
    confirmText: 'Ok',
    cancelText: null,
    onCancel: () => {},
    onConfirm: () => {},
    customIcon: null
};

export const previewInvoiceNegativeTotalModalOptions = {
    message:
        'This invoice has a negative total and cannot be previewed. Please enter a positive total before previewing.',
    confirmText: 'Ok',
    cancelText: null,
    onCancel: () => {},
    onConfirm: () => {}
};

export const previewRecurringInvoiceNegativeTotalModalOptions = {
    message:
        'This invoice has a negative total and cannot be previewed. Please enter a positive total before previewing.',
    confirmText: 'Ok',
    cancelText: null,
    onCancel: () => {},
    onConfirm: () => {}
};

export const previewEstimateNegativeTotalModalOptions = {
    message:
        'This estimate has a negative total and cannot be previewed. Please enter a positive total before previewing.',
    confirmText: 'Ok',
    cancelText: null,
    onCancel: () => {},
    onConfirm: () => {},
    customIcon: null
};

export const shareInvoiceNegativeTotalModalOptions = {
    message:
        'This invoice has a negative total and cannot be shared. Please enter a positive total before sharing.',
    confirmText: 'Ok',
    cancelText: null,
    onCancel: () => {},
    onConfirm: () => {}
};

export const shareEstimateNegativeTotalModalOptions = {
    message:
        'This estimate has a negative total and cannot be shared. Please enter a positive total before sharing.',
    confirmText: 'Ok',
    cancelText: null,
    onCancel: () => {},
    onConfirm: () => {},
    customIcon: null
};

export const sendInvoiceModalOptions = {
    message:
        'Once you send this invoice, you will no longer be able to edit it.',
    confirmText: 'Send Invoice',
    cancelText: 'Continue Working',
    onCancel: () => {},
    onConfirm: () => {}
};

export const generateInvoiceLinkOptions = {
    message:
        'Once you generate a link of this invoice, you will no longer be able to edit it. The link will then be copied to clipboard.',
    confirmText: 'Generate Link',
    cancelText: 'Continue Working',
    onCancel: () => {},
    onConfirm: () => {}
};

export const sendEstimateModalOptions = {
    message:
        'Once you send this estimate, you will no longer be able to edit it.',
    confirmText: 'Send Estimate',
    cancelText: 'Continue Working',
    onCancel: () => {},
    onConfirm: () => {},
    customIcon: null
};

export const generateEstimateLinkOptions = {
    message:
        'Once you generate a link of this estimate, you will no longer be able to edit it. The link will then be copied to clipboard.',
    confirmText: 'Generate Link',
    cancelText: 'Continue Working',
    onCancel: () => {},
    onConfirm: () => {},
    customIcon: null
};

export const downloadInvoiceModalOptions = {
    message:
        'Once you download this invoice, you will no longer be able to edit it.',
    confirmText: 'Download Invoice',
    cancelText: 'Continue Working',
    onCancel: () => {},
    onConfirm: () => {}
};

export const downloadEstimateModalOptions = {
    message:
        'Once you download this estimate, you will no longer be able to edit it.',
    confirmText: 'Download Estimate',
    cancelText: 'Continue Working',
    onCancel: () => {},
    onConfirm: () => {},
    customIcon: null
};

export const copyInvoiceLinkModalOptions = {
    message:
        'Once you share the link of this invoice, you will no longer be able to edit it.',
    confirmText: 'Share Link',
    cancelText: 'Continue Working',
    onCancel: () => {},
    onConfirm: () => {}
};

export const approveEstimateModalOptions = {
    message:
        'Once you approve this estimate, you will no longer be able to edit it.',
    confirmText: 'Approve',
    cancelText: 'Cancel',
    onCancel: () => {},
    onConfirm: () => {},
    customIcon: null
};

export const copyEstimateLinkModalOptions = {
    message:
        'Once you share the link of this estimate, you will no longer be able to edit it.',
    confirmText: 'Share Link',
    cancelText: 'Continue Working',
    onCancel: () => {},
    onConfirm: () => {},
    customIcon: null
};

export const declineEstimateModalOptions = {
    message:
        'Once you decline this estimate, you will no longer be able to edit it.',
    confirmText: 'Decline',
    cancelText: 'Cancel',
    onCancel: () => {},
    onConfirm: () => {},
    confirmColor: '#C20101',
    customIcon: null
};

export const addPaymentReceiptModalOptions = (actionType) => ({
    message:
        actionType === 'add payment'
            ? 'Once you continue, you will no longer be able to edit this receipt.'
            : `In order to ${actionType}, you must add a payment. You will no longer be able to edit this receipt.`,
    confirmText: 'Add Payment',
    cancelText: 'Cancel',
    onCancel: () => {},
    onConfirm: () => {}
});

export const sendNegativeReceiptTotalModalOptions = {
    message:
        'This receipt has a negative total and cannot be sent. Please enter a positive total before sending.',
    confirmText: 'Ok',
    cancelText: null,
    onCancel: () => {},
    onConfirm: () => {}
};

export const downloadReceiptNegativeTotalModalOptions = {
    message:
        'This receipt has a negative total and cannot be downloaded. Please enter a positive total before downloading.',
    confirmText: 'Ok',
    cancelText: null,
    onCancel: () => {},
    onConfirm: () => {}
};

export const previewReceiptNegativeTotalModalOptions = {
    message:
        'This receipt has a negative total and cannot be previewed. Please enter a positive total before previewing.',
    confirmText: 'Ok',
    cancelText: null,
    onCancel: () => {},
    onConfirm: () => {}
};

export const shareReceiptNegativeTotalModalOptions = {
    message:
        'This receipt has a negative total and cannot be shared. Please enter a positive total before sharing.',
    confirmText: 'Ok',
    cancelText: null,
    onCancel: () => {},
    onConfirm: () => {}
};

export const voidReceiptModalOptions = {
    message: 'Are you sure you want to void this receipt?',
    confirmText: 'Void Receipt',
    cancelText: 'Cancel',
    onCancel: () => {},
    onConfirm: () => {}
};

export const voidReceiptWithPaidDocumentStateOptions = {
    message:
        'You must refund any payments on this receipt before you can void it',
    confirmText: 'Ok',
    cancelText: null,
    onCancel: () => {},
    onConfirm: () => {}
};

export const deleteReceiptModalOptions = {
    message: 'Are you sure you want to delete this receipt?',
    confirmText: 'Delete Receipt',
    cancelText: 'Cancel',
    onCancel: () => {},
    onConfirm: () => {}
};

export const saveNegativeReceiptTotalModalOptions = {
    message:
        'This receipt has a negative total and cannot be saved. Please enter a positive total before saving.',
    confirmText: 'Ok',
    cancelText: null,
    onCancel: () => {},
    onConfirm: () => {}
};

export const addPaymentNegativeReceiptTotalModalOptions = {
    message:
        'This receipt has a negative total and payment cannot be added. Please enter a positive total before adding payment.',
    confirmText: 'Ok',
    cancelText: null,
    onCancel: () => {},
    onConfirm: () => {}
};

export const convertEstimateModalOptions = {
    message: 'Are you sure you want to copy this Estimate into an Invoice?',
    confirmText: 'Copy To Invoice',
    cancelText: 'Cancel',
    onCancel: () => {},
    onConfirm: () => {}
};

export const unapprovedConversionModalOptions = {
    message: 'Only approved Estimates can be copied to an Invoice.',
    confirmText: 'Ok',
    cancelText: null,
    onCancel: () => {},
    onConfirm: () => {}
};

export const stopRecurringInvoiceModalOptions = {
    message:
        'Are you sure you want to stop this recurring sequence from generating future invoices? This action cannot be undone.',
    confirmText: 'Yes, Stop',
    cancelText: 'Back',
    onCancel: () => {},
    onConfirm: () => {}
};

export const markUnpaidInvoiceModalOptions = {
    message: 'Are you sure you want to mark this invoice as unpaid?',
    confirmText: 'Mark as Unpaid',
    cancelText: 'Cancel',
    onCancel: () => {},
    onConfirm: () => {}
};
