import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    multiselectInput: {
        paddingTop: `${theme.spacing(4)} !important`,
        paddingBottom: theme.spacing(1)
    },
    listbox: {
        ...theme.typography.body2,
        fontWeight: 600
    }
}));
