import loadable from '@loadable/component';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography
} from '@mui/material';
import { Check, Block } from '@mui/icons-material';
import { ChevronGrey } from '@/resources/icons';
import { setQrCodeURL } from '@/actions';
import { removeQRCodeConnection } from '@/modules/dataWrangler/dataWrangler';
import { paymentMethodPreviewData } from '@/config';
import ConnectButton from '../ConnectButton';
import PaymentMethodAccordionInner from '../PaymentMethodAccordionInner';
import useStyles from './styles';

const QRCodeStepper = loadable(() => import('@/components/QRCodeStepper'));

const PaymentMethodAccordion = ({
    active,
    paymentChannels,
    merchant,
    connections,
    preferredPaymentConnections
}) => {
    const [expanded, setExpanded] = useState(false);
    const [open, setOpen] = useState(false);
    const [disconnectPending, setDisconnectPending] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();

    const findConnection = (application) =>
        connections.find((item) => item?.application === application);

    const openDrawer = async () => {
        const connection = findConnection(merchant.slug);
        const connected = connection?.connected && connection?.enabled;

        if (!connected) setOpen(true);
        else {
            setDisconnectPending(true);
            await removeQRCodeConnection(merchant.slug);
            setDisconnectPending(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
        dispatch(setQrCodeURL(''));
    };

    const renderPaymentChannels = () =>
        paymentChannels.map((paymentChannel) => {
            let paymentChannelClass = classes.paymentChannel;

            if (active) {
                paymentChannelClass = paymentChannel.enabled
                    ? classes.paymentChannelEnabled
                    : classes.paymentChannelDisabled;
            }

            return (
                paymentChannel.channelAllowed && (
                    <div
                        className={paymentChannelClass}
                        key={paymentChannel.name}
                    >
                        <Typography variant="body2">
                            {paymentChannel.name}
                        </Typography>
                        {active &&
                            (paymentChannel.enabled ? (
                                <Check
                                    className={classes.paymentChannelPillIcon}
                                />
                            ) : (
                                <Block
                                    className={classes.paymentChannelPillIcon}
                                />
                            ))}
                    </div>
                )
            );
        });

    return (
        <>
            <Accordion
                expanded={expanded}
                onChange={() => setExpanded(!expanded)}
                className={classes.accordionContainer}
                elevation={0}
                disableGutters
            >
                <AccordionSummary
                    expandIcon={<ChevronGrey className={classes.expandIcon} />}
                    classes={{ expandIconWrapper: classes.expandIconWrapper }}
                >
                    <Grid container item>
                        <Grid item container xs={12} alignItems="center">
                            <div className={classes.iconContainer}>
                                {merchant.icon}
                            </div>
                            <Typography
                                component="h5"
                                className={classes.merchantName}
                            >
                                {merchant.name}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            className={classes.lowerWrapper}
                        >
                            <Grid
                                item
                                container
                                className={classes.paymentChannelContainer}
                                xs
                            >
                                {renderPaymentChannels()}
                            </Grid>
                            <Grid item container xs justifyContent="flex-end">
                                <ConnectButton
                                    connections={connections}
                                    merchant={merchant}
                                    openDrawer={openDrawer}
                                    disconnectPending={disconnectPending}
                                    active={active}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                    <PaymentMethodAccordionInner
                        merchant={merchant}
                        active={active}
                        paymentChannels={paymentChannels}
                        connections={connections}
                        preferredPaymentConnections={
                            preferredPaymentConnections
                        }
                    />
                </AccordionDetails>
            </Accordion>
            <QRCodeStepper
                exampleImage={
                    paymentMethodPreviewData[merchant.slug]?.qrExample ||
                    paymentMethodPreviewData[merchant.slug]?.src
                }
                merchant={merchant}
                onClose={handleClose}
                open={open}
                UploadInstructions={
                    paymentMethodPreviewData[merchant.slug]?.instructions
                }
            />
        </>
    );
};

export default PaymentMethodAccordion;

PaymentMethodAccordion.propTypes = {
    active: PropTypes.bool,
    paymentChannels: PropTypes.arrayOf(PropTypes.object),
    merchant: PropTypes.object.isRequired,
    connections: PropTypes.arrayOf(PropTypes.object),
    preferredPaymentConnections: PropTypes.object.isRequired
};

PaymentMethodAccordion.defaultProps = {
    active: false,
    paymentChannels: [],
    connections: []
};
