import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    close: {
        position: 'absolute',
        top: theme.typography.pxToRem(10),
        right: theme.typography.pxToRem(10),
        color: '#35373D',
        height: theme.typography.pxToRem(36),
        width: theme.typography.pxToRem(36),
        backgroundColor: 'transparent',
        padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(
            18
        )}`,
        lineHeight: '1em',
        transition: 'all 250ms',
        '&:hover': {
            backgroundColor: '#D2E6FF',
            opacity: '0.8'
        },
        [theme.breakpoints.up('sm')]: {
            top: `calc(env(safe-area-inset-top) + ${theme.typography.pxToRem(
                21
            )})`,
            right: `calc(env(safe-area-inset-right) + ${theme.typography.pxToRem(
                21
            )})`
        }
    },
    closeSpan: theme.visuallyHidden
}));
