import React from 'react';
import {
    Collapse,
    List,
    ListItem,
    ListItemText,
    Typography,
    SvgIcon
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from '@/components/common';
import { accountMenuItems } from '@/config/menuItems';
import useStyles from './styles';

const AccountSection = ({ handleMenuClose, setToggleActive, toggleActive }) => {
    const toggleRotate = toggleActive === 'account';
    const classes = useStyles(toggleRotate);

    const userName =
        useSelector((state) => state?.user?.activeBusiness?.name) || '';

    return (
        <div className={classes.account} data-testid="account-dropdown">
            <ListItem
                button
                onClick={() =>
                    setToggleActive(toggleActive === 'account' ? '' : 'account')
                }
                className={classes.accountButton}
            >
                <ListItemText>
                    <span className={classes.accountTitle}>
                        <span className={classes.accountTitleSpan}>
                            {userName}
                        </span>
                        <ExpandMoreIcon className={classes.accountToggle} />
                    </span>
                </ListItemText>
            </ListItem>
            <Collapse
                in={toggleActive === 'account'}
                timeout="auto"
                unmountOnExit
            >
                <List
                    className={classes.accountListWrap}
                    component="div"
                    disablePadding
                >
                    {accountMenuItems.map((item) => {
                        const onClickFunc = () => {
                            if (item?.onClick) {
                                item.onClick();
                            }
                            handleMenuClose();
                        };

                        return (
                            <ListItem
                                key={item.path}
                                className={classes.menuItem}
                            >
                                <Link
                                    to={item.path}
                                    className={classes.menuLink}
                                    title={item?.linkTitle}
                                    onClick={onClickFunc}
                                >
                                    <SvgIcon
                                        style={{ width: 16, marginRight: 14 }}
                                    >
                                        {item.icon}
                                    </SvgIcon>

                                    <Typography
                                        variant="body2"
                                        className={
                                            classes.accountItemsTypography
                                        }
                                        component="span"
                                        color={
                                            item.title === 'Logout'
                                                ? 'error'
                                                : 'textPrimary'
                                        }
                                    >
                                        {item.title}
                                    </Typography>
                                </Link>
                            </ListItem>
                        );
                    })}
                </List>
            </Collapse>
        </div>
    );
};

AccountSection.propTypes = {
    handleMenuClose: PropTypes.func.isRequired,
    setToggleActive: PropTypes.func.isRequired,
    toggleActive: PropTypes.string
};

AccountSection.defaultProps = {
    toggleActive: null
};

export default AccountSection;
