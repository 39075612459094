import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid, Paper, Box } from '@mui/material';
import { Layout } from '@/containers';
import { settingsMenuItems } from '@/config/menuItems';
import { TabBar, PageTitle, SEO } from '@/components/common';
import { SettingsActive } from '@/resources/icons';
import {
    useCheckAuthState,
    useCheckHasBusinessLoaded,
    useSetPageLoad
} from '@/hooks';
import SubscriptionBanner from '@/components/SubscriptionBanner';
import SettingsSkeleton from './SettingsSkeleton';
import SettingsRoute from './SettingsRoute';
import styles from './styles';

const SettingsPage = ({ pathname }) => {
    const currentPage = settingsMenuItems.find(
        (item) => item.path === pathname
    );
    const classes = styles({ color: currentPage?.color });
    const hasBusinessLoaded = useCheckHasBusinessLoaded();
    const { registeredUser } = useCheckAuthState();
    const { isLoading } = useSetPageLoad(hasBusinessLoaded);

    const activeBusiness = useSelector((state) => state?.user?.activeBusiness);
    const userSettings = useSelector((state) => state?.user?.settings);

    return (
        <Layout>
            <SEO title="Settings" />
            <Grid container direction="row">
                <Grid item xs={12}>
                    <div
                        id="cio_message"
                        className={classes.cio_message}
                        data-testid="cio_message"
                    />
                    <SubscriptionBanner />
                </Grid>

                <Grid item xs={12}>
                    {!isLoading && (
                        <Paper
                            variant="outlined"
                            className={classes.settingsPaper}
                        >
                            <Box
                                sx={{
                                    display: { xs: 'none', lg: 'block' }
                                }}
                            >
                                <PageTitle
                                    icon={<SettingsActive />}
                                    title="Settings"
                                />
                            </Box>

                            <Box
                                sx={{
                                    display: { xs: 'none', lg: 'block' }
                                }}
                                className={classes.tabbar}
                            >
                                <TabBar
                                    className={classes.tabbar}
                                    items={settingsMenuItems}
                                    pathname={pathname}
                                />
                            </Box>

                            <Box
                                sx={{
                                    display: { xs: 'block', lg: 'none' }
                                }}
                            >
                                <div className={classes.mobileHeader}>
                                    <div className={classes.mobileIcon}>
                                        {currentPage?.icon}
                                    </div>
                                    <h2 className={classes.mobileTitle}>
                                        {currentPage?.title}
                                    </h2>
                                </div>
                            </Box>

                            <SettingsRoute
                                activeBusiness={activeBusiness}
                                registeredUser={registeredUser}
                                pathname={pathname}
                                userSettings={userSettings}
                            />
                        </Paper>
                    )}

                    {isLoading && <SettingsSkeleton />}
                </Grid>
            </Grid>
        </Layout>
    );
};

SettingsPage.propTypes = {
    pathname: PropTypes.string
};

SettingsPage.defaultProps = {
    pathname: undefined
};

export default SettingsPage;
