import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Skeleton } from '@mui/material';
import useStyles from './styles';

const TableSkeleton = ({ isMobile, isClient }) => {
    const classes = useStyles();

    return (
        <Grid
            item
            className={`${classes.tableSkeleton} ${
                isClient ? classes.clientMargin : ''
            }`}
            container
            xs={12}
            spacing={1}
        >
            {!isClient && isMobile && (
                <Grid container item justifyContent="space-between" xs={12}>
                    <Grid item>
                        <Skeleton
                            width="150px"
                            height="45px"
                            variant="rectangular"
                            data-testid="title-skeleton"
                        />
                    </Grid>
                    <Grid item>
                        <Skeleton
                            width="250px"
                            height="45px"
                            variant="rectangular"
                            data-testid="button-skeleton"
                        />
                    </Grid>
                </Grid>
            )}
            {isClient && isMobile && (
                <Grid item xs={12}>
                    <Skeleton
                        width="100%"
                        height={45}
                        variant="rectangular"
                        data-testid="button-skeleton"
                    />
                </Grid>
            )}
            {isClient ? (
                <Grid container item justifyContent="space-between" xs={12}>
                    <Grid item xs={isMobile ? 12 : 7}>
                        <Skeleton
                            width={isMobile ? '100%' : '75%'}
                            height={isMobile ? 100 : 45}
                            variant="rectangular"
                            data-testid="search-skeleton"
                        />
                    </Grid>
                    {!isMobile && (
                        <Grid item>
                            <Skeleton
                                width="250px"
                                height={45}
                                variant="rectangular"
                                data-testid="button-skeleton"
                            />
                        </Grid>
                    )}
                </Grid>
            ) : (
                <Grid container item justifyContent="space-between" xs={12}>
                    <Grid item xs={isMobile ? 12 : 7}>
                        <Skeleton
                            width="100%"
                            height={isMobile ? 140 : 45}
                            variant="rectangular"
                            data-testid="search-skeleton"
                        />
                    </Grid>
                    {!isMobile && (
                        <Grid item>
                            <Skeleton
                                width="250px"
                                height={isMobile ? 160 : 45}
                                variant="rectangular"
                                data-testid="button-skeleton"
                            />
                        </Grid>
                    )}
                </Grid>
            )}

            {Array.from(isMobile ? [1] : [1, 2, 3]).map((item) => (
                <Grid item key={item} xs={12}>
                    <Skeleton
                        width="100%"
                        height={isMobile ? 180 : 50}
                        variant="rectangular"
                        data-testid="listing-skeleton"
                    />
                </Grid>
            ))}
        </Grid>
    );
};

TableSkeleton.propTypes = {
    isMobile: PropTypes.bool,
    isClient: PropTypes.bool
};

TableSkeleton.defaultProps = {
    isMobile: false,
    isClient: false
};

export default TableSkeleton;
