import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

/**
 * Pure component for the Burger button.
 * @param {bool} active Is the burger active or inactive.
 * @param {func} setActive Function to trigger active state.
 */
const Burger = ({ active, setActive }) => {
    const classes = useStyles({ active });

    return (
        <button
            aria-controls="navigation"
            aria-label="Menu"
            className={`${classes.burger} mn-burger`}
            onClick={setActive}
            type="button"
        >
            <span className={classes.catcher} />
            <span className={classes.burgerLine1} />
            <span className={classes.burgerLine2} />
            <span className={classes.burgerLine3} />
        </button>
    );
};

Burger.propTypes = {
    active: PropTypes.bool,
    setActive: PropTypes.func.isRequired
};

Burger.defaultProps = {
    active: false
};

export default Burger;
