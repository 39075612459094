import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, SvgIcon } from '@mui/material';
import { useLocale, useTimezone } from '@/hooks';
import {
    formatCurrency,
    formatDate,
    setPlanIcon,
    toWholeCurrency
} from '@/util';
import styles from './styles';

const PlanChangeDialog = ({ currentPlan, newPlan, isUpgrading }) => {
    const classes = styles();
    const locale = useLocale();
    const timezone = useTimezone();

    const { product: currentProduct } = currentPlan;

    const getFormattedPrice = (price) =>
        formatCurrency({
            amount: toWholeCurrency(price),
            locale: 'en-US',
            currency: 'USD'
        });

    return (
        <>
            {/* Current Plan */}
            <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Typography
                    variant="body1"
                    component="span"
                    className={classes.lightWeightText}
                >
                    Current Plan
                </Typography>
                <SvgIcon
                    component={setPlanIcon(currentProduct?.name)}
                    className={classes.planIcon}
                />
                <Typography
                    variant="body1"
                    component="span"
                    color="textPrimary"
                >
                    {`${currentProduct?.name}: ${getFormattedPrice(
                        currentProduct?.priceInCents
                    )} ${
                        currentProduct?.interval === 1 &&
                        currentProduct?.intervalUnit === 'month'
                            ? '/month'
                            : '/year'
                    }`}
                </Typography>
            </Grid>

            {/* New Plan */}
            <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Typography
                    variant="body1"
                    component="span"
                    className={classes.lightWeightText}
                >
                    New Plan
                </Typography>
                <SvgIcon
                    component={setPlanIcon(newPlan?.name)}
                    className={classes.planIcon}
                />
                <Typography
                    variant="body1"
                    component="span"
                    color="textPrimary"
                >
                    {`${newPlan?.name}: ${getFormattedPrice(newPlan?.price)} ${
                        newPlan?.interval === 1 &&
                        newPlan?.intervalUnit === 'month'
                            ? '/month'
                            : '/year'
                    }`}
                </Typography>
            </Grid>

            {/* Effective Date */}
            <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="baseline"
            >
                <Typography
                    variant="body1"
                    component="span"
                    className={classes.effectiveDateText}
                >
                    Effective Date
                </Typography>

                <Typography
                    variant="body1"
                    component="span"
                    color="textPrimary"
                >
                    {isUpgrading
                        ? 'Immediate'
                        : `${formatDate({
                              isoDate: currentPlan?.currentPeriodEndsAt,
                              locale,
                              timezone
                          })}`}
                </Typography>
            </Grid>
        </>
    );
};

PlanChangeDialog.propTypes = {
    currentPlan: PropTypes.object.isRequired,
    newPlan: PropTypes.object.isRequired,
    isUpgrading: PropTypes.bool
};

PlanChangeDialog.defaultProps = {
    isUpgrading: false
};

export default PlanChangeDialog;
