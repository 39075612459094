import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    drawerPaper: {
        width: '100%',
        overflowX: 'hidden',
        [theme.breakpoints.up('sm')]: {
            width: theme.typography.pxToRem(475)
        }
    },
    container: {
        position: 'relative',
        height: '100%',
        padding: `env(safe-area-inset-top) ${theme.typography.pxToRem(
            15
        )} env(safe-area-inset-bottom) ${theme.typography.pxToRem(15)}`
    },
    drawerRootStyles: {
        zIndex: 1202
    }
}));
