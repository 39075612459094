/* eslint import/no-cycle: "off"  */
import store from '@/state/store';

import { globalPageCount } from '@/config';

import {
    overwriteDocument,
    setDocumentState,
    setVoidDocumentPending,
    setVoidDocumentComplete,
    fetchListingItems,
    noListingItems,
    overwriteListingItems,
    setInvoiceAsUnpaidClientMethod
} from '@/state/actions';

import {
    convertDocumentFromApiSetLocalization,
    createSaveableObject,
    filterArgFlattener
} from '@/util';

import * as subscription from '../subscriptionManager';
import axiosAuthenticated from '../authenticatedRequestor';
import axiosUnauthenticated from '../unauthenticatedRequestor';
import * as segmentManager from '../segment/segmentManager';

export const createInvoice = async (invoice) => {
    const { activeBusiness } = store.getState().user;
    const tagIds = invoice?.tags?.map((tag) => tag.tagId) || [];

    try {
        const response = await axiosAuthenticated.post(
            `/businesses/${activeBusiness.businessId}/invoices`,
            {
                ...invoice,
                tagIds,
                design: activeBusiness.documentCustomizations.design,
                disablePaymentStamp:
                    activeBusiness.documentCustomizations.disablePaymentStamp,
                logo: activeBusiness.logo || ''
            }
        );

        // Update the subscription object with new invoice count.
        subscription.getUserSubscription(activeBusiness.businessId);

        const newInvoice = convertDocumentFromApiSetLocalization(response.data);

        return newInvoice;
    } catch {
        return false;
    }
};

export const duplicateInvoice = async (invoiceId) => {
    // Get the active business.
    const { activeBusiness } = store.getState().user;
    const { businessId } = activeBusiness;

    // Get the specific invoice to be duplicated.
    const res = await axiosAuthenticated.get(
        `/businesses/${businessId}/documents/${invoiceId}`
    );

    const foundInvoice = res?.data;

    if (foundInvoice) {
        const convertedInvoice =
            convertDocumentFromApiSetLocalization(foundInvoice);
        const invoice = { ...convertedInvoice };

        const originalIssuedDate = new Date(invoice.issuedDate).getTime();
        const originalDueDate = new Date(invoice.dueDate).getTime();

        // Calculate the number of milliseconds between issued date and due date.
        const originalTermsMilliseconds = originalDueDate - originalIssuedDate;

        // Update issued date
        invoice.issuedDate = new Date(Date.now()).toISOString();

        // Update due date with correct term gap.
        invoice.dueDate = new Date(
            Date.now() + originalTermsMilliseconds
        ).toISOString();

        // Remove custom label to allow for a new one
        invoice.customLabel = undefined;

        // Pass the new invoice object to API.
        const newInvoice = await createInvoice(
            createSaveableObject({
                type: 'invoice',
                currentObject: invoice,
                activeBusiness
            })
        );

        await segmentManager.documentStarted('invoice');

        return newInvoice;
    }

    return false;
};

export const shareInvoice = async (
    documentId,
    mode,
    email,
    emailSubject,
    emailBody
) => {
    const { businessId } = store.getState().user.activeBusiness;

    try {
        const response = await axiosAuthenticated.post(
            `/businesses/${businessId}/invoices/${documentId}/sharing`,
            {
                action: mode,
                emailAddress: email,
                subject: emailSubject,
                body: emailBody
            }
        );

        const newInvoice = convertDocumentFromApiSetLocalization(response.data);

        store.dispatch(
            overwriteDocument({
                viewMode: 'edit',
                ...newInvoice
            })
        );

        return response.data;
    } catch (err) {
        return err.response;
    }
};

export const getInvoices = async (args) => {
    if (store.getState().user.temporaryUser) {
        store.dispatch(noListingItems({ type: 'invoice' }));
        return;
    }

    store.dispatch(fetchListingItems({ type: 'invoice' }));

    const { businessId } = store.getState().user.activeBusiness;

    const allArgs = { pageSize: globalPageCount, ...filterArgFlattener(args) };
    const response = await axiosAuthenticated.get(
        `/businesses/${businessId}/invoices`,
        { params: { sortOrder: 'desc', sortType: 'createdAt', ...allArgs } }
    );

    const { items, itemsMatchingRequest, pageSize, totalItems } = response.data;

    // Destructure into flatter object
    const listingItems = items.map((invoice) =>
        convertDocumentFromApiSetLocalization(invoice)
    );

    const totalPages = Math.ceil(itemsMatchingRequest / pageSize);

    store.dispatch(
        overwriteListingItems({
            type: 'invoice',
            items: listingItems,
            totalPages,
            totalItems
        })
    );
};

export const updateInvoice = async (invoice) => {
    const { businessId } = store.getState().user.activeBusiness;

    // If draft
    // Update invoice issuedDate

    await Promise.all([
        axiosAuthenticated.put(
            `/businesses/${businessId}/invoices/${invoice.documentId}`,
            invoice
        )
    ]);

    return true;
};

export const deleteInvoice = async (documentId) => {
    const { businessId } = store.getState().user.activeBusiness;

    await axiosAuthenticated.delete(
        `/businesses/${businessId}/invoices/${documentId}`
    );
};

export const voidInvoice = async (invoice) => {
    const { businessId } = store.getState().user.activeBusiness;

    store.dispatch(setVoidDocumentPending());

    await axiosAuthenticated.post(
        `/businesses/${businessId}/invoices/${invoice.documentId}/void`
    );

    const voidedInvoice = {
        ...invoice,
        documentState: 'void'
    };
    store.dispatch(setDocumentState({ document: voidedInvoice }));
    store.dispatch(setVoidDocumentComplete());
};

/**
 * Get invoice CSV
 * @param  {object} args Filter arguments.
 */
export const downloadInvoicesCsv = async (args) => {
    const { businessId } = store.getState().user.activeBusiness;

    try {
        const response = await axiosAuthenticated.get(
            `/businesses/${businessId}/invoices/export`,
            {
                params: filterArgFlattener(args)
            }
        );

        return response.data;
    } catch (err) {
        return false;
    }
};

export const invoiceMarkAsPaid = async ({ jwt, amount, method }) => {
    const res = await axiosUnauthenticated.post(
        `/public/documents/${jwt}/invoices/mark-as-paid`,
        {
            amount,
            method
        }
    );

    // If status is 200, return true
    if (res.status === 200) {
        return true;
    }

    return false;
};

export const invoiceMarkAsUnpaid = async (documentId) => {
    const { businessId } = store.getState().user.activeBusiness;

    store.dispatch(setInvoiceAsUnpaidClientMethod());

    const res = await axiosAuthenticated.delete(
        `/businesses/${businessId}/invoices/${documentId}/mark-as-paid`
    );

    // If status is 200, return true
    if (res.status === 200) {
        return true;
    }

    return false;
};
