import { SET_QR_CODE_URL } from '@/constants';

const defaultState = { open: false, imageURL: '' };
export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_QR_CODE_URL:
            return { ...state, imageURL: action.imageURL };
        default:
            return { ...state };
    }
};
