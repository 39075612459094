import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom-v5-compat';

import defaultFacebookImage from '@/resources/images/social/facebook_fallback.jpg';
import defaultTwitterImage from '@/resources/images/social/twitter_fallback.jpg';

/**
 * Creates a seo friendly HTML title.
 * @param  {string} productName Title of the product/app. i.e. 'Invoice Maker'.
 * @param  {string} pageTitle Titles of the page. i.e. 'Login'.
 * @return {string} Fully concatinated page title.
 */
const buildPageTitle = (productName, pageTitle) => {
    if (!pageTitle) {
        return productName;
    }
    return `${productName} | ${pageTitle}`;
};

/**
 * Simple URL concatinator to easily create absolute URLs.
 * @param  {string} item Item to link to.
 * @return {string} Full absolute URL.
 */
const buildUrl = (item) => `${process.env.REACT_APP_BASE_URL}${item}`;

/**
 * Create a pages meta data within components.
 * @param {string} title The title of the page. e.g. 'Login' or 'Create Account'.
 * @param {string} facebookTitle Custom title for Facebook - Allows for tailored SEO stuff.
 * @param {string} twitterTitle Custom title for Twitter - Allows for tailored SEO stuff.
 * @param {string} facebookImage Unique banner image for Facebook.
 * @param {string} twitterImage Unique banner image for Twitter, fallback to Facebook.
 * @param {string} description Page description. Standard SEO stuff.
 * @param {string} facebookDescription Custom page description for Facebook.
 * @param {string} twitterDescription Custom page description for Twitter.
 */
const SEO = ({
    title,
    facebookTitle,
    twitterTitle,
    facebookImage,
    twitterImage,
    description,
    facebookDescription,
    twitterDescription
}) => {
    const location = useLocation();

    const productName = 'Invoice Maker';
    const twitterHandle = '@invoicemakercom';
    const fbAppId = '459756648267105';

    return (
        <Helmet>
            <title>{buildPageTitle(productName, title)}</title>
            <meta name="description" content={description} />
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/apple-touch-icon.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-32x32.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/favicon-16x16.png"
            />
            <link rel="manifest" href="/manifest.json" />
            <link
                rel="mask-icon"
                href="/safari-pinned-tab.svg"
                color="#ffffff"
            />
            <link rel="shortcut icon" href="/favicon.ico" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="msapplication-config" content="/browserconfig.xml" />
            <meta name="theme-color" content="#ffffff" />

            <meta
                content={`${process.env.REACT_APP_BASE_URL}${location.pathname}`}
                property="og:url"
            />
            <meta
                content={buildPageTitle(productName, facebookTitle || title)}
                property="og:title"
            />
            <meta
                content={facebookDescription || description}
                property="og:description"
            />
            <meta
                content={
                    (facebookImage && buildUrl(facebookImage)) ||
                    buildUrl(defaultFacebookImage)
                }
                property="og:image"
            />

            {fbAppId && <meta content={fbAppId} property="fb:app_id" />}

            <meta
                content={
                    (twitterImage && buildUrl(twitterImage)) ||
                    buildUrl(defaultTwitterImage)
                }
                name="twitter:image"
            />
            <meta content="summary_large_image" name="twitter:card" />
            {twitterHandle && (
                <meta content={twitterHandle} name="twitter:creator" />
            )}
            <meta
                content={buildPageTitle(productName, twitterTitle || title)}
                name="twitter:title"
            />
            <meta
                content={twitterDescription || description}
                name="twitter:description"
            />
        </Helmet>
    );
};

SEO.defaultProps = {
    title: 'Make Invoices for Free',
    description:
        'Make and download invoices in Adobe PDF. Send an invoice to a client and get paid instantly by Credit Card, PayPal, Venmo, Cash App, or Bitcoin.',
    facebookTitle: '',
    twitterTitle: '',
    facebookImage: '',
    twitterImage: '',
    facebookDescription: '',
    twitterDescription: ''
};

SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    facebookTitle: PropTypes.string,
    twitterTitle: PropTypes.string,
    facebookImage: PropTypes.string,
    twitterImage: PropTypes.string,
    facebookDescription: PropTypes.string,
    twitterDescription: PropTypes.string
};

export default SEO;
