import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    spinner: {
        position: 'absolute',
        right: ({ centerSpinner }) =>
            !centerSpinner && theme.typography.pxToRem(15)
    },
    accessibleLoader: theme.visuallyHidden
}));
