import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Link } from '@mui/material';
import { formatCurrency, toWholeCurrency } from '@/util';
import { Tooltip, StatusPill } from '@/components/common';
import { useDocumentFormData } from '@/hooks';
import { paymentMethods } from '@/config';
import useStyles from './styles';

const Balance = ({ balanceDue, currency, locale }) => {
    const classes = useStyles();

    return (
        <div className={classes.section}>
            <Typography variant="h5" className={classes.label}>
                Balance:
            </Typography>
            <Typography
                variant="h4"
                data-cy="invoice-state-view-balance"
                data-testid="invoice-state-view-balance"
            >
                {formatCurrency({
                    amount: toWholeCurrency(balanceDue),
                    currency,
                    locale
                })}
            </Typography>
        </div>
    );
};

Balance.propTypes = {
    balanceDue: PropTypes.number,
    currency: PropTypes.string,
    locale: PropTypes.string
};

Balance.defaultProps = {
    balanceDue: 0,
    currency: 'usd',
    locale: 'en-US'
};

const InvoiceMarkedAsPaidBy = ({ paymentMethod }) => {
    const classes = useStyles();

    const selectedMethod = paymentMethods.find(
        (method) => method.slug === paymentMethod
    );

    return (
        <div className={classes.sectionPad}>
            <Typography variant="h5" className={classes.label}>
                Paid via:
            </Typography>

            <Typography variant="h4" className={classes.paidName}>
                <span className={classes.svgWrap}>{selectedMethod.icon}</span>
                {selectedMethod.name}
            </Typography>
        </div>
    );
};

InvoiceMarkedAsPaidBy.propTypes = {
    paymentMethod: PropTypes.string
};

InvoiceMarkedAsPaidBy.defaultProps = {
    paymentMethod: ''
};

const InvoiceTooltip = ({ currency, locale, documentType }) => {
    const zeroExample = formatCurrency({
        amount: 0,
        currency,
        locale
    });

    return (
        <Tooltip placement="top-start">
            <Typography variant="body2" component="p">
                In order for your {documentType} status to update from unpaid to
                paid, it must reflect a {zeroExample} balance due...{' '}
                <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://support.invoicemaker.com/hc/en-us/articles/360056360194"
                >
                    LEARN MORE
                </Link>
            </Typography>
        </Tooltip>
    );
};

InvoiceTooltip.propTypes = {
    currency: PropTypes.string,
    locale: PropTypes.string,
    documentType: PropTypes.string
};

InvoiceTooltip.defaultProps = {
    currency: 'usd',
    locale: 'en-US',
    documentType: 'invoice'
};

const DocumentStateView = memo(({ documentType }) => {
    const classes = useStyles();

    const {
        balanceDue,
        currency: { code },
        documentState,
        locale,
        markedPaidByClientMethod
    } = useDocumentFormData(documentType);

    const isEstimate = documentType === 'estimate';
    const isRecurringInvoice = documentType === 'recurring-invoice';

    const documentPaid = balanceDue === 0;

    const isRecurringStopped =
        documentState === 'stopped' && isRecurringInvoice;

    return (
        <div
            className={
                isRecurringStopped ? classes.wrapRecurring : classes.wrap
            }
        >
            <div className={classes.container}>
                <div className={classes.section}>
                    <div className={classes.status}>
                        <Typography variant="h5" className={classes.tooltip}>
                            Status
                        </Typography>
                        {!isEstimate &&
                            !isRecurringInvoice &&
                            !documentPaid && (
                                <InvoiceTooltip
                                    currency={code}
                                    locale={locale}
                                    documentType={documentType}
                                />
                            )}
                    </div>

                    <StatusPill inline status={documentState} />
                </div>
                {!isEstimate && !isRecurringInvoice && (
                    <Balance
                        balanceDue={balanceDue}
                        currency={code}
                        locale={locale}
                    />
                )}
                {markedPaidByClientMethod && documentState === 'unpaid' && (
                    <InvoiceMarkedAsPaidBy
                        paymentMethod={markedPaidByClientMethod}
                    />
                )}
            </div>
        </div>
    );
});

DocumentStateView.propTypes = {
    documentType: PropTypes.string
};

DocumentStateView.defaultProps = {
    documentType: 'invoice'
};

export default DocumentStateView;
