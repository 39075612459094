import React from 'react';
import {
    Discover,
    Jcb,
    UnionPay,
    Visa,
    Mastercard
} from '@/resources/images/logos/cards';
import { StripeIcon } from '@/resources/icons';

export const cards = {
    visa: {
        icon: <Visa data-testid="visa-svg" />,
        name: 'Visa',
        id: 1
    },
    mastercard: {
        icon: <Mastercard data-testid="mastercard-svg" />,
        name: 'Mastercard',
        id: 2
    },
    discover: {
        icon: <Discover data-testid="discover-svg" />,
        name: 'Discover',
        id: 3
    },
    unionPay: {
        icon: <UnionPay data-testid="unionpay-svg" />,
        name: 'Union Pay',
        id: 4
    },
    jcb: {
        icon: <Jcb data-testid="jcb-svg" />,
        name: 'JCB Co., Ltd',
        id: 5
    }
};

export const merchants = {
    stripe: {
        id: 1,
        slug: 'stripe',
        name: 'Stripe',
        logo: <StripeIcon />,
        paymentMethods: [
            cards.visa,
            cards.mastercard,
            cards.discover,
            cards.unionPay,
            cards.jcb
        ]
    }
};
