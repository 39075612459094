import React, { useState } from 'react';
import { Grid, Typography, Divider, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { Select, Switch } from '@/components/rhf-mui';
import { useToastNotification } from '@/hooks';
import {
    ButtonLoading,
    ColorSwatch,
    NotesField,
    Tooltip
} from '@/components/common';
import { updateBusinessSettings } from '@/modules/dataWrangler/dataWrangler';
import { dateTerms } from '@/config';
import { getObjectFromArray } from '@/util';
import { SaveIcon } from '@/resources/icons';
import {
    StampPaidOutlined,
    StampPastDue,
    StampDraft,
    StampApproved,
    StampUnapproved
} from '@/resources/images/stamps';
import settingStyles from '../styles';
import styles from './styles';

const DocumentDefaults = () => {
    const classes = styles();
    const settingsClasses = settingStyles();
    const { triggerToast } = useToastNotification();

    const activeBusiness = useSelector((state) => state?.user?.activeBusiness);
    const {
        design,
        invoiceNotes,
        invoiceTerms,
        estimateNotes,
        estimateTerms,
        receiptNotes,
        disableIMBranding,
        disablePaymentStamp
    } = activeBusiness?.documentCustomizations;

    const methods = useForm({
        criteriaMode: 'all',
        mode: 'onBlur',
        defaultValues: {
            documentColor: design,
            defaultInvoiceTerms: getObjectFromArray(
                dateTerms,
                invoiceTerms,
                'value'
            )?.name,
            defaultInvoiceNotes: invoiceNotes,
            defaultEstimateTerms: getObjectFromArray(
                dateTerms,
                estimateTerms,
                'value'
            )?.name,
            defaultEstimateNotes: estimateNotes,
            defaultReceiptNotes: receiptNotes,
            brandingSwitch: !disableIMBranding,
            paymentStamp: !disablePaymentStamp
        }
    });

    const {
        handleSubmit,
        setValue,
        register,
        watch,
        formState: { isValid }
    } = methods;

    register('defaultInvoiceNotes');
    register('defaultEstimateNotes');
    register('defaultReceiptNotes');

    const [loading, setLoading] = useState(false);

    const updateSettings = async (data) => {
        if (loading) {
            return;
        }

        const updatedInvoiceTerms = getObjectFromArray(
            dateTerms,
            data.defaultInvoiceTerms,
            'name'
        ).value;

        const updatedEstimateTerms = getObjectFromArray(
            dateTerms,
            data.defaultEstimateTerms,
            'name'
        ).value;

        setLoading(true);

        const userSettings = {
            businessProfile: activeBusiness.businessProfile,
            businessSettings: activeBusiness.businessSettings,
            documentCustomizations: {
                invoiceNotes: data.defaultInvoiceNotes,
                invoiceTerms: updatedInvoiceTerms,
                design: data.documentColor,
                disableIMBranding: !data.brandingSwitch,
                disablePaymentStamp: !data.paymentStamp,
                estimateTerms: updatedEstimateTerms,
                estimateNotes: data.defaultEstimateNotes,
                receiptNotes: data.defaultReceiptNotes
            }
        };

        const savedSuccess = await updateBusinessSettings(userSettings);

        setLoading(false);

        if (savedSuccess) {
            triggerToast({
                message: 'Customizations Saved',
                action: 'saved',
                icon: <SaveIcon />
            });
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit((data) => updateSettings(data))}>
                <Grid
                    item
                    container
                    direction="column"
                    className={settingsClasses.sectionContainerMobile}
                >
                    <Grid container spacing={2} direction="column">
                        <Grid item xs={12}>
                            <Typography
                                component="h2"
                                color="textSecondary"
                                variant="body1"
                                id="customization"
                                gutterBottom
                            >
                                Customization
                            </Typography>

                            <Divider
                                className={
                                    settingsClasses.genericSettingsDivider
                                }
                            />

                            <Typography
                                className={settingsClasses.subtitles}
                                color="textSecondary"
                            >
                                Change the look and feel of any unsent documents
                                with customizations.
                            </Typography>
                        </Grid>

                        {/* Start Color Picker */}
                        <Grid item className={classes.thinContainer}>
                            <Typography
                                variant="h3"
                                className={classes.subHeaderText}
                                color="textSecondary"
                            >
                                Choose Your Document Color
                            </Typography>

                            <ColorSwatch
                                currentColor={design}
                                selectionCallback={(color) => {
                                    setValue(
                                        'documentColor',
                                        color.backgroundColor,
                                        true
                                    );
                                }}
                                type="documents"
                            />
                        </Grid>

                        {/* Start Invoice Default Terms */}
                        <Grid item className={classes.thinContainer}>
                            <Grid
                                item
                                container
                                direction="row"
                                alignItems="center"
                                className={classes.subHeader}
                            >
                                <Typography
                                    variant="h3"
                                    color="textSecondary"
                                    className={classes.subHeaderText}
                                >
                                    Invoice Due Date Default Terms
                                </Typography>
                            </Grid>
                            <Select
                                name="defaultInvoiceTerms"
                                label="Default Terms"
                                id="default-invoice-terms"
                                variant="filled"
                                fullWidth
                            >
                                {dateTerms.map((item) =>
                                    item.name !== 'Custom' ? (
                                        <MenuItem
                                            value={item.name}
                                            key={item.value}
                                            name={item.name}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    ) : null
                                )}
                            </Select>
                        </Grid>

                        {/* Start Invoice NotesField */}
                        <Grid item className={classes.thinContainer}>
                            <Grid
                                item
                                container
                                direction="row"
                                alignItems="center"
                                className={classes.subHeader}
                            >
                                <Typography
                                    variant="h3"
                                    color="textSecondary"
                                    className={classes.subHeaderTextWithTooltip}
                                >
                                    Default Invoice Note
                                </Typography>
                                <Tooltip placement="top">
                                    <Typography variant="h4" component="p">
                                        The default note will appear in the
                                        notes section on all of your invoices.
                                    </Typography>
                                </Tooltip>
                            </Grid>

                            <Grid item>
                                <NotesField
                                    label="Invoice Notes"
                                    id="Invoice Notes"
                                    defaultRows={1}
                                    maxCharacters={250}
                                    value={watch('defaultInvoiceNotes')}
                                    name="defaultInvoiceNotes"
                                    onChange={(e) =>
                                        setValue(
                                            'defaultInvoiceNotes',
                                            e.target.value
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>

                        {/* Start Estimate Default Terms */}
                        <Grid item className={classes.thinContainer}>
                            <Grid
                                item
                                container
                                direction="row"
                                alignItems="center"
                                className={classes.subHeader}
                            >
                                <Typography
                                    variant="h3"
                                    color="textSecondary"
                                    className={classes.subHeaderText}
                                >
                                    Estimate Valid Until Date - Default Terms
                                </Typography>
                            </Grid>
                            <Select
                                name="defaultEstimateTerms"
                                label="Default Terms"
                                id="default-estimate-terms"
                                variant="filled"
                                fullWidth
                            >
                                {dateTerms.map((item) =>
                                    item.name !== 'Custom' ? (
                                        <MenuItem
                                            value={item.name}
                                            key={item.value}
                                            name={item.name}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    ) : null
                                )}
                            </Select>
                        </Grid>

                        {/* Start Estimate NotesField */}
                        <Grid item className={classes.thinContainer}>
                            <Grid
                                item
                                container
                                direction="row"
                                alignItems="center"
                                className={classes.subHeader}
                            >
                                <Typography
                                    variant="h3"
                                    color="textSecondary"
                                    className={classes.subHeaderTextWithTooltip}
                                >
                                    Default Estimate Note
                                </Typography>
                                <Tooltip placement="top">
                                    <Typography variant="h4" component="p">
                                        The default note will appear in the
                                        notes section on all of your estimates.
                                    </Typography>
                                </Tooltip>
                            </Grid>

                            <Grid item>
                                <NotesField
                                    label="Estimate Notes"
                                    id="Estimate Notes"
                                    defaultRows={1}
                                    maxCharacters={250}
                                    value={watch('defaultEstimateNotes')}
                                    name="defaultEstimateNotes"
                                    onChange={(e) =>
                                        setValue(
                                            'defaultEstimateNotes',
                                            e.target.value
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>

                        {/* Start Receipt NotesField */}
                        <Grid item className={classes.thinContainer}>
                            <Grid
                                item
                                container
                                direction="row"
                                alignItems="center"
                                className={classes.subHeader}
                            >
                                <Typography
                                    variant="h3"
                                    color="textSecondary"
                                    className={classes.subHeaderTextWithTooltip}
                                >
                                    Default Receipt Note
                                </Typography>
                                <Tooltip placement="top">
                                    <Typography variant="h4" component="p">
                                        The default note will appear in the
                                        notes section on all of your receipts.
                                    </Typography>
                                </Tooltip>
                            </Grid>

                            <Grid item>
                                <NotesField
                                    label="Receipt Notes"
                                    id="Receipt Notes"
                                    defaultRows={1}
                                    maxCharacters={250}
                                    value={watch('defaultReceiptNotes')}
                                    name="defaultReceiptNotes"
                                    onChange={(e) =>
                                        setValue(
                                            'defaultReceiptNotes',
                                            e.target.value
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>

                        {/* Start Branding */}
                        <Grid
                            item
                            xs={12}
                            md={4}
                            className={settingsClasses.switchContainer}
                        >
                            <Switch
                                label="Invoice Maker Branding"
                                name="brandingSwitch"
                            />
                        </Grid>

                        {/* pdf stamp toggle */}
                        <Grid
                            item
                            xs={12}
                            md={4}
                            className={settingsClasses.switchContainer}
                        >
                            <Switch
                                label={
                                    <Grid container>
                                        <Typography
                                            variant="inherit"
                                            component="label"
                                        >
                                            PDF Status Stamps
                                        </Typography>
                                        <Grid
                                            item
                                            className={classes.stampTooltip}
                                        >
                                            <Tooltip placement="top">
                                                <Typography
                                                    variant="h4"
                                                    component="p"
                                                >
                                                    Show a stamp indicating the
                                                    status of your invoice on
                                                    downloaded PDFs.
                                                </Typography>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                }
                                name="paymentStamp"
                            />
                        </Grid>
                        <Grid container item spacing={2} xs={12}>
                            <Grid item>
                                <StampDraft data-testid="stamp-draft" />
                            </Grid>
                            <Grid item>
                                <StampPaidOutlined data-testid="stamp-paid-outlined" />
                            </Grid>
                            <Grid item>
                                <StampPastDue data-testid="stamp-past-due" />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            spacing={2}
                            xs={12}
                            className={classes.lowerStampContainer}
                        >
                            <Grid item>
                                <StampUnapproved data-testid="stamp-unapproved" />
                            </Grid>
                            <Grid item>
                                <StampApproved data-testid="stamp-approved" />
                            </Grid>
                        </Grid>

                        {/* Save Button */}
                        <Grid
                            container
                            className={settingsClasses.saveButtonContainer}
                            item
                        >
                            <Grid item xs={12} md={4}>
                                <ButtonLoading
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={!isValid}
                                    loading={loading}
                                    classes={{
                                        root: settingsClasses.saveButton,
                                        disabled:
                                            settingsClasses.saveButtonDisabled
                                    }}
                                >
                                    Save Changes
                                </ButtonLoading>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};

export default DocumentDefaults;
