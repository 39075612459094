import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    headingDivider: {
        backgroundColor: theme.palette.background.default,
        height: 1,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        width: '100%'
    }
}));
