/* eslint import/no-cycle: "off" */

import { getAvailableSubscriptions } from '@/modules/subscriptionManager';
import { toIntegerCurrency } from '@/util/currency';

export const makeFormattedProducts = async (revenuecatProducts) => {
    const productsFromBackend = await getAvailableSubscriptions();
    const newArr = [];

    const merge = (rc, be) => {
        const isPlusYearly = rc.identifier === 'plus_yearly_1';

        const beMatch = !isPlusYearly
            ? be.find(
                  (i) => i.productHandle === rc.identifier.replace('_', '-')
              )
            : be.find((u) => u.productHandle === 'plus-yearly');

        const formattedProduct = {
            productId: beMatch.productId,
            name: rc.title,
            productHandle: rc.identifier.replace('_', '-'),
            description: rc.description,
            price: toIntegerCurrency(rc.price.toFixed(2)),
            interval: beMatch.interval,
            intervalUnit: beMatch.intervalUnit,
            rank: beMatch.rank
        };
        newArr.push(formattedProduct);
    };

    revenuecatProducts.forEach((i) => {
        merge(i, productsFromBackend);
    });

    return newArr;
};
