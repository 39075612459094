import { useSelector } from 'react-redux';

/**
 * This hook returns the current users Timezone option in a string.
 * @return {[type]} [description]
 */
export const useTimezone = () =>
    useSelector(
        (state) =>
            state.user?.activeBusiness?.businessSettings?.timezone ||
            'America/New_York'
    );
