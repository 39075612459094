import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    span: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.secondary,
        opacity: 0.38,
        padding: '18px 12px',
        fontWeight: 600,
        fontSize: 16
    }
}));
