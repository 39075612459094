import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    wrap: {
        position: 'relative'
    },
    statusLabel: {
        fontSize: theme.typography.pxToRem(18),
        paddingLeft: theme.typography.pxToRem(9),
        marginTop: theme.typography.pxToRem(22)
    }
}));
