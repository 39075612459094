import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, SvgIcon } from '@mui/material';
import { setPlanIcon } from '@/util';
import styles from './styles';

const PlanTitle = ({
    planName,
    userPrice,
    currentPlanPrice,
    interval,
    subscriptionSource
}) => {
    const classes = styles();
    let subscriptionType = 'year';

    if (interval === 1) {
        subscriptionType = 'month';
    }
    if (interval === 99999) {
        subscriptionType = 'lifetime';
    }

    const isLegacyPlan =
        subscriptionSource === 'chargify'
            ? userPrice !== currentPlanPrice
            : false;

    return (
        <Grid
            item
            container
            direction="row"
            alignItems="flex-start"
            className={classes.summaryItemContainer}
            xs={12}
            sm={4}
        >
            {planName !== 'visitor' ? (
                <>
                    <Grid
                        item
                        container
                        xs={12}
                        alignItems="center"
                        className={classes.summaryItemTitle}
                    >
                        <Grid item className={classes.svgContainer}>
                            <SvgIcon
                                component={setPlanIcon(planName)}
                                className={classes.planSummaryTitleIcon}
                            />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.summaryItemInfo}>
                                {planName}
                            </Typography>
                        </Grid>
                    </Grid>
                    {planName !== 'free' && planName !== 'lifetime' && (
                        <Grid item>
                            <Typography
                                color="textSecondary"
                                className={classes.summaryItemInfo}
                            >
                                {`$${userPrice / 100}/${subscriptionType}`}
                            </Typography>
                            {isLegacyPlan && (
                                <Typography
                                    color="textSecondary"
                                    className={classes.summaryItemInfo}
                                >
                                    (Legacy Plan Pricing)
                                </Typography>
                            )}
                        </Grid>
                    )}
                </>
            ) : (
                <Grid item>
                    <Typography variant="h1">Visitor</Typography>
                </Grid>
            )}
        </Grid>
    );
};

PlanTitle.propTypes = {
    planName: PropTypes.string,
    userPrice: PropTypes.number,
    currentPlanPrice: PropTypes.number,
    interval: PropTypes.number,
    subscriptionSource: PropTypes.string
};

PlanTitle.defaultProps = {
    planName: '',
    userPrice: 0,
    currentPlanPrice: 0,
    interval: 0,
    subscriptionSource: ''
};

export default PlanTitle;
