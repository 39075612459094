import React from 'react';
import PropTypes from 'prop-types';

import InvoiceSidebar from './Invoice';
import EstimateSidebar from './Estimate';
import ReceiptSidebar from './Receipt';
import RecurringInvoiceSidebar from './RecurringInvoice';

const SideBar = ({
    amountPaid,
    currencyCode,
    currencySymbol,
    documentType,
    locale,
    locked,
    total,
    beforeActionPromptOptions,
    setBeforeActionPromptOptions,
    handleSetLoginBlocker,
    handleSetLoginView,
    loginModalOptions
}) => (
    <>
        {documentType === 'invoice' && (
            <InvoiceSidebar
                beforeActionPromptOptions={beforeActionPromptOptions}
                setBeforeActionPromptOptions={setBeforeActionPromptOptions}
                handleSetLoginBlocker={handleSetLoginBlocker}
                amountPaid={amountPaid}
                total={total}
                locked={locked}
                currencyCode={currencyCode}
                currencySymbol={currencySymbol}
            />
        )}

        {documentType === 'recurring-invoice' && (
            <RecurringInvoiceSidebar
                beforeActionPromptOptions={beforeActionPromptOptions}
                setBeforeActionPromptOptions={setBeforeActionPromptOptions}
                handleSetLoginBlocker={handleSetLoginBlocker}
                amountPaid={amountPaid}
                total={total}
                locked={locked}
                currencyCode={currencyCode}
                currencySymbol={currencySymbol}
            />
        )}

        {documentType === 'estimate' && (
            <EstimateSidebar
                beforeActionPromptOptions={beforeActionPromptOptions}
                setBeforeActionPromptOptions={setBeforeActionPromptOptions}
                handleSetLoginView={handleSetLoginView}
                loginModalOptions={loginModalOptions}
                handleSetLoginBlocker={handleSetLoginBlocker}
                amountPaid={amountPaid}
                total={total}
                locked={locked}
                locale={locale}
                currencyCode={currencyCode}
                currencySymbol={currencySymbol}
            />
        )}

        {documentType === 'receipt' && (
            <ReceiptSidebar
                beforeActionPromptOptions={beforeActionPromptOptions}
                setBeforeActionPromptOptions={setBeforeActionPromptOptions}
                handleSetLoginView={handleSetLoginView}
                loginModalOptions={loginModalOptions}
                handleSetLoginBlocker={handleSetLoginBlocker}
                amountPaid={amountPaid}
                total={total}
                locked={locked}
                locale={locale}
                currencyCode={currencyCode}
                currencySymbol={currencySymbol}
            />
        )}
    </>
);

SideBar.propTypes = {
    amountPaid: PropTypes.number,
    currencyCode: PropTypes.string,
    currencySymbol: PropTypes.string,
    documentType: PropTypes.string,
    total: PropTypes.number,
    locale: PropTypes.string,
    locked: PropTypes.bool,
    beforeActionPromptOptions: PropTypes.object,
    setBeforeActionPromptOptions: PropTypes.func,
    handleSetLoginBlocker: PropTypes.func,
    handleSetLoginView: PropTypes.func,
    loginModalOptions: PropTypes.object
};

SideBar.defaultProps = {
    amountPaid: 0,
    currencyCode: 'USD',
    currencySymbol: '$',
    documentType: 'invoice',
    total: 0,
    locale: 'en-US',
    locked: false,
    beforeActionPromptOptions: {},
    setBeforeActionPromptOptions: () => {},
    handleSetLoginBlocker: () => {},
    handleSetLoginView: () => {},
    loginModalOptions: {}
};

export default SideBar;
