/* eslint no-param-reassign: "off", dot-notation: "off"  */

import axios from 'axios';
import { API_ENDPOINT } from '@/config';

// eslint-disable-next-line import/no-cycle
import { getCurrentUserSession, logoutIfNotAuth } from './loginManager';

// Exported only for testing
export const interceptor = async (config) => {
    const currentUserSession = await getCurrentUserSession();

    if (!currentUserSession) {
        logoutIfNotAuth();
        return config;
    }

    config.baseURL = API_ENDPOINT;
    config.headers['Authorization'] = currentUserSession.idToken.jwtToken;

    return config;
};

const axiosInstance = axios.create();

// If Axios is mocked, axiosInstance will be null.
// Wrapping this in an if statement shold prevent tests from failing
if (axiosInstance) {
    axiosInstance.interceptors.request.use(interceptor);
}

export default axiosInstance;
