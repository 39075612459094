import PropTypes from 'prop-types';
import React from 'react';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from './styles';

const ItemLabel = ({ children }) => {
    const classes = makeStyles();

    return (
        <Grid item xs={12}>
            <Typography variant="h2" className={classes.label}>
                {children}
            </Typography>
        </Grid>
    );
};

ItemLabel.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.node
    ])
};

ItemLabel.defaultProps = {
    children: ''
};

export default ItemLabel;
