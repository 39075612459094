import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    form: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: 'calc(100% - 60px)'
    },
    itemList: {
        overflowX: 'hidden',
        height: '100%',
        padding: '8px 5px 5px 5px'
    },
    resetButton: {
        marginTop: theme.typography.pxToRem(22),
        backgroundColor: theme.palette.common.white,
        border: '1px solid rgba(0, 0, 0, 0.23)',
        color: theme.palette.common.black,
        '&:hover': {
            backgroundColor: 'rgba(0,0,0, .08)',
            color: theme.palette.common.black,
            border: '1px solid rgba(0, 0, 0, 0.23)'
        }
    }
}));
