import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    root: {
        marginBottom: theme.typography.pxToRem(10),
        padding: `${theme.typography.pxToRem(16)}
        ${theme.typography.pxToRem(9)}`
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.secondary.contrastText
    },
    button: {
        color: theme.palette.secondary.contrastText,
        cursor: 'pointer',
        outline: 0,
        backgroundColor: 'inherit',
        fontSize: 'inherit',
        textAlign: 'inherit',
        fontWeight: 'inherit',
        fontFamily: 'inherit',
        border: 0,
        display: 'block',
        height: '100%',
        textDecoration: 'none',
        width: '100%',
        padding: 0
    },
    title: {
        wordBreak: 'break-word'
    },
    mid: {
        display: 'flex',
        color: '#71747D',
        fontWeight: '600'
    },
    left: {
        width: '50%'
    },
    right: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end'
    },
    full: {
        width: '100%',
        color: '#71747D',
        fontWeight: '400',
        marginTop: theme.typography.pxToRem(10),
        textAlign: 'center'
    },
    actionsButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        marginBottom: theme.typography.pxToRem(10)
    },
    ellipsisButton: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        height: 15,
        border: 0,
        backgroundColor: 'inherit'
    },
    ellipsis: {
        fill: theme.palette.common.black,
        height: 5
    },
    actions: {
        backgroundColor: theme.palette.common.lightGray,
        marginBottom: theme.typography.pxToRem(10),
        padding: theme.typography.pxToRem(10),
        transition: 'all 0.5s',
        overflow: 'hidden',
        maxHeight: 95
    },
    hideActions: {
        maxHeight: 0,
        marginBottom: 0,
        padding: `0 ${theme.typography.pxToRem(10)}`
    },
    tags: {
        width: '100%',
        marginTop: theme.typography.pxToRem(10)
    }
}));
