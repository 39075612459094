import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
    svg: {
        width: 24,
        height: 24,
        minWidth: 24
    },
    svgSmall: {
        width: 16,
        height: 16,
        minWidth: 16
    }
}));
