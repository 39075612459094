import React from 'react';
import PropTypes from 'prop-types';
import { DocumentTypePill } from '@/components/common';
import { Checkbox } from '@/components/rhf-mui';
import Grid from '@mui/material/Unstable_Grid2';
import FormControlLabel from '@mui/material/FormControlLabel';
import ItemLabel from '../ItemLabel';
import makeStyles from './styles';

const DocumentTypeCheckbox = ({ documentTypes }) => {
    const classes = makeStyles();

    const getStatusIcon = (type, checked) => (
        <DocumentTypePill checked={checked} type={type} />
    );

    return (
        <Grid item container direction="column">
            <ItemLabel>Item</ItemLabel>
            <Grid item>
                {documentTypes.map((item) => (
                    <FormControlLabel
                        className={classes.statusCheckboxWrap}
                        key={item}
                        control={
                            <Checkbox
                                id={`${item}-parentType`}
                                name={`parentType[${item}]`}
                                icon={getStatusIcon(item, false)}
                                checkedIcon={getStatusIcon(item, true)}
                                className={classes.statusCheckbox}
                                disableRipple
                            />
                        }
                    />
                ))}
            </Grid>
        </Grid>
    );
};

DocumentTypeCheckbox.propTypes = {
    documentTypes: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default DocumentTypeCheckbox;
