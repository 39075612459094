import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const TabPanel = ({ children, currentTab, id, padding, ...rest }) => (
    <div
        role="tabpanel"
        hidden={currentTab !== id}
        id={`vertical-tabpanel-${id}`}
        aria-labelledby={`vertical-tab-${id}`}
        {...rest}
    >
        {currentTab === id && (
            <Box p={padding ? 2 : 0} style={{ width: '100%' }}>
                {children}
            </Box>
        )}
    </div>
);

TabPanel.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.node
    ]).isRequired,
    currentTab: PropTypes.string,
    id: PropTypes.string.isRequired,
    padding: PropTypes.bool
};

TabPanel.defaultProps = {
    currentTab: '',
    padding: false
};

export default TabPanel;
