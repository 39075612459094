import React from 'react';
import {
    ClientsActive,
    DiscountActive,
    EstimatesActive,
    InvoicesActive,
    ProductsActive,
    ReceiptsActive,
    ServicesActive,
    TaxesActive
} from '@/resources/icons/menuItems';
import { RecurringIcon } from '@/resources/icons';

export const tagTypeIcons = {
    invoice: <InvoicesActive />,
    estimate: <EstimatesActive />,
    receipt: <ReceiptsActive />,
    client: <ClientsActive />,
    product: <ProductsActive />,
    service: <ServicesActive />,
    tax: <TaxesActive />,
    discount: <DiscountActive />,
    recurringInvoice: <RecurringIcon />
};
