import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import styles from './styles';

const BillingSwitch = ({ loading, plansToShow, switchPlans }) => {
    const classes = styles();
    const showMonthly = plansToShow === 'monthly';
    return (
        <Grid
            item
            container
            component="fieldset"
            role="radiogroup"
            alignItems="center"
            justifyContent="center"
            className={classes.billingSwitchGridContainer}
        >
            {!loading && (
                <div className={classes.billingSwitchContainer}>
                    <Typography
                        variant="body2"
                        component="label"
                        htmlFor="monthly"
                        className={`${classes.billingSwitchPayMonthly} ${
                            showMonthly ? classes.billingSwitchSelected : ''
                        } `}
                        color={!showMonthly ? 'textPrimary' : 'primary'}
                    >
                        Pay Monthly
                    </Typography>
                    <Typography
                        variant="body2"
                        component="label"
                        htmlFor="yearly"
                        className={`${classes.billingSwitchPayMonthly} ${
                            !showMonthly ? classes.billingSwitchSelected : ''
                        } `}
                        color={showMonthly ? 'textPrimary' : 'primary'}
                    >
                        Pay Yearly
                        <Typography
                            variant="h5"
                            component="span"
                            className={`${classes.savePill} ${
                                showMonthly && classes.savePillSelected
                            }`}
                        >
                            Save
                        </Typography>
                    </Typography>

                    <input
                        type="radio"
                        name="planSwitch"
                        id="monthly"
                        onChange={switchPlans}
                        className={classes.hiddenRadioButton}
                        checked={Boolean(showMonthly)}
                    />
                    <input
                        type="radio"
                        name="planSwitch"
                        id="yearly"
                        onChange={switchPlans}
                        className={classes.hiddenRadioButton}
                        checked={Boolean(!showMonthly)}
                    />
                </div>
            )}
        </Grid>
    );
};

BillingSwitch.propTypes = {
    loading: PropTypes.bool.isRequired,
    plansToShow: PropTypes.string.isRequired,
    switchPlans: PropTypes.func.isRequired
};

export default BillingSwitch;
