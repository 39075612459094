import loadable from '@loadable/component';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Popover, Grid, Skeleton } from '@mui/material';

import { NoEmailModal } from '@/components/common';
import { closeModal } from '@/state/actions';
import {
    updateClient,
    voidEstimate,
    deleteEstimate,
    approveEstimate,
    declineEstimate
} from '@/modules/dataWrangler/dataWrangler';
import { ConvertIconGreen, ApproveDeclineIcon } from '@/resources/icons';
import { useCheckAuthState, useDocumentFormData, useModals } from '@/hooks';
import {
    useSendEstimate,
    useSaveEstimate,
    useValidateEstimate
} from '@/hooks/useSidebarActions';

import { LottieDownloadLoading } from '@/lottie';

import DocumentStateView from '../../DocumentStateView';

import {
    EstimateApprove,
    EstimateApproveMobile,
    EstimateConversion,
    EstimatePreviewAndSend,
    EstimateSave,
    EstimateVoid,
    EstimateDelete,
    EstimateDecline,
    EstimateDeclineMobile
} from '../actions';

import {
    previewEstimateNegativeTotalModalOptions,
    saveNegativeEstimateTotalModalOptions,
    deleteEstimateModalOptions,
    voidEstimateModalOptions,
    approveEstimateModalOptions,
    declineEstimateModalOptions,
    unapprovedConversionModalOptions,
    convertEstimateModalOptions
} from '../ModalCopy';

import useStyles from '../styles';

const LottieModal = loadable(() => import(`@/components/common/LottieModal`));
const ShareLinkModal = loadable(() =>
    import(`@/components/common/ShareLinkModal`)
);
const LottieSendingLoading = loadable(() =>
    import('@/lottie/LottieSendingLoading')
);
const LottiePreviewLoading = loadable(() =>
    import('@/lottie/LottiePreviewLoading')
);

const EstimateSidebar = ({
    beforeActionPromptOptions,
    handleSetLoginBlocker,
    locked,
    setBeforeActionPromptOptions
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Hooks
    const { loadingUser } = useCheckAuthState();
    const { documentState, documentLoading } = useDocumentFormData();

    const { isModalActive } = useModals();
    const sendEstimate = useSendEstimate();
    const saveEstimate = useSaveEstimate();

    const validateEstimate = useValidateEstimate();

    const notApprovedOrDeclined =
        !documentLoading &&
        (documentState === 'unapproved' || documentState === 'draft');

    const showCopyButton =
        !documentLoading &&
        (documentState === 'unapproved' || documentState === 'approved');

    const estimate = useSelector((state) => state.makeInvoice) || null;

    const showSkeletonLoaders = loadingUser || documentLoading;

    const classes = useStyles({
        shippingDisabled: Boolean(!estimate?.to)
    });

    const [showMobileOptions, setShowMobileOptions] = useState(null);
    const [savingForPreview, setSavingForPreview] = useState(false);

    const setSaveNegativeTotalWarningActive = () => {
        setBeforeActionPromptOptions({
            ...beforeActionPromptOptions,
            ...saveNegativeEstimateTotalModalOptions,
            onConfirm: () =>
                setBeforeActionPromptOptions({
                    ...saveNegativeEstimateTotalModalOptions,
                    open: false
                }),
            open: true
        });
    };

    const setVoidEstimateWarningActive = () => {
        setBeforeActionPromptOptions({
            ...beforeActionPromptOptions,
            ...voidEstimateModalOptions,
            onCancel: () =>
                setBeforeActionPromptOptions({
                    ...voidEstimateModalOptions,
                    open: false
                }),
            onConfirm: async () => {
                await voidEstimate(estimate);

                setBeforeActionPromptOptions({
                    ...voidEstimateModalOptions,
                    open: false
                });
            },
            open: true
        });
    };

    const setDeleteEstimateWarningActive = () => {
        setBeforeActionPromptOptions({
            ...beforeActionPromptOptions,
            ...deleteEstimateModalOptions,
            onCancel: () =>
                setBeforeActionPromptOptions({
                    ...deleteEstimateModalOptions,
                    open: false
                }),
            onConfirm: async () => {
                setBeforeActionPromptOptions({
                    ...deleteEstimateModalOptions,
                    open: false
                });

                await deleteEstimate(estimate?.documentId);

                navigate('/estimates');
            },
            open: true
        });
    };

    const setPreviewNegativeTotalWarningActive = () => {
        setBeforeActionPromptOptions({
            ...beforeActionPromptOptions,
            ...previewEstimateNegativeTotalModalOptions,
            onConfirm: () =>
                setBeforeActionPromptOptions({
                    ...previewEstimateNegativeTotalModalOptions,
                    open: false
                }),
            open: true
        });
    };

    const setApproveEstimateWarningActive = (estimateData) => {
        setBeforeActionPromptOptions({
            ...beforeActionPromptOptions,
            ...approveEstimateModalOptions,
            onCancel: () =>
                setBeforeActionPromptOptions({
                    ...approveEstimateModalOptions,
                    open: false
                }),
            onConfirm: async () => {
                setShowMobileOptions(null);

                setBeforeActionPromptOptions({
                    ...approveEstimateModalOptions,
                    buttonLoading: true,
                    open: true
                });

                if (!locked) {
                    const savedData = await saveEstimate(
                        estimateData,
                        'approve'
                    );
                    if (savedData === false) {
                        return;
                    }
                    await approveEstimate(
                        savedData?.documentId,
                        '',
                        '',
                        'none'
                    );
                } else {
                    await approveEstimate(estimate?.documentId, '', '', 'none');
                }

                setBeforeActionPromptOptions({
                    ...approveEstimateModalOptions,
                    buttonLoading: false,
                    open: false
                });
            },
            open: true
        });
    };

    const setDeclineEstimateWarningActive = (estimateData) => {
        setBeforeActionPromptOptions({
            ...beforeActionPromptOptions,
            ...declineEstimateModalOptions,
            onCancel: () =>
                setBeforeActionPromptOptions({
                    ...declineEstimateModalOptions,
                    confirmColor: undefined,
                    open: false
                }),
            onConfirm: async () => {
                setShowMobileOptions(null);

                setBeforeActionPromptOptions({
                    ...declineEstimateModalOptions,
                    buttonLoading: true,
                    open: true
                });

                if (!locked) {
                    const savedData = await saveEstimate(
                        estimateData,
                        'decline'
                    );
                    if (savedData === false) {
                        return;
                    }
                    await declineEstimate(savedData?.documentId);
                } else {
                    await declineEstimate(estimate?.documentId);
                }

                setBeforeActionPromptOptions({
                    ...declineEstimateModalOptions,
                    buttonLoading: false,
                    confirmColor: undefined,
                    open: false
                });
            },
            open: true
        });
    };

    const setConvertEstimateWarningActive = (convertEstimate) => {
        setBeforeActionPromptOptions({
            ...beforeActionPromptOptions,
            ...convertEstimateModalOptions,
            onCancel: () =>
                setBeforeActionPromptOptions({
                    ...convertEstimateModalOptions,
                    customIcon: (
                        <ConvertIconGreen
                            className={classes.convertModalIcon}
                        />
                    ),
                    open: false
                }),
            onConfirm: async () => {
                setBeforeActionPromptOptions({
                    ...convertEstimateModalOptions,
                    customIcon: (
                        <ConvertIconGreen
                            className={classes.convertModalIcon}
                        />
                    ),
                    buttonLoading: true,
                    open: true
                });

                await convertEstimate();

                setBeforeActionPromptOptions({
                    ...convertEstimateModalOptions,
                    customIcon: (
                        <ConvertIconGreen
                            className={classes.convertModalIcon}
                        />
                    ),
                    buttonLoading: false,
                    open: false
                });
            },
            customIcon: (
                <ConvertIconGreen className={classes.convertModalIcon} />
            ),
            open: true
        });
    };

    const setUnapprovedConversionNoticeActive = () => {
        setBeforeActionPromptOptions({
            ...beforeActionPromptOptions,
            ...unapprovedConversionModalOptions,
            onConfirm: () =>
                setBeforeActionPromptOptions({
                    ...unapprovedConversionModalOptions,
                    customIcon: (
                        <ConvertIconGreen
                            className={classes.convertModalIcon}
                        />
                    ),
                    open: false
                }),
            customIcon: (
                <ConvertIconGreen className={classes.convertModalIcon} />
            ),
            open: true
        });
    };

    return (
        <div className={classes.sidebar}>
            <div className={classes.container}>
                <EstimatePreviewAndSend
                    onNegativeEstimate={setPreviewNegativeTotalWarningActive}
                    onLoginBlocker={handleSetLoginBlocker}
                    setLoading={setSavingForPreview}
                    loading={savingForPreview}
                />

                <EstimateVoid
                    onLoginBlocker={handleSetLoginBlocker}
                    onWarningActive={setVoidEstimateWarningActive}
                />

                <EstimateSave
                    onLoginBlocker={handleSetLoginBlocker}
                    onNegativeEstimate={setSaveNegativeTotalWarningActive}
                />

                <EstimateDelete onClick={setDeleteEstimateWarningActive} />

                <div
                    className={
                        documentState === 'unapproved' ||
                        documentState === 'draft'
                            ? classes.desktopEstimateActionStateContainer
                            : classes.desktopEstimateActionStateContainerLocked
                    }
                >
                    <div>
                        <EstimateApprove
                            onLoginBlocker={handleSetLoginBlocker}
                            onClick={setApproveEstimateWarningActive}
                        />

                        <EstimateDecline
                            onLoginBlocker={handleSetLoginBlocker}
                            onClick={setDeclineEstimateWarningActive}
                        />
                    </div>

                    <div className={classes.section}>
                        {!showSkeletonLoaders ? (
                            <DocumentStateView documentType="estimate" />
                        ) : (
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={100}
                            />
                        )}
                    </div>
                </div>

                {showCopyButton && (
                    <div className={classes.section}>
                        <EstimateConversion
                            onApprovedEstimate={setConvertEstimateWarningActive}
                            onUnapprovedEstimate={
                                setUnapprovedConversionNoticeActive
                            }
                        />
                    </div>
                )}

                {notApprovedOrDeclined && (
                    <div className={classes.section}>
                        <div className={classes.smallerOptions}>
                            {!showSkeletonLoaders ? (
                                <Button
                                    fullWidth
                                    className={classes.button}
                                    variant="contained"
                                    color="secondary"
                                    endIcon={
                                        <ApproveDeclineIcon
                                            className={
                                                classes.buttonApproveDeclineSvg
                                            }
                                        />
                                    }
                                    onClick={(e) => {
                                        setShowMobileOptions(
                                            !showMobileOptions
                                                ? e.currentTarget
                                                : null
                                        );
                                    }}
                                    size="large"
                                >
                                    Approve / Decline
                                </Button>
                            ) : (
                                <Skeleton
                                    variant="rectangular"
                                    className={classes.sizer}
                                />
                            )}
                        </div>
                    </div>
                )}

                <Popover
                    open={Boolean(showMobileOptions)}
                    anchorEl={showMobileOptions}
                    onClose={() => {
                        setShowMobileOptions(null);
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    classes={{ root: classes.popOver }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                >
                    <div className={classes.mobileToggleWrap}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            spacing={2}
                        >
                            <EstimateDeclineMobile
                                onLoginBlocker={handleSetLoginBlocker}
                                onClick={setDeclineEstimateWarningActive}
                            />

                            <EstimateApproveMobile
                                onLoginBlocker={handleSetLoginBlocker}
                                onClick={setApproveEstimateWarningActive}
                            />
                        </Grid>
                    </div>
                </Popover>

                {isModalActive('loader-invoice-download') && (
                    <LottieModal
                        title="Downloading Estimate..."
                        backgroundColor="#F5F9FF"
                        open
                    >
                        <LottieDownloadLoading />
                    </LottieModal>
                )}

                {isModalActive('loader-estimate-send') && (
                    <LottieModal
                        title="Sending Estimate..."
                        backgroundColor="#F5F9FF"
                        open
                    >
                        <LottieSendingLoading />
                    </LottieModal>
                )}

                {isModalActive('loader-generate-estimate-link') && (
                    <LottieModal
                        title="Generating estimate link..."
                        backgroundColor="#F5F9FF"
                        open
                    >
                        <LottiePreviewLoading />
                    </LottieModal>
                )}

                {isModalActive('info-share-link') && (
                    <ShareLinkModal
                        open
                        onClose={() => dispatch(closeModal('info-share-link'))}
                        link={estimate?.link || ''}
                    />
                )}

                <NoEmailModal
                    open={isModalActive('process-estimate-email-required')}
                    onClose={async (emailAddress, saveToClient, setLoading) => {
                        if (emailAddress === undefined) {
                            dispatch(
                                closeModal('process-estimate-email-required')
                            );
                            return;
                        }

                        setLoading(true);

                        const updatedClient = {
                            ...estimate.to,
                            emailAddress
                        };

                        const validatedInvoice = await validateEstimate(
                            estimate
                        );

                        await sendEstimate(validatedInvoice, emailAddress);

                        if (saveToClient) {
                            updateClient(updatedClient);
                        }

                        setLoading(false);
                    }}
                    type="estimate"
                />
            </div>
        </div>
    );
};

EstimateSidebar.propTypes = {
    beforeActionPromptOptions: PropTypes.shape({}),
    handleSetLoginBlocker: PropTypes.func.isRequired,
    locked: PropTypes.bool,
    setBeforeActionPromptOptions: PropTypes.func.isRequired
};

EstimateSidebar.defaultProps = {
    beforeActionPromptOptions: {},
    locked: false
};

export default EstimateSidebar;
