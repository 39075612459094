import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
    billField: {
        cursor: 'pointer'
    },
    button: {
        width: '100%'
    },
    lockedBillField: {
        background: 'none !important',
        paddingTop: 4,
        paddingBottom: 4
    },
    innerInput: {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '-0.25px'
    },
    displayTextOuterLabel: {
        color: '#8C92A4',
        fontSize: 14,
        fontWeight: 500,
        minWidth: 84
    },
    displayTextAreaContainer: {
        display: 'flex'
    }
}));
