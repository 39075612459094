/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

// Helper function to get the initial state for a specific data type
const getInitialTypeState = () => ({
    items: [],
    totalPages: 0,
    totalItems: 0,
    loading: false
});

export const listingSlice = createSlice({
    name: 'listing',
    initialState,
    reducers: {
        fetchListingItems: (state, action) => {
            const { type } = action.payload;
            // Initialize state for the type if it doesn't exist or just set loading to true
            state[type] = state[type] || getInitialTypeState();
            state[type].loading = true;
        },
        overwriteListingItems: (state, action) => {
            const { type, items, totalPages, totalItems } = action.payload;
            state[type] = state[type] || getInitialTypeState();
            state[type].items = items;
            state[type].totalPages = totalPages;
            state[type].totalItems = totalItems;
            state[type].loading = false;
        },
        noListingItems: (state, action) => {
            const { type } = action.payload;
            state[type] = state[type] || getInitialTypeState();
            state[type].items = [];
            state[type].totalPages = 0;
            state[type].totalItems = 0;
            state[type].loading = false;
        },
        resetListingItems: (state, action) => {
            const { type } = action.payload;
            if (state[type]) {
                state[type] = getInitialTypeState();
            }
        }
    }
});

export const {
    fetchListingItems,
    overwriteListingItems,
    noListingItems,
    resetListingItems
} = listingSlice.actions;

export default listingSlice.reducer;
