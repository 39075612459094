import { useDispatch, useSelector } from 'react-redux';
import { setDocumentErrors, resetDocumentErrors } from '@/state/actions';
import { createSaveableObject, verifyDocumentFields } from '@/util';

/**
 * Estimate Hook
 * Validates an estimate object.
 *
 * Returns false, or clean estimate data.
 */
export const useValidateEstimate = () => {
    const dispatch = useDispatch();
    const activeBusiness = useSelector((state) => state?.user?.activeBusiness);
    const businessProfileUpdated =
        activeBusiness?.flags?.businessProfileUpdated;

    const validateEstimate = async (estimateToValidate) => {
        const estimateData = createSaveableObject({
            type: 'estimate',
            currentObject: estimateToValidate,
            activeBusiness
        });

        const verifiedResult = await verifyDocumentFields(estimateData);

        if (!businessProfileUpdated)
            verifiedResult.errors = {
                ...verifiedResult.errors,
                from: {
                    companyName: {
                        message: 'Company is required'
                    }
                }
            };

        if (!verifiedResult.success) {
            dispatch(setDocumentErrors(verifiedResult.errors));
        } else {
            dispatch(resetDocumentErrors());
        }

        if (!verifiedResult.success) {
            return false;
        }

        return estimateData;
    };

    return validateEstimate;
};
