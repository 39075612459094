/**
 * Get a currency/locale specific currency symbol.
 *
 * @param {string} locale The locale code of the destination format
 * @param {string} currency The destination currency code to format to
 * @returns {string} Currency symbol
 */
export const getCurrencySymbol = ({ locale, currency }) =>
    (0)
        .toLocaleString(locale, {
            style: 'currency',
            currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        })
        .replace(/\d/g, '')
        .trim();

/**
 * Get the position of the currency symbol.
 *
 * @param {string} locale The locale code of the destination format
 * @param {string} currency The destination currency code to format to
 * @returns {string} Symbol position. before/after.
 */
export const getCurrencySymbolPosition = ({ locale, currency }) => {
    const formatted = (0).toLocaleString(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    const symbol = formatted.replace(/\d/g, '').trim();
    const symbolIndex = formatted
        .split('')
        .findIndex((part) => part === symbol);

    if (symbolIndex === 0) {
        return 'before';
    }

    return 'after';
};

/**
 * This function takes in currency localization data object and returns formatted version of currency
 *
 * @param {number} amount The value of the currency to format
 * @param {string} locale The locale code of the destination format
 * @param {string} currency The destination currency code to format to
 * @returns {string} Formatted currency with appropriate currency symbol
 */
export const formatCurrency = ({ amount, locale, currency }) =>
    new Intl.NumberFormat(locale, {
        style: 'currency',
        currency
    }).format(amount);

/**
 * Take a number and round it to two decimal places.
 * @param  {number} num Original unrounded number.
 * @return {number}     Rounded number.
 */
export const roundToTwoDecimals = (num) =>
    Math.round((num + Number.EPSILON) * 100) / 100;

// Used to convert currency to integers for API
export const toIntegerCurrency = (amount) => Math.round(amount * 100);

export const toWholeCurrency = (amount) => {
    if (amount == null || amount === 0 || Number.isNaN(amount)) {
        return 0;
    }

    return amount / 100;
};

export const trimFloat = (float) => float && Math.trunc(float);

/*
Takes a baseNumber and percentage as integers, and adds the percentage of basenumber to baseNumber.

These functions expect integer currency arguments. This is why 10000 is the base number to which the percentage is added. It is 100 base multiplied by 100 as the passed values are as offset by 100 as well (integer currency).
*/

export const calculateIntegerPercentage = (baseNumber, percentage) =>
    Math.round((baseNumber * (10000 + percentage)) / 100 / 100) - baseNumber;

/**
 * Returns the integer amount. Get the outcome of a percentage discount.
 * @param  {number} price The original price of the item.
 * @param  {number} percentage The percent to take off the original value.
 * @return {int} Total amount.
 */
export const calculatePercentageDiscount = (price, percentage) =>
    price - calculateIntegerPercentage(price, toIntegerCurrency(percentage));
