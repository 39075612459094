import React from 'react';

import { Typography } from '@mui/material';

import {
    defaultTransaction,
    transactionMethod,
    transactionLast4
} from '@/models';

import { paymentMethods } from '@/config';

import {
    CreditCardIcon,
    OtherPaymentIcon,
    CashPaymentIcon,
    CheckPaymentIcon,
    ACHSmall,
    AfterpayLogo,
    AffirmLogo,
    KlarnaRound,
    CashAppIcon,
    VenmoIcon,
    ZelleIcon
} from '@/resources/icons';
import useStyles from './styles';

const getIcon = (method, className) => {
    switch (method) {
        case 'card':
        case 'genericCreditCard':
        case 'stripe':
        case 'square': {
            return <CreditCardIcon className={className} />;
        }
        case 'cash': {
            return <CashPaymentIcon className={className} />;
        }
        case 'check': {
            return <CheckPaymentIcon className={className} />;
        }
        case 'bank_account':
        case 'directdebit': {
            return <ACHSmall className={className} />;
        }
        case 'afterpay_clearpay': {
            return <AfterpayLogo className={className} />;
        }
        case 'affirm': {
            return <AffirmLogo className={className} />;
        }
        case 'klarna': {
            return <KlarnaRound className={className} />;
        }
        case 'wallet':
        case 'cashapp': {
            return <CashAppIcon className={className} />;
        }
        case 'venmo': {
            return <VenmoIcon className={className} />;
        }
        case 'zelle': {
            return <ZelleIcon className={className} />;
        }
        default: {
            return <OtherPaymentIcon className={className} />;
        }
    }
};

const PaymentMethodView = ({ method, last4 }) => {
    const methodIsCard =
        method === 'card' || method === 'stripe' || method === 'square';
    const classes = useStyles();

    const getThirdPartyMethodName = (name) => {
        switch (name) {
            case 'afterpay_clearpay':
                return 'Afterpay';
            case 'affirm':
                return 'Affirm';
            case 'klarna':
                return 'Klarna';
            case 'bank_account':
                return 'Bank Transfer';
            case 'wallet':
                return 'Cash App';
            case 'genericCreditCard':
                return 'Card';

            default:
                return 'Other';
        }
    };

    const methodName = methodIsCard
        ? method
        : paymentMethods.find((meth) => meth.slug === method)?.name;

    const thirdPartyMethodName = getThirdPartyMethodName(method);

    return (
        <div className={classes.cardContainer}>
            <div className={classes.cardAligner}>
                <div className={classes.cardHeader}>
                    {getIcon(method, classes.creditCardIcon)}
                    <Typography className={classes.methodTypography}>
                        {methodName || thirdPartyMethodName}
                    </Typography>
                </div>
                <div>
                    {(method === 'card' ||
                        method === 'bank_account' ||
                        method === 'directdebit') &&
                        last4 && (
                            <Typography variant="h5">{`**********${last4}`}</Typography>
                        )}
                </div>
            </div>
        </div>
    );
};

PaymentMethodView.propTypes = {
    method: transactionMethod,
    last4: transactionLast4
};

PaymentMethodView.defaultProps = {
    method: defaultTransaction.method,
    last4: defaultTransaction.last4
};

export default PaymentMethodView;
