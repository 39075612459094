import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { formatDate } from '@/util';
import useStyles from './styles';

const NextBillDate = ({ nextBillDate, locale, timezone, chargify }) => {
    const isInCancelState = chargify?.cancelAtEndOfPeriod;

    const classes = useStyles({ isInCancelState });
    return (
        <Grid container direction="column">
            <Typography variant="h5" className={classes.payInfoSubTitle}>
                {`${isInCancelState ? `Canceling` : 'Next Bill Date'}:`}
            </Typography>
            <Typography
                variant="body2"
                color="textSecondary"
                className={classes.nextBillDate}
            >
                {isInCancelState
                    ? formatDate({
                          isoDate: nextBillDate,
                          locale,
                          timezone,
                          returnFormat: 'shortDate'
                      })
                    : formatDate({
                          isoDate: nextBillDate,
                          locale,
                          timezone,
                          returnFormat: 'monthDate'
                      })}
            </Typography>
        </Grid>
    );
};

NextBillDate.propTypes = {
    nextBillDate: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
    chargify: PropTypes.object
};

NextBillDate.defaultProps = {
    chargify: undefined
};

export default NextBillDate;
