import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    listItem: {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        padding: 0,
        backgroundColor: ({ toggleRotate }) => toggleRotate && '#fff',
        [theme.breakpoints.up('lg')]: {
            minWidth: '100%'
        }
    },
    topListItem: {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        padding: 0,
        backgroundColor: ({ toggleRotate }) => toggleRotate && '#fff',
        [theme.breakpoints.up('lg')]: {
            minWidth: '100%'
        }
    },
    listItemChild: {
        borderRadius: 4,
        padding: 0,
        backgroundColor: ({ toggleRotate }) =>
            toggleRotate && theme.palette.common.white,
        [theme.breakpoints.up('lg')]: {
            minWidth: '100%'
        }
    },
    listItemLink: {
        fontFamily: theme.typography.fontFamily,
        minWidth: theme.typography.pxToRem(200),
        width: '100%',
        textDecoration: 'none',
        padding: 13,

        transition: 'background 0.3s linear',

        borderRadius: 4,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'left',
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(14),
        '& .MuiListItemText-root': {
            color: ({ toggleRotate, color }) =>
                toggleRotate ? color : '#71747D'
        },
        '& div:nth-child(2)': {
            transition: 'transform 0.3s linear',
            transform: 'translate3d(0,0,0)'
        },
        '&:hover div:nth-child(2)': {
            transform: 'translate3d(4px,0,0)'
        },
        '&:hover': {
            backgroundColor: '#fff'
        },
        '&.active, &.active:hover': {
            backgroundColor: '#fff',
            '& .MuiListItemText-root': {
                color: ({ color }) => color
            }
        }
    },
    listItemLinkChild: {
        fontFamily: theme.typography.fontFamily,
        width: theme.typography.pxToRem(184),
        textDecoration: 'none',
        padding: `${theme.typography.pxToRem(5)} ${theme.typography.pxToRem(
            9
        )}`,
        [theme.breakpoints.up('lg')]: {
            padding: `${theme.typography.pxToRem(5)} ${theme.typography.pxToRem(
                9
            )}`,
            width: theme.typography.pxToRem(190)
        },
        borderRadius: 4,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'left',
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(12),
        '& .MuiListItemText-root': {
            color: ({ toggleRotate, color }) =>
                toggleRotate ? color : '#71747D'
        },
        '& div:nth-child(2)': {
            transition: 'transform 0.3s linear',
            transform: 'translate3d(0,0,0)'
        },
        '&:hover div:nth-child(2)': {
            transform: 'translate3d(4px,0,0)'
        },
        '&.active, &.active:hover': {
            backgroundColor: theme.palette.background.default,
            '& .MuiListItemText-root': {
                color: ({ color }) => color
            }
        }
    },
    listItemIcon: {
        maxWidth: ({ child }) => (child ? 16 : 24),
        [theme.breakpoints.up('lg')]: {
            maxWidth: 24
        },
        marginRight: theme.spacing(1.5),
        '& svg': {
            width: '100%',
            height: '100%'
        }
    },
    toggle: {
        color: '#8C92A4',
        transition: 'all 250ms',
        width: theme.typography.pxToRem(20),
        position: 'absolute',
        right: theme.typography.pxToRem(15),
        transform: ({ toggleRotate }) => toggleRotate && 'rotate(-180deg)'
    },
    zenDeskBase: {
        display: 'flex',
        padding: theme.spacing(2)
    },
    zenDeskHelpButton: {
        backgroundColor: '#1F73B7',
        color: '#FFFFFF',
        fill: '#FFFFFF',
        width: theme.typography.pxToRem(115),
        borderRadius: '999rem'
    },
    zenDeskHelpInnerContent: {
        color: '#FFFFFF',
        fill: '#FFFFFF',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'left',
        marginLeft: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    zenDeskHelpIcon: {
        width: theme.typography.pxToRem(20),
        height: theme.typography.pxToRem(20),
        marginRight: theme.typography.pxToRem(12)
    }
}));
