import React from 'react';
import PropTypes from 'prop-types';
import {
    ClientsActive,
    DiscountActive,
    InvoicesActive,
    EstimatesActive,
    ReceiptsActive,
    ProductsActive,
    ServicesActive,
    RecurringIcon,
    TaxesActive,
    TagsActive
} from '@/resources/icons';

import useStyles from './styles';

const icons = {
    client: ClientsActive,
    discount: DiscountActive,
    invoice: InvoicesActive,
    estimate: EstimatesActive,
    receipt: ReceiptsActive,
    product: ProductsActive,
    service: ServicesActive,
    tax: TaxesActive,
    'recurring-invoice': RecurringIcon,
    tag: TagsActive
};

const DocumentIconPicker = ({ type, small }) => {
    const classes = useStyles();
    const Icon = icons[type];

    if (!Icon) {
        return null;
    }

    return <Icon className={small ? classes.svgSmall : classes.svg} />;
};

DocumentIconPicker.propTypes = {
    type: PropTypes.string.isRequired,
    small: PropTypes.bool
};

DocumentIconPicker.defaultProps = {
    small: false
};

export default DocumentIconPicker;
