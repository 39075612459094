const gtmData = {
    userId: '',
    businessId: ''
};

export const setGtmDataLayer = (userId, businessId) => {
    if (userId !== gtmData.userId || businessId !== gtmData.businessId) {
        if (userId) {
            gtmData.userId = userId;
        }

        if (businessId) {
            gtmData.businessId = businessId;
        }

        window.dataLayer.push({
            event: 'GAbusinessID',
            dimension1: gtmData.businessId,
            dimension2: gtmData.userId
        });
    }
};
