import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    skeletonWrap: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 20,
        marginRight: 0,
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            marginLeft: '20px',
            marginRight: '-10px'
        }
    },
    subscriptionCardSkeleton: {
        width: '100%',
        height: theme.typography.pxToRem(340),
        marginBottom: theme.spacing(2),
        '&:last-child': {
            marginBottom: 0
        },
        [theme.breakpoints.up('lg')]: {
            width: '33%',
            height: theme.typography.pxToRem(380),
            marginTop: -theme.spacing(8),
            marginRight: 20,
            marginBottom: 0
        }
    }
}));
