import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const DividerWithText = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.dividerWrap}>
            <div className={classes.divider} />
            <p className={classes.text}>{children}</p>
            <div className={classes.divider} />
        </div>
    );
};

DividerWithText.propTypes = {
    children: PropTypes.node.isRequired
};

export default DividerWithText;
