import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
    Radio,
    TextField,
    InputAdornment
} from '@mui/material';

import { VALUE_PERCENTAGE_TYPE, VALUE_FLAT_RATE_TYPE } from '@/constants';

import { useCurrency } from '@/hooks';
import {
    forcePositiveNumberInput,
    isPercent,
    currencySymbolAdornment
} from '@/util';

import RadioIcon from './RadioIcon';

import makeStyles from './styles';

export const getInputProps = (currencySymbol, position, type) => {
    if (isPercent(type)) {
        return {
            endAdornment: (
                <InputAdornment position="end">
                    <Typography>%</Typography>
                </InputAdornment>
            )
        };
    }

    return currencySymbolAdornment({ position, symbol: currencySymbol });
};

/**
 * The component adds two radio buttons next to a number input. This allows
 * changing between flat/percentage values, and updated the icons/labels to
 * correspond.
 * This is an uncontrolled component, and will need state handling externally.
 * @param {bool} error Is there an error.
 * @param {string} helperText Text to explain the error.
 * @param {string} id ID of the input field.
 * @param {object} inputRef Ref object to be passed to the DOM input element
 * @param {bool} isInvoice Is this component used in an invoice.
 * @param {func} onBlur Callback function called on input field blur.
 * @param {[type]} onUpdate Callback function called on input/radio change.
 * @param {bool} showAdornment Should adornments be shown? default to true.
 * @param {string} title Title/label of the input field.
 * @param {string} type VALUE_PERCENTAGE_TYPE/DISCOUNT_FLAT_TYPE.
 * @param {int} value Value of the input.
 */
const ValueModifier = ({
    error,
    helperText,
    id,
    inputRef,
    isInvoice,
    onBlur,
    onUpdate,
    showAdornment,
    title,
    type,
    value
}) => {
    const classes = makeStyles();

    const handleOnChange = ({ target }) =>
        onUpdate({
            value,
            type: target.value
        });

    const { symbol, position } = useCurrency({ isInvoice });

    let radioSymbol = symbol;
    if (radioSymbol.length > 1) {
        radioSymbol = '$';
    }

    return (
        <Grid item className={classes.modifier}>
            <TextField
                id={`${id}-value-input`}
                fullWidth
                error={error}
                helperText={helperText}
                value={value}
                className={classes.inputField}
                label={title}
                name="value"
                InputProps={
                    showAdornment
                        ? getInputProps(symbol, position, type)
                        : undefined
                }
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                    type: 'number',
                    step: '0.01',
                    min: '0'
                }}
                onChange={(e) =>
                    onUpdate({
                        value: e.target.value ? parseFloat(e.target.value) : '',
                        type
                    })
                }
                onKeyDown={forcePositiveNumberInput}
                onBlur={onBlur}
                inputRef={inputRef}
            />

            <div className={classes.typeOptions}>
                <Radio
                    checked={isPercent(type)}
                    onChange={handleOnChange}
                    value={VALUE_PERCENTAGE_TYPE}
                    name={`${id}-value-modifier-radio-button`}
                    id={`${id}-percent-radio-button`}
                    inputProps={{ 'aria-label': 'Percent' }}
                    icon={<RadioIcon>%</RadioIcon>}
                    checkedIcon={<RadioIcon checked>%</RadioIcon>}
                />

                <Radio
                    checked={!isPercent(type)}
                    onChange={handleOnChange}
                    value={VALUE_FLAT_RATE_TYPE}
                    name={`${id}-value-modifier-radio-button`}
                    id={`${id}-flat-radio-button`}
                    inputProps={{ 'aria-label': 'Flat Rate' }}
                    icon={<RadioIcon>{radioSymbol}</RadioIcon>}
                    checkedIcon={<RadioIcon checked>{radioSymbol}</RadioIcon>}
                />
            </div>
        </Grid>
    );
};

ValueModifier.defaultProps = {
    error: false,
    helperText: '',
    inputRef: null,
    isInvoice: false,
    onBlur: () => {},
    showAdornment: true,
    value: ''
};

ValueModifier.propTypes = {
    error: PropTypes.bool,
    helperText: PropTypes.string,
    id: PropTypes.string.isRequired,
    inputRef: PropTypes.object,
    isInvoice: PropTypes.bool,
    onBlur: PropTypes.func,
    onUpdate: PropTypes.func.isRequired,
    showAdornment: PropTypes.bool,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ValueModifier;
