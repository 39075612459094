import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    deleteBusinessDiscount,
    getPaginatedDiscounts
} from '@/modules/dataWrangler/dataWrangler';
import { DeleteIcon } from '@/resources/icons';
import { openDrawer } from '@/state/actions';
import { useToastNotification, useUrlParams } from '@/hooks';

import WarnConfirmDialog from '../../WarnConfirmDialog';
import ActionIconWrapper from '../ActionIconWrapper';

/**
 * Edit Icon for Discount with dispatch attached
 * @param {string} id ID of the discount to edit.
 */
export const EditDiscountIcon = ({ id }) => {
    const dispatch = useDispatch();

    const currentDiscount = useSelector((state) =>
        state.user?.activeBusiness?.discounts?.find(
            (element) => element.discountId === id
        )
    );

    const handleClick = () => {
        if (currentDiscount)
            dispatch(
                openDrawer({
                    id: 'discount',
                    discount: currentDiscount,
                    edit: true
                })
            );
    };

    return (
        <ActionIconWrapper
            action="edit"
            label="Edit"
            onClick={handleClick}
            tooltip="Edit Discount"
        />
    );
};

EditDiscountIcon.propTypes = {
    id: PropTypes.string.isRequired
};

/**
 * Delete Icon for Discount with dispatch attached
 * @param {string} id ID of the discount to delete.
 */
export const DeleteDiscountIcon = ({ id, discount }) => {
    const [dialogActive, setDialogAction] = useState(false);
    const { triggerToast } = useToastNotification();
    const { getUrlParams } = useUrlParams();

    const handleClick = () => {
        setDialogAction(true);
    };

    const handleConfirmation = async () => {
        const currentUrlParams = getUrlParams();
        setDialogAction(false);
        await deleteBusinessDiscount(id);
        getPaginatedDiscounts(currentUrlParams);
        triggerToast({
            message: `Discount "${discount}" Deleted `,
            action: 'delete',
            icon: <DeleteIcon />
        });
    };

    const handleCancellation = () => {
        setDialogAction(false);
    };

    return (
        <>
            <ActionIconWrapper
                action="delete"
                label="Delete"
                onClick={() => {
                    handleClick();
                }}
                tooltip="Delete Discount"
            />
            <WarnConfirmDialog
                onCancel={() => handleCancellation()}
                onConfirm={() => handleConfirmation()}
                message={`Are you sure you want to delete "${discount}"?`}
                cancelText="Cancel"
                confirmText="Delete Discount"
                open={dialogActive}
            />
        </>
    );
};

DeleteDiscountIcon.propTypes = {
    id: PropTypes.string.isRequired,
    discount: PropTypes.string.isRequired
};
