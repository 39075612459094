import { createTheme } from '@mui/material/styles';

// This only provides overrides for the pre-existing MUI theme.
// If any other root level objects need to be added, this will
// need to also be handed within the PaymentIntentModal component.
export default createTheme({
    typography: {
        fontFamily: "'Gilroy', 'Roboto', 'Helvetica', 'Arial', sans-serif"
    },
    components: {
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: 16,
                    fontWeight: 'normal'
                }
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                    borderRadius: 0,
                    borderBottom: '1px solid #F0F5F9',
                    '&$focused': {
                        backgroundColor: '#DFDFE7'
                    },
                    '&.Mui-error': {
                        borderBottom: '2px solid #C20101'
                    }
                },
                input: {
                    '&[type=number]': {
                        mozAppearance: 'textfield !important'
                    },
                    '&::-webkit-outer-spin-button': {
                        webkitAppearance: 'none !important'
                    },
                    '&::-webkit-inner-spin-button': {
                        webkitAppearance: 'none !important'
                    },
                    fontSize: 18,
                    fontWeight: 600
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: 18,
                    fontWeight: 600
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                    fontWeight: 600
                }
            }
        }
    }
});
