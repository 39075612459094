import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Menu, MenuItem } from '@mui/material';
import { SortIcon } from '@/resources/icons';
import { useUrlParams } from '@/hooks';
import useStyles from './styles';

const SortButton = ({ base, isLoading, sortMenuItems }) => {
    const classes = useStyles();
    const { getUrlParams, setUrlParams } = useUrlParams();

    const [sortMenuAnchorEl, setSortMenuAnchorEl] = useState(null);

    const currentUrlParams = getUrlParams({ base });

    const handleOpenSortMenu = (event) =>
        setSortMenuAnchorEl(event.currentTarget);

    const handleCloseSortMenu = () => setSortMenuAnchorEl(null);

    const handleSortOptionSelect = (item) => {
        const newParams = {
            ...currentUrlParams,
            sort: {
                type: item.type,
                order: item.order
            }
        };

        handleCloseSortMenu();

        // This setTimeout is necessary to space out the MUI Menu close and
        // the component rerender due to Url param change. If these two events
        // happen synchronously it will cause a lag when the Menu is trying to close.
        setTimeout(() => {
            if (!isLoading) setUrlParams({ base, params: newParams });
        }, 10);
    };

    return (
        <>
            <Button
                variant="contained"
                fullWidth
                className={classes.sortButton}
                startIcon={<SortIcon className={classes.sortIcon} />}
                onClick={handleOpenSortMenu}
            >
                Sort
            </Button>
            <Menu
                anchorEl={sortMenuAnchorEl}
                open={Boolean(sortMenuAnchorEl)}
                onClose={handleCloseSortMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <div className={classes.sortMenu}>
                    {sortMenuItems.map((item) => (
                        <MenuItem
                            key={item.name}
                            onClick={() => handleSortOptionSelect(item)}
                        >
                            {item.name}
                        </MenuItem>
                    ))}
                </div>
            </Menu>
        </>
    );
};

SortButton.propTypes = {
    base: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    sortMenuItems: PropTypes.arrayOf(PropTypes.object).isRequired
};

SortButton.defaultProps = {
    isLoading: false
};

export default SortButton;
