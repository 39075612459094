import makeStyles from '@mui/styles/makeStyles';

const fieldLineHeight = 20;

export default makeStyles((theme) => ({
    notesLabel: {
        fontStyle: 'normal',
        marginBottom: 10
    },
    notesHelperText: {
        textAlign: 'right'
    },
    notesTextfield: {},
    innerInput: ({ isExpanding }) => ({
        fontSize: 16,
        fontWeight: 600,
        lineHeight: `${fieldLineHeight}px`,
        letterSpacing: '-0.25px',
        overflow: isExpanding && 'hidden',
        alignItems: 'strech',
        [theme.breakpoints.down('sm')]: {
            overflow: 'auto'
        }
    })
}));
