/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
    useTheme,
    Stepper,
    Step,
    StepLabel,
    StepConnector,
    Button,
    Grid
} from '@mui/material';
import Check from '@mui/icons-material/Check';
import { setQrCodeURL } from '@/actions';
import {
    ButtonLoading,
    DrawerBody,
    DrawerHeading,
    CaptionedImage,
    UncontrolledGenericDrawer
} from '@/components/common';
import {
    createQrAndTextConnection,
    updateBusinessSettings,
    getAllThirdPartyConnections
} from '@/modules/dataWrangler/dataWrangler';
import UploadStep from './steps/UploadStep';
import ConfirmStep from './steps/ConfirmStep';
import useStyles from './styles';

const CustomStepperIcons = ({ active, completed, icon }) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <div
            className={`${classes.root} ${active && classes.active} ${
                completed && classes.completed
            }`}
        >
            {completed ? <Check /> : icon}
        </div>
    );
};

CustomStepperIcons.propTypes = {
    active: PropTypes.bool.isRequired,
    completed: PropTypes.bool.isRequired,
    icon: PropTypes.node.isRequired
};

const DrawerButtons = ({
    slug,
    handleBack,
    qrCodeURL,
    zelleTextfields,
    loading,
    onSubmit,
    activeStep
}) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const buttonId = `Connect${slug}`;
    return (
        <>
            <Button
                className={classes.footerButton}
                fullWidth
                onClick={handleBack}
                variant="outlined"
            >
                Back
            </Button>
            <ButtonLoading
                id={buttonId}
                className={classes.footerButton}
                color="primary"
                disabled={
                    qrCodeURL === '' &&
                    zelleTextfields.emailAddress === '' &&
                    zelleTextfields.phoneNumber === ''
                }
                disableOnLoading
                fullWidth
                loading={loading}
                onClick={onSubmit}
                type="submit"
                variant="contained"
            >
                {activeStep === 0 ? 'Next' : 'Save'}
            </ButtonLoading>
        </>
    );
};

DrawerButtons.propTypes = {
    activeStep: PropTypes.number.isRequired,
    handleBack: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    qrCodeURL: PropTypes.string,
    slug: PropTypes.string.isRequired,
    zelleTextfields: PropTypes.shape({
        emailAddress: PropTypes.string,
        phoneNumber: PropTypes.string
    })
};

DrawerButtons.defaultProps = {
    loading: false,
    qrCodeURL: '',
    zelleTextfields: {
        emailAddress: '',
        phoneNumber: ''
    }
};

const QRCodeStepper = ({
    exampleImage,
    merchant,
    onClose,
    open,
    UploadInstructions
}) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch = useDispatch();

    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [userMerchantLink, setUserMerchantLink] = useState('');
    const [QRCodeReaderPromise, setQRCodeReaderPromise] = useState(null);
    const [showError, setShowError] = useState(false);

    const qrCodeURL = useSelector((state) => state?.qrUpload?.imageURL);
    const activeBusiness = useSelector((state) => state?.user?.activeBusiness);
    const preferredPaymentConnections =
        activeBusiness?.businessSettings?.preferredPaymentConnections;
    const connections = activeBusiness?.connections;

    const steps = [
        { label: merchant.slug === 'zelle' ? 'Connect' : 'Upload QR' },
        { label: 'Confirm' }
    ];

    const onImageUploadClick = async () => {
        // Trigger loading of the QR Code Reader, and reset the error message
        setShowError(false);
        if (!QRCodeReaderPromise) {
            const promise = import('@/modules/QRCodeReader');
            setQRCodeReaderPromise(promise);
        }
    };

    const defaultZelleValues = {
        emailAddress: '',
        phoneNumber: ''
    };

    const [zelleTextfields, setZelleTextfields] = useState(defaultZelleValues);

    const handleZelleTextfields = (value, key) => {
        setZelleTextfields({ ...zelleTextfields, [key]: value });
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <UploadStep
                        merchant={merchant}
                        loadQrReader={onImageUploadClick}
                        showError={showError}
                        updateZelleTextfields={(value, key) =>
                            handleZelleTextfields(value, key)
                        }
                        zelleTextfields={zelleTextfields}
                        setQRCodeReaderPromise={setQRCodeReaderPromise}
                    >
                        {!qrCodeURL && (
                            <>
                                <Grid
                                    item
                                    container
                                    xs
                                    direction="column"
                                    alignItems="center"
                                >
                                    <CaptionedImage
                                        caption={`Example ${merchant.name} code image`}
                                        imageSrc={exampleImage}
                                    />
                                </Grid>
                                <UploadInstructions />
                            </>
                        )}
                    </UploadStep>
                );
            case 1:
                return (
                    <ConfirmStep
                        merchant={merchant}
                        zelleTextfields={zelleTextfields}
                    />
                );
            default:
                return '';
        }
    };

    const handleClose = (e) => {
        setActiveStep(0);
        setLoading(false);
        setShowError(false);
        onClose(e);
    };

    const handleBack = (e) => {
        if (activeStep === 1) {
            setActiveStep(0);
        } else {
            setShowError(false);
            setZelleTextfields(defaultZelleValues);
            setQRCodeReaderPromise(null);
            onClose(e);
        }
    };

    const uploadAndGetQrData = async (decode) => {
        let link;
        try {
            link = await decode(qrCodeURL);
        } catch (error) {
            link = '';
        }
        setLoading(false);
        let validLink = false;

        for (let i = 0; i < merchant.baseLink.length && !validLink; i += 1) {
            if (link.includes(merchant.baseLink[i].replace('https://', ''))) {
                validLink = true;
            }
        }
        if (validLink) {
            setUserMerchantLink(link);
            setActiveStep(1);
        } else {
            setShowError(true);
            dispatch(setQrCodeURL(''));
            setQRCodeReaderPromise(null);
        }
    };

    const enableConnection = async (connectionId) => {
        // If Square is connected and has "Digital Wallets" enabled,
        // the Square Cash App will overwrite the QR Cash App and we should
        // not auto-enable the QR Cash App connection
        const cashAppConflict = Boolean(
            connections.find(
                (connection) =>
                    connection?.application === 'square' &&
                    connection?.connectionId ===
                        preferredPaymentConnections?.digitalWallet
            ) && merchant?.slug === 'cashapp'
        );

        const updatedPreferredPaymentConnections = cashAppConflict
            ? preferredPaymentConnections
            : {
                  ...preferredPaymentConnections,
                  otherConnections: [
                      ...preferredPaymentConnections?.otherConnections,
                      connectionId
                  ]
              };

        await updateBusinessSettings({
            ...activeBusiness,
            businessSettings: {
                ...activeBusiness?.businessSettings,
                preferredPaymentConnections: updatedPreferredPaymentConnections
            }
        });
    };

    const onSubmit = async () => {
        setLoading(true);

        const QRCodeReader =
            QRCodeReaderPromise || import('@/modules/QRCodeReader');
        const { decode, getBase64String } = await QRCodeReader;

        if (activeStep === 0) {
            if (qrCodeURL !== '') {
                setLoading(true);
                await uploadAndGetQrData(decode);
                setLoading(false);
            } else {
                setActiveStep(1);
                setLoading(false);
            }
            // User is on step two and submitted a valid QR Code
        } else if (qrCodeURL !== '') {
            const imageString = await getBase64String(qrCodeURL);

            const connectionToEnable = await createQrAndTextConnection(
                zelleTextfields,
                {
                    application: merchant.slug,
                    imageString,
                    userMerchantLink
                }
            );

            await enableConnection(connectionToEnable?.connectionId);
            await getAllThirdPartyConnections(activeBusiness.businessId);

            handleClose();
            // User is on step two and only filled out the textfield(s)
        } else {
            const connectionToEnable = await createQrAndTextConnection(
                zelleTextfields
            );
            await enableConnection(connectionToEnable?.connectionId);
            await getAllThirdPartyConnections(activeBusiness.businessId);

            handleClose();
        }
    };
    return (
        <UncontrolledGenericDrawer open={open} close={handleClose}>
            <DrawerHeading
                title={merchant.name}
                startAdornment={merchant.icon}
            />
            <div className={classes.form}>
                <div>
                    <Stepper
                        activeStep={activeStep}
                        alternativeLabel
                        className={classes.stepper}
                        connector={
                            <StepConnector className={classes.stepConnector} />
                        }
                    >
                        {steps.map((step, index) => (
                            <Step key={step.label} expanded>
                                <StepLabel
                                    StepIconComponent={CustomStepperIcons}
                                >
                                    <span
                                        className={
                                            activeStep === index
                                                ? classes.stepUnderline
                                                : classes.inactiveLabel
                                        }
                                    >
                                        {step.label}
                                    </span>
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <DrawerBody>{getStepContent(activeStep)}</DrawerBody>
                </div>

                <div className={classes.footerButtonContainer}>
                    <DrawerButtons
                        slug={merchant?.slug}
                        handleBack={handleBack}
                        qrCodeURL={qrCodeURL}
                        zelleTextfields={zelleTextfields}
                        loading={loading}
                        onSubmit={onSubmit}
                        activeStep={activeStep}
                    />
                </div>
            </div>
        </UncontrolledGenericDrawer>
    );
};

QRCodeStepper.propTypes = {
    exampleImage: PropTypes.string,
    merchant: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    UploadInstructions: PropTypes.func
};

QRCodeStepper.defaultProps = {
    onClose: () => {},
    open: false,
    exampleImage: '',
    UploadInstructions: () => {}
};

export default QRCodeStepper;
