import React, { useEffect } from 'react';
import { Grid, useMediaQuery, useTheme, Paper, Divider } from '@mui/material';
import { NoActivity } from '@/resources/images';
import {
    FilterButton,
    TableCard,
    TableMaker,
    PageEmptyState,
    Pagination,
    SEO,
    PageTitle,
    ListingClearButton,
    TableSkeleton,
    WrapWithPaper
} from '@/components/common';
import { Container, Layout } from '@/containers';
import { ActivityFilterDrawer } from '@/components/FilterDrawers';
import {
    useLocale,
    useUrlParams,
    useListingState,
    useCheckHasBusinessLoaded
} from '@/hooks';
import { getActivities } from '@/modules/dataWrangler/activities';
import { ActivityIcon } from '@/resources/icons';
import { ACTIVITY_BASE_PARAM } from '@/constants';
import { getActivityConstant } from '@/util';
import useStyles from './styles';
import { dataProcessor, generateComponents } from './dataProcessors';
import tableHead from './tableHead';
import SubscriptionBanner from '../../components/SubscriptionBanner';

const Activity = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const locale = useLocale();
    const hasBusinessLoaded = useCheckHasBusinessLoaded();
    const { setUrlParams, getUrlParams } = useUrlParams();

    const {
        hasData,
        items,
        loadingItems,
        loadingPaper,
        totalPages,
        zeroResultsReturned
    } = useListingState(getActivityConstant());

    const currentUrlParams = getUrlParams({ base: ACTIVITY_BASE_PARAM });
    const stringifiedParams = JSON.stringify({
        ...currentUrlParams,
        allActivities: true
    });

    const noFiltersApplied = Object.keys(currentUrlParams).length === 0;

    useEffect(() => {
        if (hasBusinessLoaded) {
            getActivities(JSON.parse(stringifiedParams));
        }
    }, [hasBusinessLoaded, stringifiedParams]);

    return (
        <Layout>
            <SEO title="All Activity" />
            <Container>
                <div
                    id="cio_message"
                    className={classes.cio_message}
                    data-testid="cio_message"
                />
                <SubscriptionBanner />

                {!hasData && !loadingItems && (
                    <PageEmptyState
                        image={<NoActivity />}
                        content="No activity documented"
                    />
                )}
                <>
                    {hasData && !loadingPaper && (
                        <>
                            {!isMobile && (
                                <Paper elevation={0} variant="outlined">
                                    <Grid
                                        container
                                        direction="row"
                                        spacing={1}
                                        wrap="nowrap"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Grid item md={2}>
                                            <PageTitle
                                                icon={<ActivityIcon />}
                                                title="Activity"
                                            />
                                        </Grid>

                                        <Grid
                                            container
                                            alignItems="center"
                                            item
                                            sm={2}
                                            md={2}
                                            style={{
                                                padding: '18px 12px 12px 0'
                                            }}
                                        >
                                            <Grid item md={5}>
                                                <ListingClearButton
                                                    base={ACTIVITY_BASE_PARAM}
                                                    watch={[
                                                        'parentType',
                                                        'createdAt',
                                                        'activityType'
                                                    ]}
                                                    hide={
                                                        zeroResultsReturned ||
                                                        loadingItems
                                                    }
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                md={7}
                                                style={{ textAlign: 'right' }}
                                            >
                                                <FilterButton
                                                    base={ACTIVITY_BASE_PARAM}
                                                    type="activity"
                                                    watch={[
                                                        'parentType',
                                                        'createdAt',
                                                        'activityType'
                                                    ]}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <TableMaker
                                        actionCols={[5, 6, 7, 8, 9, 10, 11]}
                                        ariaTableName="Activity Table"
                                        handleRequestSort={() => {}}
                                        headCells={tableHead}
                                        rowData={
                                            hasData && !zeroResultsReturned
                                                ? generateComponents({
                                                      array: dataProcessor(
                                                          items
                                                      ),
                                                      locale,
                                                      classes
                                                  })
                                                : []
                                        }
                                        order={
                                            currentUrlParams?.sort?.order ||
                                            'asc'
                                        }
                                        orderBy={
                                            currentUrlParams?.sort?.type || ''
                                        }
                                    />
                                    {zeroResultsReturned &&
                                        !loadingItems &&
                                        !noFiltersApplied && (
                                            <PageEmptyState
                                                image={<NoActivity />}
                                                content="The selected filters returned 0 results."
                                                buttonText="Reset filters"
                                                onClick={() => {
                                                    setUrlParams({
                                                        base: ACTIVITY_BASE_PARAM,
                                                        params: null
                                                    });
                                                }}
                                                hidePaper
                                            />
                                        )}
                                    <Pagination
                                        base={ACTIVITY_BASE_PARAM}
                                        totalPages={totalPages}
                                    />
                                </Paper>
                            )}

                            {isMobile && (
                                <>
                                    <Grid
                                        container
                                        direction="row"
                                        spacing={1}
                                        wrap="nowrap"
                                        alignItems="center"
                                        style={{
                                            padding: '0 0 12px 0'
                                        }}
                                    >
                                        <Grid item xs={6}>
                                            <PageTitle
                                                icon={<ActivityIcon />}
                                                title="Activity"
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            container
                                            spacing={1}
                                            xs={6}
                                            direction="row"
                                            wrap="nowrap"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                className={
                                                    classes.filterButtonWrap
                                                }
                                            >
                                                <FilterButton
                                                    base={ACTIVITY_BASE_PARAM}
                                                    type="activity"
                                                    watch={[
                                                        'parentType',
                                                        'createdAt',
                                                        'activityType'
                                                    ]}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {hasData &&
                                        !zeroResultsReturned &&
                                        generateComponents({
                                            array: dataProcessor(items),
                                            locale,
                                            classes
                                        }).map((item) => (
                                            <TableCard
                                                key={item.id}
                                                title={item.to}
                                                left={
                                                    <>
                                                        {item.event}
                                                        {item.itemid}
                                                    </>
                                                }
                                                right={item.date}
                                            />
                                        ))}
                                    {zeroResultsReturned &&
                                        !loadingItems &&
                                        !noFiltersApplied && (
                                            <PageEmptyState
                                                image={<NoActivity />}
                                                content="The selected filters returned 0 results."
                                                buttonText="Reset filters"
                                                onClick={() => {
                                                    setUrlParams({
                                                        base: ACTIVITY_BASE_PARAM,
                                                        params: null
                                                    });
                                                }}
                                            />
                                        )}
                                    <Paper elevation={0} variant="outlined">
                                        <Pagination
                                            base={ACTIVITY_BASE_PARAM}
                                            totalPages={totalPages}
                                        />
                                    </Paper>
                                </>
                            )}
                        </>
                    )}
                    {loadingPaper && (
                        <WrapWithPaper removeWrap={isMobile}>
                            <TableSkeleton isMobile={isMobile} />
                        </WrapWithPaper>
                    )}
                    {!loadingPaper && <ActivityFilterDrawer />}
                </>
            </Container>
        </Layout>
    );
};

export default Activity;
