export const injectScript = ({ id, url }) =>
    new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.setAttribute('id', id);
        script.src = url;
        document.getElementsByTagName('body')[0].appendChild(script);

        script.onload = () => {
            resolve();
        };

        script.onerror = () => {
            reject();
        };
    });
