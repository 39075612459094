import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    buttonIcon: {
        width: 20,
        height: 20,
        fill: '#475569'
    },
    outerAccordionButton: {
        whiteSpace: 'nowrap',
        color: '#475569',
        maxWidth: 215,
        backgroundColor: theme.palette.common.white,
        [theme.breakpoints.down(464)]: {
            whiteSpace: 'normal'
        }
    }
}));
