import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
    ButtonBase,
    Grid,
    Popover,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { EllipsisIcon } from '@/resources/icons';
import useStyles from './styles';

import {
    DownloadEstimateIcon,
    DuplicateEstimateIcon,
    EditEstimateIcon,
    ViewEstimateIcon,
    SendEstimateIcon,
    ConvertEstimateIcon,
    DownloadInvoiceIcon,
    DuplicateInvoiceIcon,
    EditInvoiceIcon,
    ViewInvoiceIcon,
    SendInvoiceIcon,
    DownloadReceiptIcon,
    DuplicateReceiptIcon,
    EditReceiptIcon,
    ViewReceiptIcon,
    SendReceiptIcon,
    EditClientIcon,
    DeleteClientIcon,
    EditProductIcon,
    DeleteProductIcon,
    EditServiceIcon,
    DeleteServiceIcon,
    EditDiscountIcon,
    DeleteDiscountIcon,
    EditTaxIcon,
    DeleteTaxIcon,
    EditTagIcon,
    DeleteTagIcon,
    EditRecurringInvoiceIcon,
    ViewRecurringInvoiceIcon,
    DuplicateRecurringInvoiceIcon
} from '../actionIcons';

const matchButtonWithAction = ({ action, id, label, toPass }) => {
    switch (action) {
        case 'editClient':
            return <EditClientIcon id={id} key={`${id}-edit-icon`} />;
        case 'deleteClient':
            return (
                <DeleteClientIcon
                    id={id}
                    key={`${id}-delete-icon`}
                    {...toPass}
                />
            );
        case 'downloadEstimate':
            return (
                <DownloadEstimateIcon
                    id={id}
                    label={label}
                    key={`${id}-down-icon`}
                />
            );
        case 'duplicateEstimate':
            return (
                <DuplicateEstimateIcon
                    id={id}
                    label={label}
                    key={`${id}-dupl-icon`}
                />
            );
        case 'editEstimate':
            return <EditEstimateIcon id={id} key={`${id}-edit-icon`} />;
        case 'viewEstimate':
            return <ViewEstimateIcon id={id} key={`${id}-view-icon`} />;
        case 'sendEstimate':
            return (
                <SendEstimateIcon id={id} key={`${id}-send-icon`} {...toPass} />
            );
        case 'convertEstimate':
            return (
                <ConvertEstimateIcon
                    id={id}
                    key={`${id}-convert-icon`}
                    {...toPass}
                />
            );
        case 'downloadInvoice':
            return (
                <DownloadInvoiceIcon
                    id={id}
                    label={label}
                    key={`${id}-down-icon`}
                />
            );
        case 'duplicateInvoice':
            return (
                <DuplicateInvoiceIcon
                    id={id}
                    label={label}
                    key={`${id}-dupl-icon`}
                />
            );
        case 'editInvoice':
            return <EditInvoiceIcon id={id} key={`${id}-edit-icon`} />;
        case 'viewInvoice':
            return <ViewInvoiceIcon id={id} key={`${id}-view-icon`} />;
        case 'sendInvoice':
            return (
                <SendInvoiceIcon id={id} key={`${id}-send-icon`} {...toPass} />
            );
        case 'downloadReceipt':
            return (
                <DownloadReceiptIcon
                    id={id}
                    label={label}
                    key={`${id}-down-icon`}
                    {...toPass}
                />
            );
        case 'duplicateReceipt':
            return (
                <DuplicateReceiptIcon
                    id={id}
                    label={label}
                    key={`${id}-dupl-icon`}
                />
            );
        case 'editReceipt':
            return <EditReceiptIcon id={id} key={`${id}-edit-icon`} />;
        case 'viewReceipt':
            return <ViewReceiptIcon id={id} key={`${id}-view-icon`} />;
        case 'sendReceipt':
            return (
                <SendReceiptIcon id={id} key={`${id}-send-icon`} {...toPass} />
            );
        case 'editProduct':
            return <EditProductIcon id={id} key={`${id}-edit-icon`} />;
        case 'deleteProduct':
            return (
                <DeleteProductIcon
                    id={id}
                    key={`${id}-delete-icon`}
                    {...toPass}
                />
            );
        case 'editService':
            return <EditServiceIcon id={id} key={`${id}-edit-icon`} />;
        case 'deleteService':
            return (
                <DeleteServiceIcon
                    id={id}
                    key={`${id}-delete-icon`}
                    {...toPass}
                />
            );
        case 'editDiscount':
            return <EditDiscountIcon id={id} key={`${id}-edit-icon`} />;
        case 'deleteBusinessDiscount':
            return (
                <DeleteDiscountIcon
                    id={id}
                    key={`${id}-delete-icon`}
                    {...toPass}
                />
            );
        case 'editTax':
            return <EditTaxIcon id={id} key={`${id}-edit-icon`} />;
        case 'deleteTax':
            return (
                <DeleteTaxIcon id={id} key={`${id}-delete-icon`} {...toPass} />
            );
        case 'editTag':
            return <EditTagIcon id={id} key={`${id}-edit-icon`} />;
        case 'deleteTag':
            return (
                <DeleteTagIcon id={id} key={`${id}-delete-icon`} {...toPass} />
            );
        case 'editRecurringInvoice':
            return <EditRecurringInvoiceIcon id={id} key={`${id}-edit-icon`} />;
        case 'viewRecurringInvoice':
            return <ViewRecurringInvoiceIcon id={id} key={`${id}-view-icon`} />;
        case 'duplicateRecurringInvoice':
            return (
                <DuplicateRecurringInvoiceIcon
                    id={id}
                    key={`${id}-dupl-icon`}
                />
            );

        default:
            return null;
    }
};

matchButtonWithAction.propTypes = {
    action: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    toPass: PropTypes.shape({})
};

matchButtonWithAction.defaultProps = {
    label: undefined,
    toPass: {}
};

const TableActionsWrap = ({ id, label, open, rest, toPass }) => {
    // If there is more than one icon, use the popover
    const classes = useStyles({ showPopover: rest.length > 1 });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const wrapRef = useRef();
    const [showOptions, setShowOptions] = useState(false);

    return (
        <div className={classes.wrap} ref={wrapRef}>
            {isMobile ? (
                <Grid container spacing={1} className={classes.mobileContainer}>
                    {matchButtonWithAction({ action: open, id })}
                    {rest.map((item) =>
                        matchButtonWithAction({
                            action: item,
                            id,
                            label,
                            toPass
                        })
                    )}
                </Grid>
            ) : (
                <>
                    <div className={classes.open}>
                        {matchButtonWithAction({ action: open, id })}
                    </div>
                    <ButtonBase
                        onClick={(e) => {
                            setShowOptions(
                                !showOptions ? e.currentTarget : null
                            );
                        }}
                        className={classes.optionButton}
                        name="Options"
                    >
                        <div className={classes.icon}>
                            <EllipsisIcon />
                        </div>
                    </ButtonBase>
                    <div className={classes.rest}>
                        {rest.map((item) =>
                            matchButtonWithAction({
                                action: item,
                                id,
                                label,
                                toPass
                            })
                        )}
                    </div>
                    <Popover
                        open={Boolean(showOptions)}
                        anchorEl={showOptions}
                        onClose={() => {
                            setShowOptions(null);
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        container={wrapRef.current}
                    >
                        <div className={classes.popover}>
                            {rest.map((item) =>
                                matchButtonWithAction({
                                    action: item,
                                    id,
                                    label,
                                    toPass
                                })
                            )}
                        </div>
                    </Popover>
                </>
            )}
        </div>
    );
};

TableActionsWrap.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    open: PropTypes.string,
    rest: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
    toPass: PropTypes.shape({})
};

TableActionsWrap.defaultProps = {
    label: undefined,
    open: '',
    rest: [],
    toPass: {}
};

export default TableActionsWrap;
