import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    clientMargin: {
        marginTop: theme.spacing(0.5)
    },
    tableSkeleton: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(
                2
            )}`,
            marginTop: 0
        }
    }
}));
