import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
    row: ({ hover }) => ({
        transition: 'all 250ms',
        '&:hover': {
            backgroundColor: hover && '#EFEFEF'
        }
    })
}));
