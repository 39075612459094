import { SET_GLOBAL_ERROR, RESET_GLOBAL_ERROR } from '@/constants';

export default (
    state = { active: false, title: null, message: null, icon: null },
    action
) => {
    switch (action.type) {
        case SET_GLOBAL_ERROR:
            return {
                active: true,
                title: action?.title,
                message: action?.message,
                icon: action?.icon
            };
        case RESET_GLOBAL_ERROR:
            return { active: false, title: null, message: null, icon: null };
        default:
            return state;
    }
};
