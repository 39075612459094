import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    sortButton: {
        backgroundColor: '#fff',
        border: '1px solid #EDEEF0',
        color: theme.palette.common.black,
        '&:hover': {
            backgroundColor: 'rgba(0,0,0, .08)',
            color: theme.palette.common.black,
            border: '1px solid #EDEEF0'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.typography.pxToRem(131)
        }
    },
    sortMenu: {
        width: 228
    },
    sortIcon: {
        width: 24,
        height: 24
    }
}));
