import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import useStyles from './styles';

const DrawerBody = ({ children, className }) => {
    const classes = useStyles();
    return (
        <Grid
            container
            direction="column"
            rowSpacing={2}
            className={`${classes.wrap} ${className}`}
        >
            {children}
        </Grid>
    );
};

DrawerBody.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
};

DrawerBody.defaultProps = {
    className: ''
};

export default DrawerBody;
