import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, Grid, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import useStyles from './styles';

const RenderOptions = ({
    listOptions,
    labelOne,
    labelTwo,
    formatSecondaryOptionLabel
}) => {
    const classes = useStyles();

    return (
        <Grid
            item
            container
            justifyContent="space-between"
            xs={12}
            className={classes.labelWrap}
        >
            <div className={classes.labelOneWrap}>
                <Typography className={classes.labelOne} variant="body2">
                    {listOptions[labelOne]}
                </Typography>
            </div>
            {labelTwo && (
                <div className={classes.labelTwoWrap}>
                    <Typography
                        className={classes.labelTwo}
                        variant="body2"
                        color="textSecondary"
                    >
                        {formatSecondaryOptionLabel
                            ? formatSecondaryOptionLabel(listOptions[labelTwo])
                            : listOptions[labelTwo]}
                    </Typography>
                </div>
            )}
        </Grid>
    );
};

RenderOptions.propTypes = {
    listOptions: PropTypes.object.isRequired,
    labelOne: PropTypes.string.isRequired,
    labelTwo: PropTypes.string,
    formatSecondaryOptionLabel: PropTypes.func
};

RenderOptions.defaultProps = {
    labelTwo: '',
    formatSecondaryOptionLabel: null
};

/**
 * Renders a Material-UI TextField with the Autocomplete dropdown when the user types.
 *
 * @param {object} options An object of the suggested results for the dropdown.
 * @param {func} onSelect A function that gets triggered only when the user
 * selects an option from the suggested results.
 * @param {func} onUniqueValueEntered A function that gets triggered only when the user
 * submits a value that is not listed in the suggested results.
 * @param {func} onClear A function that will be called when the user clears the
 * textfield by pressing the 'X' icon in the textfield.
 * @param {func} onTextFieldChange Function called on text change.
 * @param {func} onBlur Function called when the text field is blurred
 * @param {string} inputValue The value held in state outside of this component.
 * @param {bool} error Is there an error.
 * @param {string} helperText String of text for the error.
 * @param {string} label The label to be used for the textfield. Also used in the
 * textfield's id.
 * @param {array} optionLabels An array of up to two strings. Must match the name of a key
 * from the 'options' object. Used to determine what to render as suggested items.
 * @param {func} formatSecondaryOptionLabel If two values are passed in the optionLabels prop; a function to add custom
 * formatting to optionLabels[1]. Should return a String or Number value.
 * @param {num} resultsLimit A number to specify the number of results to display as suggested results.
 * @param {func} onInputChange A callback fired when any value changes based on typing into the input field. Use in combination with inputValue for a controlled inputfield.
 * @param {string/object} defaultValue The default value for the initial render of the text field. This will reset to null after any action is taken.
 * @param {bool} allowSearchableSecondaryLabel Option to include the secondary option label to be searchable. If true, optionLabels must have two strings in the array.
 */

const AutocompleteTextField = ({
    defaultValue,
    error,
    formatSecondaryOptionLabel,
    helperText,
    id,
    inputValue,
    label,
    onBlur,
    onClear,
    onInputChange,
    onSelect,
    onTextFieldChange,
    onUniqueValueEntered,
    options,
    optionLabels,
    resultsLimit,
    allowSearchableSecondaryLabel
}) => {
    const [initialValue, setInitialValue] = useState(null);
    const [open, setOpen] = useState(false);

    // We only want to set an initial value on the first mount
    // If a user has interacted with the component, we leave the value to be handled internally
    const hasInteracted = useRef();
    useEffect(() => {
        if (!hasInteracted.current) {
            hasInteracted.current = true;
            setInitialValue(defaultValue);
        }
    }, [defaultValue]);

    const filterOptions = createFilterOptions({
        limit: resultsLimit
    });

    const onValueChange = (value, reason) => {
        if (initialValue) {
            setInitialValue(null);
        }
        setOpen(true);
        switch (reason) {
            case 'selectOption':
                onSelect(value);
                setOpen(false);
                break;
            case 'blur':
                setOpen(false);
                break;
            case 'createOption':
                onUniqueValueEntered(value);
                break;
            case 'clear':
                onClear();
                setOpen(false);
                break;
            default:
                break;
        }
    };

    const onTextFieldClick = (value) => {
        if (!value) {
            setOpen(true);
        }
    };

    const buildOptionLabel = (option) => {
        if (typeof option === 'string') return option;
        return allowSearchableSecondaryLabel
            ? `${option[optionLabels[0]]} ${option[optionLabels[1]]}`
            : option[optionLabels[0]];
    };

    return (
        <Autocomplete
            freeSolo
            autoHighlight
            open={open}
            id={id || `autocomplete-${label}`}
            options={options}
            filterOptions={filterOptions}
            getOptionLabel={buildOptionLabel}
            inputValue={inputValue}
            // When not being directly controlled this value should be null
            // Null is necessary so that when a user clears autocomplete they can reselect
            // the same value AND reapply it. If it is not set null, then all characters are deleted
            // in the input field, then select the same option, it will not fire the same
            // event when selecting that option.
            value={initialValue}
            renderOption={(props, option) => (
                <li {...props} key={props.id}>
                    <RenderOptions
                        listOptions={option}
                        labelOne={optionLabels[0]}
                        labelTwo={optionLabels[1]}
                        formatSecondaryOptionLabel={formatSecondaryOptionLabel}
                    />
                </li>
            )}
            onClose={(e, reason) =>
                reason === 'blur' && onValueChange(e.target.value, reason)
            }
            onChange={(_, value, reason) => onValueChange(value, reason)}
            onInputChange={(_, value, reason) => {
                if (reason === 'input') onInputChange(value);
                else if (reason === 'clear') onValueChange(value, reason);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={error}
                    helperText={helperText}
                    onChange={(e) => {
                        setOpen(true);
                        onTextFieldChange(e);
                    }}
                    label={label}
                    variant="filled"
                    inputProps={{
                        ...params.inputProps,
                        maxLength: 256
                    }}
                    onBlur={onBlur}
                    onClick={(e) => onTextFieldClick(e.target.value)}
                />
            )}
        />
    );
};

AutocompleteTextField.propTypes = {
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.number
    ]),
    error: PropTypes.bool,
    formatSecondaryOptionLabel: PropTypes.func,
    helperText: PropTypes.string,
    id: PropTypes.string,
    inputValue: PropTypes.string,
    label: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onClear: PropTypes.func.isRequired,
    onInputChange: PropTypes.func,
    onSelect: PropTypes.func.isRequired,
    onTextFieldChange: PropTypes.func,
    onUniqueValueEntered: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    optionLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
    resultsLimit: PropTypes.number,
    allowSearchableSecondaryLabel: PropTypes.bool
};

AutocompleteTextField.defaultProps = {
    defaultValue: null,
    error: false,
    formatSecondaryOptionLabel: undefined,
    helperText: '',
    id: undefined,
    inputValue: undefined,
    onBlur: () => {},
    onInputChange: () => {},
    onTextFieldChange: () => {},
    resultsLimit: 10,
    allowSearchableSecondaryLabel: false
};

export default AutocompleteTextField;
