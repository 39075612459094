import { parse, format } from 'date-fns';

/**
 * Creates a formatted date. Default: 10/04/2021
 *
 * @param  {string} isoDate iso string date to be formatted.
 * @param  {string} locale Format for date.
 * @param  {string} timezone Timezone for formatting.
 * @param  {boolean} useDash Option to use dashes as delimiter (i.e. 12-25-2020 if useDash is true, 12/25/2020 if useDash is false).
 * @param {string} returnFormat Optional format of returned date. Default = '12/25/2020', 'shortDate' = '12/25/20', 'monthDate' = 'Dec 25, 2020',
 *
 * @return {string} Correctly formatted date.
 */
export const formatDate = ({
    isoDate,
    locale,
    timezone,
    useDash,
    returnFormat
}) => {
    try {
        const date = new Date(isoDate);

        const formattedDate = new Intl.DateTimeFormat(locale, {
            year: returnFormat === 'shortDate' ? '2-digit' : 'numeric',
            month: returnFormat === 'monthDate' ? 'short' : '2-digit',
            day: returnFormat === 'monthDate' ? 'numeric' : '2-digit',
            timeZone: timezone
        }).format(date);

        return useDash ? formattedDate.replaceAll('/', '-') : formattedDate;
    } catch (err) {
        return '';
    }
};

/**
 * Creates a 12 hour time with letters: 10:00 AM
 *
 * @param  {string} isoDate iso string date to be formatted.
 * @param  {string} locale Format for date.
 * @param  {string} timezone Timezone for formatting.
 * @return {string} Correctly formatted date.
 */
export const formatTime = ({ isoDate, locale, timezone }) => {
    const date = new Date(isoDate);

    return new Intl.DateTimeFormat(locale, {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
        timeZone: timezone
    }).format(date);
};

/**
 *
 * @param {string} date Date string in iso 8601 format
 * @returns Short date in YYYY-MM-DD format
 */
export const dateToUniversalShort = (date) =>
    format(new Date(date), 'yyyy-MM-dd');

/**
 *
 * @param {string} date Date string in YYYY-MM-DD format
 * @returns Date string in iso 8601 format
 */
export const dateIsoStringFromUniversalShort = (date) =>
    parse(date, 'yyyy-MM-dd', new Date());
