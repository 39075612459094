import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    accordionContainer: {
        border: '1px solid #CBD5E1',
        borderRadius: 3,
        backgroundColor: '#F8F8FC',
        marginTop: 18
    },
    expandIcon: {
        width: 30,
        height: 30
    },
    expandIconWrapper: {
        margin: '0 0 auto',
        padding: 15
    },
    accordionDetails: {
        borderTop: `1px solid #CBD5E1`,
        marginBottom: theme.spacing(2)
    },
    iconContainer: {
        width: 55,
        height: 55,
        marginRight: 25
    },
    merchantName: {
        fontSize: 24
    },
    paymentChannel: {
        padding: '0 16px',
        whiteSpace: 'nowrap',
        borderLeft: '1px solid #94A3B8',
        '&:first-child': {
            borderLeft: 'none',
            paddingLeft: 0
        },
        [theme.breakpoints.down('md')]: {
            border: 'none',
            padding: '0 0 9px 0',
            '&:last-child': {
                paddingBottom: 0
            }
        }
    },
    lowerWrapper: {
        alignItems: 'center',
        marginTop: 18,
        minWidth: 'calc(100% + 60px)',
        [theme.breakpoints.down('md')]: {
            alignItems: 'end'
        }
    },
    paymentChannelPillIcon: {
        width: 16,
        height: 16,
        marginLeft: 4
    },
    paymentChannelEnabled: {
        display: 'flex',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        backgroundColor: '#BBF7D0',
        borderRadius: 100,
        padding: '2px 8px 2px 12px',
        margin: '0 2px',
        '&:first-child': {
            marginLeft: 0
        },
        '& svg': {
            fill: '#14532D'
        },
        [theme.breakpoints.down('md')]: {
            width: 'fit-content',
            margin: '0 0 9px 0',
            '&:last-child': {
                marginBottom: 0
            }
        }
    },
    paymentChannelDisabled: {
        display: 'flex',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        backgroundColor: '#E0E5EE',
        borderRadius: 100,
        padding: '2px 8px 2px 12px',
        margin: '0 2px',
        '&:first-child': {
            marginLeft: 0
        },
        [theme.breakpoints.down('md')]: {
            width: 'fit-content',
            margin: '0 0 9px 0',
            '&:last-child': {
                marginBottom: 0
            }
        }
    },
    buttonIcon: {
        width: 20,
        height: 20
    },
    paymentChannelContainer: {
        height: 24,
        flexWrap: 'nowrap',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            height: 'auto'
        }
    },
    outerAccordionButton: {
        whiteSpace: 'nowrap',
        color: '#475569',
        maxWidth: 215,
        backgroundColor: theme.palette.common.white,
        [theme.breakpoints.down(464)]: {
            whiteSpace: 'normal'
        }
    }
}));
