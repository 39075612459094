import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(2.5),
        borderTop: `1px solid ${theme.palette.background.default}`,
        borderBottom: `1px solid ${theme.palette.background.default}`
    },
    indicator: ({ tabColor }) => ({
        backgroundColor: 'transparent',
        border: 'transparent',
        display: 'flex',
        height: '3px',
        justifyContent: 'center',
        zIndex: '1',
        '& > span': {
            maxWidth: theme.typography.pxToRem(76),
            width: '100%',
            height: '3px',
            border: `1px solid ${tabColor}`,
            borderRadius: '10px',
            backgroundColor: tabColor,
            position: 'relative'
        }
    })
}));
