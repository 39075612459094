import { useEffect, useState, useCallback } from 'react';
import { Capacitor } from '@capacitor/core';
import { useSelector } from 'react-redux';
import { getAvailableSubscriptions } from '@/modules/subscriptionManager';
import { initIapStore } from '@/modules/dataWrangler/iapHelpers';

if (Capacitor.getPlatform() === 'ios') {
    document.addEventListener('deviceready', initIapStore, false);
}

export const useSubscriptionProducts = () => {
    const [loading, setLoading] = useState(false);

    const availableProducts = useSelector(
        (state) => state?.subscriptionProducts || {}
    );

    const getData = useCallback(async () => {
        // If we don't have any data, and this function has never been called.
        if (Object.entries(availableProducts || {}).length === 0 && !loading) {
            setLoading(true);
            await getAvailableSubscriptions();
            setLoading(false);
        }
    }, [availableProducts, loading]);

    useEffect(() => {
        getData();
    }, [getData]);

    return { loading, products: availableProducts?.products || [] };
};
