import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { updateBusinessProfile } from '@/state/actions';
import { DrawerBody } from '@/components/common';
import { TextField, PhoneNumber } from '@/components/rhf-mui';
import { emailRegexPattern } from '@/util';
import Button from '../../Button';
import FormHeader from '../../FormHeader';
import Warning from '../../Warning';

import useStyles from '../../styles';

const Information = ({ clickedOutside, fields, goToNextStep }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [defaultPhoneCountry, setDefaultPhoneCountry] = useState('');

    const methods = useForm({
        mode: 'onBlur',
        defaultValues: {
            companyName: fields?.companyName || '',
            emailAddress: fields?.emailAddress || '',
            phoneNumber: fields?.phoneNumber || '',
            website: fields?.website
        }
    });

    const { handleSubmit, watch } = methods;

    // Watch all fields in the form and push to Redux on change.
    useEffect(() => {
        const subscription = watch((value, { name }) => {
            dispatch(updateBusinessProfile({ [name]: value[name] || '' }));
        });

        return () => subscription.unsubscribe();
    }, [dispatch, watch]);

    return (
        <FormProvider {...methods}>
            <form
                className={classes.form}
                onSubmit={handleSubmit(goToNextStep)}
            >
                <DrawerBody>
                    <FormHeader
                        title="Add your company information."
                        subtitle="Company Information"
                    />

                    <Grid item>
                        <TextField
                            name="companyName"
                            rules={{ required: 'Company name is required' }}
                            inputProps={{ maxLength: 50 }}
                            fullWidth
                            label="Company Name (or your name)*"
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            name="emailAddress"
                            inputProps={{ maxLength: 50 }}
                            fullWidth
                            label="Company Email"
                            rules={{
                                pattern: {
                                    value: emailRegexPattern,
                                    message: 'Must be a valid email'
                                }
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <PhoneNumber
                            name="phoneNumber"
                            label="Phone Number"
                            defaultValue={fields?.phoneNumber || ''}
                            defaultCountry={
                                defaultPhoneCountry || fields?.address?.country
                            }
                            setDefaultPhoneCountry={setDefaultPhoneCountry}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            name="website"
                            fullWidth
                            label="Company Website"
                        />
                    </Grid>
                    <Warning clickedOutside={clickedOutside} />
                </DrawerBody>

                <Button id="Billfrom" />
            </form>
        </FormProvider>
    );
};

Information.propTypes = {
    clickedOutside: PropTypes.bool,
    fields: PropTypes.object,
    goToNextStep: PropTypes.func.isRequired
};

Information.defaultProps = {
    clickedOutside: false,
    fields: {}
};

export default Information;
