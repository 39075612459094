import makeStyles from '@mui/styles/makeStyles';

const burgerLine = (theme) => ({
    backgroundColor: '#1E377A',
    borderRadius: theme.typography.pxToRem(40),
    display: 'block',
    height: theme.typography.pxToRem(2),
    left: theme.typography.pxToRem(10),
    opacity: 1,
    position: 'absolute',
    right: theme.typography.pxToRem(10),
    transition: '0.25s ease-in-out'
});

export default makeStyles((theme) => ({
    burger: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        display: 'block',
        height: theme.typography.pxToRem(40),
        padding: 0,
        position: 'relative',
        transform: 'rotate(0deg)',
        width: theme.typography.pxToRem(42),
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    },
    catcher: {
        width: theme.typography.pxToRem(42),
        height: theme.typography.pxToRem(40),
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: theme.zIndex.drawer + 1
    },
    burgerLine1: ({ active }) => ({
        ...burgerLine(theme),
        top: active
            ? theme.typography.pxToRem(20)
            : theme.typography.pxToRem(12),
        transform: active ? 'rotate(135deg)' : 'rotate(0deg)'
    }),
    burgerLine2: ({ active }) => ({
        ...burgerLine(theme),
        top: theme.typography.pxToRem(19),
        opacity: active ? '0' : '1'
    }),
    burgerLine3: ({ active }) => ({
        ...burgerLine(theme),
        top: active
            ? theme.typography.pxToRem(20)
            : theme.typography.pxToRem(26),
        transform: active ? 'rotate(-135deg)' : 'rotate(0deg)'
    })
}));
