import React from 'react';
import PropTypes from 'prop-types';
import { ActivityEventLine, DocumentIconPicker } from '@/components/common';
import Link from '@/components/common/Link';
import { formatDate, formatTime } from '@/util';
import { openDrawer } from '@/state/actions';
import store from '@/state/store';
import { markNotificationState } from '@/modules/dataWrangler/dataWrangler';
import { getDiscountById } from '@/modules/dataWrangler/discounts';
import { getProductById } from '@/modules/dataWrangler/products';
import { getTagById } from '@/modules/dataWrangler/tags';
import { getTaxById } from '@/modules/dataWrangler/taxes';

import useStyles from './styles';

// Process array of objects into a stripped down version
// that only has required data so is faster to process.
export const dataProcessor = (dataArray) =>
    dataArray.map(
        ({
            notificationId,
            notificationType,
            createdAt,
            parentType,
            parentLabel,
            state,
            parentId,
            data
        }) => ({
            id: notificationId,
            notificationType,
            createdAt,
            paymentMethod: data?.method || '',
            parentType,
            parentLabel,
            state,
            parentId
        })
    );

const openDrawerHandler = async ({ type, id }) => {
    const allowedTypes = ['discount', 'product', 'service', 'tag', 'tax'];

    if (!allowedTypes.includes(type)) return;

    let data = null;
    let title = '';

    if (type === 'discount') {
        data = await getDiscountById(id);
        title = 'Edit Discount';
    } else if (type === 'product' || type === 'service') {
        data = await getProductById(id);

        title = `Edit ${type.replace(/\b\w/g, (l) => l.toUpperCase())}`;
    } else if (type === 'tag') {
        data = await getTagById(id);
        title = 'Edit Tag';
    } else if (type === 'tax') {
        data = await getTaxById(id);
        title = 'Edit Tax';
    }

    if (!data) return;

    store.dispatch(
        openDrawer({
            // Set the key dynamically
            [`${type}`]: data,
            id: type,
            edit: true,
            title,
            updating: true
        })
    );
};

const ItemLink = ({ parentType, parentLabel, parentId }) => {
    const classes = useStyles();

    const drawerType = ['discount', 'product', 'service', 'tag', 'tax'];

    if (drawerType.includes(parentType)) {
        return (
            <span className={classes.lineCenter}>
                <DocumentIconPicker type={parentType} />

                <button
                    type="button"
                    className={classes.simpleButton}
                    onClick={() =>
                        openDrawerHandler({
                            type: parentType,
                            id: parentId
                        })
                    }
                >
                    {parentLabel}
                </button>
            </span>
        );
    }

    let pagelink = `/${parentType}/${parentId}`;
    if (parentType === 'client') {
        pagelink = `/clients/${parentId}`;
    }

    return (
        <span className={classes.lineCenter}>
            <DocumentIconPicker type={parentType} />

            <Link to={pagelink} className={classes.lineLabel}>
                {parentLabel}
            </Link>
        </span>
    );
};

ItemLink.propTypes = {
    parentType: PropTypes.string.isRequired,
    parentLabel: PropTypes.string.isRequired,
    parentId: PropTypes.string.isRequired
};

export const generateComponents = ({ array, locale, classes }) =>
    array.map(
        ({
            notificationType,
            createdAt,
            parentType,
            parentLabel,
            parentId,
            state,
            paymentMethod,
            id
        }) => ({
            id,
            notificationType: (
                <ActivityEventLine
                    activityType={notificationType}
                    parentType={parentType}
                    method={paymentMethod}
                    showDot={state === 'unread'}
                />
            ),
            // user: <span className={classes.line}>John Doe</span>,
            parentType: (
                <ItemLink
                    parentType={parentType}
                    parentLabel={parentLabel}
                    parentId={parentId}
                />
            ),
            createdAt: (
                <span className={classes.lineDate}>
                    <span>
                        {formatDate({
                            isoDate: createdAt,
                            locale
                        })}
                    </span>{' '}
                    <span className={classes.lineDateTime}>
                        {formatTime({
                            isoDate: createdAt,
                            locale
                        })}
                    </span>
                </span>
            ),
            markasread:
                state === 'read' ? (
                    <button
                        type="button"
                        className={classes.simpleButton}
                        onClick={() =>
                            markNotificationState({ id, read: false })
                        }
                    >
                        Mark as unread
                    </button>
                ) : (
                    <button
                        type="button"
                        className={classes.simpleButtonMarkAsRead}
                        onClick={() =>
                            markNotificationState({ id, read: true })
                        }
                    >
                        Mark as read
                    </button>
                )
        })
    );
