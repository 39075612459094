import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { App } from '@capacitor/app';
import { setSentryUser } from '@/util';
import store from '@/state/store';

import {
    getCurrentUser,
    getCurrentUserSession,
    getLoginCodeFromURL,
    getUserEmailJwt
} from '@/modules/loginManager';

import { getBusiness } from '@/modules/dataWrangler/dataWrangler';

import { useCheckAuthState } from './useCheckAuthState';

/**
 * Check Login State - a function used within the useCheckLoginStatus hook, as
 * well as the Capacitor app.appUrlOpen function to check if any social logins
 * have taken place.
 */
const checkLoginStatus = async (navigate, loggedInUser, userIdRef) => {
    const user = getCurrentUser();
    const userSession = await getCurrentUserSession();
    const { temporaryUser } = store.getState().user;

    if (userSession === null && temporaryUser === false) {
        // The users refresh token has expired
        // Remove the user and refresh the page
        window.location.reload();
    }

    // Is the user is logged in (or has just logged in).
    // Refresh the user token, grab the JTW and set it.
    if (loggedInUser) {
        const res = await getUserEmailJwt();
        if (res) {
            setSentryUser(res);

            // If the user ID is new update the userIdRef
            // and rerun with clean business data.
            if (userIdRef.current !== user?.username) {
                // /* eslint-disable no-param-reassign */
                // userIdRef.current = user?.username;
                // resetGetBusiness();
            } else {
                // Otherwise run getBusiness as normal.
                getBusiness();
            }
        }
    }

    // If user logged in with socials, clean up URL.
    if (getLoginCodeFromURL(window.location.search)) {
        const reg = /[?&]state=([^&#]*)/i;
        const state = reg.exec(window.location.search);
        navigate(`${decodeURIComponent(state ? state[1] : '')}`, {
            replace: true
        });
    }
};

/**
 * A React hook that checks to see if the user is logged in. If they are not,
 * it checks to see if a log in code exists in the URL.
 * It also manages token refreshes on route change.
 * @return {func} This returns a function, that is run on mount.
 */
export const useCheckLoginStatus = () => {
    const navigate = useNavigate();
    const userIdRef = useRef(false);
    const { loggedInUser } = useCheckAuthState();

    // Manage app social login flow on native app via Capacitor.
    App.addListener('appUrlOpen', () => {
        checkLoginStatus(navigate, loggedInUser, userIdRef);
    });

    return useEffect(() => {
        checkLoginStatus(navigate, loggedInUser, userIdRef);
    }, [navigate, loggedInUser, userIdRef]);
};
