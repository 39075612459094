import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useCurrency, useLocale } from '@/hooks';
import {
    calculateProductDiscount,
    calculateProductSubtotal,
    calculateProductTaxes,
    formatCurrency,
    toWholeCurrency
} from '@/util';

import useStyles from './styles';

const Totals = ({ item }) => {
    const classes = useStyles();
    const { code } = useCurrency();
    const locale = useLocale();

    const hasTaxes = item.taxes?.length > 0;
    const hasMultipleTaxes = item.taxes?.length > 1;
    const hasDiscounts = item.discounts?.length > 0;
    const hasMultipleDiscounts = item.discounts?.length > 1;

    const discounts = calculateProductDiscount(item);
    const taxes = calculateProductTaxes(item);
    const itemTotal = calculateProductSubtotal(item);
    const total = itemTotal - discounts + taxes;

    return (
        <div className={classes.totals}>
            <Typography className={classes.total}>
                Item Total
                <span>
                    {formatCurrency({
                        amount: toWholeCurrency(total),
                        currency: code,
                        locale
                    })}
                </span>
            </Typography>
            {hasTaxes && (
                <Typography
                    className={classes.taxDiscount}
                    component="p"
                    variant="h4"
                >
                    {hasMultipleTaxes ? 'Taxes' : 'Tax'}
                    <span>
                        {formatCurrency({
                            amount: toWholeCurrency(taxes),
                            currency: code,
                            locale
                        })}
                    </span>
                </Typography>
            )}
            {hasDiscounts && (
                <Typography
                    className={classes.taxDiscount}
                    component="p"
                    variant="h4"
                >
                    {hasMultipleDiscounts ? 'Discounts' : 'Discount'}
                    <span>
                        -
                        {formatCurrency({
                            amount: toWholeCurrency(discounts),
                            currency: code,
                            locale
                        })}
                    </span>
                </Typography>
            )}
        </div>
    );
};

Totals.propTypes = {
    item: PropTypes.object.isRequired
};

export default Totals;
