import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom-v5-compat';
import { setTemporaryStorage, updatedocumentIds } from '@/state/actions';
import {
    createRecurringInvoice,
    updateRecurringInvoice
} from '@/modules/dataWrangler/dataWrangler';
import store from '@/state/store';

/**
 * Recurring Invoice Hook
 * Saves an invoice, where data is passed into the hook.
 * Handles redirection logic if recurring is disabled.
 *
 * Returns false, or clean recurring invoice data.
 */
export const useSaveRecurringInvoice = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const saveRecurringInvoice = async (invoiceData) => {
        const documentId = invoiceData?.documentId || null;

        const subscriptionData =
            store.getState()?.user?.activeBusiness?.subscription;

        const blockInvoiceCreation =
            !subscriptionData?.components?.recurring?.enabled;

        const tagIds = invoiceData?.tags?.map((tag) => tag.tagId);

        const saveTempDataAndRedirectToSubscription = () => {
            // Remove product ids to allow easy re-insertion into
            // form. Otherwise product values are not injected.
            const cleanTempData = {
                ...invoiceData,
                products: invoiceData.products.map((prod) => ({
                    ...prod,
                    productId: ''
                }))
            };

            dispatch(
                setTemporaryStorage({
                    component: 'recurring-invoice',
                    data: cleanTempData
                })
            );

            navigate('/settings/subscription');
        };

        if (blockInvoiceCreation) {
            saveTempDataAndRedirectToSubscription();
            return false;
        }

        if (documentId === null) {
            // If the user has no more documents remaining, save current invoice object
            // in Redux and force them to subscription page.
            if (blockInvoiceCreation) {
                saveTempDataAndRedirectToSubscription();
                return false;
            }

            const newInvoiceObject = await createRecurringInvoice({
                ...invoiceData,
                tagIds
            });

            if (newInvoiceObject?.documentId) {
                dispatch(
                    updatedocumentIds({
                        referenceNumber: newInvoiceObject.referenceNumber,
                        documentId: newInvoiceObject.documentId,
                        customLabel: newInvoiceObject.customLabel
                    })
                );
            }

            return newInvoiceObject;
        }

        await updateRecurringInvoice({ ...invoiceData, tagIds });

        return invoiceData;
    };

    return saveRecurringInvoice;
};
