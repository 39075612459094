import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    span: ({ checked }) => ({
        display: 'flex',
        alignItems: 'center',
        color: checked ? '#4287CA' : '#475569',
        backgroundColor: checked ? '#E1ECF7' : '#fff',
        borderRadius: theme.typography.pxToRem(4),
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 300,
        padding: `8px 12px`,
        textAlign: 'center',
        margin: 2,
        border: checked ? `1px solid #E1ECF7` : `1px solid #E0E5EE`
    }),
    svgWrap: {
        marginRight: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));
