import React from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDrawers } from '@/hooks';
import { DISCOUNT_BASE_PARAM } from '@/constants';
import { FilterForm, Percent, Price, Type, Tags } from '../formComponents';

const DiscountFilterDrawer = () => {
    const { isDrawerActive } = useDrawers();
    const drawerOpen = isDrawerActive('discountFilter');

    const defaultValues = {
        type: 'percent',
        value: {
            min: '',
            max: ''
        },
        tags: []
    };

    const formMethods = useForm({
        nativeValidation: false,
        criteriaMode: 'all'
    });

    const { watch } = formMethods;

    const discountType = watch('type');

    return (
        <FilterForm
            defaultValues={defaultValues}
            formMethods={formMethods}
            id="FilterDiscounts"
            open={drawerOpen}
            paramBase={DISCOUNT_BASE_PARAM}
            title="Discounts"
            type="discount"
        >
            <Grid item>
                <Type />
                {discountType !== 'flat' && (
                    <Percent heading="Percent" id="value" />
                )}
                {discountType === 'flat' && (
                    <Price
                        heading="Flat Rate"
                        id="value"
                        minTitle="Min"
                        maxTitle="Max"
                    />
                )}
                <Tags heading="Tags" id="tags" />
            </Grid>
        </FilterForm>
    );
};

export default DiscountFilterDrawer;
