import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    TextField,
    InputAdornment,
    Typography,
    Grid
} from '@mui/material';
import { DeleteIcon } from '@/resources/icons';
import { forcePositiveNumberInput, toWholeCurrency } from '@/util';
import { AutocompleteTextField } from '@/components/common';
import DiscountValueModifier from '../DiscountValueModifier';
import makeStyles from './styles';

const InputBar = React.memo(
    ({
        label,
        nameError,
        selectedItems,
        clearErrors,
        allItems,
        removeItem,
        itemType,
        updateValue,
        inputItem
    }) => {
        const classes = makeStyles();

        const handleItemSelect = (item) => {
            updateValue(
                item.name,
                item.type,
                toWholeCurrency(item.value),
                inputItem[`${itemType}Id`]
            );
        };

        // Remove item when item has been added to the product.
        const itemsToList = allItems.filter(
            (listItems) =>
                !selectedItems.some(
                    (selItems) =>
                        selItems[`${itemType}Id`] === listItems[`${itemType}Id`]
                )
        );

        return (
            <Grid item container spacing={2} className={classes.inputBar}>
                <Grid item container spacing={1}>
                    <Grid
                        item
                        md={6}
                        sm={12}
                        xs={12}
                        className={classes.nameField}
                    >
                        <AutocompleteTextField
                            id={`${
                                inputItem[`${itemType}Id`]
                            }-autocomplete-name`}
                            options={itemsToList}
                            onSelect={handleItemSelect}
                            onUniqueValueEntered={() => {}}
                            onClear={() => {
                                updateValue(
                                    '',
                                    inputItem?.type,
                                    inputItem?.value,
                                    inputItem[`${itemType}Id`]
                                );
                            }}
                            inputValue={inputItem?.name}
                            label={label}
                            optionLabels={['name']}
                            error={Boolean(nameError)}
                            helperText={nameError}
                            onTextFieldChange={(e) => {
                                clearErrors();
                                updateValue(
                                    e.target.value,
                                    inputItem?.type,
                                    toWholeCurrency(inputItem?.value),
                                    inputItem[`${itemType}Id`]
                                );
                            }}
                        />
                    </Grid>
                    {itemType === 'discount' ? (
                        <DiscountValueModifier
                            title="Amount"
                            id={`${inputItem[`${itemType}Id`]}-value-modifer`}
                            type={inputItem?.type}
                            value={
                                typeof inputItem?.value === 'string'
                                    ? ''
                                    : toWholeCurrency(inputItem?.value)
                            }
                            showAdornment
                            onUpdate={({ value: newValue, type: newType }) => {
                                updateValue(
                                    inputItem?.name,
                                    newType,
                                    newValue,
                                    inputItem[`${itemType}Id`]
                                );
                            }}
                        />
                    ) : (
                        <Grid
                            item
                            md={5}
                            sm={10}
                            xs={10}
                            className={classes.valueField}
                        >
                            {/* <Grid item md={5} sm={10} xs={xxs ? 10 : 4}> */}
                            <TextField
                                fullWidth
                                name="value"
                                id={`${inputItem[`${itemType}Id`]}-value`}
                                label="Amount"
                                value={
                                    typeof inputItem?.value === 'string'
                                        ? ''
                                        : toWholeCurrency(inputItem?.value) ||
                                          ''
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Typography>%</Typography>
                                        </InputAdornment>
                                    )
                                }}
                                // eslint-disable-next-line react/jsx-no-duplicate-props
                                inputProps={{
                                    type: 'number',
                                    step: '0.01',
                                    min: '0'
                                }}
                                onChange={(e) => {
                                    updateValue(
                                        inputItem?.name,
                                        inputItem?.type,
                                        parseFloat(e.target.value),
                                        inputItem[`${itemType}Id`]
                                    );
                                }}
                                onKeyDown={forcePositiveNumberInput}
                                placeholder="0.00"
                            />
                        </Grid>
                    )}
                    <Grid
                        item
                        md={1}
                        sm={2}
                        xs={2}
                        className={classes.buttonWrap}
                    >
                        <Button
                            type="button"
                            variant="text"
                            id={`${inputItem[`${itemType}Id`]}-delete`}
                            fullWidth
                            onClick={() => {
                                removeItem(inputItem[`${itemType}Id`]);
                            }}
                            className={classes.deleteButton}
                        >
                            <DeleteIcon />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
);

InputBar.propTypes = {
    label: PropTypes.string.isRequired,
    clearErrors: PropTypes.func.isRequired,
    nameError: PropTypes.string,
    selectedItems: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
                .isRequired,
            name: PropTypes.string.isRequired
        })
    ),
    allItems: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
                .isRequired,
            name: PropTypes.string.isRequired
        })
    ),
    removeItem: PropTypes.func.isRequired,
    itemType: PropTypes.oneOf(['discount', 'tax']).isRequired,
    updateValue: PropTypes.func.isRequired,
    inputItem: PropTypes.object.isRequired
};

InputBar.defaultProps = {
    nameError: '',
    selectedItems: [],
    allItems: []
};

export default InputBar;
