import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom-v5-compat';
import { Grid, Button } from '@mui/material';
import { useSubscription } from '@/hooks';
import styles from './styles';

const UpdateBillingInfoButton = ({ autoRenew }) => {
    const classes = styles();
    const { userSubscriptionState, managementURL } = useSubscription();

    return (
        <Grid item className={classes.buttonContainer}>
            <Button
                fullWidth
                component={Link}
                variant="outlined"
                className={classes.updateInfoButton}
                data-testid="update-button"
                to={
                    managementURL || {
                        pathname: '/update'
                    }
                }
                state={{
                    userCanUpdate: Boolean(
                        userSubscriptionState === 'active' ||
                            userSubscriptionState === 'trialing'
                    ),
                    autoRenew,
                    userSubscriptionState
                }}
            >
                {managementURL
                    ? 'Manage Subscription'
                    : 'Edit Payment Information'}
            </Button>
        </Grid>
    );
};

UpdateBillingInfoButton.propTypes = {
    autoRenew: PropTypes.bool
};

UpdateBillingInfoButton.defaultProps = {
    autoRenew: false
};

export default UpdateBillingInfoButton;
