import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, TextField, Typography } from '@mui/material';
import useStyles from './styles';

/**
 * The DisplayTextArea read-only text area or button used to display a multiline string.
 *
 * @param {function} onClick callback to run when the textarea is clicked
 * @param {string/object} label MaterialUI TextField label displayed in textarea above content
 * @param {string/object} text MaterialUI TextField value, displayed in textarea
 * @param {bool} locked disables editing and change styles to appear uninteractable
 * @param {string/object} lockedLabel left sided label displayed in locked mode
 * @param {string/object} error MaterialUI TextField helperText and error. Displays underneath if passed.
 * @param {*} otherProps Any inexplicitly declared props are spread unto the Material UI TextField
 */

const DisplayTextArea = ({
    onClick,
    label,
    text,
    locked,
    lockedLabel,
    error,
    ...otherProps
}) => {
    const classes = useStyles(locked);
    const buttonRef = useRef(null);

    const handleOnClick = () => {
        onClick();
        buttonRef.current.focus();
    };

    return (
        <div className={classes.displayTextAreaContainer}>
            {locked && (
                <Typography className={classes.displayTextOuterLabel}>
                    {lockedLabel}
                </Typography>
            )}
            <ButtonBase
                className={classes.button}
                onClick={locked ? undefined : handleOnClick}
                ref={buttonRef}
            >
                <TextField
                    multiline
                    InputProps={{
                        readOnly: true,
                        classes: {
                            root: locked
                                ? classes.lockedBillField
                                : classes.billField,
                            input: `${classes.innerInput} ${
                                !locked ? classes.billField : ''
                            }`
                        }
                    }}
                    InputLabelProps={{
                        shrink: Boolean(text)
                    }}
                    // eslint-disable-next-line
                    inputProps={{
                        classes: locked
                            ? null
                            : {
                                  root: classes.billField
                              }
                    }}
                    variant="filled"
                    fullWidth
                    value={text}
                    label={locked ? '' : label}
                    error={Boolean(error)}
                    helperText={error}
                    // eslint-disable-next-line
                    {...otherProps}
                />
            </ButtonBase>
        </div>
    );
};

DisplayTextArea.propTypes = {
    text: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onClick: PropTypes.func,
    locked: PropTypes.bool,
    lockedLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    error: PropTypes.string
};

DisplayTextArea.defaultProps = {
    text: '',
    label: '',
    onClick: () => {},
    locked: false,
    lockedLabel: '',
    error: ''
};

export default DisplayTextArea;
