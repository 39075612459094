import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { App } from '@capacitor/app';
import { useNavigate } from 'react-router-dom-v5-compat';
import { ButtonLoading } from '@/components/common';
import { createThirdPartyConnection } from '@/modules/dataWrangler/connections';
import { useSubscription } from '@/hooks';
import { LinkIconHorizontal, ExternalLinkGray } from '@/resources/icons';
import useStyles from './styles';

const ConnectButton = ({ active, disconnectPending, merchant, openDrawer }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { productId } = useSubscription();
    const [buttonLoading, setButtonLoading] = useState(false);
    const listener = useRef();

    const isPaidUser = productId !== 'free';

    /**
     * Fires when app/website moves in/out of focus.
     * Either swapping between tabs web page, or the app
     * is navigated to on a device. This is needed to stop button loading
     * on ios page redirect or browsers that don't reset state on return (i.e. Safari)
     */
    useEffect(() => {
        const runAppEventListener = async () => {
            listener.current = await App.addListener(
                'appStateChange',
                ({ isActive }) => {
                    if (isActive) {
                        setButtonLoading(false);
                    }
                }
            );
        };

        runAppEventListener();

        return () => {
            if (listener.current?.remove) {
                listener.current.remove();
            }
        };
    }, [setButtonLoading, listener]);

    const handleConnectQRMerchant = async (e) => {
        e.stopPropagation();

        if (isPaidUser) {
            openDrawer();
        } else {
            navigate('/settings/subscription');
        }
    };

    const handleRegisterMerchant = async (e) => {
        e.stopPropagation();

        if (
            isPaidUser &&
            (merchant.slug !== 'cashapp' || merchant.slug !== 'venmo')
        ) {
            setButtonLoading(true);
            window.location = await createThirdPartyConnection(merchant.slug);
        } else {
            navigate('/settings/subscription');
        }
    };

    const getConnectButton = () => {
        if (
            merchant.slug === 'venmo' ||
            merchant.slug === 'cashapp' ||
            merchant.slug === 'zelle'
        ) {
            if (active) {
                // QR merchant connected
                return <div />;
            }
            // QR merchant not connected
            return (
                <Button
                    variant="contained"
                    color="primary"
                    disabled={disconnectPending}
                    onClick={(e) => handleConnectQRMerchant(e)}
                    endIcon={
                        <LinkIconHorizontal className={classes.buttonIcon} />
                    }
                >
                    Connect
                </Button>
            );
        }

        if (active) {
            return (
                <Button
                    variant="outlined"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    component="a"
                    href={merchant?.dashboardURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    endIcon={
                        <ExternalLinkGray className={classes.buttonIcon} />
                    }
                    className={classes.outerAccordionButton}
                >
                    Manage Your Account
                </Button>
            );
        }

        return (
            <ButtonLoading
                variant="contained"
                color="primary"
                loading={buttonLoading}
                disableOnLoading
                onClick={(e) => handleRegisterMerchant(e)}
                endIcon={
                    buttonLoading ? (
                        <div className={classes.buttonIcon} />
                    ) : (
                        <LinkIconHorizontal className={classes.buttonIcon} />
                    )
                }
            >
                Connect
            </ButtonLoading>
        );
    };

    return getConnectButton();
};

ConnectButton.propTypes = {
    active: PropTypes.bool,
    merchant: PropTypes.object.isRequired,
    openDrawer: PropTypes.func,
    disconnectPending: PropTypes.bool
};

ConnectButton.defaultProps = {
    active: false,
    openDrawer: () => {},
    disconnectPending: false
};

export default ConnectButton;
