import { hotjar } from 'react-hotjar';

/**
 * Function to set up Hotjar.
 * If the testing arg is set, or app is in production
 * Hotjar will run.
 * To use on a development env pass in true.
 * @param  {bool} testing Set to true, to run on a development environment.
 */
export const initHotjar = (testing) => {
    if (process.env.REACT_APP_AMPLIFY_ENV === 'prod' || testing) {
        hotjar.initialize(2063704, 6);
    }
};

/**
 * This takes a user data and passes it the Hotjar
 * function. If the Hotjar function has not loaded
 * nothing happens.
 *
 * This allows more fine tuned tracking of users.
 *
 * To add more user attributes, add them within the object
 * and Hotjar will pick them up.
 * @param {string} email The users email address.
 */

// Set as global state so it can be built on as more data comes avalible.
const hotjarUserData = {
    email: null,
    sessionId: ''
};

export const setHotjarUser = ({ email, sessionId }) => {
    if (email) {
        hotjarUserData.email = email;
    }
    if (sessionId) {
        hotjarUserData.sessionId = sessionId;
    }

    const hotjarAttrs = { ...hotjarUserData };
    delete hotjarAttrs.email;

    if (window?.hj) {
        window.hj('identify', hotjarUserData.email, hotjarAttrs);
    }
};

/**
 * This takes a page name and passes it the Hotjar
 * function. If the Hotjar function has not loaded
 * nothing happens.
 *
 * This allows custom tracking of pages.
 * @param {string} page The current page name.
 */
export const setHotjarPage = ({ page }) => {
    if (window?.hj && page) {
        window.hj('trigger', page);
    }
};
