import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    container: {
        display: 'flex',
        position: 'sticky',
        bottom: 0,
        left: 0,
        right: 0,
        padding: `${theme.typography.pxToRem(20)}`,
        [theme.breakpoints.only('xs')]: {
            padding: `${theme.typography.pxToRem(10)} 
            ${theme.typography.pxToRem(10)} 
            calc(env(safe-area-inset-bottom) + ${theme.typography.pxToRem(10)})`
        },
        boxShadow: '0px -5px 5px -5px #DEDEE7',
        margin: `${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(
            -15
        )} ${theme.typography.pxToRem(-60)}`,
        zIndex: 999,
        backgroundColor: theme.palette.common.white
    },
    button: {
        padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
            20
        )}`,
        '&:disabled': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main
        }
    }
}));
