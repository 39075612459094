import { CROP_LOGO, TOGGLE_CROPPER_MODAL } from '@/constants';

const defaultState = { open: false };
export default (state = defaultState, action) => {
    switch (action.type) {
        case CROP_LOGO:
            return { ...state, logo: action.logo };
        case TOGGLE_CROPPER_MODAL: {
            return { ...state, open: action.open };
        }
        default:
            return { ...state };
    }
};
