import React from 'react';
import {
    formatCurrency,
    formatDate,
    toWholeCurrency,
    getFrequencyWording
} from '@/util';

import {
    MobileItemTags,
    TableActionsWrap,
    StatusPill,
    TagsIcon
} from '@/components/common';

// Process array of objects into a stripped down version
// that only has required data so is faster to process.
export const dataProcessor = (data) =>
    data.map(
        ({
            documentId,
            documentState,
            recurringInterval,
            recurringIntervalUnit,
            to,
            nextSendDate,
            totals,
            amountSent,
            currency,
            tags
        }) => ({
            client: to?.name || '',
            currency,
            id: documentId,
            frequency: getFrequencyWording({
                interval: recurringInterval,
                unit: recurringIntervalUnit
            }),
            nextSendDate,
            total: totals?.total || 0,
            amountSent,
            state: documentState,
            tags
        })
    );

export const generateComponents = ({
    array,
    mobile,
    locale,
    timezone,
    globalCurrency
}) =>
    array.map(
        ({
            currency,
            id,
            nextSendDate,
            frequency,
            total,
            amountSent,
            state,
            tags,
            client
        }) => {
            const thisCurrency =
                currency && state !== 'draft' ? currency : globalCurrency;

            return {
                id,
                client,
                frequency,
                nextSendDate: nextSendDate
                    ? formatDate({
                          isoDate: nextSendDate,
                          locale,
                          timezone
                      })
                    : '',
                total: formatCurrency({
                    currency: thisCurrency,
                    amount: toWholeCurrency(total),
                    locale
                }),
                // Balance Due - not due date.
                amountSent,
                state: <StatusPill padding={mobile} status={state} />,
                tags: mobile ? (
                    <MobileItemTags
                        type="recurring-invoice"
                        itemId={id}
                        tags={tags}
                    />
                ) : (
                    <TagsIcon
                        type="recurring-invoice"
                        itemId={id}
                        tags={tags}
                    />
                ),
                actions: (
                    <TableActionsWrap
                        id={id}
                        open={
                            state === 'draft'
                                ? 'editRecurringInvoice'
                                : 'viewRecurringInvoice'
                        }
                        rest={['duplicateRecurringInvoice']}
                    />
                ),
                primaryLink: `/recurring-invoice/${id}`
            };
        }
    );
