import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom-v5-compat';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';

import { useLocale, useUrlParams, useListingState } from '@/hooks';
import { getActivityConstant } from '@/util';

import { ACTIVITY_BASE_PARAM } from '@/constants';
import { TableMaker, TableSkeleton, Pagination } from '@/components/common';
import { getActivities } from '@/modules/dataWrangler/activities';

import { ActivityIcon, ChevronGrey } from '@/resources/icons';
import { dataProcessor, generateComponents } from './dataProcessors';
import tableHead from './tableHead';

import useStyles from './styles';

const ActivityAccordion = ({ parentType }) => {
    const params = useParams();

    const documentId = params?.documentId || null;

    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [expanded, setExpanded] = useState(false);

    const locale = useLocale();

    const { getUrlParams } = useUrlParams();

    const { hasData, items, loadingPaper, totalPages, zeroResultsReturned } =
        useListingState(getActivityConstant(parentType));

    const currentUrlParams = getUrlParams({ base: ACTIVITY_BASE_PARAM });

    const currentParentType = currentUrlParams?.parentType || parentType;

    // Hard code parentId into params.
    const stringifiedParams = JSON.stringify({
        ...currentUrlParams,
        parentId: documentId,
        parentType: currentParentType
    });

    // const setOrder = (property) => {
    //     const sortOrder =
    //         currentUrlParams?.sort?.type === property &&
    //         currentUrlParams?.sort?.order === 'asc'
    //             ? 'desc'
    //             : 'asc';

    //     const newUrlParams = {
    //         ...currentUrlParams,
    //         sort: {
    //             type: property,
    //             order: sortOrder
    //         }
    //     };

    //     setUrlParams({ base: ACTIVITY_BASE_PARAM, params: newUrlParams });
    // };

    useEffect(() => {
        getActivities(JSON.parse(stringifiedParams));
    }, [stringifiedParams]);

    if (loadingPaper) {
        return null;
    }

    return (
        <Accordion
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
            className={classes.accordionContainer}
            elevation={0}
        >
            <AccordionSummary
                expandIcon={<ChevronGrey className={classes.expandIcon} />}
            >
                <Grid container item alignItems="center">
                    <Grid
                        item
                        container
                        xs={12}
                        direction="row"
                        rowSpacing={1}
                        justifyContent="space-between"
                    >
                        <Grid
                            container
                            item
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            md="auto"
                        >
                            <ActivityIcon className={classes.iconContainer} />
                            <Typography
                                component="h5"
                                className={classes.accordionTitle}
                            >
                                Activity
                            </Typography>

                            <Grid item>
                                <span className={classes.activityCount}>
                                    {items?.length} Event
                                    {items?.length > 1 ? 's' : ''}
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetail}>
                {!loadingPaper && (
                    <>
                        <TableMaker
                            actionCols={[]}
                            ariaTableName="Activity Table"
                            handleRequestSort={() => {}}
                            headCells={tableHead}
                            rowData={
                                hasData && !zeroResultsReturned
                                    ? generateComponents({
                                          array: dataProcessor(items),
                                          locale,
                                          classes
                                      })
                                    : []
                            }
                            order={currentUrlParams?.sort?.order || 'asc'}
                            orderBy={currentUrlParams?.sort?.type || ''}
                        />
                        <Pagination
                            base={ACTIVITY_BASE_PARAM}
                            totalPages={totalPages}
                        />
                    </>
                )}
                {loadingPaper && <TableSkeleton isMobile={isMobile} />}
            </AccordionDetails>
        </Accordion>
    );
};

export default ActivityAccordion;

ActivityAccordion.propTypes = {
    parentType: PropTypes.string
};

ActivityAccordion.defaultProps = {
    parentType: undefined
};
