/* eslint import/no-cycle: "off"  */
import store from '@/state/store';
import {
    addConnection,
    removeConnection,
    setAllConnections
} from '@/state/actions';

import axiosAuthenticated from '../authenticatedRequestor';
import * as segmentManager from '../segment/segmentManager';

export const createThirdPartyConnection = async (application) => {
    const { businessId } = store.getState().user.activeBusiness;

    const response = await axiosAuthenticated.post(
        `/businesses/${businessId}/connections/oauth/authorize`,
        {
            application
        }
    );

    await segmentManager.paymentMethodConnected(application);

    return response.data.link;
};

export const getAllThirdPartyConnections = async (businessId) => {
    const response = await axiosAuthenticated.get(
        `/businesses/${businessId}/connections`
    );

    store.dispatch(setAllConnections(response.data));
};

export const removeThirdPartyConnection = async (connectionToRemove) => {
    const { businessId } = store.getState().user?.activeBusiness;

    try {
        const response = await axiosAuthenticated.post(
            `/businesses/${businessId}/connections/oauth/deauthorize`,
            connectionToRemove
        );

        store.dispatch(removeConnection(connectionToRemove.application));

        return response;
    } catch (err) {
        return '';
    }
};

export const createQRCodeConnection = async (application, qrCode, link) => {
    const { businessId } = store.getState().user.activeBusiness;

    const response = await axiosAuthenticated.put(
        `/businesses/${businessId}/connections/qrcode`,
        {
            application,
            qrCode,
            qrCodeData: link
        }
    );

    await segmentManager.paymentMethodConnected(application);
    store.dispatch(addConnection(response.data));
};

export const removeQRCodeConnection = async (application) => {
    const { businessId } = store.getState().user.activeBusiness;

    await axiosAuthenticated.delete(
        `/businesses/${businessId}/connections/qrcode/${application}`
    );

    store.dispatch(removeConnection(application));
};

export const createQrAndTextConnection = async (textData, qrData) => {
    const { businessId } = store.getState().user.activeBusiness;

    const setTextConnection = async (application, text) => {
        const res = await axiosAuthenticated.put(
            `/businesses/${businessId}/connections/text`,
            {
                application,
                text
            }
        );

        await segmentManager.paymentMethodConnected(application);

        return res;
    };

    const setQrConnection = async (application, qrCode, link) => {
        const res = await axiosAuthenticated.put(
            `/businesses/${businessId}/connections/qrcode`,
            {
                application,
                qrCode,
                qrCodeData: link
            }
        );
        await segmentManager.paymentMethodConnected(application);

        return res;
    };

    if (qrData) {
        const { application, imageString, userMerchantLink } = qrData;

        await setQrConnection(
            qrData?.application === 'zelle' ? 'zelleQR' : application,
            imageString,
            userMerchantLink
        );
    }

    if (textData.emailAddress) {
        await setTextConnection('zelleEmail', textData.emailAddress);
    }

    if (textData.phoneNumber) {
        await setTextConnection('zellePhone', textData.phoneNumber);
    }

    const response = await axiosAuthenticated.get(
        `/businesses/${businessId}/connections`
    );

    store.dispatch(setAllConnections(response.data));

    return response.data.find((connection) => {
        if (!qrData) {
            return textData.phoneNumber
                ? connection?.application === 'zellePhone'
                : connection?.application === 'zelleEmail';
        }

        return qrData?.application === 'zelle'
            ? connection?.application === 'zelleQR'
            : connection?.application === qrData?.application;
    });
};

export const removeAllZelleConnections = async () => {
    const { businessId, connections } = store.getState().user.activeBusiness;
    const callsToMake = [];

    const removeZelleConnection = async (application) =>
        axiosAuthenticated.delete(
            `/businesses/${businessId}/connections/qrcode/${application}`
        );

    connections.forEach((c) => {
        if (/zelle/.test(c.application)) {
            callsToMake.push(removeZelleConnection(c.application));
        }
    });

    await Promise.all(callsToMake);

    const response = await axiosAuthenticated.get(
        `/businesses/${businessId}/connections`
    );

    store.dispatch(setAllConnections(response.data));
};
