import { VALUE_FLAT_RATE_TYPE } from '@/constants';

import { differenceInCalendarDays, differenceInCalendarMonths } from 'date-fns';

import {
    calculateProductDiscount,
    calculateProductTaxes,
    calculateProductSubtotal
} from './invoiceHelpers';

import { trimFloat } from './currency';

// Process array of objects into a stripped down version
// that only has required data so is faster to process.
export const createSaveableObject = ({
    type,
    currentObject,
    activeBusiness
}) => {
    const businessProfile = activeBusiness?.businessProfile || {};
    const currency = activeBusiness?.businessSettings?.currency || null;
    const design = activeBusiness?.documentCustomizations?.design || null;
    const disablePaymentStamp =
        activeBusiness?.documentCustomizations?.disablePaymentStamp || false;
    const logo = activeBusiness?.logo || '';
    const userName = activeBusiness?.name || '';

    const documentState = currentObject?.documentState || 'draft';
    const documentId = currentObject?.documentId || null;
    const referenceNumber = currentObject?.referenceNumber || null;

    const customLabel = currentObject?.customLabel || '';

    const dueDate = currentObject?.dueDate || '';
    const issuedDate = currentObject?.issuedDate || '';

    const products = currentObject?.products || [];
    const totals = currentObject?.totals || [];
    const tags = currentObject?.tags || [];

    const to = currentObject?.to || {};
    const details = currentObject?.details || '';
    const from = { ...businessProfile, name: userName };
    const reminders = currentObject?.reminders || {};

    const cleanTotals = {
        discount: trimFloat(totals?.discount) || 0,
        shipping: totals?.shipping || 0,
        subtotal: totals?.subtotal || 0,
        tax: trimFloat(totals?.tax) || 0,
        total: trimFloat(totals?.total) || 0
    };

    const productsWithTaxAndDiscountData = products.map((item) => {
        const {
            discounts,
            lineId,
            name,
            price,
            product,
            productId,
            quantity,
            taxes
        } = item;

        const description = item?.description || product?.description || '';

        const totalDiscount = calculateProductDiscount(item);
        const totalTax = calculateProductTaxes(item);
        const total =
            calculateProductSubtotal(item) -
            totalDiscount +
            trimFloat(totalTax);

        return {
            discounts: discounts.map((discount) => ({
                ...discount,
                value:
                    discount.type === VALUE_FLAT_RATE_TYPE
                        ? discount.value
                        : discount.value
            })),
            taxes: taxes.map((tax) => ({
                name: tax.name,
                type: tax.type,
                value: tax.value,
                taxId: tax.taxId
            })),
            lineId,
            price,
            quantity,
            productId,
            name,
            description,
            total: trimFloat(total),
            totalTax: trimFloat(totalTax),
            totalDiscount: trimFloat(totalDiscount)
        };
    });

    const objectToReturn = {
        currency,
        design,
        disablePaymentStamp,
        documentId,
        details,
        from,
        logo,
        products: productsWithTaxAndDiscountData,
        reminders,
        tags,
        to,
        totals: cleanTotals
    };

    if (type !== 'recurring-invoice') {
        objectToReturn.documentState = documentState;
        objectToReturn.documentType = currentObject?.documentType || null;
        objectToReturn.customLabel = customLabel;
        objectToReturn.link = currentObject?.link || null;
        objectToReturn.type = type || 'invoice';
        objectToReturn.referenceNumber = referenceNumber;
        objectToReturn.dueDate = dueDate;
        objectToReturn.issuedDate = issuedDate;
    }

    if (type === 'recurring-invoice') {
        objectToReturn.state = documentState;

        // Get date from firstSendDate, with current time.
        const firstSendDate = new Date(currentObject?.firstSendDate);
        const currentDate = new Date();
        const firstSendDateWithTime = new Date(
            firstSendDate.getFullYear(),
            firstSendDate.getMonth(),
            firstSendDate.getDate(),
            currentDate.getHours(),
            currentDate.getMinutes(),
            currentDate.getSeconds()
        );

        objectToReturn.firstSendDate = firstSendDateWithTime?.toISOString();
        objectToReturn.lastSendDate = currentObject?.lastSendDate;
        objectToReturn.recurringInterval = parseInt(
            currentObject?.recurringInterval,
            10
        );
        objectToReturn.recurringIntervalUnit =
            currentObject?.recurringIntervalUnit;
        objectToReturn.termInterval = parseInt(currentObject?.termInterval, 10);
        objectToReturn.termIntervalUnit = currentObject?.termIntervalUnit;
        objectToReturn.documentType = 'recurring-invoice';

        // Handle repeat field here.
        if (!currentObject?.lastSendDate) {
            objectToReturn.repeats = null;
        } else {
            if (currentObject?.recurringIntervalUnit === 'day') {
                const daysBetween = differenceInCalendarDays(
                    new Date(currentObject?.lastSendDate),
                    new Date(currentObject?.firstSendDate)
                );

                objectToReturn.repeats =
                    daysBetween / objectToReturn.recurringInterval;
            }

            if (currentObject?.recurringIntervalUnit === 'month') {
                const monthsBetween = differenceInCalendarMonths(
                    new Date(currentObject?.lastSendDate),
                    new Date(currentObject?.firstSendDate)
                );

                objectToReturn.repeats =
                    monthsBetween / objectToReturn.recurringInterval;
            }

            if (objectToReturn.repeats) {
                // Round down to nearest whole number.
                objectToReturn.repeats = Math.floor(objectToReturn.repeats);
            }

            // Do not allow repeats to be less than 1.
            if (objectToReturn.repeats && objectToReturn.repeats < 1) {
                objectToReturn.repeats = 1;
            }
        }
    }

    return objectToReturn;
};
