import React from 'react';
import { Grid, Paper, Skeleton } from '@mui/material';
import useStyles from './styles';

const DocumentSkeleton = () => {
    const classes = useStyles();
    return (
        <Grid item xs={12}>
            <Paper
                className={classes.loaderDocument}
                elevation={0}
                variant="outlined"
            >
                <Skeleton
                    className={classes.loaderDocumentSkeleton}
                    width="250px"
                    height="60px"
                    variant="rectangular"
                    data-testid="title-skeleton"
                />
                <Skeleton
                    className={classes.loaderDocumentSkeleton}
                    width="250px"
                    height="60px"
                    variant="rectangular"
                    data-testid="listing-skeleton"
                />
                <Skeleton
                    className={classes.loaderDocumentSkeleton}
                    width="250px"
                    height="60px"
                    variant="rectangular"
                    data-testid="listing-skeleton"
                />
                <Skeleton
                    className={classes.loaderDocumentSkeleton}
                    width="100%"
                    height="250px"
                    variant="rectangular"
                    data-testid="listing-skeleton"
                />
                <Skeleton
                    className={classes.loaderDocumentTotalSkeleton}
                    height="150px"
                    variant="rectangular"
                    data-testid="listing-skeleton"
                />
            </Paper>
        </Grid>
    );
};

export default DocumentSkeleton;
