import {
    ACTIVITY_ALL,
    ACTIVITY_CLIENT,
    ACTIVITY_DISCOUNT,
    ACTIVITY_ESTIMATE,
    ACTIVITY_INVOICE,
    ACTIVITY_PRODUCT,
    ACTIVITY_RECEIPT,
    ACTIVITY_RECURRING_INVOICE,
    ACTIVITY_TAG,
    ACTIVITY_TAX
} from '@/constants';

export const getActivityConstant = (type) => {
    switch (type) {
        case 'client':
            return ACTIVITY_CLIENT;
        case 'discount':
            return ACTIVITY_DISCOUNT;
        case 'estimate':
            return ACTIVITY_ESTIMATE;
        case 'invoice':
            return ACTIVITY_INVOICE;
        case 'product':
            return ACTIVITY_PRODUCT;
        case 'receipt':
            return ACTIVITY_RECEIPT;
        case 'recurring-invoice':
            return ACTIVITY_RECURRING_INVOICE;
        case 'tag':
            return ACTIVITY_TAG;
        case 'tax':
            return ACTIVITY_TAX;
        default:
            return ACTIVITY_ALL;
    }
};
