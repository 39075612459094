/* eslint import/no-cycle: "off"  */
/* eslint no-undef: "off" */
import { Purchases } from '@awesome-cordova-plugins/purchases';
import { Capacitor } from '@capacitor/core';
import store from '@/state/store';
import { updateSubscription as updateSubscriptionAction } from '@/state/actions';
import { setSubscriptionProducts, setUserCouponCode } from '@/actions';
import {
    requestGetUserSubscription,
    requestCreateSubscription,
    requestCancelSubscription,
    requestUndoCancelSubscription,
    requestReactivateSubscription,
    requestGetAvailableSubscriptions,
    requestUpdateSubscriptionPaymentMethod,
    requestUpdateSubscriptionProduct,
    requestValidateCouponCode,
    requestAddCouponCodeToSubscription,
    requestAcceptCancellationOffer
} from '@/modules/dataWrangler/subscription';

/**
 * Upon success, all functions will return a complete and updated version of the subscription object and
 * overwrite 'user/activeBusiness/subscription' in Redux.
 *
 * https://invoicemaker.atlassian.net/wiki/spaces/IM/pages/1678999553/Subscriptions
 */

let inProgress = false;
export const getUserSubscription = async (businessId, fromThankYou) => {
    // console.log("starting getUserSub:", businessId, fromThankYou)
    const isIos = Capacitor.getPlatform() === 'ios';
    const freshBusinessId =
        !businessId && store.getState().user?.activeBusiness?.businessId;
    // console.log("fresh:", freshBusinessId)

    const prepAndDispatch = async (subData) => {
        const rcData = await Purchases.getCustomerInfo();

        const newRcSub = {
            ...subData?.data,
            chargify: subData?.data?.chargify || {},
            managementURL: rcData?.managementURL,
            activeSubscriptions: rcData?.activeSubscriptions,
            rcData
        };

        store.dispatch(updateSubscriptionAction(newRcSub));
        // console.log("newRcSub01:", newRcSub)
        return newRcSub;
    };

    if (fromThankYou && freshBusinessId) {
        // console.log("starting fromThankYou && freshBusinessId:", fromThankYou, freshBusinessId)
        inProgress = true;

        const waitUntil = async () =>
            new Promise((resolve) => {
                const interval = setInterval(async () => {
                    // console.log("calling userData")
                    const userData = await requestGetUserSubscription(
                        freshBusinessId
                    );
                    // console.log("after userData:", userData.data)

                    if (userData?.data?.subscriptionSource === 'revenueCat') {
                        resolve(userData);
                        clearInterval(interval);
                        inProgress = false;
                    }
                }, 3000);
            });

        const data = await waitUntil();
        const subData = await prepAndDispatch(data);

        inProgress = false;
        return subData;
    }

    try {
        if (inProgress) {
            inProgress = false;
            return false;
        }

        inProgress = true;
        const response = await requestGetUserSubscription(
            businessId || freshBusinessId
        );
        // console.log("response:", response.data)

        if (isIos) {
            const subData = await prepAndDispatch(response);
            inProgress = false;
            return subData;
        }

        store.dispatch(updateSubscriptionAction(response?.data));
        inProgress = false;
        return response?.data;
    } catch (err) {
        // console.log("getUserSubscription err:", err)
        inProgress = false;
        return false;
    }
};

/**
 * @param {string} paymentType The payment type: 'card', 'pay_pal'.
 * @param {string} subscriptionHandle The payment interval. ie: 'monthly'
 * @param {string} paymentToken Chargify paymentToken generated by chargify-js.
 * @param {string} recaptchaToken Token provided from executing ReCAPTCHA
 * @param {string} couponCode Coupon code for Chargify (optional).
 * @returns Updated subscription object.
 */
export const createSubscription = async (
    paymentType,
    productHandle,
    paymentToken,
    recaptchaToken,
    couponCode,
    urlParams
) => {
    const { businessId } = store.getState().user.activeBusiness;

    try {
        const response = await requestCreateSubscription(
            businessId,
            paymentType,
            productHandle,
            paymentToken,
            recaptchaToken,
            couponCode,
            urlParams
        );

        store.dispatch(updateSubscriptionAction(response?.data));
        return response?.data;
    } catch (err) {
        return false;
    }
};

export const updateSubscriptionProduct = async (productToUpdate) => {
    const { businessId } = store.getState().user.activeBusiness;

    try {
        const response = await requestUpdateSubscriptionProduct(
            businessId,
            productToUpdate
        );

        store.dispatch(updateSubscriptionAction(response?.data));
        return response?.data;
    } catch (err) {
        return false;
    }
};

/**
 * @param {string} paymentType The payment type: 'card', 'pay_pal'.
 * @param {string} paymentToken Chargify paymentToken generated by chargify-js.
 * @param {string} recaptchaToken Token provided from executing ReCAPTCHA
 * @param {object} productHandle Handle for product.
 * @returns Updated subscription object.
 */
export const updateSubscription = async (
    paymentType,
    paymentToken,
    recaptchaToken,
    productHandle
) => {
    const { businessId } = store.getState().user.activeBusiness;

    try {
        const response = await requestUpdateSubscriptionPaymentMethod(
            businessId,
            paymentToken,
            paymentType,
            recaptchaToken
        );

        if (productHandle) {
            const updatedChargifyProduct = await updateSubscriptionProduct(
                productHandle
            );

            if (!updatedChargifyProduct) {
                return false;
            }

            store.dispatch(updateSubscriptionAction(updatedChargifyProduct));
            return updatedChargifyProduct;
        }

        store.dispatch(updateSubscriptionAction(response?.data));
        return response?.data;
    } catch (err) {
        return false;
    }
};

export const cancelSubscription = async () => {
    const { businessId } = store.getState().user.activeBusiness;

    try {
        const response = await requestCancelSubscription(businessId);

        store.dispatch(updateSubscriptionAction(response?.data));
        return response?.data;
    } catch (err) {
        return false;
    }
};

export const undoCancelSubscription = async () => {
    const { businessId } = store.getState().user.activeBusiness;

    try {
        const response = await requestUndoCancelSubscription(businessId);

        store.dispatch(updateSubscriptionAction(response?.data));
        return response?.data;
    } catch (err) {
        return false;
    }
};

export const reactivateSubscription = async () => {
    const { businessId } = store.getState().user.activeBusiness;

    try {
        const response = await requestReactivateSubscription(businessId);

        store.dispatch(updateSubscriptionAction(response?.data));
        return response?.data;
    } catch (err) {
        return false;
    }
};

export const getAvailableSubscriptions = async () => {
    // console.log("starting getAvailableSubscriptions")
    const { businessId } = store.getState().user.activeBusiness;
    if (businessId) {
        try {
            const response = await requestGetAvailableSubscriptions();

            store.dispatch(setSubscriptionProducts(response?.data));
            // console.log("returning from getAvailableSubscriptions")
            return response?.data;
        } catch (err) {
            return false;
        }
    }
    return false;
};

export const validateCouponCode = async (couponCode) => {
    const { businessId } = store.getState().user.activeBusiness;

    try {
        const response = await requestValidateCouponCode(
            businessId,
            couponCode.toUpperCase()
        );

        if (response) {
            store.dispatch(setUserCouponCode(response?.data));
        }

        return response?.data;
    } catch (err) {
        return false;
    }
};

export const addCouponCodeToSubscription = async (couponCode) => {
    const { businessId } = store.getState().user.activeBusiness;

    try {
        const response = await requestAddCouponCodeToSubscription(
            businessId,
            couponCode.toUpperCase()
        );

        return response?.data;
    } catch (err) {
        return false;
    }
};

export const acceptCancellationOffer = async () => {
    const { businessId } = store.getState().user.activeBusiness;

    try {
        const response = await requestAcceptCancellationOffer(businessId);

        store.dispatch(updateSubscriptionAction(response?.data));

        return response?.data;
    } catch (err) {
        return false;
    }
};
