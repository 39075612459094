import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateBusinessSettings } from '@/state/actions';
import {
    CountrySelector,
    CurrencySelector,
    TimezoneSelector,
    DrawerBody,
    FormSwitch
} from '@/components/common';
import Button from '../../Button';
import FormHeader from '../../FormHeader';
import Warning from '../../Warning';
import useStyles from '../../styles';

const Location = ({ clickedOutside, fields, handleOnSave, loading }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleSelectedItem = (item, value) => {
        dispatch(updateBusinessSettings({ ...fields, [item]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleOnSave();
    };

    return (
        <form className={classes.form} onSubmit={handleSubmit}>
            <DrawerBody>
                <FormHeader title="Add your company information." />
                <Grid item>
                    <CountrySelector
                        country={fields?.country}
                        setCountry={(val) => handleSelectedItem('country', val)}
                    />
                </Grid>
                <Grid item>
                    <CurrencySelector
                        currency={fields?.currency}
                        setCurrency={(val) =>
                            handleSelectedItem('currency', val)
                        }
                    />
                </Grid>
                <Grid item>
                    <TimezoneSelector
                        timezone={fields?.timezone}
                        setTimezone={(val) =>
                            handleSelectedItem('timezone', val)
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormSwitch
                        label="Show international phone numbers and addresses formatting"
                        name="showInternationalOptions"
                        onChange={() =>
                            handleSelectedItem(
                                'showInternationalOptions',
                                !fields?.showInternationalOptions
                            )
                        }
                        checked={fields?.showInternationalOptions}
                        color="secondary"
                    />
                </Grid>
                <Warning clickedOutside={clickedOutside} />
            </DrawerBody>
            <Button id="Billfrom" loading={loading} />
        </form>
    );
};

Location.propTypes = {
    clickedOutside: PropTypes.bool,
    fields: PropTypes.object,
    handleOnSave: PropTypes.func.isRequired,
    loading: PropTypes.bool
};

Location.defaultProps = {
    clickedOutside: false,
    fields: {},
    loading: false
};

export default Location;
