import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, Paper, Zoom } from '@mui/material';
import { InvoiceMakerLogo } from '@/resources/images';

import useStyles from './styles';

const Warning = ({ clickedOutside }) => {
    const classes = useStyles();

    return (
        <Zoom id="zoom" in={clickedOutside}>
            <Grid item className={classes.noticeWrapper}>
                <Paper className={classes.notice} elevation={0}>
                    <Grid container wrap="nowrap">
                        <InvoiceMakerLogo className={classes.svg} />
                        <Typography>
                            You only need to do this once, but you can update
                            any time
                        </Typography>
                    </Grid>
                </Paper>
            </Grid>
        </Zoom>
    );
};

Warning.propTypes = {
    clickedOutside: PropTypes.bool
};

Warning.defaultProps = {
    clickedOutside: false
};

export default Warning;
