import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';
import useStyles from './styles';

/**
 * Pure component for atomic close button. CSS styling places it absolute within
 * its wrapper. So make sure its parent has position: 'relative' set.
 * @param {func} handleClose Function to trigger close.
 */
const CloseButton = ({ handleClose }) => {
    const classes = useStyles();

    return (
        <IconButton
            className={classes.close}
            onClick={handleClose}
            size="large"
        >
            <Close />
            <span className={classes.closeSpan}>Close</span>
        </IconButton>
    );
};

CloseButton.propTypes = {
    handleClose: PropTypes.func.isRequired
};

export default CloseButton;
