import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography } from '@mui/material';
import { EllipsisIcon } from '@/resources/icons';
import Link from '../Link';
import useStyles from './styles';

const InnerCard = ({ title, left, right, full }) => {
    const classes = useStyles();
    return (
        <>
            <Typography variant="h2" className={classes.title}>
                {title}
            </Typography>
            <div className={classes.mid}>
                <div className={classes.left}>{left}</div>
                <div className={classes.right}>{right}</div>
            </div>
            {full && <div className={classes.full}>{full}</div>}
        </>
    );
};

InnerCard.propTypes = {
    title: PropTypes.string,
    left: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    right: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    full: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

InnerCard.defaultProps = {
    title: undefined,
    left: undefined,
    right: undefined,
    full: undefined
};

/**
 * The mobile view for the table listings.
 * @param {string} title Title of the card.
 * @param {string} to Page to link the card to.
 * @param {func} onClcik Function to fire if clicked.
 * @param {elements} left Elements to be stacked in the left side of the card.
 * @param {elements} right Elements to be stacked in the right side of the card.
 * @param {elements} full Elements to be full width.
 * @param {elements} actions Action icons to be shown on the card.
 */
const TableCard = memo(
    ({ to, title, onClick, left, right, full, actions, tags }) => {
        const [showActions, setShowActions] = useState(false);
        const classes = useStyles({ showActions });

        return (
            <Paper
                elevation={0}
                variant="outlined"
                classes={{ root: classes.root }}
            >
                {actions && (
                    <div className={classes.actionsButtonContainer}>
                        <button
                            type="button"
                            onClick={() => setShowActions(!showActions)}
                            className={classes.ellipsisButton}
                            aria-label="Show actions"
                        >
                            <EllipsisIcon className={classes.ellipsis} />
                        </button>
                    </div>
                )}
                <Grid
                    container
                    className={`${classes.actions} ${
                        !showActions ? classes.hideActions : ''
                    }`}
                >
                    {actions}
                </Grid>
                {to && (
                    <Link to={to} className={classes.link}>
                        <InnerCard
                            title={title}
                            left={left}
                            right={right}
                            full={full}
                            actions={actions}
                        />
                    </Link>
                )}
                {onClick && (
                    <button
                        type="button"
                        onClick={onClick}
                        className={classes.button}
                        aria-label={title}
                    >
                        <InnerCard
                            title={title}
                            left={left}
                            right={right}
                            full={full}
                            actions={actions}
                        />
                    </button>
                )}
                {!to && !onClick && (
                    <InnerCard
                        title={title}
                        left={left}
                        right={right}
                        full={full}
                        actions={actions}
                    />
                )}
                {tags && <div className={classes.tags}>{tags}</div>}
            </Paper>
        );
    }
);

TableCard.propTypes = {
    title: PropTypes.string,
    to: PropTypes.string,
    onClick: PropTypes.func,
    left: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    right: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    full: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    actions: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    tags: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

TableCard.defaultProps = {
    title: undefined,
    to: undefined,
    onClick: undefined,
    left: undefined,
    right: undefined,
    full: undefined,
    actions: undefined,
    tags: undefined
};

export default TableCard;
