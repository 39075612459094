import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    paymentTypeTag: {
        width: '100%',
        maxWidth: 75,
        textAlign: 'center',
        color: theme.palette.common.white,
        paddingTop: theme.spacing(0.6),
        paddingBottom: theme.spacing(0.6),
        borderRadius: 4,
        backgroundColor: (styles) =>
            styles.type === 'payment'
                ? theme.palette.actionColors.positive
                : theme.palette.actionColors.negative,
        [theme.breakpoints.up('sm')]: {
            maxWidth: 85
        }
    }
}));
