import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@/components/rhf-mui';
import Grid from '@mui/material/Unstable_Grid2';
import { forcePositiveNumberInput } from '@/util';
import Divider from '../Divider';
import ItemLabel from '../ItemLabel';
import makeStyles from './styles';

const Number = ({ heading, id, maxTitle, minTitle }) => {
    const classes = makeStyles();

    return (
        <Grid item container direction="column">
            <ItemLabel>{heading}</ItemLabel>
            <Grid item container spacing={4} className={classes.wrap}>
                <Grid item xs={6}>
                    <TextField
                        name={`${id}[min]`}
                        label={minTitle}
                        fullWidth
                        type="number"
                        onKeyDown={forcePositiveNumberInput}
                    />
                </Grid>
                <Divider />
                <Grid item xs={6}>
                    <TextField
                        name={`${id}[max]`}
                        label={maxTitle}
                        fullWidth
                        type="number"
                        onKeyDown={forcePositiveNumberInput}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

Number.propTypes = {
    heading: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    minTitle: PropTypes.string.isRequired,
    maxTitle: PropTypes.string.isRequired
};

export default Number;
