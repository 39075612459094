import makeStyles from '@mui/styles/makeStyles';

const handleIncreaseMargin = (increaseMargin, theme) => {
    if (increaseMargin) {
        return `calc(env(safe-area-inset-bottom) + ${theme.typography.pxToRem(
            105
        )})`;
    }
    return `calc(env(safe-area-inset-bottom) + ${theme.typography.pxToRem(9)})`;
};

export default makeStyles((theme) => ({
    wrap: {
        padding: `${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(
            0
        )}`,
        justifyContent: 'center',
        display: 'block',
        textAlign: 'center',
        width: '100%',
        [theme.breakpoints.down('lg')]: {
            marginBottom: (increaseMargin) =>
                handleIncreaseMargin(increaseMargin, theme)
        }
    }
}));
