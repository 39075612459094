import { endOfDay, startOfDay } from 'date-fns';
import { capitalizeFirstLetter } from './capitalizeFirstLetter';
import { dateIsoStringFromUniversalShort } from './formatDateTime';
import { toIntegerCurrency } from './currency';

export const createParam = (itemName, type) =>
    `${type}${capitalizeFirstLetter(itemName)}`;

// @link https://invoicemaker.atlassian.net/wiki/spaces/IM/pages/1768554497/Search+V2+Update

// The below are items that need multipying by 100
// i.e. 1 dollar to 100 cents.
const keysToConvertToDecimal = [
    'price',
    'balancePaid',
    'balanceDue',
    'value',
    'total'
];

export const filterArgFlattener = (args) => {
    const formattedArgs = {};

    if (args?.s) {
        formattedArgs.searchTerms = args.s;
    }

    if (args?.p) {
        formattedArgs.pageNumber = args.p;
    }

    if (args?.prefilters) {
        formattedArgs.prefilters = args.prefilters;
    }

    // State - for notifications (read or unread)
    if (args?.state) {
        formattedArgs.state = args.state;
    }

    // Status - for invoices (paid, unpaid, etc)
    if (args?.status) {
        formattedArgs.state = Object.keys(args.status).join();
    }

    if (args?.client) {
        formattedArgs.clientId = args.client;
    }

    if (args?.type) {
        formattedArgs.type = args.type;
    }

    if (args?.sort?.type) {
        formattedArgs.sortType = args.sort.type;
    }

    if (args?.sort?.order) {
        formattedArgs.sortOrder = args.sort.order;
    }

    if (args?.tagIds) {
        formattedArgs.tagIds = args.tagIds;
    }

    if (args?.recurringInvoiceId) {
        formattedArgs.recurringInvoiceId = args.recurringInvoiceId;
    }

    if (args?.parentType) {
        // Check if args.parentType is an object. If it is, we need to convert it to an array.
        // This is because of the difference between how URLSearchParams handles arrays, and how
        // the API requires them.

        if (typeof args?.parentType === 'object' && args?.parentType !== null) {
            formattedArgs.parentType = Object.keys(args.parentType).join();
        } else if (Array.isArray(args?.parentType)) {
            formattedArgs.parentType = args.parentType.join();
        } else {
            formattedArgs.parentType = args.parentType;
        }
    }

    if (args?.parentId) {
        formattedArgs.parentId = args.parentId;
    }

    if (args?.activityType) {
        formattedArgs.activityType = args.activityType;
    }

    if (typeof args === 'object' && args !== null) {
        Object.entries(args).forEach(([key, value]) => {
            if (value?.min) {
                if (
                    key.toLowerCase().includes('date') ||
                    key.toLowerCase().includes('createdat')
                ) {
                    formattedArgs[createParam(key, 'min')] = startOfDay(
                        dateIsoStringFromUniversalShort(value.min)
                    ).toISOString();
                } else if (keysToConvertToDecimal.includes(key)) {
                    formattedArgs[createParam(key, 'min')] = toIntegerCurrency(
                        value.min
                    );
                } else {
                    formattedArgs[createParam(key, 'min')] = value.min;
                }
            }
            if (value?.max) {
                if (
                    key.toLowerCase().includes('date') ||
                    key.toLowerCase().includes('createdat')
                ) {
                    formattedArgs[createParam(key, 'max')] = endOfDay(
                        dateIsoStringFromUniversalShort(value.max)
                    ).toISOString();
                } else if (keysToConvertToDecimal.includes(key)) {
                    formattedArgs[createParam(key, 'max')] = toIntegerCurrency(
                        value.max
                    );
                } else {
                    formattedArgs[createParam(key, 'max')] = value.max;
                }
            }
        });
    }

    return formattedArgs;
};
