import React from 'react';
import PropTypes from 'prop-types';
import { itemsMenuItems } from '@/config/menuItems';
import { TabPanel } from '@/components/common';

import Products from './Products';
import Services from './Services';
import Taxes from './Taxes';
import Discounts from './Discounts';
import Tags from './Tags';
import SubscriptionBanner from '../../components/SubscriptionBanner';
import useStyles from './styles';

const ItemRoute = ({ pathname }) => {
    const classes = useStyles();
    return (
        <>
            <div
                id="cio_message"
                className={classes.cio_message}
                data-testid="cio_message"
            />
            <SubscriptionBanner />
            {itemsMenuItems.map((page) => (
                <TabPanel key={page.path} currentTab={pathname} id={page.path}>
                    <div>
                        {page.title === 'Products' && <Products />}
                        {page.title === 'Services' && <Services />}
                        {page.title === 'Taxes' && <Taxes />}
                        {page.title === 'Discounts' && <Discounts />}
                        {page.title === 'Tags' && <Tags />}
                    </div>
                </TabPanel>
            ))}
        </>
    );
};

ItemRoute.propTypes = {
    pathname: PropTypes.string
};

ItemRoute.defaultProps = {
    pathname: undefined
};

export default ItemRoute;
