import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

import useStyles from './styles';

/**
 * Builds on top of the standard Button component provided
 * by Material UI.
 *
 * Sets the background and text color.
 *
 * It takes all standard props that the Button
 * would usually take.
 * @param {mixed} children String or elements to be passed inside the Button.
 */
const ButtonLight = ({ children, className, ...rest }) => {
    const classes = useStyles();

    const classNames = [classes.button];
    if (className) {
        classNames.push(className);
    }

    return (
        <Button className={classNames.join(' ')} {...rest}>
            {children}
        </Button>
    );
};

ButtonLight.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    className: PropTypes.string
};

ButtonLight.defaultProps = {
    children: '',
    className: undefined
};

export default ButtonLight;
