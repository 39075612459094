import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    modifier: {
        display: 'flex',
        width: '100%'
    },
    typeOptions: {
        width: theme.typography.pxToRem(42),
        display: 'flex',
        flexDirection: 'column',
        marginRight: -9,
        marginTop: -9,
        '& > span': {
            marginBottom: -9
        }
    }
}));
