import loadable from '@loadable/component';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery, Skeleton } from '@mui/material';
import { NoEmailModal } from '@/components/common';
import { closeModal, setModal } from '@/state/actions';
import {
    updateClient,
    deleteInvoice,
    voidInvoice
} from '@/modules/dataWrangler/dataWrangler';
import { delayAwait } from '@/util';

import { useCheckAuthState, useDocumentFormData, useModals } from '@/hooks';

import { useSendInvoice, useValidateInvoice } from '@/hooks/useSidebarActions';

import DocumentStateView from '../../DocumentStateView';
import {
    InvoiceAddPayment,
    InvoiceDelete,
    InvoicePreviewAndSend,
    InvoiceSave,
    InvoiceVoid,
    InvoiceViewParentRecurring
} from '../actions';

import useStyles from '../styles';

import {
    deleteInvoiceModalOptions,
    previewInvoiceNegativeTotalModalOptions,
    saveNegativeInvoiceTotalModalOptions,
    voidInvoiceModalOptions,
    voidWithPaidDocumentStateOptions
} from '../ModalCopy';

const PaymentModal = loadable(() => import('@/components/PaymentModal'));
const LottieModal = loadable(() => import('@/components/common/LottieModal'));
const ShareLinkModal = loadable(() =>
    import('@/components/common/ShareLinkModal')
);
const LottieDownloadLoading = loadable(() =>
    import('@/lottie/LottieDownloadLoading')
);
const LottieSendingLoading = loadable(() =>
    import('@/lottie/LottieSendingLoading')
);
const LottiePiggyBank = loadable(() => import('@/lottie/LottiePiggyBank'));
const LottiePreviewLoading = loadable(() =>
    import('@/lottie/LottiePreviewLoading')
);

const InvoiceSidebar = ({
    beforeActionPromptOptions,
    setBeforeActionPromptOptions,
    handleSetLoginBlocker,
    locked
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Styling
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    // Hooks
    const { loadingUser } = useCheckAuthState();
    const { documentLoading } = useDocumentFormData();
    const { isModalActive, getModal } = useModals();
    const sendInvoice = useSendInvoice();
    const validateInvoice = useValidateInvoice();

    const invoice = useSelector((state) => state.makeInvoice) || null;

    const showSkeletonLoaders = loadingUser || documentLoading;

    const documentId = invoice?.documentId || null;

    const classes = useStyles({ shippingDisabled: Boolean(!invoice?.to) });

    const [savingForPreview, setSavingForPreview] = useState(false);

    const setSaveNegativeTotalWarningActive = () => {
        setBeforeActionPromptOptions({
            ...beforeActionPromptOptions,
            ...saveNegativeInvoiceTotalModalOptions,
            onConfirm: () =>
                setBeforeActionPromptOptions({
                    ...saveNegativeInvoiceTotalModalOptions,
                    open: false
                }),
            open: true
        });
    };

    const setPreviewNegativeTotalWarningActive = () => {
        setBeforeActionPromptOptions({
            ...beforeActionPromptOptions,
            ...previewInvoiceNegativeTotalModalOptions,
            onConfirm: () =>
                setBeforeActionPromptOptions({
                    ...previewInvoiceNegativeTotalModalOptions,
                    open: false
                }),
            open: true
        });
    };

    const setDeleteInvoiceWarningActive = () => {
        setBeforeActionPromptOptions({
            ...beforeActionPromptOptions,
            ...deleteInvoiceModalOptions,
            onCancel: () =>
                setBeforeActionPromptOptions({
                    ...deleteInvoiceModalOptions,
                    open: false
                }),
            onConfirm: async () => {
                setBeforeActionPromptOptions({
                    ...deleteInvoiceModalOptions,
                    open: false
                });

                await deleteInvoice(documentId);

                navigate('/invoices');
            },
            open: true
        });
    };

    const setVoidInvoiceWarningActive = () => {
        setBeforeActionPromptOptions({
            ...beforeActionPromptOptions,
            ...voidInvoiceModalOptions,
            onCancel: () =>
                setBeforeActionPromptOptions({
                    ...voidInvoiceModalOptions,
                    open: false
                }),
            onConfirm: async () => {
                await voidInvoice(invoice);

                setBeforeActionPromptOptions({
                    ...voidInvoiceModalOptions,
                    open: false
                });
            },
            open: true
        });
    };

    const setVoidWithPaidDocumentStateActive = () => {
        setBeforeActionPromptOptions({
            ...beforeActionPromptOptions,
            ...voidWithPaidDocumentStateOptions,
            onConfirm: () => {
                setBeforeActionPromptOptions({
                    ...voidWithPaidDocumentStateOptions,
                    open: false
                });
            },
            open: true
        });
    };

    return (
        <div className={classes.sidebar}>
            <div className={classes.container}>
                <InvoicePreviewAndSend
                    onNegativeInvoice={setPreviewNegativeTotalWarningActive}
                    onLoginBlocker={handleSetLoginBlocker}
                    setLoading={setSavingForPreview}
                    loading={savingForPreview}
                    isDesktop={isDesktop}
                />

                <InvoiceViewParentRecurring />

                <InvoiceAddPayment onLoginBlocker={handleSetLoginBlocker} />

                <InvoiceVoid
                    onLoginBlocker={handleSetLoginBlocker}
                    onWarningActive={setVoidInvoiceWarningActive}
                    onWarningPaidActive={setVoidWithPaidDocumentStateActive}
                />

                <InvoiceSave
                    onLoginBlocker={handleSetLoginBlocker}
                    onNegativeInvoice={setSaveNegativeTotalWarningActive}
                />

                <InvoiceDelete onClick={setDeleteInvoiceWarningActive} />

                {locked && (
                    <div className={classes.desktopState}>
                        <div className={classes.section}>
                            {!showSkeletonLoaders ? (
                                <DocumentStateView documentType="invoice" />
                            ) : (
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={185}
                                />
                            )}
                        </div>
                    </div>
                )}

                {isModalActive('loader-invoice-send') && (
                    <LottieModal
                        title="Sending Invoice..."
                        backgroundColor="#F5F9FF"
                        open
                    >
                        <LottieSendingLoading />
                    </LottieModal>
                )}

                {isModalActive('loader-invoice-download') && (
                    <LottieModal
                        title="Downloading Invoice..."
                        backgroundColor="#F5F9FF"
                        open
                    >
                        <LottieDownloadLoading />
                    </LottieModal>
                )}

                {isModalActive('loader-manual-payment') && (
                    <LottieModal
                        title="Adding payment..."
                        backgroundColor="#F5F9FF"
                        open
                    >
                        <LottiePiggyBank />
                    </LottieModal>
                )}

                {isModalActive('loader-generate-invoice-link') && (
                    <LottieModal
                        title="Generating invoice link..."
                        backgroundColor="#F5F9FF"
                        open
                    >
                        <LottiePreviewLoading />
                    </LottieModal>
                )}

                <NoEmailModal
                    open={isModalActive('process-invoice-email-required')}
                    onClose={async (emailAddress, saveToClient, setLoading) => {
                        if (emailAddress === undefined) {
                            dispatch(
                                closeModal('process-invoice-email-required')
                            );
                            return;
                        }

                        setLoading(true);

                        const updatedClient = {
                            ...invoice.to,
                            emailAddress
                        };

                        const validatedInvoice = await validateInvoice(invoice);

                        await sendInvoice(validatedInvoice, emailAddress);

                        if (saveToClient) {
                            updateClient(updatedClient);
                        }

                        setLoading(false);
                    }}
                />

                {isModalActive('info-share-link') && (
                    <ShareLinkModal
                        open
                        onClose={() => dispatch(closeModal('info-share-link'))}
                        link={invoice?.link || ''}
                    />
                )}

                {locked && isModalActive('process-invoice-payment-options') && (
                    <PaymentModal
                        open
                        onClose={() =>
                            dispatch(
                                closeModal('process-invoice-payment-options')
                            )
                        }
                        currentDocument={invoice}
                        isDesktop={isDesktop}
                        triggerAnimation={async () => {
                            const startTime = Date.now();
                            dispatch(
                                setModal({ slug: 'loader-manual-payment' })
                            );
                            await delayAwait(startTime, 2000);
                            dispatch(closeModal('loader-manual-payment'));
                        }}
                        preselectPaymentMethod={
                            getModal('process-invoice-payment-options')
                                ?.preselectPaymentMethod
                        }
                    />
                )}
            </div>
        </div>
    );
};

InvoiceSidebar.propTypes = {
    beforeActionPromptOptions: PropTypes.shape({}),
    setBeforeActionPromptOptions: PropTypes.func.isRequired,
    handleSetLoginBlocker: PropTypes.func.isRequired,
    locked: PropTypes.bool
};

InvoiceSidebar.defaultProps = {
    beforeActionPromptOptions: {},
    locked: false
};

export default InvoiceSidebar;
