import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { formatDate, titleCase } from '@/util';
import styles from './styles';

const NextCycleBegins = ({
    nextCycleDate,
    locale,
    timezone,
    productId,
    chargify
}) => {
    const classes = styles();

    const isInCancelState = chargify?.cancelAtEndOfPeriod;
    const nextCycleChange = nextCycleDate === chargify?.currentPeriodEndsAt;
    const nextCycleCancel = isInCancelState && nextCycleChange;

    const isInDowngradeState =
        (productId === 'plus' &&
            !chargify?.cancelAtEndOfPeriod &&
            chargify?.nextProductHandle?.includes('lite')) ||
        (productId === 'pro' &&
            !chargify?.cancelAtEndOfPeriod &&
            (chargify?.nextProductHandle?.includes('plus') ||
                chargify?.nextProductHandle?.includes('lite')));

    const getLabel = () => {
        if (isInCancelState) {
            return 'Cancelling';
        }
        if (isInDowngradeState && nextCycleChange) {
            const newPlan = titleCase(chargify.nextProductHandle).split('-')[0];
            return `${newPlan} Plan Begins`;
        }
        return 'Next Cycle Begins';
    };

    return (
        <Grid
            item
            container
            xs={12}
            sm={4}
            className={classes.summaryItemContainer}
        >
            <Grid
                item
                container
                alignItems="center"
                className={classes.summaryItemTitle}
            >
                <Grid item>
                    <Typography className={classes.summaryItemInfo}>
                        {getLabel()}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={7}>
                <Typography
                    color="textSecondary"
                    className={
                        nextCycleCancel
                            ? classes.summaryNextCycleDate
                            : classes.summaryItemInfo
                    }
                >
                    {formatDate({
                        isoDate: nextCycleDate,
                        locale,
                        timezone
                    })}
                </Typography>
            </Grid>
        </Grid>
    );
};

NextCycleBegins.propTypes = {
    nextCycleDate: PropTypes.string,
    locale: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
    productId: PropTypes.string,
    chargify: PropTypes.object
};

NextCycleBegins.defaultProps = {
    nextCycleDate: undefined,
    productId: undefined,
    chargify: undefined
};

export default NextCycleBegins;
