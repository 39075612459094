import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import MuiAutocompleteTextField from '@/components/common/AutocompleteTextField';
import { useUrlParams } from '@/hooks';

/**
 * Wraps the common AutocompleteTextField component in a RHF controller to allow for the component to be used in RHF forms.
 *
 * @param {string} id An id to be passed to the AutocompleteTextField. Will default to name if not passed.
 * @param {string} name A name to be applied to the textfield for RHF handling.
 * @param {object} options An object of the suggested results for the dropdown.
 * @param {array} optionLabels An array of up to two strings. Must match the name of a key
 * @param {bool} controlWithUrlParams Whether the component is being controlled via parameters passed in the URL.
 * @param {object} urlParamOptions Options used in the event controlWithUrlParams is true.
 * @param {string} urlParamOptions.targetParam The desired parameter in the URL to control the component.
 * @param {string} urlParamOptions.inputValueSelectionParameter The desired parameter to use as the inputValue selection key.
 * This key will access the selected object and pass that value to the inputValue of the textfield.
 * @param {string} urlParamOptions.submitValueSelectionParameter The desired parameter to use as the submit value selection key.
 * This key will access the selected object and pass that value to the URL when submitted.
 * @param {bool} allowSearchableSecondaryLabel Option to include the secondary option label to be searchable. If true, optionLabels must have two strings in the array.
 */

const AutocompleteTextField = ({
    id,
    name,
    options,
    optionLabels,
    controlWithUrlParams,
    urlParamOptions,
    allowSearchableSecondaryLabel,
    ...rest
}) => {
    const {
        field: { onChange, value, ref },
        fieldState: { error }
    } = useController({
        name
    });

    const { getUrlParams } = useUrlParams();

    let urlInputValue;

    if (controlWithUrlParams) {
        const currentUrlParams = getUrlParams();

        const urlValue = currentUrlParams?.[urlParamOptions?.targetParam];

        if (urlValue) {
            urlInputValue = options.find(
                (option) =>
                    option?.[urlParamOptions?.submitValueSelectionParameter] ===
                    urlValue
            )?.[urlParamOptions?.inputValueSelectionParameter];
        }
    }

    const [inputValue, setInputValue] = useState(urlInputValue);

    const handleSelect = (selection) => {
        if (controlWithUrlParams) {
            setInputValue(
                selection?.[urlParamOptions?.inputValueSelectionParameter]
            );
            onChange(
                selection?.[urlParamOptions?.submitValueSelectionParameter]
            );
        } else {
            onChange(selection);
        }
    };

    const handleClear = (val) => {
        onChange(val);
        if (controlWithUrlParams) {
            setInputValue(val);
        }
    };

    return (
        <MuiAutocompleteTextField
            {...rest}
            id={id || name}
            helperText={error?.message}
            error={Boolean(error?.message)}
            onUniqueValueEntered={() => {}}
            options={options}
            optionLabels={optionLabels}
            onSelect={handleSelect}
            onClear={handleClear}
            name={name}
            inputRef={ref}
            value={value || ''}
            inputValue={controlWithUrlParams ? inputValue || '' : value || ''}
            onTextFieldChange={onChange}
            onInputChange={controlWithUrlParams ? setInputValue : undefined}
            allowSearchableSecondaryLabel={allowSearchableSecondaryLabel}
        />
    );
};

AutocompleteTextField.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    optionLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
    controlWithUrlParams: PropTypes.bool,
    urlParamOptions: PropTypes.object,
    allowSearchableSecondaryLabel: PropTypes.bool
};

AutocompleteTextField.defaultProps = {
    id: '',
    controlWithUrlParams: false,
    urlParamOptions: {},
    allowSearchableSecondaryLabel: false
};

export default AutocompleteTextField;
