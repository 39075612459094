import React from 'react';
import { ActivityEventLine } from '@/components/common';
import { formatDate, formatTime } from '@/util';

// Process array of objects into a stripped down version
// that only has required data so is faster to process.
export const dataProcessor = (dataArray) =>
    dataArray.map(
        ({ activityId, activityType, createdAt, data, parentType }) => ({
            id: activityId,
            activityType,
            paymentMethod: data?.method || '',
            createdAt,
            parentType
        })
    );

export const generateComponents = ({ array, locale, classes }) =>
    array.map(({ activityType, createdAt, parentType, id, paymentMethod }) => ({
        id,
        event: (
            <ActivityEventLine
                activityType={activityType}
                parentType={parentType}
                method={paymentMethod}
                alwaysPad
            />
        ),
        date: (
            <span className={classes.lineDate}>
                <span>
                    {formatDate({
                        isoDate: createdAt,
                        locale
                    })}
                </span>{' '}
                <span className={classes.lineDateTime}>
                    {formatTime({
                        isoDate: createdAt,
                        locale
                    })}
                </span>
            </span>
        )
    }));
