import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import useStyles from './styles';

/**
 * Animation modal for sending and downloading actions.
 * @param {string} backgroundColor backgroundColor for the wrapper.
 * @param {mixed} children Lottie components that have been wrapped by this component.
 * @param {string} title String to explain the action for screen reader purposes.
 * @param {bool} open Is modal open.
 */
const LottieModal = ({ children, title, open, ...props }) => {
    const classes = useStyles(props);

    return (
        <Dialog
            maxWidth={false}
            fullWidth
            open={open}
            aria-labelledby="lottie-modal-id"
            className={classes.dialog}
        >
            <div className={classes.wrapper}>
                <div className={classes.inner}>
                    {children}
                    <p id="lottie-modal-id" className={classes.title}>
                        {title}
                    </p>
                </div>
            </div>
        </Dialog>
    );
};

LottieModal.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    open: PropTypes.bool
};

LottieModal.defaultProps = {
    children: null,
    title: '',
    open: false
};

export default LottieModal;
