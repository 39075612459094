import { getCurrencySymbol, getCurrencySymbolPosition } from './currency';

/**
 * @param {string} section The array of the localizationSettings object to search through.
 * @param {string} key A key in an object that you want to match against the input variable
 * @param {string} input What you want to match from the user's settings
 * @returns
 */
const findItem = (localizationData, section, key, input) =>
    localizationData?.[section]?.find((object) => object?.[key] === input);

/**
 * @param {object} localizationData The localization object from redux
 * @param {string} countryCode The two character country code of the country you want to find in localizationData
 * @returns The full country object of the country matched from the countryCode param
 */
export const getCountryLocaleData = (localizationData, countryCode) =>
    localizationData?.countries?.find(
        (country) => country?.code === countryCode
    );

/**
 * @param {array} countryList The full list of countries from the localization object
 * @param {string} countryCode The two character country code of the country you want to match
 * @returns The address format for the country
 */
export const getLocalizationAddressFormat = (countryList, countryCode) =>
    countryList.find((country) => country.code === countryCode)?.addressFormat;

/**
 * This function gets specific localization data sfor currency.
 *
 * @param {object} localizationData The big localization object returned from the backend.
 * @param {string} userCurrency An uppercase string. `user > activeBusiness > businessSettings > currency`
 * @param {string} userLocale user > activeBusiness > businessSettings > locale
 * @returns An object with the keys all needed currency data.
 */
export const getCurrencyLocalizationData = (
    localizationData,
    userCurrency,
    locale
) => {
    const symbol = getCurrencySymbol({
        locale,
        currency: userCurrency
    });

    const position = getCurrencySymbolPosition({
        locale,
        currency: userCurrency
    });

    return {
        ...findItem(localizationData, 'currencies', 'code', userCurrency),
        position,
        symbol
    };
};

/**
 * This function gets specific localization data set by country, currency, locale and timezone.
 * Populated with the object in the localizationData object that corresponds to the `input` passed.
 * Also adds currency symbol and position.
 *
 * @param {object} localizationData The big localization object returned from the backend.
 * @param {string} userCountry Uppercase abbreviated country code. user > activeBusiness > businessProfile > address > country
 * @param {string} userCurrency An uppercase string. `user > activeBusiness > businessSettings > currency`
 * @param {string} userLocale user > activeBusiness > businessSettings > locale
 * @returns An object with the keys `country, currency, language`.
 */

export const getAllLocalizationData = (
    localizationData,
    userCountry,
    userCurrency,
    locale
) => {
    const result = {
        country: findItem(localizationData, 'countries', 'code', userCountry),
        currency: getCurrencyLocalizationData(
            localizationData,
            userCurrency,
            locale
        ),
        language: findItem(localizationData, 'languages', 'code', 'en')
    };

    return result;
};
