import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    dividerWrap: {
        display: 'flex',
        alignItems: 'center'
    },
    divider: {
        background: '#8C92A4',
        height: 1,
        margin: `${theme.typography.pxToRem(20)} 0`,
        width: '100%'
    },
    text: {
        textAlign: 'center',
        display: 'inline-block',
        color: '#8C92A4',
        fontSize: theme.typography.pxToRem(18),
        letterSpacing: '-.5px',
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: '1.25rem',
        minWidth: theme.typography.pxToRem(93),
        padding: `0 ${theme.typography.pxToRem(6)}`,
        width: '100%',
        margin: 0
    }
}));
