import {
    calculateProductTax,
    calculateProductDiscountAmount
} from './invoiceHelpers';

export const getTaxesAndDiscountsByName = (products) => {
    const discountByName = {};
    const taxByName = {};

    products.forEach((product) => {
        product.taxes.forEach((tax) => {
            if (taxByName[tax.name])
                taxByName[tax.name] = {
                    taxId: tax.taxId,
                    value:
                        taxByName[tax.name].value +
                        calculateProductTax(tax, product),
                    amount: tax.value
                };
            else
                taxByName[tax.name] = {
                    taxId: tax.taxId,
                    value: calculateProductTax(tax, product),
                    amount: tax.value
                };
        });

        product.discounts.forEach((discountItem) => {
            if (discountByName[discountItem.name])
                discountByName[discountItem.name] = {
                    discountId: discountItem.discountId,
                    type: discountItem.type,
                    value:
                        discountByName[discountItem.name].value +
                        calculateProductDiscountAmount(discountItem, product),
                    amount: discountItem.value
                };
            else
                discountByName[discountItem.name] = {
                    discountId: discountItem.discountId,
                    type: discountItem.type,
                    value: calculateProductDiscountAmount(
                        discountItem,
                        product
                    ),
                    amount: discountItem.value
                };
        });
    });

    return {
        discountByName,
        taxByName
    };
};
