import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';
import { getLocalStorageItem } from '@/modules/localStorageManager';

const analytics = Analytics({
    app: 'Invoice Maker Frontend',
    plugins: [
        segmentPlugin({
            writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY
        })
    ]
});

export const pageView = () => analytics.page();

export const identify = (userId) =>
    analytics.identify(userId, {
        glcid: getLocalStorageItem('gclid'),
        msclkid: getLocalStorageItem('msclkid')
    });

export const ctaClicked = ({ destination, text, location, type }) =>
    analytics.track('CTA Clicked', {
        destination,
        text,
        location,
        type,
        url: window.location.href
    });

export const clientCreated = (client) =>
    analytics.track('Client Created', {
        clientId: client.clientId,
        name: client.name,
        companyName: client.companyName,
        clientEmail: client.emailAddress
    });

export const documentExported = (document, mode) =>
    analytics.track('Document Exported', {
        documentType: document?.documentType,
        documentId: document?.documentId,
        exportMethod: mode,
        paymentDueDate: document?.dueDate || document?.document?.dueDate
    });

export const documentSaved = (document) =>
    analytics.track('Document Saved', {
        documentType: document.documentType,
        documentId: document.documentId
    });

export const documentStarted = (documentType) =>
    analytics.track('Document Started', {
        documentType
    });

export const paymentMethodConnected = (paymentMethod) =>
    analytics.track('Payment Method Connected', {
        paymentMethod
    });
