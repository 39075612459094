import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import useStyles from './styles';

/**
 * Optional component used to add sorting to table headers.
 * @param {string} id The ID of the row.
 * @param {string} label The visible label for the row.
 */
const SortLabel = ({ id, handleRequestSort, label, order, orderBy }) => {
    const classes = useStyles();

    const createSortHandler = (property) => {
        handleRequestSort(property);
    };

    return (
        <TableSortLabel
            IconComponent={ExpandLessIcon}
            active={orderBy === id}
            direction={orderBy === id ? order : 'asc'}
            onClick={() => createSortHandler(id)}
            classes={{
                root: orderBy === id ? classes.activeSortLabel : '',
                icon: orderBy === id ? classes.activeSortLabel : ''
            }}
        >
            {label}
            {orderBy === id ? (
                <span className={classes.visuallyHidden}>
                    {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                </span>
            ) : null}
        </TableSortLabel>
    );
};

SortLabel.propTypes = {
    id: PropTypes.string.isRequired,
    handleRequestSort: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired
};

/**
 * Creates the head of the table within the TableMaker component. Sets if
 * columns can be ordered, which direction they are ordered and displays
 * accessibility labels for screen readers.
 * @param {func} handleRequestSort Function which when triggered, sorts the data
 * within the TableMaker component.
 * @param {array} headCells An array of objects that set the ID of a column as
 * a keyhead and a visible label.
 * @param {string} order Is the column to be ordered up or down. asc/desc.
 * @param {string} orderBy Which column is the data sorted by.
 */
const TableHeader = ({ handleRequestSort, headCells, order, orderBy }) => (
    <TableHead>
        <TableRow>
            {headCells.map((headCell) => (
                <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                    colSpan={headCell.colSpan}
                >
                    {headCell.sort ? (
                        <SortLabel
                            id={headCell.id}
                            handleRequestSort={handleRequestSort}
                            label={headCell.label}
                            order={order}
                            orderBy={orderBy}
                        />
                    ) : (
                        headCell.label
                    )}
                </TableCell>
            ))}
        </TableRow>
    </TableHead>
);

TableHeader.propTypes = {
    handleRequestSort: PropTypes.func.isRequired,
    headCells: PropTypes.instanceOf(Array).isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired
};

export default TableHeader;
