import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    hasCouponBanner: {
        position: 'relative',
        backgroundColor: theme.palette.actionColors.positive,
        borderRadius: theme.typography.pxToRem(4),
        padding: `${theme.typography.pxToRem(12)} ${theme.typography.pxToRem(
            14
        )}`,
        [theme.breakpoints.up('sm')]: {
            marginLeft: 10,
            maxWidth: 355
        },
        '& svg': {
            position: 'absolute',
            top: theme.typography.pxToRem(16),
            left: theme.typography.pxToRem(16),
            maxWidth: 18
        },
        '& svg > circle': {
            fill: theme.palette.common.white
        },
        '& svg > path': {
            stroke: theme.palette.actionColors.positive,
            strokeWidth: 3
        },
        '& p': {
            textAlign: 'left',
            marginLeft: theme.typography.pxToRem(30)
        }
    }
}));
