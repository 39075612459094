import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    makeInvoiceButton: {
        marginRight: theme.typography.pxToRem(20),
        fontWeight: 600,
        padding: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(
            20
        )}`,
        [theme.breakpoints.only('xs')]: {
            minWidth: 'auto',
            padding: theme.typography.pxToRem(6),
            borderRadius: '50%',
            marginRight: theme.typography.pxToRem(5),
            '& span': {
                margin: 0
            }
        }
    },
    paper: {
        position: 'relative'
    },
    makeInvoiceButtonText: {
        [theme.breakpoints.only('xs')]: theme.visuallyHidden
    },
    popover: {
        paddingTop: `${theme.typography.pxToRem(24)} !important`,
        zIndex: 1100
    },
    startIcon: {
        backgroundColor: '#5096da',
        borderRadius: '50%',
        [theme.breakpoints.down('sm')]: {
            backgroundColor: 'transparent'
        }
    },
    arrow: {
        position: 'absolute',
        top: -10,
        fontSize: 7,
        width: '3em',
        height: '3em',
        left: 0,
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderLeft: '12px solid transparent',
            borderRight: '12px solid transparent',
            borderBottom: '12px solid #fff'
        }
    }
}));
