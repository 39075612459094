import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    subscriptionSettingsBackground: {
        margin: '0 auto',
        marginBottom: 20,
        backgroundColor: theme.palette.common.lightGray,
        borderRadius: theme.typography.pxToRem(4),
        padding: 30,
        [theme.breakpoints.up('sm')]: {
            marginBottom: 20,
            marginRight: 'unset',
            marginLeft: 10,
            maxWidth: 355
        }
    },
    cancelAnytimeText: {
        marginTop: theme.spacing(2),
        color: theme.palette.common.detailGray,
        '& > p': {
            fontSize: 14
        }
    },
    iosSubscribeText: {
        marginTop: theme.spacing(2),
        color: theme.palette.common.detailGray,
        '& > p': {
            marginBottom: theme.spacing(0.5),
            fontSize: 14
        }
    },
    iosSettingsPath: {
        color: theme.palette.imBrand.midBlueHover
    },
    couponBanner: {
        minWidth: '100%',
        marginTop: 10,
        [theme.breakpoints.up('sm')]: {
            marginTop: 0,
            marginBottom: 30
        }
    },
    billingSwitchSkeletonContainer: {
        marginLeft: '20px'
    },

    confirmModalIcon: {
        width: '100%',
        maxWidth: 60
    },
    subscriptionAccordionContainer: {
        border: `1px solid ${theme.palette.actionColors.tags}`,
        borderRadius: 3,
        backgroundColor: theme.palette.common.lightGray,
        marginBottom: theme.spacing(2),
        '&::before': {
            backgroundColor: 'transparent'
        }
    },
    expandIconWrapper: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            position: 'static'
        }
    },
    expandIcon: {
        width: 30,
        height: 30
    },
    subscriptionDetailsContainer: {
        borderTop: `1px solid #CBD5E1`
    }
}));
