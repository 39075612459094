import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';

import { duplicateRecurringInvoice } from '@/modules/dataWrangler/dataWrangler';
import { DuplicateIcon, DuplicateIconPopup } from '@/resources/icons';

import { useToastNotification, useSubscription } from '@/hooks';

import WarnConfirmDialog from '../../WarnConfirmDialog';
import ActionIconWrapper from '../ActionIconWrapper';

const useStyles = makeStyles(() => ({
    send: {
        width: 19,
        height: 16
    },
    cleanIcon: { fill: 'none !important' },
    convertModalIcon: { width: 91, height: 91 }
}));

/**
 * View Icon with link attached.
 * @param {string} id ID of the invoice to view.
 */
export const ViewRecurringInvoiceIcon = ({ id }) => (
    <ActionIconWrapper
        action="view"
        label="View"
        link={`/recurring-invoice/${id}`}
        tooltip="View Recurring Invoice"
    />
);

ViewRecurringInvoiceIcon.propTypes = {
    id: PropTypes.string.isRequired
};

/**
 * Edit Icon with dispatch attached
 * @param {string} id ID of the invoice to edit.
 */
export const EditRecurringInvoiceIcon = ({ id }) => (
    <ActionIconWrapper
        action="edit"
        label="Edit"
        link={`/recurring-invoice/${id}`}
        tooltip="Edit Recurring Invoice"
    />
);

EditRecurringInvoiceIcon.propTypes = {
    id: PropTypes.string.isRequired
};

/**
 * Duplicate Icon with dispatch attached
 * @param {string} id ID of the invoice to duplicate.
 */
export const DuplicateRecurringInvoiceIcon = ({ id }) => {
    const theme = useTheme();
    const classes = useStyles();
    const navigate = useNavigate();
    const subscriptionData = useSubscription();

    const [duplicateDialogActive, setDuplicateDialogActive] = useState(false);

    const handleClick = () => {
        const blockInvoiceCreation =
            !subscriptionData?.components?.recurring?.enabled;

        if (blockInvoiceCreation) {
            // If the user has no more documents, remaining force them
            // to subscription page.
            navigate('/settings/subscription');
        } else {
            setDuplicateDialogActive(true);
        }
    };

    const { triggerToast } = useToastNotification();

    const handleConfirmation = async () => {
        setDuplicateDialogActive(false);

        const newInvoice = await duplicateRecurringInvoice(id);

        if (newInvoice) {
            triggerToast({
                message: `Recurring Invoice Duplicated`,
                action: 'duplicate',
                icon: <DuplicateIcon className={classes.cleanIcon} />
            });

            setTimeout(() => {
                navigate(`/recurring-invoice/${newInvoice.documentId}`);
            }, 1000);
        }
    };

    const handleCancellation = () => {
        setDuplicateDialogActive(false);
    };

    return (
        <>
            <ActionIconWrapper
                action="duplicate"
                label="Duplicate"
                onClick={() => {
                    handleClick(id);
                }}
                tooltip="Duplicate Recurring Invoice"
            />
            <WarnConfirmDialog
                onCancel={() => handleCancellation()}
                onConfirm={() => handleConfirmation()}
                customIcon={<DuplicateIconPopup />}
                confirmColor={theme.palette.primary.main}
                message="Are you sure you want to duplicate this recurring invoice?"
                cancelText="Cancel"
                confirmText="Duplicate"
                open={duplicateDialogActive}
            />
        </>
    );
};

DuplicateRecurringInvoiceIcon.propTypes = {
    id: PropTypes.string.isRequired
};
