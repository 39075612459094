import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    wrap: {
        display: 'flex',
        position: 'sticky',
        bottom: 0,
        right: 0,
        left: 0,
        padding: `${theme.typography.pxToRem(20)}`,
        [theme.breakpoints.only('xs')]: {
            padding: `${theme.typography.pxToRem(10)} 
            ${theme.typography.pxToRem(10)} 
            calc(env(safe-area-inset-bottom) + ${theme.typography.pxToRem(10)})`
        },
        boxShadow: '0px -5px 5px -5px #DEDEE7',
        margin: `${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(
            -15
        )} ${theme.typography.pxToRem(-60)}`,
        zIndex: 999,
        backgroundColor: theme.palette.common.white
    },
    buttonWrap: {
        width: '50%',
        paddingRight: theme.typography.pxToRem(10),
        '&:last-child': {
            paddingRight: 0,
            paddingLeft: theme.typography.pxToRem(10)
        }
    },
    saveButton: {
        padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
            20
        )}`,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        '&:disabled': {
            color: ({ trueDisabledStyle }) =>
                trueDisabledStyle ? undefined : theme.palette.common.white,
            backgroundColor: ({ trueDisabledStyle }) =>
                trueDisabledStyle
                    ? undefined
                    : `${theme.palette.primary.main} !important`
        }
    },
    cancelButton: {
        backgroundColor: theme.palette.common.white,
        border: '1px solid black',
        padding: `${theme.typography.pxToRem(9)} ${theme.typography.pxToRem(
            20
        )}`,
        color: theme.palette.common.black,
        '&:hover': {
            backgroundColor: 'rgba(0,0,0, .08)',
            color: theme.palette.common.black
        }
    }
}));
