import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { TagsIconBlue } from '@/resources/icons';
import { openDrawer, removeDocumentTag } from '@/state/actions';
import { TagPill, MakeDocumentAccordion } from '@/components/common';
import { updateLockedDocumentTags } from '@/modules/dataWrangler/document';

import useStyles from './styles';

const TagsAccordion = ({ locked, recurring }) => {
    const classes = useStyles();
    const document = useSelector((state) => state?.makeInvoice);
    const documentId = document?.documentId || '';
    const documentType = recurring
        ? 'recurring-invoice'
        : document?.documentType || '';
    const tags = document?.tags || [];
    const tagNumber = tags.length;
    const dispatch = useDispatch();

    const handleOpenDrawer = (e) => {
        e.stopPropagation();
        dispatch(
            openDrawer({
                id: 'tags',
                type: documentType,
                item: document,
                makeDocument: true
            })
        );
    };

    const handleRemoveTag = (tagId) => {
        if (locked) {
            const currentTags = [...tags];
            const tagIndex = currentTags.findIndex(
                (tag) => tag.tagId === tagId
            );

            currentTags.splice(tagIndex, 1);

            updateLockedDocumentTags(documentId, documentType, currentTags);
        } else {
            dispatch(removeDocumentTag(tagId));
        }
    };

    return (
        <MakeDocumentAccordion
            buttonName="Add Tags"
            title="Tags"
            handleAddButtonClick={handleOpenDrawer}
            count={tagNumber}
            icon={<TagsIconBlue className={classes.iconContainer} />}
        >
            <Grid container>
                {tags.map((tag) => (
                    <Grid item key={tag.name}>
                        <TagPill
                            name={tag.name}
                            color={tag.color}
                            backgroundColor={tag.backgroundColor}
                            onClick={() => handleRemoveTag(tag.tagId)}
                        />
                    </Grid>
                ))}
            </Grid>
        </MakeDocumentAccordion>
    );
};

export default TagsAccordion;

TagsAccordion.propTypes = {
    locked: PropTypes.bool,
    recurring: PropTypes.bool
};

TagsAccordion.defaultProps = {
    locked: false,
    recurring: false
};
