import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    paymentMethodLast4Container: {
        display: 'flex',
        textTransform: 'capitalize',
        alignItems: 'center'
    },
    dialogPaper: {
        overflowX: 'hidden'
    },
    modal: {
        padding: theme.typography.pxToRem(16),
        margin: `0 -8px`,
        [theme.breakpoints.up('sm')]: {
            padding: theme.typography.pxToRem(50)
        }
    },
    cardHeader: {
        display: 'flex',
        alignItems: 'center'
    },
    refundIcon: {
        width: 24,
        height: 24,
        marginRight: 14
    },
    paymentIcon: {
        width: 17.3,
        height: 12.11,
        marginRight: 7.31
    },
    methodTextContainer: {
        [theme.breakpoints.down(500)]: {
            minWidth: '100%'
        }
    },
    buttonContainer: {
        marginTop: theme.typography.pxToRem(10)
    },
    refundTitle: {
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.text.secondary
    },
    refundField: {
        fontSize: theme.typography.pxToRem(26),
        padding: 0,
        width: '100%'
    },
    cardInfo: {
        display: 'flex',
        alignItems: 'center',
        float: 'bottom'
    },
    cardInfoContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    characterText: {
        fontSize: theme.typography.pxToRem(12),
        color: theme.palette.text.disabled
    },
    withoutProcess: {
        [theme.breakpoints.up(501)]: {
            display: 'none'
        }
    },
    withProcess: {
        [theme.breakpoints.down(501)]: {
            display: 'none'
        }
    },
    refundWarningContainer: {
        marginTop: 25,
        marginLeft: 16,
        padding: 10,
        backgroundColor: '#F9F0D2',
        color: '#745F11'
    },
    refundWarningText: {
        width: 'calc(100% - 36px)',
        marginLeft: 16
    },
    refundWarningIcon: {
        width: 20,
        height: 20,
        '& path:first-child': {
            fill: '#BB991B'
        },
        '& path:last-child': {
            fill: '#BB991B'
        }
    }
}));
