import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import ReceiptForm from '@/components/MakeReceipt';
import { Redirect, SEO } from '@/components/common';
import { Container, Layout } from '@/containers';
import { useSetIMPageType } from '@/hooks';
import { setHotjarPage } from '@/util';

const Receipt = () => {
    const params = useParams();
    const { documentLoading, redirect } = useSetIMPageType({
        params,
        documentType: 'receipt'
    });

    // Allows redirection of page if this component/URL is not correct.
    if (!documentLoading && redirect) {
        return <Redirect to={redirect} />;
    }

    setHotjarPage({ page: 'MakeReceipt' });

    return (
        <Layout>
            <Container>
                <SEO title="Make a Receipt" />
                <ReceiptForm
                    documentType="receipt"
                    isLoading={documentLoading}
                />
            </Container>
        </Layout>
    );
};

export default Receipt;
