import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    dash: {
        width: 10,
        height: 2,
        backgroundColor: theme.palette.common.detailGray,
        position: 'absolute',
        top: 'calc(50% - 1px)',
        left: 'calc(50% - 5px)'
    }
}));
