/* eslint import/no-cycle: "off"  */
import store from '@/state/store';

import { loggedIn, setActiveBusinessPending } from '@/state/actions';

import { getCurrentUser, getSession } from '@/modules/loginManager';

import { createNewCognitoUserSession } from '@/modules/loginManager/cognitoHelpers';

import * as dataWrangler from '@/modules/dataWrangler/dataWrangler';
import { setSentryUser } from '@/util';
import * as segmentManager from '@/modules/segment/segmentManager';

export const initializeUser = async (cognitoUserSession) => {
    const jwt = cognitoUserSession.idToken.jwtToken;

    segmentManager.identify(cognitoUserSession.idToken.payload.sub);
    // eslint-disable-next-line no-underscore-dangle
    window._cio.identify({ id: cognitoUserSession.idToken.payload.sub });
    setSentryUser({ jwt });
    store.dispatch(loggedIn(true));
    store.dispatch(setActiveBusinessPending());

    await dataWrangler.getUser();
    await dataWrangler.overwriteBusiness();

    // These load asynchronously as the data is not need on initial load.
    // Future refactor - may be good to move them to the actual components
    // that the data is used in. To stop unneeded API calls.
    dataWrangler.overwriteProducts();
    dataWrangler.overwriteClients();
    dataWrangler.getTaxes();
    dataWrangler.getBusinessDiscounts();
    dataWrangler.getTags();
    // dataWrangler.getFiles();
};

export const convertTemporaryUserToUnregisteredUser = async () => {
    const { IdToken, RefreshToken, AccessToken } =
        await dataWrangler.createUnregisteredUser(
            store.getState().user.activeBusiness
        );
    const cognitoUserSession = createNewCognitoUserSession(
        IdToken,
        RefreshToken,
        AccessToken
    );
    await initializeUser(cognitoUserSession);
};

const handlePageRedirect = (location, navigate) => {
    if (
        location &&
        location?.pathname !== '/' &&
        !(
            location?.pathname?.includes('settings') ||
            location?.pathname?.includes('login') ||
            location?.pathname?.includes('forgot-password') ||
            location?.pathname?.includes('sign-up') ||
            location?.pathname?.includes('reset-password') ||
            location?.pathname?.includes('quick-checkout') ||
            location?.pathname?.match(/\/clients\/.*/)
        )
    ) {
        navigate('/', { replace: true });
    }
};

export const onPageLoadSetup = async (location, navigate) => {
    // If the user is on a link (pay invoice) page we do not
    // need to set up any user data. Everything is managed by the
    // document API call. They are considered 'external'.
    if (!location?.pathname?.includes('link')) {
        const user = getCurrentUser();
        const session = await getSession(user);

        if (user === null || session === null) {
            await dataWrangler.getTemporaryBusiness();
            handlePageRedirect(location, navigate);
        } else {
            handlePageRedirect(location, navigate);
            await initializeUser(session);
        }
    }
};
