import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom-v5-compat';

/**
 * Redirects to a new page. This is a workaround for the issue with
 * react-router-dom-v5-compat where the navigate function is not
 * updated when the component is re-rendered.
 * https://github.com/remix-run/react-router/issues/8733
 */
export const Redirect = ({ to, state }) => {
    const navigate = useNavigate();
    const navigateRef = useRef(navigate);

    useEffect(() => {
        navigateRef.current = navigate;
    }, [navigate]);

    useEffect(() => {
        navigateRef.current(to, { replace: true, state });
    }, [to, state]);

    return null;
};

Redirect.propTypes = {
    to: PropTypes.string.isRequired,
    state: PropTypes.shape({})
};

Redirect.defaultProps = {
    state: undefined
};

export default Redirect;
