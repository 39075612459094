import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    badge: {
        marginRight: 12,
        [theme.breakpoints.up('sm')]: {
            marginRight: 18
        }
    },
    dot: {
        backgroundColor: '#C20101',
        color: '#fff',
        height: 14,
        minWidth: 14,
        padding: 4,
        fontSize: 11,
        opacity: 1,
        transition: 'all .2s ease-in-out'
    },
    noDot: {
        opacity: 0,
        transition: 'all .2s ease-in-out'
    },
    circleStyle: {
        borderRadius: '50%',
        backgroundColor: '#F5F5FA',
        width: 36,
        height: 36,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    bellIcon: {
        color: '#64748B',
        width: 20,
        height: 24,
        transition: 'all .2s ease-in-out',
        transformOrigin: 'center'
    },
    buttonBase: {
        '&:hover .MuiBadge-standard': {
            transform: 'scale(1.2) translate(50%, -50%)'
        },
        '&:hover svg': {
            transform: 'scale(0.9)'
        }
    }
}));
