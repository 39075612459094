import React from 'react';
import PropTypes from 'prop-types';
import { Grid, SvgIcon, Typography } from '@mui/material';
import {
    formatCurrency,
    toWholeCurrency,
    setPlanIcon,
    calculatePercentageDiscount
} from '@/util';
import { ReactComponent as FireIcon } from '@/resources/icons/fire.svg';
import SubscriptionCardButton from './SubscriptionCardButton';
import styles from './styles';

const SubscriptionCard = ({
    cancelling,
    couponData,
    couponIsValid,
    description,
    hasChargifyData,
    hasRcData,
    iosIsPurchasing,
    isIos,
    isPending,
    isUpgrade,
    managementURL,
    onButtonClick,
    planType,
    price,
    product,
    subscribed,
    subscriptionSource,
    title
}) => {
    const classes = styles();

    const formattedPrice = formatCurrency({
        amount: toWholeCurrency(price),
        locale: 'en-US',
        currency: 'USD'
    });

    const discountedPrice = couponIsValid
        ? calculatePercentageDiscount(price, Number(couponData?.percentage))
        : null;

    const formattedDiscountPrice = couponIsValid
        ? formatCurrency({
              amount: toWholeCurrency(discountedPrice),
              currency: 'USD',
              locale: 'en-US'
          })
        : null;

    const cycleIndicator = planType === 'monthly' ? '/mo' : '/yr';

    const showButton = (isIos && !hasChargifyData) || (!isIos && !hasRcData);

    const canUserGetTrial =
        product?.trialInterval && subscriptionSource === 'unassigned' && !isIos;

    return (
        <Grid
            item
            xs={12}
            md={4}
            className={
                title.toLowerCase() === 'plus'
                    ? classes.cardOrder1
                    : classes.cardOrder2
            }
        >
            <div
                className={`${classes.subscriptionCardContainer} ${
                    title.toLowerCase() === 'plus' && classes.plusContainer
                }`}
            >
                <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    columnSpacing={1}
                    xs={5}
                    className={classes.iconWrap}
                >
                    <Grid item className={classes.iconWrap}>
                        <SvgIcon
                            component={setPlanIcon(title)}
                            className={classes.cardTitleIcon}
                        />
                    </Grid>

                    <Grid item>
                        <Typography variant="h4">{title}</Typography>
                    </Grid>
                </Grid>

                {title.toLowerCase() === 'plus' && (
                    <div className={classes.mostPopularPill}>
                        <Typography
                            component="span"
                            variant="body2"
                            color="primary"
                            className={classes.popularPillText}
                        >
                            <FireIcon className={classes.fireStyle} /> Most
                            popular
                        </Typography>
                    </div>
                )}

                {canUserGetTrial && (
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="flex-start"
                        alignItems="baseline"
                    >
                        <Typography className={classes.planCardPriceTrial}>
                            {`$0 for ${product?.trialInterval} ${
                                product?.trialIntervalUnit
                            }${product?.trialInterval > 1 ? 's' : ''}`}
                        </Typography>

                        <Typography
                            component="h4"
                            variant="h4"
                            color="textSecondary"
                            className={classes.planCardPriceIntervalTrial}
                        >
                            {`then ${formattedPrice}${
                                planType === 'monthly' ? '/month' : '/year'
                            }`}
                        </Typography>
                    </Grid>
                )}

                {!canUserGetTrial && (
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="flex-start"
                        alignItems="baseline"
                    >
                        <Typography className={classes.planCardPrice}>
                            {formattedDiscountPrice || formattedPrice}
                        </Typography>
                        {couponIsValid ? (
                            <Typography
                                component="h4"
                                variant="h4"
                                color="textSecondary"
                                className={classes.planCardPriceToday}
                            >
                                today
                            </Typography>
                        ) : (
                            <Typography
                                component="h4"
                                variant="h4"
                                color="textSecondary"
                                className={classes.planCardPriceInterval}
                            >
                                {planType === 'monthly' ? '/month' : '/year'}
                            </Typography>
                        )}
                    </Grid>
                )}

                {couponIsValid && (
                    <Grid item container justifyContent="flex-start">
                        <Typography
                            variant="body2"
                            color="textSecondary"
                        >{`${formattedPrice} ${cycleIndicator} after`}</Typography>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Typography
                        variant="h4"
                        className={classes.planCardDescription}
                    >
                        <div
                            data-testid="plan-description"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: description }}
                        />
                    </Typography>
                </Grid>
                {showButton && (
                    <div
                        className={
                            subscribed || isPending
                                ? classes.planCardNoButton
                                : classes.planCardButton
                        }
                    >
                        <SubscriptionCardButton
                            cancelling={cancelling}
                            isIos={isIos}
                            onButtonClick={onButtonClick}
                            subscribed={subscribed}
                            title={title}
                            discountedPrice={discountedPrice}
                            iosIsPurchasing={iosIsPurchasing}
                            isUpgrade={isUpgrade}
                            isPending={isPending}
                            managementURL={managementURL}
                            product={product}
                            subscriptionSource={subscriptionSource}
                        />
                    </div>
                )}
            </div>
        </Grid>
    );
};

SubscriptionCard.propTypes = {
    cancelling: PropTypes.bool,
    couponData: PropTypes.object,
    couponIsValid: PropTypes.bool,
    description: PropTypes.string.isRequired,
    hasChargifyData: PropTypes.bool,
    hasRcData: PropTypes.bool,
    iosIsPurchasing: PropTypes.bool,
    isIos: PropTypes.bool,
    isPending: PropTypes.bool,
    isUpgrade: PropTypes.bool,
    managementURL: PropTypes.string,
    onButtonClick: PropTypes.func.isRequired,
    planType: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    product: PropTypes.object.isRequired,
    subscribed: PropTypes.bool,
    subscriptionSource: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

SubscriptionCard.defaultProps = {
    cancelling: false,
    couponData: {},
    couponIsValid: false,
    hasChargifyData: false,
    hasRcData: false,
    iosIsPurchasing: false,
    isIos: false,
    isPending: false,
    isUpgrade: null,
    managementURL: '',
    subscribed: false
};

export default SubscriptionCard;
