export const delayAwait = async (start, minDelay) =>
    new Promise((resolve) => {
        const timePassed = Date.now() - start;
        const timeToWait = minDelay - timePassed;
        if (timeToWait < 0) {
            resolve(true);
        }
        setTimeout(() => {
            resolve(true);
        }, timeToWait);
    });
