import { useSelector } from 'react-redux';

export const useCurrency = ({ isInvoice = false } = {}) => {
    const defaultCurrency = {
        code: 'USD',
        name: 'United States Dollar',
        stripeEnabled: true,
        stripeMin: 50,
        decimalPlaces: 2,
        position: 'before',
        symbol: '$'
    };

    const stateCurrencyObject = useSelector(
        (state) => state.user?.activeBusiness?.localeData?.currency
    );

    const docState = useSelector((state) => state?.makeInvoice?.documentState);

    const invoiceCurrency =
        useSelector(
            (state) => state?.makeInvoice?.localizationSettings?.currency
        ) || false;

    if (isInvoice && invoiceCurrency && docState !== 'draft') {
        return invoiceCurrency;
    }

    return stateCurrencyObject?.code ? stateCurrencyObject : defaultCurrency;
};
