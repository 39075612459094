import React, { memo } from 'react';
import Cell from '@mui/material/TableCell';
import PropTypes from 'prop-types';
import Link from '@/components/common/Link';
import useStyles from './styles';

/**
 * Individual table row for the TableMaker component.
 *
 * Links are set on each cell, rather than on the row - as this is correct
 * semantically.
 * @param {nodes} children Items to be put into the table cell.
 * @param {bool} thin Is the row thinner than default?
 * @param {string} to URI to link the row to.
 */
const TableCell = memo(({ children, thin, to, onClick }) => {
    const classes = useStyles({ thin });
    return (
        <Cell className={classes.cell}>
            {to && (
                <Link to={to} className={classes.link}>
                    {children}
                </Link>
            )}
            {onClick && (
                <button
                    type="button"
                    onClick={onClick}
                    className={classes.button}
                >
                    {children}
                </button>
            )}
            {!to && !onClick && children}
        </Cell>
    );
});

TableCell.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    thin: PropTypes.bool,
    to: PropTypes.string,
    onClick: PropTypes.func
};

TableCell.defaultProps = {
    thin: false,
    to: undefined,
    onClick: undefined
};

export default TableCell;
