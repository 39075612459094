import loadable from '@loadable/component';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';
import PropTypes from 'prop-types';
import { ButtonLight, ZendeskMenuItem } from '@/components/common';
import { useCheckAuthState } from '@/hooks';
import { resetDocument } from '@/state/actions';
import AccountSection from './AccountSection';
import Menu from './Menu';
import NavWrapper from './NavWrapper';
import useStyles from './styles';

const SignUpLoginModal = loadable(() => import('@/components/Login'));
const SubscriptionCounter = loadable(() =>
    import(`@/components/SubscriptionCounter`)
);

const Navbar = ({ open, handleMenuClose }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let subLocation = null;
    if (location.pathname?.includes('items')) {
        subLocation = 'Items';
    } else if (location.pathname?.includes('settings')) {
        subLocation = 'Settings';
    }

    const [toggleActive, setToggleActive] = useState(subLocation);
    const [loginView, setLoginView] = useState('');
    const [loadLoginModal, setLoadLoginModal] = useState(false);
    const classes = useStyles();
    const { registeredUser } = useCheckAuthState();

    const lockedDocument =
        useSelector((state) => state?.makeInvoice?.viewMode) === 'edit';

    useEffect(() => {
        if (loginView && !loadLoginModal) {
            setLoadLoginModal(true);
        }
    }, [loginView, loadLoginModal]);

    const handleClickAwayListener = ({ target }) => {
        // This stops the conflict between burger and ClickAwayListener
        // trying to open/close on the same click.
        if (
            target?.parentNode?.classList &&
            !target?.parentNode?.classList.contains('mn-burger')
        ) {
            handleMenuClose();
        }
    };

    const handleLoginClick = () => {
        setLoginView('login');
        handleMenuClose();
    };

    return (
        <>
            <NavWrapper
                open={open}
                handleMenuClose={handleMenuClose}
                handleClickAwayListener={handleClickAwayListener}
            >
                {!registeredUser && (
                    <ButtonLight
                        className={classes.loginButton}
                        fullWidth
                        onClick={() => handleLoginClick()}
                    >
                        Login
                    </ButtonLight>
                )}
                {registeredUser && (
                    <div
                        data-testid="AccountSectionWrapper"
                        className={classes.accountSectionWrapper}
                    >
                        <AccountSection
                            handleMenuClose={handleMenuClose}
                            setToggleActive={setToggleActive}
                            toggleActive={toggleActive}
                        />
                    </div>
                )}
                <Menu
                    handleMenuClose={handleMenuClose}
                    setToggleActive={setToggleActive}
                    toggleActive={toggleActive}
                />

                <SubscriptionCounter />

                <ZendeskMenuItem handleMenuClose={handleMenuClose} />
            </NavWrapper>
            {loadLoginModal && (
                <SignUpLoginModal
                    setLoginView={setLoginView}
                    view={loginView}
                    onSuccess={(login) => {
                        if (login && lockedDocument) {
                            dispatch(resetDocument());
                            navigate('/');
                        }
                    }}
                />
            )}
        </>
    );
};

Navbar.propTypes = {
    open: PropTypes.bool,
    handleMenuClose: PropTypes.func.isRequired
};

Navbar.defaultProps = {
    open: false
};

export default Navbar;
