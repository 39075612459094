import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { AutocompleteTextField } from '@/components/common';
import { formatCurrency, toWholeCurrency } from '@/util';
import { updateDocumentProduct } from '@/state/actions';
import { emptyProduct } from '@/config/products';

const ItemName = ({ applyFieldToItem, code, item, locale }) => {
    const dispatch = useDispatch();
    const products =
        useSelector((state) => state?.user?.activeBusiness?.products) || [];

    const applySelectedProductToItem = (selectedProduct) => {
        const updatedItem = {
            ...item,
            product: selectedProduct,
            name: selectedProduct.name,
            price: selectedProduct.price,
            description: selectedProduct.description,
            taxes: selectedProduct.taxes || [],
            discounts: selectedProduct.discounts || [],
            productId: selectedProduct.productId,
            quantity: selectedProduct.quantity,
            type: selectedProduct.type
        };

        dispatch(updateDocumentProduct(updatedItem));
    };

    const clearSelectedItem = () => {
        applySelectedProductToItem(emptyProduct);
    };

    const setSingleUseItemName = (value) => {
        applyFieldToItem('name', value);
    };

    const getItemLabel = () => {
        let label = 'Item';
        if (item?.product?.type) {
            label = item.product.type === 'service' ? 'Service' : 'Product';
        }
        return label;
    };

    return (
        <AutocompleteTextField
            label={getItemLabel()}
            id={`line-item-autocomplete-item-field-${item?.lineId}`}
            options={products}
            optionLabels={['name', 'price']}
            onSelect={applySelectedProductToItem}
            onUniqueValueEntered={setSingleUseItemName}
            onClear={clearSelectedItem}
            resultsLimit={10}
            formatSecondaryOptionLabel={(e) =>
                `${formatCurrency({
                    locale,
                    currency: code,
                    amount: toWholeCurrency(e)
                })}`
            }
            onInputChange={setSingleUseItemName}
            inputValue={item?.name || ''}
        />
    );
};

ItemName.propTypes = {
    applyFieldToItem: PropTypes.func.isRequired,
    code: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired
};

export default ItemName;
