import React from 'react';
import { Grid } from '@mui/material';
import { useDrawers } from '@/hooks';
import { SERVICE_BASE_PARAM } from '@/constants';
import { FilterForm, Price, Number, Tags } from '../formComponents';

const ServiceFilterDrawer = () => {
    const { isDrawerActive } = useDrawers();
    const drawerOpen = isDrawerActive('serviceFilter');

    const defaultValues = {
        price: {
            min: '',
            max: ''
        },
        quantity: {
            min: '',
            max: ''
        },
        tags: []
    };

    return (
        <FilterForm
            defaultValues={defaultValues}
            id="FilterServices"
            open={drawerOpen}
            paramBase={SERVICE_BASE_PARAM}
            title="Services"
            type="service"
        >
            <Grid item>
                <Price
                    heading="Service Rate"
                    id="price"
                    maxTitle="Max"
                    minTitle="Min"
                />
                <Number
                    heading="Hours"
                    id="quantity"
                    maxTitle="Max"
                    minTitle="Min"
                />
                <Tags heading="Tags" id="tags" />
            </Grid>
        </FilterForm>
    );
};

export default ServiceFilterDrawer;
