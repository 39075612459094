import { parseISO } from 'date-fns';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { TextField } from '@mui/material';
import defaultUSLocale from 'date-fns/locale/en-US';
import isValid from 'date-fns/isValid';
import { useController } from 'react-hook-form';
import { useLocale, useTimezone } from '@/hooks';
import { formatDate, getLocaleFile } from '@/util';
import { useTheme } from '@mui/styles';

/**
 * This component controlls the MUI DatePicker component with a
 * React Hook Forms Controller reducing complexity.
 *
 * To use
 * ------
 * Make sure that the component is placed in a form which is wrapped in a FormProvider.
 *
 * OnChange
 * --------
 * If you need to use onChange for the input, this can be set up outside of this component
 * using the React Hook Form 'watch' function. Hooking into onChange directly will break
 * the component.
 *
 * @link: https://react-hook-form.com/
 *
 * @param {bool} disabled Should the input be disabled or not.
 * @param {bool} disablePast Should a user be able to select dates from the past or not.
 * @param {bool} disableFuture Should a user be able to select dates from the future or not.
 * @param {string} id The component ID. If ignored will be set by 'name'.
 * @param {string} label The label to be used in the input.
 * @param {string} minDate The earliest date allowed to be selected.
 * @param {string} name The component name. This will be used by React Hook Forms when returning data.
 * @param {props} rest Any other props that you want to pass across to the component.
 */
const GenericDatePicker = ({
    disabled,
    disablePast,
    disableFuture,
    id,
    label,
    minDate,
    name,
    className,
    ...rest
}) => {
    const {
        field: { onChange, value, ref },
        fieldState: { isDirty }
    } = useController({
        name
    });

    const theme = useTheme();
    const locale = useLocale();
    const timezone = useTimezone();

    // Preset with en-US to speed up initial page load.
    const [pickedLocale, setPickedLocale] = useState(defaultUSLocale);
    const localeRef = useRef('en-US');

    // As we don't want to load every single locale under the sun
    // on the initial render of the app set locale to en-US.
    // Then when redux kicks in, load their specific locale.
    // If that loader fails - we can keep en-US.
    useEffect(() => {
        const importLocale = async () => {
            try {
                // This handles setting the locale.
                await getLocaleFile({
                    locale,
                    setPickedLocale,
                    localeRef
                });
            } catch {
                // Nothing
                // No state overwrite happens.
            }
        };
        // If the locale has not yet been loaded.
        if (localeRef.current !== locale) {
            importLocale();
        }
    }, [locale, pickedLocale]);

    const handleOnChange = (newDate) => {
        if (newDate) {
            const newDateISOString = newDate.toISOString();
            onChange(newDateISOString);
        }
    };

    // Display the date correctly. take format from locale and timezone
    const formatSelectLabel = (date, invalidLabel) => {
        // If the input has not been touched by the user, and there
        // is no default value to show. Return empty string.
        if (!isDirty && value === '') {
            return '';
        }

        if (date && isValid(date)) {
            return formatDate({ isoDate: date, locale, timezone });
        }

        return invalidLabel;
    };

    return (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={pickedLocale} // use 'bg' locale for date parser/formatter
        >
            <MobileDatePicker
                {...rest}
                autoOk
                disableToolbar
                disablePast={Boolean(disablePast)}
                disableFuture={Boolean(disableFuture)}
                fullWidth
                variant="inline"
                inputVariant={disabled ? 'standard' : 'filled'}
                disabled={disabled}
                error={disabled ? false : undefined}
                helperText={disabled ? '' : undefined}
                minDate={minDate}
                InputLabelProps={
                    disabled
                        ? {
                              style: {
                                  color: theme.palette.text.disabled,
                                  fontSize: theme.typography.h4.fontSize
                              }
                          }
                        : {}
                }
                InputProps={
                    disabled
                        ? {
                              disableUnderline: true,
                              style: {
                                  color: theme.palette.text.primary,
                                  fontSize: theme.typography.h4.fontSize,
                                  paddingTop: 0
                              }
                          }
                        : {}
                }
                labelFunc={formatSelectLabel}
                label={label}
                value={parseISO(value)}
                onChange={handleOnChange}
                inputRef={ref}
                renderInput={(params) => {
                    let inputError = params.error;
                    if (params.error && value === '') {
                        inputError = false;
                    }
                    return (
                        <TextField
                            {...params}
                            id={id || name}
                            name={name}
                            error={inputError}
                            fullWidth
                            className={className}
                        />
                    );
                }}
            />
        </LocalizationProvider>
    );
};

GenericDatePicker.propTypes = {
    disabled: PropTypes.bool,
    disablePast: PropTypes.bool,
    disableFuture: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    minDate: PropTypes.string,
    name: PropTypes.string.isRequired,
    className: PropTypes.string
};

GenericDatePicker.defaultProps = {
    disabled: false,
    disablePast: false,
    disableFuture: false,
    id: '',
    minDate: undefined,
    className: ''
};

export default GenericDatePicker;
