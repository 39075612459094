import React from 'react';
import { formatCurrency, toWholeCurrency } from '@/util';
import {
    MobileItemTags,
    TableActionsWrap,
    TagsIcon
} from '@/components/common';
import { openDrawer } from '@/state/actions';
import store from '@/state/store';

// Process array of objects into a stripped down version
// that only has required data so is faster to process.
export const dataProcessor = (data) =>
    data.map(({ productId, name, price, quantity, tags }) => ({
        id: productId,
        serviceName: name,
        rate: toWholeCurrency(price),
        hours: quantity,
        tags
    }));

export const generateComponents = ({ array, currency, locale, isMobile }) => {
    const { items } = store.getState().listing;

    const handleClick = (id) => {
        const service = items?.find((item) => item.productId === id);

        if (service) {
            store.dispatch(
                openDrawer({
                    id: 'service',
                    service,
                    edit: true
                })
            );
        }
    };

    return array.map(({ id, serviceName, rate, hours, tags }) => ({
        id,
        serviceName,
        hours,
        rate: formatCurrency({
            currency,
            amount: rate,
            locale
        }),
        tags: isMobile ? (
            <MobileItemTags type="service" itemId={id} tags={tags} />
        ) : (
            <TagsIcon type="service" itemId={id} tags={tags} />
        ),
        actions: serviceName && (
            <TableActionsWrap
                id={id}
                open="editService"
                rest={['deleteService']}
                toPass={{ service: serviceName }}
            />
        ),
        primaryClick: () => handleClick(id)
    }));
};
