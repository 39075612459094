import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    loaderSettings: {
        padding: theme.spacing(2)
    },
    loaderSettingsSkeletonTitle: {
        marginBottom: theme.spacing(3)
    },
    loaderSettingsSkeletonBar: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'block',
            marginBottom: theme.spacing(3)
        }
    }
}));
