import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getDocumentById } from '@/modules/dataWrangler/document';
import { getRecurringInvoiceById } from '@/modules/dataWrangler/recurring-invoices';
import { setGlobalError } from '@/actions';
import { documentStoppedLoading, setDocumentState } from '@/state/actions';
import { validateIdFormat } from '@/util';
import { useCheckAuthState } from './useCheckAuthState';

/**
 * This hook checks the parameters passed in by router location, and if the
 * user is authorized.
 *
 * If no documentId is passed in. Nothing happens.
 * If a documentId is passed in, it checks the ID and trys to return the
 * correct document data. If this fails, a redirect URL is returned.
 *
 * @param  {string} documentType The document type. estimate/invoice/receipt
 * @param  {object} params Params passed from useLocation.
 * @return {object} {documentLoading - bool, redirect - URL to redirect to}
 */
export const useSetIMPageType = ({ documentType, params }) => {
    const documentId = params?.documentId || false;
    const { loadingUser, loggedInUser } = useCheckAuthState();
    const dispatch = useDispatch();

    const [documentLoading, setLoading] = useState(loadingUser || documentId);
    const [redirect, setRedirect] = useState(false);
    const documentTypeLabel =
        documentType === 'recurring-invoice'
            ? 'recurring invoice'
            : documentType || 'document';

    useEffect(() => {
        if (documentId && !loadingUser && loggedInUser) {
            if (validateIdFormat(documentId)) {
                // Put request in async function to allow state to
                // flow through component.
                const getCurrentDocument = async () => {
                    try {
                        let currentDocument = null;

                        if (documentType === 'recurring-invoice') {
                            currentDocument = await getRecurringInvoiceById(
                                documentId
                            );
                        } else {
                            currentDocument = await getDocumentById(documentId);
                        }

                        // If no document was returned, or the document does not
                        // match the type requested then redirect (if type requested).
                        if (
                            !currentDocument ||
                            (documentType &&
                                currentDocument?.documentType !==
                                    documentType &&
                                documentType !== 'recurring-invoice')
                        ) {
                            dispatch(
                                setGlobalError({
                                    message: `We cannot find the ${documentTypeLabel} you are looking for.`
                                })
                            );

                            // If a document type was set, redirect to specific
                            // document listing page. Otherwise if no type was set,
                            // e.g. preview page - redirect to app home.
                            // This covers the small use case of a bookmarked
                            // preview link on a logged out account.
                            if (documentType) {
                                setRedirect(`/${documentType}s`);
                            } else {
                                setRedirect('/');
                            }
                        }

                        if (currentDocument) {
                            let locked = false;

                            if (currentDocument?.documentState !== 'draft') {
                                locked = true;
                            }

                            dispatch(
                                setDocumentState({
                                    document: currentDocument,
                                    locked
                                })
                            );
                        }

                        setLoading(false);
                        return true;
                    } catch {
                        // If the getDocumentById function throws an error, this is likely due to a user
                        // logging into an account mid-flow, causing permissions issues. In this case
                        // we return false without updating state.
                        dispatch(documentStoppedLoading());
                        setLoading(false);
                        return false;
                    }
                };
                getCurrentDocument();
            } else {
                dispatch(
                    setGlobalError({
                        message: `We cannot find the ${documentTypeLabel} you are looking for.`
                    })
                );
                setLoading(false);
                if (documentType) {
                    setRedirect(`/${documentType}s`);
                } else {
                    setRedirect('/');
                }
            }
        }
    }, [
        loadingUser,
        loggedInUser,
        documentId,
        documentType,
        documentTypeLabel,
        dispatch
    ]);

    return { documentLoading: Boolean(documentLoading), redirect };
};
