export const BILLING_INFO_VERIFYING = 'BILLING_INFO_VERIFYING';
export const BILLING_INFO_UPDATE_ERROR = 'BILLING_INFO_UPDATE_ERROR';
export const BILLING_INFO_UPDATE_SUCCESS = 'BILLING_INFO_UPDATE_SUCCESS';

export const PAYMENT_INTENT = 'PAYMENT_INTENT';
export const PAYMENT_INPUT = 'PAYMENT_INPUT';
export const PAYMENT_PENDING = 'PAYMENT_PENDING';
export const PAYMENT_PROCESSING = 'PAYMENT_PROCESSING';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
