import PropTypes from 'prop-types';
import React from 'react';
import { Grid } from '@mui/material';
import Tooltip from '@/components/common/Tooltip';
import useStyles from './styles';

const InnerComponent = ({ children }) => {
    const classes = useStyles();
    return (
        <Grid container direction="column">
            <span className={classes.span}>{children}</span>
        </Grid>
    );
};

InnerComponent.propTypes = {
    children: PropTypes.node.isRequired
};

const DisabledInputField = ({ children, tooltip }) => {
    if (!tooltip) return <InnerComponent>{children}</InnerComponent>;

    return (
        <Tooltip title={tooltip} placement="bottom" trueChildren>
            <InnerComponent>{children}</InnerComponent>
        </Tooltip>
    );
};

DisabledInputField.propTypes = {
    children: PropTypes.node.isRequired,
    tooltip: PropTypes.string
};

DisabledInputField.defaultProps = {
    tooltip: ''
};

export default DisabledInputField;
