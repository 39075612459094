import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    wrap: {
        display: 'flex',
        position: 'sticky',
        zIndex: 999,
        backgroundColor: theme.palette.common.white,
        padding: `${theme.typography.pxToRem(25)}
                0
                ${theme.typography.pxToRem(10)}
                ${theme.typography.pxToRem(13)}`,
        [theme.breakpoints.only('xs')]: {
            marginTop: 'calc(env(safe-area-inset-top) * -1)',
            paddingTop: `calc(env(safe-area-inset-top) + ${theme.typography.pxToRem(
                25
            )})`
        },
        top: 0,
        margin: `0 ${theme.typography.pxToRem(-15)}`,
        boxShadow: '0px 5px 5px -5px #DEDEE7'
    },
    icon: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginRight: theme.typography.pxToRem(10),
        width: theme.typography.pxToRem(24),
        '& svg': {
            width: '100%'
        }
    }
}));
