/* eslint import/no-cycle: "off"  */
import store from '@/state/store';

import { globalPageCount } from '@/config';

import {
    overwriteDocument,
    setDocumentState,
    setVoidDocumentPending,
    setVoidDocumentComplete,
    fetchListingItems,
    overwriteListingItems,
    noListingItems
} from '@/state/actions';

import {
    convertDocumentFromApiSetLocalization,
    createSaveableObject,
    filterArgFlattener
} from '@/util';

import * as subscription from '../subscriptionManager';
import axiosAuthenticated from '../authenticatedRequestor';
import * as segmentManager from '../segment/segmentManager';

export const createReceipt = async (receipt) => {
    const { activeBusiness } = store.getState().user;
    const tagIds = receipt?.tags?.map((tag) => tag.tagId) || [];

    try {
        const response = await axiosAuthenticated.post(
            `/businesses/${activeBusiness.businessId}/receipts`,
            {
                ...receipt,
                tagIds,
                design: activeBusiness.documentCustomizations.design,
                disablePaymentStamp:
                    activeBusiness.documentCustomizations.disablePaymentStamp,
                logo: activeBusiness.logo || ''
            }
        );

        // Update the subscription object with new receipt count.
        subscription.getUserSubscription(activeBusiness.businessId);

        const newReceipt = convertDocumentFromApiSetLocalization(response.data);
        return newReceipt;
    } catch {
        return false;
    }
};

export const duplicateReceipt = async (receiptId) => {
    // Get the active business.
    const { activeBusiness } = store.getState().user;
    const { businessId } = activeBusiness;

    // Get the specific receipt to be duplicated.
    const res = await axiosAuthenticated.get(
        `/businesses/${businessId}/documents/${receiptId}`
    );

    const foundReceipt = res?.data;

    if (foundReceipt) {
        const convertedReceipt =
            convertDocumentFromApiSetLocalization(foundReceipt);
        const receipt = { ...convertedReceipt };

        const originalIssuedDate = new Date(receipt.issuedDate).getTime();
        const originalDueDate = new Date(receipt.dueDate).getTime();

        // Calculate the number of milliseconds between issued date and due date.
        const originalTermsMilliseconds = originalDueDate - originalIssuedDate;

        // Update issued date
        receipt.issuedDate = new Date(Date.now()).toISOString();

        // Update due date with correct term gap.
        receipt.dueDate = new Date(
            Date.now() + originalTermsMilliseconds
        ).toISOString();

        // Remove custom label to allow for a new one
        receipt.customLabel = undefined;

        // Pass the new receipt object to API.
        const newReceipt = await createReceipt(
            createSaveableObject({
                type: 'receipt',
                currentObject: receipt,
                activeBusiness
            })
        );

        await segmentManager.documentStarted('receipt');

        return newReceipt;
    }

    return false;
};

export const shareReceipt = async (
    documentId,
    mode,
    email,
    emailSubject,
    emailBody
) => {
    const { businessId } = store.getState().user.activeBusiness;

    try {
        const response = await axiosAuthenticated.post(
            `/businesses/${businessId}/receipts/${documentId}/sharing`,
            {
                action: mode,
                emailAddress: email,
                subject: emailSubject,
                body: emailBody
            }
        );

        const newReceipt = convertDocumentFromApiSetLocalization(response.data);

        store.dispatch(
            overwriteDocument({
                viewMode: 'edit',
                ...newReceipt
            })
        );

        return response.data;
    } catch (err) {
        return err.response;
    }
};

export const getReceipts = async (args) => {
    if (store.getState().user.temporaryUser) {
        store.dispatch(noListingItems({ type: 'receipt' }));
        return;
    }

    store.dispatch(fetchListingItems({ type: 'receipt' }));

    const { businessId } = store.getState().user.activeBusiness;

    const allArgs = { pageSize: globalPageCount, ...filterArgFlattener(args) };

    const response = await axiosAuthenticated.get(
        `/businesses/${businessId}/receipts`,
        { params: { sortOrder: 'desc', sortType: 'createdAt', ...allArgs } }
    );

    const { items, itemsMatchingRequest, pageSize, totalItems } = response.data;

    // Destructure into flatter object
    const listingItems = items.map((receipt) =>
        convertDocumentFromApiSetLocalization(receipt)
    );

    const totalPages = Math.ceil(itemsMatchingRequest / pageSize);

    store.dispatch(
        overwriteListingItems({
            type: 'receipt',
            items: listingItems,
            totalPages,
            totalItems
        })
    );
};

export const getReceiptById = async (documentId) => {
    if (store.getState().user.temporaryUser) {
        return false;
    }

    const { businessId } = store.getState().user?.activeBusiness;

    const res = await axiosAuthenticated.get(
        `/businesses/${businessId}/receipts/${documentId}`
    );

    const { documentState } = res.data;

    if (documentState === 'deleted') {
        // eslint-disable-next-line no-throw-literal
        throw 'receipt has been deleted';
    }

    const newReceipt = convertDocumentFromApiSetLocalization(res.data);

    return newReceipt;
};

export const updateReceipt = async (receipt) => {
    const { businessId } = store.getState().user.activeBusiness;

    await Promise.all([
        axiosAuthenticated.put(
            `/businesses/${businessId}/receipts/${receipt.documentId}`,
            receipt
        )
    ]);
    return true;
};

export const deleteReceipt = async (documentId) => {
    const { businessId } = store.getState().user.activeBusiness;

    await axiosAuthenticated.delete(
        `/businesses/${businessId}/receipts/${documentId}`
    );
};

export const voidReceipt = async (receipt) => {
    const { businessId } = store.getState().user.activeBusiness;

    store.dispatch(setVoidDocumentPending());

    await axiosAuthenticated.post(
        `/businesses/${businessId}/receipts/${receipt.documentId}/void`
    );

    const voidedReceipt = {
        ...receipt,
        documentState: 'void'
    };
    store.dispatch(setDocumentState({ document: voidedReceipt }));
    store.dispatch(setVoidDocumentComplete());
};

/**
 * Get receipts CSV
 * @param  {object} args Filter arguments.
 */
export const downloadReceiptsCsv = async (args) => {
    const { businessId } = store.getState().user.activeBusiness;

    try {
        const response = await axiosAuthenticated.get(
            `/businesses/${businessId}/receipts/export`,
            {
                params: filterArgFlattener(args)
            }
        );

        return response.data;
    } catch (err) {
        return false;
    }
};
