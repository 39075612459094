import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    form: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: 'calc(100% - 60px)'
    },
    topDivider: {
        marginLeft: -25,
        marginRight: -25,
        paddingBottom: '0 !important'
    },
    itemList: {
        height: '100%',
        marginTop: 10
    },
    selectClientContainer: {
        paddingBottom: 0
    },
    line: {
        height: 2,
        backgroundColor: '#E9EBF1',
        marginTop: theme.typography.pxToRem(12)
    },
    lineContainer: {
        paddingTop: 0,
        paddingBottom: 0
    },
    editButton: {
        width: '100%',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #000000',
        color: theme.palette.common.black,
        '&:hover': {
            backgroundColor: 'rgba(0,0,0, .08)',
            color: theme.palette.common.black
        }
    },
    saveButton: {
        padding: theme.typography.pxToRem(20)
    },
    cancelButton: {
        backgroundColor: theme.palette.common.white,
        border: '1px solid #000000',
        color: theme.palette.common.black,
        '&:hover': {
            backgroundColor: 'rgba(0,0,0, .08)',
            color: theme.palette.common.black
        }
    }
}));
