/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

export const drawerSlice = createSlice({
    name: 'drawer',
    initialState,
    reducers: {
        closeAllDrawers: () => initialState,
        // Action to close a single drawer. If an id is passed, that drawer will be closed.
        // If no id is passed, the last drawer off the stack will be closed.
        closeDrawer: (state, action) => {
            const drawers = [...state];

            if (action.payload) {
                // Close specific drawer.
                return drawers.filter(({ id }) => id !== action.payload);
            }

            // Close the last drawer off the stack.
            drawers.shift();
            return drawers;
        },
        // Uses first in, last out approach.
        // This allows future proofing of stacking drawers.
        openDrawer: (state, action) => {
            const drawers = [...state];

            const currentDrawer = action.payload;

            // Allows passing in id directly rather than in an object if options are not needed
            const currentDrawerId = currentDrawer?.id || currentDrawer;

            const drawerIsOpen = drawers.find(
                ({ id }) => id === currentDrawerId
            );

            if (!drawerIsOpen) {
                // Add drawer to top of stack.
                return [
                    currentDrawer?.id ? currentDrawer : { id: currentDrawer },
                    ...drawers
                ];
            }

            if (drawers.length > 1) {
                // Move drawer to top of stack if there are other drawers open.
                return [
                    drawers.find(({ id }) => id === currentDrawerId),
                    ...drawers.filter(({ id }) => id !== currentDrawerId)
                ];
            }

            // If drawer is already the only drawer open, do nothing.
            return drawers;
        },
        updateDrawer: (state, action) => {
            const drawers = [...state];

            const updatedDrawerData = action.payload;

            const openDrawerIndex = drawers.findIndex(
                ({ id }) => id === updatedDrawerData?.id
            );

            if (openDrawerIndex >= 0) {
                drawers.splice(openDrawerIndex, 1);
                return [updatedDrawerData, ...drawers];
            }

            return drawers;
        }
    }
});

export const { closeAllDrawers, closeDrawer, openDrawer, updateDrawer } =
    drawerSlice.actions;

export default drawerSlice.reducer;
