import { useState, useEffect } from 'react';
import { useCheckHasBusinessLoaded } from './useCheckHasBusinessLoaded';

/**
 * @param {bool} pageDoneLoading Boolean that represents if page data is done loading
 * @returns {object} Object contains isLoading (for the loading animation) and isLoadingSkeleton (for the skeletons)
 */
export const useSetPageLoad = (pageDoneLoading) => {
    // Is the user still loading, and are they brand new.
    const businessHasLoaded = useCheckHasBusinessLoaded();

    const [isLoading, setLoading] = useState(
        pageDoneLoading === false || !businessHasLoaded
    );

    const [isLoadingSkeleton, setLoadingSkeleton] = useState(
        !businessHasLoaded
    );

    useEffect(() => {
        let timeout;
        if (pageDoneLoading && businessHasLoaded) {
            timeout = setTimeout(() => {
                setLoading(false);
                setLoadingSkeleton(false);
            }, 1500);
        }
        return () => clearTimeout(timeout);
    }, [pageDoneLoading, businessHasLoaded, setLoading, setLoadingSkeleton]);

    return { isLoading, isLoadingSkeleton };
};
