import React from 'react';
import PropTypes from 'prop-types';
import { Button, Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import { useCheckAuthState, useDocumentFormData } from '@/hooks';

import useStyles from '../../styles';

const RecurringInvoiceStop = ({ onClick }) => {
    const classes = useStyles();
    const { loadingUser } = useCheckAuthState();
    const { documentLoading } = useDocumentFormData();

    const documentState = useSelector(
        (state) => state?.makeInvoice?.documentState
    );
    const documentId = useSelector((state) => state?.makeInvoice?.documentId);

    const isActive = documentState === 'active' && Boolean(documentId);

    const loadingButton = loadingUser || documentLoading;

    if (isActive) {
        return (
            <div className={classes.section}>
                {!loadingButton ? (
                    <Button
                        fullWidth
                        className={`${classes.button} ${classes.negativeButton}`}
                        variant="outlined"
                        size="large"
                        onClick={onClick}
                    >
                        Stop
                    </Button>
                ) : (
                    <Skeleton variant="rectangular" className={classes.sizer} />
                )}
            </div>
        );
    }

    return null;
};

RecurringInvoiceStop.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default RecurringInvoiceStop;
