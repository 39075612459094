// Data to set the head of the table.
const tableHead = [
    {
        id: 'name',
        label: 'PRODUCT NAME',
        sort: true
    },
    { id: 'description', label: 'DESCRIPTION' },
    {
        id: 'price',
        label: 'PRICE',
        sort: true
    },
    {
        id: 'tags',
        label: 'TAGS'
    },
    {
        id: 'actions',
        label: '',
        colSpan: 2
    }
];

export default tableHead;
