import makeStyles from '@mui/styles/makeStyles';

const makeSafeMargin = (theme, position) =>
    `calc(env(safe-area-inset-${position}) + ${theme.typography.pxToRem(10)})`;

export default makeStyles((theme) => ({
    paper: {
        backgroundColor: '#F8FBFD',
        width: `calc(100% - ((env(safe-area-inset-left) * 2) + ${theme.typography.pxToRem(
            20
        )}))`,
        maxWidth: theme.typography.pxToRem(840),
        alignItems: 'center',
        margin: `${makeSafeMargin(theme, 'top')} ${makeSafeMargin(
            theme,
            'right'
        )} ${makeSafeMargin(theme, 'bottom')} ${makeSafeMargin(theme, 'left')}`,
        [theme.breakpoints.down('md')]: {
            maxHeight: 'calc(100% - 20px)'
        }
    },
    muiContainer: {
        display: 'block',
        [theme.breakpoints.up('sm')]: {
            display: 'flex'
        }
    },
    mainWrap: {
        padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
            10
        )} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(10)}`,
        width: '100%',
        maxWidth: theme.typography.pxToRem(500),
        [theme.breakpoints.up('sm')]: {
            padding: `${theme.typography.pxToRem(
                20
            )} ${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(
                25
            )} ${theme.typography.pxToRem(20)}`
        },
        [theme.breakpoints.up('md')]: {
            border: `1px solid #DBDFEA`,
            borderRadius: theme.typography.pxToRem(6),
            margin: `${theme.typography.pxToRem(60)} ${theme.typography.pxToRem(
                70
            )} ${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(70)}`
        }
    },
    heading: {
        margin: theme.spacing(0, 0, 1, 0)
    },
    subHeading: {
        color: '#33335C',
        fontWeight: 500,
        margin: theme.spacing(1, 0),
        textTransform: 'uppercase'
    },
    form: {
        backgroundColor: '#fff',
        border: `1px solid #E4E7EF`,
        boxShadow: '0px 2px 3px 0px rgba(223,229,238,1)',
        borderRadius: theme.typography.pxToRem(6),
        overflow: 'hidden'
    },
    input: {
        backgroundColor: 'transparent',
        borderRadius: 0
    },
    splitWrap: {
        display: 'block',
        [theme.breakpoints.up('sm')]: {
            display: 'flex'
        }
    },
    splitInput: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '50%'
        }
    },
    stripeWrap: {
        padding: `${theme.typography.pxToRem(15)} ${theme.typography.pxToRem(
            20
        )}`
    },
    cardError: {
        margin: `${theme.typography.pxToRem(5)} 0`,
        color: theme.palette.error.main
    },
    button: {
        margin: theme.spacing(3, 0, 0, 0)
    },
    footer: {
        textAlign: 'center',
        marginBottom: theme.spacing(3)
    },
    link: {
        color: '#AFAFAF',
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 400,
        textDecoration: 'none',
        padding: `${theme.typography.pxToRem(5)} ${theme.typography.pxToRem(
            2
        )}`,
        margin: `0 ${theme.typography.pxToRem(5)}`,
        '&:hover, &:focus': {
            opacity: '0.8'
        }
    },
    linkSplit: {
        backgroundColor: '#E8E8E8',
        display: 'inline-block',
        width: 1,
        height: theme.typography.pxToRem(20),
        margin: `0 ${theme.typography.pxToRem(5)} ${theme.typography.pxToRem(
            -5
        )} ${theme.typography.pxToRem(5)}`
    },
    stripeLogo: {
        display: 'inline-block',
        height: theme.typography.pxToRem(20),
        margin: `0 0 ${theme.typography.pxToRem(-5)} ${theme.typography.pxToRem(
            5
        )}`,
        width: theme.typography.pxToRem(50)
    }
}));
