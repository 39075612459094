import makeStyles from '@mui/styles/makeStyles';

const colorOptions = {
    active: 'positive',
    draft: 'draft',
    paid: 'positive',
    unpaid: 'negative',
    declined: 'negative',
    void: 'void',
    send: 'send',
    approved: 'positive',
    unapproved: 'unapproved',
    stopped: 'negative',
    complete: 'void'
};

const matchStatusToColor = (status, theme) =>
    theme.palette.actionColors[colorOptions[status]];

const setMargin = (inline, padding, pxToRem) => {
    if (inline) {
        return 0;
    }
    if (padding) {
        return `${pxToRem(10)} 0`;
    }
    return `${pxToRem(-6)} 0`;
};

export default makeStyles((theme) => ({
    span: ({ padding, status, inline, removeFill }) => ({
        color: removeFill ? matchStatusToColor(status, theme) : '#fff',
        backgroundColor: removeFill
            ? '#fff'
            : matchStatusToColor(status, theme),
        borderRadius: theme.typography.pxToRem(4),
        display: inline ? 'inline' : 'block',
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 300,
        padding: `${theme.typography.pxToRem(5)} 0`,
        width: theme.typography.pxToRem(100),
        textAlign: 'center',
        textTransform: 'capitalize',
        margin: setMargin(inline, padding, theme.typography.pxToRem),
        border: removeFill
            ? `1px solid ${matchStatusToColor(status, theme)}`
            : '#fff'
    })
}));
