import { SET_COUPON_CODE } from '@/constants';

export default (state = {}, action) => {
    switch (action.type) {
        case SET_COUPON_CODE: {
            return action.couponData;
        }
        default: {
            return state;
        }
    }
};
