// Data to set the head of the table.
const tableHead = [
    {
        id: 'customLabel',
        label: 'INVOICE #',
        sort: true
    },
    { id: 'dueDate', label: 'DUE DATE', sort: true },
    {
        id: 'client',
        label: 'CLIENT',
        sort: true
    },
    {
        id: 'total',
        label: 'TOTAL',
        sort: true
    },
    {
        id: 'balanceDue',
        label: 'AMOUNT DUE',
        sort: true
    },
    {
        id: 'documentState',
        label: 'STATUS'
    },
    {
        id: 'tags',
        label: 'TAGS'
    },
    {
        id: 'actions',
        label: ''
    }
];

export default tableHead;
