import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

/**
 * Wrapper for main page of the app. Allows simple drop in loading animations
 * entirely based off props.
 * @param {node} children The inside of the page, what you want to show after load.
 * @param {bool} loading Is the page loading?
 * @param {node} loadingComponent Optional custom loading component.
 */
const Container = ({ children }) => {
    const classes = useStyles();

    return <div className={classes.wrapper}>{children}</div>;
};

Container.propTypes = {
    children: PropTypes.node.isRequired
};

export default Container;
