import React from 'react';
import ReactDOM from 'react-dom/client';

import { CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { ToastProvider } from '@/hooks';
import Toaster from '@/components/common/Toaster';
import { BrowserRouter } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import {
    activateSharedSessionId,
    initCustomerIo,
    initHotjar,
    initSentry
} from './util';
import theme from './theme';
import App from './App';
import store from './state/store';
import * as serviceWorker from './serviceWorker';
import RootErrorDialog from './components/RootErrorDialog';

import './index.css';

initSentry();
initHotjar();
activateSharedSessionId();
initCustomerIo();

TagManager.initialize({
    gtmId: 'GTM-P8GPBS2',
    preview: process.env.REACT_APP_GTM_PREVIEW,
    auth: process.env.REACT_APP_GTM_AUTH
});

// New GTM manager. Has new fancy event tracking.
ReactGA.initialize('G-3Q577LX99M');

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <ToastProvider>
                    <Toaster />
                    <CssBaseline />
                    <BrowserRouter>
                        <CompatRouter>
                            <RootErrorDialog />
                            <App />
                        </CompatRouter>
                    </BrowserRouter>
                </ToastProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
