import PropTypes from 'prop-types';
import * as yup from 'yup';
import set from 'lodash/set';

export const createUsablePath = (err, formObj) => {
    let productIndex;
    const isTaxOrDiscountError = /taxes|discounts/g.test(err.path);
    const hasNameKey = err.path.includes('name');

    // Create useable path with correct product and item Ids
    const path = err.path.replace(/[0-9]|\[|\]/g, (match) => {
        if (match === '[') {
            return '.';
        }
        if (match === ']') {
            return '';
        }

        if (!isTaxOrDiscountError) {
            return match;
        }

        const errType = err.path.includes('taxes') ? 'taxes' : 'discounts';

        const idLabel = errType === 'taxes' ? 'taxId' : 'discountId';

        if (!productIndex) {
            productIndex = match;
            return formObj.products[productIndex]?.lineId;
        }

        return formObj.products[productIndex][errType][match]?.[idLabel];
    });

    return isTaxOrDiscountError && !hasNameKey ? path.concat('.name') : path;
};

const dateInFutureOrToday = (date) => {
    if (date) {
        return new Date(date) >= new Date().setHours(0, 0, 0, 0);
    }

    return true;
};

const dateAfterDate = (date, dateToCompare) => {
    if (date) {
        return new Date(date) > new Date(dateToCompare);
    }

    return true;
};

const mustBeOneOrMore = (value) => {
    if (value) {
        return parseInt(value, 10) > 0;
    }

    return false;
};

/**
 * Takes the invoice maker object to be passed to the API, checks all
 * required data has been added.
 * @param  {object} formObj The data object to be passed to API.
 * @return {object} success: true/false, errors: object of errors.
 */
export const verifyDocumentFields = async (formObj) => {
    /**
     * yup is used to validate the invoice object.
     * @link https://github.com/jquense/yup
     */

    const testUnique = (item, label, idLabel) => {
        for (let i = 0; i < formObj.products?.length; i += 1) {
            const foundDuplicate = formObj.products[i][label]?.find(
                (searchItem) =>
                    item.name &&
                    searchItem.name === item.name &&
                    searchItem[idLabel] !== item[idLabel]
            );

            const productContainsItem = formObj.products[i][label]?.find(
                (searchItem) => searchItem[idLabel] === item[idLabel]
            );

            // If the item and its duplicate are in the same product, return false, creating an error
            if (foundDuplicate && productContainsItem) return false;
        }
        return true;
    };

    const isRecurring = formObj.documentType === 'recurring-invoice';

    const schemaInners = {
        to: yup.object().shape({
            clientId: yup.string().required('Client is required'),
            emailAddress: isRecurring
                ? yup.string().required('Client email is required')
                : yup.string().nullable().default(null)
        }),
        from: yup.object().shape({
            companyName: yup.string().required('Company is required')
        }),
        products: yup.array().of(
            yup.object().shape({
                discounts: yup.array().of(
                    yup
                        .object()
                        .shape({
                            name: yup
                                .string()
                                .required('A discount name is required')
                        })
                        .test(
                            'unique',
                            'Discount name must be unique',
                            (discount) =>
                                testUnique(discount, 'discounts', 'discountId')
                        )
                ),
                taxes: yup.array().of(
                    yup
                        .object()
                        .shape({
                            name: yup
                                .string()
                                .required('A tax name is required')
                        })
                        .test('unique', 'Tax name must be unique', (tax) =>
                            testUnique(tax, 'taxes', 'taxId')
                        )
                )
            })
        )
    };

    if (isRecurring) {
        schemaInners.firstSendDate = yup
            .string()
            .required('Required')
            .test(
                'future',
                'Start date must be today or in the future',
                (date) => dateInFutureOrToday(date)
            );
        schemaInners.lastSendDate = yup
            .string()
            .nullable()
            .default(null)
            .test('dateAfterDay', 'End date must be after Start date', (date) =>
                dateAfterDate(date, formObj.firstSendDate)
            );
        schemaInners.recurringInterval = yup
            .string()
            .required('Repeats unit is required')
            .test('intervalNeedUnit', 'Interval must be 1 or more', (value) =>
                mustBeOneOrMore(value)
            );
        schemaInners.recurringIntervalUnit = yup
            .string()
            .required('Repeats type is required');
        schemaInners.termInterval = yup
            .string()
            .required('Terms unit is required')
            .test('termNeedUnit', 'Term must be 1 or more', (value) =>
                mustBeOneOrMore(value)
            );
        schemaInners.termIntervalUnit = yup
            .string()
            .required('Terms type is required');
    }

    const schema = yup.object().shape(schemaInners);

    /**
     * Check if invoice object is validated, or if we need to clean up
     * the errors returned by yup.
     */
    try {
        await schema.validate(formObj, {
            abortEarly: false
        });
        return { success: true, errors: {} };
    } catch ({ inner }) {
        // Turn yup errors to usable object
        const errors = {};
        inner.forEach((err) => {
            const path = createUsablePath(err, formObj);
            set(errors, path, err.message);
        });

        return { success: false, errors };
    }
};

verifyDocumentFields.propTypes = {
    formObj: PropTypes.object.isRequired
};
