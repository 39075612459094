import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { WarningIcon } from '@/resources/icons';
import makeStyles from './styles';

const FailedPaymentReason = ({ reason, hasLast4 }) => {
    const classes = makeStyles(hasLast4);

    return (
        <div className={classes.failedPaymentReason}>
            <WarningIcon className={classes.warningIcon} />
            <Typography variant="h4" component="span">
                {reason}
            </Typography>
        </div>
    );
};

FailedPaymentReason.propTypes = {
    reason: PropTypes.string.isRequired,
    hasLast4: PropTypes.bool
};

FailedPaymentReason.defaultProps = {
    hasLast4: false
};

export default FailedPaymentReason;
