import makeStyles from '@mui/styles/makeStyles';

const styledBy = (property, mapping) => () => mapping[property];

export default makeStyles((theme) => ({
    button: ({ action, listOnTablet, buttonDisabled }) => ({
        height: theme.typography.pxToRem(56),
        justifyContent: 'left',
        '&:hover > div': {
            backgroundColor: buttonDisabled
                ? undefined
                : styledBy(action, {
                      send: theme.palette.actionColors.send,
                      archive: theme.palette.actionColors.archive,
                      download: theme.palette.actionColors.download,
                      duplicate: theme.palette.actionColors.duplicate,
                      edit: theme.palette.actionColors.edit,
                      view: theme.palette.actionColors.preview,
                      delete: theme.palette.actionColors.negative,
                      convert: theme.palette.actionColors.positive,
                      tags: theme.palette.actionColors.tags
                  })
        },
        margin: action === 'tags' && '0 20px',
        '&:hover > div svg': action !== 'tags' && {
            filter: 'brightness(200%)'
        },
        [theme.breakpoints.only('xl')]: action === 'convert' && {
            '&:hover > div path:nth-child(3)': {
                fill: theme.palette.actionColors.positive,
                filter: 'brightness(60%)'
            },
            '&:hover > div path:last-child': {
                fill: theme.palette.actionColors.positive,
                filter: 'brightness(60%)'
            },
            '&:hover > div circle': {
                stroke: theme.palette.actionColors.positive,
                filter: 'brightness(60%)',
                fill: theme.palette.background.paper
            }
        },
        [theme.breakpoints.between('md', 'xl')]: {
            transition: 'background-color 0.3s ease',
            filter: listOnTablet && buttonDisabled && 'opacity(50%)',
            backgroundColor: listOnTablet && theme.palette.common.white,
            '&:hover': {
                backgroundColor: listOnTablet && '#F5F5FA'
            },
            '&:hover > div': {
                backgroundColor: listOnTablet && 'transparent !important'
            },
            '&:hover > div svg': {
                filter: listOnTablet && 'brightness(100%) !important'
            },
            padding:
                listOnTablet &&
                `0 ${theme.typography.pxToRem(30)} 0 ${theme.typography.pxToRem(
                    10
                )}`
        }
    }),
    buttonDisabled: ({ action }) => ({
        filter: 'opacity(50%)',
        [theme.breakpoints.up('lg')]: action === 'convert' && {
            '&:hover > div path:nth-child(3)': {
                fill: theme.palette.background.paper,
                filter: 'none'
            },
            '&:hover > div path:last-child': {
                fill: theme.palette.background.paper,
                filter: 'none'
            },
            '&:hover > div circle': {
                stroke: theme.palette.background.paper,
                filter: 'none',
                fill: theme.palette.common.detailGray
            }
        },
        '&:hover > div svg': {
            filter: 'none'
        }
    }),
    icon: ({ listOnTablet }) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40px',
        height: '40px',
        backgroundColor: theme.palette.common.white,
        borderRadius: '50%',
        transition: 'background-color 0.3s ease',
        [theme.breakpoints.between('md', 'xl')]: {
            marginRight: listOnTablet && theme.typography.pxToRem(10)
        }
    }),
    tagButton: ({ listOnTablet }) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '40px',
        backgroundColor: theme.palette.common.white,
        borderRadius: 24,
        border: '1px solid #E0E5EE',
        padding: '0 15px',
        transition: 'background-color 0.3s ease',
        [theme.breakpoints.between('md', 'xl')]: {
            marginRight: listOnTablet && theme.typography.pxToRem(10)
        }
    }),
    editSvg: {
        '& path': {
            fill: '#85949F'
        }
    },
    sendSvg: {
        width: 20,
        height: 16,
        fill: '#85949F',
        '& path': {
            transform: 'translate(2px)'
        },
        [theme.breakpoints.down('md')]: {
            fill: theme.palette.common.white
        }
    },
    previewSvg: {
        width: 20,
        height: 13,
        fill: '#85949F'
    },
    convertSvg: {
        width: 20,
        height: 16
    },
    deleteSvg: {
        '& path': {
            [theme.breakpoints.down('md')]: {
                fill: theme.palette.common.white
            }
        }
    },
    tagSvg: {
        width: 16,
        height: 16
    },
    label: ({ action, listOnTablet }) => ({
        display: 'block',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 600,
        textAlign: 'center',
        [theme.breakpoints.between('xs', 'md')]: {
            color:
                action === 'delete'
                    ? theme.palette.actionColors.negative
                    : theme.palette.primary.main,
            marginTop: theme.typography.pxToRem(10)
        },
        [theme.breakpoints.between('md', 'xl')]:
            !listOnTablet && theme.visuallyHidden,
        [theme.breakpoints.up('xl')]: theme.visuallyHidden
    }),
    mobileButton: {
        height: 32,
        width: '100%',
        transition: 'background-color 0.3s ease',
        backgroundColor: ({ action }) => {
            switch (action) {
                case 'send':
                    return theme.palette.actionColors.send;
                case 'delete':
                    return theme.palette.actionColors.negative;
                default:
                    return theme.palette.secondary.main;
            }
        },
        border: ({ action }) =>
            action === 'send' || action === 'delete'
                ? 'none'
                : '1px solid #E0E5EE',
        color: ({ action }) =>
            action === 'delete' || action === 'send'
                ? theme.palette.secondary.main
                : 'inherit',
        '&:hover': {
            backgroundColor: ({ action }) => {
                switch (action) {
                    case 'send':
                        return '#255583';
                    case 'delete':
                        return '#7B0000';
                    default:
                        return 'rgba(0,0,0, .08)';
                }
            }
        }
    },
    mobileButtonText: ({ action }) => ({
        fontWeight: 700,
        color: action === 'delete' ? theme.palette.common.white : 'inherit'
    }),
    mobileIcon: {
        width: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        marginLeft: 6
    },
    count: {
        marginLeft: 8,
        fontSize: 17,
        width: 'auto',
        whiteSpace: 'nowrap',
        fontWeight: 600
    },
    tagTooltip: {
        backgroundColor: '#fff',
        padding: theme.spacing(1.2),
        boxShadow:
            '0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1)'
    },
    tooltipPopper: {
        '& > div': {
            marginBottom: '0 !important'
        }
    }
}));
