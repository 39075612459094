import { useSelector } from 'react-redux';
import { useCheckAuthState } from './useCheckAuthState';

/**
 * Checks if a business needs to be loaded and has loaded. Or if we can
 * more forwards without needing to load a business if the user is
 * brand new.
 * @returns loaded {bool} The business has loaded, or does not need to load.
 */
export const useCheckHasBusinessLoaded = () => {
    // Is the user logged in?
    // Is the user still loading? and are they brand new?
    const { loggedInUser, loadingUser, brandNewUser } = useCheckAuthState();

    // Get the business ID and not business object
    // as this will be set if a business exists.
    const activeBusinessId = useSelector(
        (state) => state?.user?.activeBusiness?.businessId
    );

    // If a business ID is defined, then a business has loaded.
    const hasBusiness = activeBusinessId !== undefined;

    // Are we in the process of setting the business in Redux?
    const activeBusinessPending = useSelector(
        (state) => state?.user?.activeBusinessPending
    );

    // If the user has finished loading and is brand new,
    // or the user is not new and their business has loaded.
    // Then we are no longer waiting for a business to load.
    const loaded =
        (loggedInUser && !loadingUser && brandNewUser) ||
        (loggedInUser &&
            !loadingUser &&
            !brandNewUser &&
            !activeBusinessPending &&
            hasBusiness);

    return loaded;
};
