import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Skeleton, Typography, Link } from '@mui/material';
import { useSubscription, useLocale, useTimezone } from '@/hooks';
import { toIntegerCurrency } from '@/util';
import {
    DocumentRemainingAmount,
    FreeTrialEnds,
    PlanTitle,
    NextCycleBegins
} from './SummaryComponents';
import styles from './styles';

const PlanSummary = ({ currentPlanPrice }) => {
    const classes = styles();
    const locale = useLocale();
    const timezone = useTimezone();
    const {
        chargify,
        components,
        loading,
        productId,
        nextRefreshDate,
        subscriptionSource,
        revenueCat
    } = useSubscription();

    const revenueCatInterval = revenueCat?.product_id.includes('monthly')
        ? 1
        : 12;
    let interval = chargify?.product?.interval || revenueCatInterval;

    if (
        chargify?.product?.productHandle === 'pro-lifetime' ||
        chargify?.product?.productHandle === 'lite-lifetime' ||
        chargify?.product?.productHandle === 'plus-lifetime'
    ) {
        interval = 99999;
    }

    const userPrice =
        subscriptionSource === 'chargify'
            ? chargify?.product?.priceInCents
            : toIntegerCurrency(revenueCat?.price);

    const subscriptionState = chargify?.state;

    return loading ? (
        <Grid
            item
            xs={12}
            sm={5}
            lg={4}
            className={classes.skeletonLoader}
            data-testid="summary-skeleton"
        >
            <Skeleton variant="text" height="100%" width="90%" />
        </Grid>
    ) : (
        <Grid item container className={classes.planSummaryContainer} xs={12}>
            <Grid item container xs={12} justifyContent="space-between">
                <PlanTitle
                    planName={productId}
                    userPrice={userPrice}
                    currentPlanPrice={currentPlanPrice}
                    interval={interval}
                    subscriptionSource={subscriptionSource}
                />
                {subscriptionState === 'trialing' && (
                    <FreeTrialEnds
                        trialEnds={chargify?.trialEndsAt}
                        locale={locale}
                        timezone={timezone}
                    />
                )}
                {subscriptionState !== 'trialing' && interval !== 99999 && (
                    <NextCycleBegins
                        nextCycleDate={nextRefreshDate}
                        locale={locale}
                        timezone={timezone}
                        productId={productId}
                        chargify={chargify}
                    />
                )}
                <DocumentRemainingAmount
                    maxAmount={components?.document?.maxAmount}
                    amountUsed={components?.document?.amountUsed}
                    limited={productId === 'free' || productId === 'lite'}
                />
            </Grid>
            <Grid
                item
                container
                xs={12}
                columnSpacing={1}
                className={classes.questionsContainer}
            >
                <Grid item>
                    <Typography variant="body2">
                        Questions about your plan?
                    </Typography>
                </Grid>
                <Grid item>
                    <Link
                        href="https://invoicemaker.com/pricing/"
                        variant="body2"
                        underline="none"
                        target="_blank"
                        rel="noopener"
                    >
                        Learn about plans and pricing.
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    );
};

PlanSummary.propTypes = {
    currentPlanPrice: PropTypes.number
};

PlanSummary.defaultProps = {
    currentPlanPrice: 0
};

export default PlanSummary;
