import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import FormSwitch from '../common/FormSwitch';

/**
 * This FormSwitchRef is needed to pass the inputRef prop to the correct component.
 * This allows for a commone component to be used within the "render" prop of a RHF Controller component.
 */
const FormSwitchRef = forwardRef((props, ref) => (
    <FormSwitch {...props} inputRef={ref} />
));

/**
 * This component wraps the MUI Switch in a React Hook Forms Controller reducing
 * complexity.
 *
 * To use
 * ------
 * Make sure that the component is placed in a form which is wrapped in a FormProvider.
 *
 * Default value
 * --------
 * Checkboxes use 'checked', rather than default values. If you want a checkbox pre-checked,
 * set the value within 'useForm' defaultValues.
 *
 * @link: https://react-hook-form.com/
 *
 * @param {string} label Components label. Can be ignored if using a FormGroup.
 * @param {string} labelPlacement The placement of the label (i.e. "start" or "end"). Defaults to "start".
 * @param {string} name The component name. This will be used by React Hook Forms when returning data.
 * @param {bool} disabled Option to disable the switch.
 * @param {string} color Text color for the label. References the "theme.typography.text" options.
 * @param {props} rest Any other props that you want to pass across to the component.
 */
const Switch = ({
    label,
    labelPlacement,
    name,
    helperText,
    disabled,
    color,
    ...rest
}) => (
    <Controller
        name={name}
        render={({ field }) => {
            const { value } = field;
            return (
                <FormSwitchRef
                    {...rest}
                    {...field}
                    name={name}
                    checked={Boolean(value)}
                    label={label}
                    labelPlacement={labelPlacement}
                    color={color}
                    disabled={disabled}
                    helperText={helperText}
                />
            );
        }}
    />
);

Switch.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    labelPlacement: PropTypes.string,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    color: PropTypes.oneOf([
        'primary',
        'secondary',
        'disabled',
        'red',
        'orange'
    ]),
    helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

Switch.defaultProps = {
    label: undefined,
    labelPlacement: undefined,
    disabled: false,
    color: undefined,
    helperText: undefined
};

export default Switch;
