import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    locked: {
        backgroundColor: `${theme.palette.background.default} !important`,
        '& input': {
            '-webkit-text-fill-color': `${theme.palette.secondary.contrastText} !important`
        }
    },
    lockIcon: {
        width: 20,
        height: 20
    }
}));
