import React from 'react';
import PropTypes from 'prop-types';
import { RadioGroup as MuiRadioGroup } from '@mui/material';
import { Controller } from 'react-hook-form';

/**
 * This component wraps the MUI RadioGroup in a React Hook Forms Controller reducing
 * complexity.
 *
 * To use
 * ------
 * Make sure that the component is placed in a form which is wrapped in a FormProvider.
 *
 * @link: https://react-hook-form.com/
 *
 * @param {node} children Radio button children to be rendered in the radio group.
 * @param {string} id The component ID. If ignored will be set by 'name'.
 * @param {string} name The component name. This will be used by React Hook Forms when returning data.
 * @param {props} rest Any other props that you want to pass across to the component.
 */

const RadioGroup = ({ children, id, name, ...rest }) => (
    <Controller
        name={name}
        render={({ field }) => (
            <MuiRadioGroup {...field} {...rest} id={id || name}>
                {children}
            </MuiRadioGroup>
        )}
    />
);

RadioGroup.propTypes = {
    children: PropTypes.node.isRequired,
    id: PropTypes.string,
    name: PropTypes.string.isRequired
};

RadioGroup.defaultProps = {
    id: ''
};

export default RadioGroup;
