import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { Grid } from '@mui/material';
import { useDrawers } from '@/hooks';
import { RECURRING_INVOICE_BASE_PARAM } from '@/constants';
import {
    ClientInput,
    Date,
    FilterForm,
    Price,
    Number,
    StatusCheckbox,
    Tags
} from '../formComponents';

const RecurringInvoiceFilterDrawer = () => {
    const { isDrawerActive } = useDrawers();
    const drawerOpen = isDrawerActive('recurringInvoiceFilter');
    const { clientId: isClientPage } = useParams();

    const documentStates = ['active', 'draft', 'stopped', 'complete'];

    const defaultValues = {
        status: {
            active: false,
            draft: false,
            stopped: false,
            complete: false
        },
        client: '',
        total: {
            min: '',
            max: ''
        },
        amountSent: {
            min: '',
            max: ''
        },
        nextSendDate: {
            min: '',
            max: ''
        },
        tags: []
    };

    return (
        <FilterForm
            defaultValues={defaultValues}
            id="FilterRecurringInvoices"
            open={drawerOpen}
            paramBase={RECURRING_INVOICE_BASE_PARAM}
            title="Recurring Invoices"
            type="recurringInvoice"
        >
            <Grid item>
                <StatusCheckbox documentStates={documentStates} />
                {!isClientPage && <ClientInput />}
                <Price
                    heading="Total"
                    id="total"
                    maxTitle="Max"
                    minTitle="Min"
                />
                <Number
                    heading="Sent"
                    id="amountSent"
                    maxTitle="Max"
                    minTitle="Min"
                />
                <Date heading="Next Send Date" id="nextSendDate" />
                <Tags heading="Tags" id="tags" />
            </Grid>
        </FilterForm>
    );
};

export default RecurringInvoiceFilterDrawer;
