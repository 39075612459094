import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, ListItem, Button } from '@mui/material';
import { failedPaymentErrors } from '@/config';
import { REFUND_TYPE } from '@/constants';
import { Tooltip } from '@/components/common';
import {
    transaction as transactionModel,
    defaultTransaction as defaultTransactionModel
} from '@/models';
import { usePaymentConnections } from '@/hooks';
import {
    capitalizeFirstLetter,
    formatCurrency,
    formatDate,
    formatTime,
    toWholeCurrency
} from '@/util';
import { HelpFilled } from '@/resources/icons';
import PaymentMethodView from '../PaymentMethodView';
import PaymentTypeTag from './PaymentTypeTag';
import makeStyles from './styles';
import FailedPaymentReason from './FailedPaymentReason';

const PaymentViewItem = memo(
    ({
        currency,
        transaction,
        refundAvailable,
        onSelectForRefund,
        locale,
        timezone,
        toolTip,
        refundDisabled,
        isRefund
    }) => {
        const {
            timestamp,
            amountCollected,
            amountRefunded,
            type,
            notes,
            processor,
            processorAccountId,
            paymentMethod,
            state: transactionState,
            refunds
        } = transaction;

        const refundFailed =
            !isRefund && refunds?.some(({ state }) => state === 'failed');

        const classes = makeStyles({
            type,
            hasRefunds: isRefund || amountRefunded > 0 || refundFailed,
            notes,
            hasLast4:
                (paymentMethod?.last4 || paymentMethod?.data) && !isRefund,
            isRefund
        });

        const { connections } = usePaymentConnections();

        const processorConnection = connections.find(
            (connection) => connection.application === processor
        );

        const amount = amountCollected || amountRefunded;

        const method = type === 'refund' ? processor : paymentMethod?.type;

        const paymentPending = transactionState.toLowerCase() === 'pending';

        const paymentFailed = transactionState.toLowerCase() === 'failed';

        const refundPending = refunds?.some(
            ({ state }) => state.toLowerCase() === 'pending'
        );

        const transactionPending = paymentPending || refundPending;

        const applicationAccountId =
            processorAccountId || transaction?.applicationAccountId;

        // If the applicationAccountId on the transaction is different than the applicationAccountId
        // on the connection, this transaction was made on a different oauth connection
        // and cannot be refunded on this connection.
        const differentPreviousConnection =
            applicationAccountId &&
            processorConnection &&
            processorConnection?.applicationAccountId !== applicationAccountId;

        const processorName = capitalizeFirstLetter(processor);

        const previousConnectionToolTip = differentPreviousConnection
            ? `It looks like this payment was either created under a different ${processorName} account or your ${processorName} account may not be connnected to Invoice Maker. To issue a refund, you will need to be connected to the same ${processorName} account that created the payment.`
            : null;

        const refundPendingTooltip = refundPending
            ? 'This transaction currently has a pending refund. Please allow up to 4 days for processing.'
            : null;

        const formattedDate = formatDate({
            isoDate: timestamp,
            locale,
            timezone
        });

        const formattedTime = formatTime({
            isoDate: timestamp,
            locale,
            timezone
        });

        const getRefundButton = () => (
            <Button
                fullWidth
                variant="contained"
                color="primary"
                className={
                    transactionPending || differentPreviousConnection
                        ? classes.pendingButton
                        : classes.refundButton
                }
                onClick={
                    transactionPending || differentPreviousConnection
                        ? () => {}
                        : onSelectForRefund
                }
                disabled={Boolean(
                    refundDisabled || differentPreviousConnection
                )}
                endIcon={
                    transactionPending || differentPreviousConnection ? (
                        <HelpFilled className={classes.helpIcon} />
                    ) : null
                }
            >
                <Typography variant="h4" component="span">
                    {transactionPending && !differentPreviousConnection
                        ? 'Pending'
                        : 'Refund'}
                </Typography>
            </Button>
        );

        const nativeFailedPaymentError =
            paymentMethod?.error || transaction?.error;

        const failedPaymentError = failedPaymentErrors.find(
            (error) => error.nativeMessage === nativeFailedPaymentError
        );

        return (
            <ListItem disableGutters className={classes.listContainer}>
                {/* Payment Tag */}
                <div className={classes.paymentLabelContainer}>
                    <PaymentTypeTag
                        type={!isRefund && paymentFailed ? 'declined' : type}
                    />
                </div>

                {/* Arrow */}
                <div
                    className={
                        type === 'payment'
                            ? classes.downArrowContainerPayment
                            : classes.downArrowContainerRefund
                    }
                >
                    {type === 'payment' &&
                        (!refundPending || refunds?.length > 1) &&
                        (amountRefunded > 0 || refundFailed) && (
                            <div className={classes.downArrowPayment}>
                                <div
                                    className={classes.downArrowPaymentTriangle}
                                />
                            </div>
                        )}
                    {type === 'refund' && (
                        <div className={classes.downArrowRefund}>
                            <div className={classes.downArrowRefundTriangle} />
                        </div>
                    )}
                </div>

                {/* Date */}
                <div className={classes.dateContainer}>
                    <Typography variant="h4" className={classes.detailText}>
                        {`${formattedDate} ${formattedTime}`}
                    </Typography>
                </div>

                {/* Price */}
                <div className={classes.priceContainer}>
                    <Typography variant="h3">
                        {type === 'refund' ? '-' : ''}
                        {formatCurrency({
                            amount: toWholeCurrency(amount),
                            currency,
                            locale
                        })}
                    </Typography>
                </div>

                {/* Payment Type */}
                <div className={classes.paymentMethodContainer}>
                    {type !== 'refund' && (
                        <PaymentMethodView
                            method={method || type?.provider}
                            last4={
                                paymentMethod?.last4 ||
                                paymentMethod?.data ||
                                ''
                            }
                        />
                    )}
                </div>

                {/* Refund Button */}
                <div className={classes.refundButtonContainer}>
                    {(refundAvailable || refundPending) &&
                        !refundFailed &&
                        type !== REFUND_TYPE &&
                        (refundDisabled ||
                        transactionPending ||
                        differentPreviousConnection ? (
                            // show tooltip when refund is disabled or payment is pending or if this is a new connection
                            <Tooltip
                                data-testid="toolTipTestId"
                                title={
                                    previousConnectionToolTip ||
                                    refundPendingTooltip ||
                                    toolTip
                                }
                                placement="top-start"
                                arrow
                                trueChildren
                            >
                                {getRefundButton()}
                            </Tooltip>
                        ) : (
                            <div>{getRefundButton()}</div>
                        ))}
                </div>

                {paymentFailed && (
                    <div className={classes.secondaryInfoContainer}>
                        <div className={classes.failedPaymentReasonContainer}>
                            <FailedPaymentReason
                                reason={
                                    failedPaymentError?.displayError ||
                                    'Payment Failed'
                                }
                                hasLast4={Boolean(
                                    (paymentMethod?.last4 ||
                                        paymentMethod?.data) &&
                                        !isRefund
                                )}
                            />
                        </div>
                        {(paymentMethod?.error || transaction?.error) && (
                            <div className={classes.notesContainer}>
                                <Typography variant="h5">
                                    {failedPaymentError?.displayMessage ||
                                        paymentMethod.error}
                                </Typography>
                            </div>
                        )}
                    </div>
                )}

                {notes && !paymentFailed && (
                    <div className={classes.notesContainer}>
                        <Typography variant="h5">{notes}</Typography>
                    </div>
                )}
            </ListItem>
        );
    }
);

PaymentViewItem.propTypes = {
    currency: PropTypes.string,
    locale: PropTypes.string,
    transaction: transactionModel,
    refundAvailable: PropTypes.bool,
    onSelectForRefund: PropTypes.func,
    toolTip: PropTypes.string,
    timezone: PropTypes.string,
    refundDisabled: PropTypes.bool,
    isRefund: PropTypes.bool
};

PaymentViewItem.defaultProps = {
    ...defaultTransactionModel,
    currency: 'USD',
    locale: 'en-US',
    refundAvailable: false,
    onSelectForRefund: () => {},
    toolTip: '',
    timezone: 'America/Detroit',
    refundDisabled: false,
    isRefund: false
};

export default PaymentViewItem;
