// Data to set the head of the table.
const tableHead = [
    {
        id: 'name',
        label: 'NAME',
        sort: true
    },
    { id: 'type', label: 'TYPE', sort: true },
    {
        id: 'value',
        label: 'AMOUNT',
        sort: true
    },
    {
        id: 'tags',
        label: 'TAGS'
    },
    {
        id: 'actions',
        label: '',
        colSpan: 2
    }
];

export default tableHead;
