import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    filterButton: {
        backgroundColor: '#fff',
        border: '1px solid #EDEEF0',
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.typography.pxToRem(131)
        }
    },
    sortMenu: {
        width: 228
    },
    filterIcon: {
        width: 24,
        height: 24
    },
    line: {
        [theme.breakpoints.up('md')]: {
            padding: '1.25em'
        }
    },
    lineCenter: {
        display: 'flex',
        alignItems: 'center',
        padding: '0.3em 0',
        [theme.breakpoints.up('md')]: {
            padding: '1.25em'
        }
    },
    lineDate: {
        [theme.breakpoints.up('md')]: {
            padding: '1.25em',
            textAlign: 'left'
        },
        textAlign: 'right',
        width: 110,
        fontSize: 14
    },
    lineDateTime: {
        [theme.breakpoints.down('md')]: {
            display: 'block'
        }
    },
    lineLabel: {
        marginLeft: 10,
        fontSize: 14
    },
    simpleButton: {
        // reset button
        backgroundColor: 'transparent',
        border: 'none',
        outline: 'none',
        textDecoration: 'underline',

        // style like link
        cursor: 'pointer',
        color: '#35373D',
        fontSize: 14,
        fontWeight: 600,
        fontFamily: 'inherit'
    }
}));
