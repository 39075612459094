import PropTypes from 'prop-types';

export const clientModel = PropTypes.shape({
    balances: PropTypes.object,
    billingAddress: PropTypes.shape({
        city: PropTypes.string,
        country: PropTypes.string,
        line1: PropTypes.string,
        line2: PropTypes.string,
        postalCode: PropTypes.string,
        state: PropTypes.string
    }),
    businessId: PropTypes.string,
    clientId: PropTypes.string.isRequired,
    companyName: PropTypes.string,
    createdAt: PropTypes.string,
    customFields: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired
        })
    ),
    emailAddress: PropTypes.string.isRequired,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    shippingAddress: PropTypes.shape({
        city: PropTypes.string,
        country: PropTypes.string,
        line1: PropTypes.string,
        line2: PropTypes.string,
        postalCode: PropTypes.string,
        state: PropTypes.string
    }),
    tags: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            color: PropTypes.string.isRequired,
            numberOfTimesUsed: PropTypes.number.isRequired,
            usageByCategory: PropTypes.object,
            businessId: PropTypes.string.isRequired,
            tagId: PropTypes.string.isRequired,
            createdAt: PropTypes.string.isRequired,
            updatedAt: PropTypes.string.isRequired
        })
    ),
    updatedAt: PropTypes.string
});

export const clientDefaultModel = {
    balances: {},
    billingAddress: {
        city: '',
        country: '',
        line1: '',
        line2: '',
        postalCode: '',
        state: ''
    },
    businessId: '',
    companyName: '',
    createdAt: '',
    customFields: [],
    name: '',
    phoneNumber: '',
    shippingAddress: {
        city: '',
        country: '',
        line1: '',
        line2: '',
        postalCode: '',
        state: ''
    },
    tags: [],
    updatedAt: ''
};
