import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    menuDropdownButton: {
        color: theme.palette.imBrand.darkBlue,
        textDecoration: 'none'
    },
    menuItem: {
        marginLeft: theme.typography.pxToRem(7),
        marginRight: theme.typography.pxToRem(7)
    },
    menuLink: {
        color: theme.palette.text.primary,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textDecoration: 'none',
        padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(
            0
        )}`,
        width: '100%'
    },
    menuIcon: {
        height: theme.typography.pxToRem(24),
        minWidth: theme.typography.pxToRem(40)
    },
    copyInfoButton: {
        paddingTop: theme.typography.pxToRem(4),
        paddingBottom: theme.typography.pxToRem(4),
        paddingLeft: 0,
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    logoutText: {
        color: theme.palette.primary
    }
}));
