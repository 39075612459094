import React from 'react';
import { useDispatch } from 'react-redux';
import { useToastNotification } from '@/hooks';
import { closeModal, setModal, setDocumentStatus } from '@/state/actions';
import { shareInvoice } from '@/modules/dataWrangler/dataWrangler';
import { SendIcon } from '@/resources/icons';
import { delayAwait } from '@/util';
import store from '@/state/store';
import { useSaveInvoice } from './useSaveInvoice';

/**
 * Invoice Hook
 * Sends an invoice, where data is passed into the hook.
 * Handles redirection logic if invoices limited, and
 * creating or updating functionality.
 *
 * Returns false, or clean invoice data.
 */
export const useSendInvoice = () => {
    const dispatch = useDispatch();
    const { triggerToast } = useToastNotification();
    const saveInvoice = useSaveInvoice();
    const subscriptionData =
        store.getState()?.user?.activeBusiness?.subscription || {};

    const { productId } = subscriptionData || undefined;

    const sendInvoice = async (
        invoiceData,
        emailOverwrite,
        emailSubject,
        emailBody
    ) => {
        const startTime = Date.now();
        dispatch(setModal({ slug: 'loader-invoice-send' }));

        let savedData;

        if (invoiceData?.documentState === 'draft' || productId === 'free') {
            savedData = await saveInvoice(invoiceData, 'send');

            if (savedData === false) {
                dispatch(closeModal('loader-invoice-send'));
                return false;
            }
        } else {
            savedData = { ...invoiceData };
        }

        const documentId = savedData?.documentId;

        if (documentId !== null) {
            savedData = await shareInvoice(
                documentId,
                'email',
                emailOverwrite,
                emailSubject,
                emailBody
            );

            if (savedData?.status === 429) {
                await delayAwait(startTime, 2000);

                dispatch(closeModal('loader-invoice-send'));

                triggerToast({
                    message: 'Please wait before sending again',
                    action: 'delete',
                    icon: <SendIcon />
                });

                return false;
            }

            // If draft, and API returned correctly - update invoice state.
            if (
                savedData?.documentState &&
                savedData?.documentState === 'draft'
            ) {
                dispatch(setDocumentStatus(savedData.documentState));
            }

            await delayAwait(startTime, 2000);

            dispatch(closeModal('loader-invoice-send'));

            triggerToast({
                message: 'Invoice Sent',
                action: 'send',
                icon: <SendIcon />
            });

            return savedData;
        }

        return false;
    };

    return sendInvoice;
};
