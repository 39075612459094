import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    listWrap: {
        backgroundColor: '#fff',
        padding: `0 8px 8px 8px`
    },
    collapseWrap: {
        borderRadius: 4,
        overflow: 'hidden'
    },
    listItem: {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        padding: 0,
        marginTop: theme.spacing(1),
        [theme.breakpoints.up('lg')]: {
            marginTop: theme.spacing(4.2),
            minWidth: '100%'
        }
    },
    loginButton: {
        marginBottom: theme.typography.pxToRem(20),
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    accountSectionWrapper: {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    skeleton: {
        height: '50px !important',
        width: '150px'
    },
    mainMenuIcon: {
        '& svg': {
            height: '24px',
            width: '24px'
        }
    }
}));
