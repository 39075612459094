import {
    UPDATE_SUBSCRIPTION_PRODUCTS,
    SET_HAS_CLICKED_SUBSCRIBE
} from '@/constants';

export const setSubscriptionProducts = (products) => ({
    type: UPDATE_SUBSCRIPTION_PRODUCTS,
    products
});

export const setHasClickedSubscribe = (clickStatus) => ({
    type: SET_HAS_CLICKED_SUBSCRIBE,
    hasClickedSubscribe: clickStatus
});
