import { SET_GLOBAL_ERROR, RESET_GLOBAL_ERROR } from '@/constants';

export const setGlobalError = (error) => ({
    type: SET_GLOBAL_ERROR,
    title: error?.title,
    message: error?.message,
    icon: error?.icon
});

export const resetGlobalError = () => ({
    type: RESET_GLOBAL_ERROR
});
