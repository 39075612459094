import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import { useToastNotification } from '@/hooks/useToastNotification';
import useStyles from './styles';

/**
 * Toaster component to show notifications.
 *
 * This component should be used alongside the useToastNotification hook.
 * Read https://invoicemaker.atlassian.net/wiki/spaces/IM/pages/355500033/useToastNotification
 * for more info on usage.
 */
const Toaster = () => {
    const {
        toastActive,
        toastIcon,
        toastAction,
        toastMessage,
        toastKey,
        closeToast,
        toastTimeout
    } = useToastNotification();

    return (
        <Toast
            toastActive={toastActive}
            toastIcon={toastIcon}
            toastAction={toastAction}
            toastMessage={toastMessage}
            toastKey={toastKey}
            closeToast={closeToast}
            toastTimeout={toastTimeout}
        />
    );
};

export default Toaster;

/**
 * Pure Toast component to be used inside the Toaster.
 * @param {bool} toastActive Is this Toast active?
 * @param {elem} toastIcon React component icon. The Toast can be used without this.
 * @param {string} toastAction saves/send/archive/download/duplicate.
 * @param {string} toastMessage Content to put inside Toast.
 * @param {date} toastKey Unique key based off date.
 * @param {func} closeToast Function fired when Toast closes itself.
 * @param {number} toastTimeout Custom timeout for individual Toast.
 */
export const Toast = ({
    toastActive,
    toastIcon,
    toastAction,
    toastMessage,
    toastKey,
    closeToast,
    toastTimeout
}) => {
    const classes = useStyles({ action: toastAction });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        closeToast();
    };

    return (
        <Snackbar
            key={toastKey}
            autoHideDuration={toastTimeout}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={toastActive}
            onClose={handleClose}
            className={classes.toastContainer}
        >
            <div className={classes.toast}>
                {toastIcon && <div className={classes.icon}>{toastIcon}</div>}
                <p>{toastMessage}</p>
            </div>
        </Snackbar>
    );
};

Toast.propTypes = {
    toastActive: PropTypes.bool,
    toastIcon: PropTypes.element,
    toastAction: PropTypes.oneOf([
        'archive',
        'delete',
        'download',
        'duplicate',
        'saved',
        'send',
        'notification'
    ]),
    toastMessage: PropTypes.string,
    toastKey: PropTypes.number,
    closeToast: PropTypes.func,
    toastTimeout: PropTypes.number
};

Toast.defaultProps = {
    toastActive: false,
    toastIcon: null,
    toastAction: null,
    toastMessage: null,
    toastKey: null,
    closeToast: null,
    toastTimeout: 3000
};
