import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { invoiceMarkAsUnpaid } from '@/modules/dataWrangler/dataWrangler';
import { XOutlined } from '@/resources/icons';

import { markUnpaidInvoiceModalOptions } from '../../ModalCopy';

import useStyles from './styles';

// USED FOR ZELLE/VENMO/CASHAPP PAYMENTS
const InvoiceMarkAsUnpaid = ({
    beforeActionPromptOptions,
    setBeforeActionPromptOptions
}) => {
    const classes = useStyles();

    const documentId = useSelector((state) => state?.makeInvoice?.documentId);

    const onClick = () => {
        setBeforeActionPromptOptions({
            ...beforeActionPromptOptions,
            ...markUnpaidInvoiceModalOptions,
            onCancel: () =>
                setBeforeActionPromptOptions({
                    ...markUnpaidInvoiceModalOptions,
                    open: false
                }),
            onConfirm: async () => {
                setBeforeActionPromptOptions({
                    ...markUnpaidInvoiceModalOptions,
                    open: false
                });

                await invoiceMarkAsUnpaid(documentId);
            },
            open: true
        });
    };

    return (
        <Button
            fullWidth
            className={classes.button}
            variant="contained"
            color="secondary"
            size="medium"
            startIcon={<XOutlined className={classes.svg} />}
            onClick={onClick}
        >
            Not Received
        </Button>
    );
};

InvoiceMarkAsUnpaid.propTypes = {
    beforeActionPromptOptions: PropTypes.object,
    setBeforeActionPromptOptions: PropTypes.func
};

InvoiceMarkAsUnpaid.defaultProps = {
    beforeActionPromptOptions: {},
    setBeforeActionPromptOptions: () => {}
};

export default InvoiceMarkAsUnpaid;
