import React, { useState } from 'react';
import loadable from '@loadable/component';
import { Grid } from '@mui/material';
import InlineLink from '@/components/common/InlineLink';
import {
    acceptCancellationOffer,
    cancelSubscription
} from '@/modules/subscriptionManager';
import styles from './styles';

const UnsubscribeDialog = loadable(() =>
    import(`@/pageComponents/Settings/Subscription/UnsubscribeDialog`)
);

const CancelSubscriptionButton = () => {
    const classes = styles();
    const [open, setOpen] = useState(false);
    const [cancelSubLoading, setCancelSubLoading] = useState(false);

    const submitAcceptCancellationOffer = async () => {
        setCancelSubLoading(true);
        await acceptCancellationOffer();
        setCancelSubLoading(false);
        setOpen(false);
    };

    const submitCancelSubscription = async () => {
        setCancelSubLoading(true);
        await cancelSubscription();
        setCancelSubLoading(false);
        setOpen(false);
    };

    return (
        <>
            <Grid
                item
                container
                justifyContent="center"
                className={classes.cancelSubscriptionButtonContainer}
            >
                <InlineLink
                    disableUnderline
                    onClick={() => setOpen(true)}
                    className={classes.cancelSubscriptionButton}
                >
                    Cancel Subscription
                </InlineLink>
            </Grid>

            {open && (
                <UnsubscribeDialog
                    onClose={() => setOpen(false)}
                    onCancel={submitCancelSubscription}
                    acceptOffer={submitAcceptCancellationOffer}
                    loadingState={cancelSubLoading}
                />
            )}
        </>
    );
};

export default CancelSubscriptionButton;
