import React from 'react';
import PropTypes from 'prop-types';
import { Button, Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import { useCheckAuthState, useDocumentFormData } from '@/hooks';
import {
    useConvertEstimate,
    useValidateEstimate
} from '@/hooks/useSidebarActions';
import { ConvertIconDisabled, ConvertIconGreen } from '@/resources/icons';
import useStyles from '../../styles';

const handleOnClick = async ({
    estimate,
    onApprovedEstimate,
    onUnapprovedEstimate,
    convertEstimate,
    validateEstimate
}) => {
    const estimateData = await validateEstimate(estimate);
    if (!estimateData) return;

    if (estimate?.documentState === 'approved') {
        onApprovedEstimate(() => convertEstimate(estimateData));
        return;
    }

    onUnapprovedEstimate();
};

export const EstimateConversion = ({
    onApprovedEstimate,
    onUnapprovedEstimate
}) => {
    const classes = useStyles();

    const { documentLoading } = useDocumentFormData();
    const { loadingUser } = useCheckAuthState();
    const convertEstimate = useConvertEstimate();
    const validateEstimate = useValidateEstimate();

    const estimate = useSelector((state) => state?.makeInvoice);

    const loadingButton = loadingUser || documentLoading;

    const availableForConversion = estimate?.documentState === 'approved';

    const locked = estimate?.viewMode === 'edit';

    if (locked) {
        return (
            <div className={classes.estimateConversionButton}>
                {!loadingButton ? (
                    <Button
                        fullWidth
                        className={classes.button}
                        variant="contained"
                        color="secondary"
                        endIcon={
                            availableForConversion ? (
                                <ConvertIconGreen
                                    className={classes.buttonConversionSVG}
                                />
                            ) : (
                                <ConvertIconDisabled
                                    className={classes.buttonConversionSVG}
                                />
                            )
                        }
                        onClick={() =>
                            handleOnClick({
                                estimate,
                                onApprovedEstimate,
                                onUnapprovedEstimate,
                                convertEstimate,
                                validateEstimate
                            })
                        }
                        id="estimate-conversion-sidebar"
                    >
                        <span
                            className={
                                !availableForConversion
                                    ? classes.conversionTextUnavailable
                                    : ''
                            }
                        >
                            Copy To Invoice
                        </span>
                    </Button>
                ) : (
                    <Skeleton variant="rectangular" className={classes.sizer} />
                )}
            </div>
        );
    }

    return null;
};

EstimateConversion.propTypes = {
    onApprovedEstimate: PropTypes.func.isRequired,
    onUnapprovedEstimate: PropTypes.func.isRequired
};

export default EstimateConversion;
