export const formatContactDetails = ({
    formattedAddress,
    email,
    phone,
    website
}) => {
    if (!email && !phone && !website) {
        return formattedAddress;
    }

    const addressArray = formattedAddress ? [formattedAddress, ''] : [];

    if (email && email !== formattedAddress?.split('\n')[0]) {
        addressArray.push(email);
    }

    if (phone) {
        addressArray.push(phone);
    }

    if (website) {
        addressArray.push(website);
    }

    return addressArray.join('\n');
};
