import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Paper,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { Capacitor } from '@capacitor/core';
import { useLocale, useTimezone } from '@/hooks';

import UpdateBillingInfoButton from '../PlanSummary/SummaryComponents/UpdateBillingInfoButton';
import SubPaymentSource from '../PlanSummary/SummaryComponents/SubPaymentSource';
import NextBillDate from '../PlanSummary/SummaryComponents/NextBillDate';
import useStyles from './styles';

const PaymentInfo = ({ chargify, nextRefreshDate, subscriptionSource }) => {
    const classes = useStyles();
    const theme = useTheme();

    const locale = useLocale();
    const timezone = useTimezone();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const isIos = Capacitor.getPlatform() === 'ios';

    const paymentMethod = chargify?.creditCard || chargify.paypalAccount;

    const autoRenew = !chargify?.cancelAtEndOfPeriod;
    const hasIosProduct = subscriptionSource === 'revenueCat';
    const productHandle = chargify?.product?.productHandle;

    return (
        <Grid>
            <Grid item xs={12} className={classes.paymentInfoHeader}>
                <Typography variant="h4">Payment Information</Typography>
            </Grid>

            <Grid>
                <Paper
                    variant="outlined"
                    elevation={0}
                    className={classes.paymentInfoPaper}
                >
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems={isMobile ? 'flex-start' : 'center'}
                        className={classes.payInfoContainer}
                        spacing={2}
                    >
                        <Grid item>
                            <SubPaymentSource
                                paymentMethod={paymentMethod}
                                subscriptionSource={subscriptionSource}
                                isMobile={isMobile}
                            />
                        </Grid>
                        {productHandle !== 'pro-lifetime' &&
                            productHandle !== 'lite-lifetime' &&
                            productHandle !== 'plus-lifetime' && (
                                <Grid item>
                                    <NextBillDate
                                        nextBillDate={
                                            chargify?.currentPeriodEndsAt ||
                                            nextRefreshDate
                                        }
                                        locale={locale}
                                        timezone={timezone}
                                        chargify={chargify}
                                    />
                                </Grid>
                            )}
                        {(!hasIosProduct || (isIos && hasIosProduct)) && (
                            <Grid
                                item
                                className={classes.payInfoButtonContainer}
                            >
                                <UpdateBillingInfoButton
                                    autoRenew={autoRenew}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </Grid>
            {isIos && (
                <Grid>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        className={classes.inAppText}
                    >
                        In-app payments may be processed up to 48 hours before
                        or after your scheduled renewal date.
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};

PaymentInfo.propTypes = {
    chargify: PropTypes.object,
    nextRefreshDate: PropTypes.string,
    subscriptionSource: PropTypes.string
};

PaymentInfo.defaultProps = {
    chargify: {},
    nextRefreshDate: '',
    subscriptionSource: ''
};

export default PaymentInfo;
