import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    searchWrap: {
        margin: theme.spacing(0.5, 0, 1, 0),
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(1)
        }
    },
    newInvoiceButtonContainer: {
        marginRight: 0
    },
    newInvoiceButton: {
        maxWidth: theme.typography.pxToRem(250),
        width: theme.typography.pxToRem(250),
        [theme.breakpoints.up('md')]: {
            marginRight: theme.spacing(2)
        }
    },
    filterButtonWrap: {
        [theme.breakpoints.up('md')]: {
            marginRight: theme.spacing(2)
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(1),
            paddingRight: '0px !important'
        }
    },
    sortButtonWrap: {
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(1)
        }
    },
    filterButton: {
        backgroundColor: '#fff',
        border: '1px solid #EDEEF0',
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.typography.pxToRem(131)
        }
    },
    sortMenu: {
        width: 228
    },
    filterIcon: {
        width: 24,
        height: 24
    },
    cio_message: {
        width: '100%'
    }
}));
