import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    merchantSectionContainer: {
        paddingTop: theme.spacing(2)
    },
    merchantTitleAndSwitch: {
        marginBottom: theme.spacing(1)
    },
    merchantCardIconContainer: {
        '& svg': {
            maxHeight: 24,
            marginRight: 8,
            height: 24
        }
    },
    inactiveMerchantCard: {
        opacity: 0.38
    },
    merchantButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        padding: '1rem 0 0 3rem',
        marginLeft: 'auto'
    },
    previewButton: {
        width: 118,
        border: '1px solid #E0E5EE',
        '& svg': {
            width: 20,
            height: 20,
            fill: theme.palette.text.secondary,
            border: 'none'
        }
    },
    zelleTextField: {
        '& .MuiFilledInput-root': {
            backgroundColor: theme.palette.common.white,
            border: `1px solid lightgrey`
        }
    },
    zellePhoneNumberInput: {
        marginBottom: theme.spacing(2)
    },
    zellePurpleText: {
        color: '#6D1DD4',
        fontWeight: 600
    },
    disconnectContainer: {
        marginTop: 16,
        justifyContent: 'space-between',
        [theme.breakpoints.only('xs')]: {
            flexDirection: 'column'
        }
    },
    disconnectButton: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.actionColors.negative,
        borderColor: theme.palette.actionColors.negative,
        width: 170,
        height: 40,
        '&:hover': {
            backgroundColor: '#35373d0a',
            borderColor: theme.palette.actionColors.negative
        },
        [theme.breakpoints.only('xs')]: {
            width: '100%'
        }
    },
    disconnectButtonContainer: {
        marginRight: 0
    },
    capabilitiesWarningContainer: {
        padding: 10,
        backgroundColor: '#F9D2D2',
        color: '#991B1B',
        borderRadius: 3,
        [theme.breakpoints.only('xs')]: {
            marginBottom: 16
        }
    },
    capabilitiesWarningText: {
        width: 'calc(100% - 36px)',
        marginLeft: 16
    },
    capabilitiesWarningIcon: {
        width: 20,
        height: 20
    }
}));
