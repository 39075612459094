export const ACTIVITY_ALL = 'activity-all';
export const ACTIVITY_INVOICE = 'activity-invoice';
export const ACTIVITY_ESTIMATE = 'activity-estimate';
export const ACTIVITY_RECEIPT = 'activity-receipt';
export const ACTIVITY_CLIENT = 'activity-client';
export const ACTIVITY_PRODUCT = 'activity-product';
export const ACTIVITY_TAX = 'activity-tax';
export const ACTIVITY_DISCOUNT = 'activity-discount';
export const ACTIVITY_RECURRING_INVOICE = 'activity-recurring-invoice';
export const ACTIVITY_TAG = 'activity-tag';
