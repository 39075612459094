import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid } from '@mui/material';
import {
    UncontrolledGenericDrawer,
    DrawerBody,
    DrawerHeading,
    DrawerFooter
} from '@/components/common';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { FilterIcon } from '@/resources/icons';
import { closeDrawer } from '@/state/actions';
import { useUrlParams } from '@/hooks/useUrlParams';
import { activityTypes } from '@/config';
import {
    dateToUniversalShort,
    dateIsoStringFromUniversalShort,
    deepCombineObjects
} from '@/util';
import makeStyles from './styles';

/**
 * RHF wrapper component for Filter Drawers
 *
 * @param {object} defaultValues The default values to be passed into child components.
 * All components must have default values even if the default value is empty,
 * similar to the flow of passing defaultValues into the useForm RHF hook.
 * @param {node} children Children components to be rendered. i.e. form components
 * @param {object} formMethods Optional useForm override to be passed if parent component needs access to methods at a higher level than the FilterForm component.
 * @param {string} id The form ID.
 * @param {boolean} open Controls the opening and closing of the drawer.
 * @param {string} paramBase The base URL for the search params.
 * @param {string} title The form title.
 * @returns {node} Wrapped form with controlled RHF values.
 */

const FilterForm = ({
    defaultValues,
    children,
    formMethods,
    id,
    open,
    paramBase,
    title,
    type
}) => {
    const limitFormReset = useRef();
    const { getUrlParams, setUrlParams } = useUrlParams();
    const classes = makeStyles();
    const dispatch = useDispatch();

    const clientList =
        useSelector((state) => state.user?.activeBusiness?.clients) || [];
    const tags =
        useSelector((state) => state?.user?.activeBusiness?.tags) || [];

    const defaultMethods = useForm({
        nativeValidation: false,
        criteriaMode: 'all'
    });

    const methods = formMethods || defaultMethods;

    const { setError, clearErrors } = methods;

    const handleCloseDrawer = () => dispatch(closeDrawer(`${type}Filter`));

    const onSubmit = (data) => {
        if (data?.client) {
            const validClient = clientList.find(
                (client) => client.clientId === data.client
            );

            if (!validClient) {
                setError('client', {
                    type: 'custom',
                    message: 'You must select an existing client'
                });
                return;
            }

            clearErrors('client');
        }

        // Do not pass in pagination, this is reset automatically.
        // Make sure to keep search if already provided.

        const formData = { ...data };

        if (formData.activities) {
            const activityType = formData.activities.map(
                (activity) => activity.slug
            );

            formData.activityType = activityType.toString();
            delete formData.activities;
        }

        if (formData.tags) {
            const tagIds = formData.tags.map((tag) => tag.tagId);

            formData.tagIds = tagIds.toString();
            delete formData.tags;
        }

        // Converts iso string into YYYY-MM-DD for URL.
        Object.entries(formData).forEach(([key, value]) => {
            if (
                key.toLowerCase().includes('date') ||
                key.toLowerCase().includes('createdat')
            ) {
                if (value?.min) {
                    formData[key].min = dateToUniversalShort(value.min);
                }
                if (value?.max) {
                    formData[key].max = dateToUniversalShort(value.max);
                }
            }
        });

        // If object has 'p' (pagination) property, remove it.
        if (formData.p) {
            delete formData.p;
        }

        setUrlParams({ base: paramBase, params: formData, ignore: ['s'] });
        handleCloseDrawer();
    };

    const { handleSubmit, reset } = methods;

    const currentUrlParams = getUrlParams({ base: paramBase });

    // Converts YYYY-MM-DD format into iso string for date pickers.
    if (typeof currentUrlParams === 'object' && currentUrlParams !== null) {
        Object.entries(currentUrlParams).forEach(([key, value]) => {
            if (
                key.toLowerCase().includes('date') ||
                key.toLowerCase().includes('createdat')
            ) {
                if (value?.min) {
                    currentUrlParams[key].min = dateIsoStringFromUniversalShort(
                        value.min
                    );
                }
                if (value?.max) {
                    currentUrlParams[key].max = dateIsoStringFromUniversalShort(
                        value.max
                    );
                }
            }
        });
    }

    if (currentUrlParams?.activityType) {
        const activities = currentUrlParams.activityType.split(',');

        currentUrlParams.activities = activityTypes.filter((activityType) =>
            activities.includes(activityType.slug)
        );

        delete currentUrlParams.activityType;
    }

    if (currentUrlParams?.tagIds) {
        const tagIds = currentUrlParams.tagIds.split(',');

        currentUrlParams.tags = tags.filter((tag) =>
            tagIds.includes(tag.tagId)
        );
        delete currentUrlParams.tagIds;
    }

    // Because we have two different forms of default values (can be set by URL params or passed in as default in top-level),
    // we need to combine the two objects here. URL params take priority,
    // and anything not set via the URL params is handled by traditional defaultValues.
    const resetValues = deepCombineObjects(defaultValues, currentUrlParams);

    const stringifiedParams = JSON.stringify(resetValues);

    const resetForm = useCallback(async () => {
        if (limitFormReset.current !== stringifiedParams) {
            reset(JSON.parse(stringifiedParams));
            limitFormReset.current = stringifiedParams;
        }
    }, [reset, stringifiedParams]);

    const handleOnResetClick = () => {
        reset(defaultValues);
    };

    useEffect(() => {
        resetForm();
    }, [resetForm]);

    return (
        <UncontrolledGenericDrawer
            open={open}
            close={handleCloseDrawer}
            id={`${type}Filter`}
        >
            <FormProvider {...methods}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={classes.form}
                >
                    <DrawerHeading
                        startAdornment={<FilterIcon />}
                        title={`Filter ${title}`}
                    />

                    <DrawerBody className={classes.itemList}>
                        {children}
                        <Grid item>
                            <Button
                                fullWidth
                                variant="outlined"
                                className={classes.resetButton}
                                onClick={handleOnResetClick}
                            >
                                Reset Filters
                            </Button>
                        </Grid>
                    </DrawerBody>

                    <DrawerFooter
                        submitButtonId={id}
                        acceptOptions={{
                            label: 'See Results',
                            type: 'submit'
                        }}
                        cancelOptions={{
                            label: 'Cancel',
                            action: handleCloseDrawer
                        }}
                    />
                </form>
            </FormProvider>
        </UncontrolledGenericDrawer>
    );
};

FilterForm.propTypes = {
    children: PropTypes.node.isRequired,
    formMethods: PropTypes.object,
    title: PropTypes.string.isRequired,
    open: PropTypes.bool,
    paramBase: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    defaultValues: PropTypes.object.isRequired,
    type: PropTypes.string
};

FilterForm.defaultProps = {
    formMethods: undefined,
    open: false,
    type: ''
};

export default FilterForm;
