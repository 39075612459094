import React from 'react';
import PropTypes from 'prop-types';
import MuiPagination from '@mui/material/Pagination';
import { Grid } from '@mui/material';
import { useUrlParams } from '@/hooks';

const Pagination = ({ base, totalPages }) => {
    const { setUrlParams, getUrlParams } = useUrlParams();

    const currentUrlParams = getUrlParams({ base });

    const setPage = (p) => {
        const newUrlParams = {
            ...currentUrlParams,
            p
        };
        setUrlParams({ base, params: newUrlParams });
        window.scrollTo(0, 0);
    };

    if (totalPages > 1) {
        return (
            <Grid container direction="row-reverse" spacing={1}>
                <MuiPagination
                    count={totalPages}
                    onChange={(_, page) => {
                        setPage(page);
                    }}
                    page={Number(currentUrlParams?.p || 1)}
                />
            </Grid>
        );
    }
    return null;
};

Pagination.propTypes = {
    base: PropTypes.string.isRequired,
    totalPages: PropTypes.number
};

Pagination.defaultProps = {
    totalPages: undefined
};

export default Pagination;
