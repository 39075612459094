/* eslint import/no-cycle: "off"  */
import { randomSync } from 'ksuid';
import store from '@/state/store';
import axiosAuthenticated from '../authenticatedRequestor';
import axiosUnauthenticated from '../unauthenticatedRequestor';

export const sendTransaction = async (documentId, transaction) => {
    const { businessId } = store.getState().user.activeBusiness;

    const transactionData = {
        documentId,
        ...transaction
    };

    try {
        const response = await axiosAuthenticated.post(
            `/businesses/${businessId}/transactions/payments/manual`,
            transactionData
        );

        return response.data;
    } catch (err) {
        return false;
    }
};

export const sendRefund = async (paymentId, refund, processor) => {
    const { businessId } = store.getState().user.activeBusiness;

    let idempotencyKey;
    if (processor === 'square') {
        idempotencyKey = randomSync().string;
    }

    const response = await axiosAuthenticated.post(
        `/businesses/${businessId}/transactions/payments/${paymentId}/refund`,
        !idempotencyKey ? refund : { ...refund, idempotencyKey }
    );

    return response.data;
};

export const createManualStripePayment = async ({
    documentId,
    amount,
    notes
}) => {
    const { businessId } = store.getState().user.activeBusiness;
    try {
        const response = await axiosAuthenticated.post(
            `/businesses/${businessId}/transactions/payments/stripe`,
            { documentId, amount, notes }
        );

        return response.data.paymentIntent;
    } catch (err) {
        return '';
    }
};

export const createManualSquarePayment = async (paymentData) => {
    const { businessId } = store.getState().user.activeBusiness;
    const {
        token,
        documentId,
        amount,
        currency,
        country,
        notes,
        idempotencyKey
    } = paymentData;

    let generatedIdempotencyKey;

    if (idempotencyKey === undefined) {
        generatedIdempotencyKey = randomSync().string;
    }

    try {
        const res = await axiosAuthenticated.post(
            `businesses/${businessId}/transactions/payments/square`,
            {
                documentId,
                token,
                amount,
                currency,
                country,
                notes,
                idempotencyKey: generatedIdempotencyKey
            }
        );

        return res?.data;
    } catch (err) {
        return idempotencyKey;
    }
};

export const createPublicSquarePayment = async (paymentData) => {
    const { token, amount, currency, idempotencyKey, documentJwt } =
        paymentData;

    let generatedIdempotencyKey;

    if (idempotencyKey === undefined) {
        generatedIdempotencyKey = randomSync().string;
    }

    try {
        const res = await axiosUnauthenticated.post(
            `public/pay/square/${documentJwt}`,
            {
                token,
                amount,
                currency,
                idempotencyKey: generatedIdempotencyKey
            }
        );

        return res?.data;
    } catch (err) {
        return err;
    }
};
