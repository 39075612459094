import React from 'react';
import {
    ButtonBase,
    Grid,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom-v5-compat';
import {
    DownloadIcon,
    DuplicateIcon,
    EditIcon,
    PreviewIcon,
    SendIcon,
    DeleteIcon,
    ConvertIconGrey,
    TagsIconGrey
} from '@/resources/icons';
import Tooltip from '../../Tooltip';
import { TableTagPills } from '../../MobileItemTags';
import useStyles from './styles';

/**
 * Tooltip wrapper component to handle showing/hiding of tooltips based on screen size.
 * @param {node} children The content to wrap in the tooltip.
 * @param {string} tooltip The content to place in the tooltip.
 * @param {string} action The action type this tooltip will be applied to.
 * @param {array} tags The tags associated with this action, to show on the edit tag icon.
 * @param {bool} showAllTooltips Option to conditionally show tooltips or not.
 * @param {string} documentState The document state of the applicable document.
 * @returns {node} The wrapped content.
 */
const WrapInTooltip = ({
    children,
    tooltip,
    action,
    tags,
    showAllTooltips,
    documentState
}) => {
    const classes = useStyles();

    if (action === 'tags' && tags.length > 0) {
        const tagArray = tags.map(({ name, color, backgroundColor }) => (
            <Grid item key={`tag-${name}`}>
                <TableTagPills
                    name={name}
                    color={color}
                    backgroundColor={backgroundColor}
                />
            </Grid>
        ));

        return (
            <Tooltip
                trueChildren
                title={
                    <Grid container direction="column" rowSpacing={1}>
                        {tagArray.slice(0, 3)}
                        {tagArray.length > 3 && (
                            <Grid item>
                                <TableTagPills
                                    name={`+${tagArray.length - 3} more`}
                                    backgroundColor="#FFFFFF"
                                    color="#000000"
                                />
                            </Grid>
                        )}
                    </Grid>
                }
                classSettings={{
                    tooltip: classes.tagTooltip,
                    popper: classes.tooltipPopper
                }}
                placement="top"
                disableArrow
                disableClickableTooltip
            >
                {children}
            </Tooltip>
        );
    }

    if (
        showAllTooltips ||
        // Override to show info about how only approved estimates can be converted
        (action === 'convert' && documentState !== 'approved')
    ) {
        return (
            <Tooltip title={tooltip} placement="top" trueChildren>
                {children}
            </Tooltip>
        );
    }

    return children;
};

WrapInTooltip.propTypes = {
    children: PropTypes.node.isRequired,
    tooltip: PropTypes.string,
    action: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.object),
    showAllTooltips: PropTypes.bool,
    documentState: PropTypes.string
};

WrapInTooltip.defaultProps = {
    tooltip: '',
    tags: [],
    showAllTooltips: false,
    documentState: undefined
};

const WrapInGridOnMobile = ({ children, isMobile }) =>
    isMobile ? (
        <Grid item xs={6} data-testid="actionicon-grid-wrapper">
            {children}
        </Grid>
    ) : (
        children
    );

WrapInGridOnMobile.propTypes = {
    children: PropTypes.node.isRequired,
    isMobile: PropTypes.bool
};

WrapInGridOnMobile.defaultProps = {
    isMobile: false
};

/**
 * Wrapper component to create action buttons for using within tables.
 * @param {func} onClick Function to fire onclick of icon.
 * @param {string} action The icon to show as the action.
 * @param {bool} buttonDisabled Option to show the button as disabled.
 * @param {string} documentState documentState of the applicable document.
 * @param {string} label Name of the icon, to be shown on mobile.
 * @param {string} link Path to route if being used as 'a href' element.
 * @param {string} listOnTablet Convert to a list format when at tablet size.
 * @param {string} tooltip The content to place in the tooltip.
 */
const ActionIconWrapper = ({
    action,
    buttonDisabled,
    documentState,
    onClick,
    label,
    link,
    listOnTablet,
    tooltip,
    count,
    tags
}) => {
    const classes = useStyles({ action, listOnTablet, buttonDisabled });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const showAllTooltips = useMediaQuery(theme.breakpoints.only('xl'));

    const icons = {
        download: <DownloadIcon />,
        duplicate: <DuplicateIcon />,
        edit: <EditIcon className={classes.editSvg} />,
        view: <PreviewIcon className={classes.previewSvg} />,
        send: <SendIcon className={classes.sendSvg} />,
        delete: <DeleteIcon className={classes.deleteSvg} />,
        convert: <ConvertIconGrey className={classes.convertSvg} />,
        tags: <TagsIconGrey className={classes.tagSvg} />
    };

    return (
        <WrapInGridOnMobile isMobile={isMobile}>
            <WrapInTooltip
                tooltip={tooltip}
                action={action}
                tags={tags}
                showAllTooltips={showAllTooltips}
                documentState={documentState}
            >
                <ButtonBase
                    onClick={onClick}
                    className={`${
                        isMobile ? classes.mobileButton : classes.button
                    } ${buttonDisabled ? classes.buttonDisabled : ''}`}
                    name={action}
                    component={link ? Link : 'button'}
                    to={link}
                    disableRipple={buttonDisabled}
                    id={`table-action-button-${action}`}
                >
                    {isMobile ? (
                        <>
                            <Typography
                                className={classes.mobileButtonText}
                                variant="body2"
                            >
                                {label}
                            </Typography>
                            <div className={classes.mobileIcon}>
                                {icons[action]}
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                className={
                                    action !== 'tags'
                                        ? classes.icon
                                        : classes.tagButton
                                }
                            >
                                {icons[action]}
                                {count >= 0 && (
                                    <Typography className={classes.count}>
                                        {count}
                                    </Typography>
                                )}
                            </div>
                            <span className={classes.label}>{label}</span>
                        </>
                    )}
                </ButtonBase>
            </WrapInTooltip>
        </WrapInGridOnMobile>
    );
};

const requiredPropsCheck = ({ onClick, link }) => {
    if (!onClick && !link) {
        return new Error(
            `One of 'onClick' or 'link' is required by 'ActionIconWrapper' component.`
        );
    }
    return null;
};

ActionIconWrapper.propTypes = {
    action: PropTypes.string.isRequired,
    buttonDisabled: PropTypes.bool,
    documentState: PropTypes.string,
    onClick: requiredPropsCheck,
    label: PropTypes.string,
    link: requiredPropsCheck,
    listOnTablet: PropTypes.bool,
    tooltip: PropTypes.string.isRequired,
    count: PropTypes.number,
    tags: PropTypes.arrayOf(PropTypes.object)
};

ActionIconWrapper.defaultProps = {
    buttonDisabled: false,
    documentState: undefined,
    onClick: undefined,
    label: null,
    link: undefined,
    listOnTablet: false,
    count: undefined,
    tags: []
};

export default ActionIconWrapper;
