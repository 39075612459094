import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { formatDate } from '@/util';
import styles from './styles';

const FreeTrialEnds = ({ trialEnds, locale, timezone }) => {
    const classes = styles();

    return (
        <Grid
            item
            container
            xs={12}
            sm={4}
            className={classes.summaryItemContainer}
        >
            <Grid
                item
                container
                alignItems="center"
                className={classes.summaryItemTitle}
            >
                <Grid item>
                    <Typography className={classes.summaryItemInfo}>
                        Free Trial Expires
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={7}>
                <Typography
                    color="textSecondary"
                    className={classes.summaryItemInfo}
                >
                    {formatDate({
                        isoDate: trialEnds,
                        locale,
                        timezone
                    })}
                </Typography>
            </Grid>
        </Grid>
    );
};

FreeTrialEnds.propTypes = {
    trialEnds: PropTypes.string,
    locale: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired
};

FreeTrialEnds.defaultProps = {
    trialEnds: undefined
};

export default FreeTrialEnds;
