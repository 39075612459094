import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material';
import { WarnConfirmDialog } from '@/components/common';
import { paymentMethodPreviewData } from '@/config';
import useStyles from './styles';

const PaymentPreview = ({ merchant, onConfirm }) => {
    const theme = useTheme();
    const classes = useStyles();

    return (
        <WarnConfirmDialog
            open
            confirmText="Back"
            cancelText={null}
            onConfirm={onConfirm}
            confirmColor={theme.palette.primary.main}
            onCancel={onConfirm}
            customIcon={
                <img
                    src={paymentMethodPreviewData[merchant]?.src}
                    alt={paymentMethodPreviewData[merchant]?.alt}
                    className={classes.image}
                />
            }
            message={paymentMethodPreviewData[merchant]?.message}
            title="What your customers will see"
            wrapperStyles={classes.wrapper}
            confirmButtonStyles={classes.button}
            disableOutside
        />
    );
};

export default PaymentPreview;

PaymentPreview.propTypes = {
    merchant: PropTypes.oneOf(['stripe', 'square', 'paypal']).isRequired,
    onConfirm: PropTypes.func.isRequired
};
