import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Grid } from '@mui/material';
import InvoiceMarkAsUnpaid from '@/components/MakeDocument/Sidebar/actions/InvoiceMarkAsUnpaid';
import InvoiceMarkAsPaid from '@/components/MakeDocument/Sidebar/actions/InvoiceMarkAsPaid';

const CheckPaymentAlert = ({
    documentId,
    isLoading,
    locked,
    method,
    state,
    beforeActionPromptOptions,
    setBeforeActionPromptOptions
}) => {
    if (!isLoading && locked && method && state === 'unpaid') {
        return (
            <Alert
                severity="warning"
                style={{
                    marginBottom: 16,
                    alignItems: 'center'
                }}
            >
                <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    justifyContent="space-between"
                    item
                    xs={12}
                >
                    <Grid item xs={12} sm={6} md={7} xl={8}>
                        Your client has submitted a payment via {method}.
                    </Grid>
                    <Grid item xs={15} sm={6} md={5} xl={4}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12} sm={6}>
                                <InvoiceMarkAsPaid />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InvoiceMarkAsUnpaid
                                    documentId={documentId}
                                    setBeforeActionPromptOptions={
                                        setBeforeActionPromptOptions
                                    }
                                    beforeActionPromptOptions={
                                        beforeActionPromptOptions
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Alert>
        );
    }

    return null;
};

export default CheckPaymentAlert;

CheckPaymentAlert.propTypes = {
    documentId: PropTypes.string,
    isLoading: PropTypes.bool,
    locked: PropTypes.bool,
    method: PropTypes.string,
    state: PropTypes.string,
    setBeforeActionPromptOptions: PropTypes.func,
    beforeActionPromptOptions: PropTypes.object
};

CheckPaymentAlert.defaultProps = {
    documentId: '',
    isLoading: false,
    locked: false,
    method: '',
    state: '',
    setBeforeActionPromptOptions: () => {},
    beforeActionPromptOptions: {}
};
