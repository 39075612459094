import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import { Grid, Typography } from '@mui/material';
import {
    CreditCardIcon,
    AmexRound,
    DiscoverRound,
    MastercardRound,
    VisaRound,
    UnionPayRound,
    PaypalIcon
} from '@/resources/icons/payment';
import useStyles from './styles';

const SubPaymentSource = ({ paymentMethod, subscriptionSource, isMobile }) => {
    const isIosSubscription = subscriptionSource === 'revenueCat';

    const classes = useStyles({ isIosSubscription });

    const getCardIcon = (card) => {
        switch (card) {
            case 'visa':
                return <VisaRound />;
            case 'mastercard':
                return <MastercardRound />;
            case 'discover':
                return <DiscoverRound />;
            case 'unionpay':
                return <UnionPayRound />;
            case 'amex':
                return <AmexRound />;
            case 'paypal_account':
                return <PaypalIcon className={classes.paypalIcon} />;
            default:
                return <CreditCardIcon />;
        }
    };

    const {
        cardType,
        expirationMonth,
        expirationYear,
        maskedCardNumber,
        paymentType
    } = paymentMethod || {};

    const cardDate =
        expirationYear &&
        format(
            new Date(expirationYear || 2030, expirationMonth - 1 || 0),
            'MM/yy'
        );

    const displayCardNum =
        maskedCardNumber?.slice(maskedCardNumber.length - 4) || '';

    return (
        <Grid item className={classes.payDetailContainer}>
            {!isIosSubscription && (
                <Grid container>
                    <Grid container alignItems="center">
                        <Grid className={classes.cardIcon}>
                            {getCardIcon(cardType || paymentType)}
                        </Grid>

                        <Typography
                            variant="h5"
                            className={classes.payInfoSubTitle}
                        >
                            {paymentType === 'paypal_account'
                                ? 'PayPal'
                                : `...${displayCardNum}`}
                        </Typography>
                    </Grid>

                    {cardDate && (
                        <Typography
                            variant="body2"
                            className={classes.payInfoSubText}
                        >
                            Expires {cardDate}
                        </Typography>
                    )}
                </Grid>
            )}
            {isIosSubscription && (
                <Grid>
                    <Typography
                        variant="h5"
                        className={classes.payInfoSubTitle}
                    >
                        {isMobile ? 'In-App Sub.' : 'In-App Subscription'}
                    </Typography>
                    <Typography
                        variant="body2"
                        className={classes.payInfoSubText}
                    >
                        Apple
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};

SubPaymentSource.propTypes = {
    paymentMethod: PropTypes.object,
    subscriptionSource: PropTypes.string,
    isMobile: PropTypes.bool
};

SubPaymentSource.defaultProps = {
    paymentMethod: undefined,
    subscriptionSource: '',
    isMobile: false
};

export default SubPaymentSource;
