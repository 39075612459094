/* eslint import/no-cycle: "off"  */
import store from '@/state/store';

import { globalPageCount } from '@/config';

import { convertTemporaryUserToUnregisteredUser } from '@/modules/setup';
import {
    createDiscount as createDiscountAction,
    deleteDiscount,
    fetchListingItems,
    noListingItems,
    overwriteListingItems,
    updateDiscount as updateDiscountAction,
    updateDiscounts,
    updateDiscountsComplete,
    updateDiscountsPending
} from '@/state/actions';
import { filterArgFlattener } from '@/util';
import axiosAuthenticated from '../authenticatedRequestor';

export const createDiscount = async (discount) => {
    if (store.getState().user.temporaryUser) {
        await convertTemporaryUserToUnregisteredUser();
    }

    const { businessId } = store.getState().user.activeBusiness;

    const response = await axiosAuthenticated.post(
        `/businesses/${businessId}/discounts`,
        discount
    );

    store.dispatch(createDiscountAction(response.data));

    return response.data;
};

export const getDiscountById = async (discountId) => {
    const { businessId } = store.getState().user.activeBusiness;

    const response = await axiosAuthenticated.get(
        `/businesses/${businessId}/discounts/${discountId}`
    );

    return response.data;
};

export const getBusinessDiscounts = async () => {
    if (store.getState().user.temporaryUser) {
        store.dispatch(updateDiscounts([]));
        return [];
    }
    store.dispatch(updateDiscountsPending());

    const { businessId } = store.getState().user.activeBusiness;

    const items = [];

    let morePages = true;
    let pageCount = 1;

    while (morePages) {
        // This returns 100 items as default.
        // eslint-disable-next-line no-await-in-loop
        const response = await axiosAuthenticated.get(
            `/businesses/${businessId}/discounts`,
            { params: { pageNumber: pageCount } }
        );

        // Add items to main array. Fallback to empty array if API failed/for some
        // reason returns nothing.
        items.push(...(response?.data?.items || []));

        pageCount += 1;

        // There are more pages if totalPages is larger than current page count.
        // Minus one page so it will fail if the same or smaller correctly.
        morePages =
            response.data.totalItems / response.data.pageSize > pageCount - 1;
    }

    store.dispatch(updateDiscounts(items));
    store.dispatch(updateDiscountsComplete());

    return items;
};

export const deleteBusinessDiscount = async (discountId) => {
    const { businessId } = store.getState().user.activeBusiness;

    await axiosAuthenticated.delete(
        `/businesses/${businessId}/discounts/${discountId}`
    );

    store.dispatch(deleteDiscount(discountId));
};

export const updateDiscount = async (discount) => {
    const { businessId } = store.getState().user.activeBusiness;

    const response = await axiosAuthenticated.put(
        `/businesses/${businessId}/discounts/${discount.discountId}`,
        discount
    );
    store.dispatch(updateDiscountAction(response.data));
};

export const getPaginatedDiscounts = async (args) => {
    if (store.getState().user.temporaryUser) {
        store.dispatch(noListingItems({ type: 'discount' }));
        return;
    }

    store.dispatch(fetchListingItems({ type: 'discount' }));

    const { businessId } = store.getState().user.activeBusiness;

    const allArgs = { pageSize: globalPageCount, ...filterArgFlattener(args) };

    const response = await axiosAuthenticated.get(
        `/businesses/${businessId}/discounts`,
        {
            params: {
                sortOrder: 'desc',
                sortType: 'createdAt',
                ...allArgs
            }
        }
    );

    const { items, itemsMatchingRequest, pageSize, totalItems } = response.data;

    const totalPages = Math.ceil(itemsMatchingRequest / pageSize);

    store.dispatch(
        overwriteListingItems({
            type: 'discount',
            items,
            totalPages,
            totalItems
        })
    );
};
