// Data to set the head of the table.
const tableHead = [
    {
        id: 'activity',
        label: 'ACTIVITY',
        sort: false
    },
    // { id: 'user', label: 'USER', sort: false },
    { id: 'itemid', label: 'ITEM', sort: false },
    {
        id: 'datetime',
        label: 'DATE/TIME',
        sort: false
    }
];

export default tableHead;
