/* eslint react/prop-types: 0 */
import React, { forwardRef } from 'react';
import TextField from '@mui/material/TextField';

const TextfieldRef = (props, ref) => {
    const { fullWidth, label, id, name, disabled } = props;

    return (
        <TextField
            {...props}
            disabled={disabled}
            fullWidth={fullWidth}
            id={id || name}
            inputRef={ref}
            label={label}
            name={name}
            variant="filled"
        />
    );
};

export default forwardRef(TextfieldRef);
