import { useDispatch, useSelector } from 'react-redux';
import { setDocumentErrors, resetDocumentErrors } from '@/state/actions';
import { createSaveableObject, verifyDocumentFields } from '@/util';

/**
 * Receipt Hook
 * Validates an receipt object.
 *
 * Returns false, or clean receipt data.
 */
export const useValidateReceipt = () => {
    const dispatch = useDispatch();
    const activeBusiness = useSelector((state) => state?.user?.activeBusiness);
    const businessProfileUpdated =
        activeBusiness?.flags?.businessProfileUpdated;

    const validateReceipt = async (receiptToValidate) => {
        const receiptData = createSaveableObject({
            type: 'receipt',
            currentObject: receiptToValidate,
            activeBusiness
        });

        const verifiedResult = await verifyDocumentFields(receiptData);

        if (!businessProfileUpdated)
            verifiedResult.errors = {
                ...verifiedResult.errors,
                from: {
                    companyName: {
                        message: 'A company is required.'
                    }
                }
            };

        if (!verifiedResult.success) {
            dispatch(setDocumentErrors(verifiedResult.errors));
        } else {
            dispatch(resetDocumentErrors());
        }

        if (!verifiedResult.success) {
            return false;
        }

        return receiptData;
    };

    return validateReceipt;
};
