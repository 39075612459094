import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    container: {
        marginBottom: 22
    },
    inputField: {
        '& label': {
            opacity: 0
        },
        '& .MuiFilledInput-input': {
            padding: '16px 12px'
        }
    },
    hiddenLabel: {
        opacity: 0
    },
    inputPadding: {
        '& .MuiFilledInput-input': {
            padding: '16px 16px'
        }
    },
    innerPadding: {
        padding: '12px 16px'
    },
    innerPaddingLocked: {
        padding: '5px 10px',
        [theme.breakpoints.up('sm')]: {
            padding: '12px 16px'
        },
        [theme.breakpoints.up('md')]: {
            padding: '12px 16px 12px 58px'
        }
    },
    innerPaddingSub: {
        [theme.breakpoints.up('sm')]: {
            marginRight: 15
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: 0,
            marginLeft: 30
        }
    },
    innerPaddingDate: {
        [theme.breakpoints.up('sm')]: {
            paddingRight: 15
        }
    },
    title: {
        fontSize: theme.typography.pxToRem(26),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            marginRight: theme.spacing(1),
            fontSize: theme.typography.pxToRem(20)
        }
    },
    subtitle: {
        fontSize: theme.typography.pxToRem(12),
        color: theme.palette.text.secondary,
        marginBottom: 10
    },
    lockedSubTitle: {
        fontSize: theme.typography.pxToRem(18),
        color: theme.palette.secondary.contrastText,
        width: '100%',
        letterSpacing: -0.5
    },
    lockedValue: {
        fontSize: theme.typography.pxToRem(14),
        color: '#334155',
        fontWeight: 400,
        letterSpacing: -0.5
    },
    iconHeight: {
        width: 24,
        height: 24,
        fontWeight: 400
    },
    upgradeButton: {
        width: '100%',
        pointerEvents: 'auto',
        backgroundColor: '#F5F5FA',
        color: '#475569',
        border: '1px solid #E0E5EE',
        [theme.breakpoints.up('sm')]: {
            width: 200,
            marginLeft: 0
        }
    },
    radioGroup: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-start',
            flexDirection: 'column'
        }
    },
    radioInput1: {
        padding: '2px 7px 3px 7px'
    },
    radioInput2: {
        padding: '2px 7px'
    },
    errorListUlStyle: {
        marginTop: 0,
        marginLeft: 0
    },
    errorListLiStyle: {
        color: theme.palette.error.main,
        fontWeight: 400,
        fontSize: 12
    }
}));
