import * as Sentry from '@sentry/react';

// eslint-disable-next-line import/no-cycle
import { onPageLoadSetup } from '@/modules/setup';

import store from '@/state/store';
import { loggedIn, resetDocument, resetState } from '@/state/actions';
import { getUserPool } from './cognitoHelpers';

/**
 * Get the current Cognito user.
 * @return {mixed} Returns user object or null
 */
export const getCurrentUser = () => {
    const userPool = getUserPool();
    return userPool.getCurrentUser();
};

/**
 * Returns a session from a logged in Cognito user.
 * @param object user Cognito user
 * @return promise session or error
 */
export const getSession = (user) =>
    new Promise((resolve) => {
        if (!user) {
            resolve(null);
        }

        user.getSession((err, session) => {
            if (err || !session || !session.isValid()) {
                resolve(null);
            } else {
                resolve(session);
            }
        });
    });

/**
 * Return user session token or error.
 * This function also refreshes token when called:
 * https://github.com/aws-amplify/amplify-js/blob/master/packages/amazon-cognito-identity-js/src/CognitoUser.js#L1366
 * Look through until line 1418 where tokens are refreshed.
 * @return Promise - token or error.
 */
export const getCurrentUserSession = async () => {
    const currentUser = getCurrentUser();
    try {
        const session = await getSession(currentUser);
        return session;
    } catch (e) {
        // TODO: Handle error.
        return null;
    }
};

/**
 * Return users JWT token or null.
 * This function also refreshes token when called:
 * https://github.com/aws-amplify/amplify-js/blob/master/packages/amazon-cognito-identity-js/src/CognitoUser.js#L1366
 * Look through until line 1418 where tokens are refreshed.
 * @return token or null.
 */
export const getUserJWT = async () => {
    const session = await getCurrentUserSession();
    if (session) {
        return session.idToken?.jwtToken || false;
    }
    return false;
};

/**
 * Get the current users email from the session
 * @return mixed Email or false
 */
export const getUserEmail = async () => {
    const session = await getCurrentUserSession();
    if (session) {
        const email = session?.idToken?.payload?.email || false;
        return email;
    }
    return false;
};

/**
 * Get the current user email and JWT
 * @return mixed object or false
 */
export const getUserEmailJwt = async () => {
    const session = await getCurrentUserSession();
    if (session) {
        const jwt = session.idToken?.jwtToken || false;
        const email = session?.idToken?.payload?.email || false;
        return { jwt, email };
    }
    return false;
};

/**
 * Refreshes user refresh token to keep them logged in for another hour.
 * Uses getUserJWT to check if session has been successfully refreshed.
 * @return {bool} true for success or false
 */
export const refreshUserToken = async () => {
    try {
        const token = await getUserJWT();
        if (token) {
            return true;
        }
        return false;
    } catch (e) {
        // TODO: Handle error.
        return false;
    }
};

/**
 * Check if current user has authorization. If not, log them out.
 */
export const logoutIfNotAuth = async () => {
    const userLoggedIn = await refreshUserToken();
    if (!userLoggedIn) {
        store.dispatch(loggedIn(false));
    }
};

/**
 * Get the code from the callback URL when a user logs in with OAuth.
 * @param  {string} url Full URL to grab varible from code param
 * @return {string} code param or null
 */
export const getLoginCodeFromURL = (url) => {
    const reg = /[?&]code=([^&#]*)/i;
    const code = reg.exec(url);
    return code ? code[1] : null;
};

/**
 * User can create a new account.
 * @param {string} username Users username.
 * @param {string} password Users password.
 * @param {string} recaptchaToken Validated token passed from Recaptcha.
 * @return {promise} Success or failure.
 */
export const createNewUserAccount = async (
    username,
    password,
    recaptchaToken
) => {
    const currentSession = await getCurrentUserSession();

    const clientMetadata = {
        accessTokenToMigrate: currentSession?.accessToken?.jwtToken,
        recaptchaToken
    };

    return new Promise((resolve, reject) => {
        const userPool = getUserPool();

        userPool.signUp(
            username,
            password,
            null,
            null,
            (error) => {
                if (error) {
                    reject(error);
                }
                resolve();
            },
            clientMetadata
        );
    });
};

/**
 * Change the current users password.
 * @param  {string} oldPassword Users old password
 * @param  {string} newPassword Users new password.
 * @return {promise} true on success, or error
 */
export const changePassword = (oldPassword, newPassword) =>
    new Promise((resolve, reject) => {
        const currentUser = getCurrentUser();

        if (!currentUser || currentUser == null) {
            reject(Error('no-user', 'User not provided'));
        }

        currentUser.getSession((err, session) => {
            if (err) {
                reject(err);
            }
            if (!session.isValid()) {
                reject(Error('invalid-session', 'Invalid user session'));
            }
            currentUser.changePassword(oldPassword, newPassword, (error) => {
                if (error) {
                    reject(error);
                }
                resolve(true);
            });
        });
    });

/**
 * Log out the current user using Cognito session.
 * Reset all state and refresh the window.
 */
export const logoutCurrentUser = async () => {
    store.dispatch(loggedIn(false));
    store.dispatch(resetState());
    store.dispatch(resetDocument());
    Sentry.setUser(null);

    const currentUser = getCurrentUser();
    if (currentUser) {
        await currentUser.signOut();
    }

    await onPageLoadSetup();
};

/**
 * Logout/remove the user's Cognito JWTs
 * without refreshing/resetting state
 */
export const removeUserJwts = async () => {
    const currentUser = getCurrentUser();

    if (currentUser) {
        await currentUser.signOut();
    }
};
