import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Grid, Switch as MuiSwitch, Typography } from '@mui/material';
import { ButtonLoading } from '@/components/common';
import {
    paymentMethodPreviewData,
    stripeCapabilities,
    stripeMethodCapabilityHash
} from '@/config';
import { PreviewIcon, WarningIcon } from '@/resources/icons';
import {
    removeThirdPartyConnection,
    updateBusinessSettings
} from '@/modules/dataWrangler/dataWrangler';
import { isMerchantCapabilityActive } from '@/util';
import PaymentChannelSwitchConfirmationModal from '../PaymentChannelSwitchConfirmationModal';
import QRCodeVerification from '../QRCodeVerification';
import PaymentPreview from '../PaymentPreview';

import useStyles from './styles';
import PaymentChannelUpdateModal from '../PaymentChannelUpdateModal';

const PaymentMethodAccordionInner = ({
    merchant,
    active,
    paymentChannels,
    connections,
    preferredPaymentConnections
}) => {
    const classes = useStyles();
    const [
        paymentChannelSwitchModalMerchant,
        setPaymentChannelSwitchModalMerchant
    ] = useState(null);
    const [previewMerchant, setPreviewMerchant] = useState(null);
    const [
        paymentChannelSwitchButtonLoading,
        setPaymentChannelSwitchButtonLoading
    ] = useState(false);
    const [
        paymentChannelUpdateButtonLoading,
        setPaymentChannelUpdateButtonLoading
    ] = useState(false);
    const [
        paymentChannelUpdateModalOptions,
        setPaymentChannelUpdateModalOptions
    ] = useState(null);
    const [disconnectPending, setDisconnectPending] = useState(false);

    const activeBusiness = useSelector((state) => state?.user?.activeBusiness);
    const businessSettings = activeBusiness?.businessSettings;

    const findConnection = (application) =>
        application === 'zelle'
            ? connections.find((item) =>
                  item?.application.includes(application)
              )
            : connections.find((item) => item?.application === application);

    const stripeConnection = findConnection('stripe');

    const nativeCashAppConnection = findConnection('cashapp');

    const zelleConnections = connections.filter((connection) =>
        connection.application?.includes('zelle')
    );
    const zelleConnectionIds = zelleConnections?.map(
        (connection) => connection.connectionId
    );

    const updateChannelSelection = ({
        paymentChannel,
        selectedConnection,
        enable,
        removeCashAppQrConnection
    }) => {
        const updatedPreferredPaymentConnections = {
            ...preferredPaymentConnections
        };

        const { connectionId } = selectedConnection;

        if (paymentChannel === 'otherConnections') {
            if (enable) {
                // Add the connectionId to the "otherConnections" array
                updatedPreferredPaymentConnections.otherConnections = [
                    ...updatedPreferredPaymentConnections.otherConnections,
                    connectionId
                ];
            } else {
                // Remove the connectionId from the "otherConnections" array
                updatedPreferredPaymentConnections.otherConnections =
                    preferredPaymentConnections.otherConnections.filter(
                        (item) => item !== connectionId
                    );
            }
        } else {
            // Update or clear the paymentChannel in regards to enabling/disabling
            updatedPreferredPaymentConnections[paymentChannel] = enable
                ? connectionId
                : '';
        }

        return updateBusinessSettings({
            ...activeBusiness,
            businessSettings: {
                ...businessSettings,
                preferredPaymentConnections: {
                    ...updatedPreferredPaymentConnections,
                    otherConnections: removeCashAppQrConnection
                        ? updatedPreferredPaymentConnections?.otherConnections?.filter(
                              (item) =>
                                  item !== nativeCashAppConnection?.connectionId
                          )
                        : updatedPreferredPaymentConnections?.otherConnections
                }
            }
        });
    };

    const handlePaymentChannelUpdate = (channel, slug) => {
        // Note that for Zelle connections, it doesn't matter which one we select (QR, email, or phone)
        // as long as it is Zelle. They are all disconnected together
        // so we can assume that if one is preferred then the others are too.
        const selectedConnection = connections.find(
            (connection) =>
                connection.application?.includes(slug) && connection.connected
        );
        const selectedPaymentChannel = preferredPaymentConnections[channel];

        let paymentChannelAlreadyConnected;

        if (slug !== 'zelle') {
            paymentChannelAlreadyConnected =
                channel === 'otherConnections'
                    ? selectedPaymentChannel.find(
                          (item) => item === selectedConnection?.connectionId
                      )
                    : selectedPaymentChannel ===
                      selectedConnection?.connectionId;
        } else {
            paymentChannelAlreadyConnected = selectedPaymentChannel.find(
                (item) => zelleConnectionIds?.includes(item)
            );
        }

        // If the connection is Zelle, we must find the specific connection found in preferredPaymentConnections
        const connectionToUpdate =
            slug === 'zelle'
                ? connections.find(
                      (connection) =>
                          connection.connectionId ===
                          paymentChannelAlreadyConnected
                  )
                : selectedConnection;

        if (
            channel !== 'otherConnections' &&
            selectedPaymentChannel &&
            selectedPaymentChannel !== selectedConnection?.connectionId
        ) {
            setPaymentChannelSwitchModalMerchant({
                slug,
                selectedConnection,
                channel
            });
            return;
        }

        setPaymentChannelUpdateModalOptions({
            merchant: slug,
            paymentChannel: channel,
            enable: !paymentChannelAlreadyConnected,
            selectedConnection: connectionToUpdate || selectedConnection
        });
    };

    const removeConnectionFromPreferredPayment = (connectionToRemove) => {
        const updatedPreferredPaymentConnections = {
            ...preferredPaymentConnections,
            creditCard:
                preferredPaymentConnections?.creditCard ===
                connectionToRemove?.connectionId
                    ? ''
                    : preferredPaymentConnections?.creditCard,
            digitalWallet:
                preferredPaymentConnections?.digitalWallet ===
                connectionToRemove?.connectionId
                    ? ''
                    : preferredPaymentConnections?.digitalWallet,
            bankTransfer:
                preferredPaymentConnections?.bankTransfer ===
                connectionToRemove?.connectionId
                    ? ''
                    : preferredPaymentConnections?.bankTransfer
        };

        return updateBusinessSettings({
            ...activeBusiness,
            businessSettings: {
                ...businessSettings,
                preferredPaymentConnections: updatedPreferredPaymentConnections
            }
        });
    };
    const handleDisconnectButton = async () => {
        setDisconnectPending(true);
        const connectionToRemove = findConnection(merchant.slug);
        await removeConnectionFromPreferredPayment(connectionToRemove);
        await removeThirdPartyConnection(connectionToRemove);
        setDisconnectPending(false);
    };

    const enabledPaymentSwitches = paymentChannels?.reduce(
        (enabledChannels, paymentChannel) => {
            if (
                merchant?.slug !== 'stripe' ||
                isMerchantCapabilityActive(
                    paymentChannel?.name,
                    stripeConnection
                )
            ) {
                enabledChannels.push(paymentChannel?.name);
            }
            return enabledChannels;
        },
        []
    );

    const checkAllStripeCapabilityStates = (state) =>
        merchant?.slug === 'stripe' &&
        stripeConnection &&
        stripeCapabilities.some((capability) =>
            // If we're checking for inactive capabilities, we also need to make sure that the capability exists as well.
            // If the capability does not exist, we treat it as inactive.
            state === 'inactive'
                ? !stripeConnection.capabilities?.[capability] ||
                  stripeConnection.capabilities?.[capability] === state
                : stripeConnection.capabilities?.[capability] === state
        );

    const capabilitiesInactive = checkAllStripeCapabilityStates('inactive');
    const capabilitiesPending = checkAllStripeCapabilityStates('pending');

    const getMerchantCardIconClass = (slug) => {
        if (
            merchant?.slug !== 'stripe' ||
            !stripeConnection ||
            !stripeConnection?.enabled
        ) {
            return classes.merchantCardIconContainer;
        }

        const methodCapability = stripeMethodCapabilityHash[slug];

        return stripeConnection?.capabilities?.[methodCapability] === 'active'
            ? classes.merchantCardIconContainer
            : `${classes.merchantCardIconContainer} ${classes.inactiveMerchantCard}`;
    };

    const isQrMerchant =
        merchant.slug === 'venmo' ||
        merchant.slug === 'cashapp' ||
        merchant.slug === 'zelle';

    return (
        <>
            <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                direction="row"
            >
                <Grid item xs={12}>
                    <Typography variant="body2">
                        Payments processed with {merchant.name} will
                        {isQrMerchant ? <strong> not </strong> : ' '}
                        automatically update the payment status of Invoice Maker
                        documents.
                    </Typography>
                </Grid>
                {merchant?.acceptedCards.length !== 0 && (
                    <Grid
                        item
                        xs={12}
                        md={3}
                        className={classes.merchantSectionContainer}
                    >
                        <Grid
                            item
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            className={classes.merchantTitleAndSwitch}
                        >
                            <Typography
                                component="label"
                                htmlFor={
                                    merchant.slug !== 'paypal'
                                        ? `credit-card-switch-${merchant.slug}`
                                        : ''
                                }
                                variant="h3"
                            >
                                Credit Cards
                            </Typography>
                            {active && merchant.slug !== 'paypal' && (
                                <MuiSwitch
                                    id={`credit-card-switch-${merchant.slug}`}
                                    data-testid={`credit-card-switch-${merchant.slug}`}
                                    name="card"
                                    color="primary"
                                    checked={paymentChannels[0]?.enabled}
                                    onClick={() =>
                                        handlePaymentChannelUpdate(
                                            'creditCard',
                                            merchant.slug
                                        )
                                    }
                                    disabled={
                                        !enabledPaymentSwitches?.includes(
                                            'Credit Cards'
                                        )
                                    }
                                />
                            )}
                        </Grid>

                        <Grid item xs={8}>
                            {merchant?.acceptedCards.map((card) => (
                                <span
                                    key={`${merchant.slug}-${card.name}`}
                                    className={getMerchantCardIconClass(
                                        card.slug
                                    )}
                                >
                                    {card.icon}
                                </span>
                            ))}
                        </Grid>
                    </Grid>
                )}

                {(merchant?.acceptedWallets.length !== 0 ||
                    (merchant?.isDigitalWallet && active)) && (
                    <Grid
                        item
                        xs={12}
                        md={3}
                        className={classes.merchantSectionContainer}
                    >
                        <Grid
                            item
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            className={classes.merchantTitleAndSwitch}
                        >
                            <Typography
                                component="label"
                                htmlFor={`digital-wallet-switch-${merchant.slug}`}
                                variant="h3"
                            >
                                Digital Wallet
                                {merchant?.acceptedWallets?.length !== 0 && 's'}
                            </Typography>
                            {active && merchant.slug !== 'paypal' && (
                                <MuiSwitch
                                    id={`digital-wallet-switch-${merchant.slug}`}
                                    data-testid={`digital-wallet-switch-${merchant.slug}`}
                                    color="primary"
                                    checked={
                                        merchant?.isDigitalWallet
                                            ? paymentChannels[3]?.enabled
                                            : paymentChannels[1]?.enabled
                                    }
                                    onClick={() =>
                                        handlePaymentChannelUpdate(
                                            merchant.slug === 'stripe' ||
                                                merchant.slug === 'square'
                                                ? 'digitalWallet'
                                                : 'otherConnections',
                                            merchant.slug
                                        )
                                    }
                                    disabled={
                                        !enabledPaymentSwitches?.includes(
                                            merchant?.acceptedWallets
                                                ?.length !== 0
                                                ? 'Digital Wallets'
                                                : 'Digital Wallet'
                                        )
                                    }
                                />
                            )}
                        </Grid>
                        {merchant?.acceptedWallets.length !== 0 && (
                            <Grid item xs={8}>
                                {merchant?.acceptedWallets.map((wallet) => (
                                    <span
                                        key={`${merchant.slug}-${wallet.name}`}
                                        className={getMerchantCardIconClass(
                                            wallet.slug
                                        )}
                                    >
                                        {wallet.icon}
                                    </span>
                                ))}
                            </Grid>
                        )}
                    </Grid>
                )}

                {merchant?.acceptedBanks.length !== 0 && (
                    <Grid
                        item
                        xs={12}
                        md={3}
                        className={classes.merchantSectionContainer}
                    >
                        <Grid
                            item
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            className={classes.merchantTitleAndSwitch}
                        >
                            <Typography
                                component="label"
                                htmlFor={`bank-transfer-switch-${merchant.slug}`}
                                variant="h3"
                            >
                                Bank Transfer
                            </Typography>
                            {active && merchant.slug !== 'paypal' && (
                                <MuiSwitch
                                    id={`bank-transfer-switch-${merchant.slug}`}
                                    data-testid={`bank-transfer-switch-${merchant.slug}`}
                                    color="primary"
                                    checked={paymentChannels[2]?.enabled}
                                    onClick={() =>
                                        handlePaymentChannelUpdate(
                                            'bankTransfer',
                                            merchant.slug
                                        )
                                    }
                                    disabled={
                                        !enabledPaymentSwitches?.includes(
                                            'Bank Transfers'
                                        )
                                    }
                                />
                            )}
                        </Grid>

                        <Grid item xs={8}>
                            {merchant?.acceptedBanks.map((bank) => (
                                <span
                                    key={`${merchant.slug}-${bank.name}`}
                                    className={getMerchantCardIconClass(
                                        bank.slug
                                    )}
                                >
                                    {bank.icon}
                                </span>
                            ))}
                        </Grid>
                    </Grid>
                )}

                <Grid
                    item
                    xs={12}
                    md={3}
                    className={classes.merchantButtonContainer}
                >
                    {!active &&
                        merchant.slug !== 'zelle' &&
                        !merchant.isDigitalWallet && (
                            <Button
                                className={classes.previewButton}
                                variant="contained"
                                startIcon={
                                    <PreviewIcon
                                        className={classes.previewButton}
                                    />
                                }
                                onClick={() =>
                                    setPreviewMerchant(merchant.slug)
                                }
                                color="secondary"
                            >
                                Preview
                            </Button>
                        )}
                </Grid>

                {isQrMerchant && (
                    <Grid item xs={12}>
                        <QRCodeVerification
                            allConnections={connections}
                            connection={findConnection(merchant.slug)}
                            exampleImage={
                                paymentMethodPreviewData[merchant.slug]
                                    ?.qrExample ||
                                paymentMethodPreviewData[merchant.slug].src
                            }
                            merchant={merchant}
                            UploadInstructions={
                                paymentMethodPreviewData[merchant.slug]
                                    .instructions
                            }
                        />
                    </Grid>
                )}
                {!merchant.isDigitalWallet && active && (
                    <Grid
                        item
                        container
                        xs={12}
                        className={classes.disconnectContainer}
                    >
                        {(capabilitiesInactive || capabilitiesPending) && (
                            <Grid
                                item
                                container
                                sm={8}
                                xs={12}
                                className={classes.capabilitiesWarningContainer}
                            >
                                <WarningIcon
                                    className={classes.capabilitiesWarningIcon}
                                />
                                {capabilitiesInactive &&
                                    !capabilitiesPending && (
                                        <Typography
                                            variant="body2"
                                            className={
                                                classes.capabilitiesWarningText
                                            }
                                        >
                                            One or more payment types are
                                            unavailable to connect via Stripe
                                            due to your Stripe account
                                            configuration.
                                        </Typography>
                                    )}

                                {capabilitiesInactive &&
                                    capabilitiesPending && (
                                        <Typography
                                            variant="body2"
                                            className={
                                                classes.capabilitiesWarningText
                                            }
                                        >
                                            Your Stripe payment integrations are
                                            pending. Some features may be
                                            unavailable due to your Stripe
                                            account configuration. Please check
                                            back later to confirm when these
                                            payment methods are active.
                                        </Typography>
                                    )}

                                {!capabilitiesInactive &&
                                    capabilitiesPending && (
                                        <Typography
                                            variant="body2"
                                            className={
                                                classes.capabilitiesWarningText
                                            }
                                        >
                                            Your Stripe payment integrations are
                                            pending. Please check back later to
                                            confirm when these payment methods
                                            are active.
                                        </Typography>
                                    )}
                            </Grid>
                        )}
                        <Grid
                            item
                            container
                            sm={
                                capabilitiesInactive || capabilitiesPending
                                    ? 4
                                    : 12
                            }
                            xs={12}
                            className={classes.disconnectButtonContainer}
                            justifyContent="flex-end"
                        >
                            <ButtonLoading
                                className={classes.disconnectButton}
                                color="primary"
                                disableOnLoading
                                loading={disconnectPending}
                                onClick={handleDisconnectButton}
                                variant="outlined"
                                spinnerColor="inherit"
                            >
                                Disconnect
                            </ButtonLoading>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {paymentChannelSwitchModalMerchant && (
                <PaymentChannelSwitchConfirmationModal
                    selectedMerchant={paymentChannelSwitchModalMerchant.slug}
                    paymentChannel={paymentChannelSwitchModalMerchant.channel}
                    onCancel={() => setPaymentChannelSwitchModalMerchant(null)}
                    onConfirm={async (removeCashAppQrConnection) => {
                        setPaymentChannelSwitchButtonLoading(true);
                        await updateChannelSelection({
                            paymentChannel:
                                paymentChannelSwitchModalMerchant.channel,
                            selectedConnection:
                                paymentChannelSwitchModalMerchant.selectedConnection,
                            enable: true,
                            removeCashAppQrConnection
                        });
                        setPaymentChannelSwitchButtonLoading(false);
                        setPaymentChannelSwitchModalMerchant(null);
                    }}
                    buttonLoading={paymentChannelSwitchButtonLoading}
                />
            )}
            {previewMerchant && (
                <PaymentPreview
                    merchant={previewMerchant}
                    onConfirm={() => setPreviewMerchant(null)}
                />
            )}
            {paymentChannelUpdateModalOptions && (
                <PaymentChannelUpdateModal
                    merchant={paymentChannelUpdateModalOptions.merchant}
                    paymentChannel={
                        paymentChannelUpdateModalOptions.paymentChannel
                    }
                    enable={paymentChannelUpdateModalOptions.enable}
                    onConfirm={async (removeCashAppQrConnection) => {
                        setPaymentChannelUpdateButtonLoading(true);
                        await updateChannelSelection({
                            paymentChannel:
                                paymentChannelUpdateModalOptions.paymentChannel,
                            selectedConnection:
                                paymentChannelUpdateModalOptions.selectedConnection,
                            enable: paymentChannelUpdateModalOptions.enable,
                            removeCashAppQrConnection
                        });
                        setPaymentChannelUpdateButtonLoading(false);
                        setPaymentChannelUpdateModalOptions(null);
                    }}
                    onCancel={() => setPaymentChannelUpdateModalOptions(null)}
                    buttonLoading={paymentChannelUpdateButtonLoading}
                />
            )}
        </>
    );
};

PaymentMethodAccordionInner.propTypes = {
    merchant: PropTypes.object.isRequired,
    active: PropTypes.bool,
    paymentChannels: PropTypes.arrayOf(PropTypes.object).isRequired,
    connections: PropTypes.arrayOf(PropTypes.object),
    preferredPaymentConnections: PropTypes.object.isRequired
};

PaymentMethodAccordionInner.defaultProps = {
    active: false,
    connections: []
};

export default PaymentMethodAccordionInner;
