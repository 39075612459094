/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { DrawerHeading, UncontrolledGenericDrawer } from '@/components/common';
import { ClientsActive } from '@/resources/icons';
import { closeDrawer, resetBillFromErrors } from '@/state/actions';
import { updateBusinessSettings } from '@/modules/dataWrangler/dataWrangler';
import useStyles from './styles';
import StepperHead from './StepperHead';
import AddressStep from './steps/Address';
import InformationStep from './steps/Information';
import LocationStep from './steps/Location';

const BillFromDrawer = ({ open }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [activeStep, setActiveStep] = useState(0);
    const [clickedOutside, setClickedOutside] = useState(false);
    const [loading, setLoading] = useState(false);

    const activeBusiness =
        useSelector((state) => state?.user?.activeBusiness) || {};

    const documentCustomizations = activeBusiness?.documentCustomizations;
    const businessProfile = activeBusiness?.businessProfile;
    const businessSettings = activeBusiness?.businessSettings;

    const reminders = useSelector((state) => state?.makeInvoice?.reminders);

    const handleClickOutside = () => {
        setClickedOutside(true);
    };

    const goToNextStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleOnSave = async () => {
        if (loading) {
            return;
        }

        const updatedSettings = {
            businessProfile,
            businessSettings,
            documentCustomizations,
            reminders
        };

        setLoading(true);
        setClickedOutside(false);
        await updateBusinessSettings(updatedSettings);

        dispatch(resetBillFromErrors());

        setLoading(false);
        setActiveStep(0);
        dispatch(closeDrawer('billFrom'));
    };

    return (
        <UncontrolledGenericDrawer
            open={open}
            close={handleClickOutside}
            id="billFrom"
        >
            <DrawerHeading
                title="Bill From"
                startAdornment={<ClientsActive />}
            />
            <div className={classes.drawer}>
                <StepperHead
                    activeStep={activeStep}
                    steps={['Information', 'Address', 'Location']}
                />

                {activeStep === 0 && (
                    <InformationStep
                        clickedOutside={clickedOutside}
                        goToNextStep={goToNextStep}
                        fields={businessProfile}
                    />
                )}
                {activeStep === 1 && (
                    <AddressStep
                        clickedOutside={clickedOutside}
                        goToNextStep={goToNextStep}
                        fields={businessProfile}
                    />
                )}
                {activeStep === 2 && (
                    <LocationStep
                        clickedOutside={clickedOutside}
                        fields={businessSettings}
                        handleOnSave={handleOnSave}
                        loading={loading}
                    />
                )}
            </div>
        </UncontrolledGenericDrawer>
    );
};

BillFromDrawer.propTypes = {
    open: PropTypes.bool
};

BillFromDrawer.defaultProps = {
    open: false
};

export default BillFromDrawer;
