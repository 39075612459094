export const thirdPartyConnectionLimits = {
    affirm: {
        min: 5000,
        max: 3000000
    },
    afterpay: {
        min: 100,
        max: 200000
    },
    klarna: {
        min: 1000,
        max: 299800
    },
    'creditCard-stripe': {
        min: 100,
        max: 3000000
    },
    'creditCard-square': {
        min: 100,
        max: 5000000
    },
    'apple-pay': {
        min: 100,
        max: 5000000
    },
    cashapp: {
        min: 1,
        max: 10000000000
    },
    squarecashapp: {
        min: 100,
        max: 5000000
    },
    venmo: {
        min: 1,
        max: 10000000000
    },
    'google-pay': {
        min: 100,
        max: 5000000
    },
    'ach-wire-transfer': {
        min: 100,
        max: 5000000
    },
    'ach-direct-debit': {
        min: 100,
        max: 3000000
    },
    zelle: {
        min: 1,
        max: 10000000000
    }
};

export const failedPaymentErrors = [
    {
        nativeMessage: `The customer's bank account has been closed.`,
        displayMessage: `The customer's bank account has been closed.`,
        displayError: 'Account Closed'
    },
    {
        nativeMessage: `The customer's account has insufficient funds to cover this payment.`,
        displayMessage: `The customer's account has insufficient funds to cover this payment.`,
        displayError: 'Insufficient Funds'
    },
    {
        nativeMessage: `The customer's bank account could not be located.`,
        displayMessage: `The customer's bank account could not be located.`,
        displayError: 'Payment Failed'
    },
    {
        nativeMessage: `The customer has notified their bank that this payment was unauthorized.`,
        displayMessage: `The customer has notified their bank that this payment was unauthorized.`,
        displayError: 'Unauthorized'
    },
    {
        nativeMessage: `The payment failed.`,
        displayMessage: `The payment failed.`,
        displayError: 'Payment Failed'
    },
    {
        nativeMessage: `Your card was declined.`,
        displayMessage: `The customer's card was declined.`,
        displayError: 'Card Declined'
    },
    {
        nativeMessage: `Your card has insufficient funds.`,
        displayMessage: `The customer's card has insufficient funds to cover this payment.`,
        displayError: 'Insufficient Funds'
    },
    {
        nativeMessage: `Your card has expired.`,
        displayMessage: `The customer's card has expired`,
        displayError: 'Card Expired'
    },
    {
        nativeMessage: `Your card's security code is incorrect.`,
        displayMessage: `The customer's card's security code is incorrect.`,
        displayError: 'Incorrect CVC'
    },
    {
        nativeMessage: `An error occurred while processing your card. Try again in a little bit.`,
        displayMessage: `An error occurred while processing the customer's card. Try again in a little bit.`,
        displayError: 'Processing Error'
    },
    {
        nativeMessage: `lost_or_stolen_card`,
        displayMessage: `This card has been marked as lost or stolen.`,
        displayError: 'Refund Failed'
    },
    {
        nativeMessage: `charge_for_pending_refund_disputed`,
        displayMessage: `This refund has been disputed.`,
        displayError: 'Refund Disputed'
    },
    {
        nativeMessage: `expired_or_canceled_card`,
        displayMessage: `This card has expired or been canceled.`,
        displayError: 'Refund Failed'
    },
    {
        nativeMessage: `insufficient_funds`,
        displayMessage: `Your account has insufficient funds to refund this payment.`,
        displayError: 'Insufficient Funds'
    },
    {
        nativeMessage: `declined`,
        displayMessage: `This refund has been declined.`,
        displayError: 'Declined'
    },
    {
        nativeMessage: `merchant_request`,
        displayMessage: `This refund has been canceled by the merchant.`,
        displayError: 'Refund Failed'
    },
    {
        nativeMessage: `unknown`,
        displayMessage: `An unknown error occured while processing this refund.`,
        displayError: 'Refund Failed'
    }
];
