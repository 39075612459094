import * as Sentry from '@sentry/react';
import { setGlobalError } from '@/actions';
import store from '@/state/store';
import {
    getLocalStorageItem,
    setLocalStorageItem
} from '@/modules/localStorageManager';
import { APP_VERSION } from '../version';

const isSentryError = (sentryObject, error) => {
    // Loop through exception.values array
    // Check if any of the 'stacktrace.frames' objects contain the error message within the 'module' property

    const { values } = sentryObject?.exception || {};
    if (values) {
        for (let i = 0; i < values.length; i += 1) {
            const { stacktrace, value, type } = values[i] || {};

            if (type) {
                if (type.includes(error)) {
                    return true;
                }
            }

            if (value) {
                if (value.includes(error)) {
                    return true;
                }
            }

            if (stacktrace) {
                const { frames } = stacktrace;
                if (frames) {
                    for (let j = 0; j < frames.length; j += 1) {
                        const { module } = frames[j];
                        if (module) {
                            if (module.includes(error)) {
                                return true;
                            }
                        }
                    }
                }
            }
        }
    }
    return false;
};

/**
 * Function to set up Sentry.
 * Adds current environment, and release version of the app as well as all standard
 * Sentry data. To use on a development env pass in true.
 * @param  {bool} testing Set to true, to run on a development environment.
 */
export const initSentry = (testing) => {
    let env = process.env.REACT_APP_AMPLIFY_ENV || false;

    if (testing) {
        env = 'development';
    }

    if (env) {
        Sentry.init({
            dsn: 'https://7978d79454a848489f6a124bf611071e@o388160.ingest.sentry.io/5224593',
            environment: env,
            release: `invoice_maker_react@${APP_VERSION}`,
            normalizeDepth: 10, // Change this if Sentry is clipping Redux state.
            beforeSend(event) {
                let showGlobalError = true;

                // If adblocker error, ignore
                if (
                    isSentryError(
                        event,
                        '?(pagead/viewthroughconversion/669990609/)'
                    )
                ) {
                    return null;
                }

                // Outlook link reader error
                if (
                    isSentryError(
                        event,
                        'Non-Error promise rejection captured with value: Object Not Found Matching Id:3'
                    )
                ) {
                    return null;
                }

                // If the title string includes "ChunkLoadError: Loading chunk" .
                if (isSentryError(event, 'ChunkLoadError')) {
                    // If the user has already refreshed the page, do not refresh again.
                    const alreadyRefreshedPage =
                        getLocalStorageItem('retry-lazy-refreshed') || false;

                    // Otherwise, refresh the page.
                    if (!alreadyRefreshedPage) {
                        setLocalStorageItem('retry-lazy-refreshed', true);
                        window.location.reload();

                        // Do not show an error - for this first attempt.
                        return null;
                    }

                    // Either way, dont show an error.
                    showGlobalError = false;
                }

                if (isSentryError(event, 'Network Error')) {
                    // If axios network error. Do not trigger global error.
                    showGlobalError = false;
                }

                if (event?.level === 'error' && showGlobalError) {
                    store.dispatch(setGlobalError());
                }

                return event;
            },
            beforeBreadcrumb(breadcrumb, hint) {
                if (breadcrumb.category === 'xhr') {
                    // This contains method, status code and URL.
                    // Add on response
                    const newBreadcrumb = {
                        data: {
                            ...breadcrumb.data,
                            response: hint.xhr.response
                        }
                    };
                    return newBreadcrumb;
                }
                return breadcrumb;
            }
        });
    }
};

// Set as global state so it can be built on as more data comes avalible.
const sentryUserData = {
    businessId: '',
    email: '',
    jwt: '',
    sessionId: ''
};

export const setSentryUser = ({ businessId, email, jwt, sessionId }) => {
    if (businessId) {
        sentryUserData.businessId = businessId;
    }
    if (email) {
        sentryUserData.email = email;
    }
    if (jwt) {
        sentryUserData.jwt = jwt;
    }
    if (sessionId) {
        sentryUserData.sessionId = sessionId;
    }
    // Every time setUser is called, it overwrites previous user data.
    Sentry.setUser(sentryUserData);
};
