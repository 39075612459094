import React from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import TagPill from '@/components/common/TagPill';

const AutocompleteMultiselect = ({
    classes,
    id,
    inputLabel,
    labelIdentifier,
    rhfName,
    options,
    helperText,
    rules,
    ...rest
}) => {
    const {
        field: { onChange, value },
        fieldState: { error }
    } = useController({
        name: rhfName,
        rules
    });

    return (
        <Autocomplete
            {...rest}
            multiple
            autoHighlight
            filterSelectedOptions
            forcePopupIcon={false}
            disableCloseOnSelect
            id={id}
            name={rhfName}
            options={options}
            getOptionLabel={(option) => option[labelIdentifier]}
            classes={classes}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={inputLabel}
                    name={`${rhfName}TextField`}
                    error={Boolean(error)}
                    helperText={Boolean(error) && helperText}
                />
            )}
            isOptionEqualToValue={(option, val) =>
                option[labelIdentifier] === val[labelIdentifier]
            }
            value={value}
            onChange={(_, v) => onChange(v)}
            renderTags={(val, getTagProps) =>
                val.map((option, index) => (
                    <TagPill
                        {...getTagProps({ index })}
                        name={option.name}
                        color={option.color}
                        backgroundColor={option.backgroundColor}
                        onClick={() => {
                            const { onDelete } = getTagProps({ index });
                            onDelete();
                        }}
                        classOverride={classes.multiselectTagPill}
                    />
                ))
            }
        />
    );
};

AutocompleteMultiselect.propTypes = {
    classes: PropTypes.object,
    id: PropTypes.string.isRequired,
    helperText: PropTypes.string,
    inputLabel: PropTypes.string.isRequired,
    labelIdentifier: PropTypes.string.isRequired,
    rhfName: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.object),
    rules: PropTypes.object
};

AutocompleteMultiselect.defaultProps = {
    classes: {},
    helperText: '',
    options: [],
    rules: {}
};

export default AutocompleteMultiselect;
