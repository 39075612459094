import React from 'react';
import { Grid } from '@mui/material';
import { useDrawers } from '@/hooks';
import { PRODUCT_BASE_PARAM } from '@/constants';
import { FilterForm, Price, Tags } from '../formComponents';

const ProductFilterDrawer = () => {
    const { isDrawerActive } = useDrawers();
    const drawerOpen = isDrawerActive('productFilter');

    const defaultValues = {
        price: {
            min: '',
            max: ''
        },
        tags: []
    };

    return (
        <FilterForm
            defaultValues={defaultValues}
            id="FilterProducts"
            open={drawerOpen}
            paramBase={PRODUCT_BASE_PARAM}
            title="Products"
            type="product"
        >
            <Grid item>
                <Price
                    heading="Price"
                    id="price"
                    maxTitle="Max"
                    minTitle="Min"
                />
                <Tags heading="Tags" id="tags" />
            </Grid>
        </FilterForm>
    );
};

export default ProductFilterDrawer;
