import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    billingSwitchGridContainer: {
        border: 'none',
        marginBottom: 8
    },
    billingSwitchContainer: {
        border: '1px solid #E0E5EE',
        borderRadius: 4,
        minWidth: 250,
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        padding: 4,
        backgroundColor: theme.palette.common.white
    },
    billingSwitchPayMonthly: {
        backgroundColor: 'transparent',
        padding: `10px 12px`,
        borderRadius: 4,
        cursor: 'pointer'
    },
    billingSwitchSelected: {
        backgroundColor: '#E1ECF7'
    },
    savePill: {
        color: '#ACF6DE',
        fontWeight: 600,
        backgroundColor: '#09533A',
        padding: `2px 8px`,
        borderRadius: 24,
        marginLeft: 8
    },

    savePillSelected: {
        color: '#09533A',
        backgroundColor: '#ACF6DE'
    },
    hiddenRadioButton: { display: 'none' }
}));
