import React from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Menu, MenuItem, Button, Typography } from '@mui/material';
import { Link } from '@/components/common';
import { accountMenuItems } from '@/config/menuItems';
import useStyles from './styles';

const UserDropdown = ({ userName }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpen = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <Button
                onClick={handleOpen}
                endIcon={<ExpandMoreIcon />}
                id="user-account-dropdown"
            >
                <Typography
                    variant="h4"
                    component="span"
                    className={classes.menuDropdownButton}
                >
                    {userName}
                </Typography>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.dropdownMenu}
            >
                {accountMenuItems.map((item) => {
                    const onClickFunc = () => {
                        if (item?.onClick) {
                            item.onClick();
                        }
                        handleClose();
                    };

                    return (
                        <MenuItem key={item.path} className={classes.menuItem}>
                            <Link
                                to={item.path}
                                className={classes.menuLink}
                                title={item?.linkTitle}
                                onClick={onClickFunc}
                            >
                                <div className={classes.menuIcon}>
                                    {item.icon}
                                </div>

                                <Typography
                                    variant="h4"
                                    component="span"
                                    color={
                                        item.title === 'Logout'
                                            ? 'error'
                                            : 'textPrimary'
                                    }
                                >
                                    {item.title}
                                </Typography>
                            </Link>
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};

UserDropdown.propTypes = {
    userName: PropTypes.string
};

UserDropdown.defaultProps = {
    userName: ''
};

export default UserDropdown;
