import loadable from '@loadable/component';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery, Skeleton } from '@mui/material';
import { NoEmailModal } from '@/components/common';
import {
    closeModal,
    setModal,
    setDocumentState,
    setTemporaryStorage
} from '@/state/actions';
import {
    updateClient,
    deleteReceipt,
    voidReceipt,
    updateReceipt
} from '@/modules/dataWrangler/dataWrangler';

import { useCheckAuthState, useDocumentFormData, useModals } from '@/hooks';

import {
    useSaveReceipt,
    useSendReceipt,
    useValidateReceipt
} from '@/hooks/useSidebarActions';
import store from '@/state/store';

import DocumentStateView from '../../DocumentStateView';
import {
    ReceiptAddPayment,
    ReceiptDelete,
    ReceiptPreviewAndSend,
    ReceiptSave,
    ReceiptVoid
} from '../actions';

import useStyles from '../styles';

import {
    deleteReceiptModalOptions,
    previewReceiptNegativeTotalModalOptions,
    saveNegativeReceiptTotalModalOptions,
    voidReceiptModalOptions,
    voidReceiptWithPaidDocumentStateOptions,
    addPaymentReceiptModalOptions,
    addPaymentNegativeReceiptTotalModalOptions
} from '../ModalCopy';

const PaymentModal = loadable(() => import('@/components/PaymentModal'));
const LottieModal = loadable(() => import(`@/components/common/LottieModal`));
const ShareLinkModal = loadable(() =>
    import(`@/components/common/ShareLinkModal`)
);
const LottieDownloadLoading = loadable(() =>
    import('@/lottie/LottieDownloadLoading')
);
const LottieSendingLoading = loadable(() =>
    import('@/lottie/LottieSendingLoading')
);
const LottiePiggyBank = loadable(() => import('@/lottie/LottiePiggyBank'));
const LottiePreviewLoading = loadable(() =>
    import('@/lottie/LottiePreviewLoading')
);

const ReceiptSidebar = ({
    beforeActionPromptOptions,
    setBeforeActionPromptOptions,
    handleSetLoginBlocker,
    locked
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Styling
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const hideDesktopSidebar = useMediaQuery(theme.breakpoints.down('xl'));

    // Hooks
    const { loadingUser } = useCheckAuthState();
    const { documentLoading } = useDocumentFormData();
    const { isModalActive } = useModals();
    const saveReceipt = useSaveReceipt();
    const sendReceipt = useSendReceipt();
    const validateReceipt = useValidateReceipt();

    const receipt = useSelector((state) => state.makeInvoice) || null;

    const showSkeletonLoaders = loadingUser || documentLoading;
    const documentId = receipt?.documentId || null;
    const addPaymentsAvailable = receipt?.documentState !== 'paid';
    const isVoid = receipt?.documentState === 'void';
    const showAddPayment = addPaymentsAvailable && !isVoid;

    const classes = useStyles({ shippingDisabled: Boolean(!receipt?.to) });

    const [savingForPreview, setSavingForPreview] = useState(false);
    const [onPaymentSuccess, setOnPaymentSuccess] = useState(() => {});

    const handleBlockReceiptCreation = async (receiptToValidate) => {
        const receiptData = await validateReceipt(receiptToValidate);
        const cleanTempData = {
            ...receiptData,
            products: receiptData.products.map((prod) => ({
                ...prod,
                productId: ''
            }))
        };

        dispatch(
            setTemporaryStorage({
                component: 'receipt',
                data: cleanTempData
            })
        );

        navigate('/settings/subscription');
        return false;
    };

    const setAddPaymentWarningActive = (onSuccess, actionType) => {
        const addPaymentReceiptModalOptionsWithAction =
            addPaymentReceiptModalOptions(actionType);

        setBeforeActionPromptOptions({
            ...beforeActionPromptOptions,
            ...addPaymentReceiptModalOptionsWithAction,
            onCancel: () =>
                setBeforeActionPromptOptions({
                    ...addPaymentReceiptModalOptionsWithAction,
                    open: false
                }),
            onConfirm: async () => {
                setOnPaymentSuccess(onSuccess);

                // Rather than get this info via a hook, this data must be fetched dynamically
                // to handle the case that the user's subscription state changes mid-flow,
                // such as signing up for a new account during the lock flow.
                const subInfo =
                    store.getState()?.user?.activeBusiness?.subscription
                        ?.components?.document;

                // Block new receipt creation if a user does not have any documents remaining on their plan
                // If a documentId exists, this document has already been created and does not need to be blocked
                const blockReceiptCreation =
                    !subInfo?.enabled ||
                    (subInfo?.limited &&
                        subInfo?.amountRemaining < 1 &&
                        !documentId);

                if (blockReceiptCreation) {
                    handleBlockReceiptCreation(receipt);
                    return false;
                }

                dispatch(setModal({ slug: 'process-receipt-payment-options' }));

                setBeforeActionPromptOptions({
                    ...addPaymentReceiptModalOptionsWithAction,
                    open: false
                });
                return true;
            },
            open: true
        });
    };

    const setSaveNegativeTotalWarningActive = () => {
        setBeforeActionPromptOptions({
            ...beforeActionPromptOptions,
            ...saveNegativeReceiptTotalModalOptions,
            onConfirm: () =>
                setBeforeActionPromptOptions({
                    ...saveNegativeReceiptTotalModalOptions,
                    open: false
                }),
            open: true
        });
    };

    const setPreviewNegativeTotalWarningActive = () => {
        setBeforeActionPromptOptions({
            ...beforeActionPromptOptions,
            ...previewReceiptNegativeTotalModalOptions,
            onConfirm: () =>
                setBeforeActionPromptOptions({
                    ...previewReceiptNegativeTotalModalOptions,
                    open: false
                }),
            open: true
        });
    };

    const setAddPaymentNegativeTotalWarningActive = () => {
        setBeforeActionPromptOptions({
            ...beforeActionPromptOptions,
            ...addPaymentNegativeReceiptTotalModalOptions,
            onConfirm: () =>
                setBeforeActionPromptOptions({
                    ...addPaymentNegativeReceiptTotalModalOptions,
                    open: false
                }),
            open: true
        });
    };

    const setDeleteReceiptWarningActive = () => {
        setBeforeActionPromptOptions({
            ...beforeActionPromptOptions,
            ...deleteReceiptModalOptions,
            onCancel: () =>
                setBeforeActionPromptOptions({
                    ...deleteReceiptModalOptions,
                    open: false
                }),
            onConfirm: async () => {
                setBeforeActionPromptOptions({
                    ...deleteReceiptModalOptions,
                    open: false
                });

                await deleteReceipt(documentId);

                navigate('/receipts');
            },
            open: true
        });
    };

    const setVoidReceiptWarningActive = () => {
        setBeforeActionPromptOptions({
            ...beforeActionPromptOptions,
            ...voidReceiptModalOptions,
            onCancel: () =>
                setBeforeActionPromptOptions({
                    ...voidReceiptModalOptions,
                    open: false
                }),
            onConfirm: async () => {
                await voidReceipt(receipt);

                setBeforeActionPromptOptions({
                    ...voidReceiptModalOptions,
                    open: false
                });
            },
            open: true
        });
    };

    const setVoidWithPaidDocumentStateActive = () => {
        setBeforeActionPromptOptions({
            ...beforeActionPromptOptions,
            ...voidReceiptWithPaidDocumentStateOptions,
            onConfirm: () => {
                setBeforeActionPromptOptions({
                    ...voidReceiptWithPaidDocumentStateOptions,
                    open: false
                });
            },
            open: true
        });
    };

    const processReceiptSend = async (receiptData, emailOverwrite) => {
        dispatch(closeModal('loader-manual-payment'));

        const sendComplete = await sendReceipt(receiptData, emailOverwrite);

        if (sendComplete !== false) {
            // navigate to unique page
            navigate(`/receipt/${sendComplete?.documentId}`);
        }
    };

    const processAddPayment = async (receiptData) => {
        dispatch(closeModal('loader-manual-payment'));

        if (receiptData === false) {
            return false;
        }

        if (receiptData) {
            navigate(`/receipt/${receiptData?.documentId}`);
        }

        return true;
    };

    return (
        <div className={classes.sidebar}>
            <div className={classes.container}>
                <ReceiptPreviewAndSend
                    onNegativeReceipt={setPreviewNegativeTotalWarningActive}
                    onLoginBlocker={handleSetLoginBlocker}
                    setLoading={setSavingForPreview}
                    loading={savingForPreview}
                    isDesktop={isDesktop}
                />

                <ReceiptVoid
                    onLoginBlocker={handleSetLoginBlocker}
                    onWarningActive={setVoidReceiptWarningActive}
                    onWarningPaidActive={setVoidWithPaidDocumentStateActive}
                />

                <ReceiptSave
                    onLoginBlocker={handleSetLoginBlocker}
                    onNegativeReceipt={setSaveNegativeTotalWarningActive}
                />

                <ReceiptDelete onClick={setDeleteReceiptWarningActive} />

                {showAddPayment && (
                    <ReceiptAddPayment
                        onLoginBlocker={handleSetLoginBlocker}
                        onDraftReceipt={() =>
                            setAddPaymentWarningActive(
                                () => processAddPayment,
                                'add payment'
                            )
                        }
                        onNegativeReceipt={
                            setAddPaymentNegativeTotalWarningActive
                        }
                        setOnPaymentSuccess={setOnPaymentSuccess}
                    />
                )}

                {!hideDesktopSidebar && locked && (
                    <div className={classes.section}>
                        {!showSkeletonLoaders ? (
                            <DocumentStateView documentType="receipt" />
                        ) : (
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={185}
                            />
                        )}
                    </div>
                )}

                {isModalActive('loader-receipt-send') && (
                    <LottieModal
                        title="Sending Receipt..."
                        backgroundColor="#F5F9FF"
                        open
                    >
                        <LottieSendingLoading />
                    </LottieModal>
                )}

                {isModalActive('loader-receipt-download') && (
                    <LottieModal
                        title="Downloading Receipt..."
                        backgroundColor="#F5F9FF"
                        open
                    >
                        <LottieDownloadLoading />
                    </LottieModal>
                )}

                {isModalActive('loader-manual-payment') && (
                    <LottieModal
                        title="Adding payment..."
                        backgroundColor="#F5F9FF"
                        open
                    >
                        <LottiePiggyBank />
                    </LottieModal>
                )}

                {isModalActive('loader-generate-receipt-link') && (
                    <LottieModal
                        title="Generating receipt link..."
                        backgroundColor="#F5F9FF"
                        open
                    >
                        <LottiePreviewLoading />
                    </LottieModal>
                )}

                <NoEmailModal
                    open={isModalActive('process-receipt-email-required')}
                    onClose={async (emailAddress, saveToClient, setLoading) => {
                        if (emailAddress === undefined) {
                            dispatch(
                                closeModal('process-receipt-email-required')
                            );
                            return;
                        }

                        setLoading(true);

                        const updatedClient = {
                            ...receipt.to,
                            emailAddress
                        };

                        const receiptWithClientEmail = {
                            ...receipt,
                            to: updatedClient
                        };

                        const validatedReceipt = await validateReceipt(
                            receiptWithClientEmail
                        );

                        if (saveToClient) {
                            updateClient(updatedClient);
                        }

                        dispatch(
                            setDocumentState({ document: validatedReceipt })
                        );

                        setLoading(false);

                        dispatch(closeModal('process-receipt-email-required'));

                        if (validatedReceipt?.documentState === 'draft') {
                            setAddPaymentWarningActive(
                                () => processReceiptSend,
                                'send'
                            );
                            return;
                        }

                        await processReceiptSend(
                            validatedReceipt,
                            emailAddress
                        );
                    }}
                    type="receipt"
                />

                {isModalActive('info-share-link') && (
                    <ShareLinkModal
                        open
                        onClose={() => dispatch(closeModal('info-share-link'))}
                        link={receipt?.link || ''}
                    />
                )}

                {isModalActive('process-receipt-payment-options') && (
                    <PaymentModal
                        open
                        onClose={() =>
                            dispatch(
                                closeModal('process-receipt-payment-options')
                            )
                        }
                        currentDocument={receipt}
                        isDesktop={isDesktop}
                        triggerAnimation={async () => {
                            dispatch(
                                setModal({ slug: 'loader-manual-payment' })
                            );
                        }}
                        onPreSuccess={async () => {
                            if (receipt?.documentState === 'draft') {
                                const receiptData = await validateReceipt(
                                    receipt
                                );

                                if (!receipt?.documentId) {
                                    const savedData = await saveReceipt(
                                        receiptData
                                    );

                                    if (savedData === false) {
                                        return false;
                                    }
                                    return savedData;
                                }

                                await updateReceipt({
                                    ...receiptData,
                                    tagIds:
                                        receiptData?.tags?.map(
                                            (tag) => tag?.tagId
                                        ) || []
                                });

                                return receiptData;
                            }

                            return receipt;
                        }}
                        onPostSuccess={onPaymentSuccess}
                    />
                )}
            </div>
        </div>
    );
};

ReceiptSidebar.propTypes = {
    beforeActionPromptOptions: PropTypes.shape({}),
    setBeforeActionPromptOptions: PropTypes.func.isRequired,
    loginModalOptions: PropTypes.shape({}),
    handleSetLoginBlocker: PropTypes.func.isRequired,
    locked: PropTypes.bool
};

ReceiptSidebar.defaultProps = {
    beforeActionPromptOptions: {},
    loginModalOptions: {},
    locked: false
};

export default ReceiptSidebar;
