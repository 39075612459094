/* eslint import/no-cycle: "off"  */
import store from '@/state/store';

import { globalPageCount } from '@/config';
import {
    addClient as addClientAction,
    deleteClient as deleteClientAction,
    fetchClients,
    fetchListingItems,
    noListingItems,
    overwriteClients as overwriteClientsAction,
    overwriteListingItems,
    updateClient as updateClientAction
} from '@/state/actions';
import { convertTemporaryUserToUnregisteredUser } from '@/modules/setup';
import { filterArgFlattener, getLocalizationAddressFormat } from '@/util';
import axiosAuthenticated from '../authenticatedRequestor';
import * as segmentManager from '../segment/segmentManager';

export const overwriteClients = async () => {
    store.dispatch(fetchClients());

    const { businessId } = store.getState().user.activeBusiness;
    const allCountries = store.getState().localization?.countries || [];

    const items = [];

    let morePages = true;
    let pageCount = 1;

    while (morePages) {
        // This returns 100 items as default.
        // eslint-disable-next-line no-await-in-loop
        const response = await axiosAuthenticated.get(
            `/businesses/${businessId}/clients`,
            {
                params: { pageNumber: pageCount }
            }
        );

        const clients =
            response?.data?.items?.map((client) => ({
                ...client,
                total: client.total || 0,
                totalPaid: client.totalPaid || 0,
                totalUnpaid: client.totalUnpaid || 0,
                billingAddress: {
                    ...client.billingAddress,
                    addressFormat: getLocalizationAddressFormat(
                        allCountries,
                        client.billingAddress.country
                    )
                }
            })) || [];

        items.push(...clients);

        pageCount += 1;

        // There are more pages if totalPages is larger than current page count.
        // Minus one page so it will fail if the same or smaller correctly.
        morePages =
            response.data.totalItems / response.data.pageSize > pageCount - 1;
    }

    store.dispatch(overwriteClientsAction(items));

    return items;
};

export const addClient = async (client) => {
    if (store.getState().user.temporaryUser) {
        await convertTemporaryUserToUnregisteredUser();
    }

    const { businessId } = store.getState().user.activeBusiness;

    const response = await axiosAuthenticated.post(
        `businesses/${businessId}/clients`,
        client
    );

    await segmentManager.clientCreated(response.data);
    store.dispatch(addClientAction(response.data));

    return response.data;
};

export const getClientById = async (clientId) => {
    const { businessId } = store.getState().user.activeBusiness;

    const response = await axiosAuthenticated.get(
        `/businesses/${businessId}/clients/${clientId}`
    );

    return response.data;
};

export const updateClient = async (client) => {
    const { businessId } = store.getState().user.activeBusiness;

    const response = await axiosAuthenticated.put(
        `/businesses/${businessId}/clients/${client.clientId}`,
        client
    );

    store.dispatch(updateClientAction(response?.data));
    return true;
};

export const deleteClient = async (clientId) => {
    const { businessId } = store.getState().user.activeBusiness;

    await axiosAuthenticated.delete(
        `/businesses/${businessId}/clients/${clientId}`
    );

    store.dispatch(deleteClientAction(clientId));
};

export const getPaginatedClients = async (args) => {
    if (store.getState().user.temporaryUser) {
        store.dispatch(noListingItems({ type: 'client' }));
        return;
    }

    store.dispatch(fetchListingItems({ type: 'client' }));

    const { businessId } = store.getState().user.activeBusiness;

    const allArgs = { pageSize: globalPageCount, ...filterArgFlattener(args) };

    const response = await axiosAuthenticated.get(
        `/businesses/${businessId}/clients`,
        {
            params: {
                sortOrder: 'desc',
                sortType: 'createdAt',
                ...allArgs
            }
        }
    );

    const { items, itemsMatchingRequest, pageSize, totalItems } = response.data;

    const totalPages = Math.ceil(itemsMatchingRequest / pageSize);

    store.dispatch(
        overwriteListingItems({ type: 'client', items, totalPages, totalItems })
    );
};
