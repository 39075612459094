import PropTypes from 'prop-types';
import { useCheckLoginStatus } from '@/hooks';

/**
 * @param {element} children React component to be shown.
 */
const InternalRoute = ({ children }) => {
    // Hook that fires on isAuthenticated state change/app rerender.
    // It checks the logged in state via Cognito and updates Redux if needed.
    // This also controls the activityMonitor and general setup of user data.
    // If the user is logged in, it refreshes their session.
    useCheckLoginStatus();

    return children;
};

InternalRoute.propTypes = {
    component: PropTypes.element
};

export default InternalRoute;
