import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => {
    const gridSettingsMobile = {
        gridTemplateRows: ({ notes }) => `repeat(${notes ? 4 : 3}, 1fr)`,
        gridTemplateColumns: ({ type, hasRefunds }) =>
            type === 'payment' && !hasRefunds ? '1fr .5fr' : '20px 1fr .5fr',
        gridTemplateAreas: ({ hasRefunds }) =>
            hasRefunds
                ? `'arrow label method'
                  'arrow price price'
                  'arrow date date'
                  'arrow note note'
                  'arrow refund refund' !important`
                : `'label method'
                  'price price'
                  'date date'
                  'note note'
                  'refund refund' !important`
    };

    const gridSettingsDesktop = {
        gridTemplateRows: ({ notes }) =>
            notes ? '1fr 1fr !important' : '1fr !important',
        gridTemplateColumns: '100px repeat(3, 1fr) .4fr !important',
        gridTemplateAreas: ({ type, hasRefunds }) =>
            type === 'payment' && hasRefunds
                ? `'label date price method refund'
                'note note note note note'
                'arrow . . . .' !important`
                : `'label date price method refund'
                    'note note note note note' !important`
    };

    return {
        listContainer: {
            ...gridSettingsMobile,
            padding: theme.spacing(3),
            width: '100%',
            minHeight: theme.typography.pxToRem(100),
            display: 'grid',
            backgroundColor: ({ type }) =>
                type === 'refund' && 'rgb(245, 245, 250)',
            borderTop: ({ isRefund }) =>
                isRefund ? '1px solid #E0E5EE' : '1px solid #F5F5FA',
            [theme.breakpoints.up('sm')]: {
                ...gridSettingsDesktop
            }
        },
        paymentLabelContainer: {
            gridArea: 'label',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            [theme.breakpoints.up('sm')]: {
                justifyContent: 'center'
            }
        },
        dateContainer: {
            gridArea: 'date',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '100%',
            color: '#656B7D',
            [theme.breakpoints.up('sm')]: {
                justifyContent: 'center',
                height: 'unset'
            }
        },
        priceContainer: {
            gridArea: 'price',
            marginTop: theme.spacing(1),
            [theme.breakpoints.up('sm')]: {
                marginTop: 'unset'
            }
        },
        paymentMethodContainer: {
            gridArea: 'method',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            height: '100%',
            [theme.breakpoints.up('sm')]: {
                height: 'unset',
                justifyContent: 'center'
            }
        },
        downArrowContainerPayment: {
            gridArea: 'arrow',
            height: '100%',
            color: 'white',
            display: ({ hasRefunds }) => (!hasRefunds ? 'none' : 'unset'),
            [theme.breakpoints.down('sm')]: {
                paddingTop: 15
            },
            [theme.breakpoints.up('sm')]: {
                display: ({ hasRefunds }) => (hasRefunds ? 'flex' : 'none'),
                position: 'absolute',
                left: 'calc(50% - 1px)',
                paddingTop: '0'
            }
        },
        downArrowContainerRefund: {
            gridArea: 'arrow',
            height: '100%',
            color: 'white',
            display: 'unset',
            paddingTop: 0,
            [theme.breakpoints.up('sm')]: {
                display: 'none'
            }
        },
        downArrowPayment: {
            borderTop: '2px solid #BEC1CC',
            borderLeft: '2px solid #BEC1CC',
            marginBottom: '-14px',
            height: 'calc(100% + 14px)',
            transform: 'translate(-10px, 0)',
            position: 'relative',
            [theme.breakpoints.up('sm')]: {
                borderTop: '2px solid #656B7D',
                borderLeft: '2px solid #656B7D',
                transform: 'translate(0, -5px)',
                height: 15,
                marginBottom: 0
            }
        },
        downArrowPaymentTriangle: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                position: 'absolute',
                bottom: -5,
                left: -5,
                display: 'block',
                width: 0,
                height: 0,
                borderLeft: '4px solid transparent',
                borderRight: '4px solid transparent',
                borderTop: '7px solid #656B7D'
            }
        },
        downArrowRefund: {
            borderLeft: '2px solid #BEC1CC',
            borderBottom: '2px solid #BEC1CC',
            height: 33,
            width: 15,
            transform: 'translate(-10px, -15px)',
            position: 'relative'
        },
        downArrowRefundTriangle: {
            width: 0,
            height: 0,
            borderTop: '4.5px solid transparent',
            borderBottom: '4.5px solid transparent',
            borderLeft: '8px solid #BEC1CC',
            position: 'absolute',
            bottom: -5,
            right: -6
        },
        refundButtonContainer: {
            gridArea: 'refund',
            width: '100%'
        },
        notesContainer: {
            gridArea: 'note',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '100%',
            display: 'flex',
            wordBreak: 'break-all',
            marginTop: ({ hasLast4, isRefund }) => {
                if (hasLast4) {
                    return 0;
                }
                return isRefund ? 14 : 7;
            },
            [theme.breakpoints.up('sm')]: {
                alignItems: 'center',
                justifyContent: 'flex-start',
                padding: theme.spacing(1),
                marginTop: ({ hasLast4 }) => (hasLast4 ? 0 : 7)
            }
        },
        secondaryInfoContainer: {
            gridArea: 'note',
            display: 'flex',
            marginTop: 4
        },
        failedPaymentReasonContainer: {
            width: 'fit-content',
            paddingLeft: 5.5,
            marginRight: 20
        },
        refundButton: {
            padding: theme.spacing(1.5)
        },
        pendingButton: {
            padding: theme.spacing(1.5),
            background: theme.palette.common.lightGray,
            border: '1px solid #E0E5EE',
            color: '#475569',
            width: 113,
            [theme.breakpoints.only('xs')]: {
                width: '100%'
            },
            '&:hover': {
                background: '#BDBDBD'
            }
        },
        helpIcon: {
            width: 20,
            height: 20
        }
    };
});
