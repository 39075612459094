import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as MuiCheckbox } from '@mui/material';
import { Controller } from 'react-hook-form';

/**
 * This component wraps the MUI Checkbox in a React Hook Forms Controller reducing
 * complexity.
 *
 * To use
 * ------
 * Make sure that the component is placed in a form which is wrapped in a FormProvider.
 *
 * Default value
 * --------
 * Checkboxes use 'checked', rather than default values. If you want a checkbox pre-checked,
 * set the value within 'useForm' defaultValues.
 *
 * @link: https://react-hook-form.com/
 *
 * @param {string} label Components label. Can be ignored if using a FormGroup.
 * @param {string} name The component name. This will be used by React Hook Forms when returning data.
 * @param {object} rules Any rules such as 'isRequired', or validation.
 * @param {string} id The component ID. If ignored will be set by 'name'.
 * @param {props} rest Any other props that you want to pass across to the component.
 */
const Checkbox = ({ label, name, id, rules, ...rest }) => (
    <Controller
        name={name}
        rules={rules}
        render={({ field }) => {
            const { value } = field;
            return (
                <MuiCheckbox
                    {...rest}
                    {...field}
                    id={id || name}
                    name={name}
                    checked={Boolean(value)}
                    label={label}
                />
            );
        }}
    />
);

Checkbox.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    rules: PropTypes.shape({}),
    id: PropTypes.string
};

Checkbox.defaultProps = {
    label: '',
    rules: {},
    id: ''
};

export default Checkbox;
