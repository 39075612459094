// Data to set the head of the table.
const tableHead = [
    {
        id: 'client',
        label: 'CLIENT',
        sort: true
    },
    {
        id: 'frequency',
        label: 'FREQUENCY'
    },
    { id: 'nextSendDate', label: 'NEXT SEND', sort: true },
    {
        id: 'total',
        label: 'Total',
        sort: true
    },
    {
        id: 'amountSent',
        label: 'SENT',
        sort: true
    },
    {
        id: 'state',
        label: 'STATUS',
        sort: true
    },
    {
        id: 'tags',
        label: 'TAGS'
    },
    {
        id: 'actions',
        label: ''
    }
];

export default tableHead;
