const draggedItem = {
    boxShadow: '0 0 20px 10px rgb(0, 0, 0, .1)'
};

const idleItem = {};

const baseItem = (theme) => ({
    transition: 'box-shadow .5s ease-in-out',
    background: '#FFF',
    borderRadius: 4,
    marginBottom: theme.spacing(2),
    border: '1px solid #E3E3EB'
});

const handleBarWidth = 43;

export default (theme) => ({
    item: ({ draggableStyle, isDragging }) => ({
        ...draggableStyle,
        ...baseItem(theme),
        ...(isDragging ? draggedItem : idleItem),
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.typography.pxToRem(8)
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.typography.pxToRem(25)
        }
    }),
    itemHandle: {
        width: handleBarWidth,
        position: 'absolute',
        margin: 0,
        padding: 0,
        left: 0,
        top: 0,
        bottom: 0,
        background: theme.palette.common.lightGray,
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    lineItemRootGrid: {
        display: 'flex',
        paddingTop: '30px',
        paddingBottom: 0,
        [theme.breakpoints.up('sm')]: {
            padding: 0
        }
    },
    wrapperInner: {
        padding: '20px 10px 0 10px',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            padding: '0 0 0 60px'
        }
    },
    rightWrap: {
        display: 'flex',
        flexDirection: 'column-reverse',
        justifyContent: 'space-between',
        width: '100%',
        padding: '20px 0 30px 0',
        [theme.breakpoints.up('sm')]: {
            flexShrink: 0,
            flexDirection: 'column',
            width: 280,
            padding: '15px 15px 10px 20px'
        },
        [theme.breakpoints.up('md')]: {
            padding: '15px 15px 10px 25px'
        },
        [theme.breakpoints.only('xs')]: {
            padding: '10px 0 5px 0'
        }
    },
    deleteDesktop: {
        position: 'absolute',
        top: -1,
        right: -1,
        display: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        width: 24,
        height: 24,
        borderTopRightRadius: 4,
        borderBottomLeftRadius: 4,
        background: '#E3E3EB',
        cursor: 'pointer',
        border: 0,
        outline: 'none',
        [theme.breakpoints.up('xs')]: {
            display: 'flex'
        }
    },
    mobileLineItemAligner: {
        top: 0,
        left: 0,
        width: '100%',
        maxWidth: 'calc(100% - 45px)',
        padding: 8,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    mobileLineItemHeader: {
        backgroundColor: ({ documentColor }) => documentColor,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        padding: 4,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        zIndex: 2,
        borderRadius: '2px 0 2px 0',

        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    mobileLineItemFrictionHandle: {
        width: theme.typography.pxToRem(16),
        minWidth: theme.typography.pxToRem(16),
        height: theme.typography.pxToRem(6),
        minHeight: theme.typography.pxToRem(6),
        '& path': {
            fill: theme.palette.secondary.main
        }
    },
    lineItemFrictionHandle: {
        width: theme.typography.pxToRem(16),
        height: theme.typography.pxToRem(6)
    },
    mobileLineItemHeaderTitle: {
        paddingLeft: theme.typography.pxToRem(6),
        paddingRight: theme.typography.pxToRem(6),
        color: theme.palette.secondary.main,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    unexpandChevron: {
        transition: 'all 250ms ease-in-out',
        height: theme.typography.pxToRem(15),
        width: theme.typography.pxToRem(15),
        [theme.breakpoints.down('sm')]: {
            transform: 'rotate(180deg)'
        }
    },
    expandChevron: {
        transition: 'all 250ms ease-in-out',
        height: theme.typography.pxToRem(15),
        width: theme.typography.pxToRem(15),
        [theme.breakpoints.down('sm')]: {
            transform: 'none'
        }
    },
    expandChevronButton: {
        padding: theme.typography.pxToRem(13),
        marginLeft: 'auto'
    },
    mobileHeaderTotal: {
        marginLeft: 'auto',
        color: 'white'
    },
    removeItemButton: {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    removeItemButtonText: { marginTop: 2 },
    textAreaWrapper: {
        [theme.breakpoints.only('xs')]: {
            paddingBottom: 5
        },
        [theme.breakpoints.up('sm')]: {
            padding: '0px 10px 0px 15px'
        }
    },
    taxDiscountDivider: {
        width: '100%',
        marginBottom: theme.typography.pxToRem(8)
    },
    taxDiscountWrap: {
        marginBottom: -6
    }
});
