import { useDispatch } from 'react-redux';
import {
    resetDocument,
    saveNotes,
    setDueDate,
    setDocumentTerms,
    setDocumentReminders
} from '@/state/actions';
import { dateTerms } from '@/config';
import { addDaysToDate } from '@/util';
import { useDocumentFormData } from './useDocumentFormData';

export const useResetMakeInvoice = (makeDocumentType = 'invoice') => {
    const { defaultNotes, issuedDate, presetTerms, reminders } =
        useDocumentFormData(makeDocumentType);

    const dispatch = useDispatch();

    const handleTermsChange = (item) => {
        const newDueDate = addDaysToDate(issuedDate, item.value);
        dispatch(setDueDate(newDueDate.toISOString()));
        dispatch(
            setDocumentTerms({
                name: item.name,
                value: item.value
            })
        );
    };

    return () => {
        dispatch(resetDocument(makeDocumentType));
        dispatch(saveNotes(defaultNotes));
        dispatch(setDocumentReminders(reminders));
        if (presetTerms)
            handleTermsChange(
                dateTerms.find((dateTerm) => dateTerm.value === presetTerms)
            );
    };
};
