import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select, InputLabel, FormControl, MenuItem } from '@mui/material';
import { useLocalization, useTimezone } from '@/hooks';

/**
 * Timezone Selector dropdown.
 * @param {string} timezone Currently selected timezone.
 * @param {bool} disabled if the prop should be disabled
 * @param {func} setTimezone Set the timezone using this function.
 * @param {string} customClasses Custom className styles from the parent component.
 */
const TimezoneSelector = ({
    timezone,
    setTimezone,
    customClasses,
    disabled
}) => {
    const { loading, timezones } = useLocalization();

    // Default or currently selected locale.
    // This is only shown when waiting for timezone data to load.
    const defaultTimezone = useTimezone();

    // This state management allows us to handle the selected state inside the
    // component, as we don't know what happens outside.
    const [current, setCurrent] = useState(timezone);
    const handleSelection = (selected) => {
        setCurrent(selected);
        setTimezone(selected);
    };

    return (
        <FormControl fullWidth variant="filled">
            <InputLabel id="timezoneLabel">Timezone</InputLabel>
            <Select
                labelId="timezoneLabel"
                disabled={disabled}
                label="Timezone"
                value={!loading && current ? current : defaultTimezone}
                className={customClasses}
                onChange={(e) => {
                    handleSelection(e.target.value);
                }}
            >
                {loading && (
                    <MenuItem
                        key={defaultTimezone}
                        value={defaultTimezone}
                        name={defaultTimezone}
                    >
                        {defaultTimezone}
                    </MenuItem>
                )}
                {!loading &&
                    timezones.map((item) => (
                        <MenuItem
                            key={item.name}
                            value={item.name}
                            name={item.name}
                        >
                            {item.name}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
};

TimezoneSelector.propTypes = {
    timezone: PropTypes.string,
    setTimezone: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    customClasses: PropTypes.string
};

TimezoneSelector.defaultProps = {
    disabled: false,
    timezone: undefined,
    customClasses: undefined
};

export default TimezoneSelector;
