import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    cancelSubscriptionButtonContainer: {
        padding: theme.spacing(2, 0)
    },
    cancelSubscriptionButton: {
        fontSize: 14
    }
}));
