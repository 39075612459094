import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    root: {
        zIndex: `${theme.zIndex.drawer}!important`
    },
    paper: {
        backgroundColor: theme.palette.common.lightGray,
        paddingTop: theme.titleBarHeight,
        paddingBottom: theme.typography.pxToRem(30),
        borderTop: '1px solid #F3F3F3',
        boxShadow:
            '0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14)',
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    },
    navbarContainer: {
        gridArea: 'navbar',
        [theme.breakpoints.down('lg')]: {
            display: 'none'
        }
    },
    navbar: {
        padding: `0 ${theme.typography.pxToRem(20)}`,
        [theme.breakpoints.down('lg')]: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            marginTop: `calc(env(safe-area-inset-top) + ${theme.typography.pxToRem(
                35
            )})`,
            fallbacks: {
                marginTop: theme.typography.pxToRem(35)
            }
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: -20,
            padding: 0,
            position: 'sticky'
        }
    }
}));
