import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
    failedPaymentReason: {
        textAlign: 'center',
        color: '#981616',
        padding: '5px 15px 5px 8px',
        borderRadius: 3,
        backgroundColor: '#F9D2D2',
        display: 'flex',
        whiteSpace: 'nowrap',
        marginTop: (hasLast4) => (hasLast4 ? 0 : 7)
    },
    warningIcon: {
        width: 20,
        height: 20,
        marginRight: 8
    }
}));
