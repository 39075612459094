import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    wrap: {
        maxWidth: '450px',
        // maxWidth: '375px',
        margin: `${theme.typography.pxToRem(
            50
        )} auto ${theme.typography.pxToRem(100)} auto`,
        [theme.breakpoints.up('sm')]: {
            margin: `${theme.typography.pxToRem(
                120
            )} auto ${theme.typography.pxToRem(200)} auto`
        },
        [theme.breakpoints.up('md')]: {
            margin: `${theme.typography.pxToRem(
                150
            )} auto ${theme.typography.pxToRem(230)} auto`
        },
        '& svg': {
            width: '100%'
        }
    },
    image: {
        padding: `0 ${theme.typography.pxToRem(10)}`
    },
    content: {
        textAlign: 'center',
        fontSize: theme.typography.pxToRem(20),
        margin: `${theme.typography.pxToRem(16)} 0`,
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(24)
        }
    },
    subContent: {
        textAlign: 'center',
        fontSize: theme.typography.pxToRem(14),
        margin: `${theme.typography.pxToRem(16)} 0`,
        fontWeight: 100,
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(16)
        }
    },
    button: {
        display: 'block',
        width: theme.typography.pxToRem(212),
        margin: '0 auto',
        textAlign: 'center'
    }
}));
