import { useSelector } from 'react-redux';

import {
    calculateSubtotalOfAllProducts,
    calculateDiscountOfAllProducts,
    calculateTaxesOfAllProducts
} from '@/util';

import {
    useFromAddressObject,
    useToAddressObject
} from '@/components/MakeDocument/FormatObjectHelpers';

export const useDocumentFormData = (makeDocumentType = 'invoice') => {
    // Get business data.
    const businessProfileUpdated = useSelector(
        (state) => state.user?.activeBusiness?.flags?.businessProfileUpdated
    );

    // This is only used for a individual internal document page. Not used
    // on listing pages (this hook should only be on individual pages).
    // Triggered by the getDocumentById function used in useSetImPageType hook.
    const documentLoading =
        useSelector((state) => state.user?.loadingDocument) || false;

    const activeBusinessLogo = useSelector(
        (state) => state?.user?.activeBusiness?.logo
    );

    const formInstanceHash = useSelector(
        (state) => state?.makeInvoice?.formInstanceHash
    );

    const recurringInvoiceId =
        useSelector((state) => state?.makeInvoice?.recurringInvoiceId) || null;
    const repeats = useSelector((state) => state?.makeInvoice?.repeats) || null;

    const amountSent =
        useSelector((state) => state?.makeInvoice?.amountSent) || null;

    const currentInvoiceLogo = useSelector((state) => state?.makeInvoice?.logo);

    const documentColor = useSelector(
        (state) => state?.user?.activeBusiness?.documentCustomizations?.design
    );

    const setDocumentColor = useSelector((state) => state?.makeInvoice?.design);

    const setHideStamp = useSelector(
        (state) => state?.makeInvoice?.disablePaymentStamp
    );

    const defaultNotes = useSelector(
        (state) =>
            state?.user?.activeBusiness?.documentCustomizations?.[
                `${makeDocumentType}Notes`
            ]
    );

    const presetTerms = useSelector(
        (state) =>
            state?.user?.activeBusiness?.documentCustomizations?.[
                `${makeDocumentType}Terms`
            ]
    );

    const hideStamp = useSelector(
        (state) =>
            state?.user?.activeBusiness?.documentCustomizations
                ?.disablePaymentStamp
    );

    const currency = useSelector(
        (state) => state.user?.activeBusiness?.localeData?.currency
    );

    const invoiceCurrency =
        useSelector(
            (state) => state?.makeInvoice?.localizationSettings?.currency
        ) || false;

    const locale = useSelector(
        (state) => state.user?.activeBusiness?.businessSettings?.locale
    );

    const timezone = useSelector(
        (state) => state.user?.activeBusiness?.businessSettings?.timezone
    );

    // Get the invoice mode.
    const mode = useSelector((state) => state?.makeInvoice?.viewMode);
    const locked = mode === 'edit';

    // General invoice data.
    const referenceNumber = useSelector(
        (state) => state?.makeInvoice?.referenceNumber
    );

    const customLabel = useSelector((state) => state?.makeInvoice?.customLabel);

    const documentState =
        useSelector((state) => state?.makeInvoice?.documentState) || 'draft';

    const balanceDue =
        useSelector((state) => state?.makeInvoice?.balanceDue) || 0;

    const balancePaid =
        useSelector((state) => state?.makeInvoice?.balancePaid) || 0;

    const documentType = useSelector(
        (state) => state?.makeInvoice?.documentType
    );

    const issuedDate =
        useSelector((state) => state?.makeInvoice?.issuedDate) ||
        new Date().toISOString();

    const dueDate = useSelector((state) => state?.makeInvoice?.dueDate);

    const invoiceTerms = useSelector((state) => state?.makeInvoice?.terms);

    const showNotes = useSelector((state) => state?.makeInvoice?.notes);

    const noteContent = useSelector((state) => state?.makeInvoice?.details);

    const signature = useSelector((state) => state?.makeInvoice?.signature);

    const link = useSelector((state) => state?.makeInvoice?.link);

    const transactions = useSelector(
        (state) => state?.makeInvoice?.transactions || []
    );

    // Client Data.
    const billTo = useToAddressObject();

    // From data.
    const billFrom = useFromAddressObject(locked);

    // Product data.

    const products = useSelector((state) => state?.makeInvoice?.products) || [];

    const subTotal = calculateSubtotalOfAllProducts(products);

    const discount = calculateDiscountOfAllProducts(products);

    const taxes = calculateTaxesOfAllProducts(products);

    const total = subTotal - discount + taxes;

    const reminders = useSelector((state) => state?.makeInvoice?.reminders);

    const currencyToReturn =
        documentState !== 'draft' && invoiceCurrency
            ? invoiceCurrency
            : currency;

    const markedPaidByClientMethod =
        useSelector((state) => state?.makeInvoice?.markedPaidByClientMethod) ||
        false;

    return {
        activeBusinessLogo,
        amountSent,
        balancePaid,
        balanceDue,
        billFrom,
        billTo,
        businessProfileUpdated,
        formInstanceHash,
        currency: currencyToReturn,
        currentInvoiceLogo,
        customLabel,
        defaultNotes,
        discount,
        documentColor: locked ? setDocumentColor : documentColor,
        documentState,
        documentType,
        dueDate,
        hideStamp: locked ? setHideStamp : hideStamp,
        documentLoading,
        invoiceTerms,
        issuedDate,
        locked,
        locale,
        link,
        noteContent,
        presetTerms,
        products,
        referenceNumber,
        recurringInvoiceId,
        repeats,
        reminders,
        showNotes,
        signature,
        subTotal,
        taxes,
        total,
        transactions,
        timezone,
        markedPaidByClientMethod
    };
};
