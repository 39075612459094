import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { toIntegerCurrency } from '@/util';
import InputBar from '../InputBar';

const TaxLine = ({
    selectedTaxes,
    applyFieldToItem,
    taxItem,
    error,
    clearErrors
}) => {
    const globalTaxList =
        useSelector((state) => state.user?.activeBusiness?.taxes) || [];

    const removeTax = (taxId) => {
        const newSelectedTaxes = selectedTaxes.filter(
            (tax) => tax.taxId !== taxId
        );
        applyFieldToItem('taxes', newSelectedTaxes);
    };

    const updateValue = (name, type, value, taxId) => {
        const formattedValue = Number.isNaN(value)
            ? 0
            : toIntegerCurrency(value);

        const taxIndexToUpdate = selectedTaxes.findIndex(
            (tax) => tax.taxId === taxId
        );

        const newSelectedTaxes = [...selectedTaxes];

        newSelectedTaxes[taxIndexToUpdate] = {
            name,
            type,
            value: formattedValue,
            taxId
        };

        applyFieldToItem('taxes', newSelectedTaxes);
    };

    return (
        <InputBar
            label="Tax"
            clearErrors={clearErrors}
            nameError={error}
            selectedItems={selectedTaxes}
            allItems={globalTaxList}
            removeItem={removeTax}
            itemType="tax"
            updateValue={updateValue}
            inputItem={taxItem}
        />
    );
};

TaxLine.propTypes = {
    applyFieldToItem: PropTypes.func.isRequired,
    selectedTaxes: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
                .isRequired,
            name: PropTypes.string.isRequired
        })
    ),
    taxItem: PropTypes.object.isRequired,
    error: PropTypes.string,
    clearErrors: PropTypes.func.isRequired
};

TaxLine.defaultProps = {
    selectedTaxes: [],
    error: ''
};

export default TaxLine;
