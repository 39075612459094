/* eslint import/no-cycle: "off"  */
import store from '@/state/store';

import { globalPageCount } from '@/config';

import {
    createTax as createTaxAction,
    deleteTax as deleteTaxAction,
    fetchListingItems,
    noListingItems,
    overwriteListingItems,
    overwriteTaxes,
    updateTax as updateTaxAction
} from '@/state/actions';
import { convertTemporaryUserToUnregisteredUser } from '@/modules/setup';
import { filterArgFlattener } from '@/util';
import axiosAuthenticated from '../authenticatedRequestor';

export const createTax = async (tax) => {
    if (store.getState().user.temporaryUser) {
        await convertTemporaryUserToUnregisteredUser();
    }
    const { businessId } = store.getState().user.activeBusiness;

    const response = await axiosAuthenticated.post(
        `/businesses/${businessId}/taxes`,
        tax
    );

    store.dispatch(createTaxAction(response.data));
    return response.data;
};

export const getTaxes = async () => {
    if (store.getState().user.temporaryUser) {
        store.dispatch(overwriteTaxes([]));
        return [];
    }

    const { businessId } = store.getState().user.activeBusiness;

    const items = [];

    let morePages = true;
    let pageCount = 1;

    while (morePages) {
        // This returns 100 items as default.
        // eslint-disable-next-line no-await-in-loop
        const response = await axiosAuthenticated.get(
            `/businesses/${businessId}/taxes`,
            { params: { pageNumber: pageCount } }
        );

        // Add items to main array. Fallback to empty array if API failed/for some
        // reason returns nothing.
        items.push(...(response?.data?.items || []));

        pageCount += 1;

        // There are more pages if totalPages is larger than current page count.
        // Minus one page so it will fail if the same or smaller correctly.
        morePages =
            response.data.totalItems / response.data.pageSize > pageCount - 1;
    }

    store.dispatch(overwriteTaxes(items));
    return items;
};

export const getTaxById = async (taxId) => {
    const { businessId } = store.getState().user.activeBusiness;

    const response = await axiosAuthenticated.get(
        `/businesses/${businessId}/taxes/${taxId}`
    );

    return response.data;
};

export const deleteTax = async (taxId) => {
    const { businessId } = store.getState().user.activeBusiness;

    await axiosAuthenticated.delete(`/businesses/${businessId}/taxes/${taxId}`);
    store.dispatch(deleteTaxAction(taxId));
};

export const updateTax = async (tax) => {
    const { businessId } = store.getState().user.activeBusiness;

    const response = await axiosAuthenticated.put(
        `/businesses/${businessId}/taxes/${tax.taxId}`,
        tax
    );

    store.dispatch(updateTaxAction(response.data));
};

export const getPaginatedTaxes = async (args) => {
    if (store.getState().user.temporaryUser) {
        store.dispatch(noListingItems({ type: 'tax' }));
        return;
    }

    store.dispatch(fetchListingItems({ type: 'tax' }));

    const { businessId } = store.getState().user.activeBusiness;

    const allArgs = { pageSize: globalPageCount, ...filterArgFlattener(args) };

    const response = await axiosAuthenticated.get(
        `/businesses/${businessId}/taxes`,
        {
            params: {
                sortOrder: 'desc',
                sortType: 'createdAt',
                ...allArgs
            }
        }
    );

    const { items, itemsMatchingRequest, pageSize, totalItems } = response.data;

    const totalPages = Math.ceil(itemsMatchingRequest / pageSize);

    store.dispatch(
        overwriteListingItems({ type: 'tax', items, totalPages, totalItems })
    );
};
