import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material';
import { WarnConfirmDialog } from '@/components/common';
import { paymentMethods } from '@/config';
import { usePaymentConnections } from '@/hooks';
import {
    PaymentsIconLargeBlue,
    BankTransfer,
    DigitalWallet
} from '@/resources/icons';
import useStyles from './styles';

const PaymentChannelUpdateModal = ({
    merchant,
    paymentChannel,
    enable,
    onConfirm,
    onCancel,
    buttonLoading
}) => {
    const theme = useTheme();
    const classes = useStyles();
    const { connections, preferredPaymentConnections } =
        usePaymentConnections();

    const cashAppQrConnection = connections.find(
        (connection) => connection.application === 'cashapp'
    );
    const nativeCashAppEnabled =
        preferredPaymentConnections?.otherConnections?.find(
            (connectionId) => connectionId === cashAppQrConnection?.connectionId
        );

    const handleCashAppIntegration =
        nativeCashAppEnabled &&
        merchant === 'square' &&
        paymentChannel === 'digitalWallet' &&
        enable;

    const paymentChannelData = {
        creditCard: {
            name: 'Credit Card',
            paymentTypeKey: 'acceptedCards',
            icon: <PaymentsIconLargeBlue className={classes.icon} />
        },
        digitalWallet: {
            name: 'Digital Wallet',
            paymentTypeKey: 'acceptedWallets',
            icon: <DigitalWallet className={classes.icon} />
        },
        bankTransfer: {
            name: 'Bank Transfer',
            paymentTypeKey: 'acceptedBanks',
            icon: <BankTransfer className={classes.icon} />
        },
        otherConnections: {
            name: 'Digital Wallet',
            paymentTypeKey: 'acceptedWallets',
            icon: <DigitalWallet className={classes.icon} />
        }
    };

    const {
        name: paymentChannelName,
        paymentTypeKey,
        icon
    } = paymentChannelData[paymentChannel];

    const paymentMethod = paymentMethods.find(
        (method) => method.slug === merchant
    );

    const buildPaymentTypesString = () => {
        const paymentTypesArray = paymentMethod?.[paymentTypeKey].map(
            (acceptedType) => acceptedType.name
        );

        if (paymentTypesArray.length === 0) {
            return paymentMethod.name;
        }

        if (paymentTypesArray.length === 1) {
            return paymentTypesArray[0];
        }

        if (paymentTypesArray.length === 2) {
            return paymentTypesArray.join(' and');
        }

        if (paymentTypesArray.length === 3) {
            return `${paymentTypesArray.slice(0, 2).join(', ')}, and ${
                paymentTypesArray[2]
            }`;
        }

        return `${paymentTypesArray.slice(0, 4).join(', ')}, and many others`;
    };

    return (
        <WarnConfirmDialog
            open
            cancelText="Cancel"
            confirmColor={theme.palette.primary.main}
            confirmText="Confirm"
            customIcon={icon}
            title={`${paymentChannelName} ${
                enable ? 'Enable' : 'Disable'
            } Confirmation`}
            message={
                <>
                    {`By ${
                        enable ? 'enabling' : 'disabling'
                    } ${paymentChannelName}s via ${
                        paymentMethod.name
                    } your clients will${
                        enable ? '' : ' no longer'
                    } have the ability to pay you with ${buildPaymentTypesString()}.`}
                    {handleCashAppIntegration && (
                        <>
                            <br />
                            <br /> Your current Cash App integration will be
                            turned off.
                        </>
                    )}
                </>
            }
            onConfirm={() =>
                handleCashAppIntegration ? onConfirm(true) : onConfirm()
            }
            onCancel={onCancel}
            buttonLoading={buttonLoading}
        />
    );
};

export default PaymentChannelUpdateModal;

PaymentChannelUpdateModal.propTypes = {
    merchant: PropTypes.string.isRequired,
    paymentChannel: PropTypes.string.isRequired,
    enable: PropTypes.bool,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    buttonLoading: PropTypes.bool
};

PaymentChannelUpdateModal.defaultProps = {
    enable: false,
    buttonLoading: false
};
