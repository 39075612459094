import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    line: {
        [theme.breakpoints.up('md')]: {
            padding: '1.25em'
        }
    },
    lineCenter: {
        display: 'flex',
        alignItems: 'center',
        padding: '0.3em 0',
        [theme.breakpoints.up('md')]: {
            padding: '1.25em'
        }
    },
    lineDate: {
        [theme.breakpoints.up('md')]: {
            padding: '1.25em',
            textAlign: 'left'
        },
        textAlign: 'right',
        width: 110,
        fontSize: 14
    },
    lineDateTime: {
        [theme.breakpoints.down('md')]: {
            display: 'block'
        }
    },
    lineLabel: {
        marginLeft: 10,
        fontSize: 14
    },
    simpleButton: {
        // reset button
        backgroundColor: 'transparent',
        border: 'none',
        outline: 'none',
        textDecoration: 'none',

        // style like link
        cursor: 'pointer',
        color: theme.palette.primary.main,
        fontSize: 14,
        fontWeight: 600,
        fontFamily: 'inherit',
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    simpleButtonMarkAsRead: {
        // reset button
        backgroundColor: '#E1ECF7',
        border: 'none',
        outline: 'none',
        textDecoration: 'none',

        // style like link
        padding: '6px 12px',
        borderRadius: 6,
        cursor: 'pointer',
        color: theme.palette.primary.main,
        fontSize: 14,
        fontWeight: 600,
        fontFamily: 'inherit',
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        },
        '&:hover': {
            opacity: 0.8
        }
    },
    buttonWrap: {
        display: 'block',
        padding: '16px',
        [theme.breakpoints.down('md')]: {
            padding: '8px 0'
        }
    },
    markAllReadButton: {
        textTransform: 'none'
    },
    markAllReadSvg: {
        width: 20,
        height: 20
    }
}));
