import { useSelector } from 'react-redux';
import { getCurrentUser } from '@/modules/loginManager';

export const useSubscription = () => {
    const defaultData = {
        components: {
            document: {
                componentType: 'document',
                amountStartedWith: 1,
                limited: true,
                enabled: true,
                amountPerRefresh: 1,
                amountRemaining: 1
            }
        },
        chargify: {},
        productId: 'visitor',
        subscriptionSource: 'unassigned'
    };

    const user = getCurrentUser();

    let loading = true;
    let subscriptionState = 'active';

    const subscription = useSelector(
        (state) => state?.user?.activeBusiness?.subscription
    );

    if (user) {
        if (Object.entries(subscription || {}).length === 0) {
            loading = true;
        } else {
            loading = false;
        }
    } else {
        loading = false;
    }

    if (
        subscription?.productId === 'visitor' ||
        !subscription?.[subscription?.subscriptionSource]
    ) {
        subscriptionState = 'trialing';
    }

    if (subscription?.[subscription?.subscriptionSource]?.state) {
        subscriptionState =
            subscription?.[subscription?.subscriptionSource]?.state;
    }

    if (subscription?.autoRenew === false) {
        subscriptionState = 'canceled';
    }

    return user === null
        ? {
              loading,
              ...defaultData
          }
        : {
              loading,
              userSubscriptionState: subscriptionState,
              ...subscription
          };
};
