import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    countrySelectMargin: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
    },
    flagMenuItem: {
        justifyContent: 'center',
        paddingLeft: 4,
        paddingRight: 6
    },
    flagIcons: {
        width: 24,
        height: 16,
        marginRight: theme.spacing(1)
    },
    selectMenu: {
        height: 0
    }
}));
