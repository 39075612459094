import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    cardOrder1: {
        order: 0,
        [theme.breakpoints.up('md')]: {
            order: 1
        }
    },
    cardOrder2: {
        order: 1
    },
    subscriptionCardContainer: {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #E0E5EE',
        borderRadius: 4,
        padding: theme.spacing(2),
        boxShadow: 'none',
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        marginBottom: 20,
        [theme.breakpoints.up('md')]: {
            marginBottom: 0,
            minHeight: 380
        }
    },
    plusContainer: {
        border: `2px solid ${theme.palette.primary.main}`,
        [theme.breakpoints.up('md')]: {
            boxShadow: '0 20px 25px -5px rgba(16,24,40,0.1)'
        }
    },
    mostPopularPill: {
        position: 'absolute',
        top: 14,
        right: 14,
        backgroundColor: theme.palette.common.lightGray,
        padding: `4px 10px`,
        borderRadius: 24
    },
    iconWrap: {
        height: 25
    },
    cardTitleIcon: {
        width: 24,
        height: 25
    },
    planCardPrice: {
        fontSize: theme.typography.pxToRem(36),
        fontWeight: 700,
        margin: '10px 0 15px 0',
        lineHeight: 1
    },
    planCardPriceTrial: {
        fontSize: theme.typography.pxToRem(36),
        fontWeight: 700,
        margin: '10px 0 5px 0',
        lineHeight: 1,
        textTransform: 'capitalize'
    },
    planCardPriceInterval: {
        fontWeight: 400
    },
    planCardPriceIntervalTrial: {
        fontWeight: 400,
        display: 'block',
        marginBottom: 15,
        width: '100%'
    },
    planCardPriceToday: {
        fontWeight: 400,
        marginLeft: 4
    },
    planCardDescription: {
        fontWeight: 500,
        color: theme.palette.text.secondary,
        lineHeight: '24px',
        marginBottom: 20,
        '& hr': {
            border: '1px solid #E0E5EE'
        }
    },
    planCardNoButton: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        marginTop: 'auto'
    },
    planCardButton: {
        alignContent: 'center',
        marginTop: 'auto'
    },
    buttonHeight: {
        display: 'flex',
        alignItems: 'center',
        height: 40,
        justifyContent: 'center'
    },
    pendingWrap: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center'
    },
    fireStyle: {
        width: 11,
        marginRight: 5
    },
    popularPillText: {
        display: 'flex',
        alignItems: 'center'
    }
}));
