import React from 'react';
import { InputAdornment } from '@mui/material';

export const currencySymbolAdornment = ({ position, symbol }) =>
    position === 'after'
        ? {
              endAdornment: (
                  <InputAdornment position="end">{symbol}</InputAdornment>
              )
          }
        : {
              startAdornment: (
                  <InputAdornment position="start">{symbol}</InputAdornment>
              )
          };
