import {
    FreeSubIcon,
    LifetimeSubIcon,
    LiteSubIcon,
    PlusSubIcon,
    ProSubIcon
} from '@/resources/icons';

export const setPlanIcon = (planTitle) => {
    switch (planTitle?.toLowerCase()) {
        case 'lifetime':
            return LifetimeSubIcon;
        case 'lite':
            return LiteSubIcon;
        case 'plus':
            return PlusSubIcon;
        case 'pro':
            return ProSubIcon;
        case 'free':
            return FreeSubIcon;
        default:
            return undefined;
    }
};
