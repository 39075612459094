/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

export const modalSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        closeAllModals: () => initialState,
        closeModal: (state, action) => {
            const modals = [...state];

            if (action.payload) {
                // Close specific modal.
                return [
                    ...modals.filter(
                        ({ modalSlug }) => modalSlug !== action.payload
                    )
                ];
            }

            // Close the last modal off the stack.
            modals.shift();
            return modals;
        },
        // Uses first in, last out approach.
        // This allows future proofing of stacking modals.
        setModal: (state, action) => {
            const modals = [...state];
            const modal = action.payload.slug;

            const hasModal = modals.find(
                ({ modalSlug }) => modalSlug === modal
            );

            if (!hasModal) {
                // Add modal to top of stack.
                modals.unshift({ modalSlug: action.payload.slug });

                return modals;
            }

            if (modals.length > 1) {
                // Move modal to top of stack if there are more than this modal.
                return [
                    modals.find(({ modalSlug }) => modalSlug === modal),
                    ...modals.filter(({ modalSlug }) => modalSlug !== modal)
                ];
            }

            return modals;
        }
    }
});

export const { closeAllModals, closeModal, setModal } = modalSlice.actions;

export default modalSlice.reducer;
