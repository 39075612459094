import loadable from '@loadable/component';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Capacitor } from '@capacitor/core';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link, useNavigate } from 'react-router-dom-v5-compat';
import { AppBar, Toolbar, Typography, Grid, Skeleton } from '@mui/material';
import { InvoiceMakerLogo } from '@/resources/images';
import { Burger, ButtonLight } from '@/components/common';
import NotificationBell from '@/components/NotificationBell';
import { resetDocument as resetDocumentAction } from '@/state/actions';
import {
    useCheckAuthState,
    useCheckHasBusinessLoaded,
    useResetMakeInvoice
} from '@/hooks';
import { getCurrentUser } from '@/modules/loginManager';
import UserDropdown from './UserDropdown';
import CreateButton from './CreateButton';
import useStyles from './styles';

const SignUpLoginModal = loadable(() => import(`@/components/Login`));

const AccountButton = ({ registeredUser, userName, setLoginView }) =>
    registeredUser ? (
        <UserDropdown userName={userName} />
    ) : (
        <ButtonLight onClick={() => setLoginView('login')}>Login</ButtonLight>
    );

AccountButton.propTypes = {
    registeredUser: PropTypes.bool,
    userName: PropTypes.string,
    setLoginView: PropTypes.func
};

AccountButton.defaultProps = {
    registeredUser: false,
    userName: '',
    setLoginView: () => {}
};

const BurgerLink = ({
    mobileMenuActive,
    setMobileMenuActive,
    resetDocument
}) => {
    const isIos = Capacitor.getPlatform() === 'ios';
    const classes = useStyles(isIos);

    return (
        <Grid item className={classes.burgerLogoGrid}>
            <Burger active={mobileMenuActive} setActive={setMobileMenuActive} />
            <Link
                to="/"
                className={classes.removeClickFocus}
                onClick={() => {
                    resetDocument();
                }}
            >
                <Grid item className={classes.headerLogo}>
                    <InvoiceMakerLogo className={classes.headerLogoSvg} />
                    <Typography variant="h1" className={classes.title}>
                        Invoice Maker
                    </Typography>
                </Grid>
            </Link>
        </Grid>
    );
};

BurgerLink.propTypes = {
    mobileMenuActive: PropTypes.bool,
    setMobileMenuActive: PropTypes.func,
    resetDocument: PropTypes.func
};

BurgerLink.defaultProps = {
    mobileMenuActive: false,
    setMobileMenuActive: () => {},
    resetDocument: () => {}
};

const Titlebar = ({ mobileMenuActive, setMobileMenuActive }) => {
    const isIos = Capacitor.getPlatform() === 'ios';
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles(isIos);
    const resetDocument = useResetMakeInvoice();
    const hasBusinessLoaded = useCheckHasBusinessLoaded();
    const [loginView, setLoginView] = useState('');
    const [loadLoginModal, setLoadLoginModal] = useState(false);
    const { loadingUser, registeredUser } = useCheckAuthState();
    const { pathname } = useLocation();

    const userName =
        useSelector((state) => state?.user?.activeBusiness?.name) || '';

    const lockedDocument =
        useSelector((state) => state?.makeInvoice?.viewMode) === 'edit';

    // Checks if the URL is a login related pathname, and
    // the user is not registered. If so trigger a Login
    // modal view.
    useEffect(() => {
        if (!loadingUser && !registeredUser) {
            if (pathname === '/login') {
                setLoginView('login');
            } else if (pathname === '/sign-up') {
                setLoginView('signUp');
            } else if (pathname === '/forgot-password') {
                setLoginView('forgotPassword');
            } else {
                // All other paths.
                const userSession = getCurrentUser();
                if (userSession) {
                    // If a user is in local storage, but not logged in we can presume they will want to log in.
                    setLoginView('login');
                } else {
                    // If we have no user in local storage, we can presume they will want to sign up.
                    setLoginView('signUp');
                }
            }
        }
        if (pathname === '/reset-password') {
            setLoginView('resetPassword');
        }
    }, [pathname, loadingUser, registeredUser]);

    useEffect(() => {
        if (loginView && !loadLoginModal) {
            setLoadLoginModal(true);
        }
    }, [loginView, loadLoginModal]);

    return (
        <>
            <AppBar
                className={`${classes.appBar} ${
                    isIos ? classes.appBarIos : ''
                }`}
            >
                <Toolbar className={classes.toolbar} disableGutters>
                    <BurgerLink
                        mobileMenuActive={mobileMenuActive}
                        setMobileMenuActive={setMobileMenuActive}
                        resetDocument={resetDocument}
                    />
                    <Grid item>
                        <Grid
                            item
                            container
                            direction="row"
                            xs={12}
                            alignItems="center"
                        >
                            <NotificationBell />
                            <CreateButton />
                            {hasBusinessLoaded && (
                                <div className={classes.AccountButtonContainer}>
                                    <AccountButton
                                        registeredUser={registeredUser}
                                        userName={userName}
                                        setLoginView={setLoginView}
                                    />
                                </div>
                            )}

                            {!hasBusinessLoaded && (
                                <Grid
                                    item
                                    className={classes.AccountButtonContainer}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        height={37}
                                        width={78}
                                        data-testid="skeleton-titlebar-account"
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            {loadLoginModal && (
                <SignUpLoginModal
                    blockClose
                    setLoginView={setLoginView}
                    view={loginView}
                    onSuccess={(login) => {
                        if (login && lockedDocument) {
                            dispatch(resetDocumentAction());
                            navigate('/');
                        }
                    }}
                />
            )}
            <div className={classes.mobileBarSpacer} />
        </>
    );
};

Titlebar.propTypes = {
    mobileMenuActive: PropTypes.bool,
    setMobileMenuActive: PropTypes.func.isRequired
};

Titlebar.defaultProps = {
    mobileMenuActive: false
};

export default Titlebar;
