import { useEffect, useRef, useCallback } from 'react';
import { useNavigationType, useLocation } from 'react-router-dom-v5-compat';
import { useDispatch, useSelector } from 'react-redux';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { closeAllDrawers } from '@/state/actions';
import { getUserSubscription } from '@/modules/subscriptionManager';
import { getAllThirdPartyConnections } from '@/modules/dataWrangler/connections';
import { checkIfDateIsPassed } from '@/util';
import { useSubscription } from './useSubscription';

/**
 * The purpose of this function is to only call the
 * `getUserSubscription` endpoint if:
 *  - The user has a business.
 *  - The app/webpage is active.
 *  - The refresh date has expired.
 *  OR
 *  - The app/webpage is active.
 *  - The call is from the capacitor event listener.
 */
export const updateSubscription = async ({
    businessId,
    isActive,
    isFromListener,
    nextRefreshDate
}) => {
    if (
        (businessId !== 'default' &&
            isActive &&
            checkIfDateIsPassed(nextRefreshDate)) ||
        (isActive && isFromListener && businessId !== 'default')
    ) {
        await getUserSubscription(businessId);
    }
};

// Updates the user's connection object with current connections
export const updateConnections = ({ platform, businessId }) => {
    if (platform !== 'web' && businessId && businessId !== 'default') {
        getAllThirdPartyConnections(businessId);
    }
};

export const useRefreshAppState = () => {
    const listener = useRef();
    const location = useLocation();
    const navigate = useNavigationType();
    const dispatch = useDispatch();
    const businessId = useSelector(
        (state) => state?.user?.activeBusiness?.businessId
    );
    const platform = Capacitor.getPlatform();
    const { nextRefreshDate } = useSubscription();

    const updateSubscriptionData = useCallback(
        async (isActive, isFromListener) =>
            updateSubscription({
                businessId,
                isActive,
                isFromListener,
                nextRefreshDate
            }),
        [businessId, nextRefreshDate]
    );

    /**
     * Fires on page change.
     */
    useEffect(() => {
        updateSubscriptionData(true, false);
    }, [location.key, updateSubscriptionData]);

    /**
     * Fires when app/website moves in/out of focus.
     * Either swapping between tabs web page, or the app
     * is navigated to on a device.
     */
    useEffect(() => {
        const runAppEventListener = async () => {
            listener.current = await App.addListener(
                'appStateChange',
                ({ isActive }) => {
                    updateSubscriptionData(isActive, true);
                    updateConnections({ platform, businessId });
                }
            );
        };

        runAppEventListener();

        return () => {
            if (listener.current?.remove) {
                listener.current.remove();
            }
        };
    }, [businessId, updateSubscriptionData, listener, platform]);

    /**
     * Listens for the browser back button to be clicked.
     * When it is it makes sure drawer states do not persist
     * to the new page.
     */
    useEffect(() => {
        if (navigate === 'POP') {
            dispatch(closeAllDrawers());
        }
    }, [dispatch, navigate]);
};
