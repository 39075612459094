import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2';
import { useSelector } from 'react-redux';
import AutocompleteMultiselect from '@/components/rhf-mui/AutocompleteMultiselect';
import ItemLabel from '../ItemLabel';
import makeStyles from './styles';

const Tags = ({ heading, id }) => {
    const classes = makeStyles();
    const tags =
        useSelector((state) => state?.user?.activeBusiness?.tags) || [];

    return (
        <Grid item container direction="column">
            <ItemLabel>{heading}</ItemLabel>
            <Grid item>
                <AutocompleteMultiselect
                    fullWidth
                    id={id}
                    classes={{
                        inputRoot: classes.multiselectInput,
                        popupIndicator: classes.popupIndicator,
                        noOptions: classes.listbox,
                        listbox: classes.listbox
                    }}
                    inputLabel="Add a tag"
                    labelIdentifier="name"
                    helperText="The maximum number of tags allowed is ten"
                    rhfName="tags"
                    options={tags}
                    rules={{ validate: (val) => val?.length <= 10 }}
                />
            </Grid>
        </Grid>
    );
};

Tags.propTypes = {
    id: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired
};

export default Tags;
