// https://github.com/ilkkapeltola/visitdata/tree/main

import { forEach } from 'lodash';
import { fromEntries } from '@/util';
import paidUrlParamsConfig from './click_identifiers.json';
import searchEngineConfig from './search_engines.json';

const getDomain = (url) => {
    try {
        const newUrl = url.substring(0, 4) === 'http' ? url : `https://${url}`;
        const u = new URL(newUrl);
        const h = u.hostname;
        const s = h.split('.');
        const sl = s.slice(-2);

        if (['com', 'co'].includes(sl[0]) && sl.join('').length <= 5) {
            return s.slice(-3).join('.');
        }

        return s.slice(-2).join('.');
    } catch (e) {
        return null;
    }
};

const getUtmTags = (urlParamsObject) => {
    const utmTagMap = {
        utm_source: 'source',
        utm_medium: 'medium',
        utm_campaign: 'campaign',
        utm_content: 'content',
        utm_term: 'term'
    };

    // An empty object to store found utm tags
    const utmTagResults = {};

    // Iterate all URL parameters, check if they are utm tags, and save in results if they are
    const keys = Object.keys(urlParamsObject);
    forEach(keys, (key) => {
        if (key in utmTagMap) {
            utmTagResults[utmTagMap[key]] = urlParamsObject[key];
        }
    });

    // Return found UTM tags or null if empty
    if (Object.keys(utmTagResults).length > 0) {
        return utmTagResults;
    }

    return null;
};

const getSearchEngineData = (referringDomain = '', urlParamsObject) => {
    if (referringDomain == null) {
        return null;
    }

    if (referringDomain in searchEngineConfig) {
        const { n: source, p: searchParam } =
            searchEngineConfig[referringDomain];
        const returnData = {
            source,
            medium: 'organic'
        };

        if (searchParam in urlParamsObject) {
            returnData.term = urlParamsObject[searchParam];
        }

        return returnData;
    }

    // This part handles searchEngineConfigs with regex == true
    // If the referring domain isn't found earlier, we will match against regex, but only then
    const filteredSearchEngineConfigs = Object.entries(searchEngineConfig)
        .filter(
            ([key, config]) =>
                config.regex && referringDomain?.match(key) != null
        )
        .map(([, config]) => ({
            source: config.n,
            medium: 'organic'
        }));

    if (filteredSearchEngineConfigs.length > 0) {
        return filteredSearchEngineConfigs[0]; // Return the first matching config
    }

    return null;
};

const getPaidUrlData = (urlParamsObject) => {
    const keys = Object.keys(urlParamsObject);
    for (let i = 0; i < keys.length; i += 1) {
        if (keys[i] in paidUrlParamsConfig) {
            return paidUrlParamsConfig[keys[i]];
        }
    }

    // If no Click Id is found, return null
    return null;
};

const getRawVisitData = () => {
    // This bit strips the protocol away from the referrer, since psl doesn't want that
    // eslint-disable-next-line prefer-destructuring
    const referrer = document.referrer;

    // Get only the top-level domain of the referrer
    const referringDomain = getDomain(referrer);
    // Get URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Then turn them into an object with key: value pairs
    const urlParamsObject = fromEntries(urlParams);
    // Gets only utm tags from URL parameters
    const utmTags = getUtmTags(urlParamsObject);
    // Checks for click identifiers in URL parameters, and if present, results in cpc/cpm
    const paidUrlData = getPaidUrlData(urlParamsObject);
    // Checks referring domain for common search engines, and when found, results in organic
    const searchEngineData = getSearchEngineData(
        referringDomain,
        urlParamsObject,
        searchEngineConfig
    );
    // Set referring domain if present
    const referralData =
        referringDomain == null
            ? null
            : { medium: 'referral', source: referringDomain };

    return {
        this_hostname: document.location.origin || 'localhost',
        this_domain: getDomain(document.location.origin) || 'localhost',
        referring_hostname: referrer || null,
        referring_domain: referringDomain,
        query_string: window.location.search,
        utm_tags: utmTags,
        url_params:
            Object.keys(urlParamsObject).length > 0 ? urlParamsObject : null,
        paid_url_data: paidUrlData,
        organic_search_data: searchEngineData,
        referral_data: referralData
    };
};

const getVisitData = () => {
    const trafficData = getRawVisitData();

    const returnData = {};

    const dataSources = [
        trafficData.utm_tags,
        trafficData.paid_url_data,
        trafficData.organic_search_data,
        trafficData.referral_data
    ];

    const fieldsToCopy = ['source', 'medium', 'campaign', 'content', 'term'];

    forEach(dataSources, (dataSource) => {
        if (dataSource) {
            forEach(fieldsToCopy, (field) => {
                if (!returnData[field] && dataSource[field]) {
                    returnData[field] = dataSource[field];
                }
            });
        }
    });

    return returnData;
};

export { getRawVisitData, getVisitData };
