// Data to set the head of the table.
const tableHead = [
    {
        id: 'event',
        label: 'EVENT',
        sort: false
    },
    { id: 'date', label: 'DATE', sort: false }
];

export default tableHead;
