/**
 * Searching function that finds an object in an array of objects.
 * @param  {array} array The array you want to search through.
 * @param  {any} searchQuery The value you are searching for.
 * @param  {string} property The property of the object you want to match to searchQuery.
 * @return {obj} The first object in the array that equals searchQuery.
 */

export const getObjectFromArray = (array, searchQuery, property) =>
    array.find((object) => object[property] === searchQuery);
