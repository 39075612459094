import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Icon from '@mui/material/Icon';
import flags from 'react-phone-number-input/flags';
import { useLocalization } from '@/hooks';
import useStyles from './styles';

const PhoneNumberCountrySelect = ({
    value,
    onChange,
    disabled,
    defaultCountry,
    setCallingCode,
    setDefaultPhoneCountry
}) => {
    const classes = useStyles();
    const { countries } = useLocalization();

    const onCountrySelect = useCallback(
        async (e) => {
            if (!e.target.value) {
                return;
            }

            setCallingCode(e.target.value);
            onChange(e.target.value);
            setDefaultPhoneCountry(e.target.value);
        },
        [onChange, setCallingCode, setDefaultPhoneCountry]
    );

    if (!countries) {
        return null;
    }

    return (
        <TextField
            select
            name="phoneNumberCountry"
            id="phoneNumberCountry"
            variant="filled"
            label="Code"
            disabled={disabled}
            value={value || defaultCountry}
            defaultValue={defaultCountry}
            onChange={onCountrySelect}
            InputLabelProps={{ shrink: true }}
            className={classes.countrySelectMargin}
            SelectProps={{ classes: { root: classes.selectMenu } }}
        >
            {countries?.map(({ code }) => (
                <MenuItem
                    key={code}
                    value={code || ''}
                    className={classes.flagMenuItem}
                >
                    <span className={classes.countrySelectMargin}>
                        <Icon
                            component={flags?.[code]}
                            className={classes.flagIcons}
                        />
                        <Typography variant="body2">{code}</Typography>
                    </span>
                </MenuItem>
            ))}
        </TextField>
    );
};

PhoneNumberCountrySelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    defaultCountry: PropTypes.string.isRequired,
    setCallingCode: PropTypes.func,
    setDefaultPhoneCountry: PropTypes.func
};

PhoneNumberCountrySelect.defaultProps = {
    value: '',
    disabled: false,
    setCallingCode: () => {},
    onChange: () => {},
    setDefaultPhoneCountry: () => {}
};

export default PhoneNumberCountrySelect;
