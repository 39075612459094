import React from 'react';
import PropTypes from 'prop-types';
import NotesField from '@/components/common/NotesField';

const Description = ({ applyFieldToItem, item }) => (
    <NotesField
        label="Description"
        defaultRows={1}
        maxCharacters={2048}
        onChange={(event) => {
            applyFieldToItem('description', event.target.value);
        }}
        value={item.description}
        useHelperText={false}
        id={`DescriptionField-${item.lineId}`}
    />
);

Description.propTypes = {
    applyFieldToItem: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired
};

export default Description;
