import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    wrapper: {
        overflowX: 'hidden',
        [theme.breakpoints.up(558)]: {
            minWidth: 494,
            width: 494,
            padding: '35px 0'
        }
    },
    button: {
        width: '100%',
        margin: '0 35px',
        maxWidth: 'calc(100% - 70px)'
    },
    image: {
        width: 420,
        maxWidth: '100%'
    },
    squareSvg: {
        width: 411,
        height: 254,
        maxWidth: '100%'
    }
}));
