import React, { useEffect } from 'react';
import {
    Button,
    Grid,
    useMediaQuery,
    useTheme,
    Paper,
    Divider
} from '@mui/material';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useDispatch } from 'react-redux';
import { CreateDocumentImage, ZeroResultsImage } from '@/resources/images';
import {
    FilterButton,
    TableCard,
    TableMaker,
    TableMobileLabel,
    SearchInput,
    PageEmptyState,
    Pagination,
    PageTitle,
    SEO,
    SortButton,
    ListingClearButton,
    TableSkeleton,
    WrapWithPaper
} from '@/components/common';
import { RecurringInvoiceFilterDrawer } from '@/components/FilterDrawers';
import {
    useCurrency,
    useLocale,
    useTimezone,
    useUrlParams,
    useListingState,
    useCheckHasBusinessLoaded,
    useResetMakeInvoice
} from '@/hooks';
import { RECURRING_INVOICE_BASE_PARAM } from '@/constants';
import { getRecurringInvoices } from '@/modules/dataWrangler/dataWrangler';
import { Container, Layout } from '@/containers';
import { RecurringIcon } from '@/resources/icons';
import { fetchListingItems } from '@/state/actions';
import { recurringSortMenuItems } from '@/config';
import * as segmentManager from '@/modules/segment/segmentManager';
import useStyles from './styles';
import { dataProcessor, generateComponents } from './dataProcessors';
import tableHead from './tableHead';
import SubscriptionBanner from '../../components/SubscriptionBanner';

const RecurringInvoices = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const resetRecurringInvoice = useResetMakeInvoice('recurring-invoice');
    const navigate = useNavigate();

    const { code: currency } = useCurrency();
    const locale = useLocale();
    const timezone = useTimezone();
    const hasBusinessLoaded = useCheckHasBusinessLoaded();
    const { setUrlParams, getUrlParams } = useUrlParams();

    const {
        hasData,
        items,
        loadingItems,
        loadingPaper,
        totalPages,
        zeroResultsReturned
    } = useListingState('recurring-invoice');

    const currentUrlParams = getUrlParams({
        base: RECURRING_INVOICE_BASE_PARAM
    });
    const stringifiedParams = JSON.stringify(currentUrlParams);

    const noFiltersApplied = Object.keys(currentUrlParams).length === 0;

    const setOrder = (property) => {
        const sortOrder =
            currentUrlParams?.sort?.type === property &&
            currentUrlParams?.sort?.order === 'asc'
                ? 'desc'
                : 'asc';

        const newUrlParams = {
            ...currentUrlParams,
            sort: {
                type: property,
                order: sortOrder
            }
        };

        setUrlParams({
            base: RECURRING_INVOICE_BASE_PARAM,
            params: newUrlParams
        });
    };

    useEffect(() => {
        if (hasBusinessLoaded) {
            getRecurringInvoices(JSON.parse(stringifiedParams));
        }
    }, [hasBusinessLoaded, stringifiedParams]);

    return (
        <Layout>
            <SEO title="Recurring Invoices" />
            <Container>
                <div
                    id="cio_message"
                    className={classes.cio_message}
                    data-testid="cio_message"
                />
                <SubscriptionBanner />

                {!hasData && !loadingItems && (
                    <PageEmptyState
                        image={<CreateDocumentImage />}
                        content="Create Your First Recurring Invoice"
                        buttonText="Create Recurring Invoice"
                        onClick={async () => {
                            await segmentManager.documentStarted(
                                'recurring-invoice'
                            );
                            navigate('/recurring-invoice');
                        }}
                    />
                )}
                <>
                    {hasData && !loadingPaper && (
                        <>
                            {!isMobile && (
                                <Paper elevation={0} variant="outlined">
                                    <Grid
                                        container
                                        direction="row"
                                        spacing={1}
                                        wrap="nowrap"
                                        alignItems="center"
                                    >
                                        <Grid item md={4}>
                                            <PageTitle
                                                icon={<RecurringIcon />}
                                                title="Recurring Invoices"
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <div className={classes.searchWrap}>
                                                <SearchInput
                                                    base={
                                                        RECURRING_INVOICE_BASE_PARAM
                                                    }
                                                    id="dropdownSearch"
                                                    label="Search"
                                                    ariaLabel="Search invoices."
                                                />
                                            </div>
                                        </Grid>

                                        <Grid
                                            item
                                            md={2}
                                            className={classes.filterButtonWrap}
                                        >
                                            <Grid container alignItems="center">
                                                <Grid item md={8}>
                                                    <FilterButton
                                                        base={
                                                            RECURRING_INVOICE_BASE_PARAM
                                                        }
                                                        type="recurringInvoice"
                                                        watch={[
                                                            'status',
                                                            'client',
                                                            'total',
                                                            'amountSent',
                                                            'nextSendDate',
                                                            'tagIds'
                                                        ]}
                                                    />
                                                </Grid>
                                                <Grid item md={4}>
                                                    <ListingClearButton
                                                        base={
                                                            RECURRING_INVOICE_BASE_PARAM
                                                        }
                                                        watch={[
                                                            'status',
                                                            'client',
                                                            'total',
                                                            'amountSent',
                                                            'nextSendDate',
                                                            'tagIds'
                                                        ]}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            item
                                            container
                                            justifyContent="flex-end"
                                            md={4}
                                            className={
                                                classes.newInvoiceButtonContainer
                                            }
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                className={
                                                    classes.newInvoiceButton
                                                }
                                                onClick={async () => {
                                                    await segmentManager.documentStarted(
                                                        'recurring-invoice'
                                                    );
                                                    resetRecurringInvoice();
                                                    navigate(
                                                        '/recurring-invoice'
                                                    );
                                                }}
                                            >
                                                New Recurring Invoice
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <TableMaker
                                        actionCols={[5, 6, 7, 8, 9, 10, 11]}
                                        ariaTableName="Recurring Invoices Table"
                                        handleRequestSort={setOrder}
                                        headCells={tableHead}
                                        rowData={
                                            hasData && !zeroResultsReturned
                                                ? generateComponents({
                                                      array: dataProcessor(
                                                          items
                                                      ),
                                                      globalCurrency: currency,
                                                      timezone,
                                                      mobile: false,
                                                      locale
                                                  })
                                                : []
                                        }
                                        order={
                                            currentUrlParams?.sort?.order ||
                                            'asc'
                                        }
                                        orderBy={
                                            currentUrlParams?.sort?.type || ''
                                        }
                                    />
                                    {zeroResultsReturned &&
                                        (noFiltersApplied && !loadingItems ? (
                                            <PageEmptyState
                                                image={<ZeroResultsImage />}
                                                content="You do not have any recurring invoices in your selected currency."
                                                subContent="Update your currency preference in Settings or create a new invoice to see your documents."
                                                buttonText="Create Recurring Invoice"
                                                onClick={async () => {
                                                    await segmentManager.documentStarted(
                                                        'recurring-invoice'
                                                    );
                                                    navigate(
                                                        '/recurring-invoice'
                                                    );
                                                }}
                                                hidePaper
                                            />
                                        ) : (
                                            <PageEmptyState
                                                image={<ZeroResultsImage />}
                                                content="The selected filters returned 0 results."
                                                buttonText="Reset filters"
                                                onClick={() => {
                                                    fetchListingItems({
                                                        type: 'recurring-invoice'
                                                    });
                                                    setUrlParams({
                                                        base: RECURRING_INVOICE_BASE_PARAM,
                                                        params: null
                                                    });
                                                }}
                                                hidePaper
                                            />
                                        ))}
                                    <Pagination
                                        base={RECURRING_INVOICE_BASE_PARAM}
                                        totalPages={totalPages}
                                    />
                                </Paper>
                            )}

                            {isMobile && (
                                <>
                                    <Grid
                                        container
                                        direction="row"
                                        spacing={1}
                                        wrap="nowrap"
                                        alignItems="center"
                                    >
                                        <Grid item xs={10}>
                                            <PageTitle
                                                icon={<RecurringIcon />}
                                                title="Recurring Invoices"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            justifyContent="flex-end"
                                            xs={2}
                                            className={
                                                classes.newInvoiceButtonContainer
                                            }
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                className={
                                                    classes.newInvoiceButton
                                                }
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    onClick={() => {
                                                        resetRecurringInvoice();
                                                        navigate(
                                                            '/recurring-invoice'
                                                        );
                                                    }}
                                                >
                                                    New
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className={classes.searchWrap}>
                                                <SearchInput
                                                    base={
                                                        RECURRING_INVOICE_BASE_PARAM
                                                    }
                                                    id="dropdownSearch"
                                                    label="Search"
                                                    ariaLabel="Search invoices."
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent="center">
                                        <Grid
                                            item
                                            container
                                            spacing={1}
                                            xs={12}
                                            direction="row"
                                            wrap="nowrap"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid
                                                item
                                                container
                                                xs={6}
                                                className={
                                                    classes.sortButtonWrap
                                                }
                                            >
                                                <SortButton
                                                    base={
                                                        RECURRING_INVOICE_BASE_PARAM
                                                    }
                                                    isLoading={loadingItems}
                                                    sortMenuItems={
                                                        recurringSortMenuItems
                                                    }
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                className={
                                                    classes.filterButtonWrap
                                                }
                                            >
                                                <FilterButton
                                                    base={
                                                        RECURRING_INVOICE_BASE_PARAM
                                                    }
                                                    type="recurringInvoice"
                                                    watch={[
                                                        'status',
                                                        'client',
                                                        'total',
                                                        'amountSent',
                                                        'nextSendDate',
                                                        'tagIds'
                                                    ]}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item md={4}>
                                            <ListingClearButton
                                                base={
                                                    RECURRING_INVOICE_BASE_PARAM
                                                }
                                                watch={[
                                                    'status',
                                                    'client',
                                                    'total',
                                                    'amountSent',
                                                    'nextSendDate',
                                                    'tagIds'
                                                ]}
                                                hide={
                                                    zeroResultsReturned ||
                                                    loadingItems
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    {hasData &&
                                        !zeroResultsReturned &&
                                        generateComponents({
                                            array: dataProcessor(items),
                                            globalCurrency: currency,
                                            mobile: true,
                                            locale,
                                            timezone
                                        }).map((item) => (
                                            <TableCard
                                                key={item.id}
                                                title={item.client}
                                                to={`/recurring-invoice/${item.id}`}
                                                left={
                                                    <>
                                                        <TableMobileLabel label="Total" />
                                                        {item.total}
                                                        <TableMobileLabel label="Frequency" />
                                                        {item.frequency}
                                                    </>
                                                }
                                                right={
                                                    <>
                                                        {item.state}
                                                        {item?.nextSendDate && (
                                                            <>
                                                                <TableMobileLabel label="Next Send" />
                                                                {
                                                                    item?.nextSendDate
                                                                }
                                                            </>
                                                        )}
                                                    </>
                                                }
                                                actions={item.actions}
                                                tags={item.tags}
                                            />
                                        ))}
                                    {zeroResultsReturned &&
                                        (noFiltersApplied && !loadingItems ? (
                                            <PageEmptyState
                                                image={<ZeroResultsImage />}
                                                content="You do not have any recurring invoices in your selected currency."
                                                subContent="Update your currency preference in Settings or create a new invoice to see your documents."
                                                buttonText="Create Recurring Invoice"
                                                buttonLink="/"
                                            />
                                        ) : (
                                            <PageEmptyState
                                                image={<ZeroResultsImage />}
                                                content="The selected filters returned 0 results."
                                                buttonText="Reset filters"
                                                onClick={() => {
                                                    dispatch(
                                                        fetchListingItems({
                                                            type: 'recurring-invoice'
                                                        })
                                                    );
                                                    setUrlParams({
                                                        base: RECURRING_INVOICE_BASE_PARAM,
                                                        params: null
                                                    });
                                                }}
                                            />
                                        ))}
                                    <Paper elevation={0} variant="outlined">
                                        <Pagination
                                            base={RECURRING_INVOICE_BASE_PARAM}
                                            totalPages={totalPages}
                                        />
                                    </Paper>
                                </>
                            )}
                        </>
                    )}
                    {loadingPaper && (
                        <WrapWithPaper removeWrap={isMobile}>
                            <TableSkeleton isMobile={isMobile} />
                        </WrapWithPaper>
                    )}
                    {!loadingPaper && <RecurringInvoiceFilterDrawer />}
                </>
            </Container>
        </Layout>
    );
};

export default RecurringInvoices;
