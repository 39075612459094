/**
 * This function takes in a number and locale data object and formats to given locale format
 *
 * @param {number} number Input number to format.
 * @param {string} locale Destination locale to format to.
 * @param {bool} optionalDecimal Allow no decimal places if number allows (i.e. 10.00 = 10).
 * @returns {string} Formatted decimal number.
 */

export const formatDecimal = ({ number, locale, optionalDecimal }) =>
    new Intl.NumberFormat(locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: optionalDecimal ? 0 : 2
    }).format(number);

/**
 * This function takes in a number and locale data object and formats to given locale percentage format
 *
 * @param {number} number Number to format as to be shown. 100.50 = 100.50%
 * @param {string} locale Destination locale to format to
 * @returns {string} Formatted percentage
 */

export const formatPercentage = ({ number, locale }) => {
    const reducedNumber = number / 100;

    return new Intl.NumberFormat(locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        style: 'percent'
    }).format(reducedNumber);
};
