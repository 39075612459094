import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import makeStyles from '@mui/styles/makeStyles';
import { createInvoice } from '@/modules/dataWrangler/dataWrangler';
import { ConvertIconWhite } from '@/resources/icons';
import * as segmentManager from '@/modules/segment/segmentManager';
import { useSubscription } from '../useSubscription';
import { useToastNotification } from '../useToastNotification';

const useStyles = makeStyles(() => ({
    convertIcon: {
        '& path:nth-child(3)': {
            filter: 'brightness(60%)'
        },
        '& path:last-child': {
            filter: 'brightness(60%)'
        },
        '& circle': {
            stroke: '#00834E',
            filter: 'brightness(60%)',
            fill: '#FFF'
        }
    }
}));

export const useConvertEstimate = () => {
    const navigate = useNavigate();
    const subscriptionData = useSubscription();
    const { triggerToast } = useToastNotification();
    const classes = useStyles();

    return async (estimateData) => {
        const { documentState, customLabel } = estimateData;

        const subInfo = subscriptionData?.components?.document;
        const blockEstimateCreation =
            !subInfo?.enabled ||
            (subInfo?.limited && subInfo?.amountRemaining < 1);

        // If the user has no more documents remaining, force them to subscription page.
        if (blockEstimateCreation) {
            navigate('/settings/subscription');
            return false;
        }

        if (documentState !== 'approved') {
            return false;
        }

        const convertedEstimateData = {
            ...estimateData,
            documentState: 'draft',
            documentType: 'invoice',
            documentId: undefined,
            customLabel: undefined
        };

        await segmentManager.documentStarted('invoice');
        const newInvoiceObject = await createInvoice(convertedEstimateData);

        if (newInvoiceObject?.documentId) {
            triggerToast({
                message: `Estimate ${customLabel} Converted`,
                action: 'saved',
                icon: <ConvertIconWhite className={classes.convertIcon} />
            });

            setTimeout(() => {
                navigate(`/invoice/${newInvoiceObject.documentId}`);
            }, 1000);
        }
        return newInvoiceObject;
    };
};
