import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, useTheme } from '@mui/material';
import { useUrlParams } from '@/hooks';
import InlineLink from '../InlineLink';
import useStyles from './styles';

const ListingClearButton = ({ base, watch, hide }) => {
    const { getUrlParams, setUrlParams } = useUrlParams();
    const params = getUrlParams({ base, watch });
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    if (Object.keys(params).length > 0 && !hide) {
        return (
            <InlineLink
                classes={classes.button}
                onClick={() => setUrlParams({ base, params: null })}
            >
                {isMobile ? 'Clear Filters' : 'Clear All'}
            </InlineLink>
        );
    }

    if (isMobile) {
        return <div className={classes.mobileSpacer} />;
    }

    return null;
};

ListingClearButton.propTypes = {
    base: PropTypes.string.isRequired,
    watch: PropTypes.arrayOf(PropTypes.string),
    hide: PropTypes.bool
};

ListingClearButton.defaultProps = {
    watch: [],
    hide: false
};

export default ListingClearButton;
