import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, useMediaQuery, useTheme, Paper, Divider } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ZeroResultsImage } from '@/resources/images';
import {
    FilterButton,
    TableCard,
    TableMaker,
    TableMobileLabel,
    SearchInput,
    PageEmptyState,
    Pagination,
    PageTitle,
    SortButton,
    ListingClearButton,
    TableSkeleton,
    WrapWithPaper
} from '@/components/common';
import { InvoiceFilterDrawer } from '@/components/FilterDrawers';
import {
    useCurrency,
    useLocale,
    useTimezone,
    useUrlParams,
    useListingState,
    useCheckHasBusinessLoaded
} from '@/hooks';
import { INVOICE_BASE_PARAM } from '@/constants';
import { getInvoices } from '@/modules/dataWrangler/dataWrangler';
import { documentSortMenuItems } from '@/config';
import { InvoicesActive } from '@/resources/icons';
import { fetchListingItems } from '@/state/actions';
import useStyles from './styles';
import { dataProcessor, generateComponents } from './dataProcessors';
import tableHead from './tableHead';

const RecurringInvoicesCreatedTable = ({ recurringInvoiceId }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();

    const { code: currency } = useCurrency();
    const locale = useLocale();
    const timezone = useTimezone();
    const hasBusinessLoaded = useCheckHasBusinessLoaded();
    const { setUrlParams, getUrlParams } = useUrlParams();

    const {
        hasData,
        items,
        loadingItems,
        loadingPaper,
        totalPages,
        zeroResultsReturned
    } = useListingState('invoice');

    const currentUrlParams = getUrlParams({ base: INVOICE_BASE_PARAM });

    // Hard code recurringInvoiceId into params.
    const stringifiedParams = JSON.stringify({
        ...currentUrlParams,
        recurringInvoiceId
    });

    const setOrder = (property) => {
        const sortOrder =
            currentUrlParams?.sort?.type === property &&
            currentUrlParams?.sort?.order === 'asc'
                ? 'desc'
                : 'asc';

        const newUrlParams = {
            ...currentUrlParams,
            sort: {
                type: property,
                order: sortOrder
            }
        };

        setUrlParams({ base: INVOICE_BASE_PARAM, params: newUrlParams });
    };

    useEffect(() => {
        if (hasBusinessLoaded) {
            getInvoices(JSON.parse(stringifiedParams));
        }
    }, [hasBusinessLoaded, stringifiedParams]);

    return (
        <div>
            {hasData && !loadingPaper && (
                <>
                    {!isMobile && (
                        <Paper elevation={0} variant="outlined">
                            <Grid
                                container
                                direction="row"
                                spacing={1}
                                wrap="nowrap"
                                alignItems="center"
                            >
                                <Grid item md={4}>
                                    <PageTitle
                                        icon={<InvoicesActive />}
                                        title="Created Invoices"
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <div className={classes.searchWrap}>
                                        <SearchInput
                                            base={INVOICE_BASE_PARAM}
                                            id="dropdownSearch"
                                            label="Search"
                                            ariaLabel="Search invoices."
                                        />
                                    </div>
                                </Grid>

                                <Grid
                                    item
                                    md={2}
                                    className={classes.filterButtonWrap}
                                >
                                    <Grid container alignItems="center">
                                        <Grid item md={8}>
                                            <FilterButton
                                                base={INVOICE_BASE_PARAM}
                                                type="invoice"
                                                watch={[
                                                    'status',
                                                    'client',
                                                    'total',
                                                    'balancePaid',
                                                    'balanceDue',
                                                    'dueDate',
                                                    'issuedDate',
                                                    'tagIds'
                                                ]}
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <ListingClearButton
                                                base={INVOICE_BASE_PARAM}
                                                watch={[
                                                    's',
                                                    'status',
                                                    'client',
                                                    'total',
                                                    'balancePaid',
                                                    'balanceDue',
                                                    'dueDate',
                                                    'issuedDate',
                                                    'tagIds'
                                                ]}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider />
                            <TableMaker
                                actionCols={[5, 6, 7, 8, 9, 10, 11]}
                                ariaTableName="Invoices Table"
                                handleRequestSort={setOrder}
                                headCells={tableHead}
                                rowData={
                                    hasData && !zeroResultsReturned
                                        ? generateComponents({
                                              array: dataProcessor(items),
                                              globalCurrency: currency,
                                              timezone,
                                              mobile: false,
                                              locale
                                          })
                                        : []
                                }
                                order={currentUrlParams?.sort?.order || 'asc'}
                                orderBy={currentUrlParams?.sort?.type || ''}
                            />
                            {zeroResultsReturned && !loadingItems && (
                                <PageEmptyState
                                    image={<ZeroResultsImage />}
                                    content="The selected filters returned 0 results."
                                    buttonText="Reset filters"
                                    onClick={() => {
                                        dispatch(
                                            fetchListingItems({
                                                type: 'invoice'
                                            })
                                        );
                                        setUrlParams({
                                            base: INVOICE_BASE_PARAM,
                                            params: null
                                        });
                                    }}
                                    hidePaper
                                />
                            )}
                            <Pagination
                                base={INVOICE_BASE_PARAM}
                                totalPages={totalPages}
                            />
                        </Paper>
                    )}

                    {isMobile && (
                        <>
                            <Grid
                                container
                                direction="row"
                                spacing={1}
                                wrap="nowrap"
                                alignItems="center"
                            >
                                <Grid item xs={6}>
                                    <PageTitle
                                        icon={<InvoicesActive />}
                                        title="Created Invoices"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <div className={classes.searchWrap}>
                                        <SearchInput
                                            base={INVOICE_BASE_PARAM}
                                            id="dropdownSearch"
                                            label="Search"
                                            ariaLabel="Search recent clients."
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center">
                                <Grid
                                    item
                                    container
                                    spacing={1}
                                    xs={12}
                                    direction="row"
                                    wrap="nowrap"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid
                                        item
                                        container
                                        xs={6}
                                        className={classes.sortButtonWrap}
                                    >
                                        <SortButton
                                            base={INVOICE_BASE_PARAM}
                                            isLoading={loadingItems}
                                            sortMenuItems={
                                                documentSortMenuItems
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        className={classes.filterButtonWrap}
                                    >
                                        <FilterButton
                                            base={INVOICE_BASE_PARAM}
                                            type="invoice"
                                            watch={[
                                                'status',
                                                'client',
                                                'total',
                                                'balancePaid',
                                                'balanceDue',
                                                'dueDate',
                                                'issuedDate',
                                                'tagIds'
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item md={4}>
                                    <ListingClearButton
                                        base={INVOICE_BASE_PARAM}
                                        watch={[
                                            's',
                                            'status',
                                            'client',
                                            'total',
                                            'balancePaid',
                                            'balanceDue',
                                            'dueDate',
                                            'issuedDate',
                                            'sort',
                                            'tagIds'
                                        ]}
                                        hide={
                                            zeroResultsReturned || loadingItems
                                        }
                                    />
                                </Grid>
                            </Grid>
                            {hasData &&
                                !zeroResultsReturned &&
                                generateComponents({
                                    array: dataProcessor(items),
                                    globalCurrency: currency,
                                    mobile: true,
                                    locale,
                                    timezone
                                }).map((item) => (
                                    <TableCard
                                        key={item.id}
                                        title={item.to}
                                        to={`/invoice/${item.id}`}
                                        left={
                                            <>
                                                <TableMobileLabel label="Total" />
                                                {item.total}
                                                <TableMobileLabel label="Amount Due" />
                                                {item.due}
                                            </>
                                        }
                                        right={
                                            <>
                                                {item.documentState}
                                                <span>{item.label}</span>
                                                <span>{item.date}</span>
                                            </>
                                        }
                                        actions={item.actions}
                                        tags={item.tags}
                                    />
                                ))}
                            {zeroResultsReturned && !loadingItems && (
                                <PageEmptyState
                                    image={<ZeroResultsImage />}
                                    content="The selected filters returned 0 results."
                                    buttonText="Reset filters"
                                    onClick={() => {
                                        dispatch(
                                            fetchListingItems({
                                                type: 'invoice'
                                            })
                                        );
                                        setUrlParams({
                                            base: INVOICE_BASE_PARAM,
                                            params: null
                                        });
                                    }}
                                />
                            )}
                            <Paper elevation={0} variant="outlined">
                                <Pagination
                                    base={INVOICE_BASE_PARAM}
                                    totalPages={totalPages}
                                />
                            </Paper>
                        </>
                    )}
                </>
            )}
            {loadingPaper && (
                <WrapWithPaper removeWrap={isMobile}>
                    <TableSkeleton isMobile={isMobile} />
                </WrapWithPaper>
            )}
            {!loadingPaper && <InvoiceFilterDrawer />}
        </div>
    );
};

RecurringInvoicesCreatedTable.propTypes = {
    recurringInvoiceId: PropTypes.string
};

RecurringInvoicesCreatedTable.defaultProps = {
    recurringInvoiceId: ''
};

export default RecurringInvoicesCreatedTable;
