import React from 'react';
import PropTypes from 'prop-types';
import MakeDocument from '../MakeDocument';

const MakeEstimate = ({ isLoading }) => (
    // future logic to be passed down here
    <MakeDocument documentType="estimate" isLoading={isLoading} />
);

MakeEstimate.propTypes = {
    isLoading: PropTypes.bool
};

MakeEstimate.defaultProps = {
    isLoading: false
};

export default MakeEstimate;
