export const emptyProduct = {
    product: '',
    name: '',
    price: 0,
    description: '',
    taxes: [],
    discounts: [],
    productId: '',
    quantity: 1
};

export const freePlan = [
    {
        productId: 0,
        name: 'Free',
        productHandle: 'free-monthly',
        description: '1 Document Per Month',
        price: 0,
        interval: 1,
        intervalUnit: 'month',
        rank: 0
    },
    {
        productId: 1,
        name: 'Free',
        productHandle: 'free-yearly',
        description: '1 Document Per Month',
        price: 0,
        interval: 12,
        intervalUnit: 'month',
        rank: 0
    }
];
