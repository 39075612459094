import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    form: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: `calc(100% - ${theme.typography.pxToRem(90)})`
    },
    footerButtonContainer: {
        display: 'flex',
        position: 'sticky',
        bottom: 0,
        left: 0,
        right: 0,
        padding: `${theme.typography.pxToRem(20)}`,
        [theme.breakpoints.only('xs')]: {
            padding: `${theme.typography.pxToRem(10)} 
            ${theme.typography.pxToRem(10)} 
            calc(env(safe-area-inset-bottom) + ${theme.typography.pxToRem(10)})`
        },
        boxShadow: '0px -5px 5px -5px #DEDEE7',
        margin: `${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(
            -15
        )} ${theme.typography.pxToRem(-25)}`,
        zIndex: 999,
        backgroundColor: theme.palette.common.white
    },
    stepper: {
        padding: `${theme.typography.pxToRem(24)} 0`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'inherit',
        margin: `0 -10% ${theme.spacing(3)} -10%`
    },
    footerButton: {
        padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
            20
        )}`,
        margin: `0 ${theme.typography.pxToRem(5)}`,
        '& disabled': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main
        }
    },
    formHeader: {
        marginBottom: theme.typography.pxToRem(20)
    },
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: theme.palette.common.white,
        width: 34,
        height: 34,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    active: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(18)
    },
    completed: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(18)
    },
    stepConnector: {
        top: 18,
        padding: `0 ${theme.spacing(3)}`
    },
    stepUnderline: {
        borderBottom: `3px solid ${theme.palette.primary.main}`,
        paddingBottom: theme.spacing(2),
        width: theme.typography.pxToRem(90),
        position: 'absolute',
        left: '50%',
        transform: `translate(-50%)`,
        color: theme.palette.primary.main
    },
    inactiveLabel: {
        color: theme.palette.common.black,
        opacity: 0.2
    },
    uploadedCodeImage: {
        maxWidth: '100%',
        maxHeight: theme.typography.pxToRem(400),
        display: 'block',
        margin: 'auto',
        boxShadow: '0px 5px 10px 3px #DEDEE7'
    },
    checkmarkIcon: {
        width: 18,
        height: 18,
        marginRight: theme.spacing(1),
        verticalAlign: 'middle'
    },
    userMerchantLink: {
        display: 'inline-block'
    },
    contentBody: {
        lineHeight: 2,
        marginTop: theme.spacing(2)
    },
    errorIcon: {
        width: theme.typography.pxToRem(20),
        height: theme.typography.pxToRem(20),
        verticalAlign: 'middle'
    },
    zelleText: {
        color: '#6D1DD4',
        fontWeight: 600
    },
    nonClickableTextField: {
        pointerEvents: 'none'
    }
}));
