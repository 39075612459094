/* eslint import/no-cycle: "off"  */
import store from '@/state/store';
import {
    deleteLogo as deleteLogoAction,
    setDeleteLogoComplete,
    setDeleteLogoPending,
    setUpdateLogoComplete,
    setUpdateLogoPending,
    updateLogo as updateLogoAction
} from '@/state/actions';

import { SET_GLOBAL_ERROR } from '@/constants';

import { convertTemporaryUserToUnregisteredUser } from '@/modules/setup';

import axiosAuthenticated from '../authenticatedRequestor';

export const updateLogo = async (logo) => {
    if (store.getState().user.temporaryUser) {
        await convertTemporaryUserToUnregisteredUser();
    }
    const { businessId } = store.getState().user.activeBusiness;

    try {
        store.dispatch(setUpdateLogoPending());
        const response = await axiosAuthenticated.put(
            `/businesses/${businessId}/logos`,
            { logo }
        );

        store.dispatch(updateLogoAction(response.data.logo));
    } catch (err) {
        store.dispatch({ type: SET_GLOBAL_ERROR });
        throw err;
    } finally {
        store.dispatch(setUpdateLogoComplete());
    }
};

export const deleteLogo = async () => {
    const { businessId } = store.getState().user.activeBusiness;

    try {
        store.dispatch(setDeleteLogoPending());
        await axiosAuthenticated.delete(`/businesses/${businessId}/logos`);

        store.dispatch(deleteLogoAction());
    } catch (err) {
        store.dispatch({ type: SET_GLOBAL_ERROR });
        throw err;
    } finally {
        store.dispatch(setDeleteLogoComplete());
    }
};
