import React from 'react';
import { formatCurrency, formatDate, toWholeCurrency } from '@/util';

import {
    MobileItemTags,
    TableActionsWrap,
    StatusPill,
    TagsIcon
} from '@/components/common';

// Process array of objects into a stripped down version
// that only has required data so is faster to process.
export const dataProcessor = (data) =>
    data.map(
        ({
            balanceDue,
            documentId,
            documentState,
            referenceNumber,
            customLabel,
            to,
            dueDate,
            currency,
            totals: { total },
            tags
        }) => ({
            client: to,
            currency,
            id: documentId,
            label: customLabel || referenceNumber,
            to: to?.name || '',
            name: to?.name || '',
            date: dueDate,
            due: balanceDue,
            total,
            documentState,
            tags
        })
    );

export const generateComponents = ({
    array,
    mobile,
    locale,
    timezone,
    globalCurrency
}) =>
    array.map(
        ({
            client,
            id,
            label,
            to,
            date,
            due,
            total,
            documentState,
            currency,
            tags
        }) => {
            const thisCurrency =
                currency && documentState !== 'draft'
                    ? currency
                    : globalCurrency;

            return {
                id,
                label,
                date: formatDate({
                    isoDate: date,
                    locale,
                    timezone
                }),
                to,
                total: formatCurrency({
                    currency: thisCurrency,
                    amount: toWholeCurrency(total),
                    locale
                }),
                // Balance Due - not due date.
                due: formatCurrency({
                    currency: thisCurrency,
                    amount: toWholeCurrency(due),
                    locale
                }),
                documentState: (
                    <StatusPill padding={mobile} status={documentState} />
                ),
                tags: mobile ? (
                    <MobileItemTags type="invoice" itemId={id} tags={tags} />
                ) : (
                    <TagsIcon type="invoice" itemId={id} tags={tags} />
                ),
                actions: (
                    <TableActionsWrap
                        id={id}
                        label={label}
                        open={
                            documentState === 'draft'
                                ? 'editInvoice'
                                : 'viewInvoice'
                        }
                        rest={[
                            // 'sendInvoice',
                            'downloadInvoice',
                            'duplicateInvoice'
                        ]}
                        toPass={{ client }}
                    />
                ),
                primaryLink: `/invoice/${id}`
            };
        }
    );
