import React from 'react';
import PropTypes from 'prop-types';
import MakeDocument from '../MakeDocument';

const MakeReceipt = ({ isLoading }) => (
    // future logic to be passed down here
    <MakeDocument documentType="receipt" isLoading={isLoading} />
);

MakeReceipt.propTypes = {
    isLoading: PropTypes.bool
};

MakeReceipt.defaultProps = {
    isLoading: false
};

export default MakeReceipt;
