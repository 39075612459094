import makeStyles from '@mui/styles/makeStyles';

const topSpacing = 86;
const navbar = 200;

export default makeStyles((theme) => ({
    appContainer: {
        maxWidth: '100%',
        padding: `calc(env(safe-area-inset-top) + ${theme.typography.pxToRem(
            20
        )}) calc(env(safe-area-inset-right) + ${theme.typography.pxToRem(
            12
        )}) calc(env(safe-area-inset-bottom) + ${theme.typography.pxToRem(
            20
        )}) calc(env(safe-area-inset-left) + ${theme.typography.pxToRem(12)})`,

        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(3),
            margin: `${theme.typography.pxToRem(64)} auto 0 auto`
        },
        [theme.breakpoints.up('lg')]: {
            margin: `${topSpacing}px auto 0 auto`,
            display: 'grid',
            padding: theme.spacing(0, 3, 0, 3),
            gridTemplateColumns: `${navbar}px 1fr`,
            gridTemplateRows: 'auto',
            gridColumnGap: theme.typography.pxToRem(29),
            gridRowGap: 0,
            maxWidth: 1600,
            gridTemplateAreas: "'navbar page-view'"
        },
        [theme.breakpoints.up('xl')]: {
            padding: 0
        },
        fallbacks: {
            padding: `${theme.typography.pxToRem(
                24
            )} ${theme.typography.pxToRem(12)}`
        }
    },
    pageContainer: {
        gridArea: 'page-view',
        minHeight: `calc(100vh - ${topSpacing}px)`,
        padding: theme.spacing(2)
    },
    loader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        gridArea: 'page-view'
    }
}));
