import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    dialog: {
        maxWidth: theme.typography.pxToRem(770),
        margin: 'auto'
    },
    wrapper: {
        position: 'relative',
        paddingBottom: '110%',
        backgroundColor: (props) => props.backgroundColor,
        overflow: 'hidden',
        [theme.breakpoints.up('sm')]: {
            paddingBottom: '90%'
        },
        [theme.breakpoints.up('md')]: {
            paddingBottom: '71%'
        }
    },
    inner: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        padding: `0 ${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(
            20
        )} ${theme.typography.pxToRem(20)}`,
        [theme.breakpoints.up('sm')]: {
            position: 'absolute'
        }
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(26),
        textAlign: 'center',
        marginTop: theme.typography.pxToRem(25)
    }
}));
