import { parseISO } from 'date-fns';
import React, { useState, useEffect, useRef } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import TextField from '@mui/material/TextField';
import defaultUSLocale from 'date-fns/locale/en-US';
import isValid from 'date-fns/isValid';
import PropTypes from 'prop-types';
import { useLocale, useTimezone } from '@/hooks';
import { formatDate, getLocaleFile } from '@/util';
import useStyles from './styles';

const GenericDatePicker = ({
    error,
    helperText,
    disabled,
    disablePast,
    id,
    label,
    margin,
    required,
    selectionReturn,
    setDateError,
    startDate,
    minDate,
    value,
    className
}) => {
    const classes = useStyles();
    const locale = useLocale();
    const timezone = useTimezone();

    // Preset with en-US to speed up initial page load.
    const [pickedLocale, setPickedLocale] = useState(defaultUSLocale);
    const localeRef = useRef('en-US');

    // As we don't want to load every single locale under the sun
    // on the initial render of the app set locale to en-US.
    // Then when redux kicks in, load their specific locale.
    // If that loader fails - we can keep en-US.
    useEffect(() => {
        const importLocale = async () => {
            try {
                // This handles setting the locale.
                await getLocaleFile({
                    locale,
                    setPickedLocale,
                    localeRef
                });
            } catch {
                // Nothing
                // No state overwrite happens.
            }
        };
        // If the locale has not yet been loaded.
        if (localeRef.current !== locale) {
            importLocale();
        }
    }, [locale, pickedLocale]);

    // Display the date correctly. take format from locale and timezone
    const formatSelectLabel = (date, invalidLabel) =>
        date && isValid(date)
            ? formatDate({
                  isoDate: date,
                  locale,
                  timezone
              })
            : invalidLabel;

    return (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={pickedLocale} // use 'bg' locale for date parser/formatter
        >
            <MobileDatePicker
                autoOk
                disableToolbar
                disablePast={Boolean(disablePast)}
                fullWidth
                className={disabled ? classes.filledInputDisabledRoot : null}
                variant="inline"
                inputVariant={disabled ? 'standard' : 'filled'}
                disabled={disabled}
                minDate={minDate}
                error={disabled ? false : helperText || error}
                helperText={disabled ? '' : helperText}
                InputLabelProps={
                    disabled
                        ? {
                              classes: {
                                  disabled: classes.filledInputDisabledLabel
                              }
                          }
                        : {}
                }
                InputProps={
                    disabled
                        ? {
                              disableUnderline: true,
                              classes: {
                                  disabled: classes.filledInputDisabled
                              }
                          }
                        : {}
                }
                margin={!margin ? 'normal' : margin}
                labelFunc={formatSelectLabel}
                required={required}
                label={label}
                value={parseISO(value || startDate)}
                onChange={(data) => selectionReturn(data)}
                onError={(errorValue) => setDateError(Boolean(errorValue))}
                renderInput={(params) => {
                    let inputError = params.error;
                    if (params.error && value === '') {
                        inputError = false;
                    }
                    return (
                        <TextField
                            {...params}
                            error={inputError}
                            className={className}
                            id={id}
                            helperText={
                                inputError && 'Please enter a valid date'
                            }
                            fullWidth
                        />
                    );
                }}
            />
        </LocalizationProvider>
    );
};

GenericDatePicker.propTypes = {
    error: PropTypes.bool,
    helperText: PropTypes.string,
    disabled: PropTypes.bool,
    disablePast: PropTypes.bool,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    margin: PropTypes.string,
    required: PropTypes.bool,
    selectionReturn: PropTypes.func,
    setDateError: PropTypes.func,
    startDate: PropTypes.string,
    minDate: PropTypes.string,
    value: PropTypes.string,
    className: PropTypes.string
};

GenericDatePicker.defaultProps = {
    error: false,
    helperText: '',
    disabled: false,
    disablePast: true,
    margin: 'normal',
    required: false,
    startDate: '',
    selectionReturn: () => {},
    setDateError: () => {},
    minDate: '01/01/1700',
    value: '',
    className: ''
};

export default GenericDatePicker;
