import store from '@/state/store';
import { SET_LOCALIZATION_DATA } from '@/constants';

import axiosUnauthenticated from '../unauthenticatedRequestor';

export const getLocalizationSettings = async () => {
    try {
        const response = await axiosUnauthenticated.get(
            'utilities/localization'
        );

        store.dispatch({
            type: SET_LOCALIZATION_DATA,
            localization: response.data
        });

        return response.data;
    } catch (err) {
        return {};
    }
};
