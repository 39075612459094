import React from 'react';
import PropTypes from 'prop-types';
import { Capacitor } from '@capacitor/core';
import { ButtonBase, ListItem, Typography } from '@mui/material';
import { Help } from '@/resources/icons';
import { useZendeskWidget } from '@/hooks/useZendeskWidget';
import useStyles from './styles';

const ZendeskMenuItem = ({ handleMenuClose }) => {
    const classes = useStyles();
    const isIos = Capacitor.getPlatform() === 'ios';
    const { openZendeskWidget } = useZendeskWidget();

    if (isIos) {
        return null;
    }

    return (
        <ListItem className={`${classes.listItem} ${classes.zenDeskBase}`}>
            <ButtonBase
                className={classes.zenDeskHelpButton}
                onClick={() => {
                    openZendeskWidget();
                    handleMenuClose();
                }}
                color="primary"
                variant="contained"
            >
                <div className={classes.zenDeskHelpInnerContent}>
                    <Help className={classes.zenDeskHelpIcon} />
                    <Typography variant="body2">Help</Typography>
                </div>
            </ButtonBase>
        </ListItem>
    );
};

ZendeskMenuItem.propTypes = {
    handleMenuClose: PropTypes.func.isRequired
};

export default ZendeskMenuItem;
