/* eslint import/no-cycle: "off"  */
import store from '@/state/store';

import { globalPageCount } from '@/config';

import {
    overwriteDocument,
    setDocumentState,
    setVoidDocumentPending,
    setVoidDocumentComplete,
    noListingItems,
    overwriteListingItems,
    fetchListingItems
} from '@/state/actions';

import {
    convertDocumentFromApi,
    convertDocumentFromApiSetLocalization,
    createSaveableObject,
    filterArgFlattener
} from '@/util';

// eslint-disable-next-line import/no-cycle
import axiosAuthenticated from '../authenticatedRequestor';
import axiosUnauthenticated from '../unauthenticatedRequestor';
import * as subscription from '../subscriptionManager';
import * as segmentManager from '../segment/segmentManager';

/**
 * Approve an estimate.
 * @param  {string} documentId The document ID
 * @param  {string} name The name of approver. or empty string.
 * @param  {string} signature base64 string or empty string
 * @param  {string} type 'drawn', 'typed' or 'none'
 * @return {Promise} The estimate object.
 */
export const approveEstimate = async (documentId, name, signature, type) => {
    const { activeBusiness } = store.getState().user;

    try {
        const res = await axiosAuthenticated.post(
            `/businesses/${activeBusiness.businessId}/estimates/${documentId}/approve`,
            {
                signature,
                signatureType: type,
                name
            }
        );

        const newEstimate = convertDocumentFromApiSetLocalization(res.data);
        store.dispatch(overwriteDocument({ viewMode: 'edit', ...newEstimate }));
        return newEstimate;
    } catch {
        // Future error handling...
        return false;
    }
};

/**
 * Creates a new estimate.
 * @param  {object} estimate The estimate object.
 * @return {Promise} The estimate object.
 */
export const createEstimate = async (estimate) => {
    const { activeBusiness } = store.getState().user;
    const tagIds = estimate?.tags?.map((tag) => tag.tagId) || [];

    try {
        const response = await axiosAuthenticated.post(
            `/businesses/${activeBusiness.businessId}/estimates`,
            {
                ...estimate,
                tagIds,
                design: activeBusiness.documentCustomizations.design,
                disablePaymentStamp:
                    activeBusiness.documentCustomizations.disablePaymentStamp,
                logo: activeBusiness.logo || ''
            }
        );

        const newEstimate = convertDocumentFromApiSetLocalization(
            response.data
        );

        // Update the subscription object with new invoice count.
        subscription.getUserSubscription(activeBusiness.businessId);

        return newEstimate;
    } catch {
        return false;
    }
};

/**
 * Decline an estimate.
 * @param  {string} documentId The document ID
 * @return {Promise} The estimate object.
 */
export const declineEstimate = async (documentId) => {
    const { activeBusiness } = store.getState().user;

    try {
        const res = await axiosAuthenticated.post(
            `/businesses/${activeBusiness.businessId}/estimates/${documentId}/decline`
        );

        const newEstimate = convertDocumentFromApiSetLocalization(res.data);
        store.dispatch(overwriteDocument({ viewMode: 'edit', ...newEstimate }));
        return newEstimate;
    } catch {
        // Future error handling...
        return false;
    }
};

/**
 * Deletes an estimate using its document ID.
 */
export const deleteEstimate = async (documentId) => {
    const { businessId } = store.getState().user.activeBusiness;

    await axiosAuthenticated.delete(
        `/businesses/${businessId}/estimates/${documentId}`
    );
};

/**
 * Duplicates an estimate using its document ID.
 */
export const duplicateEstimate = async (estimateId) => {
    // Get the active business.
    const { activeBusiness } = store.getState().user;
    const { businessId } = activeBusiness;

    // Get the specific estimate to be duplicated.
    const res = await axiosAuthenticated.get(
        `/businesses/${businessId}/documents/${estimateId}`
    );

    const foundEstimate = res?.data;

    if (foundEstimate) {
        const convertedEstimate =
            convertDocumentFromApiSetLocalization(foundEstimate);
        const estimate = { ...convertedEstimate };
        const originalIssuedDate = new Date(estimate.issuedDate).getTime();
        const originalDueDate = new Date(estimate.dueDate).getTime();

        // Calculate the number of milliseconds between issued date and due date.
        const originalTermsMilliseconds = originalDueDate - originalIssuedDate;

        // Update issued date
        estimate.issuedDate = new Date(Date.now()).toISOString();

        // Update due date with correct term gap.
        estimate.dueDate = new Date(
            Date.now() + originalTermsMilliseconds
        ).toISOString();

        // Remove custom label to allow for a new one
        estimate.customLabel = undefined;

        // Pass the new estimate object to API.
        const newEstimate = await createEstimate(
            createSaveableObject({
                type: 'estimate',
                currentObject: estimate,
                activeBusiness
            })
        );

        await segmentManager.documentStarted('estimate');

        return newEstimate;
    }

    // If anything failed.
    return false;
};

/**
 * Gets an estimate from document ID.
 * @param  {string} documentId The estimate document id.
 * @return {Promise} The estimate object.
 */
export const getEstimateById = async (documentId) => {
    const { businessId } = store.getState().user?.activeBusiness;

    const res = await axiosAuthenticated.get(
        `/businesses/${businessId}/estimates/${documentId}`
    );

    const { documentState } = res.data;

    if (documentState === 'deleted') {
        // eslint-disable-next-line
        throw 'estimate has been deleted';
    }

    const newEstimate = convertDocumentFromApiSetLocalization(res.data);

    return newEstimate;
};

/**
 * Updated Redux with all users estimates from database.
 */
export const getAllEstimates = async (args) => {
    if (store.getState().user.temporaryUser) {
        store.dispatch(noListingItems({ type: 'estimate' }));
        return;
    }

    store.dispatch(fetchListingItems({ type: 'estimate' }));

    const { businessId } = store.getState().user.activeBusiness;

    const allArgs = { pageSize: globalPageCount, ...filterArgFlattener(args) };

    const response = await axiosAuthenticated.get(
        `/businesses/${businessId}/estimates`,
        { params: { sortOrder: 'desc', sortType: 'createdAt', ...allArgs } }
    );

    const { items, itemsMatchingRequest, pageSize, totalItems } = response.data;

    // Destructure into flatter object
    const listingItems = items.map((estimate) =>
        convertDocumentFromApiSetLocalization(estimate)
    );

    const totalPages = Math.ceil(itemsMatchingRequest / pageSize);

    store.dispatch(
        overwriteListingItems({
            type: 'estimate',
            items: listingItems,
            totalPages,
            totalItems
        })
    );
};

/**
 * Share an estimate.
 * @param  {string} documentId The estimate document id.
 * @param  {string} mode 'download', 'email', 'link',
 * @param  {string} email If mode is email, the email address to send to.
 * @return {Promise} The estimate object.
 */
export const shareEstimate = async (
    documentId,
    mode,
    email,
    emailSubject,
    emailBody
) => {
    const { businessId } = store.getState().user.activeBusiness;

    try {
        const response = await axiosAuthenticated.post(
            `/businesses/${businessId}/estimates/${documentId}/sharing`,
            {
                action: mode,
                emailAddress: email,
                subject: emailSubject,
                body: emailBody
            }
        );

        const newEstimate = convertDocumentFromApiSetLocalization(
            response.data
        );

        store.dispatch(
            overwriteDocument({
                viewMode: 'edit',
                ...newEstimate
            })
        );

        return response.data;
    } catch (err) {
        return err.response;
    }
};

/**
 * Approve an estimate publicly - used for /link page.
 * @param  {object} estimate Estimate to update.
 * @return {Promise} Bool. If update was successful.
 */
export const updateEstimate = async (estimate) => {
    const { businessId } = store.getState().user.activeBusiness;

    await Promise.all([
        axiosAuthenticated.put(
            `/businesses/${businessId}/estimates/${estimate.documentId}`,
            estimate
        )
    ]);

    return true;
};

/**
 * Void an estimate
 * @param {object} estimate The estimate object to void
 * @return {Promise} The estimate object.
 */
export const voidEstimate = async (estimate) => {
    store.dispatch(setVoidDocumentPending());

    const voidedEstimate = {
        ...estimate,
        documentState: 'void'
    };

    const { businessId } = store.getState().user.activeBusiness;

    await axiosAuthenticated.post(
        `/businesses/${businessId}/estimates/${estimate.documentId}/void`
    );

    store.dispatch(setDocumentState({ document: voidedEstimate }));
    store.dispatch(setVoidDocumentComplete());

    return voidedEstimate;
};

// Public endpoints

/**
 * Approve an estimate publicly - used for /link page.
 * @param  {string} jwt JWT passed in by link page
 * @param  {string} name The name of approver. or empty string.
 * @param  {string} signature base64 string or empty string
 * @param  {string} type 'drawn', 'typed' or 'none'
 * @return {Promise} The estimate object or false.
 */
export const approveEstimatePublic = async (jwt, name, signature, type) => {
    try {
        const res = await axiosUnauthenticated.post(
            `/public/documents/${jwt}/estimates/approve`,
            {
                signature,
                signatureType: type,
                name
            }
        );

        const newEstimate = convertDocumentFromApi({
            currentObject: { ...res.data, ...res.data.document }
        });

        return newEstimate;
    } catch {
        // Future error handling...
        return false;
    }
};

/**
 * Decline an estimate publicly - used for /link page.
 * @param  {string} jwt JWT passed in by link page
 * @return {Promise} Estimate object or false.
 */
export const declineEstimatePublic = async (jwt) => {
    try {
        const res = await axiosUnauthenticated.post(
            `/public/documents/${jwt}/estimates/decline`
        );

        const newEstimate = convertDocumentFromApi({
            currentObject: { ...res.data, ...res.data.document }
        });

        return newEstimate;
    } catch {
        // Future error handling...
        return false;
    }
};

/**
 * Get estimates CSV
 * @param  {object} args Filter arguments.
 */
export const downloadEstimatesCsv = async (args) => {
    const { businessId } = store.getState().user.activeBusiness;

    try {
        const response = await axiosAuthenticated.get(
            `/businesses/${businessId}/estimates/export`,
            {
                params: filterArgFlattener(args)
            }
        );

        return response.data;
    } catch (err) {
        return false;
    }
};
