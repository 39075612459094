import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@/components/rhf-mui';
import { InputAdornment, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { forcePositiveNumberInput } from '@/util';
import Divider from '../Divider';
import ItemLabel from '../ItemLabel';
import makeStyles from './styles';

const Percent = ({ heading, id }) => {
    const classes = makeStyles();

    return (
        <Grid item container direction="column">
            <ItemLabel>{heading}</ItemLabel>
            <Grid item container spacing={4} className={classes.wrap}>
                <Grid item xs={6}>
                    <TextField
                        name={`${id}[min]`}
                        label="Min"
                        fullWidth
                        type="number"
                        onKeyDown={forcePositiveNumberInput}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography>%</Typography>
                                </InputAdornment>
                            )
                        }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        inputProps={{
                            type: 'number',
                            step: '0.01',
                            min: '0'
                        }}
                    />
                </Grid>
                <Divider />
                <Grid item xs={6}>
                    <TextField
                        name={`${id}[max]`}
                        label="Max"
                        fullWidth
                        type="number"
                        onKeyDown={forcePositiveNumberInput}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography>%</Typography>
                                </InputAdornment>
                            )
                        }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        inputProps={{
                            type: 'number',
                            step: '0.01',
                            min: '0'
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

Percent.propTypes = {
    heading: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
};

export default Percent;
