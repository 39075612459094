import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    imageShadow: {
        width: '100%',
        maxWidth: theme.typography.pxToRem(200),
        boxShadow: '0px 5px 10px 3px #DEDEE7'
    },
    imageCaption: {
        fontStyle: 'italic',
        textAlign: 'center'
    }
}));
