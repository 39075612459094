export const getFrequencyWording = ({ interval, unit }) => {
    if (unit === 'day') {
        if (interval === 1) {
            return 'Daily';
        }

        // If interval is divisible by 7, then it's weekly.
        if (interval === 7) {
            return 'Weekly';
        }

        if (interval % 7 === 0) {
            return `${interval / 7} weeks`;
        }

        return `${interval} days`;
    }

    if (unit === 'month') {
        if (interval === 1) {
            return 'Monthly';
        }

        return `${interval} months`;
    }

    return null;
};
