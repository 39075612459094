const { paymentMethods, thirdPartyConnectionLimits } = require('@/config');

const stripeDigitalWalletCapabilities = [
    'affirm_payments',
    'afterpay_clearpay_payments'
];

const capabilityHash = {
    creditCard: 'card_payments',
    affirm: 'affirm_payments',
    afterpay: 'afterpay_clearpay_payments',
    'ach-direct-debit': 'us_bank_account_ach_payments'
};

/**
 * Function that takes in a payment channel name and connection data to check if the connection is Stripe
 * and, if so, verifies if the given payment channel is active within the Stripe capabilities object.
 * @param {string} paymentChannel The payment channel name to verify.
 * @param {object} connection The connection object for the merchant you are verifying.
 * @returns True if the connection is not Stripe or if the given payment channel is active within the
 * Stripe capabilities object. False if the connection is Stripe and the payment channel is anything but
 * "active" (i.e. "pending" or "inactive").
 */
export const isMerchantCapabilityActive = (paymentChannel, connection) => {
    if (!connection) {
        return false;
    }

    if (connection?.application !== 'stripe') {
        return true;
    }

    const { capabilities } = connection;

    if (paymentChannel === 'Credit Cards') {
        return capabilities?.card_payments === 'active';
    }

    if (paymentChannel === 'Digital Wallets') {
        // Only returns false if all three digital wallets are inactive
        return stripeDigitalWalletCapabilities.some(
            (capability) => capabilities?.[capability] === 'active'
        );
    }

    if (paymentChannel === 'Bank Transfers') {
        return capabilities?.us_bank_account_ach_payments === 'active';
    }

    // Catch-all for if an incorrect paymentChannel is passed
    return false;
};

/**
 * Similar to the function above, however this function takes in a specific payment method (such as 'creditCard', 'klarna', etc.)
 * and verifies that the given method is "active" within the Stripe capabilities object
 * @param {string} method The payment method to verify
 * @param {object} connection The connection object for the merchant you are verifying.
 * @returns True if either the connection is not Stripe or if the capability is "active". False if the
 * capability is not "active" (i.e. "inactive" or "pending").
 */
export const isPaymentMethodCapabilityActive = (method, connection) => {
    if (!connection) {
        return false;
    }

    if (connection?.application !== 'stripe') {
        return true;
    }

    const { capabilities } = connection;

    return capabilities?.[capabilityHash[method]] === 'active';
};

/**
 * This function takes a connectionId and returns an object containing the connection object and the paymentMethod object that matches the connection.
 * @param {string} connectionId The connectionId of the connection to find.
 * @param {object} connections The connections object with all available connections.
 * @returns {object} Data object with keys for the connection that was found and the paymentData coming from the paymentMethods config object.
 */
export const findConnectionData = ({ connectionId, connections }) => {
    const connectionMatch = connections?.find(
        (connection) => connection.connectionId === connectionId
    );

    if (connectionMatch === undefined) return null;

    return {
        connection: connectionMatch,
        // Loop through our config file - paymentMethod array.
        paymentData: paymentMethods.find((paymentMethod) =>
            // If the value of the application key in the connection object contains "zelle"
            // This is because zelle has three cases. 'zellePhone', 'zelleEmail', and 'zelleQR'.
            connectionMatch?.application?.includes('zelle')
                ? // Make sure paymentMethod mirrors the connection application value.
                  paymentMethod.slug === 'zelle'
                : connectionMatch?.application === paymentMethod.slug
        )
    };
};

/**
 * Function that verifies if an invoice balance is within the minimum and maximum bounds for a given merchant.
 * @param {string} slug The payment method slug for the merchant to verify.
 * @param {number} balanceDue The balance due on the invoice to be used for min/max verification.
 * @returns {bool} True if the invoice is within the merchant's minimum and maximum charge range, false if the invoice balance is too high or low for the given merchant to accept.
 */
export const verifyMinMax = ({ slug, balanceDue }) =>
    balanceDue >= thirdPartyConnectionLimits[slug]?.min &&
    balanceDue <= thirdPartyConnectionLimits[slug]?.max;

/**
 * Function that sorts and verifies payment options in regards to available connections and minimum/maximum charges.
 * @param {object} paymentProviders The payment providers object containing both connections and preferredPaymentConnections data.
 * @param {number} balanceDue The balance due on the invoice. To be used to enforce min/max charges for merchants.
 * @returns {object} Validated and sorted paymentOptions object containing relevant data for all possible payment options considering available connections and minimums and maximums.
 */
export const buildPaymentOptions = ({ paymentProviders, balanceDue }) => {
    const paymentOptions = {
        payNow: [],
        payOverTime: []
    };

    // If no connections are passed or if there is a zero balance due,
    // return empty payment options
    if (!paymentProviders || !balanceDue) {
        return paymentOptions;
    }

    const { connections, preferredPaymentConnections } = paymentProviders;

    const squareCashAppEnabled =
        findConnectionData({
            connectionId: preferredPaymentConnections?.digitalWallet,
            connections
        })?.connection?.application === 'square';

    // Iterates over the preferredPaymentConnections object and sorts each
    // connection into either "payNow" or "payLater".
    const prefPayments = Object.entries(preferredPaymentConnections);

    prefPayments?.forEach(
        ([preferredConnectionType, preferredConnectionValue]) => {
            // If there is no preferred connection for this type, do not add it.
            if (!preferredConnectionValue) return;

            if (preferredConnectionType === 'otherConnections') {
                preferredPaymentConnections?.otherConnections.forEach(
                    (connectionId) => {
                        const connectionData = findConnectionData({
                            connectionId,
                            connections
                        });

                        // If the connection is not found, don't show it.
                        if (!connectionData) return;

                        const showOption =
                            connectionData?.connection?.application ===
                            'cashapp'
                                ? // We want to show the native QR Cash App option if either:
                                  // -  Square Cash App connection is not active or...
                                  // -  The balance due is either below the minimum or above the maximum for square payments.
                                  !squareCashAppEnabled ||
                                  !verifyMinMax({
                                      slug: 'squarecashapp',
                                      balanceDue
                                  })
                                : // Otherwise we verify the balance due is within the min/max bounds as normal
                                  verifyMinMax({
                                      slug: connectionData?.paymentData?.slug,
                                      balanceDue
                                  });

                        if (showOption) {
                            paymentOptions.payNow.push({
                                connection: connectionData?.connection,
                                icon: connectionData?.paymentData?.icon,
                                name: connectionData?.paymentData?.name,
                                slug: connectionData?.paymentData?.slug
                            });
                        }
                    }
                );
                return;
            }

            const connectionData = findConnectionData({
                connectionId: preferredConnectionValue,
                connections
            });

            if (preferredConnectionType === 'creditCard') {
                const cardIcons = [];
                connectionData?.paymentData?.acceptedCards?.forEach((card) =>
                    cardIcons.push(card.icon)
                );

                // If the credit card connection is via Square and digital wallets are enabled via Square
                // We need to push the Google Pay and Apple Pay icons to the credit card option view
                // as they will be handled by the Square integration modal
                if (
                    connectionData?.connection?.slug === 'square' &&
                    preferredPaymentConnections?.digitalWallet ===
                        connectionData?.connection?.connectionId
                ) {
                    connectionData?.paymentData?.acceptedWallets?.forEach(
                        (wallet) => {
                            if (wallet.showInCreditCardOption) {
                                cardIcons.push(wallet?.icon);
                            }
                        }
                    );
                }

                const creditCardSlug = `creditCard-${connectionData?.connection.application}`;

                if (
                    verifyMinMax({ slug: creditCardSlug, balanceDue }) &&
                    isPaymentMethodCapabilityActive(
                        'creditCard',
                        connectionData?.connection
                    )
                ) {
                    // unshift to always show Credit Card option first
                    paymentOptions.payNow.unshift({
                        connection: connectionData?.connection,
                        icon: cardIcons,
                        name: creditCardSlug,
                        slug: creditCardSlug
                    });
                }

                return;
            }

            const acceptedKey =
                preferredConnectionType === 'digitalWallet'
                    ? 'acceptedWallets'
                    : 'acceptedBanks';

            connectionData?.paymentData?.[acceptedKey]?.forEach(
                (paymentOption) => {
                    const newPaymentOption = {
                        connection: connectionData?.connection,
                        icon: paymentOption.smallIcon || paymentOption.icon,
                        name: paymentOption.name,
                        slug: paymentOption.slug || paymentOption,
                        tagLine: paymentOption.tagLine
                    };

                    // If the Square "Cash App" connection is active, that will take precidence
                    // over this connection. We only want to render one "Cash App" option.
                    // This avoids rendering both the separated "Cash App" option and the Square digital wallet "Cash App" option at the same time.
                    if (
                        paymentOption.slug === 'cashapp' &&
                        !squareCashAppEnabled
                    ) {
                        return;
                    }

                    if (paymentOption.payLater) {
                        if (
                            verifyMinMax({
                                slug: paymentOption.slug,
                                balanceDue
                            }) &&
                            isPaymentMethodCapabilityActive(
                                paymentOption.slug,
                                connectionData?.connection
                            )
                        ) {
                            paymentOptions.payOverTime.push(newPaymentOption);
                        }
                        return;
                    }

                    // If we've progressed beyond the "Pay Later" logic, then we know this is a "Pay Now" option and should be handled.
                    if (
                        // If the slug is cashapp, we need to make sure to verify the Square (aka digital wallet) connection,
                        // not the native QR connection
                        verifyMinMax({
                            slug:
                                paymentOption?.slug === 'cashapp'
                                    ? 'squarecashapp'
                                    : paymentOption?.slug,
                            balanceDue
                        }) &&
                        isPaymentMethodCapabilityActive(
                            // We don't need the same cashapp check for this, as this function only tests Stripe connections
                            paymentOption.slug,
                            connectionData?.connection
                        )
                    ) {
                        paymentOptions.payNow.push(newPaymentOption);
                    }
                }
            );
        }
    );

    return paymentOptions;
};
