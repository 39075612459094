import { Purchases } from '@awesome-cordova-plugins/purchases';
import store from '@/state/store';
import { IAP_PRODUCTS } from '@/config';
import { getUserSubscription } from '../subscriptionManager';

export const getRcProducts = async () => {
    try {
        const rcProducts = await Purchases.getProducts(IAP_PRODUCTS);
        return rcProducts;
    } catch (err) {
        return err;
    }
};

export const initIapStore = async () => {
    Purchases.setDebugLogsEnabled(true);
    Purchases.configureWith({ apiKey: process.env.REACT_APP_RC_KEY });
};

export const rcLoginUser = async () => {
    const { businessId } = store.getState().user?.activeBusiness;
    const userData = store.getState()?.user;

    try {
        await Purchases.logIn(businessId);
        Purchases.setEmail(userData?.email);

        return true;
    } catch (err) {
        return false;
    }
};

export const getRcCustomerInfo = async () => {
    await Purchases.getCustomerInfo((res) => res);
};

export const iapPurchaseProduct = async (rcSelectedPlan) => {
    const { businessId } = store.getState().user?.activeBusiness;
    const rcPurchaserInfo = await Purchases.getCustomerInfo();

    if (Object.keys(rcPurchaserInfo?.entitlements?.active || {})?.length > 0) {
        await getUserSubscription(businessId);
        return { ...rcPurchaserInfo, hasPurchase: true };
    }

    const purchaseRes = await Purchases.purchaseProduct(
        rcSelectedPlan.identifier
    );

    return { ...purchaseRes, newPurchase: true };
};

export const migrateSubscription = async () => {
    const { businessId } = store.getState().user?.activeBusiness;

    try {
        await Purchases.restorePurchases();
        const userData = await getUserSubscription(businessId);

        return userData?.data;
    } catch (error) {
        return error;
    }
};
