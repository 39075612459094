import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GenericDialog } from '@/components/common';
import { resetGlobalError } from '@/actions';

const RootErrorDialog = () => {
    const dispatch = useDispatch();

    const error = useSelector((state) => state?.errors);

    return (
        <GenericDialog
            confirmText="ok"
            message={
                error?.message ||
                'It looks like something went wrong. This has been reported to our development team.'
            }
            open={error?.active || false}
            onConfirm={() => dispatch(resetGlobalError())}
            title={error?.title || 'Oops!'}
            icon={error?.icon || false}
        />
    );
};

export default RootErrorDialog;
