import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField as MuiTextField } from '@mui/material';
import { forcePositiveNumberInput, currencySymbolAdornment } from '@/util';

const CurrencyValueInput = ({
    currency,
    label,
    id,
    name,
    required,
    ...rest
}) => {
    const { position, symbol } = currency;
    const {
        control,
        formState: { errors }
    } = useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            rules={{
                required: required ? 'Please enter a valid number' : undefined,
                pattern: {
                    value: /^-?\d*\.?\d*$/,
                    message: 'Please enter a valid number'
                }
            }}
            render={({ field }) => (
                <MuiTextField
                    {...field}
                    {...rest}
                    id={id || name}
                    helperText={errors?.[name]?.message}
                    error={Boolean(errors?.[name]?.message)}
                    label={label}
                    fullWidth
                    inputProps={{
                        step: '0.01',
                        min: '0'
                    }}
                    type="number"
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={currencySymbolAdornment({ position, symbol })}
                    onKeyDown={forcePositiveNumberInput}
                />
            )}
        />
    );
};

CurrencyValueInput.propTypes = {
    currency: PropTypes.object,
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool
};

CurrencyValueInput.defaultProps = {
    currency: { position: 'before', symbol: '$' },
    required: false
};

export default CurrencyValueInput;
