import React from 'react';
import { SvgIcon, Typography } from '@mui/material';
import { PlusSubIcon } from '@/resources/icons';
import useStyles from './styles';

const PlusStamp = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <SvgIcon component={PlusSubIcon} className={classes.plusSvg} />
            <Typography className={classes.plusText}>Plus</Typography>
        </div>
    );
};

export default PlusStamp;
