import loadable from '@loadable/component';
import React from 'react';
import { useLocation } from 'react-router-dom-v5-compat';
import { useSubscription } from '@/hooks';

const SubscriptionBannerRender = loadable(() =>
    import('./SubscriptionBannerRender')
);

const SubscriptionBanner = () => {
    const location = useLocation();
    const { components, productId, loading } = useSubscription();

    const { amountStartedWith, amountRemaining, limited } =
        components?.document || {};

    if (
        loading ||
        productId === 'visitor' ||
        location.pathname === '/settings/subscription'
    )
        return null;

    return (
        <SubscriptionBannerRender
            amountStartedWith={amountStartedWith}
            amountRemaining={amountRemaining}
            limited={limited}
        />
    );
};

export default SubscriptionBanner;
