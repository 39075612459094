import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const PRESETTOASTTIMEOUT = 3000;

const ToastContext = createContext([{}, () => {}]);

const ToastProvider = ({ children }) => {
    const [state, setState] = useState({
        toastActive: false,
        creationTime: null,
        toastAction: null,
        toastIcon: null,
        toastKey: null,
        toastTimeout: PRESETTOASTTIMEOUT
    });

    return (
        <ToastContext.Provider value={[state, setState]}>
            {children}
        </ToastContext.Provider>
    );
};

/**
 * Toast related hooks and state.
 * @return {object} Mix of values and functions.
 */
const useToastNotification = () => {
    const [state, setState] = useContext(ToastContext);

    const triggerToast = ({ action, message, icon, customTimer }) => {
        // If no custom timer, fallback to 3 seconds.
        const toastTimeout = customTimer || PRESETTOASTTIMEOUT;

        if (state.toastActive) {
            setState((prevState) => ({
                ...prevState,
                toastActive: false
            }));
        }

        setState((prevState) => ({
            ...prevState,
            toastKey: new Date().getTime(),
            toastAction: action,
            toastActive: true,
            toastIcon: icon,
            toastMessage: message,
            toastTimeout
        }));
    };

    const closeToast = () => {
        setState((prevState) => ({
            ...prevState,
            toastActive: false
        }));
    };

    return {
        closeToast,
        triggerToast,
        toastAction: state.toastAction,
        toastActive: state.toastActive,
        toastIcon: state.toastIcon,
        toastMessage: state.toastMessage,
        toastKey: state.toastKey,
        toastTimeout: state.toastTimeout
    };
};

ToastProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export { ToastProvider, useToastNotification };
