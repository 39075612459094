import {
    UPDATE_SUBSCRIPTION_PRODUCTS,
    SET_HAS_CLICKED_SUBSCRIBE
} from '@/constants';

export default (state, action) => {
    switch (action.type) {
        case UPDATE_SUBSCRIPTION_PRODUCTS: {
            return {
                ...state,
                products: action.products
            };
        }
        case SET_HAS_CLICKED_SUBSCRIBE: {
            return {
                ...state,
                hasClickedSubscribe: action.hasClickedSubscribe
            };
        }
        default:
            return { ...state };
    }
};
