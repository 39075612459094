/* eslint import/no-cycle: "off"  */
import store from '@/state/store';

import { globalPageCount } from '@/config';

import { convertTemporaryUserToUnregisteredUser } from '@/modules/setup';
import {
    getBusinessDiscounts,
    getTaxes,
    overwriteClients,
    overwriteProducts
} from '@/modules/dataWrangler/dataWrangler';
import {
    createTag as createTagAction,
    deleteTag as deleteTagAction,
    fetchListingItems,
    noListingItems,
    overwriteListingItems,
    overwriteTags,
    updateTag as updateTagAction
} from '@/state/actions';
import { filterArgFlattener } from '@/util';
import axiosAuthenticated from '../authenticatedRequestor';

export const createTag = async (tag) => {
    if (store.getState().user.temporaryUser) {
        await convertTemporaryUserToUnregisteredUser();
    }

    const { businessId } = store.getState().user.activeBusiness;

    const response = await axiosAuthenticated.post(
        `/businesses/${businessId}/tags`,
        tag
    );

    store.dispatch(createTagAction(response.data));
    return response.data;
};

export const getTags = async () => {
    if (store.getState().user.temporaryUser) {
        store.dispatch(overwriteTags([]));
        return [];
    }

    const { businessId } = store.getState().user.activeBusiness;

    const items = [];

    let morePages = true;
    let pageCount = 1;

    while (morePages) {
        // This returns 100 items as default.
        // eslint-disable-next-line no-await-in-loop
        const response = await axiosAuthenticated.get(
            `/businesses/${businessId}/tags`,
            { params: { pageNumber: pageCount } }
        );

        // Add items to main array. Fallback to empty array if API failed/for some
        // reason returns nothing.
        items.push(...(response?.data?.items || []));

        pageCount += 1;

        // There are more pages if totalPages is larger than current page count.
        // Minus one page so it will fail if the same or smaller correctly.
        morePages =
            response.data.totalItems / response.data.pageSize > pageCount - 1;
    }

    store.dispatch(overwriteTags(items));
    return items;
};

export const getTagById = async (tagId) => {
    const { businessId } = store.getState().user.activeBusiness;

    const response = await axiosAuthenticated.get(
        `/businesses/${businessId}/tags/${tagId}`
    );

    return response.data;
};

export const deleteTag = async (tagId) => {
    const { businessId } = store.getState().user.activeBusiness;

    await axiosAuthenticated.delete(`/businesses/${businessId}/tags/${tagId}`);

    store.dispatch(deleteTagAction(tagId));
};

export const updateTag = async (tag) => {
    const { businessId } = store.getState().user.activeBusiness;

    const response = await axiosAuthenticated.put(
        `/businesses/${businessId}/tags/${tag?.tagId}`,
        tag
    );

    const usage = Object.entries(response.data.usageByCategory);

    usage.forEach(async (item) => {
        // Check if any items are using this tag
        if (item[1] > 0) {
            // Update any items that have this tag applied to them
            switch (item[0]) {
                case 'tax':
                    await getTaxes();
                    break;
                case 'discount':
                    await getBusinessDiscounts();
                    break;
                case 'product':
                    await overwriteProducts();
                    break;
                case 'service':
                    await overwriteProducts();
                    break;
                case 'client':
                    await overwriteClients();
                    break;
                default:
                    break;
            }
        }
    });

    store.dispatch(updateTagAction(response.data));
};

export const getPaginatedTags = async (args) => {
    if (store.getState().user.temporaryUser) {
        store.dispatch(noListingItems({ type: 'tags' }));
        return;
    }

    store.dispatch(fetchListingItems({ type: 'tags' }));

    const { businessId } = store.getState().user.activeBusiness;

    const allArgs = { pageSize: globalPageCount, ...filterArgFlattener(args) };

    const response = await axiosAuthenticated.get(
        `/businesses/${businessId}/tags`,
        {
            params: {
                sortOrder: 'desc',
                sortType: 'createdAt',
                ...allArgs
            }
        }
    );

    const { items, itemsMatchingRequest, pageSize, totalItems } = response.data;

    const totalPages = Math.ceil(itemsMatchingRequest / pageSize);

    store.dispatch(
        overwriteListingItems({ type: 'tags', items, totalPages, totalItems })
    );
};
