import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';
import { useCheckAuthState, useDocumentFormData } from '@/hooks';
import { SendIcon } from '@/resources/icons';
import { ButtonLoading } from '@/components/common';
import {
    useSaveRecurringInvoice,
    useValidateRecurringInvoice
} from '@/hooks/useSidebarActions';
import useStyles from '../../styles';

const handleOnClick = async ({
    invoice,
    saveRecurringInvoice,
    onLoginBlocker,
    validateRecurringInvoice,
    onNegativeInvoice,
    registeredUser,
    setLoading,
    total,
    navigate
}) => {
    const invoiceData = await validateRecurringInvoice(invoice);

    if (!invoiceData) return;

    if (total < 0) {
        onNegativeInvoice();
        return;
    }

    const completePreviewProcess = async () => {
        setLoading(true);

        let savedData;
        if (invoiceData?.documentState === 'draft' || invoiceData?.state) {
            savedData = await saveRecurringInvoice(invoiceData);

            if (savedData === false) {
                setLoading(false);
                return;
            }
        } else {
            savedData = { ...invoiceData };
        }

        setLoading(false);

        if (savedData?.documentId !== null) {
            navigate(`/preview-recurring/${savedData?.documentId}`);
        }
    };

    // Check is a user is registered, if they are complete the process.
    // If not, see if we can get them to register.
    if (registeredUser) {
        completePreviewProcess();
    } else {
        onLoginBlocker(completePreviewProcess);
    }
};

const RecurringInvoicePreviewAndActivate = ({
    onNegativeInvoice,
    onLoginBlocker,
    setLoading,
    loading,
    isDesktop
}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { documentLoading, total } = useDocumentFormData();

    const { loadingUser, registeredUser } = useCheckAuthState();
    const saveRecurringInvoice = useSaveRecurringInvoice();
    const validateRecurringInvoice = useValidateRecurringInvoice();

    // Used for validation.
    const invoice = useSelector((state) => state?.makeInvoice);

    const documentState = useSelector(
        (state) => state?.makeInvoice?.documentState
    );

    // Recurring uses different state. Usually it is cleaned up and turned into
    // documentState, but this is not the case if a user does not have a plan.
    // This is needed for the temporary storage flow.
    const recurringState = useSelector((state) => state?.makeInvoice?.state);

    const isDraft = documentState === 'draft' || recurringState === 'draft';

    const loadingButton = loadingUser || documentLoading;

    if (isDraft) {
        return (
            <div className={classes.section}>
                {!loadingButton ? (
                    <ButtonLoading
                        fullWidth
                        className={classes.primaryButton}
                        variant="contained"
                        color="primary"
                        endIcon={<SendIcon className={classes.buttonSendSvg} />}
                        onClick={() =>
                            handleOnClick({
                                invoice,
                                saveRecurringInvoice,
                                onLoginBlocker,
                                validateRecurringInvoice,
                                onNegativeInvoice,
                                registeredUser,
                                setLoading,
                                total,
                                navigate
                            })
                        }
                        size="large"
                        disableOnLoading
                        spinnerColor="primary"
                        loading={loading}
                        centerSpinner={!isDesktop}
                    >
                        Preview & Activate
                    </ButtonLoading>
                ) : (
                    <Skeleton variant="rectangular" className={classes.sizer} />
                )}
            </div>
        );
    }

    return null;
};

RecurringInvoicePreviewAndActivate.propTypes = {
    onNegativeInvoice: PropTypes.func.isRequired,
    onLoginBlocker: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    isDesktop: PropTypes.bool.isRequired
};

RecurringInvoicePreviewAndActivate.defaultProps = {
    loading: false
};

export default RecurringInvoicePreviewAndActivate;
