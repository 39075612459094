import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    root: {
        'z-index': `${1301}!important`
    },
    icon: {
        display: 'flex',
        justifyContent: 'center'
    },
    wrap: ({ iconTop }) => ({
        padding: iconTop ? theme.spacing(0, 3, 3, 3) : theme.spacing(3),
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            padding: iconTop ? theme.spacing(0, 6, 6, 6) : theme.spacing(6)
        },
        [theme.breakpoints.up('md')]: {
            minWidth: theme.typography.pxToRem(600)
        }
    }),
    buttons: {
        justifyContent: 'center',
        padding: 0
    },
    button: {
        width: theme.typography.pxToRem(320),
        margin: `0 ${theme.spacing(1)}`
    },
    contentWrap: {
        textAlign: 'center',
        padding: `${theme.spacing(3)} 0`,
        maxWidth: theme.typography.pxToRem(365),
        flex: '0 0 auto',
        margin: `${theme.typography.pxToRem(10)} auto 0 auto`
    },
    title: {
        fontSize: theme.typography.pxToRem(26),
        fontWeight: 600,
        color: '#35373D',
        margin: 0
    },
    messageWrap: {
        marginTop: theme.typography.pxToRem(10)
    },
    message: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: 600,
        color: '#656B7D',
        margin: 0
    }
}));
