import { alpha, createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

import createBreakpoints from '@mui/system/createTheme/createBreakpoints';

const breakpoints = createBreakpoints({
    values: {
        xs: 0,
        sm: 768,
        md: 1024,
        lg: 1280,
        xl: 1600
    }
});

// Create a theme instance, to be reference for the grey variant.
// https://mui.com/material-ui/customization/theming/#api
// See Composition for more details.
const theme = createTheme({
    palette: {
        primary: {
            main: '#357ABC'
        },
        secondary: {
            main: '#FFFFFF',
            contrastText: '#35373D'
        },
        background: {
            paper: '#FFF',
            default: '#F5F5FA'
        },
        neutral: {
            main: '#e0e0e0',
            contrastText: 'rgba(0, 0, 0, 0.87)'
        },
        common: {
            black: '#35373D',
            lightGray: '#F5F5FA',
            detailGray: '#8C92A4'
        },
        text: {
            primary: '#35373D',
            secondary: '#656B7D',
            disabled: '#656B7D',
            red: '#C20101',
            orange: '#D07C33'
        },
        error: {
            main: '#B10000',
            contrastText: '#FFCBCB',
            background: '#fef3f3'
        },
        imBrand: {
            purple: '#01004C',
            linkBlue: '#3F8FDF',
            linkBlueHover: '#295F95',
            lightBlue: '#E9F4FF',
            darkBlue: '#1E377A',
            midBlue: '#4287CA',
            midBlueHover: '#306EA9'
        },
        actionColors: {
            archive: '#AE2828',
            download: '#BC35A1',
            draft: '#656B7D',
            duplicate: '#3538BC',
            edit: '#7D35BC',
            negative: '#C20101',
            preview: '#2C989C',
            positive: '#00834E',
            send: '#357ABC',
            unapproved: '#D07C33',
            void: '#6D00F9',
            tags: '#E0E5EE'
        },
        sidebarSvg: {
            active: '#fff',
            inactive: '#85949F'
        },
        items: {
            tax: '#AA6600',
            discount: '#127F83'
        },
        grey: {
            main: grey[300],
            dark: grey[400]
        }
    },
    typography: {
        fontFamily: "'Gilroy', 'Roboto', 'Helvetica', 'Arial', sans-serif"
    }
});

export default createTheme(theme, {
    breakpoints,
    typography: {
        h1: {
            fontWeight: 600,
            fontSize: '1.625rem', // 26px
            letterSpacing: -0.32
        },
        h2: {
            fontWeight: 600,
            fontSize: '1.4375rem', // 23px
            lineHeight: '1.625rem'
        },
        h3: {
            fontSize: '1.25rem', // 20px
            fontWeight: 600
        },
        h4: {
            fontSize: '1rem', // 16px
            fontWeight: 600
        },
        h5: {
            fontWeight: 600,
            fontSize: '0.8125rem', // 13px
            letterSpacing: -0.16
        },
        body1: {
            fontSize: '1.125rem', // 18px
            fontWeight: 600
        },
        body2: {
            fontSize: '.875rem', // 14px
            fontWeight: 500
        },
        button: {
            fontWeight: 600,
            fontSize: '1rem',
            letterSpacing: -0.25,
            textTransform: 'capitalize'
        },
        switchLabels: {
            fontWeight: 600,
            fontSize: '.75rem', // 12px
            letterSpacing: 0.5,
            textTransform: 'uppercase'
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontSize: '0.875rem',
                    lineHeight: 1.43,
                    letterSpacing: '0.01071em'
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                message: {
                    width: '100%'
                }
            }
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true
            },
            styleOverrides: {
                label: {
                    textTransform: 'none',
                    transition: 'color .01s'
                }
            },
            variants: [
                {
                    props: { variant: 'contained', color: 'grey' },
                    style: {
                        color: theme.palette.getContrastText(
                            theme.palette.grey[300]
                        )
                    }
                },
                {
                    props: { variant: 'outlined', color: 'grey' },
                    style: {
                        color: theme.palette.text.primary,
                        borderColor:
                            theme.palette.mode === 'light'
                                ? 'rgba(0, 0, 0, 0.23)'
                                : 'rgba(255, 255, 255, 0.23)',
                        '&.Mui-disabled': {
                            border: `1px solid ${theme.palette.action.disabledBackground}`
                        },
                        '&:hover': {
                            borderColor:
                                theme.palette.mode === 'light'
                                    ? 'rgba(0, 0, 0, 0.23)'
                                    : 'rgba(255, 255, 255, 0.23)',
                            backgroundColor: alpha(
                                theme.palette.text.primary,
                                theme.palette.action.hoverOpacity
                            )
                        }
                    }
                },
                {
                    props: { color: 'grey', variant: 'text' },
                    style: {
                        color: theme.palette.text.primary,
                        '&:hover': {
                            backgroundColor: alpha(
                                theme.palette.text.primary,
                                theme.palette.action.hoverOpacity
                            )
                        }
                    }
                },
                {
                    props: { color: 'midBlue', variant: 'secondary' },
                    style: {
                        color: '#4287CA',
                        backgroundColor: '#E1ECF7',
                        '&:hover': {
                            color: '#306EA9',
                            backgroundColor: '#B9D3EB'
                        },
                        '&:pressed': {
                            backgroundColor: '#B9D3EB'
                        }
                    }
                }
            ]
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    '&.Mui-expanded': {
                        margin: 0
                    }
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                label: {
                    textTransform: 'none',
                    transition: 'color .01s'
                }
            },
            variants: [
                {
                    props: { color: 'midBlue', variant: 'secondary' },
                    style: {
                        color: '#4287CA',
                        backgroundColor: '#E1ECF7',
                        '&:hover': {
                            color: '#306EA9',
                            backgroundColor: '#B9D3EB'
                        },
                        '&:pressed': {
                            backgroundColor: '#B9D3EB'
                        }
                    }
                }
            ]
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderRadius: 4
                },
                multiline: {
                    padding: '27px 12px 10px !important'
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: 'filled'
            }
        },
        MuiFilledInput: {
            defaultProps: {
                disableUnderline: true
            },
            styleOverrides: {
                root: {
                    backgroundColor: '#F5F5FA',
                    '&$focused': {
                        backgroundColor: '#DFDFE7'
                    },
                    '&$disabled': {
                        opacity: 0.5
                    },
                    '&:hover': {
                        backgroundColor: '#EAEAF2'
                    },
                    '&.Mui-error': {
                        borderBottom: '2px solid #C20101'
                    }
                },
                input: {
                    '&[type=number]': {
                        mozAppearance: 'textfield !important'
                    },
                    '&::-webkit-outer-spin-button': {
                        webkitAppearance: 'none !important'
                    },
                    '&::-webkit-inner-spin-button': {
                        webkitAppearance: 'none !important'
                    }
                }
            }
        },
        MuiCheckbox: {
            defaultProps: {
                color: 'primary'
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: 16,
                    fontWeight: 'normal'
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                }
            }
        },
        MuiTableSortLabel: {
            styleOverrides: {
                icon: {
                    opacity: 1
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#1B1E26',
                    fontSize: '12px'
                },
                arrow: {
                    color: '#1B1E26'
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    color: '#35373D',
                    fontWeight: 600,
                    textTransform: 'uppercase',
                    letterSpacing: '0.5px',
                    padding: '10px 20px'
                },
                body: {
                    padding: '12px 20px',
                    color: '#71747D',
                    fontWeight: 600,
                    borderBottom: '1px solid #E3E5E9'
                }
            }
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    padding: '20px 30px'
                }
            }
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    color: '#1D487D'
                },
                page: {
                    borderRadius: '2px',
                    backgroundColor: '#F5F5FA',
                    fontSize: '16px',
                    '&:hover': {
                        backgroundColor: '#D2E6FF'
                    },
                    '&:focus': {
                        backgroundColor: '#D2E6FF'
                    },
                    '&.Mui-selected': {
                        backgroundColor: '#D2E6FF'
                    },
                    '&.Mui-disabled': {
                        opacity: '0'
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                    fontWeight: 600
                }
            }
        },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    '&$active': {
                        fontWeight: 600
                    }
                }
            }
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    '&$active': {
                        color: '#357ABC'
                    },
                    color: '#8C92A4'
                }
            }
        },
        MuiStepConnector: {
            styleOverrides: {
                line: {
                    '&$lineVertical': {
                        minHeight: 47
                    }
                }
            }
        },
        MuiSnackbar: {
            styleOverrides: {
                anchorOriginTopRight: {
                    [breakpoints.up('md')]: {
                        top: 71,
                        right: 71
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: { minWidth: 'unset' }
            }
        },
        PrivateTabIndicator: {
            styleOverrides: {
                root: {
                    borderLeft: `35px solid #F5F5FA`,
                    borderRight: `35px solid #F5F5FA`,
                    height: 3
                },
                colorPrimary: {
                    backgroundColor: '#01004C'
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    marginRight: '80px',
                    marginLeft: '10px',
                    '&:last-child': {
                        marginRight: 0
                    }
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paperScrollPaper: {
                    maxHeight: `calc(100% - 24px)`
                }
            }
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    titleBarHeight: 58
});
