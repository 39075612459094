import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { toIntegerCurrency, toWholeCurrency } from '@/util';
import FormattedNumberField from '@/components/common/FormattedNumberField';

const PriceField = ({ item, applyFieldToItem }) => {
    const format = (amount) => numeral(amount).format('0,0.00');

    return (
        <FormattedNumberField
            item={item}
            applyFieldToItem={(val) => {
                applyFieldToItem('price', toIntegerCurrency(val || 0));
            }}
            label="Price"
            maxLength={15}
            format={format}
            value={toWholeCurrency(item?.price)}
            placeHolder="1.00"
        />
    );
};

PriceField.propTypes = {
    item: PropTypes.object.isRequired,
    applyFieldToItem: PropTypes.func.isRequired
};

export default PriceField;
