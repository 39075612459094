import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    filterButton: {
        backgroundColor: '#fff',
        border: '1px solid #EDEEF0',
        color: theme.palette.common.black,
        '&:hover': {
            backgroundColor: 'rgba(0,0,0, .08)',
            color: theme.palette.common.black,
            border: '1px solid #EDEEF0'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.typography.pxToRem(131)
        }
    },
    filterIcon: {
        width: 24,
        height: 24
    },
    paramCount: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 8,
        fontSize: 12,
        height: 23,
        width: 23,
        minWidth: 23,
        borderRadius: 12,
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.main
    }
}));
