import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    planIcon: {
        minHeight: theme.typography.pxToRem(28),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    lightWeightText: {
        fontWeight: 500
    },
    effectiveDateText: {
        fontWeight: 500,
        marginRight: theme.spacing(1)
    }
}));
