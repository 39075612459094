import React from 'react';
import PropTypes from 'prop-types';
import { Button, Skeleton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '@/state/actions';
import { useCheckAuthState } from '@/hooks';
import { AddPaymentIcon } from '@/resources/icons';

import useStyles from '../../styles';

const InvoiceAddPayment = ({ onLoginBlocker }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { loadingUser, registeredUser } = useCheckAuthState();
    const invoice = useSelector((state) => state?.makeInvoice);

    const markedPaidByClientMethod = useSelector(
        (state) => state?.makeInvoice?.markedPaidByClientMethod
    );

    const editing = invoice?.viewMode === 'edit';
    const addPaymentsAvailable = invoice?.documentState !== 'paid';
    const isVoid = invoice?.documentState === 'void';

    const handleOnClick = async () => {
        if (registeredUser) {
            dispatch(setModal({ slug: 'process-invoice-payment-options' }));
        } else {
            onLoginBlocker(() =>
                dispatch(setModal({ slug: 'process-invoice-payment-options' }))
            );
        }
    };

    if (
        editing &&
        !isVoid &&
        addPaymentsAvailable &&
        !markedPaidByClientMethod
    ) {
        return (
            <div className={classes.section}>
                {!loadingUser ? (
                    <Button
                        fullWidth
                        className={classes.button}
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={handleOnClick}
                        endIcon={
                            <AddPaymentIcon
                                className={classes.buttonAddPaymentSvg}
                            />
                        }
                    >
                        Add Payment
                    </Button>
                ) : (
                    <Skeleton variant="rectangular" className={classes.sizer} />
                )}
            </div>
        );
    }

    return null;
};

InvoiceAddPayment.propTypes = {
    onLoginBlocker: PropTypes.func.isRequired
};

export default InvoiceAddPayment;
