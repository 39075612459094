import React from 'react';
import PropTypes from 'prop-types';
import { Button, Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import { useCheckAuthState, useDocumentFormData } from '@/hooks';
import { VoidIcon } from '@/resources/icons';

import useStyles from '../../styles';

const handleOnClick = async ({
    balancePaid,
    onWarningActive,
    onWarningPaidActive,
    onLoginBlocker,
    registeredUser
}) => {
    const completeVoidProcess = () => {
        if (balancePaid === 0) {
            onWarningActive();
        } else {
            onWarningPaidActive();
        }
    };

    if (registeredUser) {
        completeVoidProcess();
    } else {
        onLoginBlocker(completeVoidProcess);
    }
};

export const InvoiceVoid = ({
    onLoginBlocker,
    onWarningActive,
    onWarningPaidActive
}) => {
    const classes = useStyles();
    const { loadingUser, registeredUser } = useCheckAuthState();
    const { documentLoading } = useDocumentFormData();

    const documentState = useSelector(
        (state) => state?.makeInvoice?.documentState
    );

    const balancePaid = useSelector((state) => state?.makeInvoice?.balancePaid);

    const isDraft = documentState === 'draft';
    const isVoid = documentState === 'void';

    const loadingButton = loadingUser || documentLoading;

    if (!isDraft && !isVoid) {
        return (
            <div className={classes.section}>
                {!loadingButton ? (
                    <Button
                        fullWidth
                        className={`${classes.button} ${classes.negativeButton}`}
                        variant="outlined"
                        endIcon={<VoidIcon className={classes.buttonVoidSvg} />}
                        size="large"
                        onClick={() =>
                            handleOnClick({
                                balancePaid,
                                onWarningActive,
                                onWarningPaidActive,
                                onLoginBlocker,
                                registeredUser
                            })
                        }
                    >
                        Void
                    </Button>
                ) : (
                    <Skeleton variant="rectangular" className={classes.sizer} />
                )}
            </div>
        );
    }

    return null;
};

InvoiceVoid.propTypes = {
    onLoginBlocker: PropTypes.func.isRequired,
    onWarningActive: PropTypes.func.isRequired,
    onWarningPaidActive: PropTypes.func.isRequired
};

export default InvoiceVoid;
