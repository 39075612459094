import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const RadioIcon = ({ checked, children }) => {
    const classes = useStyles(checked);
    return <span className={classes.radio}>{children}</span>;
};

RadioIcon.defaultProps = {
    checked: false
};

RadioIcon.propTypes = {
    checked: PropTypes.bool,
    children: PropTypes.string.isRequired
};

export default RadioIcon;
