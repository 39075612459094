import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    planSummaryContainer: {
        margin: 0,
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(2)
        }
    },
    planSummaryTitleIcon: {
        marginRight: theme.spacing(1)
    },
    skeletonLoader: {
        marginTop: -theme.spacing(10),
        height: theme.typography.pxToRem(400)
    },
    manageSubscriptionButtonContainer: {
        maxWidth: 330 + theme.spacing(1),
        [theme.breakpoints.down(475)]: {
            textAlign: 'center',
            maxWidth: '100%'
        }
    },
    questionsContainer: {
        marginTop: theme.spacing(2.5)
    }
}));
