import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    stepper: {
        padding: `${theme.typography.pxToRem(24)} 0`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'inherit',
        margin: `0 -10% ${theme.spacing(3)} -10%`
    },
    icon: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: theme.palette.common.white,
        width: 34,
        height: 34,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    iconActive: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(18)
    },
    iconCompleted: {
        backgroundColor: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(18)
    },
    stepConnector: {
        top: 18,
        padding: `0 ${theme.spacing(3)}`
    },
    activeLabel: {
        borderBottom: `3px solid ${theme.palette.primary.main}`,
        paddingBottom: theme.spacing(2),
        width: theme.typography.pxToRem(90),
        position: 'absolute',
        left: '50%',
        transform: `translate(-50%)`,
        color: theme.palette.primary.main
    },
    inactiveLabel: {
        color: theme.palette.common.black,
        opacity: 0.2
    }
}));
