import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    commonTooltipContent: {
        backgroundColor: '#35373D',
        padding: theme.spacing(2)
    },
    tooltipArrow: {
        color: '#35373D'
    },
    tooltipIcon: {
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center',
        maxWidth: 13,
        visibility: (showTooltip) => (showTooltip ? 'visible' : 'hidden')
    }
}));
