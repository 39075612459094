import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useSelector } from 'react-redux';
import { Skeleton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useCheckAuthState, useDocumentFormData } from '@/hooks';
import {
    useSaveEstimate,
    useValidateEstimate
} from '@/hooks/useSidebarActions';
import { ButtonLoading } from '@/components/common';
import { SendIcon } from '@/resources/icons';
import useStyles from '../../styles';

const handleOnClick = async ({
    estimate,
    saveEstimate,
    onLoginBlocker,
    validateEstimate,
    onNegativeEstimate,
    registeredUser,
    setLoading,
    total,
    navigate
}) => {
    const estimateData = await validateEstimate(estimate);

    if (!estimateData) return;

    if (total < 0) {
        onNegativeEstimate();
        return;
    }

    const completePreviewProcess = async () => {
        setLoading(true);

        let savedData;
        if (estimateData?.documentState === 'draft') {
            savedData = await saveEstimate(estimateData, 'preview');
            if (savedData === false) {
                setLoading(false);
                return;
            }
        } else {
            savedData = { ...estimateData };
        }

        setLoading(false);

        if (savedData?.documentId !== null) {
            navigate(`/preview/${savedData?.documentId}`);
        }
    };

    // Check is a user is registered, if they are complete the process.
    // If not, see if we can get them to register.
    if (registeredUser) {
        completePreviewProcess();
    } else {
        onLoginBlocker(completePreviewProcess);
    }
};

const EstimatePreviewAndSend = ({
    onNegativeEstimate,
    onLoginBlocker,
    setLoading,
    loading
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const { documentLoading, total } = useDocumentFormData();
    const { loadingUser, registeredUser } = useCheckAuthState();
    const saveEstimate = useSaveEstimate();
    const validateEstimate = useValidateEstimate();

    // Used for validation.
    const estimate = useSelector((state) => state?.makeInvoice);

    const loadingButton = loadingUser || documentLoading;

    return (
        <div className={classes.section}>
            {!loadingButton ? (
                <ButtonLoading
                    fullWidth
                    className={classes.primaryButton}
                    variant="contained"
                    color="primary"
                    endIcon={<SendIcon className={classes.buttonSendSvg} />}
                    size="large"
                    onClick={() =>
                        handleOnClick({
                            estimate,
                            saveEstimate,
                            onLoginBlocker,
                            validateEstimate,
                            onNegativeEstimate,
                            registeredUser,
                            setLoading,
                            total,
                            navigate
                        })
                    }
                    disableOnLoading
                    spinnerColor="primary"
                    loading={loading}
                    centerSpinner={isMobile}
                >
                    Preview & Send
                </ButtonLoading>
            ) : (
                <Skeleton variant="rectangular" className={classes.sizer} />
            )}
        </div>
    );
};

EstimatePreviewAndSend.propTypes = {
    onNegativeEstimate: PropTypes.func.isRequired,
    onLoginBlocker: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    loading: PropTypes.bool
};

EstimatePreviewAndSend.defaultProps = {
    loading: false
};

export default EstimatePreviewAndSend;
