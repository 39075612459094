import makeStyles from '@mui/styles/makeStyles';

const styledBy = (property, mapping) => (props) => mapping[props[property]];

export default makeStyles((theme) => ({
    toastContainer: {
        pointerEvents: 'none'
    },
    toast: {
        borderRadius: theme.typography.pxToRem(4),
        boxShadow: `0px ${theme.typography.pxToRem(
            20
        )} ${theme.typography.pxToRem(50)} rgba(0, 0, 0, 0.25)`,
        padding: `0 ${theme.typography.pxToRem(23)}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: theme.typography.pxToRem(16),
        color: '#fff',
        backgroundColor: styledBy('action', {
            archive: theme.palette.actionColors.archive,
            delete: theme.palette.actionColors.archive,
            download: theme.palette.actionColors.download,
            duplicate: theme.palette.actionColors.duplicate,
            saved: theme.palette.actionColors.positive,
            send: theme.palette.actionColors.send,
            notification: theme.palette.actionColors.archive
        })
    },
    icon: {
        height: theme.typography.pxToRem(18),
        marginRight: theme.typography.pxToRem(17),
        filter: 'brightness(200%)',
        '& svg': {
            width: theme.typography.pxToRem(18),
            height: theme.typography.pxToRem(18),
            fill: theme.palette.common.white
        }
    }
}));
