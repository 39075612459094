import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
    statusCheckbox: {
        marginRight: 8,
        marginBottom: 8,
        padding: 0
    },
    statusCheckboxWrap: {
        margin: 0
    }
}));
