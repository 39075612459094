import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';

import { useCheckAuthState, useSubscription } from '@/hooks';
import {
    getRcProducts,
    iapPurchaseProduct,
    rcLoginUser
} from '@/modules/dataWrangler/iapHelpers';
import { getUserSubscription } from '@/modules/subscriptionManager';
import { makeFormattedProducts } from '@/util';

import SubscriptionCard from '../SubscriptionCard';
import ThreeCardSkeleton from '../ThreeCardSkeleton';

export const getCurrentPlanType = (interval, intervalUnit) =>
    interval === 1 && intervalUnit === 'month' ? 'monthly' : 'yearly';

const InAppPurchases = ({
    loading = false,
    plansToShow,
    setShowMigrateButton
}) => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { registeredUser } = useCheckAuthState() || {};

    const {
        chargify,
        loading: useSubLoading,
        productId: currentPlan,
        refreshInterval,
        userSubscriptionState,
        subscriptionSource,
        managementURL
    } = useSubscription();

    const [products, setProducts] = useState([]);
    const [isPurchasing, setIsPurchasing] = useState(false);
    const [rcLoading, setRcLoading] = useState(false);
    const rcProductRef = useRef(null); // products from revenuecat
    const productToPurchaseRef = useRef(null); // selected product formatted for our components
    const showThankYouPage = useRef(false);

    const hasChargifyData = Object.keys(chargify || {}).length > 1;
    const loadingProducts = loading || useSubLoading || rcLoading;

    const rcLoginAndSubscribe = async () => {
        try {
            setIsPurchasing(true);

            const rcLoginSuccess = await rcLoginUser();

            if (rcLoginSuccess) {
                const plusYearlyRegex = /plus_yearly/;

                const planWithUnderscores =
                    productToPurchaseRef.current.productHandle.replace(
                        '-',
                        '_'
                    );

                const rcSelectedPlan = rcProductRef.current.find((p) =>
                    plusYearlyRegex.test(planWithUnderscores)
                        ? p.identifier === 'plus_yearly_1'
                        : p.identifier === planWithUnderscores
                );

                const purchaseRes = await iapPurchaseProduct(rcSelectedPlan);

                if (purchaseRes.productIdentifier && purchaseRes.newPurchase) {
                    showThankYouPage.current = true;
                }
                if (purchaseRes.hasPurchase) {
                    setShowMigrateButton(true);
                }
            }
        } catch (err) {
            return;
        } finally {
            setIsPurchasing(false);
            if (showThankYouPage.current === true) {
                showThankYouPage.current = false;
                navigate('/thankyou', {
                    replace: true,
                    state: { checkoutComplete: true }
                });
            }
        }
    };

    const makePurchase = async () => {
        if (!registeredUser) {
            return;
        }

        setIsPurchasing(true);

        await rcLoginAndSubscribe();
        if (showThankYouPage.current === true) {
            showThankYouPage.current = false;
            navigate('/thankyou', {
                replace: true,
                state: { checkoutComplete: true }
            });
        }
    };

    const getAfterSub = useCallback(async () => {
        if (state?.comingFromThankYou) {
            setIsPurchasing(true);
            await getUserSubscription(null, true);
            navigate('/settings/subscription', { replace: true, state: {} });
        }
    }, [navigate, state?.comingFromThankYou]);

    useEffect(() => {
        const getPackages = async () => {
            setRcLoading(true);
            const rcProducts = await getRcProducts();
            if (rcProducts.length !== 0) {
                rcProductRef.current = rcProducts;
                const formattedProducts = await makeFormattedProducts(
                    rcProducts
                );

                setProducts([...formattedProducts]);
            }
            setRcLoading(false);
        };

        getPackages();
        getAfterSub();
    }, [getAfterSub]);

    if (loadingProducts || products.length < 3) {
        return <ThreeCardSkeleton />;
    }

    const filteredProducts = products.filter((product) => {
        const currentPlanType = getCurrentPlanType(
            product.interval,
            product.intervalUnit
        );
        return plansToShow === currentPlanType;
    });

    return filteredProducts
        .sort((a, b) => a.rank - b.rank)
        .map((product) => {
            const currentPlanType = getCurrentPlanType(
                product.interval,
                product.intervalUnit
            );

            return (
                <SubscriptionCard
                    key={`${product.name}-${product.interval}-${product.intervalUnit}`}
                    title={product.name}
                    description={product.description}
                    price={product.price}
                    planType={currentPlanType}
                    subscribed={
                        userSubscriptionState === 'active' &&
                        refreshInterval === product.interval &&
                        product.name.toLowerCase() === currentPlan
                    }
                    iosIsPurchasing={isPurchasing}
                    isIos
                    onButtonClick={() => {
                        productToPurchaseRef.current = product;
                        makePurchase();
                    }}
                    subscriptionSource={subscriptionSource}
                    managementURL={
                        (userSubscriptionState === 'active' &&
                            product.name.toLowerCase() !== 'free' &&
                            managementURL) ||
                        null
                    }
                    hasChargifyData={hasChargifyData}
                />
            );
        });
};

InAppPurchases.propTypes = {
    loading: PropTypes.bool,
    plansToShow: PropTypes.string.isRequired,
    setShowMigrateButton: PropTypes.func.isRequired
};

InAppPurchases.defaultProps = {
    loading: false
};

export default InAppPurchases;
