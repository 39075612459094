import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    wrap: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.typography.pxToRem(10)
        },
        [theme.breakpoints.between('xs', 'md')]: {
            justifyContent: 'center',
            minWidth: '100%'
        }
    },
    open: {
        paddingRight: theme.typography.pxToRem(10),
        borderRight: '1px solid #DEE1EB',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    rest: ({ showPopover }) => ({
        display: 'flex',
        justifyContent: 'space-between',
        '& button': {
            marginLeft: theme.typography.pxToRem(10)
        },
        '& button:last-child': {
            [theme.breakpoints.down('md')]: {
                marginRight: 0
            }
        },
        [theme.breakpoints.between('md', 'xl')]: {
            display: showPopover && 'none'
        },
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    }),
    optionButton: ({ showPopover }) => ({
        display: 'none',
        [theme.breakpoints.between('md', 'xl')]: {
            height: theme.typography.pxToRem(56),
            display: showPopover ? 'block' : 'none'
        }
    }),
    icon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40px',
        height: '40px',
        backgroundColor: '#fff',
        borderRadius: '50%',
        transition: 'background-color 0.3s ease',
        margin: `0 0 0 ${theme.typography.pxToRem(10)}`,
        '& svg': {
            width: 23,
            height: 6,
            '& path': {
                fill: '#85949F'
            }
        }
    },
    popover: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.typography.pxToRem(10)
    }
}));
