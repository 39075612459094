import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
    LinearProgress,
    useMediaQuery,
    useTheme
} from '@mui/material';
import styles from './styles';

const DocumentRemainingAmount = ({ maxAmount, amountUsed, limited }) => {
    const theme = useTheme();
    const ninetyPlusUsed = amountUsed / maxAmount >= 0.9;
    const classes = styles({ ninetyPlusUsed });
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid
            item
            container
            xs={6}
            sm={4}
            className={classes.summaryItemContainer}
            justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
        >
            <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                xs={11}
                sm={8}
                className={classes.summaryItemTitle}
            >
                <Grid item>
                    <Typography className={classes.summaryItemInfo}>
                        Documents
                    </Typography>
                </Grid>
                <Grid item>
                    {isMobile ? (
                        limited && (
                            <Typography
                                color="textSecondary"
                                className={classes.summaryItemInfo}
                            >
                                {`${amountUsed} / ${maxAmount}`}
                            </Typography>
                        )
                    ) : (
                        <Typography
                            color="textSecondary"
                            className={classes.summaryItemInfo}
                        >
                            {limited
                                ? `${amountUsed} / ${maxAmount}`
                                : 'Unlimited'}
                        </Typography>
                    )}
                </Grid>
            </Grid>
            {isMobile ? (
                <Grid item xs={11}>
                    {limited ? (
                        <LinearProgress
                            value={
                                (amountUsed / maxAmount) * 100 ||
                                (limited ? 3 : 0)
                            }
                            variant="determinate"
                            classes={{
                                root: classes.progressBarRoot,
                                bar: classes.progressBarInner
                            }}
                        />
                    ) : (
                        <Typography
                            color="textSecondary"
                            className={classes.summaryItemInfo}
                        >
                            Unlimited
                        </Typography>
                    )}
                </Grid>
            ) : (
                <Grid item sm={8}>
                    <LinearProgress
                        value={
                            limited ? (amountUsed / maxAmount) * 100 || 3 : 0
                        }
                        variant="determinate"
                        classes={{
                            root: classes.progressBarRoot,
                            bar: classes.progressBarInner
                        }}
                    />
                </Grid>
            )}
        </Grid>
    );
};

DocumentRemainingAmount.propTypes = {
    maxAmount: PropTypes.number,
    amountUsed: PropTypes.number,
    limited: PropTypes.bool
};

DocumentRemainingAmount.defaultProps = {
    maxAmount: 0,
    amountUsed: 0,
    limited: false
};

export default DocumentRemainingAmount;
