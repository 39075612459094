/**
 * This function removes an empty key value pairs from a nested object.
 * Primary use for cleaning up URL params.
 * @param {object} object
 * @returns object with no empty values.
 */
export const removeEmptyFromObject = (obj) =>
    Object.fromEntries(
        Object.entries(obj)
            .filter(([, v]) => v !== null && v !== '' && v)
            .map(([k, v]) => [
                k,
                v === Object(v) ? removeEmptyFromObject(v) : v
            ])
    );
