import React from 'react';
import InvoiceForm from '@/components/MakeInvoice';
import { SEO } from '@/components/common';
import { Container, Layout } from '@/containers';
import { setHotjarPage } from '@/util';

const MakeInvoice = () => {
    setHotjarPage({ page: 'MakeInvoice' });

    return (
        <Layout>
            <Container>
                <SEO title="Make an Invoice" />
                <InvoiceForm />
            </Container>
        </Layout>
    );
};

export default MakeInvoice;
