import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom-v5-compat';

/**
 * Custom component to dynamically create internal/external links.
 * @param {node} children Elements to be wrapped by link.
 * @param {string} to URL to direct to.
 */
const Link = ({ children, to, ...other }) => {
    // Checks if link is internal/external URL.
    const internal = /^\/(?!\/)/.test(to);

    if (internal) {
        return (
            <NavLink
                to={to}
                // eslint-disable-next-line
                {...other}
            >
                {children}
            </NavLink>
        );
    }
    return (
        <a href={to} target="_blank" rel="noopener noreferrer" {...other}>
            {children}
        </a>
    );
};

Link.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    to: PropTypes.string.isRequired
};

export default Link;
