import React from 'react';
import PropTypes from 'prop-types';
import { Select as MuiSelect, FormControl, InputLabel } from '@mui/material';
import { Controller } from 'react-hook-form';

/**
 * This component wraps the MUI Select in a React Hook Forms Controller reducing
 * complexity. Also includes FormControl and label for cleaner semantic component.
 *
 * To use
 * ------
 * Make sure that the component is placed in a form which is wrapped in a FormProvider.
 *
 * To add options to the select, wrap MUI MenuItems with this component.
 *
 * @link: https://react-hook-form.com/
 *
 * @param {components} children All options to be included in Select.
 * @param {string} defaultValue This can also be set in useForm.
 * @param {string} label Components label.
 * @param {string} id The component ID. If ignored will be set by 'name'.
 * @param {string} name The component name. This will be used by React Hook Forms when returning data.
 * @param {props} rest Any other props that you want to pass across to the component.
 */
const Select = ({ children, defaultValue, label, id, name, ...rest }) => {
    const labelId = `${name}-label`;

    return (
        <FormControl {...rest}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Controller
                name={name}
                defaultValue={defaultValue}
                render={({ field }) => (
                    <MuiSelect {...field} labelId={labelId} label={label}>
                        {children}
                    </MuiSelect>
                )}
            />
        </FormControl>
    );
};

Select.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.array
    ]),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string.isRequired
};

Select.defaultProps = {
    children: '',
    defaultValue: '',
    id: '',
    label: ''
};

export default Select;
