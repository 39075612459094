import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { Grid, TextField, Button } from '@mui/material';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import {
    AddressInputBlock,
    DrawerBody,
    DrawerHeading,
    UncontrolledGenericDrawer,
    DrawerFooter,
    PhoneNumberInput
} from '@/components/common';
import EmailTextField from '@/components/common/EmailTextField';
import { useMountEffect, useLocaleCountry } from '@/hooks';
import { ClientsActive } from '@/resources/icons';
import {
    addClient,
    getClients,
    resetGetClients,
    updateClient
} from '@/modules/dataWrangler/dataWrangler';
import AutocompleteTextField from '@/components/common/AutocompleteTextField';
import {
    closeDrawer,
    resetBillToErrors,
    setBilledClient
} from '@/state/actions';
import { emailRegexPattern } from '@/util';
import useStyles from './styles';

const defaultClient = {
    billingAddress: {
        postalCode: '',
        country: '',
        city: '',
        state: '',
        line2: '',
        line1: '',
        customAddress: ''
    },
    shippingAddress: {
        postalCode: '',
        country: '',
        city: '',
        state: '',
        line2: '',
        line1: '',
        customAddress: ''
    },
    clientId: '',
    emailAddress: '',
    phoneNumber: '',
    companyName: '',
    name: '',
    tags: [],
    customFields: []
};

const BillToDrawer = ({ open, permanent }) => {
    const localeCountry = useLocaleCountry();

    const clientToDisplay =
        useSelector((state) => state.makeInvoice?.to) || defaultClient;

    const presaveClient = useRef();
    if (clientToDisplay.name && presaveClient.current === undefined) {
        presaveClient.current = clientToDisplay;
    }

    const businessId = useSelector(
        (state) => state.user?.activeBusiness?.businessId
    );
    const businessIdRef = useRef('');

    useEffect(() => {
        if (businessId && businessIdRef?.current !== businessId) {
            resetGetClients();
            businessIdRef.current = businessId;
        }
    }, [businessIdRef, businessId]);

    const [previousClient, setPreviousClient] = useState(
        cloneDeep(clientToDisplay)
    );

    const [selectedName, setSelectedName] = useState('');

    const [defaultPhoneCountry, setDefaultPhoneCountry] = useState('');

    useMountEffect(() => {
        getClients();
        setSelectedName('');
    });

    useEffect(() => {
        if (open) {
            setSelectedName(clientToDisplay?.name || '');
            setPreviousClient(cloneDeep(clientToDisplay));
        }
    }, [open, clientToDisplay]);

    const [clientError, setClientError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [preEditClient, setPreEditClient] = useState(
        cloneDeep(clientToDisplay)
    );

    const [lock, setLock] = useState(false);
    const [edit, setEdit] = useState(false);
    const dispatch = useDispatch();

    const clientList =
        useSelector((state) => state.user?.activeBusiness?.clients) || [];

    const classes = useStyles();

    const setClient = (client) => {
        const newClient = cloneDeep(client);
        return setBilledClient(newClient);
    };

    const resetClient = () => {
        // eslint-disable-next-line
        if (Boolean(previousClient.clientId)) {
            setEdit(false);
            setLock(true);
        } else {
            setEdit(false);
            setLock(false);
        }
        dispatch(setClient(cloneDeep(previousClient)));
    };

    const resetToPreEditClient = () => {
        setSelectedName(preEditClient.name || '');
        dispatch(setClient(cloneDeep(preEditClient)));
    };

    const handleCloseDrawer = () => {
        setClientError('');
        setEmailError('');
        dispatch(closeDrawer('billTo'));
    };

    const cancelAndCloseDrawer = () => {
        resetClient();
        handleCloseDrawer();
    };

    const isDefaultClient = () =>
        JSON.stringify(defaultClient) === JSON.stringify(clientToDisplay);

    const [saving, setSaving] = useState(false);

    const saveClient = async (e) => {
        e.preventDefault();

        const newClient = { ...clientToDisplay };

        if (!newClient?.billingAddress?.country) {
            newClient.billingAddress = {
                ...newClient.billingAddress,
                country: localeCountry.code
            };
        }

        const updating = Boolean(newClient.clientId);
        if (!newClient?.name) {
            if (isDefaultClient()) {
                handleCloseDrawer();
                return;
            }

            setClientError('Please enter a client name.');
            return;
        }

        if (
            newClient?.phoneNumber?.length !== 0 &&
            newClient?.phoneNumer !== undefined &&
            !isPossiblePhoneNumber(String(newClient?.phoneNumber))
        ) {
            setEmailError('Please enter a valid email address.');
            return;
        }

        if (
            newClient?.emailAddress &&
            !emailRegexPattern.test(newClient?.emailAddress)
        ) {
            setPhoneNumberError('Please enter a vaild phone number');
            return;
        }

        // eslint-disable-next-line
        if (emailError || phoneNumberError) {
            return;
        }

        if (phoneNumberError) {
            return;
        }

        if (updating) {
            setSaving(true);

            let updatedClient = false;

            // If the client has been updated, save.
            if (
                JSON.stringify(presaveClient.current) !==
                JSON.stringify(newClient)
            ) {
                updatedClient = await updateClient({
                    ...newClient,
                    emailAddress: newClient.emailAddress
                });
            }

            if (updatedClient) {
                dispatch(
                    setClient({
                        ...newClient,
                        emailAddress: newClient.emailAddress
                    })
                );
                setPreviousClient(cloneDeep(newClient));
            }

            presaveClient.current = newClient;
        } else {
            setSaving(true);
            const { clientId, ...clientFields } = newClient;

            const clientToSave = await addClient({
                ...clientFields,
                emailAddress: clientFields.emailAddress
            });

            dispatch(setClient(clientToSave));
            setPreviousClient(cloneDeep(clientToSave));

            presaveClient.current = clientToSave;
        }
        setEdit(false);
        setLock(true);
        dispatch(resetBillToErrors());
        setSaving(false);
        handleCloseDrawer();
    };

    const updateSelectedClient = (fieldName, fieldValue) => {
        if (
            fieldName === 'phoneNumber' &&
            (isPossiblePhoneNumber(String(fieldValue)) || fieldValue === '')
        ) {
            setPhoneNumberError('');
        }

        const newClient = { ...clientToDisplay };

        if (!newClient?.billingAddress?.country) {
            newClient.billingAddress = {
                ...newClient.billingAddress,
                country: localeCountry.code
            };
        }

        newClient.emailAddress = clientToDisplay.emailAddress;

        set(newClient, fieldName, fieldValue);

        dispatch(setClient(newClient));
    };

    const handleApply = () => {
        presaveClient.current = preEditClient;

        if (!clientToDisplay?.name) {
            setClientError('Please enter a client name.');
            return;
        }

        // eslint-disable-next-line
        if (Boolean(emailError)) {
            return;
        }
        setEdit(false);
        setLock(true);
        setPreEditClient(cloneDeep(clientToDisplay));
    };

    const handleCancelEdit = () => {
        setEdit(false);
        setLock(true);
        setClientError('');
        setEmailError('');
        resetToPreEditClient();
    };

    const handleEditButton = () => {
        setEdit(true);
        setLock(false);
        setPreEditClient(cloneDeep(clientToDisplay));
    };

    const handleUpdateNameText = (client) => {
        setLock(false);
        setPreEditClient(cloneDeep(client));
        dispatch(setClient(client));
    };

    const handleClientSelect = (client) => {
        setPreEditClient(cloneDeep(client));
        setEdit(false);
        setLock(true);

        if (!client?.name) {
            setClientError('Please enter a client name.');
        } else {
            setClientError('');
        }
        dispatch(setClient(client));
    };

    const handleAddressBlock = (address) => {
        const newClient = {
            ...clientToDisplay,
            billingAddress: address
        };

        dispatch(setClient(newClient));
    };

    const clearAllButName = (name) => {
        setSelectedName(name);
        const newClient = cloneDeep(defaultClient);
        newClient.name = name;
        handleUpdateNameText(newClient);
    };

    const handleTextFieldChange = (name) => {
        if (!name) {
            // set error for empty fields
            setClientError('Please enter a client name.');
        } else {
            setClientError('');
        }

        if (edit) {
            // only update if edit is selected, else type new name
            updateSelectedClient('name', name);
            setSelectedName(name);
        } else if (
            // eslint-disable-next-line
            Boolean(clientToDisplay.clientId)
        ) {
            clearAllButName(name);
        } else {
            setSelectedName(name);
            const newClient = cloneDeep(clientToDisplay);
            newClient.name = name;
            handleUpdateNameText(newClient);
        }
    };

    const handleClear = () => {
        if (!edit) {
            setLock(false);
            setEdit(false);
            handleUpdateNameText(cloneDeep(defaultClient));
        } else {
            const newClient = cloneDeep(clientToDisplay);
            newClient.name = '';
            dispatch(setClient(newClient));
        }
    };

    return (
        <UncontrolledGenericDrawer
            open={open}
            permanent={permanent}
            id="billTo"
        >
            <form className={classes.form}>
                <div>
                    <DrawerHeading
                        startAdornment={<ClientsActive />}
                        title="Bill To"
                        label="clientSelector"
                    />
                    <DrawerBody className={classes.itemList}>
                        <Grid item xs>
                            <AutocompleteTextField
                                options={clientList}
                                onSelect={handleClientSelect}
                                onUniqueValueEntered={() => {}}
                                onClear={handleClear}
                                inputValue={selectedName}
                                label="Name"
                                optionLabels={['name']}
                                error={Boolean(clientError)}
                                helperText={clientError}
                                onTextFieldChange={(e) => {
                                    handleTextFieldChange(e.target.value);
                                }}
                                resultsLimit={10}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                disabled={lock}
                                onChange={(e) =>
                                    updateSelectedClient(
                                        'companyName',
                                        e.target.value
                                    )
                                }
                                value={clientToDisplay.companyName}
                                id="companyName"
                                label="Company"
                            />
                        </Grid>

                        <Grid item xs>
                            <EmailTextField
                                fullWidth
                                disabled={lock}
                                error={Boolean(emailError)}
                                helperText={emailError}
                                onChange={(e) => {
                                    if (
                                        emailRegexPattern.test(
                                            e.target.value
                                        ) ||
                                        !e.target.value
                                    ) {
                                        setEmailError('');
                                    }
                                    updateSelectedClient(
                                        'emailAddress',
                                        e.target.value
                                    );
                                }}
                                value={clientToDisplay.emailAddress}
                                onBlur={(e) => {
                                    if (emailRegexPattern.test(e) || !e) {
                                        setEmailError('');
                                        updateSelectedClient('emailAddress', e);
                                    } else {
                                        setEmailError(
                                            'Please enter a valid email address.'
                                        );
                                    }
                                }}
                                id="emailAddress"
                                label="Email"
                            />
                        </Grid>

                        <Grid item xs>
                            <PhoneNumberInput
                                disabled={lock}
                                fullWidth
                                id="phoneNumber"
                                value={clientToDisplay?.phoneNumber || ''}
                                name="phoneNumber"
                                onChange={(value) =>
                                    updateSelectedClient('phoneNumber', value)
                                }
                                defaultValue={
                                    clientToDisplay?.phoneNumber || ''
                                }
                                defaultCountry={
                                    defaultPhoneCountry ||
                                    clientToDisplay?.billingAddress?.country ||
                                    localeCountry?.code
                                }
                                setDefaultPhoneCountry={setDefaultPhoneCountry}
                                error={Boolean(phoneNumberError)}
                                helperText={phoneNumberError}
                            />
                        </Grid>

                        <AddressInputBlock
                            address={clientToDisplay?.billingAddress}
                            disabled={lock}
                            setAddress={handleAddressBlock}
                        />

                        {lock && (
                            <Grid item>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    className={classes.editButton}
                                    onClick={handleEditButton}
                                >
                                    Edit Client
                                </Button>
                            </Grid>
                        )}
                        {!lock && edit && (
                            <Grid item>
                                <Grid container spacing={2}>
                                    <Grid item xs>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            className={classes.cancelButton}
                                            onClick={handleCancelEdit}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item xs>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            id="edit-client-apply"
                                            fullWidth
                                            onClick={handleApply}
                                        >
                                            Apply
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </DrawerBody>
                </div>
                <DrawerFooter
                    disabled={edit}
                    saving={saving}
                    cancelOptions={{
                        label: 'Cancel',
                        action: cancelAndCloseDrawer
                    }}
                    acceptOptions={{
                        label: 'Save',
                        type: 'submit',
                        action: saveClient
                    }}
                />
            </form>
        </UncontrolledGenericDrawer>
    );
};

BillToDrawer.propTypes = {
    permanent: PropTypes.bool,
    open: PropTypes.bool
};

BillToDrawer.defaultProps = {
    permanent: false,
    open: false
};

export default BillToDrawer;
