/* eslint import/no-cycle: "off"  */
import store from '@/state/store';

import { globalPageCount } from '@/config';

import {
    fetchListingItems,
    noListingItems,
    overwriteListingItems,
    fetchNotificationCount,
    reduceNotificationCount,
    increaseNotificationCount,
    clearNotificationCount
} from '@/state/actions';

import { filterArgFlattener } from '@/util';

import axiosAuthenticated from '../authenticatedRequestor';

export const getNotifications = async (args) => {
    if (store.getState().user.temporaryUser) {
        store.dispatch(noListingItems({ type: 'notifications' }));
        return;
    }

    store.dispatch(fetchListingItems({ type: 'notifications' }));

    const allArgs = { pageSize: globalPageCount, ...filterArgFlattener(args) };

    const response = await axiosAuthenticated.get(`/user/notifications`, {
        params: allArgs
    });

    const { items, itemsMatchingRequest, pageSize, totalItems } = response.data;

    const totalPages = Math.ceil(itemsMatchingRequest / pageSize);

    store.dispatch(
        overwriteListingItems({
            type: 'notifications',
            items,
            totalPages,
            totalItems
        })
    );
};

export const getNotificationUnreadCount = async () => {
    if (store.getState().user.temporaryUser) {
        store.dispatch(clearNotificationCount());
        return;
    }

    const params = {
        state: 'unread',
        pageSize: 1
    };

    const response = await axiosAuthenticated.get(`/user/notifications`, {
        params
    });

    const { itemsMatchingRequest } = response.data;

    store.dispatch(fetchNotificationCount(itemsMatchingRequest));
};

export const markNotificationState = async ({ id, read }) => {
    // No need for async. No error handling here.
    axiosAuthenticated.put(
        `/user/notifications/${id}`,
        read ? { state: 'read' } : { state: 'unread' }
    );

    // Get the notification listing.
    const currentListing = store.getState()?.listing?.notifications;

    // Find the notification in the listing and mark it as read.
    if (currentListing?.items?.length > 0) {
        const newItems = currentListing.items.map((notification) => {
            if (notification.notificationId === id) {
                return { ...notification, state: read ? 'read' : 'unread' };
            }

            return notification;
        });

        if (read) {
            store.dispatch(reduceNotificationCount());
        } else {
            store.dispatch(increaseNotificationCount());
        }

        store.dispatch(
            overwriteListingItems({
                type: 'notifications',
                ...currentListing,
                items: newItems
            })
        );
    }
};

export const markAllNotificationsAsRead = async () => {
    // No need for async. No error handling here.
    await axiosAuthenticated.post(`/user/notifications/mark-as-read`);

    store.dispatch(clearNotificationCount());

    return true;
};
