import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery, Skeleton } from '@mui/material';
import { NoEmailModal } from '@/components/common';
import { closeModal } from '@/state/actions';
import {
    updateClient,
    deleteRecurringInvoice,
    stopRecurringInvoice
} from '@/modules/dataWrangler/dataWrangler';

import { useCheckAuthState, useDocumentFormData, useModals } from '@/hooks';

import {
    useActivateRecurringInvoice,
    useValidateRecurringInvoice
} from '@/hooks/useSidebarActions';

import DocumentStateView from '../../DocumentStateView';
import {
    RecurringInvoiceStop,
    RecurringInvoiceDelete,
    RecurringInvoicePreviewAndActivate,
    RecurringInvoiceSave
} from '../actions';

import useStyles from '../styles';

import {
    stopRecurringInvoiceModalOptions,
    deleteRecurringInvoiceModalOptions,
    previewRecurringInvoiceNegativeTotalModalOptions,
    saveNegativeRecurringInvoiceTotalModalOptions
} from '../ModalCopy';

const RecurringInvoiceSidebar = ({
    beforeActionPromptOptions,
    setBeforeActionPromptOptions,
    handleSetLoginBlocker,
    locked
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Styling
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    // Hooks
    const { loadingUser } = useCheckAuthState();
    const { documentLoading } = useDocumentFormData();
    const { isModalActive } = useModals();
    const validateRecurringInvoice = useValidateRecurringInvoice();
    const activateRecurringInvoice = useActivateRecurringInvoice();

    const invoice = useSelector((state) => state.makeInvoice) || null;

    const showSkeletonLoaders = loadingUser || documentLoading;

    const documentId = invoice?.documentId || null;

    const classes = useStyles({ shippingDisabled: Boolean(!invoice?.to) });

    const [savingForPreview, setSavingForPreview] = useState(false);

    const setSaveNegativeTotalWarningActive = () => {
        setBeforeActionPromptOptions({
            ...beforeActionPromptOptions,
            ...saveNegativeRecurringInvoiceTotalModalOptions,
            onConfirm: () =>
                setBeforeActionPromptOptions({
                    ...saveNegativeRecurringInvoiceTotalModalOptions,
                    open: false
                }),
            open: true
        });
    };

    const setPreviewNegativeTotalWarningActive = () => {
        setBeforeActionPromptOptions({
            ...beforeActionPromptOptions,
            ...previewRecurringInvoiceNegativeTotalModalOptions,
            onConfirm: () =>
                setBeforeActionPromptOptions({
                    ...previewRecurringInvoiceNegativeTotalModalOptions,
                    open: false
                }),
            open: true
        });
    };

    const setDeleteInvoiceWarningActive = () => {
        setBeforeActionPromptOptions({
            ...beforeActionPromptOptions,
            ...deleteRecurringInvoiceModalOptions,
            onCancel: () =>
                setBeforeActionPromptOptions({
                    ...deleteRecurringInvoiceModalOptions,
                    open: false
                }),
            onConfirm: async () => {
                setBeforeActionPromptOptions({
                    ...deleteRecurringInvoiceModalOptions,
                    open: false
                });

                await deleteRecurringInvoice(documentId);

                navigate('/recurring-invoices');
            },
            open: true
        });
    };

    const setStopInvoiceWarningActive = () => {
        setBeforeActionPromptOptions({
            ...beforeActionPromptOptions,
            ...stopRecurringInvoiceModalOptions,
            onCancel: () =>
                setBeforeActionPromptOptions({
                    ...stopRecurringInvoiceModalOptions,
                    open: false
                }),
            onConfirm: async () => {
                setBeforeActionPromptOptions({
                    ...stopRecurringInvoiceModalOptions,
                    open: false
                });

                await stopRecurringInvoice(documentId);
            },
            open: true
        });
    };

    return (
        <div className={classes.sidebar}>
            <div className={classes.container}>
                <RecurringInvoicePreviewAndActivate
                    onNegativeInvoice={setPreviewNegativeTotalWarningActive}
                    onLoginBlocker={handleSetLoginBlocker}
                    setLoading={setSavingForPreview}
                    loading={savingForPreview}
                    isDesktop={isDesktop}
                />

                <RecurringInvoiceSave
                    onLoginBlocker={handleSetLoginBlocker}
                    onNegativeInvoice={setSaveNegativeTotalWarningActive}
                />

                <RecurringInvoiceDelete
                    onClick={setDeleteInvoiceWarningActive}
                />

                <RecurringInvoiceStop onClick={setStopInvoiceWarningActive} />

                {locked && (
                    <div className={classes.desktopState}>
                        <div className={classes.section}>
                            {!showSkeletonLoaders ? (
                                <DocumentStateView documentType="recurring-invoice" />
                            ) : (
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={185}
                                />
                            )}
                        </div>
                    </div>
                )}

                <NoEmailModal
                    open={isModalActive('process-invoice-email-required')}
                    onClose={async (emailAddress, saveToClient, setLoading) => {
                        if (emailAddress === undefined) {
                            dispatch(
                                closeModal('process-invoice-email-required')
                            );
                            return;
                        }

                        setLoading(true);

                        const updatedClient = {
                            ...invoice.to,
                            emailAddress
                        };

                        const validatedInvoice = await validateRecurringInvoice(
                            invoice
                        );

                        await activateRecurringInvoice(
                            validatedInvoice,
                            emailAddress
                        );

                        if (saveToClient) {
                            updateClient(updatedClient);
                        }

                        setLoading(false);
                    }}
                />
            </div>
        </div>
    );
};

RecurringInvoiceSidebar.propTypes = {
    beforeActionPromptOptions: PropTypes.shape({}),
    setBeforeActionPromptOptions: PropTypes.func.isRequired,
    handleSetLoginBlocker: PropTypes.func.isRequired,
    locked: PropTypes.bool
};

RecurringInvoiceSidebar.defaultProps = {
    beforeActionPromptOptions: {},
    locked: false
};

export default RecurringInvoiceSidebar;
