/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { differenceInDays } from 'date-fns';
import { dateTerms } from '@/config/dateTerms';
import { createHash } from '@/util/createHash';
import { defaultDocument } from '@/config/defaultDocument';

export const makeDocumentSlice = createSlice({
    name: 'makeInvoice',
    initialState: defaultDocument,
    reducers: {
        toggleDiscount: (state, action) => {
            state.discount = action.payload;
        },
        saveNotes: (state, action) => {
            state.details = action.payload;
        },
        saveCustomLabel: (state, action) => {
            state.customLabel = action.payload;
        },
        toggleShipping: (state, action) => {
            state.shipping = action.payload;
        },
        setBilledClient: (state, action) => {
            state.to = action.payload;
        },
        setDueDate: (state, action) => {
            state.dueDate = action.payload;
        },
        setDocumentTerms: (state, action) => {
            state.terms = action.payload;
        },
        setDocumentState: (state, action) => {
            const document = { ...action.payload.document };

            document.products = document?.products?.map((product, index) => ({
                ...product,
                lineId: index
            }));

            if (action.payload.locked) {
                return {
                    ...document,
                    viewMode: 'edit'
                };
            }

            if (action.payload.documentType !== 'recurring-invoice') {
                // If no terms, get terms from config and work out
                // from issued/due date.
                if (
                    !document?.terms &&
                    document?.issuedDate &&
                    document?.dueDate
                ) {
                    const daysBetween = differenceInDays(
                        new Date(document.dueDate),
                        new Date(document.issuedDate)
                    );
                    const term = dateTerms.find(
                        ({ value }) => value === daysBetween
                    );
                    if (term) {
                        document.terms = term;
                    } else {
                        // eslint-disable-next-line prefer-destructuring
                        document.terms = dateTerms[1];
                    }
                }
            }

            return document;
        },
        resetDocument: (_, action) => {
            let baseDocument = { ...defaultDocument };

            // If it is a recurring invoice, we need to clean up the document.
            // There are some items that are not needed for recurring invoices.
            // And others that are.
            if (action.payload === 'recurring-invoice') {
                // Remove the items that are not needed.
                delete baseDocument.issuedDate;
                delete baseDocument.dueDate;
                delete baseDocument.terms;
                delete baseDocument.customLabel;

                // Add the items that are needed.
                baseDocument = {
                    ...baseDocument,
                    recurringInterval: 1,
                    recurringIntervalUnit: 'day',
                    firstSendDate: new Date().toISOString(),
                    lastSendDate: null,
                    repeats: null,
                    termInterval: 1,
                    termIntervalUnit: 'day'
                };
            }

            return {
                ...baseDocument,
                formInstanceHash: createHash(),
                documentType: action.payload || 'invoice'
            };
        },
        updatedocumentIds: (state, action) => {
            state.referenceNumber = action.payload.referenceNumber;
            state.documentId = action.payload.documentId;
            state.customLabel = action.payload.customLabel;
        },
        overwriteDocument: (state, action) => action.payload,
        setDocumentErrors: (state, action) => {
            state.errors = action.payload;
        },
        resetDocumentErrors: (state) => {
            state.errors = {};
        },
        resetBillToErrors: (state) => {
            state.errors = {
                ...state.errors,
                to: undefined
            };
        },
        resetBillFromErrors: (state) => {
            state.errors = {
                ...state.errors,
                from: undefined
            };
        },
        addProductToDocument: (state, action) => {
            state.products.push(action.payload);
        },
        removeProductFromDocument: (state, action) => {
            const allProducts = [...state.products];

            const productIndex = allProducts.findIndex(
                (product) => product.lineId === action.payload
            );

            if (productIndex >= 0) {
                allProducts.splice(productIndex, 1);
            }

            state.products = allProducts;
        },
        updateDocumentProduct: (state, action) => {
            // eslint-disable-next-line no-case-declarations
            const productIndex = state.products.findIndex(
                (item) => item.lineId === action.payload.lineId
            );

            // eslint-disable-next-line no-case-declarations
            const allProducts = [...state.products];

            // if the staged product exists in the store,
            // replace it with the action's staged product
            if (productIndex > -1) allProducts[productIndex] = action.payload;

            state.products = allProducts;
        },
        updateDocumentProducts: (state, action) => {
            state.products = action.payload;
        },
        selectDocumentProduct: (state, action) => {
            state.selectedInvoiceProduct = state.products.find(
                (product) => product.lineId === action.payload
            );
        },
        setDocumentReminders: (state, action) => {
            state.reminders = action.payload;
        },
        setDocumentStatus: (state, action) => {
            state.documentState = action.payload;
        },
        updateDocumentTotals: (state, action) => {
            state.totals = {
                total: action.payload?.total || 0,
                subtotal: action.payload?.subTotal || 0,
                discount: action.payload?.discount || 0,
                tax: action.payload?.tax || 0,
                shipping: action.payload?.shipping || 0
            };
        },
        setVoidDocumentPending: (state) => {
            state.voidDocumentPending = true;
        },
        setVoidDocumentComplete: (state) => {
            state.voidDocumentPending = false;
        },
        setInvoiceAsUnpaidClientMethod: (state) => {
            state.markedPaidByClientMethod = null;
        },
        setDocumentTags: (state, action) => {
            state.tags = action.payload;
        },
        removeDocumentTag: (state, action) => {
            const allTags = [...state.tags];

            const tagIndex = allTags.findIndex(
                (tag) => tag.tagId === action.payload
            );

            if (tagIndex >= 0) {
                allTags.splice(tagIndex, 1);
            }

            state.tags = allTags;
        },
        setRecurringDetails: (state, action) => {
            state.recurringInterval = action.payload.recurringInterval;
            state.recurringIntervalUnit = action.payload.recurringIntervalUnit;
            state.firstSendDate = action.payload.firstSendDate;
            state.lastSendDate = action.payload.lastSendDate;
            state.repeats = action.payload.repeats;
            state.termInterval = action.payload.termInterval;
            state.termIntervalUnit = action.payload.termIntervalUnit;
        }
    }
});

export const {
    toggleDiscount,
    setDiscountMode,
    setDiscountAmount,
    toggleNotes,
    saveNotes,
    saveCustomLabel,
    toggleShipping,
    setBilledClient,
    setDueDate,
    setDocumentTerms,
    setDocumentState,
    resetDocument,
    updatedocumentIds,
    overwriteDocument,
    setDocumentErrors,
    resetDocumentErrors,
    resetBillToErrors,
    resetBillFromErrors,
    addProductToDocument,
    removeProductFromDocument,
    updateDocumentProduct,
    updateDocumentProducts,
    selectDocumentProduct,
    setDocumentReminders,
    setDocumentStatus,
    updateDocumentTotals,
    setVoidDocumentPending,
    setVoidDocumentComplete,
    setInvoiceAsUnpaidClientMethod,
    setDocumentTags,
    removeDocumentTag,
    setRecurringDetails
} = makeDocumentSlice.actions;

export default makeDocumentSlice.reducer;
