import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    radio: {
        color: (active) =>
            active ? theme.palette.common.white : theme.palette.common.black,
        backgroundColor: (active) =>
            active
                ? theme.palette.actionColors.send
                : theme.palette.common.white,
        border: (active) => (active ? undefined : '1px solid #DBDFEA'),
        borderRadius: 4,
        height: 24,
        width: '100%',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'center'
    }
}));
