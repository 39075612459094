import React from 'react';
import PropTypes from 'prop-types';
import { StatusPill } from '@/components/common';
import { Checkbox } from '@/components/rhf-mui';
import Grid from '@mui/material/Unstable_Grid2';
import FormControlLabel from '@mui/material/FormControlLabel';
import ItemLabel from '../ItemLabel';
import makeStyles from './styles';

const StatusCheckbox = ({ documentStates }) => {
    const classes = makeStyles();

    const getStatusIcon = (status, checked) => (
        <StatusPill status={status} inline removeFill={!checked} />
    );

    return (
        <Grid item container direction="column">
            <ItemLabel>Status</ItemLabel>
            <Grid item xs={documentStates.length > 4 ? 10 : 12}>
                {documentStates.map((status) => (
                    <FormControlLabel
                        className={classes.statusCheckboxWrap}
                        key={status}
                        control={
                            <Checkbox
                                id={`${status}-status`}
                                name={`status[${status}]`}
                                icon={getStatusIcon(status)}
                                checkedIcon={getStatusIcon(status, true)}
                                className={classes.statusCheckbox}
                                disableRipple
                            />
                        }
                    />
                ))}
            </Grid>
        </Grid>
    );
};

StatusCheckbox.propTypes = {
    documentStates: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default StatusCheckbox;
