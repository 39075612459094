import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    switchLabel: {
        ...theme.typography.body2,
        color: ({ color }) => theme.palette.text[color]
    },
    switchLabelPlacement: {
        marginLeft: 2,
        width: '100%',
        justifyContent: 'space-between'
    }
}));
