import React from 'react';
import Lottie from 'lottie-react';
import sendingLoading from './sending-loading.json';
import useStyles from './styles';

const LottieSendingLoading = () => {
    const classes = useStyles();

    return (
        <div className={classes.wrap} data-testid="lottie-sending">
            <Lottie animationData={sendingLoading} />
        </div>
    );
};

export default LottieSendingLoading;
