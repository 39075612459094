export const reminderOptions = {
    upcoming: [
        { name: '1 day before', slug: 'upcomingDue1Days' },
        { name: '3 days before', slug: 'upcomingDue3Days' },
        { name: '7 days before', slug: 'upcomingDue7Days' }
        // { name: '14 days before', slug: 'upcomingDue14Days' },
        // { name: '30 days before', slug: 'upcomingDue30Days' }
    ],
    pastDue: [
        { name: '1 day after', slug: 'pastDue1Days' },
        { name: '3 days after', slug: 'pastDue3Days' },
        { name: '7 days after', slug: 'pastDue7Days' },
        { name: '14 days after', slug: 'pastDue14Days' },
        { name: '30 days after', slug: 'pastDue30Days' },
        { name: '60 days after', slug: 'pastDue60Days' }
        // { name: '90 days after', slug: 'pastDue90Days' },
        // { name: '180 days after', slug: 'pastDue180Days' }
    ]
};
