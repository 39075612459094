import React, { useEffect } from 'react';
import {
    Route,
    Routes,
    useLocation,
    useNavigate
} from 'react-router-dom-v5-compat';
import loadable from '@loadable/component';

import {
    useCheckIfAppNeedUpdate,
    useDeepLinkListener,
    useLocalization,
    useMountEffect
} from '@/hooks';

import {
    setLocalStorageItem,
    removeLocalStorageItem
} from '@/modules/localStorageManager';

import { useRefreshAppState } from '@/hooks/useRefreshAppState';

import { onPageLoadSetup } from '@/modules/setup';

import InternalRoute from '@/components/common/InternalRoute';

import { startNetworkChecks } from '@/modules/networkManager';
import { getVisitData } from '@/modules/visitData';

import { pageView as segmentPageView } from '@/modules/segment/segmentManager';

import Products from '@/pages/items/products';
import Services from '@/pages/items/services';
import Taxes from '@/pages/items/taxes';
import Discounts from '@/pages/items/discounts';
import Tags from '@/pages/items/tags';

import Settings from '@/pages/settings';
import Activity from '@/pages/activity';
import Notifications from '@/pages/notifications';
import PaymentMethods from '@/pages/settings/payment-methods';
import DocumentDefaults from '@/pages/settings/document-defaults';
import Subscription from '@/pages/settings/subscription';

import RecurringInvoice from '@/pages/recurring-invoice';
import RecurringInvoices from '@/pages/recurring-invoices';

// MakeEstimate is used for both /estimate and estimate/id
import MakeEstimate from '@/pages/estimate';

// MakeReceipt is used for both /receipt and receipt/id
import MakeReceipt from '@/pages/receipt';

// MakeInvoice just used for /
import MakeInvoice from '@/pages';

const AllClients = loadable(() => import(`./pages/clients`));
const AllEstimates = loadable(() => import(`./pages/estimates`));
const AllReceipts = loadable(() => import(`./pages/receipts`));
const AllInvoices = loadable(() => import(`./pages/invoices`));
const ConnectPaymentStepper = loadable(() =>
    import(`./pages/connect-payments`)
);

const Invoice = loadable(() => import(`./pages/invoice`));
const Client = loadable(() => import(`./pages/clients/client`));

const PayInvoice = loadable(() => import(`./pages/link`));
const Preview = loadable(() => import(`./pages/preview`));
const PreviewRecurring = loadable(() => import(`./pages/preview-recurring`));

const Checkout = loadable(() => import(`./pages/checkout`));
const QuickCheckout = loadable(() => import(`./pages/quick-checkout`));
const Update = loadable(() => import(`./pages/update`));
const ThankYou = loadable(() => import(`./pages/thankyou`));
const AccountDeletionConfirmation = loadable(() =>
    import(`./pages/account-deletion`)
);

const UpdateAppModal = loadable(() => import(`./components/UpdateAppModal`));
const MaintenanceModal = loadable(() =>
    import(`./components/MaintenanceModal`)
);

function App() {
    const appUpdateState = useCheckIfAppNeedUpdate();
    const location = useLocation();
    const navigate = useNavigate();
    useDeepLinkListener();

    const locationPathname = location.pathname;

    window.dataLayer.push({
        event: 'pageview'
    });

    useMountEffect(() => {
        startNetworkChecks();
        onPageLoadSetup(location, navigate);

        // Grab url params gclid and msclkid and store them in local storage
        const urlParams = new URLSearchParams(window.location.search);
        const gclid = urlParams.get('gclid');
        const msclkid = urlParams.get('msclkid');
        const visitData = getVisitData();

        // Set visit data in local storage
        setLocalStorageItem('imvd', visitData);

        if (gclid) {
            setLocalStorageItem('gclid', gclid);
        } else {
            removeLocalStorageItem('gclid');
        }
        if (msclkid) {
            setLocalStorageItem('msclkid', msclkid);
        } else {
            removeLocalStorageItem('msclkid');
        }
    });

    // Handle page view tracking
    useEffect(() => {
        segmentPageView();
    }, [locationPathname]);

    useLocalization();
    useRefreshAppState();

    return (
        <>
            <div className="ios-notch-fix" />
            {appUpdateState.inMaintenance && (
                <MaintenanceModal open={appUpdateState.inMaintenance} />
            )}
            {appUpdateState.update && (
                <UpdateAppModal open={appUpdateState.update} />
            )}
            <Routes>
                <Route exact path="link/:invoiceJwt" element={<PayInvoice />} />
                <Route
                    path="connect-payments"
                    element={
                        <InternalRoute>
                            <ConnectPaymentStepper />
                        </InternalRoute>
                    }
                />
                <Route
                    element={
                        <InternalRoute>
                            <Client />
                        </InternalRoute>
                    }
                    path="clients/:clientId/*"
                />
                <Route
                    exact
                    element={
                        <InternalRoute>
                            <AllClients />
                        </InternalRoute>
                    }
                    path="clients"
                />
                <Route
                    exact
                    element={
                        <InternalRoute>
                            <AllEstimates />
                        </InternalRoute>
                    }
                    path="estimates"
                />
                <Route
                    exact
                    element={
                        <InternalRoute>
                            <RecurringInvoices />
                        </InternalRoute>
                    }
                    path="recurring-invoices"
                />
                <Route
                    exact
                    element={
                        <InternalRoute>
                            <AllInvoices />
                        </InternalRoute>
                    }
                    path="invoices"
                />
                <Route
                    exact
                    element={
                        <InternalRoute>
                            <AllReceipts />
                        </InternalRoute>
                    }
                    path="receipts"
                />

                <Route
                    element={
                        <InternalRoute>
                            <Products />
                        </InternalRoute>
                    }
                    path="items/products"
                />

                <Route
                    element={
                        <InternalRoute>
                            <Services />
                        </InternalRoute>
                    }
                    path="items/services"
                />
                <Route
                    element={
                        <InternalRoute>
                            <Taxes />
                        </InternalRoute>
                    }
                    path="items/taxes"
                />
                <Route
                    element={
                        <InternalRoute>
                            <Discounts />
                        </InternalRoute>
                    }
                    path="items/discounts"
                />
                <Route
                    element={
                        <InternalRoute>
                            <Tags />
                        </InternalRoute>
                    }
                    path="items/tags"
                />

                {/* Keep this at the bottom of "items/" URLs as a catch all */}
                <Route
                    element={
                        <InternalRoute>
                            <Products />
                        </InternalRoute>
                    }
                    path="items"
                />
                <Route
                    element={
                        <InternalRoute>
                            <PaymentMethods />
                        </InternalRoute>
                    }
                    path="settings/payment-methods"
                />
                <Route
                    element={
                        <InternalRoute>
                            <PaymentMethods />
                        </InternalRoute>
                    }
                    path="settings/payment-methods/stripe"
                />
                <Route
                    element={
                        <InternalRoute>
                            <DocumentDefaults />
                        </InternalRoute>
                    }
                    path="settings/document-defaults"
                />
                <Route
                    element={
                        <InternalRoute>
                            <Subscription />
                        </InternalRoute>
                    }
                    path="settings/subscription"
                />
                <Route
                    element={
                        <InternalRoute>
                            <Settings />
                        </InternalRoute>
                    }
                    path="settings"
                />
                <Route
                    element={
                        <InternalRoute>
                            <Activity />
                        </InternalRoute>
                    }
                    path="activity"
                />
                <Route
                    element={
                        <InternalRoute>
                            <Notifications />
                        </InternalRoute>
                    }
                    path="notifications"
                />
                <Route
                    element={
                        <InternalRoute>
                            <QuickCheckout />
                        </InternalRoute>
                    }
                    path="quick-checkout"
                />
                <Route element={<Checkout />} path="checkout" />
                <Route element={<Update />} path="update" />
                <Route element={<ThankYou />} path="thankyou" />
                <Route
                    element={<AccountDeletionConfirmation />}
                    path="account-deletion"
                />
                <Route
                    element={
                        <InternalRoute>
                            <Preview />
                        </InternalRoute>
                    }
                    path="preview/:documentId"
                />
                <Route
                    element={
                        <InternalRoute>
                            <PreviewRecurring />
                        </InternalRoute>
                    }
                    path="preview-recurring/:documentId"
                />
                <Route
                    element={
                        <InternalRoute>
                            <Invoice />
                        </InternalRoute>
                    }
                    path="invoice/:documentId"
                />
                <Route
                    element={
                        <InternalRoute>
                            <MakeEstimate />
                        </InternalRoute>
                    }
                    path="estimate/:documentId"
                />
                <Route
                    element={
                        <InternalRoute>
                            <MakeEstimate />
                        </InternalRoute>
                    }
                    path="estimate"
                />
                <Route
                    element={
                        <InternalRoute>
                            <MakeReceipt />
                        </InternalRoute>
                    }
                    path="receipt/:documentId"
                />
                <Route
                    element={
                        <InternalRoute>
                            <MakeReceipt />
                        </InternalRoute>
                    }
                    path="receipt"
                />
                <Route
                    element={
                        <InternalRoute>
                            <RecurringInvoice />
                        </InternalRoute>
                    }
                    path="recurring-invoice/:documentId"
                />
                <Route
                    element={
                        <InternalRoute>
                            <RecurringInvoice />
                        </InternalRoute>
                    }
                    path="recurring-invoice"
                />
                <Route
                    element={
                        <InternalRoute>
                            <MakeInvoice />
                        </InternalRoute>
                    }
                    path="/*"
                />
            </Routes>
        </>
    );
}

export default App;
