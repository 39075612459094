import { useSelector } from 'react-redux';

export const usePaymentConnections = () => {
    const activeBusiness = useSelector((state) => state.user?.activeBusiness);

    return {
        preferredPaymentConnections:
            activeBusiness?.businessSettings?.preferredPaymentConnections || {},
        connections: activeBusiness?.connections || []
    };
};
