import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, TextField, Typography } from '@mui/material';
import { setQrCodeURL } from '@/actions';
import { ImageUploadButton } from '@/components/common';
import { ErrorExclamation } from '@/resources/icons';
import useStyles from '../styles';

const UploadStep = ({
    children,
    merchant,
    loadQrReader,
    setQRCodeReaderPromise,
    showError,
    updateZelleTextfields,
    zelleTextfields
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const qrCodeURL = useSelector((state) => state?.qrUpload?.imageURL);
    const uploadLabel = `Upload ${merchant.name} Barcode`;

    const handleTextfieldInput = (value, key) => {
        updateZelleTextfields(value, key);
    };

    return (
        <>
            {merchant.slug === 'zelle' && (
                <>
                    <Grid item>
                        <Typography variant="body2">
                            Enter the email address or phone number linked with
                            your{' '}
                            <span className={classes.zelleText}>
                                Zelle account
                            </span>
                        </Typography>
                    </Grid>

                    <Grid item>
                        <TextField
                            fullWidth
                            inputProps={{ 'aria-label': 'Email Address' }}
                            label="Email Address"
                            defaultValue={zelleTextfields.emailAddress}
                            onChange={(e) =>
                                handleTextfieldInput(
                                    e.target.value,
                                    'emailAddress'
                                )
                            }
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            fullWidth
                            inputProps={{
                                'aria-label': 'Phone Number'
                            }}
                            label="Phone Number"
                            onChange={(e) => {
                                const regex = /^[0-9\b]+$/;
                                if (
                                    e.target.value === '' ||
                                    regex.test(e.target.value)
                                ) {
                                    handleTextfieldInput(
                                        e.target.value,
                                        'phoneNumber'
                                    );
                                }
                            }}
                            value={zelleTextfields.phoneNumber}
                        />
                    </Grid>
                </>
            )}

            <ImageUploadButton
                imageAlt="Uploaded QR Code"
                imageSrc={qrCodeURL}
                label={uploadLabel}
                onDelete={() => {
                    dispatch(setQrCodeURL(''));
                    setQRCodeReaderPromise(null);
                }}
                loadQrReader={loadQrReader}
                qrMerchant={merchant.name}
            />
            {showError && (
                <Grid item container alignItems="center" spacing={1}>
                    <Grid item>
                        <ErrorExclamation className={classes.errorIcon} />
                    </Grid>
                    <Grid item xs>
                        <Typography color="error" variant="body2">
                            QR code did not scan successfully, please try a
                            different image.
                        </Typography>
                    </Grid>
                </Grid>
            )}
            <Grid item>{children}</Grid>
        </>
    );
};

UploadStep.propTypes = {
    children: PropTypes.node.isRequired,
    merchant: PropTypes.object.isRequired,
    loadQrReader: PropTypes.func,
    setQRCodeReaderPromise: PropTypes.func,
    showError: PropTypes.bool,
    updateZelleTextfields: PropTypes.func.isRequired,
    zelleTextfields: PropTypes.object.isRequired
};

UploadStep.defaultProps = {
    loadQrReader: () => {},
    setQRCodeReaderPromise: () => {},
    showError: false
};

export default UploadStep;
