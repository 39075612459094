import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    accordionContainer: {
        border: `1px solid ${theme.palette.actionColors.tags}`,
        borderRadius: 3,
        backgroundColor: theme.palette.common.white,
        marginBottom: `0 !important`,
        '&::before': {
            backgroundColor: 'transparent'
        }
    },
    noContentPointer: {
        pointerEvents: 'none'
    },
    expandIcon: {
        width: 30,
        height: 30
    },
    noContentExpandIcon: {
        width: 30,
        height: 30,
        visibility: 'hidden'
    },
    accordionTitle: {
        fontSize: theme.typography.pxToRem(26),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            marginRight: theme.spacing(1),
            fontSize: theme.typography.pxToRem(20)
        }
    },
    previewButton: {
        backgroundColor: theme.palette.common.lightGray,
        color: '#334155',
        minWidth: 'fit-content',
        fontSize: theme.typography.pxToRem(12),
        padding: '3px 10px',
        [theme.breakpoints.up('md')]: {
            padding: '6px 10px'
        }
    },
    iconHeight: {
        width: 24,
        height: 24
    },
    iconStroke: {
        display: 'flex',
        justifyContent: 'center',
        '& svg > path': {
            stroke: theme.palette.imBrand.midBlue,
            strokeWidth: 3
        }
    },
    addButton: {
        whiteSpace: 'nowrap',
        width: 200,
        pointerEvents: 'auto',
        marginRight: theme.spacing(3),
        '&:hover > *': {
            '& svg > path': {
                stroke: theme.palette.imBrand.midBlueHover,
                strokeWidth: 3
            }
        }
    },
    smScreenButton: {
        height: 40,
        width: 40,
        borderRadius: 100,
        pointerEvents: 'auto',
        marginRight: theme.spacing(1),
        '& svg > path': {
            stroke: theme.palette.imBrand.midBlue,
            strokeWidth: 3
        },
        '&:hover > svg > path': {
            stroke: theme.palette.imBrand.midBlueHover,
            strokeWidth: 3
        }
    },
    accordionDetail: ({ title }) => ({
        borderTop: title !== 'Payments' ? '1px solid #F5F5FA' : 0,
        padding:
            title === 'Payments'
                ? 0
                : `${theme.spacing(1)} ${theme.spacing(2)} 5px 11px`,
        [theme.breakpoints.up('md')]: {
            padding: title === 'Payments' ? 0 : `${theme.spacing(2)}`
        }
    }),
    upgradeButton: {
        width: 'calc(100% - 30px)',
        pointerEvents: 'auto',
        backgroundColor: '#F5F5FA',
        color: '#475569',
        border: '1px solid #E0E5EE',
        marginLeft: 30,
        [theme.breakpoints.up('sm')]: {
            width: 200,
            marginLeft: 0,
            marginRight: theme.spacing(3)
        }
    }
}));
