import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    makeInvoiceContent: {
        gridArea: 'content',
        padding: theme.spacing(2),
        overflow: 'hidden',
        [theme.breakpoints.down('xl')]: {
            minHeight: 'unset'
        }
    },
    buttonText: {
        fontWeight: 600
    },
    buttonWidth: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 175
        }
    },
    newItemButtonGrid: {
        marginBottom: 30
    },
    totalsContainer: {
        paddingTop: '7%',
        display: 'flex',
        flexDirection: 'column-reverse',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        [theme.breakpoints.only('sm')]: {
            minWidth: 325,
            maxWidth: 325,
            paddingRight: 15
        },
        [theme.breakpoints.only('md')]: {
            minWidth: 345,
            maxWidth: 345,
            paddingRight: 35
        },
        [theme.breakpoints.up('lg')]: {
            minWidth: '48%',
            maxWidth: '48%',
            paddingRight: 35
        }
    },
    upperThirdContainer: {
        marginBottom: theme.spacing(2),
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto',
        gridTemplateAreas: `
            'number'
            'logo'
            'billing'
            'terms'`,
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(4),
            gridTemplateColumns: `
                ${theme.typography.pxToRem(330)} 1fr ${theme.typography.pxToRem(
                330
            )}`,
            gridTemplateAreas: `
                'number . logo'
                'billing . logo'
                'billing . terms'`
        }
    },
    number: {
        gridArea: 'number',
        marginBottom: theme.spacing(2)
    },
    logo: {
        gridArea: 'logo',
        marginBottom: theme.spacing(2)
    },
    billContainer: {
        gridArea: 'billing',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        [theme.breakpoints.only('xs')]: {
            marginBottom: theme.spacing(2)
        }
    },
    dateContainer: {
        gridArea: 'terms',
        display: 'flex',
        alignSelf: 'start',
        alignItems: 'flex-end'
    }
}));
