import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    icon: {
        width: theme.typography.pxToRem(20),
        height: theme.typography.pxToRem(20),
        marginRight: theme.typography.pxToRem(6),
        marginLeft: '-5px',
        display: 'flex',
        '& svg': {
            width: '100%'
        },
        '& > svg > path': {
            fill: ({ active }) => !active && '#475569'
        }
    },
    label: ({ active, color }) => ({
        color: active ? color : '#475569'
    }),
    root: {
        margin: `${theme.typography.pxToRem(2)} ${theme.typography.pxToRem(
            24
        )}`,
        flexDirection: 'row',
        fontSize: theme.typography.h4.fontSize,
        opacity: 1,
        minWidth: theme.typography.pxToRem(100),
        '& .MuiTab-wrapper': {
            flexDirection: 'row',
            alignItems: 'center'
        }
    }
}));
