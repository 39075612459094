import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { dateTerms } from '@/config';

const DatePickerTerms = ({ invoiceTerms, handleTermsChange }) => (
    <FormControl fullWidth variant="filled" margin="none">
        <InputLabel id="date-picker-terms" data-testid="date-picker-terms">
            Terms
        </InputLabel>
        <Select
            disableUnderline
            displayEmpty
            labelId="date-picker-terms"
            value={invoiceTerms?.name || 'None'}
        >
            {dateTerms.map((item) => (
                <MenuItem
                    onClick={() => {
                        handleTermsChange(item);
                    }}
                    value={item.name}
                    key={item.value}
                >
                    {item.value > 1 ? `${item.value} Days` : item.name}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);

DatePickerTerms.propTypes = {
    invoiceTerms: PropTypes.shape({ name: PropTypes.string }),
    handleTermsChange: PropTypes.func
};

DatePickerTerms.defaultProps = {
    invoiceTerms: { name: 'None' },
    handleTermsChange: () => {}
};

export default DatePickerTerms;
