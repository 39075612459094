import React from 'react';
import PropTypes from 'prop-types';
import { Tab as MuiTab } from '@mui/material';
import { Link } from 'react-router-dom-v5-compat';
import useStyles from './styles';

/**
 * A wrapped MUI component to allow some smoother handling of styling
 * and active icons. Used within TabBar, but can also be used standalone.
 * @param {boom} active If this tab is active.
 * @param {string} color Colour of text, as hex.
 * @param {node} icon SVG icon.
 * @param {string} title Label for the tab.
 * @param {string} to Where the tab will navigate to on click.
 * @param {string} value How its internal state knows if it is set.
 */
const Tab = ({
    active,
    color,
    customTabStyles,
    icon,
    isSettingsPage,
    title,
    to,
    value
}) => {
    const classes = useStyles({ active, color });
    return (
        <MuiTab
            label={
                <>
                    {isSettingsPage && (
                        <span className={classes.icon}>{icon}</span>
                    )}
                    <span className={classes.label}>{title}</span>
                </>
            }
            component={Link}
            disableRipple
            disableFocusRipple
            className={isSettingsPage ? classes.root : customTabStyles}
            to={to}
            value={value}
        />
    );
};

Tab.propTypes = {
    active: PropTypes.bool,
    color: PropTypes.string,
    customTabStyles: PropTypes.string,
    icon: PropTypes.node,
    isSettingsPage: PropTypes.bool,
    title: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
};

Tab.defaultProps = {
    active: false,
    color: '#656B7D',
    customTabStyles: '',
    icon: null,
    isSettingsPage: true
};

export default Tab;
