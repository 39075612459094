import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { Link as LinkRoute } from 'react-router-dom-v5-compat';
import useStyles from './styles';

const ExternalLink = ({ href, disableUnderline, classes, children }) => {
    const style = useStyles(disableUnderline);
    return (
        <Link
            href={href}
            target="_blank"
            rel="noopener"
            color="primary"
            className={style.inlineLink}
            classes={{ root: classes }}
        >
            {children}
        </Link>
    );
};

ExternalLink.propTypes = {
    href: PropTypes.string.isRequired,
    disableUnderline: PropTypes.bool,
    classes: PropTypes.string,
    children: PropTypes.string.isRequired
};

ExternalLink.defaultProps = {
    disableUnderline: true,
    classes: ''
};

const RouterLink = ({ classes, href, disableUnderline, children }) => {
    const style = useStyles(disableUnderline);
    return (
        <LinkRoute to={href} className={`${style.inlineLink} ${classes}`}>
            {children}
        </LinkRoute>
    );
};

RouterLink.propTypes = {
    href: PropTypes.string.isRequired,
    disableUnderline: PropTypes.bool,
    classes: PropTypes.string,
    children: PropTypes.string.isRequired
};

RouterLink.defaultProps = {
    disableUnderline: false,
    classes: ''
};

const LinkStyledButton = ({ disableUnderline, classes, children, onClick }) => {
    const style = useStyles(disableUnderline);

    return (
        <Button
            disableRipple
            disableFocusRipple
            disableTouchRipple
            variant="text"
            color="primary"
            onClick={onClick}
            className={style.inlineLink}
            classes={{ root: classes }}
        >
            {children}
        </Button>
    );
};

LinkStyledButton.propTypes = {
    disableUnderline: PropTypes.bool,
    classes: PropTypes.string,
    children: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

LinkStyledButton.defaultProps = {
    disableUnderline: false,
    classes: ''
};

/**
 *
 * @param {string} href A link, both external links and internal routes can be passed.
 * @param {func} onClick Action to perform when the button is clicked.
 * @param {bool} disableUnderline Hides underline styling. Useful when rendering as Button that looks like a link.
 * @param {string} classes Custom CSS className to pass to the component.
 * @param {string} children The text to render. Required.
 * @returns
 */

const InlineLink = ({ href, onClick, disableUnderline, classes, children }) => {
    const isExternal = /(http)/.test(href);

    return (
        <>
            {isExternal && (
                <ExternalLink href={href} disableUnderline={disableUnderline}>
                    {children}
                </ExternalLink>
            )}
            {!isExternal && href && (
                <RouterLink
                    classes={classes}
                    href={href}
                    disableUnderline={disableUnderline}
                >
                    {children}
                </RouterLink>
            )}
            {!href && (
                <LinkStyledButton
                    disableUnderline={disableUnderline}
                    classes={classes}
                    onClick={onClick}
                >
                    {children}
                </LinkStyledButton>
            )}
        </>
    );
};

InlineLink.propTypes = {
    href: PropTypes.string,
    disableUnderline: PropTypes.bool,
    onClick: PropTypes.func,
    classes: PropTypes.string,
    children: PropTypes.string.isRequired
};

InlineLink.defaultProps = {
    disableUnderline: false,
    href: undefined,
    onClick: () => {},
    classes: ''
};

export default InlineLink;
