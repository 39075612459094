import React from 'react';
import { Container, Drawer } from '@mui/material';
import PropTypes from 'prop-types';
import { useDrawers } from '@/hooks/useDrawers';
import useStyles from './styles';

/**
 * Generic common drawer wrapper to be used with all drawer components.
 * @param {boolean} open Whether the drawer should be open or not
 * @param {node} children Children component(s) to be rendered within the drawer
 * @param {boolean} permanent Option to change the drawer variant to "permanent" over the default "temporary"
 * @param {func} close Function to be ran on close of the drawer
 * @param {string} id The id of the drawer component that will appear in redux to be used
 * in determining if the backdrop of the drawer will be hidden.
 * If no id is passed, this drawer is not expected to be open with others.
 */
const UncontrolledGenericDrawer = ({
    open,
    children,
    permanent,
    close,
    id
}) => {
    const classes = useStyles();
    const { getDrawerCount, getDrawerIndex } = useDrawers();
    const drawerCount = getDrawerCount();
    const drawerIndex = getDrawerIndex(id);

    return (
        <Drawer
            anchor="right"
            variant={permanent ? 'permanent' : 'temporary'}
            open={open}
            onClose={close}
            classes={{
                root: classes.drawerRootStyles,
                paper: classes.drawerPaper
            }}
            hideBackdrop={Boolean(
                id && drawerCount > 1 && drawerIndex !== drawerCount - 1 // Backdrop shown only on last in queue/first rendered
            )}
        >
            <Container className={classes.container}>{children}</Container>
        </Drawer>
    );
};

UncontrolledGenericDrawer.propTypes = {
    open: PropTypes.bool,
    children: PropTypes.node,
    permanent: PropTypes.bool,
    close: PropTypes.func,
    id: PropTypes.string
};

UncontrolledGenericDrawer.defaultProps = {
    children: null,
    open: false,
    permanent: false,
    close: () => {},
    id: ''
};

export default UncontrolledGenericDrawer;
