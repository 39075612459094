import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    svgContainer: {
        height: 24
    },
    planSummaryTitleIcon: {
        marginRight: theme.spacing(1)
    },
    summaryItemContainer: {
        marginBottom: theme.spacing(1.5),
        [theme.breakpoints.up('sm')]: {
            marginBottom: 0
        }
    },
    summaryItemTitle: {
        marginBottom: theme.spacing(1),
        textTransform: 'capitalize',
        height: 24
    },
    summaryItemInfo: {
        fontSize: 14
    },
    summaryNextCycleDate: {
        fontSize: 14,
        backgroundColor: '#FEE2E2',
        width: 'fit-content',
        padding: theme.spacing(0.5, 1.5),
        borderRadius: 100,
        color: '#7F1D1D'
    },

    progressBarRoot: {
        height: 5,
        borderRadius: 5,
        backgroundColor: '#CBD5E1'
    },
    progressBarInner: {
        borderRadius: 5,
        backgroundColor: ({ ninetyPlusUsed }) =>
            ninetyPlusUsed ? '#EF4444' : '#4287CA'
    },
    payDetailContainer: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    updateInfoButton: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 330
        }
    },
    payInfoSubTitle: {
        fontSize: 14
    },
    buttonContainer: {
        [theme.breakpoints.down(475)]: {
            textAlign: 'center'
        }
    },
    cardDetailContainer: {
        display: 'flex'
    },
    payInfoSubText: ({ isIosSubscription }) => ({
        color: '#64748B',
        [theme.breakpoints.up('sm')]: {
            marginLeft: !isIosSubscription && theme.spacing(5)
        }
    }),
    nextBillDate: ({ isInCancelState }) => ({
        backgroundColor: isInCancelState && '#FEE2E2',
        width: isInCancelState && 'fit-content',
        padding: isInCancelState && theme.spacing(0.5, 1.5),
        borderRadius: isInCancelState && 100,
        color: isInCancelState && '#7F1D1D'
    }),
    cardIcon: {
        width: 34,
        height: 24,
        marginRight: theme.spacing(1)
    },
    paypalIcon: {
        width: 24,
        height: 24
    }
}));
