import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { CheckmarkFilled } from '@/resources/icons';
import useStyles from './styles';

const CouponAppliedBanner = ({ customStyles }) => {
    const classes = useStyles();

    const couponName = useSelector((state) => state?.couponData?.name) || '';

    return (
        <div className={`${classes.hasCouponBanner} ${customStyles}`}>
            <CheckmarkFilled className={classes.couponCodeCheckmark} />
            <Typography variant="body1" color="secondary">
                {couponName ? `${couponName} - ` : ''}Discount Applied
            </Typography>
        </div>
    );
};

CouponAppliedBanner.propTypes = {
    customStyles: PropTypes.string
};

CouponAppliedBanner.defaultProps = {
    customStyles: null
};

export default CouponAppliedBanner;
