/**
 * Takes an invoice ID and checks that it is valid.
 * @param  {string} id Internal invoice ID.
 * @return {bool} Whether it is valid or not.
 */
export const validateIdFormat = (id) => Boolean(id.match('^[A-Za-z0-9]+$'));

/**
 * Takes payment viewToken from payment page and checks it is valid.
 * @param  {string} id viewtoken ID.
 * @return {bool} Whether it is valid or not.
 */
export const validateInvoiceJwt = (id) => {
    if (!id) {
        return false;
    }
    return Boolean(id.match('^[A-Za-z0-9._-]+$'));
};
