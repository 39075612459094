import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    lineCenter: {
        display: 'flex',
        alignItems: 'center',
        padding: '0.3em 0',
        [theme.breakpoints.up('md')]: {
            padding: '1.25em'
        }
    },
    lineCenterPad: {
        display: 'flex',
        alignItems: 'center',
        padding: '0.8em',
        [theme.breakpoints.up('md')]: {
            padding: '1.25em'
        }
    },
    lineLabel: {
        marginLeft: 10,
        fontSize: 14
    },
    lineLabelBold: {
        marginLeft: 10,
        fontSize: 14,
        fontWeight: 'bold',
        color: theme.palette.common.black
    },
    eventDotWrap: {
        position: 'relative',
        lineHeight: 0
    },
    dot: {
        width: 10,
        height: 10,
        border: '1px solid #fff',
        borderRadius: '50%',
        position: 'absolute',
        top: -2,
        left: -2,
        backgroundColor: theme.palette.primary.main
    }
}));
