import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    toggleWrap: {
        marginTop: theme.spacing(2)
    },
    radioIcon: {
        color: theme.palette.common.white,
        backgroundColor: (active) =>
            active
                ? theme.palette.actionColors.send
                : theme.palette.text.secondary,
        borderRadius: 4,
        height: 24,
        width: 24,
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'center',
        marginRight: theme.typography.pxToRem(8),
        marginLeft: theme.typography.pxToRem(-4)
    },
    radioButton: {
        width: '100%'
    },
    radioGroup: {
        width: '100%'
    },
    radio: {
        width: '100%',
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        border: (active) =>
            active
                ? `1px solid ${theme.palette.primary.main}`
                : '1px solid #D9D9E1'
    },
    radioText: {
        fontSize: '1rem',
        color: theme.palette.common.black
    }
}));
