import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updateBusinessAddress } from '@/state/actions';
import { AddressInputBlock, DrawerBody } from '@/components/common';
import Button from '../../Button';
import FormHeader from '../../FormHeader';
import Warning from '../../Warning';

import useStyles from '../../styles';

const Address = ({ clickedOutside, fields, goToNextStep }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleSetAddress = (address) => {
        dispatch(updateBusinessAddress(address));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        goToNextStep();
    };

    return (
        <form className={classes.form} onSubmit={handleSubmit}>
            <DrawerBody>
                <FormHeader
                    title="Add your company information."
                    subtitle="Company Address"
                />
                <AddressInputBlock
                    address={fields?.address}
                    setAddress={handleSetAddress}
                />
                <Warning clickedOutside={clickedOutside} />
            </DrawerBody>
            <Button id="Billfrom" />
        </form>
    );
};

Address.propTypes = {
    clickedOutside: PropTypes.bool,
    fields: PropTypes.object,
    goToNextStep: PropTypes.func.isRequired
};

Address.defaultProps = { clickedOutside: false, fields: {} };

export default Address;
