import KSUID from 'ksuid';
import { setHotjarUser } from './hotjar';
import { setSentryUser } from './sentry';

/**
 * Function to create a shared session Id
 * between Hotjar and Sentry.
 */
export const activateSharedSessionId = () => {
    const ksuidFromDate = KSUID.randomSync();
    const sharedSessionId = ksuidFromDate.string;
    setHotjarUser({ sessionId: sharedSessionId });
    setSentryUser({ sessionId: sharedSessionId });
};
