import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Grid,
    useMediaQuery,
    useTheme,
    Paper,
    Divider
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { NoActivity } from '@/resources/images';
import { MarkAllRead } from '@/resources/icons';
import {
    FormSwitch,
    TableCard,
    TableMaker,
    PageEmptyState,
    Pagination,
    PageTitle,
    SEO,
    TableSkeleton,
    WrapWithPaper
} from '@/components/common';
import {
    useLocale,
    useUrlParams,
    useListingState,
    useCheckHasBusinessLoaded
} from '@/hooks';
import { NOTIFICATION_BASE_PARAM } from '@/constants';
import {
    getNotifications,
    markAllNotificationsAsRead
} from '@/modules/dataWrangler/dataWrangler';
import { Container, Layout } from '@/containers';
import useStyles from './styles';
import { dataProcessor, generateComponents } from './dataProcessors';
import tableHead from './tableHead';
import SubscriptionBanner from '../../components/SubscriptionBanner';

const ButtonAllRead = ({ params }) => {
    const classes = useStyles();

    return (
        <div className={classes.buttonWrap}>
            <Button
                fullWidth
                className={classes.markAllReadButton}
                variant="contained"
                color="primary"
                startIcon={<MarkAllRead className={classes.markAllReadSvg} />}
                onClick={async () => {
                    await markAllNotificationsAsRead();
                    getNotifications(params);
                }}
            >
                Mark all as read
            </Button>
        </div>
    );
};

ButtonAllRead.propTypes = {
    params: PropTypes.object
};

ButtonAllRead.defaultProps = {
    params: {}
};

const Notifications = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const locale = useLocale();

    const hasBusinessLoaded = useCheckHasBusinessLoaded();
    const { setUrlParams, getUrlParams } = useUrlParams();

    const {
        hasData,
        items,
        loadingItems,
        loadingPaper,
        totalPages,
        zeroResultsReturned
    } = useListingState('notifications');

    const currentUrlParams = getUrlParams({
        base: NOTIFICATION_BASE_PARAM
    });
    const stringifiedParams = JSON.stringify(currentUrlParams);

    const showingUnread = currentUrlParams?.state === 'unread';

    const setOrder = (property) => {
        const sortOrder =
            currentUrlParams?.sort?.type === property &&
            currentUrlParams?.sort?.order === 'asc'
                ? 'desc'
                : 'asc';

        const newUrlParams = {
            ...currentUrlParams,
            sort: {
                type: property,
                order: sortOrder
            }
        };

        setUrlParams({ base: NOTIFICATION_BASE_PARAM, params: newUrlParams });
    };

    const toggleShowAll = () => {
        const newUrlParams = {
            ...currentUrlParams
        };

        // If showingUnread is true, remove the state param from the url.
        if (showingUnread) {
            delete newUrlParams.state;
        } else {
            newUrlParams.state = 'unread';
        }

        setUrlParams({ base: NOTIFICATION_BASE_PARAM, params: newUrlParams });
    };

    useEffect(() => {
        if (hasBusinessLoaded) {
            getNotifications(JSON.parse(stringifiedParams));
        }
    }, [hasBusinessLoaded, stringifiedParams]);

    return (
        <Layout>
            <SEO title="Notifications" />
            <Container>
                <div
                    id="cio_message"
                    className={classes.cio_message}
                    data-testid="cio_message"
                />
                <SubscriptionBanner />

                <>
                    {!hasData && !loadingItems && (
                        <PageEmptyState
                            image={<NoActivity />}
                            content="No notifications documented"
                        />
                    )}

                    {hasData && !loadingPaper && (
                        <>
                            {!isMobile && (
                                <Paper elevation={0} variant="outlined">
                                    <Grid
                                        container
                                        direction="row"
                                        spacing={1}
                                        wrap="nowrap"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Grid item md={5}>
                                            <Grid
                                                container
                                                direction="row"
                                                spacing={1}
                                                wrap="nowrap"
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                <Grid item md={6}>
                                                    <PageTitle
                                                        icon={
                                                            <NotificationsIcon
                                                                style={{
                                                                    fill: '#2C989C'
                                                                }}
                                                            />
                                                        }
                                                        title="Notifications"
                                                    />
                                                </Grid>
                                                <Grid item md={6}>
                                                    <FormSwitch
                                                        label={
                                                            showingUnread
                                                                ? 'Show all'
                                                                : 'Show unread'
                                                        }
                                                        labelPlacement="end"
                                                        onChange={() =>
                                                            toggleShowAll()
                                                        }
                                                        checked={!showingUnread}
                                                        labelWidth="100"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <ButtonAllRead
                                                params={currentUrlParams}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <TableMaker
                                        actionCols={[]}
                                        ariaTableName="Notifications Table"
                                        handleRequestSort={setOrder}
                                        headCells={tableHead}
                                        rowData={
                                            hasData && !zeroResultsReturned
                                                ? generateComponents({
                                                      array: dataProcessor(
                                                          items
                                                      ),
                                                      classes,
                                                      locale
                                                  })
                                                : []
                                        }
                                        order={
                                            currentUrlParams?.sort?.order ||
                                            'asc'
                                        }
                                        orderBy={
                                            currentUrlParams?.sort?.type || ''
                                        }
                                    />
                                    {zeroResultsReturned && !loadingItems && (
                                        <PageEmptyState
                                            image={<NoActivity />}
                                            content="No notifications to show."
                                            hidePaper
                                        />
                                    )}
                                    <Pagination
                                        base={NOTIFICATION_BASE_PARAM}
                                        totalPages={totalPages}
                                    />
                                </Paper>
                            )}

                            {isMobile && (
                                <>
                                    <Grid
                                        container
                                        direction="row"
                                        spacing={1}
                                        wrap="nowrap"
                                        alignItems="center"
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            spacing={1}
                                            wrap="nowrap"
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <Grid item md={6}>
                                                <PageTitle
                                                    icon={
                                                        <NotificationsIcon
                                                            style={{
                                                                fill: '#2C989C'
                                                            }}
                                                        />
                                                    }
                                                    title="Notifications"
                                                />
                                            </Grid>
                                            <Grid item md={6}>
                                                <FormSwitch
                                                    label={
                                                        showingUnread
                                                            ? 'Show all'
                                                            : 'Show unread'
                                                    }
                                                    labelPlacement="end"
                                                    onChange={() =>
                                                        toggleShowAll()
                                                    }
                                                    checked={!showingUnread}
                                                    labelWidth="100"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid container justifyContent="center">
                                        <Grid
                                            item
                                            container
                                            spacing={1}
                                            xs={12}
                                            direction="row"
                                            wrap="nowrap"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item xs={6}>
                                                <ButtonAllRead
                                                    params={currentUrlParams}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {hasData &&
                                        !zeroResultsReturned &&
                                        generateComponents({
                                            array: dataProcessor(items),
                                            classes,
                                            locale
                                        }).map((item) => (
                                            <TableCard
                                                key={item.id}
                                                left={
                                                    <>
                                                        {item.notificationType}
                                                        {item.parentType}
                                                    </>
                                                }
                                                right={item.createdAt}
                                                full={item.markasread}
                                            />
                                        ))}
                                    {zeroResultsReturned && !loadingItems && (
                                        <PageEmptyState
                                            image={<NoActivity />}
                                            content="No notifications to show."
                                        />
                                    )}
                                    <Paper elevation={0} variant="outlined">
                                        <Pagination
                                            base={NOTIFICATION_BASE_PARAM}
                                            totalPages={totalPages}
                                        />
                                    </Paper>
                                </>
                            )}
                        </>
                    )}
                    {loadingPaper && (
                        <WrapWithPaper removeWrap={isMobile}>
                            <TableSkeleton isMobile={isMobile} />
                        </WrapWithPaper>
                    )}
                </>
            </Container>
        </Layout>
    );
};

export default Notifications;
