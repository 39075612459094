import { useSelector } from 'react-redux';

/**
 * A hook to get the drawers state from the redux store, and some helper functions.
 *
 * @returns {Object} drawers - The drawers state from the redux store.
 * @returns {Function} getDrawer - A function to get a drawer by id.
 * @returns {Function} getDrawerCount - A function to get the total number of drawers.
 * @returns {Function} getDrawerIndex - A function to get the index of a drawer by id.
 * @returns {Function} isDrawerActive - A function to check if a drawer is active.
 * @example
 *
 * const { drawers, getDrawer, getDrawerCount, getDrawerIndex, isDrawerActive } = useDrawers();
 *
 * const drawer = getDrawer('product');
 * const drawerCount = getDrawerCount();
 * const drawerIndex = getDrawerIndex('product');
 * const isActive = isDrawerActive('product');
 * const drawer = drawers[0];
 * const drawer = drawers.find(({ id }) => id === 'product');
 */
export const useDrawers = () => {
    const drawers = useSelector((state) => state?.drawers) || [];

    const getDrawer = (drawer) => drawers?.find(({ id }) => id === drawer);

    const getDrawerCount = () => drawers?.length || 0;

    const getDrawerIndex = (id) =>
        drawers?.findIndex((drawer) => drawer?.id === id);

    const isDrawerActive = (drawer) =>
        Boolean(drawers?.find(({ id }) => id === drawer));

    return {
        drawers,
        getDrawer,
        getDrawerCount,
        getDrawerIndex,
        isDrawerActive
    };
};
