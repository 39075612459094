import React from 'react';
import { formatCurrency, toWholeCurrency } from '@/util';
import {
    MobileItemTags,
    TableActionsWrap,
    TagsIcon
} from '@/components/common';
import { openDrawer } from '@/state/actions';
import store from '@/state/store';

// Process array of objects into a stripped down version
// that only has required data so is faster to process.
export const dataProcessor = (data) =>
    data.map(({ productId, name, description, price, tags }) => ({
        id: productId,
        productName: name,
        description,
        price: toWholeCurrency(price),
        tags
    }));

export const generateComponents = ({
    array,
    currency,
    locale,
    classes,
    isMobile
}) => {
    const { items } = store.getState().listing;

    const handleClick = (id) => {
        const product = items?.find((item) => item.productId === id);

        if (product) {
            store.dispatch(
                openDrawer({
                    id: 'product',
                    product,
                    edit: true
                })
            );
        }
    };

    return array.map(({ id, productName, description, price, tags }) => ({
        id,
        productName,
        description,
        price: (
            <span className={classes.priceSpan}>
                {formatCurrency({
                    currency,
                    amount: price,
                    locale
                })}
            </span>
        ),
        tags: isMobile ? (
            <MobileItemTags type="product" itemId={id} tags={tags} />
        ) : (
            <TagsIcon type="product" itemId={id} tags={tags} />
        ),
        actions: productName && (
            <TableActionsWrap
                id={id}
                open="editProduct"
                rest={['deleteProduct']}
                toPass={{ product: productName }}
            />
        ),

        primaryClick: () => handleClick(id)
    }));
};
