/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { Alert, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import { v4 as generateUuid } from 'uuid';

import {
    addProductToDocument,
    clearTemporaryStorage,
    resetDocument,
    saveNotes,
    setDueDate,
    setDocumentState,
    setDocumentTerms,
    closeAllDrawers,
    openDrawer
} from '@/state/actions';

import { dateTerms, paymentMethods } from '@/config';
import { DocumentSkeleton } from '@/components/common';

import {
    useDocumentFormData,
    useTemporaryStorage,
    useShowInternationalOptions,
    useDrawers,
    useSubscription
} from '@/hooks';

import {
    addDaysToDate,
    formatAddress,
    formatContactDetails,
    formatPhoneNumber
} from '@/util';

import DocumentStateView from './DocumentStateView';
import useStyles from './styles';
import Sidebar from './Sidebar';
import ActivityAccordion from './ActivityAccordion';
import CheckPaymentAlert from './CheckPaymentAlert';

import BillFromDrawer from './BillFromDrawer';
import BillToDrawer from '../BillToDrawer';
import PureDocumentEditable from '../PureDocumentEditable';
// import SubscriptionBanner from '../SubscriptionBanner';
import TagsAccordion from './TagsAccordion';
import RemindersAccordion from './RemindersAccordion';
import PaymentsAccordion from './PaymentsAccordion';
import RemindersDrawer from '../ReminderDrawer';
import RecurringBlock, { RecurringBlockLocked } from './RecurringBlock';
import RecurringInvoiceCreatedTable from './RecurringInvoiceCreatedTable';

const PureDocumentLocked = loadable(() => import('../PureDocumentLocked'));

const SignUpLoginModal = loadable(() => import('@/components/Login'));
const WarnConfirmDialog = loadable(() =>
    import('@/components/common/WarnConfirmDialog')
);

// const Timeline = loadable(() => import('../Timeline'));

const MakeDocument = ({ isLoading, documentType, recurring }) => {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const isFirstMount = useRef(true);
    const sidebarVisible = useMediaQuery(theme.breakpoints.up('xl'));

    const documentId = params?.documentId || false;

    const {
        amountSent,
        activeBusinessLogo,
        balancePaid,
        balanceDue,
        billFrom,
        billTo,
        businessProfileUpdated,
        currency,
        currentInvoiceLogo,
        customLabel,
        defaultNotes,
        discount,
        documentColor,
        documentState,
        dueDate,
        formInstanceHash,
        hideStamp,
        invoiceTerms,
        issuedDate,
        locked,
        locale,
        noteContent,
        presetTerms,
        products,
        recurringInvoiceId,
        referenceNumber,
        shippingFee,
        showNotes,
        signature,
        subTotal,
        total,
        transactions,
        timezone,
        markedPaidByClientMethod
    } = useDocumentFormData(documentType);

    const voidPending = useSelector(
        (state) => state?.makeInvoice?.voidDocumentPending
    );

    const { components } = useSubscription();

    const recurringInvoicesCanBeActive = components?.recurring?.enabled;

    useEffect(() => {
        // If this is a new recurring invoice, and recurring invoices are not active,
        if (
            !isLoading &&
            recurring &&
            !documentId &&
            !recurringInvoiceId &&
            !recurringInvoicesCanBeActive
        ) {
            // Redirect to the recurring invoices page.
            navigate('/settings/subscription?ref=recurring-invoices');
        }
    }, [
        isLoading,
        documentId,
        navigate,
        recurring,
        recurringInvoiceId,
        recurringInvoicesCanBeActive
    ]);

    const selectedPaymentMethod = paymentMethods.find(
        (method) => method.slug === markedPaidByClientMethod
    );

    const markedAsPaidMethodLabel = selectedPaymentMethod?.name || '';

    // const timeline = useSelector((state) => state?.makeInvoice?.timeline) || [];

    const formatInternational = useShowInternationalOptions();
    const { isDrawerActive } = useDrawers();

    // Build User Address String (fromText)
    const billFromAddress = formatAddress({
        name: billFrom.name,
        addressObject: billFrom,
        countryName: billFrom.country?.name,
        addressFormat: billFrom.country?.addressFormat,
        formatInternational
    });

    const fromText = formatContactDetails({
        formattedAddress: billFromAddress,
        email: billFrom.email,
        phone: formatPhoneNumber({
            phoneNumber: billFrom.phone,
            formatInternational
        }),
        website: billFrom.website
    });

    // Build Client Address String (toText)
    const billToAddress = formatAddress({
        name: billTo.name2,
        companyName: billTo.name,
        addressObject: billTo,
        countryName: billTo.country?.name,
        addressFormat: billTo.country?.addressFormat,
        formatInternational
    });

    const toText = formatContactDetails({
        formattedAddress: billToAddress,
        email: billTo.email,
        phone: formatPhoneNumber({
            phoneNumber: billTo.phone,
            formatInternational
        })
    });

    const handleAddProductToInvoice = () => {
        dispatch(
            addProductToDocument({
                quantity: 1,
                description: '',
                taxes: [],
                discounts: [],
                price: 0,
                lineId: generateUuid()
            })
        );
    };

    const handleDueDateSelection = (newDueDate) => {
        dispatch(setDocumentTerms({ name: 'Custom', value: 0 }));
        dispatch(setDueDate(newDueDate.toISOString()));
    };

    const handleTermsChange = useCallback(
        (item) => {
            const newDueDate = addDaysToDate(issuedDate, item.value);
            dispatch(setDueDate(newDueDate.toISOString()));
            dispatch(
                setDocumentTerms({
                    name: item.name,
                    value: item.value
                })
            );
        },
        [dispatch, issuedDate]
    );

    const temporaryStorage = useTemporaryStorage();

    // Either reset the form, or inject the stored invoice data.
    useEffect(() => {
        // Only run on first render. Never again.
        if (isFirstMount.current) {
            const temporaryStorageDocument = temporaryStorage?.[documentType];

            dispatch(closeAllDrawers());
            if (temporaryStorageDocument && temporaryStorage?.inject) {
                dispatch(resetDocument(documentType));

                dispatch(
                    setDocumentState({
                        document: temporaryStorageDocument,
                        locked: false
                    })
                );

                dispatch(clearTemporaryStorage());
            } else {
                // Clean up invoice if coming from locked/previewed
                // to stop flash.
                dispatch(resetDocument(documentType));
                dispatch(saveNotes(defaultNotes));
            }

            isFirstMount.current = false;
        }
    }, [defaultNotes, documentType, dispatch, temporaryStorage, isFirstMount]);

    useEffect(() => {
        if (presetTerms) {
            const termsObj = dateTerms.find((o) => o.value === presetTerms);
            const newDueDate = addDaysToDate(
                new Date().toISOString(),
                termsObj.value
            );
            dispatch(setDueDate(newDueDate.toISOString()));
            dispatch(setDocumentTerms(termsObj));
        }
    }, [dispatch, presetTerms]);

    const handleSaveNotes = (newNote) => {
        const newNoteContent = newNote;
        if (noteContent !== newNote) {
            dispatch(saveNotes(newNoteContent));
        }
    };

    const billFromDrawerStatus = isDrawerActive('billFrom');

    const billToDrawerStatus = isDrawerActive('billTo');

    const clientError = useSelector(
        (state) => state?.makeInvoice?.errors?.to?.clientId
    );
    const recurringClientEmailError = useSelector(
        (state) => state?.makeInvoice?.errors?.to?.emailAddress
    );

    const companyError = useSelector(
        (state) => state?.makeInvoice?.errors?.from?.companyName?.message
    );

    useEffect(() => {
        if (clientError || companyError) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [clientError, companyError]);

    const documentTypeText =
        documentType === 'recurring-invoice' ? 'invoice' : documentType;
    const referenceNumberText =
        documentType === 'recurring-invoice'
            ? 'auto generated'
            : referenceNumber;

    // FOR SIDEBAR
    const [loadLoginModal, setLoadLoginModal] = useState(false);
    const [loginModalOptions, setLoginModalOptions] = useState({
        view: '',
        onClose: () => {},
        onSuccess: () => {},
        onNoSave: () => {}
    });

    const handleSetLoginView = (view) => {
        setLoginModalOptions({ ...loginModalOptions, view });
    };

    const handleSetLoginBlocker = (handleFunc) => {
        setLoginModalOptions({
            ...loginModalOptions,
            view: 'login',
            onSuccess: (login) => {
                if (login && locked) {
                    dispatch(resetDocument());
                    navigate('/');
                } else {
                    handleFunc();
                }
            },
            onNoSave: () => handleFunc()
        });
    };

    const [beforeActionPromptOptions, setBeforeActionPromptOptions] = useState({
        open: false,
        cancelText: 'Continue Working',
        customIcon: null,
        onCancel: () => {},
        onConfirm: () => {}
    });

    useEffect(() => {
        if (loginModalOptions.view && !loadLoginModal) {
            setLoadLoginModal(true);
        }
    }, [loginModalOptions.view, loadLoginModal]);

    return (
        <div className={classes.makeInvoiceContainer}>
            <div className={classes.makeInvoiceInnerContainer}>
                <div
                    id="cio_message"
                    className={classes.cio_message}
                    data-testid="cio_message"
                />
                <CheckPaymentAlert
                    isLoading={isLoading}
                    locked={locked}
                    method={markedAsPaidMethodLabel}
                    state={documentState}
                    beforeActionPromptOptions={beforeActionPromptOptions}
                    setBeforeActionPromptOptions={setBeforeActionPromptOptions}
                />
                {!isLoading &&
                    locked &&
                    documentState === 'active' &&
                    !recurringInvoicesCanBeActive && (
                        <Alert severity="error" style={{ marginBottom: 16 }}>
                            Recurring invoices have been paused due to your
                            subscription being inactive. Reactivate your
                            subscription to reactivate this series.
                        </Alert>
                    )}
                {locked && !isLoading && !sidebarVisible && (
                    <div className={classes.smallStateView}>
                        <DocumentStateView documentType={documentType} />
                    </div>
                )}
                {isLoading && <DocumentSkeleton />}
                {!isLoading && recurring && !locked && <RecurringBlock />}
                {!isLoading && recurring && locked && (
                    <RecurringBlockLocked locale={locale} timezone={timezone} />
                )}

                {!isLoading && !locked && (
                    <PureDocumentEditable
                        balancePaid={balancePaid}
                        businessLogo={activeBusinessLogo}
                        clientError={clientError || recurringClientEmailError}
                        companyError={companyError}
                        currency={currency}
                        customLabel={customLabel}
                        discount={discount}
                        dispatchOpenBillFromDrawer={() =>
                            dispatch(openDrawer('billFrom'))
                        }
                        dispatchOpenBillToDrawer={() =>
                            dispatch(openDrawer('billTo'))
                        }
                        documentType={documentType}
                        dueDate={dueDate}
                        fromText={
                            businessProfileUpdated || billFromDrawerStatus
                                ? fromText
                                : ''
                        }
                        handleAddProductToInvoice={handleAddProductToInvoice}
                        handleDueDateSelection={handleDueDateSelection}
                        handleSaveNotes={handleSaveNotes}
                        handleTermsChange={handleTermsChange}
                        invoiceTerms={invoiceTerms}
                        issuedDate={issuedDate}
                        locale={locale}
                        note={noteContent}
                        presetTerms={presetTerms}
                        shippingFee={shippingFee}
                        showNotes={showNotes}
                        subTotal={subTotal}
                        toText={toText}
                        key={formInstanceHash}
                    />
                )}
                {!isLoading && locked && (
                    <PureDocumentLocked
                        approvedDate={signature?.timestamp}
                        balanceDue={balanceDue}
                        balancePaid={balancePaid}
                        billFrom={businessProfileUpdated ? billFrom : {}}
                        billFromAddress={billFromAddress}
                        billTo={billTo}
                        billToAddress={billToAddress}
                        currency={currency?.code}
                        customLabel={customLabel}
                        discount={discount}
                        documentColor={documentColor}
                        documentLogo={currentInvoiceLogo}
                        documentState={documentState}
                        documentType={documentTypeText}
                        dueDate={dueDate}
                        formatInternational={formatInternational}
                        hideStamp={hideStamp}
                        issuedDate={issuedDate}
                        locale={locale}
                        note={noteContent}
                        products={products}
                        referenceNumber={referenceNumberText}
                        shippingFee={shippingFee}
                        signature={signature?.signature}
                        subTotal={subTotal}
                        total={total}
                        timezone={timezone}
                    />
                )}
            </div>
            <Grid container className={classes.bottomBlocks}>
                {!isLoading &&
                locked &&
                documentType !== 'estimate' &&
                transactions?.length > 0 ? (
                    <Grid item xs={12}>
                        <PaymentsAccordion
                            transactions={transactions}
                            hideAddPaymentButton={
                                documentType !== 'invoice' ||
                                documentState === 'paid'
                            }
                        />
                        {/* <Timeline timelineEvents={timeline} /> */}
                    </Grid>
                ) : (
                    <>
                        <BillFromDrawer open={billFromDrawerStatus} />
                        <BillToDrawer open={billToDrawerStatus} />
                    </>
                )}
                {(documentType === 'invoice' ||
                    documentType === 'recurring-invoice') && (
                    <Grid item xs={12}>
                        <RemindersAccordion locked={locked} />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <TagsAccordion locked={locked} recurring={recurring} />
                </Grid>
                {documentType === 'recurring-invoice' && amountSent > 0 && (
                    <Grid item xs={12}>
                        <RecurringInvoiceCreatedTable
                            recurringInvoiceId={recurringInvoiceId}
                        />
                    </Grid>
                )}
                {!isLoading && (customLabel || recurringInvoiceId) && (
                    <ActivityAccordion
                        parentId={recurringInvoiceId}
                        parentType={documentType}
                    />
                )}
            </Grid>
            <div className={classes.sidebar}>
                <Sidebar
                    amountPaid={balancePaid}
                    currencyCode={currency?.code}
                    currencySymbol={currency?.symbol}
                    documentType={documentType}
                    total={total}
                    locale={locale}
                    locked={locked}
                    beforeActionPromptOptions={beforeActionPromptOptions}
                    setBeforeActionPromptOptions={setBeforeActionPromptOptions}
                    handleSetLoginBlocker={handleSetLoginBlocker}
                    handleSetLoginView={handleSetLoginView}
                    loginModalOptions={loginModalOptions}
                />
            </div>
            <RemindersDrawer recurring={recurring} />
            {loadLoginModal && (
                <SignUpLoginModal
                    setLoginView={handleSetLoginView}
                    view={loginModalOptions.view}
                    onClose={loginModalOptions.onClose}
                    onSuccess={loginModalOptions.onSuccess}
                    onNoSave={loginModalOptions.onNoSave}
                    inAction
                />
            )}
            <WarnConfirmDialog
                onCancel={beforeActionPromptOptions.onCancel}
                onConfirm={beforeActionPromptOptions.onConfirm}
                confirmColor={
                    beforeActionPromptOptions.confirmColor ||
                    theme.palette.primary.main
                }
                message={beforeActionPromptOptions.message}
                cancelText={beforeActionPromptOptions.cancelText}
                confirmText={beforeActionPromptOptions.confirmText}
                open={beforeActionPromptOptions.open}
                customIcon={beforeActionPromptOptions.customIcon}
                buttonLoading={
                    voidPending || beforeActionPromptOptions.buttonLoading
                }
            />
        </div>
    );
};

MakeDocument.propTypes = {
    isLoading: PropTypes.bool,
    documentType: PropTypes.string,
    recurring: PropTypes.bool
};

MakeDocument.defaultProps = {
    isLoading: false,
    documentType: 'invoice',
    recurring: false
};

export default MakeDocument;
