import React from 'react';
import PropTypes from 'prop-types';
import { Button, Skeleton } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useCheckAuthState, useDocumentFormData } from '@/hooks';
import { useValidateReceipt } from '@/hooks/useSidebarActions';
import { setModal, closeModal } from '@/state/actions';
import { AddPaymentIcon } from '@/resources/icons';

import useStyles from '../../styles';

const handleOnClick = async ({
    receipt,
    onLoginBlocker,
    validateReceipt,
    onNegativeReceipt,
    onDraftReceipt,
    registeredUser,
    total,
    dispatch,
    setOnPaymentSuccess
}) => {
    const receiptData = await validateReceipt(receipt);

    if (!receiptData) return;

    if (total < 0) {
        onNegativeReceipt();
        return;
    }

    if (registeredUser) {
        if (receiptData?.documentState === 'draft') {
            onDraftReceipt(receiptData);
            return;
        }

        setOnPaymentSuccess(
            () => () => dispatch(closeModal('loader-manual-payment'))
        );
        dispatch(setModal({ slug: 'process-receipt-payment-options' }));
    } else {
        onLoginBlocker(() => onDraftReceipt(receiptData));
    }
};

const ReceiptAddPayment = ({
    onLoginBlocker,
    onDraftReceipt,
    onNegativeReceipt,
    setOnPaymentSuccess
}) => {
    const classes = useStyles();
    const { documentLoading, total } = useDocumentFormData();
    const { loadingUser, registeredUser } = useCheckAuthState();
    const receipt = useSelector((state) => state?.makeInvoice);
    const validateReceipt = useValidateReceipt();
    const dispatch = useDispatch();

    const loadingButton = loadingUser || documentLoading;

    return (
        <div className={classes.section}>
            <div className={classes.receiptLowerSidebarContainer}>
                {!loadingButton ? (
                    <Button
                        fullWidth
                        className={`${classes.button} ${classes.approveButton}`}
                        variant="contained"
                        size="large"
                        onClick={() =>
                            handleOnClick({
                                receipt,
                                onLoginBlocker,
                                validateReceipt,
                                onNegativeReceipt,
                                onDraftReceipt,
                                registeredUser,
                                total,
                                dispatch,
                                setOnPaymentSuccess
                            })
                        }
                        endIcon={
                            <AddPaymentIcon
                                className={classes.buttonAddPaymentSvg}
                            />
                        }
                    >
                        Add Payment
                    </Button>
                ) : (
                    <Skeleton variant="rectangular" className={classes.sizer} />
                )}
            </div>
        </div>
    );
};

ReceiptAddPayment.propTypes = {
    onLoginBlocker: PropTypes.func.isRequired,
    onDraftReceipt: PropTypes.func.isRequired,
    onNegativeReceipt: PropTypes.func.isRequired,
    setOnPaymentSuccess: PropTypes.func.isRequired
};

export default ReceiptAddPayment;
