import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, SvgIcon } from '@mui/material';
import { documentColors, tagColors } from '@/config';
import useStyles from './styles';

export const PureColorSwatch = ({ backgroundColor, color }) => (
    <circle
        cx="12"
        cy="12"
        r="9"
        fill={backgroundColor}
        stroke={backgroundColor === '#FFFFFF' ? color : backgroundColor}
    />
);

PureColorSwatch.propTypes = {
    backgroundColor: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
};

export const ColorSwatch = ({ currentColor, selectionCallback, type }) => {
    const classes = useStyles();
    const isTags = type === 'tags';

    const [selectedColor, setSelectedColor] = useState(currentColor);

    const handleColorSelect = (selected) => {
        setSelectedColor(selected.backgroundColor);
        selectionCallback(selected);
    };

    return (
        <div>
            {(isTags ? tagColors : documentColors).map((item) => (
                <div className={classes.colorSwatchItem} key={item.id}>
                    <IconButton
                        edge="start"
                        classes={{
                            edgeStart: classes.buttonEdge,
                            root: classes.button
                        }}
                        onClick={() => handleColorSelect(item)}
                        size="large"
                        data-testid={`color-swatch-button-${item.id}`}
                    >
                        <SvgIcon classes={{ root: classes.colorSwatch }}>
                            {item.backgroundColor === selectedColor && (
                                <circle
                                    cx="12"
                                    cy="12"
                                    r="11"
                                    stroke={
                                        item.backgroundColor === '#FFFFFF'
                                            ? item.stroke
                                            : item.backgroundColor
                                    }
                                    radius="2"
                                    fill="none"
                                />
                            )}

                            <PureColorSwatch
                                backgroundColor={item.backgroundColor}
                                color={item.stroke}
                            />
                        </SvgIcon>
                    </IconButton>
                </div>
            ))}
        </div>
    );
};

ColorSwatch.propTypes = {
    currentColor: PropTypes.string,
    selectionCallback: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired
};

ColorSwatch.defaultProps = {
    currentColor: '#94A3B8'
};
