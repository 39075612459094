import { VALUE_PERCENTAGE_TYPE } from '@/constants';
import { calculateIntegerPercentage } from './currency';

export const calculateProductSubtotal = (product) =>
    Math.round(product.price * product.quantity);

export const calculateProductDiscount = (product) => {
    // Percentage dicounts are always calculated from the base subtotal
    const subtotal = calculateProductSubtotal(product);

    const reducer = (accum, discount) => {
        const discountTotal =
            discount.type === VALUE_PERCENTAGE_TYPE
                ? calculateIntegerPercentage(subtotal, discount.value)
                : discount.value;

        return accum + discountTotal;
    };

    return product.discounts.reduce(reducer, 0);
};

export const calculateProductTax = (tax, product) => {
    const productSubtotal =
        calculateProductSubtotal(product) - calculateProductDiscount(product);

    return calculateIntegerPercentage(productSubtotal, tax.value);
};

export const calculateProductDiscountAmount = (discount, product) => {
    const subtotal = calculateProductSubtotal(product);

    return discount.type === VALUE_PERCENTAGE_TYPE
        ? calculateIntegerPercentage(subtotal, discount.value)
        : discount.value;
};

export const calculateProductTaxes = (product) => {
    const subtotal =
        calculateProductSubtotal(product) - calculateProductDiscount(product);

    const reducer = (accum, tax) => {
        const taxTotal = calculateIntegerPercentage(subtotal, tax.value);
        return accum + taxTotal;
    };

    return product.taxes.reduce(reducer, 0);
};

export const calculateSubtotalOfAllProducts = (products) =>
    products.reduce(
        (accum, currentProduct) =>
            accum + calculateProductSubtotal(currentProduct),
        0
    );

export const calculateDiscountOfAllProducts = (products) =>
    products.reduce(
        (accum, currentProduct) =>
            accum + calculateProductDiscount(currentProduct),
        0
    );

export const calculateTaxesOfAllProducts = (products) =>
    products.reduce(
        (accum, currentProduct) =>
            accum + calculateProductTaxes(currentProduct),
        0
    );
