import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

/**
 * Creates status item to be shown in views for Invoices and Estimates. Also used
 * on the invoice view once sent/downloaded.
 * @param {string} status The status of the item. paid/unpaid/draft/void/send/approved.
 * @param {bool} removeFill Option to remove the fill of the StatusPill. Setting this to true will return a StatusPill with a white background and colored text/border.
 */
const StatusPill = ({ status, padding, inline, removeFill }) => {
    const statusSlug = status.toLowerCase();
    const classes = useStyles({
        status: statusSlug,
        padding,
        inline,
        removeFill
    });

    return <span className={classes.span}>{status}</span>;
};

StatusPill.propTypes = {
    status: PropTypes.string.isRequired,
    padding: PropTypes.bool,
    inline: PropTypes.bool,
    removeFill: PropTypes.bool
};

StatusPill.defaultProps = {
    padding: false,
    inline: false,
    removeFill: false
};

export default StatusPill;
