import loadable from '@loadable/component';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useStyles from './styles';

const SignUpLoginModal = loadable(() => import(`@/components/Login`));

const NotRegisteredBlocker = ({ activeView }) => {
    const classes = useStyles();
    const [loginView, setLoginView] = useState('');

    let buttonText;
    if (activeView === 'payment-methods') {
        buttonText = 'Login to set up electronic payments';
    } else if (activeView === 'support') {
        buttonText = 'Login to access support page';
    } else if (activeView === 'feature-request') {
        buttonText = 'Login to submit a feature request';
    } else {
        buttonText = 'Login to create custom document settings';
    }

    return (
        <div
            className={classes.notRegisteredScreenContainer}
            id="restricted-content"
        >
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={classes.gridContainer}
            >
                <Grid item xs={3} />
                <Grid
                    item
                    xs={9}
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    classes={{ container: classes.contentContainer }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.loginButton}
                        onClick={() => setLoginView('login')}
                    >
                        {buttonText}
                    </Button>
                    <Typography
                        variant="body2"
                        align="center"
                        className={classes.notRegisteredText}
                    >
                        Don&apos;t have an account?
                        <Button
                            disableRipple
                            disableFocusRipple
                            variant="text"
                            color="primary"
                            onClick={() => setLoginView('signUp')}
                            className={classes.textLink}
                        >
                            Create one here
                        </Button>
                    </Typography>
                </Grid>
            </Grid>
            <SignUpLoginModal setLoginView={setLoginView} view={loginView} />
        </div>
    );
};

NotRegisteredBlocker.propTypes = {
    activeView: PropTypes.string
};

NotRegisteredBlocker.defaultProps = {
    activeView: null
};

export default NotRegisteredBlocker;
