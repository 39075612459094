import React, { useState, useRef, useEffect } from 'react';
import { TextField, FormHelperText, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import useStyles from './styles';

export const NotesField = ({
    classOverride,
    defaultRows,
    defaultNotes,
    onBlur,
    isExpanding,
    label,
    maxCharacters,
    onChange,
    placeholder,
    value,
    useHelperText,
    id,
    isPayment
}) => {
    const fieldRef = useRef();

    const classes = useStyles({
        isExpanding
    });

    const [characterCounter, setCharacterCounter] = useState(
        defaultNotes?.length || 0
    );

    useEffect(() => {
        setCharacterCounter((defaultNotes || value)?.length || 0);
    }, [defaultNotes, value]);

    const handleCharacterCounter = (e) => {
        setCharacterCounter(e.target.value.length);
    };

    return (
        <>
            <TextField
                multiline
                fullWidth
                label={label}
                defaultValue={defaultNotes}
                id={`default-notes-${id}`}
                aria-labelledby={`default-notes-${id}`}
                className={classOverride || classes.notesTextfield}
                InputLabelProps={{ className: classes.notesLabel }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                    maxLength: maxCharacters,
                    ref: fieldRef
                }}
                minRows={defaultRows}
                // eslint-disable-next-line
                InputProps={{
                    classes: {
                        root: classes.notesTextfield,
                        input: classes.innerInput
                    }
                }}
                onChange={(e) => {
                    handleCharacterCounter(e);
                    if (typeof onChange === 'function') {
                        onChange(e);
                    }
                }}
                placeholder={placeholder}
                onBlur={(e) => {
                    if (typeof onBlur === 'function') {
                        onBlur(e);
                    }
                }}
                value={value}
            />
            {useHelperText && (
                <Grid
                    container
                    justifyContent={isPayment ? 'space-between' : 'flex-end'}
                >
                    {isPayment && (
                        <Grid item xs={10}>
                            <FormHelperText>
                                Transaction ID, Check Number, Reference Number,
                                Etc.
                            </FormHelperText>
                        </Grid>
                    )}
                    <Grid item xs={2}>
                        <FormHelperText className={classes.notesHelperText}>
                            {`${characterCounter}/${maxCharacters}`}
                        </FormHelperText>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

const requiredPropsCheck = ({ onBlur, onChange }) => {
    if (!onBlur && !onChange) {
        return new Error(
            `One of 'onBlur' or 'onChange' is required by 'NotesField' component.`
        );
    }
    return null;
};

NotesField.propTypes = {
    classOverride: PropTypes.string,
    defaultNotes: PropTypes.string,
    defaultRows: PropTypes.number,
    isExpanding: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
    maxCharacters: PropTypes.number.isRequired,
    onBlur: requiredPropsCheck,
    onChange: requiredPropsCheck,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    useHelperText: PropTypes.bool,
    id: PropTypes.string,
    isPayment: PropTypes.bool
};

NotesField.defaultProps = {
    classOverride: '',
    defaultNotes: undefined,
    defaultRows: undefined,
    onBlur: undefined,
    onChange: undefined,
    isExpanding: true,
    placeholder: '',
    value: undefined,
    useHelperText: true,
    id: '',
    isPayment: false
};

export default NotesField;
