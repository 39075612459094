import React, { memo } from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import ButtonLoading from '../ButtonLoading';
import useStyles from './styles';

const DrawerFooter = memo(
    ({
        acceptOptions,
        cancelOptions,
        saving,
        disabled,
        submitButtonId,
        trueDisabledStyle
    }) => {
        const classes = useStyles({ trueDisabledStyle });

        return (
            <div className={classes.wrap}>
                <div className={classes.buttonWrap}>
                    <Button
                        variant="contained"
                        fullWidth
                        className={classes.cancelButton}
                        onClick={cancelOptions.action}
                        startIcon={cancelOptions.startIcon}
                    >
                        {cancelOptions.label}
                    </Button>
                </div>
                <div className={classes.buttonWrap}>
                    <ButtonLoading
                        id={submitButtonId}
                        disabled={disabled}
                        color="primary"
                        variant="contained"
                        className={classes.saveButton}
                        onClick={acceptOptions.action}
                        type={acceptOptions.type}
                        disableOnLoading
                        fullWidth
                        loading={saving}
                        endIcon={acceptOptions.endIcon}
                    >
                        {acceptOptions.label}
                    </ButtonLoading>
                </div>
            </div>
        );
    }
);

DrawerFooter.propTypes = {
    acceptOptions: PropTypes.shape({
        label: PropTypes.string,
        action: PropTypes.func,
        type: PropTypes.string,
        endIcon: PropTypes.node
    }),
    cancelOptions: PropTypes.shape({
        label: PropTypes.string,
        action: PropTypes.func,
        startIcon: PropTypes.node
    }),
    saving: PropTypes.bool,
    disabled: PropTypes.bool,
    submitButtonId: PropTypes.string,
    trueDisabledStyle: PropTypes.bool
};

DrawerFooter.defaultProps = {
    acceptOptions: {
        label: 'Accept',
        action: () => {},
        type: 'text',
        endIcon: null
    },
    cancelOptions: {
        label: 'Cancel',
        action: () => {},
        startIcon: null
    },
    saving: false,
    disabled: false,
    submitButtonId: '',
    trueDisabledStyle: false
};

export default DrawerFooter;
