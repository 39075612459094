/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Grid,
    Typography,
    MenuItem,
    Divider,
    Hidden,
    useTheme
} from '@mui/material';
import loadable from '@loadable/component';
import { useForm, FormProvider } from 'react-hook-form';
import { differenceInDays } from 'date-fns';
import { Capacitor } from '@capacitor/core';
import { TextField, PhoneNumber, Select, Switch } from '@/components/rhf-mui';
import {
    useToastNotification,
    useLocalization,
    useCurrency,
    useSubscription,
    useUrlParams
} from '@/hooks';
import {
    updateBusinessSettings,
    updateUserSettings,
    deleteLogo
} from '@/modules/dataWrangler/dataWrangler';
import { beginForgotPassword, getUserEmail } from '@/modules/loginManager';
import { emailRegexPattern, getCurrentDateString } from '@/util';
import {
    AddressInputBlock,
    ButtonLoading,
    ImageUploadButton,
    WarnConfirmDialog
} from '@/components/common';
import { SaveIcon, SubscriptionActive } from '@/resources/icons';
import styles from './styles';
import settingStyles from '../styles';

const SignUpLoginModal = loadable(() => import(`@/components/Login`));

const CompanyProfileSettings = ({ activeBusiness, userSettings }) => {
    const classes = styles();
    const theme = useTheme();
    const settingsClasses = settingStyles();
    const { triggerToast } = useToastNotification();
    const { currencies, timezones, countries } = useLocalization();
    const currency = useCurrency();
    const subscriptionData = useSubscription();
    const { getUrlParams } = useUrlParams();
    const businessUpdated = useRef(null);

    const currentDate = getCurrentDateString();
    const daysRemainingInBillingPeriod = differenceInDays(
        new Date(
            subscriptionData?.chargify?.currentPeriodEndsAt ||
                subscriptionData?.revenueCat?.expiration_at_ms
        ),
        new Date(currentDate)
    );

    const isIos = Capacitor.getPlatform() === 'ios';
    const managementUrl = subscriptionData?.rcData?.managementURL;
    const useManagementUrl = isIos && managementUrl;

    const [loginView, setLoginView] = useState('');
    const [loadLoginModal, setLoadLoginModal] = useState(false);

    const [loading, setLoading] = useState(false);
    const [loadingPasswordChange, setLoadingPasswordChange] = useState(false);
    const [defaultPhoneCountry, setDefaultPhoneCountry] = useState('');
    const [cancelAccountWarningActive, setCancelAccountWarningActive] =
        useState(false);
    const [
        mobileDeleteActiveAccountWarning,
        setMobileDeleteActiveAccountWarning
    ] = useState(false);

    const methods = useForm({
        criteriaMode: 'all',
        mode: 'onChange',
        defaultValues: {
            companyName: activeBusiness?.businessProfile?.companyName || '',
            emailAddress: activeBusiness?.businessProfile?.emailAddress || '',
            phoneNumber: activeBusiness?.businessProfile?.phoneNumber || '',
            website: activeBusiness?.businessProfile?.website || '',
            address: {
                line1: activeBusiness?.businessProfile?.address?.line1 || '',
                line2: activeBusiness?.businessProfile?.address?.line2 || '',
                country:
                    activeBusiness?.businessProfile?.address?.country || 'US',
                city: activeBusiness?.businessProfile?.address?.city || '',
                state: activeBusiness?.businessProfile?.address?.state || '',
                postalCode:
                    activeBusiness?.businessProfile?.address?.postalCode || '',
                customAddress:
                    activeBusiness?.businessProfile?.address?.customAddress ||
                    ''
            },
            currency: currency?.code || '',
            timezone: activeBusiness?.businessSettings?.timezone || '',
            localCountry: activeBusiness?.businessSettings?.country || '',
            showInternationalOptions:
                activeBusiness?.businessSettings?.showInternationalOptions ||
                false,
            enableEmailNotifications: userSettings?.enableEmailNotifications
        }
    });

    // If url params contain "success: 'true'", then we have just redirected from the Stripe connection window.
    if (!businessUpdated.current && getUrlParams()?.success === 'true') {
        const stripeConnection = activeBusiness?.connections?.find(
            (connection) => connection?.application === 'stripe'
        );

        const preferredPaymentConnections =
            activeBusiness?.businessSettings?.preferredPaymentConnections;

        // Since Stripe has just connected, we need to auto-enable any payment channel that
        // has not already been enabled
        const updatedPreferredPaymentConnections = {
            creditCard:
                preferredPaymentConnections?.creditCard ||
                stripeConnection?.connectionId,
            digitalWallet:
                preferredPaymentConnections?.digitalWallet ||
                stripeConnection?.connectionId,
            bankTransfer:
                preferredPaymentConnections?.bankTransfer ||
                stripeConnection?.connectionId,
            otherConnections: preferredPaymentConnections?.otherConnections
        };

        updateBusinessSettings({
            ...activeBusiness,
            businessSettings: {
                ...activeBusiness?.businessSettings,
                preferredPaymentConnections: updatedPreferredPaymentConnections
            }
        });

        businessUpdated.current = true;
    }

    useEffect(() => {
        if (loginView && !loadLoginModal) {
            setLoadLoginModal(true);
        }
    }, [loginView, loadLoginModal]);

    const {
        handleSubmit,
        setValue,
        getValues,
        reset,
        watch,
        formState: { isValid }
    } = methods;

    const activeBusinessLogo = activeBusiness?.logo;

    const onSubmit = async (data) => {
        if (loading) {
            return;
        }

        setLoading(true);

        const updatedSettings = {
            businessProfile: {
                companyName: data.companyName,
                emailAddress: data.emailAddress,
                phoneNumber: data.phoneNumber || '',
                website: data.website,
                address: data.address
            },
            businessSettings: {
                ...activeBusiness.businessSettings,
                country: data.localCountry,
                currency: data.currency,
                showInternationalOptions: data.showInternationalOptions,
                timezone: data.timezone
            },
            documentCustomizations: activeBusiness.documentCustomizations
        };

        const businessSavedSuccess = await updateBusinessSettings(
            updatedSettings
        );

        await updateUserSettings({
            enableEmailNotifications: data.enableEmailNotifications
        });

        setLoading(false);

        if (businessSavedSuccess) {
            triggerToast({
                message: 'Settings Saved',
                action: 'saved',
                icon: <SaveIcon />
            });
        }

        reset({ ...getValues() });
    };

    const handleAddress = (address) => setValue('address', address);

    const handleChangeAccountPassword = async () => {
        setLoadingPasswordChange(true);
        const userEmail = await getUserEmail();

        try {
            // No need to await this, as we want to show the message
            // anyway. Currently don't have error handling in the unlikely
            // case it breaks - so nothing to wait it for.
            beginForgotPassword(userEmail);
            setLoginView('passwordInstructionsSent');
            setLoadingPasswordChange(false);
        } catch (err) {
            setLoadingPasswordChange(false);
        }
    };

    const handleCancelAccountClick = async () => {
        // If a user has not subscribed or has already cancelled their account
        // Open cancellation portal
        if (
            subscriptionData?.chargify?.cancelAtEndOfPeriod ||
            subscriptionData?.productId === 'free' ||
            subscriptionData?.productId === 'visitor'
        ) {
            setLoginView('deleteAccount');
            return;
        }

        // If a user has signed up for an account via in-app purchases
        // And they have not yet cancelled their account (autoRenew is still true)
        // Open active mobile account warning modal
        if (
            subscriptionData?.subscriptionSource === 'revenueCat' &&
            subscriptionData?.autoRenew
        ) {
            setMobileDeleteActiveAccountWarning(true);
            return;
        }

        // If a user has an active account and has not yet canceled
        // Open warning modal to show the user how many days they have left in their current period
        setCancelAccountWarningActive(true);
    };

    return (
        <>
            <FormProvider
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...methods}
            >
                {/* First Section */}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid
                        item
                        container
                        className={settingsClasses.sectionContainerMobile}
                    >
                        <Grid
                            container
                            spacing={2}
                            className={classes.companyProfileContainer}
                        >
                            <Grid item xs={12}>
                                <Typography
                                    component="h2"
                                    color="textSecondary"
                                    variant="body1"
                                    id="company-information"
                                    gutterBottom
                                >
                                    Company Information
                                </Typography>

                                <Divider
                                    className={
                                        settingsClasses.genericSettingsDivider
                                    }
                                />
                            </Grid>

                            {/* Company Information */}
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                                container
                                className={classes.leftColumnContainer}
                                spacing={2}
                            >
                                <Grid item xs={12}>
                                    <TextField
                                        name="companyName"
                                        label="Company Name"
                                        rules={{
                                            required: 'Company name is required'
                                        }}
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="emailAddress"
                                            label="Company Email"
                                            rules={{
                                                pattern: {
                                                    value: emailRegexPattern,
                                                    message:
                                                        'Must be a valid email'
                                                }
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <PhoneNumber
                                        name="phoneNumber"
                                        label="Phone Number"
                                        defaultCountry={
                                            defaultPhoneCountry ||
                                            getValues('address.country')
                                        }
                                        defaultValue={getValues('phoneNumber')}
                                        setDefaultPhoneCountry={
                                            setDefaultPhoneCountry
                                        }
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        name="website"
                                        label="Company Website"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>

                            {/* Logo */}
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                                container
                                className={classes.rightColumnContainer}
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.logoContainer}
                                >
                                    <ImageUploadButton
                                        imageAlt="logo"
                                        imageSrc={activeBusinessLogo}
                                        label="Logo"
                                        onDelete={deleteLogo}
                                        uploadPrompt="Company Logo"
                                        isLogo
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Second Section */}
                    <Grid
                        item
                        container
                        className={settingsClasses.sectionContainerMobile}
                    >
                        <Grid
                            container
                            className={classes.companyProfileContainer}
                            spacing={2}
                        >
                            <Grid item xs={12}>
                                <Typography
                                    component="h2"
                                    color="textSecondary"
                                    variant="body1"
                                    id="company-address"
                                >
                                    Company Address
                                </Typography>

                                <Divider className={classes.settingsDivider} />
                            </Grid>

                            {/* Company Address */}
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                                container
                                className={classes.leftColumnContainer}
                                spacing={2}
                            >
                                {/* Address Block */}
                                <AddressInputBlock
                                    address={watch('address')}
                                    setAddress={handleAddress}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Third Section */}
                    <Grid
                        item
                        container
                        className={settingsClasses.sectionContainerMobile}
                    >
                        {/* Localization Section */}
                        <Grid
                            container
                            className={classes.companyProfileContainer}
                            spacing={2}
                        >
                            <Grid item xs={12}>
                                <Typography
                                    component="h2"
                                    color="textSecondary"
                                    variant="body1"
                                    id="company-settings"
                                >
                                    Localization
                                </Typography>

                                <Divider className={classes.settingsDivider} />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={8}
                                container
                                spacing={2}
                                className={classes.leftColumnContainer}
                            >
                                <Grid item xs={12} lg={6}>
                                    <Select
                                        name="localCountry"
                                        variant="filled"
                                        label="Country"
                                        fullWidth
                                    >
                                        {countries.map((item) => (
                                            <MenuItem
                                                key={item.code}
                                                value={item.code}
                                                name={item.name}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Select
                                        name="currency"
                                        variant="filled"
                                        label="Currency"
                                        fullWidth
                                    >
                                        {currencies.map((item) => (
                                            <MenuItem
                                                key={item?.code}
                                                value={item?.code}
                                                name={item?.code}
                                            >
                                                {item?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Select
                                        name="timezone"
                                        variant="filled"
                                        label="Time Zone"
                                        fullWidth
                                    >
                                        {timezones.map((item) => (
                                            <MenuItem
                                                key={item?.name}
                                                value={item?.name}
                                                name={item?.name}
                                            >
                                                {item?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    className={classes.blankGrid}
                                />

                                <Grid item xs={12} md={12} lg={6}>
                                    <Switch
                                        label="Show international phone numbers and addresses formatting"
                                        name="showInternationalOptions"
                                        id="showInternationalOptions"
                                        color="secondary"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Fourth Section */}
                    <Grid
                        item
                        container
                        className={settingsClasses.sectionContainerMobile}
                    >
                        {/* User Section */}
                        <Grid
                            container
                            className={classes.companyProfileContainer}
                            spacing={2}
                        >
                            <Grid item xs={12}>
                                <Typography
                                    component="h2"
                                    color="textSecondary"
                                    variant="body1"
                                    id="company-settings"
                                >
                                    Notifications
                                </Typography>

                                <Divider className={classes.settingsDivider} />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={8}
                                container
                                spacing={2}
                                className={classes.leftColumnContainer}
                            >
                                <Grid item xs={12} md={12} lg={6}>
                                    <Switch
                                        label="Email Notifications"
                                        name="enableEmailNotifications"
                                        id="enableEmailNotifications"
                                        color="secondary"
                                        helperText="Receive email notifications when a customer interacts with a document."
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        lg={4}
                        className={settingsClasses.saveButtonContainer}
                    >
                        <Grid
                            item
                            xs={12}
                            className={classes.accountSaveButtonContainer}
                        >
                            <ButtonLoading
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="primary"
                                label="submitButton"
                                loading={loading}
                                disabled={!isValid}
                                classes={{
                                    root: settingsClasses.saveButton,
                                    disabled: settingsClasses.saveButtonDisabled
                                }}
                            >
                                Save Changes
                            </ButtonLoading>
                        </Grid>
                    </Grid>
                </form>
            </FormProvider>

            <Hidden smDown implementation="css">
                <Grid item>
                    <Divider
                        className={`${classes.settingsDivider} ${classes.dividerTopPadding}`}
                    />
                </Grid>
            </Hidden>

            {/* Account Settings Buttons */}
            <Grid
                container
                item
                spacing={2}
                className={classes.accountSettingsButtonRowContainer}
                xs={12}
            >
                <Grid item xs={12} sm={4} lg={4}>
                    <ButtonLoading
                        fullWidth
                        color="grey"
                        variant="outlined"
                        disableOnLoading
                        loading={loadingPasswordChange}
                        className={classes.accountSettingsButton}
                        onClick={handleChangeAccountPassword}
                    >
                        Change Account Password
                    </ButtonLoading>
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                    <Button
                        fullWidth
                        color="grey"
                        variant="outlined"
                        className={classes.accountSettingsButton}
                        onClick={() => setLoginView('changeAccountEmail')}
                    >
                        Change Account Email
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4} lg={4}>
                    <Button
                        fullWidth
                        variant="outlined"
                        className={classes.accountDeletionButton}
                        onClick={handleCancelAccountClick}
                    >
                        Delete My Account
                    </Button>
                </Grid>
            </Grid>
            {loadLoginModal && (
                <SignUpLoginModal
                    setLoginView={setLoginView}
                    view={loginView}
                />
            )}
            {cancelAccountWarningActive && (
                <WarnConfirmDialog
                    cancelText="Continue Subscription"
                    confirmText="Yes, delete my account"
                    confirmButtonStyles={classes.cancelAccountConfirmButton}
                    customIcon={
                        <SubscriptionActive className={classes.warningIcon} />
                    }
                    message="Unused time within your billing period will not be returned and all data will be deleted permanently."
                    open
                    title={`You have ${
                        Number.isNaN(daysRemainingInBillingPeriod)
                            ? 'more'
                            : daysRemainingInBillingPeriod
                    } days remaining`}
                    onConfirm={() => {
                        setCancelAccountWarningActive(false);
                        setLoginView('deleteAccount');
                    }}
                    onCancel={() => {
                        setCancelAccountWarningActive(false);
                    }}
                />
            )}
            {mobileDeleteActiveAccountWarning && (
                <WarnConfirmDialog
                    confirmText={
                        useManagementUrl ? 'Manage Subscription' : 'Got it'
                    }
                    cancelText={useManagementUrl ? 'Got it' : null}
                    title="Cancel your subscription before deleting your account"
                    message={
                        <div
                            className={classes.mobileAccountDeletionWarningText}
                        >
                            {isIos &&
                            subscriptionData?.rcData?.managementURL ? (
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    You can check your Invoice Maker Premium
                                    subscription status or cancel automatic
                                    renewal from the Manage Subscription button
                                    below
                                </Typography>
                            ) : (
                                <>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        You can check your Invoice Maker Premium
                                        subscription status or cancel automatic
                                        renewal from the following location on
                                        an iOS device:
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        Settings &gt; Apple ID &gt;
                                        Subscriptions
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        Note: The above location varies based on
                                        the version of the operating system that
                                        you are running per device. Please
                                        contact the Apple App Store if you are
                                        in need of further assistance.
                                    </Typography>
                                </>
                            )}
                        </div>
                    }
                    open
                    onConfirm={() =>
                        useManagementUrl
                            ? window.open(managementUrl)
                            : setMobileDeleteActiveAccountWarning(false)
                    }
                    onCancel={() =>
                        useManagementUrl
                            ? setMobileDeleteActiveAccountWarning(false)
                            : () => {}
                    }
                    confirmColor={theme.palette.primary.main}
                />
            )}
        </>
    );
};

CompanyProfileSettings.propTypes = {
    activeBusiness: PropTypes.object.isRequired,
    userSettings: PropTypes.object.isRequired
};

export default CompanyProfileSettings;
