import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import useStyles from './styles';

const CaptionedImage = ({ caption, imageSrc }) => {
    const classes = useStyles();

    return (
        <>
            <Grid item>
                <img
                    src={imageSrc}
                    alt={caption}
                    className={classes.imageShadow}
                />
            </Grid>
            <Grid item>
                {caption && (
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        className={classes.imageCaption}
                    >
                        {caption}
                    </Typography>
                )}
            </Grid>
        </>
    );
};

CaptionedImage.propTypes = {
    caption: PropTypes.string,
    imageSrc: PropTypes.string.isRequired
};

CaptionedImage.defaultProps = {
    caption: ''
};

export default CaptionedImage;
