import { makeStyles } from '@mui/styles';

const valueLabelColor = '#1B1E26';
const valueColor = '#35373D';

export default makeStyles((theme) => ({
    valueLabelTypography: {
        fontWeight: 600,
        fontSize: 13,
        color: valueLabelColor,
        letterSpacing: 0.1625
    },
    totalTypography: {
        fontWeight: 600,
        fontSize: 16,
        color: valueLabelColor,
        letterSpacing: 0.1625
    },
    valueTypography: {
        fontWeight: 600,
        fontSize: 16,
        color: valueColor,
        letterSpacing: 0.325
    },
    totalBalanceDue: {
        fontWeight: 600,
        fontSize: 26,
        color: valueLabelColor,
        letterSpacing: 0.325,
        wordBreak: 'break-word'
    },
    gridRowItem: {
        wordBreak: 'break-word'
    },
    totalsBreakdownText: {
        marginLeft: theme.spacing(3)
    },
    totalsBreakdownTextTypography: {
        color: theme.palette.text.disabled
    },
    dividerContainer: {
        marginTop: 25,
        marginBottom: 17
    }
}));
