import loadable from '@loadable/component';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Titlebar from '@/components/Titlebar';
import Navbar from '@/components/Navbar';
import NoInternetModal from '@/components/common/NoInternetModal';
import Footer from '@/components/Footer';
import useStyles from './styles';

const CreateTagsDrawer = loadable(() =>
    import('@/components/CreateTagsDrawer')
);
const CropperModal = loadable(() => import('@/components/common/CropperModal'));
const TagsDrawer = loadable(() => import('@/components/TagsDrawer'));
const ClientDrawer = loadable(() => import('@/components/ClientDrawer'));
const ProductDrawer = loadable(() => import('@/components/ProductDrawer'));
const ServiceDrawer = loadable(() => import('@/components/ServiceDrawer'));
const TaxDrawer = loadable(() => import('@/components/TaxDrawer'));
const DiscountDrawer = loadable(() => import('@/components/DiscountDrawer'));
const ThirdPartyConnectionModal = loadable(() =>
    import('@/components/common/ThirdPartyConnectionModal')
);
const ThirdPartyDisconnectionModal = loadable(() =>
    import('@/components/common/ThirdPartyDisconnectionModal')
);

/**
 * A wrapper for internal logged in pages. Allows drop in functionality
 * for mobile menu, toasts and modals.
 */
const Layout = ({ children }) => {
    const classes = useStyles();
    const [mobileMenuActive, setMobileMenuActive] = useState(false);

    return (
        <>
            <Titlebar
                mobileMenuActive={mobileMenuActive}
                setMobileMenuActive={() =>
                    setMobileMenuActive(!mobileMenuActive)
                }
            />
            <div className={classes.appContainer}>
                <Navbar
                    handleMenuClose={() => setMobileMenuActive(false)}
                    open={mobileMenuActive}
                />
                {children}
                <CropperModal />
                <CreateTagsDrawer />
                <ClientDrawer />
                <ProductDrawer />
                <ServiceDrawer />
                <TagsDrawer />
                <TaxDrawer />
                <DiscountDrawer />
                <NoInternetModal />
                <ThirdPartyDisconnectionModal />
                <ThirdPartyConnectionModal />
            </div>
            <Footer />
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired
};

export default Layout;
