import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Button } from '@mui/material';
import {
    DisplayTextArea,
    NotesField,
    ImageUploadButton,
    DisabledInputField
} from '@/components/common/index';
import { AddIcon } from '@/resources/icons';
import LineItemEditable from '@/components/LineItem/LineItemEditable';
import DocumentNumber from '@/components/MakeDocument/DocumentNumber';
import Totals from '@/components/MakeDocument/Totals';
import DatePickerTerms from '@/components/MakeDocument/DatePickers/DatePickerTerms';
import DueDate from '@/components/MakeDocument/DatePickers/DueDate';
import { titleCase } from '@/util';
import useStyles from './styles';

const PureDocumentEditable = memo(
    ({
        balanceDue,
        businessLogo,
        clientError,
        companyError,
        currency,
        customLabel,
        discount,
        dispatchOpenBillFromDrawer,
        dispatchOpenBillToDrawer,
        documentType,
        dueDate,
        fromText,
        handleAddProductToInvoice,
        handleDueDateSelection,
        handleSaveNotes,
        handleTermsChange,
        invoiceTerms,
        issuedDate,
        locale,
        note,
        balancePaid,
        presetTerms,
        shippingFee,
        subTotal,
        toText
    }) => {
        const classes = useStyles();

        const documentTypeLabel =
            documentType === 'recurring-invoice'
                ? 'Recurring Invoice'
                : titleCase(documentType);

        return (
            <Paper
                elevation={0}
                variant="outlined"
                className={classes.makeInvoiceContent}
            >
                <div className={classes.upperThirdContainer}>
                    <div className={classes.number}>
                        <DocumentNumber
                            documentNumber={customLabel || ''}
                            documentType={documentType}
                        />
                    </div>
                    <div className={classes.logo}>
                        <ImageUploadButton
                            imageSrc={businessLogo}
                            imageAlt="Business Logo"
                            label="Add Logo"
                            isMakeDocumentPage
                        />
                    </div>
                    <div className={classes.billContainer}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <DisplayTextArea
                                    id="bill-from-text"
                                    text={fromText}
                                    label="Bill from*"
                                    lockedLabel="Bill from:"
                                    error={companyError}
                                    onClick={dispatchOpenBillFromDrawer}
                                />
                            </Grid>
                            <Grid item>
                                <DisplayTextArea
                                    id="bill-to-text"
                                    text={toText}
                                    label="Bill to*"
                                    lockedLabel="Bill to:"
                                    error={clientError}
                                    onClick={dispatchOpenBillToDrawer}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    {documentType !== 'receipt' && (
                        <div className={classes.dateContainer}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    {documentType === 'recurring-invoice' && (
                                        <DisabledInputField tooltip="Recurring invoice terms are determined by the invoice send date and the invoice terms">
                                            Terms (auto generated)
                                        </DisabledInputField>
                                    )}
                                    {documentType !== 'recurring-invoice' && (
                                        <DatePickerTerms
                                            presetTerms={presetTerms}
                                            invoiceTerms={invoiceTerms}
                                            issuedDate={issuedDate}
                                            handleTermsChange={
                                                handleTermsChange
                                            }
                                        />
                                    )}
                                </Grid>
                                <Grid item>
                                    <DueDate
                                        dueDate={dueDate}
                                        handleDueDateSelection={
                                            handleDueDateSelection
                                        }
                                        documentType={documentType}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    )}
                </div>

                <LineItemEditable />

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    className={classes.newItemButtonGrid}
                >
                    <Button
                        classes={{
                            label: classes.buttonText,
                            root: classes.buttonWidth
                        }}
                        color="primary"
                        variant="contained"
                        size="large"
                        startIcon={<AddIcon />}
                        onClick={handleAddProductToInvoice}
                    >
                        Add Item
                    </Button>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item container alignItems="flex-start" sm>
                        <Grid item xs={12} sm={8}>
                            <Grid container direction="column">
                                <NotesField
                                    label={`${documentTypeLabel} Notes`}
                                    maxCharacters={250}
                                    onChange={(event) =>
                                        handleSaveNotes(event.target.value)
                                    }
                                    value={note}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={7}
                        className={classes.totalsContainer}
                    >
                        <Totals
                            balanceDue={balanceDue}
                            balancePaid={balancePaid}
                            currency={currency?.code}
                            discount={discount}
                            locale={locale}
                            shippingFee={shippingFee}
                            subTotal={subTotal}
                            documentType={documentType}
                        />
                    </Grid>
                </Grid>
            </Paper>
        );
    }
);

PureDocumentEditable.propTypes = {
    balanceDue: PropTypes.number,
    businessLogo: PropTypes.string,
    clientError: PropTypes.string,
    companyError: PropTypes.string,
    currency: PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
        position: PropTypes.string,
        stripeEnabled: PropTypes.bool,
        stripeMin: PropTypes.number,
        symbol: PropTypes.string,
        decimalPlaces: PropTypes.number
    }),
    customLabel: PropTypes.string,
    discount: PropTypes.number,
    dispatchOpenBillFromDrawer: PropTypes.func,
    dispatchOpenBillToDrawer: PropTypes.func,
    documentType: PropTypes.string,
    dueDate: PropTypes.string,
    fromText: PropTypes.string,
    handleAddProductToInvoice: PropTypes.func,
    handleDueDateSelection: PropTypes.func,
    handleSaveNotes: PropTypes.func,
    handleTermsChange: PropTypes.func,
    invoiceTerms: PropTypes.shape({ name: PropTypes.string }),
    issuedDate: PropTypes.string,
    locale: PropTypes.string,
    note: PropTypes.string,
    balancePaid: PropTypes.number,
    presetTerms: PropTypes.number,
    shippingFee: PropTypes.number,
    subTotal: PropTypes.number,
    toText: PropTypes.string
};

PureDocumentEditable.defaultProps = {
    balanceDue: undefined,
    businessLogo: undefined,
    clientError: '',
    companyError: '',
    currency: {},
    customLabel: '',
    discount: 0,
    dispatchOpenBillFromDrawer: () => {},
    dispatchOpenBillToDrawer: () => {},
    documentType: 'invoice',
    dueDate: undefined,
    fromText: '',
    handleAddProductToInvoice: () => {},
    handleDueDateSelection: () => {},
    handleSaveNotes: () => {},
    handleTermsChange: () => {},
    invoiceTerms: {},
    issuedDate: undefined,
    locale: undefined,
    note: undefined,
    balancePaid: 0,
    presetTerms: undefined,
    shippingFee: 0,
    subTotal: 0,
    toText: ''
};

export default PureDocumentEditable;
