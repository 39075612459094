/* eslint import/no-cycle: "off"  */

import axios from 'axios';
import { API_ENDPOINT } from '@/config';

import {
    overwriteProducts as overwriteProductsAction,
    overwriteClients as overwriteClientsAction
} from '@/state/actions';
import store from '@/state/store';

import * as products from './products';

import { overwriteBusiness } from './businesses';
import { overwriteClients } from './clients';

export * from './businesses';
export * from './clients';
export * from './connections';
export * from './discounts';
export * from './document';
export * from './estimates';
export * from './files';
export * from './invoices';
export * from './localization';
export * from './lob';
export * from './logo';
export * from './notes';
export * from './notifications';
export * from './products';
export * from './receipts';
export * from './recurring-invoices';
export * from './support';
export * from './tags';
export * from './taxes';
export * from './transactions';
export * from './user';

// eslint-disable-next-line camelcase

export const getDatabaseVersionNumber = async () => {
    const response = await axios.get(`${API_ENDPOINT}/version`);
    return response?.data?.version;
};

// eslint-disable-next-line
export let getBusiness = async () => {
    const result = await overwriteBusiness();
    getBusiness = async () => result;
    return result;
};

/**
 * Overwrites the getProducts closure with a fresh
 * business function.
 * Also returns the newest business data.
 */
export const resetGetProducts = () => {
    if (store.getState().user.temporaryUser) {
        store.dispatch(overwriteProductsAction([]));

        return [];
    }
    const result = products.overwriteProducts();
    return result;
};

// eslint-disable-next-line
export let getClients = async () => {
    if (store.getState().user.temporaryUser) {
        store.dispatch(overwriteClientsAction([]));

        return [];
    }
    const result = overwriteClients();

    getClients = async () => result;

    return result;
};

/**
 * Overwrites the getClients closure.
 * Also returns the newest data.
 */
export const resetGetClients = () => {
    if (store.getState().user.temporaryUser) {
        store.dispatch(overwriteClientsAction([]));

        return [];
    }
    const result = overwriteClients();
    getClients = async () => result;
    return result;
};
