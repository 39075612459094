import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    cell: ({ thin }) => ({
        width: thin && theme.typography.pxToRem(80),
        padding: 0,
        wordBreak: 'break-word'
    }),
    link: {
        color: 'inherit',
        display: 'block',
        textDecoration: 'none',
        padding: theme.typography.pxToRem(20)
    },
    button: {
        color: 'inherit',
        cursor: 'pointer',
        outline: 0,
        backgroundColor: 'inherit',
        fontSize: 'inherit',
        textAlign: 'inherit',
        fontWeight: 'inherit',
        fontFamily: 'inherit',
        border: 0,
        display: 'block',
        height: '100%',
        textDecoration: 'none',
        padding: theme.typography.pxToRem(20),
        width: '100%'
    }
}));
