export const CLIENT_BASE_PARAM = 'cli';
export const TAG_BASE_PARAM = 'tag';
export const TAX_BASE_PARAM = 'tax';
export const INVOICE_BASE_PARAM = 'inv';
export const RECURRING_INVOICE_BASE_PARAM = 'recinv';
export const ESTIMATE_BASE_PARAM = 'est';
export const RECEIPT_BASE_PARAM = 'rec';
export const PRODUCT_BASE_PARAM = 'prod';
export const SERVICE_BASE_PARAM = 'serv';
export const DISCOUNT_BASE_PARAM = 'disc';
export const ACTIVITY_BASE_PARAM = 'act';
export const NOTIFICATION_BASE_PARAM = 'not';
