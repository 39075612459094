import { Buffer } from 'buffer';
import fileDownload from 'js-file-download';

/**
 * Converts a base64 PDF file and downloads using a package made for cross
 * browser JS downloading.
 * @param  {string} name Name of the file.
 * @param  {string} base64 File in base64 format.
 */
export const downloadPdf = ({ name, base64 }) => {
    const converted = Buffer.from(base64, 'base64');
    // Remove any preset extension of PDF.
    const cleanName = name.replace('.pdf', '');
    fileDownload(converted, `${cleanName}.pdf`);
};
