import { useDispatch, useSelector } from 'react-redux';
import { setDocumentErrors, resetDocumentErrors } from '@/state/actions';
import { createSaveableObject, verifyDocumentFields } from '@/util';

/**
 * Recurring Invoice Hook
 * Validates an invoice object.
 *
 * Returns false, or clean invoice data.
 */
export const useValidateRecurringInvoice = () => {
    const dispatch = useDispatch();
    const activeBusiness = useSelector((state) => state?.user?.activeBusiness);
    const businessProfileUpdated =
        activeBusiness?.flags?.businessProfileUpdated;

    const validateRecurringInvoice = async (invoiceToValidate) => {
        const invoiceData = createSaveableObject({
            type: 'recurring-invoice',
            currentObject: invoiceToValidate,
            activeBusiness
        });

        const verifiedResult = await verifyDocumentFields(invoiceData);

        if (!businessProfileUpdated)
            verifiedResult.errors = {
                ...verifiedResult.errors,
                from: {
                    companyName: {
                        message: 'Company is required'
                    }
                }
            };

        if (!verifiedResult.success) {
            dispatch(setDocumentErrors(verifiedResult.errors));
        } else {
            dispatch(resetDocumentErrors());
        }

        if (!verifiedResult.success) {
            return false;
        }

        return invoiceData;
    };

    return validateRecurringInvoice;
};
