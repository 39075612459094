import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import EstimateForm from '@/components/MakeEstimate';
import { Redirect, SEO } from '@/components/common';
import { Container, Layout } from '@/containers';
import { useSetIMPageType } from '@/hooks';
import { setHotjarPage } from '@/util';

const Estimate = () => {
    const params = useParams();

    const { documentLoading, redirect } = useSetIMPageType({
        params,
        documentType: 'estimate'
    });

    // Allows redirection of page if this component/URL is not correct.
    if (!documentLoading && redirect) {
        return <Redirect to={redirect} />;
    }

    setHotjarPage({ page: 'MakeEstimate' });

    return (
        <Layout>
            <Container>
                <SEO title="Make an Estimate" />
                <EstimateForm
                    documentType="estimate"
                    isLoading={documentLoading}
                />
            </Container>
        </Layout>
    );
};

export default Estimate;
