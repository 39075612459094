import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, TextField, Typography, Link } from '@mui/material';
import { setQrCodeURL } from '@/actions';
import { ButtonLoading, CaptionedImage } from '@/components/common';
import {
    removeQRCodeConnection,
    removeAllZelleConnections,
    updateBusinessSettings
} from '@/modules/dataWrangler/dataWrangler';
import QRCodeStepper from '@/components/QRCodeStepper';
import { CheckmarkFilled } from '@/resources/icons';
import { formatPhoneNumber } from '@/util';
import useStyles from './styles';

const QRCodeVerification = ({
    allConnections,
    connection,
    exampleImage,
    merchant,
    UploadInstructions
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [disconnectPending, setDisconnectPending] = useState(false);

    const activeBusiness = useSelector((state) => state?.user?.activeBusiness);

    /**
     * @param {string} type The type of Zelle connection to find. (zelleQR, zelleEmail, zellePhone)
     * @returns The connection object for that Zelle type.
     */
    const findZelle = (type) =>
        merchant.slug === 'zelle' &&
        allConnections.find((conn) => conn.application === type);

    const zelleTypes = ['zelleQR', 'zelleEmail', 'zellePhone'];

    // Returns an array of booleans. One for each Zelle type.
    // Disconnect button should be shown if any Zelle connection is connected.
    const isZelleConnected = () => {
        const allZelleConnected = zelleTypes.map((type) => {
            if (findZelle(type)?.connected && findZelle(type)?.enabled) {
                return true;
            }

            return false;
        });

        return allZelleConnected;
    };

    const connected =
        (connection.connected && connection.enabled) ||
        isZelleConnected().includes(true);

    const handleClose = () => {
        setOpen(false);
        dispatch(setQrCodeURL(''));
    };

    const handleDisconnectButton = async () => {
        setDisconnectPending(true);
        const zelleConnectionIds = [];

        if (merchant.slug === 'zelle') {
            zelleTypes.forEach((zelleConnectionType) => {
                const zelleConnection = findZelle(zelleConnectionType);

                if (zelleConnection) {
                    zelleConnectionIds.push(zelleConnection?.connectionId);
                }
            });

            await removeAllZelleConnections();
        } else {
            await removeQRCodeConnection(merchant.slug);
        }

        // If a user is disconnecting a merchant, we need to remove that merchant's connectionIds
        // from the preferredPaymentConnections object as well
        const updatedOtherConnections =
            activeBusiness?.businessSettings?.preferredPaymentConnections?.otherConnections.filter(
                (preferredConnection) =>
                    merchant.slug === 'zelle'
                        ? !zelleConnectionIds.includes(preferredConnection)
                        : preferredConnection !== connection?.connectionId
            );

        await updateBusinessSettings({
            ...activeBusiness,
            businessSettings: {
                ...activeBusiness?.businessSettings,
                preferredPaymentConnections: {
                    ...activeBusiness?.businessSettings
                        ?.preferredPaymentConnections,
                    otherConnections: updatedOtherConnections
                }
            }
        });

        setDisconnectPending(false);
    };

    return (
        <>
            <Grid
                className={classes.showOnTop}
                container
                item
                direction="column"
                spacing={3}
                wrap="nowrap"
            >
                {merchant.slug === 'zelle' && (
                    <Grid item xs={5} container direction="column" spacing={2}>
                        {findZelle('zelleEmail')?.connected && (
                            <Grid item>
                                <TextField
                                    fullWidth
                                    label="Email Address"
                                    value={findZelle('zelleEmail')?.text}
                                    className={classes.unclickableTextField}
                                />
                            </Grid>
                        )}

                        {findZelle('zellePhone')?.connected && (
                            <Grid item>
                                <TextField
                                    fullWidth
                                    label="Phone Number"
                                    value={formatPhoneNumber({
                                        phoneNumber:
                                            findZelle('zellePhone')?.text
                                                ?.length > 1 &&
                                            findZelle('zellePhone')?.text
                                                ?.length < 18
                                                ? `+1${
                                                      findZelle('zellePhone')
                                                          ?.text
                                                  }`
                                                : findZelle('zellePhone')?.text
                                    })}
                                    className={classes.unclickableTextField}
                                />
                            </Grid>
                        )}
                    </Grid>
                )}

                <Grid item>
                    <Typography
                        variant="body2"
                        className={classes.uploadInstructions}
                    >
                        {merchant.name} payments will have to be manually
                        updated after the payment is accepted to update the
                        invoice status.{' '}
                        <Link
                            className={classes.showMeHowLink}
                            color="primary"
                            href={`${merchant.supportUrl}`}
                            target="_blank"
                        >
                            Show me how.
                        </Link>
                    </Typography>
                </Grid>
                <Grid item container direction="row" spacing={2}>
                    <Grid
                        item
                        container
                        xs={12}
                        sm={4}
                        direction="column"
                        alignItems="center"
                    >
                        <CaptionedImage
                            caption={
                                !connected
                                    ? `Example ${merchant.name} code image`
                                    : undefined
                            }
                            imageSrc={
                                connection?.qrCodeURL ||
                                findZelle('zelleQR')?.qrCodeURL ||
                                exampleImage
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        {(!connected ||
                            findZelle('zelleQR')?.enabled === false) && (
                            <UploadInstructions />
                        )}
                    </Grid>

                    {connection?.enabled
                        ? findZelle('zelleQR')?.enabled && (
                              <Grid
                                  item
                                  xs={12}
                                  className={classes.qrLinkContainer}
                              >
                                  <CheckmarkFilled
                                      className={classes.checkmarkIcon}
                                  />
                                  <Link
                                      className={classes.showMeHowLink}
                                      color="primary"
                                      href={`${
                                          connection.qrCodeData ||
                                          findZelle('zelleQR')?.qrCodeData
                                      }`}
                                      target="_blank"
                                  >
                                      {connection?.qrCodeData ||
                                          findZelle('zelleQR')?.qrCodeData}
                                  </Link>
                              </Grid>
                          )
                        : null}
                </Grid>
                {connected && (
                    <Grid item container>
                        <Grid item xs={12} md={4}>
                            <ButtonLoading
                                className={classes.disconnectButton}
                                color="primary"
                                disableOnLoading
                                fullWidth
                                loading={disconnectPending}
                                onClick={handleDisconnectButton}
                                variant="contained"
                            >
                                Disconnect
                            </ButtonLoading>
                        </Grid>
                    </Grid>
                )}
            </Grid>

            <QRCodeStepper
                exampleImage={exampleImage}
                merchant={merchant}
                onClose={handleClose}
                open={open}
                UploadInstructions={UploadInstructions}
            />
        </>
    );
};

QRCodeVerification.propTypes = {
    allConnections: PropTypes.arrayOf(PropTypes.object),
    connection: PropTypes.object,
    exampleImage: PropTypes.string.isRequired,
    merchant: PropTypes.object.isRequired,
    UploadInstructions: PropTypes.func.isRequired
};

QRCodeVerification.defaultProps = {
    allConnections: [{}],
    connection: {}
};

export default QRCodeVerification;
