import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    accordionContainer: {
        width: '100%',
        border: `1px solid ${theme.palette.actionColors.tags}`,
        borderRadius: 3,
        backgroundColor: theme.palette.common.white,
        marginBottom: 0,
        '&::before': {
            backgroundColor: 'transparent'
        },
        '& .Mui-expanded .MuiAccordionSummary-contentGutters': {
            margin: '0'
        }
    },
    expandIcon: {
        width: 30,
        height: 30
    },
    iconContainer: {
        width: 24,
        height: 24
    },
    accordionTitle: {
        fontSize: theme.typography.pxToRem(26),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            marginRight: theme.spacing(1),
            fontSize: theme.typography.pxToRem(20)
        }
    },
    iconHeight: {
        width: 24,
        height: 24
    },
    iconStroke: {
        display: 'flex',
        justifyContent: 'center',
        '& svg > path': {
            stroke: theme.palette.imBrand.midBlue,
            strokeWidth: 3
        }
    },
    activityCount: {
        padding: '7px 10px',
        backgroundColor: '#F5F5FA',
        color: '#334155',
        borderRadius: 3
    },
    accordionDetail: {
        borderTop: '1px solid #F5F5FA',
        padding: 0
    },
    lineCenter: {
        display: 'flex',
        alignItems: 'center',
        padding: '1.25em'
    },
    lineDate: {
        [theme.breakpoints.up('md')]: {
            padding: '1.25em',
            textAlign: 'left'
        },
        [theme.breakpoints.down('md')]: {
            display: 'block',
            padding: '0.8em'
        },
        textAlign: 'right',
        width: 110,
        fontSize: 14
    },
    lineDateTime: {
        [theme.breakpoints.down('md')]: {
            display: 'block'
        }
    },
    lineLabel: {
        marginLeft: 10
    }
}));
