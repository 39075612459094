import React from 'react';
import PropTypes from 'prop-types';
import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    Switch
} from '@mui/material';
import useStyles from './styles';

/**
 * Common FormSwitch component to be used within forms.
 * @param {string/node} label The label for the switch. Can be a string or can be a node to allow for more customization.
 * @param {string} labelPlacement The placement of the label (i.e. "start" or "end"). Defaults to "start".
 * @param {string} name RHF name for the component to be passed.
 * @param  {func} onChange Function to be fired on click of the switch.
 * @param {bool} checked Is the switch checked or not.
 * @param {bool} disabled Option to disable the switch.
 * @param {string} color Text color for the label. References the "theme.typography.text" options.
 */
const FormSwitch = ({
    label,
    labelPlacement,
    name,
    onChange,
    checked,
    disabled,
    color,
    helperText,
    labelWidth
}) => {
    const classes = useStyles({ color });

    return (
        <FormControl fullWidth>
            <FormControlLabel
                label={
                    <span
                        style={{
                            width: `${labelWidth}px` || 'auto',
                            display: 'inline-block'
                        }}
                    >
                        {label}
                    </span>
                }
                labelPlacement={labelPlacement}
                classes={{
                    label: classes.switchLabel,
                    labelPlacementStart: classes.switchLabelPlacement
                }}
                disabled={disabled}
                control={
                    <Switch
                        name={name}
                        id={name}
                        onChange={onChange}
                        checked={checked}
                    />
                }
            />

            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
};

FormSwitch.propTypes = {
    helperText: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    labelPlacement: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    color: PropTypes.oneOf([
        'primary',
        'secondary',
        'disabled',
        'red',
        'orange'
    ]),
    labelWidth: PropTypes.string
};

FormSwitch.defaultProps = {
    helperText: '',
    label: undefined,
    labelPlacement: 'start',
    name: '',
    onChange: () => {},
    checked: false,
    disabled: false,
    color: 'primary',
    labelWidth: null
};

export default FormSwitch;
