import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    wrap: {
        alignItems: 'center',
        display: 'flex',
        marginLeft: theme.spacing(2)
    },
    icon: {
        height: theme.typography.pxToRem(24),
        marginRight: theme.typography.pxToRem(10),
        width: theme.typography.pxToRem(24)
    },
    title: {
        fontSize: theme.typography.pxToRem(26),
        fontWeight: 600,
        margin: 0
    }
}));
