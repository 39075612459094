import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    companyProfileContainer: {
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.typography.pxToRem(20)
        }
    },
    leftColumnContainer: {
        [theme.breakpoints.only('xs')]: {
            margin: 0,
            padding: '0 !important',
            order: 3
        }
    },
    rightColumnContainer: {
        [theme.breakpoints.only('xs')]: {
            order: 2,
            paddingTop: '0 !important'
        }
    },
    logoContainer: {
        [theme.breakpoints.up('sm')]: {
            transform: `translateY(${theme.typography.pxToRem(-16)})`,
            marginBottom: theme.typography.pxToRem(-14)
        }
    },
    settingsDivider: {
        backgroundColor: theme.palette.background.default,
        height: 1,
        marginTop: theme.spacing(1),
        width: '100%'
    },
    accountSaveButtonContainer: {
        [theme.breakpoints.up('sm')]: {
            paddingRight: theme.spacing(2)
        }
    },
    blankGrid: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    dividerTopPadding: {
        marginTop: theme.spacing(3)
    },
    accountSettingsButtonRowContainer: {
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(2),
            maxWidth: 'calc(100% + 16px)'
        }
    },
    accountSettingsButton: {
        backgroundColor: theme.palette.secondary.main,
        whiteSpace: 'nowrap'
    },
    accountDeletionButton: {
        borderColor: theme.palette.text.red,
        color: theme.palette.text.red,
        '&:hover': {
            borderColor: theme.palette.text.red
        }
    },
    warningIcon: {
        height: 81,
        width: 81
    },
    cancelAccountConfirmButton: {
        whiteSpace: 'nowrap',
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main
        },
        '& p': {
            color: theme.palette.primary.main
        }
    },
    mobileAccountDeletionWarningText: {
        textAlign: 'left',
        '& p': {
            marginTop: 20
        },
        '& p:first-child': {
            marginTop: 0
        }
    }
}));
