import React from 'react';
import PropTypes from 'prop-types';
import ActivityIconPicker from '@/components/common/ActivityIconPicker';
import useStyles from './styles';

const parentTypeStringToLabel = (type) => {
    // Turn dash-case into space case
    const string = type.replace(/-/g, ' ');
    // Capitalise first letter of each word
    return string.replace(/\b\w/g, (l) => l.toUpperCase());
};

const paymentTypeStringToLabel = (method) => {
    switch (method) {
        case 'venmo':
            return 'Venmo';
        case 'cashapp':
            return 'Cash App';
        case 'zelle':
            return 'Zelle';
        default:
            return method;
    }
};

const activityTypeStringToLabel = (type, method) => {
    const string = type
        .replace('recurring-', '')
        .replace('link-created', 'link created')
        .replace('marked-paid', 'marked as paid')
        .replace('marked-unpaid', 'marked as unpaid');

    if (method) {
        return `${string} with ${paymentTypeStringToLabel(method)}`;
    }

    return string;
};

const ActivityEventLine = ({
    activityType,
    parentType,
    method,
    showDot,
    alwaysPad
}) => {
    const classes = useStyles();

    return (
        <span
            className={alwaysPad ? classes.lineCenterPad : classes.lineCenter}
        >
            <span className={classes.eventDotWrap}>
                {showDot && <span className={classes.dot} />}
                <ActivityIconPicker activity={activityType} />
            </span>
            <span
                className={showDot ? classes.lineLabelBold : classes.lineLabel}
            >
                {parentTypeStringToLabel(parentType)}{' '}
                {activityTypeStringToLabel(activityType, method)}
            </span>
        </span>
    );
};

ActivityEventLine.propTypes = {
    activityType: PropTypes.string.isRequired,
    parentType: PropTypes.string.isRequired,
    method: PropTypes.string,
    showDot: PropTypes.bool,
    alwaysPad: PropTypes.bool
};

ActivityEventLine.defaultProps = {
    method: '',
    showDot: false,
    alwaysPad: false
};

export default ActivityEventLine;
