import { parsePhoneNumber } from 'libphonenumber-js';

/**
 * This function takes in a phone number data object and formats it appropriately
 *
 * @param {string} phoneNumber The phone number to be formatted
 * @param {bool} formatInternational Toggles if the phone number should be displayed in an international format
 * @returns {string} Correctly formatted phone number
 */

export const formatPhoneNumber = ({ phoneNumber, formatInternational }) => {
    try {
        if (formatInternational) {
            return parsePhoneNumber(phoneNumber).formatInternational();
        }
        return parsePhoneNumber(phoneNumber).formatNational();
    } catch (err) {
        return phoneNumber;
    }
};
