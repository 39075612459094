import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    button: {
        backgroundColor: theme.palette.actionColors.positive,
        color: '#fff',
        // Hover
        '&:hover': {
            backgroundColor: theme.palette.actionColors.positive,
            opacity: 0.8
        }
    },
    svg: {
        width: 20,
        height: 20,
        marginRight: 8
    }
}));
