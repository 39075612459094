import React, { forwardRef, useState, useRef } from 'react';
import 'react-phone-number-input/style.css';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import {
    getCountryCallingCode,
    isPossiblePhoneNumber,
    parsePhoneNumber
} from 'react-phone-number-input';
import PhoneNumberCountrySelect from '@/components/common/PhoneNumberCountrySelect';
import { useShowInternationalOptions } from '@/hooks';

const TextfieldRef = forwardRef((props, ref) => {
    const { disabled, fullWidth, label, id, name } = props;

    return (
        <TextField
            {...props}
            disabled={disabled}
            fullWidth={fullWidth}
            id={id || name}
            inputRef={ref}
            label={label}
            name={name}
            variant="filled"
        />
    );
});

TextfieldRef.propTypes = {
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    id: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
};

TextfieldRef.defaultProps = {
    disabled: false,
    fullWidth: false,
    id: '',
    value: ''
};

const PhoneNumber = ({
    defaultCountry,
    defaultValue,
    disabled,
    fullWidth,
    id,
    label,
    name,
    rules,
    setDefaultPhoneCountry
}) => {
    // Import React Hook Form functions from context.
    const {
        control,
        getValues,
        formState: { errors }
    } = useFormContext();

    const showInternationalOptions = useShowInternationalOptions();

    const [callingCode, setCallingCode] = useState(defaultCountry);
    const phoneData = parsePhoneNumber(
        defaultValue || getValues('phoneNumber') || ''
    );
    const numberRef = useRef(defaultValue);

    return (
        <PhoneInputWithCountry
            inputComponent={TextfieldRef}
            disabled={disabled}
            fullWidth={fullWidth}
            label={label}
            id={id || name}
            name={name}
            control={control}
            defaultValue={phoneData?.nationalNumber || ''}
            rules={{
                ...rules,
                validate: {
                    number: (value) =>
                        (value === undefined || value === '' || value === null
                            ? true
                            : isPossiblePhoneNumber(String(value))) ||
                        'Please enter a valid phone number'
                }
            }}
            helperText={errors?.[name]?.message}
            error={Boolean(errors?.[name]?.message)}
            defaultCountry={defaultCountry}
            international={false}
            onChange={(e) => {
                numberRef.current = e;
            }}
            initialValueFormat="national"
            countrySelectComponent={PhoneNumberCountrySelect}
            countrySelectProps={{
                defaultCountry: phoneData?.country || defaultCountry,
                setCallingCode,
                setDefaultPhoneCountry
            }}
            style={{ alignItems: 'inherit' }}
            InputProps={
                showInternationalOptions
                    ? {
                          startAdornment: (
                              <InputAdornment
                                  position="start"
                                  disablePointerEvents
                                  style={{ marginTop: 18 }}
                              >
                                  <Typography variant="body1">
                                      {`+${
                                          numberRef.current === null
                                              ? getCountryCallingCode(
                                                    defaultCountry
                                                )
                                              : phoneData?.countryCallingCode ||
                                                getCountryCallingCode(
                                                    callingCode
                                                )
                                      }`}
                                  </Typography>
                              </InputAdornment>
                          )
                      }
                    : undefined
            }
        />
    );
};

PhoneNumber.propTypes = {
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    rules: PropTypes.shape({}),
    defaultCountry: PropTypes.string,
    setDefaultPhoneCountry: PropTypes.func
};

PhoneNumber.defaultProps = {
    defaultValue: '',
    disabled: false,
    fullWidth: false,
    id: '',
    rules: null,
    defaultCountry: 'US',
    setDefaultPhoneCountry: () => {}
};

export default PhoneNumber;
