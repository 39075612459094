import React from 'react';
import PropTypes from 'prop-types';
import MakeDocument from '../MakeDocument';

const MakeInvoice = ({ isLoading, recurring }) => (
    // future logic to be passed down here
    <MakeDocument
        documentType="recurring-invoice"
        isLoading={isLoading}
        recurring={recurring}
    />
);

MakeInvoice.propTypes = {
    isLoading: PropTypes.bool,
    recurring: PropTypes.bool
};

MakeInvoice.defaultProps = {
    isLoading: false,
    recurring: false
};

export default MakeInvoice;
