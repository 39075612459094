import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    dialogPaper: {
        overflowX: 'hidden'
    },
    modal: {
        padding: theme.typography.pxToRem(16),
        [theme.breakpoints.up('sm')]: {
            padding: theme.typography.pxToRem(50)
        }
    },
    modalHeader: {
        marginBottom: theme.spacing(2)
    },
    headerIcon: {
        marginRight: theme.spacing(2)
    },
    notesField: {
        marginTop: 0
    },
    buttonContainer: {
        marginTop: theme.typography.pxToRem(10)
    },
    menuItemOptions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: theme.palette.text.secondary
    },
    paymentSelectorIcon: {
        paddingRight: 10,
        height: 21,
        '& svg': {
            width: 21,
            height: 21
        }
    },
    loginButton: {
        margin: '10px 16px',
        width: 'calc(100% - 32px)',
        whiteSpace: 'nowrap'
    },
    disabledListItem: {
        opacity: '1 !important'
    },
    previousPaymentListItem: {
        marginTop: 10
    },
    titleDivider: {
        marginTop: 0,
        marginRight: theme.typography.pxToRem(16),
        marginLeft: theme.typography.pxToRem(16),
        borderWidth: 1,
        borderColor: '#DBDFEA'
    }
}));
