import React from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener, Drawer, List } from '@mui/material';
import useStyles from './styles';

const NavWrapper = ({
    open,
    handleMenuClose,
    handleClickAwayListener,
    children
}) => {
    const classes = useStyles();

    return (
        <>
            <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={handleClickAwayListener}
            >
                <Drawer
                    anchor="left"
                    variant="temporary"
                    open={open}
                    onClose={(event, reason) => {
                        if (
                            reason === 'backdropClick' ||
                            reason === 'escapeKeyDown'
                        ) {
                            handleMenuClose();
                        }
                    }}
                    classes={{ root: classes.root, paper: classes.paper }}
                    BackdropProps={{ invisible: true }}
                >
                    <nav className={classes.navbar}>
                        <List component="ul" disablePadding>
                            {children}
                        </List>
                    </nav>
                </Drawer>
            </ClickAwayListener>
            <nav className={classes.navbarContainer}>
                <List component="ul" disablePadding className={classes.navbar}>
                    {children}
                </List>
            </nav>
        </>
    );
};

NavWrapper.propTypes = {
    open: PropTypes.bool,
    handleMenuClose: PropTypes.func.isRequired,
    handleClickAwayListener: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired
};

NavWrapper.defaultProps = {
    open: false
};

export default NavWrapper;
