import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom-v5-compat';
import { updatedocumentIds, setTemporaryStorage } from '@/state/actions';
import {
    createEstimate,
    updateEstimate
} from '@/modules/dataWrangler/dataWrangler';
import store from '@/state/store';

/**
 * Estimate Hook
 * Saves an estimate, where data is passed into the hook.
 * Handles redirection logic if estimates limited, and
 * creating or updating functionality.
 *
 * Returns false, or clean estimate data.
 */
export const useSaveEstimate = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const saveEstimate = async (estimateData, action) => {
        const documentId = estimateData?.documentId || null;

        const subscriptionData =
            store.getState()?.user?.activeBusiness?.subscription;
        const subInfo = subscriptionData?.components?.document;

        const allowedActions = [
            'download',
            'preview',
            'save',
            'approve',
            'decline'
        ];
        const restrictedActions = ['send', 'share'];

        const blockAction =
            (subscriptionData?.productId === 'free' &&
                !allowedActions.includes(action)) ||
            (restrictedActions.includes(action) &&
                subscriptionData?.productId === 'free');

        const blockEstimateCreation =
            !subInfo?.enabled ||
            (subInfo?.limited && subInfo?.amountRemaining < 1);

        const tagIds = estimateData?.tags?.map((tag) => tag?.tagId);

        const saveTempDataAndRedirectToSubscription = () => {
            // Remove product ids to allow easy re-insertion into
            // form. Otherwise product values are not injected.
            const cleanTempData = {
                ...estimateData,
                products: estimateData.products.map((prod) => ({
                    ...prod,
                    productId: ''
                }))
            };

            dispatch(
                setTemporaryStorage({
                    component: 'estimate',
                    data: cleanTempData
                })
            );

            navigate('/settings/subscription');
        };

        if (subscriptionData?.productId === 'free' && blockAction) {
            saveTempDataAndRedirectToSubscription();
            return false;
        }

        if (documentId === null) {
            // If the user has no more documents remaining, save current estimate object
            // in Redux and force them to subscription page.
            if (blockEstimateCreation) {
                saveTempDataAndRedirectToSubscription();
                return false;
            }

            const newEstimateObject = await createEstimate({
                ...estimateData,
                tagIds
            });

            if (newEstimateObject?.documentId) {
                dispatch(
                    updatedocumentIds({
                        referenceNumber: newEstimateObject.referenceNumber,
                        documentId: newEstimateObject.documentId,
                        customLabel: newEstimateObject.customLabel
                    })
                );
            }
            return newEstimateObject;
        }

        await updateEstimate({ ...estimateData, tagIds });

        return estimateData;
    };

    return saveEstimate;
};
