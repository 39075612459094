import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';
import PlusStamp from '@/components/common/PlusStamp';
import { useSubscription } from '@/hooks';
import Link from '../Link';
import useStyles from './styles';

const MenuListLink = ({
    icon,
    label,
    end,
    isPlusOnly,
    linkTitle,
    onClick,
    to,
    color,
    child,
    parent
}) => {
    const classes = useStyles({ child, color, parent, to });

    const { productId } = useSubscription();

    const listItemClass =
        to === '/invoices' ? classes.topListItem : classes.listItem;

    return (
        <ListItem
            className={child ? classes.listItemChild : listItemClass}
            component="li"
        >
            <Link
                to={to}
                end={end}
                className={
                    child ? classes.listItemLinkChild : classes.listItemLink
                }
                onClick={onClick}
                title={linkTitle}
            >
                <ListItemIcon className={classes.listItemIcon}>
                    {icon}
                </ListItemIcon>
                <ListItemText disableTypography primary={label} />
                {isPlusOnly && productId && productId !== 'plus' && (
                    <PlusStamp />
                )}
            </Link>
        </ListItem>
    );
};

MenuListLink.propTypes = {
    end: PropTypes.bool,
    color: PropTypes.string,
    child: PropTypes.bool,
    icon: PropTypes.node,
    isPlusOnly: PropTypes.bool,
    label: PropTypes.string.isRequired,
    linkTitle: PropTypes.string,
    onClick: PropTypes.func,
    parent: PropTypes.bool,
    to: PropTypes.string
};

MenuListLink.defaultProps = {
    end: null,
    color: null,
    child: false,
    icon: null,
    isPlusOnly: false,
    linkTitle: '',
    onClick: null,
    parent: false,
    to: null
};

export default MenuListLink;
