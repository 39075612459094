import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    subHeader: {
        marginBottom: theme.typography.pxToRem(6)
    },
    subHeaderTextWithTooltip: {
        marginRight: theme.typography.pxToRem(9),
        fontSize: theme.typography.pxToRem(14)
    },
    subHeaderText: {
        fontSize: theme.typography.pxToRem(14)
    },
    stampTooltip: {
        marginLeft: 9
    },
    lowerStampContainer: {
        marginTop: -theme.spacing(2),
        maxWidth: '450px !important'
    },
    thinContainer: {
        maxWidth: '450px !important'
    }
}));
