import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    wrap: ({ iconTop, large }) => ({
        padding: iconTop ? theme.spacing(0, 3, 3, 3) : theme.spacing(3),
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            padding: iconTop ? theme.spacing(0, 6, 6, 6) : theme.spacing(6)
        },
        [theme.breakpoints.up('md')]: {
            minWidth: large
                ? theme.typography.pxToRem(720)
                : theme.typography.pxToRem(600)
        }
    }),
    buttons: {
        justifyContent: 'center',
        padding: 0,
        flexDirection: 'column-reverse',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row'
        }
    },
    button: {
        width: theme.typography.pxToRem(200),
        margin: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            margin: theme.spacing(0, 1)
        }
    },
    contentWrap: ({ large }) => ({
        textAlign: 'center',
        padding: theme.spacing(1, 0),
        flex: '0 0 auto',
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3, 0),
            maxWidth: large
                ? theme.typography.pxToRem(520)
                : theme.typography.pxToRem(360)
        }
    }),
    title: {
        fontSize: theme.typography.pxToRem(26),
        fontWeight: 600,
        color: '#35373D',
        margin: 0
    },
    messageWrap: {
        marginTop: theme.typography.pxToRem(10)
    },
    message: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 600,
        color: '#656B7D',
        margin: 0,
        whiteSpace: 'pre-line'
    },
    icon: {
        margin: '0 auto'
    }
}));
