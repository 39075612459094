import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    logoDropper: {
        borderRadius: 2,
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column'
    },
    logoDropperButton: {
        backgroundColor: 'transparent',
        cursor: 'pointer',
        outline: 'none',
        padding: 0,
        width: '100%',
        border: '1.5px dashed #92BAE0'
    },
    removeBorder: {
        border: 'none'
    },
    logoDropperContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
        cursor: 'pointer',
        padding: ({ isMakeDocumentPage }) =>
            isMakeDocumentPage
                ? `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(
                      14
                  )} ${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(
                      14
                  )}`
                : '22px 0',
        background: '#F5F9FC',
        '&:hover': {
            background: '#E1ECF7'
        }
    },
    logoImage: {
        maxWidth: '100%',
        maxHeight: ({ isLogo, isMakeDocumentPage }) => {
            if (isLogo) {
                return theme.typography.pxToRem(132);
            }
            if (isMakeDocumentPage) {
                return theme.typography.pxToRem(100);
            }
            return theme.typography.pxToRem(400);
        },
        padding: 0,
        cursor: 'pointer',
        display: 'block',
        margin: 'auto',
        boxShadow: ({ isMakeDocumentPage }) =>
            isMakeDocumentPage ? undefined : '0px 5px 10px 3px #DEDEE7'
    },
    label: {
        color: '#909397',
        fontWeight: 500,
        fontSize: 11,
        textTransform: 'uppercase'
    },
    editButton: {
        backgroundColor: theme.palette.common.white,
        border: '1px solid #E0E5EE',
        margin: '41px 0 12px',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0, .08)',
            color: theme.palette.common.black
        }
    },
    deleteButton: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.actionColors.negative,
        border: `1px solid ${theme.palette.actionColors.negative}`,
        '&:hover': {
            backgroundColor: '#35373d0a'
        }
    },
    documentIcon: {
        width: 48,
        height: 48,
        marginBottom: 8
    },
    uploadPrompt: {
        color: '#64748B'
    },
    uploadSubPrompt: {
        color: '#64748B',
        fontSize: theme.typography.pxToRem(12)
    },
    dropZoneDragOver: {
        background: '#E1ECF7'
    }
}));
