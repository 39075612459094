import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { toIntegerCurrency } from '@/util';
import InputBar from '../InputBar';

const DiscountLine = ({
    selectedDiscounts,
    applyFieldToItem,
    discountItem,
    error,
    clearErrors
}) => {
    const globalDiscountList =
        useSelector((state) => state.user?.activeBusiness?.discounts) || [];

    const removeDiscount = (discountId) => {
        const newSelectedDiscounts = selectedDiscounts.filter(
            (discount) => discount.discountId !== discountId
        );

        applyFieldToItem('discounts', newSelectedDiscounts);
    };

    const updateValue = (name, type, value, discountId) => {
        const formattedValue = Number.isNaN(value)
            ? 0
            : toIntegerCurrency(value);

        const discountIndexToUpdate = selectedDiscounts.findIndex(
            (discount) => discount.discountId === discountId
        );

        const newSelectedDiscounts = [...selectedDiscounts];

        newSelectedDiscounts[discountIndexToUpdate] = {
            name,
            type,
            value: formattedValue,
            discountId
        };

        applyFieldToItem('discounts', newSelectedDiscounts);
    };

    return (
        <InputBar
            label="Discount"
            clearErrors={clearErrors}
            nameError={error}
            selectedItems={selectedDiscounts}
            allItems={globalDiscountList}
            removeItem={removeDiscount}
            itemType="discount"
            updateValue={updateValue}
            inputItem={discountItem}
        />
    );
};

DiscountLine.propTypes = {
    applyFieldToItem: PropTypes.func.isRequired,
    selectedDiscounts: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
                .isRequired,
            name: PropTypes.string.isRequired
        })
    ),
    discountItem: PropTypes.object.isRequired,
    error: PropTypes.string,
    clearErrors: PropTypes.func.isRequired
};

DiscountLine.defaultProps = {
    selectedDiscounts: [],
    error: ''
};

export default DiscountLine;
