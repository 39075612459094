import React from 'react';
import PropTypes from 'prop-types';
import GenericDatePicker from '@/components/common/GenericDatePicker';
import { DisabledInputField } from '@/components/common';

const DueDate = ({ dueDate, handleDueDateSelection, documentType }) => {
    if (documentType === 'recurring-invoice') {
        return (
            <DisabledInputField tooltip="Recurring invoice due dates are determined by the invoice send date and the invoice terms">
                Due Date (based on terms)
            </DisabledInputField>
        );
    }

    return (
        <GenericDatePicker
            label={documentType === 'invoice' ? 'Due Date' : 'Expiration Date'}
            id={documentType === 'invoice' ? 'dueDate' : 'expirationDate'}
            startDate={dueDate}
            selectionReturn={(selectedData) =>
                handleDueDateSelection(selectedData)
            }
            margin="none"
            value={dueDate}
            disablePast
        />
    );
};

DueDate.propTypes = {
    dueDate: PropTypes.string,
    handleDueDateSelection: PropTypes.func,
    documentType: PropTypes.string
};

DueDate.defaultProps = {
    dueDate: new Date().toISOString(),
    handleDueDateSelection: () => {},
    documentType: 'invoice'
};

export default DueDate;
