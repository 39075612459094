export const setLocalStorageItem = (key, value) => {
    if (typeof Storage !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(value));
    }
};

export const getLocalStorageItem = (key) => {
    if (typeof Storage !== 'undefined') {
        const result = window.localStorage.getItem(key);
        if (result) {
            return JSON.parse(result);
        }
    }
    return false;
};

export const removeLocalStorageItem = (key) => {
    if (typeof Storage !== 'undefined') {
        window.localStorage.removeItem(key);
    }
};

export const setLocalStorageDeviceHadAccount = () => {
    setLocalStorageItem('invoicemaker-device-had-account', 1);
};

export const getLocalStorageDeviceHadAccount = () =>
    getLocalStorageItem('invoicemaker-device-had-account');
