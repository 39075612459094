import React from 'react';
import { useDispatch } from 'react-redux';
import { useToastNotification } from '@/hooks';
import { closeModal, setModal, setDocumentStatus } from '@/state/actions';
import { CheckmarkFilled } from '@/resources/icons';
import { delayAwait } from '@/util';
import { startRecurringInvoice } from '@/modules/dataWrangler/dataWrangler';
import { useSaveRecurringInvoice } from './useSaveRecurringInvoice';

/**
 * Recurring Invoice Hook
 * Activate a recurring invoice, where data is passed into the hook.
 * Handles redirection logic if invoices limited, and
 * creating or updating functionality.
 *
 * Returns false, or clean invoice data.
 */
export const useActivateRecurringInvoice = () => {
    const dispatch = useDispatch();
    const { triggerToast } = useToastNotification();
    const saveInvoice = useSaveRecurringInvoice();

    const activateRecurringInvoice = async (
        invoiceData,
        emailOverwrite,
        emailSubject,
        emailBody
    ) => {
        const startTime = Date.now();
        dispatch(setModal({ slug: 'loader-invoice-send' }));

        const newInvoiceData = {
            ...invoiceData,
            to: {
                ...invoiceData.to,
                email: emailOverwrite
            },
            emailSubject,
            emailBody
        };

        let savedData;

        if (
            newInvoiceData?.documentState === 'draft' ||
            newInvoiceData?.state === 'draft'
        ) {
            savedData = await saveInvoice(newInvoiceData, 'send');

            if (savedData === false) {
                dispatch(closeModal('loader-invoice-send'));
                return false;
            }
        } else {
            savedData = { ...invoiceData };
        }

        const documentId = savedData?.documentId;

        if (documentId !== null) {
            savedData = await startRecurringInvoice(documentId);

            if (savedData?.status === 429) {
                await delayAwait(startTime, 2000);

                dispatch(closeModal('loader-invoice-send'));

                triggerToast({
                    message: 'Currently processing',
                    action: 'delete'
                });

                return false;
            }

            // If draft, and API returned correctly - update invoice state.
            if (
                savedData?.documentState &&
                savedData?.documentState === 'draft'
            ) {
                dispatch(setDocumentStatus(savedData.documentState));
            }

            await delayAwait(startTime, 2000);

            dispatch(closeModal('loader-invoice-send'));

            // If the firstSendDate is near the end of the day
            if (new Date(savedData.firstSendDate).getHours() === 23) {
                triggerToast({
                    message:
                        'Your recurring invoice is being processed and will be sent shortly.',
                    action: 'saved',
                    icon: <CheckmarkFilled />
                });
            } else {
                triggerToast({
                    message: 'Currently processing',
                    action: 'saved',
                    icon: <CheckmarkFilled />
                });
            }

            return savedData;
        }

        return false;
    };

    return activateRecurringInvoice;
};
