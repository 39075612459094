import React, { useEffect } from 'react';
import { Collapse, List } from '@mui/material';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom-v5-compat';
import { MenuListButton, MenuListLink } from '@/components/common';
import { slugify } from '@/util';
import { mainMenuItems } from '@/config/menuItems';
import useGeneralStyles from '../styles';

const SubMenuList = ({ active, item, handleMenuClose }) => {
    const generalClasses = useGeneralStyles();
    return (
        <Collapse
            key={slugify(`mob-sub-${item.title}`)}
            in={active}
            timeout="auto"
            unmountOnExit
        >
            <List
                className={generalClasses.listWrap}
                component="div"
                disablePadding
            >
                {item.children.map((subItem) => {
                    const onClickFunc = () => {
                        if (subItem?.onClick) {
                            subItem.onClick();
                        }
                        handleMenuClose();
                    };

                    return (
                        <MenuListLink
                            child
                            color={subItem?.color}
                            end={subItem?.end}
                            to={subItem.path}
                            icon={subItem.icon}
                            iconActive={subItem.iconActive}
                            key={slugify(`mob-sub-item-${subItem.title}`)}
                            label={subItem.title}
                            linkTitle={subItem?.linkTitle}
                            onClick={onClickFunc}
                        />
                    );
                })}
            </List>
        </Collapse>
    );
};

SubMenuList.propTypes = {
    active: PropTypes.bool,
    handleMenuClose: PropTypes.func.isRequired,
    item: PropTypes.shape({
        children: PropTypes.instanceOf(Array),
        color: PropTypes.string,
        path: PropTypes.string,
        title: PropTypes.string
    }).isRequired
};

SubMenuList.defaultProps = {
    active: false
};

const Menu = ({ handleMenuClose, setToggleActive, toggleActive }) => {
    const generalClasses = useGeneralStyles();
    const location = useLocation();

    useEffect(() => {
        const pathArray = location.pathname.split('/');
        mainMenuItems.forEach((item) => {
            const cleanSlug = item.path.replace(/\//g, '');
            if (pathArray.includes(cleanSlug)) {
                setToggleActive(item.title);
            }
        });
    }, [location, setToggleActive]);

    return mainMenuItems.map((item) => (
        <React.Fragment key={slugify(`mob-menu-${item.title}`)}>
            {!item?.children && (
                <div className={generalClasses.mainMenuIcon}>
                    <MenuListLink
                        color={item.color}
                        end={item.end}
                        icon={item.icon}
                        iconActive={item.iconActive}
                        isPlusOnly={item?.isPlusOnly}
                        label={item.title}
                        to={item.path}
                    />
                </div>
            )}
            {item?.children && (
                <div className={generalClasses.collapseWrap}>
                    <MenuListButton
                        color={item.color}
                        icon={item.icon}
                        iconActive={item.iconActive}
                        label={item.title}
                        toggleActive={toggleActive}
                        parent
                        onClick={() =>
                            setToggleActive(
                                toggleActive === item.title ? '' : item.title
                            )
                        }
                    />
                    <SubMenuList
                        active={toggleActive === item.title}
                        item={item}
                        handleMenuClose={handleMenuClose}
                    />
                </div>
            )}
        </React.Fragment>
    ));
};

Menu.propTypes = {
    handleMenuClose: PropTypes.func.isRequired,
    setToggleActive: PropTypes.func.isRequired,
    toggleActive: PropTypes.string
};

Menu.defaultProps = {
    toggleActive: null
};

export default Menu;
