/**
 * This function takes a collection of address data and returns a
 * correctly formatted address.
 *
 * @param  {string} name Individual's name
 * @param  {string} companyName Company name
 * @param  {object} addressObject Standard address object.
 * @param  {string} addressObject.line1 First line of address.
 * @param  {string} addressObject.line2 Second line of address.
 * @param  {string} addressObject.city City/town/locality.
 * @param  {string} addressObject.state State/province/county.
 * @param  {string} addressObject.postalCode Postal code/zip code.
 * @param  {string} addressObject.customAddress User formatted address field.
 * @param  {string} countryName Written name of the country coming from the country information.
 * @param  {int} addressFormat [0 (custom), 1 (US), 2 (European), 3 (UK)] Comes from the country information.
 * @param  {bool} formatInternational Whether to show the country name. Comes from the business settings.
 * @return {string} Correctly formatted address.
 */
export const formatAddress = ({
    name,
    companyName,
    addressObject,
    countryName,
    addressFormat,
    formatInternational
}) => {
    let address = '';

    if (name) {
        address = address.concat(name, '\n');
    }

    if (companyName) {
        address = address.concat(companyName, '\n');
    }

    switch (addressFormat) {
        case 1:
            if (addressObject?.line1) {
                address = address.concat(addressObject.line1, '\n');
            }

            if (addressObject?.line2) {
                address = address.concat(addressObject.line2, '\n');
            }

            if (addressObject?.city) {
                address = address.concat(addressObject.city);

                if (addressObject?.state) {
                    address = address.concat(', ');
                }
            }

            if (addressObject?.state) {
                address = address.concat(addressObject.state);

                if (addressObject?.postalCode) {
                    address = address.concat(' ');
                }
            }

            if (addressObject?.postalCode) {
                address = address.concat(addressObject.postalCode);
            }

            break;
        case 2:
            if (addressObject?.line1) {
                address = address.concat(addressObject.line1, '\n');
            }

            if (addressObject?.line2) {
                address = address.concat(addressObject.line2, '\n');
            }

            if (addressObject?.postalCode) {
                if (addressObject?.city) {
                    address = address.concat(
                        addressObject.postalCode,
                        ' ',
                        addressObject.city
                    );
                } else {
                    address = address.concat(addressObject.postalCode);
                }
            }

            if (!addressObject?.postalCode && addressObject?.city) {
                address = address.concat(addressObject.city);
            }

            break;
        case 3:
            if (addressObject?.line1) {
                address = address.concat(addressObject.line1, '\n');
            }

            if (addressObject?.line2) {
                address = address.concat(addressObject.line2, '\n');
            }

            if (addressObject?.city) {
                address = address.concat(addressObject.city, '\n');
            }

            if (addressObject?.state) {
                address = address.concat(addressObject.state, '\n');
            }

            if (addressObject?.postalCode) {
                address = address.concat(addressObject.postalCode);
            }

            break;
        case 0:
        default:
            if (addressObject?.customAddress) {
                address = address.concat(addressObject.customAddress);
            }

            break;
    }

    // Remove trailing returns and tabs if present
    address = address.replace(/(\r\n|\r|\n|\t)$/, '');

    if (formatInternational && countryName) {
        address = address.concat('\n', countryName);
    }

    return address;
};
