import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    loaderDocument: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column'
    },
    loaderDocumentSkeleton: {
        marginBottom: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(3)
        }
    },
    loaderDocumentTotalSkeleton: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '50%',
            alignSelf: 'flex-end'
        }
    }
}));
