import { useSelector } from 'react-redux';
import { getLocalStorageDeviceHadAccount } from '@/modules/localStorageManager';

/**
 * Checks whether or not a user is authenticated, and what type of user they are.
 * @returns interimUser {bool} User has an account but not 'signed up'. We automatically generated it.
 * @returns loadingUser {bool} true if user is still being authenticated, false if user is authenticated
 * @returns brandNewUser {bool} They do not have a 'invisible' account. They are brand new to the site.
 * @returns registeredUser {bool} user is logged in and registered with proper account.
 */
export const useCheckAuthState = () => {
    const loading = useSelector((state) => state?.user?.loadingUser);
    const loggedIn = useSelector((state) => state?.user?.userLoggedIn);
    const unregistered = useSelector((state) => state?.user?.unregistered);

    const hasInvoices =
        useSelector(
            (state) => state?.user?.activeBusiness?.recordsCreated?.invoice
        ) || false;

    const deviceHadAccount = getLocalStorageDeviceHadAccount();

    // User has not touched any create account form.
    // They do not have a 'invisible' account.
    // User is brand new.
    const brandNewUser = unregistered === undefined;

    // User has an account but not 'signed up'.
    // Meaning an invisible account exists, but we
    // do not have their details.
    const interimUser = unregistered !== undefined && unregistered;

    // User is logged in and registered with the site.
    const registeredUser = !brandNewUser && !interimUser;

    const userNotLoggedOut = loggedIn === undefined || loggedIn;
    const userNotStoppedLoading = loading === undefined || loading;

    // The user is loading if they are not logged out, and they
    // have not completed loading yet.
    const loadingUser = userNotLoggedOut && userNotStoppedLoading;

    return {
        deviceHadAccount: Boolean(deviceHadAccount),
        hasInvoices: Boolean(hasInvoices),
        interimUser,
        loadingUser,
        loggedInUser: loggedIn || false,
        brandNewUser,
        registeredUser
    };
};
