import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    showOnTop: {
        maxWidth: theme.typography.pxToRem(870)
    },
    boldMessage: {
        fontWeight: theme.typography.fontWeightBold
    },
    merchantInstructionsListContainer: {
        marginTop: theme.typography.pxToRem(24)
    },
    instructionList: {
        marginTop: theme.typography.pxToRem(8),
        [theme.breakpoints.only('xs')]: {
            paddingLeft: theme.typography.pxToRem(20)
        }
    },
    uploadInstructions: {
        lineHeight: 2,
        marginBottom: 16
    },
    showMeHowLink: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 800,
        paddingTop: 0,
        paddingBottom: 0,
        margin: '0 auto',
        textDecoration: 'underline'
    },
    venmoScanButton: {
        width: theme.typography.pxToRem(64),
        marginLeft: theme.typography.pxToRem(15),
        verticalAlign: 'middle'
    },
    venmoTopBar: {
        width: theme.typography.pxToRem(210),
        marginLeft: theme.typography.pxToRem(15),
        verticalAlign: 'middle'
    },
    venmoExportButton: {
        width: theme.typography.pxToRem(32),
        marginLeft: theme.typography.pxToRem(15),
        verticalAlign: 'middle'
    },
    cashAppIcon: {
        width: theme.typography.pxToRem(20),
        marginLeft: theme.typography.pxToRem(15),
        verticalAlign: 'middle'
    },
    qrLinkContainer: {
        wordBreak: 'break-all',
        overflowWrap: 'anywhere'
    },
    checkmarkIcon: {
        width: 18,
        height: 18,
        marginRight: theme.spacing(1),
        verticalAlign: 'middle',
        '& circle': {
            fill: theme.palette.actionColors.positive
        },

        [theme.breakpoints.down(468)]: {
            display: 'none'
        }
    },
    disconnectButton: {
        backgroundColor: theme.palette.actionColors.negative,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: 'rgba(253,41,41, .79)',
            color: 'white'
        }
    },
    verifiedMerchantCode: {
        wordBreak: 'break-all'
    },
    unclickableTextField: {
        pointerEvents: 'none',
        borderRadius: 4,
        marginRight: theme.spacing(2),
        '& .MuiFilledInput-root': {
            backgroundColor: 'transparent'
        }
    },
    disconnectText: {
        fontSize: 12
    }
}));
