import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    filledInputDisabledRoot: {
        opacity: 0.38
    },
    filledInputDisabled: {
        color: theme.palette.text.primary,
        fontSize: theme.typography.h4.fontSize
    },
    filledInputDisabledLabel: {
        color: theme.palette.text.disabled,
        fontSize: theme.typography.h4.fontSize
    }
}));
