import React from 'react';
import {
    MobileItemTags,
    TableActionsWrap,
    TagsIcon
} from '@/components/common';
import { openDrawer } from '@/state/actions';
import { toWholeCurrency, formatPercentage } from '@/util';
import store from '@/state/store';

// Process array of objects into a stripped down version
// that only has required data so is faster to process.
export const dataProcessor = (data) =>
    data.map(({ taxId, name, value, tags }) => ({
        id: taxId,
        taxName: name,
        value,
        tags
    }));

export const generateComponents = ({ array, locale, isMobile }) => {
    const { items } = store.getState().listing;

    const handleClick = (id) => {
        const tax = items?.find((item) => item.taxId === id);

        if (tax) {
            store.dispatch(
                openDrawer({
                    id: 'tax',
                    tax,
                    edit: true
                })
            );
        }
    };

    return array.map(({ id, taxName, value, tags }) => ({
        id,
        taxName,
        amount: formatPercentage({
            number: toWholeCurrency(parseFloat(value || 0)),
            locale
        }),
        tags: isMobile ? (
            <MobileItemTags type="tax" itemId={id} tags={tags} />
        ) : (
            <TagsIcon type="tax" itemId={id} tags={tags} />
        ),
        actions: taxName && (
            <TableActionsWrap
                id={id}
                open="editTax"
                rest={['deleteTax']}
                toPass={{ tax: taxName }}
            />
        ),
        primaryClick: () => handleClick(id)
    }));
};
