import makeStyles from '@mui/styles/makeStyles';

export default makeStyles({
    disableRipple: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    searchBox: {
        width: '100%',
        backgroundColor: '#fff',
        '& svg path': {
            fill: '#8C92A4'
        }
    },
    searchBoxOutline: {
        borderColor: '#EDEEF0'
    },
    input: {
        '&[type="search"]::-webkit-search-cancel-button': {
            '-webkitAppearance': 'none',
            appearance: 'none'
        },
        '&[type="search"]::-webkit-search-decoration': {
            '-webkitAppearance': 'none',
            appearance: 'none'
        }
    }
});
