import React, { memo } from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import useStyles from './styles';

const DrawerHeading = memo(({ startAdornment, title }) => {
    const classes = useStyles();

    return (
        <div className={classes.wrap}>
            <div className={classes.icon}>{startAdornment}</div>
            <Typography variant="h1">{title}</Typography>
        </div>
    );
});

DrawerHeading.propTypes = {
    startAdornment: PropTypes.element,
    title: PropTypes.string.isRequired
};

DrawerHeading.defaultProps = {
    startAdornment: null
};

export default DrawerHeading;
