import { useRef } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { injectScript } from '@/util';

/**
 * Set specific config/functionality specific to Zendesk.
 * When this is run window.zE should be defined, as it
 * will alway be run after onload of the script.
 */
const setInitialZendeskConfig = (isMobile) => {
    window?.zE('webWidget:on', 'close', () => {
        if (isMobile) {
            window.zE('webWidget', 'hide');
        }
    });
};

export const useZendeskWidget = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const zendeskScriptRequested = useRef(false);
    const zendeskHasLoaded = useRef(false);

    // Checks the script has never been injectd before, and injects it.
    const setupZendeskScript = async () => {
        if (!zendeskScriptRequested.current && !zendeskHasLoaded.current) {
            zendeskScriptRequested.current = true;

            if (isMobile) {
                window.zESettings = {
                    webWidget: {
                        zIndex: 2000
                    }
                };
            } else {
                window.zESettings = {
                    webWidget: {
                        zIndex: 1200
                    }
                };
            }

            await injectScript({
                id: 'ze-snippet',
                url: 'https://static.zdassets.com/ekr/snippet.js?key=35d95266-48a6-4639-b679-a4b3c2db8309'
            });

            // Wait for the script to become active.
            await new Promise((resolve) => {
                window?.zE(() => {
                    // Then we need to wait a little, otherwise the functions
                    // dont seem to work...
                    setTimeout(() => {
                        resolve();
                    }, 100);
                });
            });

            zendeskHasLoaded.current = true;

            setInitialZendeskConfig(isMobile);
        }
        return true;
    };

    // A wrapper for any commands that rely on the Zendesk script.
    const runZendeskCommand = async (callback) => {
        await setupZendeskScript();
        if (callback) callback();
    };

    // Hook function to open the Widget.
    const openZendeskWidget = () =>
        runZendeskCommand(() => {
            window?.zE('webWidget', 'open');
        });

    return {
        openZendeskWidget
    };
};
