import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material';
import { WarnConfirmDialog } from '@/components/common';
import { usePaymentConnections } from '@/hooks';
import { PaymentsIconLarge } from '@/resources/icons';
import { capitalizeFirstLetter } from '@/util';
import useStyles from './styles';

const PaymentChannelSwitchConfirmationModal = ({
    selectedMerchant,
    paymentChannel,
    onCancel,
    onConfirm,
    buttonLoading
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const { connections, preferredPaymentConnections } =
        usePaymentConnections();

    const newMerchant = capitalizeFirstLetter(selectedMerchant);

    const previousMerchant =
        selectedMerchant === 'stripe' ? 'Square' : 'Stripe';

    const readablePaymentChannel = {
        creditCard: 'Credit Card',
        digitalWallet: 'Digital Wallet',
        bankTransfer: 'Bank Transfer'
    };

    const cashAppQrConnection = connections.find(
        (connection) => connection.application === 'cashapp'
    );

    const nativeCashAppEnabled =
        preferredPaymentConnections?.otherConnections?.find(
            (connectionId) => connectionId === cashAppQrConnection?.connectionId
        );

    const handleCashAppIntegration =
        nativeCashAppEnabled &&
        selectedMerchant === 'square' &&
        paymentChannel === 'digitalWallet';

    return (
        <WarnConfirmDialog
            open
            cancelText="Cancel"
            confirmText={`Use ${newMerchant}`}
            confirmColor={theme.palette.primary.main}
            customIcon={<PaymentsIconLarge className={classes.icon} />}
            title={`Are you sure you want to use ${newMerchant} as your ${readablePaymentChannel[paymentChannel]} Merchant?`}
            message={
                <>
                    {`All outstanding invoices and future documents will now use your ${newMerchant} account instead of ${previousMerchant} for payment services.`}
                    {handleCashAppIntegration && (
                        <>
                            <br />
                            <br /> Your current Cash App integration will be
                            turned off.
                        </>
                    )}
                </>
            }
            onCancel={onCancel}
            onConfirm={() =>
                handleCashAppIntegration ? onConfirm(true) : onConfirm()
            }
            buttonLoading={buttonLoading}
        />
    );
};

export default PaymentChannelSwitchConfirmationModal;

PaymentChannelSwitchConfirmationModal.propTypes = {
    selectedMerchant: PropTypes.string.isRequired,
    paymentChannel: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    buttonLoading: PropTypes.bool
};

PaymentChannelSwitchConfirmationModal.defaultProps = {
    buttonLoading: false
};
