import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    span: {
        color: '#9DA1AC',
        display: 'block',
        fontSize: theme.typography.pxToRem(12),
        fontWeight: '400',
        textTransform: 'uppercase',
        marginTop: theme.typography.pxToRem(8),
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
}));
