import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    account: {
        marginBottom: theme.typography.pxToRem(10),
        borderRadius: 4,
        overflow: 'hidden'
    },
    accountButton: {
        backgroundColor: (toggleRotate) =>
            toggleRotate && theme.palette.common.white,
        borderRadius: 4,
        padding: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(
            20
        )}`
    },
    accountTitle: {
        display: 'flex',
        color: '#1E377A',
        padding: theme.spacing(1, 0),
        margin: 0,
        alignItems: 'center'
    },
    accountTitleSpan: {
        fontSize: theme.typography.pxToRem(14),
        width: theme.typography.pxToRem(140),
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    accountToggle: {
        transition: 'all 250ms',
        width: theme.typography.pxToRem(20),
        position: 'absolute',
        right: theme.typography.pxToRem(15),
        transform: (toggleRotate) => toggleRotate && 'rotate(-180deg)'
    },
    copyInfoButton: {
        padding: 0,
        width: '100%',
        maxWidth: 150,
        '& h4': {
            fontSize: 14
        },
        '& p': {
            fontSize: 10
        },
        '& svg': {
            maxWidth: 16
        },
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    menuItem: {
        margin: 0
    },
    menuLink: {
        color: theme.palette.text.primary,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textDecoration: 'none',
        width: '100%',
        padding: 0
    },
    menuIcon: {
        height: theme.typography.pxToRem(14)
    },
    accountItemsTypography: {
        fontWeight: 600
    },
    accountListWrap: {
        backgroundColor: theme.palette.common.white,
        width: '100%',
        wordBreak: 'break-all'
    }
}));
