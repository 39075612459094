import React from 'react';
import { Grid } from '@mui/material';
import { useDrawers } from '@/hooks';
import { TAX_BASE_PARAM } from '@/constants';
import { FilterForm, Percent, Tags } from '../formComponents';

const TaxFilterDrawer = () => {
    const { isDrawerActive } = useDrawers();
    const drawerOpen = isDrawerActive('taxFilter');

    const defaultValues = {
        value: {
            min: '',
            max: ''
        },
        tags: []
    };

    return (
        <FilterForm
            defaultValues={defaultValues}
            id="FilterTaxes"
            open={drawerOpen}
            paramBase={TAX_BASE_PARAM}
            title="Taxes"
            type="tax"
        >
            <Grid item>
                <Percent heading="Amount" id="value" />
                <Tags heading="Tags" id="tags" />
            </Grid>
        </FilterForm>
    );
};

export default TaxFilterDrawer;
