import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import FormattedNumberField from '@/components/common/FormattedNumberField';

const Quantity = ({ item, applyFieldToItem }) => {
    const format = (amount) => numeral(amount).format('0,0.00');
    return (
        <FormattedNumberField
            item={item}
            applyFieldToItem={(val) => {
                applyFieldToItem('quantity', parseFloat(val || 0, 10));
            }}
            label="Quantity"
            maxLength={15}
            format={format}
            useCurrencySymbol={false}
            value={item.quantity ? item.quantity : 0}
            placeHolder="1.00"
        />
    );
};

Quantity.propTypes = {
    item: PropTypes.object.isRequired,
    applyFieldToItem: PropTypes.func.isRequired
};

export default Quantity;
