import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    itemTagsContainer: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    itemTagTopSpacing: {
        paddingTop: theme.spacing(1)
    },
    itemTagPillContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 5
    },
    mobileItemTagPill: (tags) => ({
        backgroundColor: tags.backgroundColor,
        color: tags.color,
        border: `1px solid ${
            tags.backgroundColor === '#FFFFFF' ? '#E0E5EE' : 'transparent'
        }`,
        padding: `${theme.spacing(0.2)} ${theme.spacing(1.5)}`,
        borderRadius: 24,
        wordBreak: 'break-word',
        width: 'fit-content'
    }),
    itemTagIcon: {
        width: 16,
        height: 16,
        minWidth: 16,
        marginTop: 4,
        marginRight: 12
    }
}));
