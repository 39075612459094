import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { FilterIcon } from '@/resources/icons';
import { openDrawer } from '@/state/actions';
import { useUrlParams } from '@/hooks';
import useStyles from './styles';

const CountCircle = ({ paramCount }) => {
    const classes = useStyles();

    if (paramCount === 0) {
        return null;
    }
    return <span className={classes.paramCount}>{paramCount}</span>;
};

CountCircle.propTypes = {
    paramCount: PropTypes.number.isRequired
};

const FilterButton = ({ base, type, watch }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { getUrlParams } = useUrlParams();

    const currentParams = getUrlParams({ base, watch });

    const paramCount = currentParams ? Object.keys(currentParams).length : 0;

    return (
        <Button
            variant="contained"
            fullWidth
            className={classes.filterButton}
            startIcon={<FilterIcon className={classes.filterIcon} />}
            onClick={() => {
                dispatch(openDrawer(`${type}Filter`));
            }}
        >
            Filter
            <CountCircle paramCount={paramCount} />
        </Button>
    );
};

FilterButton.propTypes = {
    base: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    watch: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default FilterButton;
