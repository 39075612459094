import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom-v5-compat';
import { updatedocumentIds, setTemporaryStorage } from '@/state/actions';
import {
    createReceipt,
    updateReceipt
} from '@/modules/dataWrangler/dataWrangler';
import store from '@/state/store';

/**
 * Receipt Hook
 * Saves an receipt, where data is passed into the hook.
 * Handles redirection logic if documents are limited, and
 * creating or updating functionality.
 *
 * Returns false, or clean receipt data.
 */
export const useSaveReceipt = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const saveReceipt = async (receiptData, action) => {
        const documentId = receiptData?.documentId || null;

        const subscriptionData =
            store.getState()?.user?.activeBusiness?.subscription;
        const subInfo = subscriptionData?.components?.document;

        const blockAction =
            subscriptionData?.productId === 'free' &&
            ['send', 'share'].includes(action);

        const blockReceiptCreation =
            !subInfo?.enabled ||
            (subInfo?.limited && subInfo?.amountRemaining < 1);

        const tagIds = receiptData?.tags?.map((tag) => tag.tagId);

        const saveTempDataAndRedirectToSubscription = () => {
            // Remove product ids to allow easy re-insertion into
            // form. Otherwise product values are not injected.
            const cleanTempData = {
                ...receiptData,
                products: receiptData.products.map((prod) => ({
                    ...prod,
                    productId: ''
                }))
            };

            dispatch(
                setTemporaryStorage({
                    component: 'receipt',
                    data: cleanTempData
                })
            );

            navigate('/settings/subscription');
        };

        if (subscriptionData?.productId === 'free' && blockAction) {
            saveTempDataAndRedirectToSubscription();
            return false;
        }

        if (documentId === null) {
            // If the user has no more documents remaining, save current receipt object
            // in Redux and force them to subscription page.
            if (blockReceiptCreation) {
                saveTempDataAndRedirectToSubscription();
                return false;
            }

            const newReceiptObject = documentId
                ? receiptData
                : await createReceipt({ ...receiptData, tagIds });

            if (newReceiptObject?.documentId) {
                dispatch(
                    updatedocumentIds({
                        referenceNumber: newReceiptObject.referenceNumber,
                        documentId: newReceiptObject.documentId,
                        customLabel: newReceiptObject.customLabel
                    })
                );
            }
            return newReceiptObject;
        }

        await updateReceipt({ ...receiptData, tagIds });

        return receiptData;
    };

    return saveReceipt;
};
