import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select, InputLabel, FormControl, MenuItem } from '@mui/material';
import { useLocalization, useLocaleCountry } from '@/hooks';

/**
 * Country Selector dropdown.
 * @param {string} country Currently selected Country.
 * @param {bool} disabled if the prop should be disabled
 * @param {func} setCountry Set the Country using this function.
 * @param {string} customClasses Custom className styles from the parent component.
 */
const CountrySelector = ({ country, setCountry, customClasses, disabled }) => {
    const { loading, countries } = useLocalization();

    // Default or currently selected locale.
    // This is only shown when waiting for country data to load.
    const defaultCountry = useLocaleCountry();

    // This state management allows us to handle the selected state inside the
    // component, as we don't know what happens outside.
    const [current, setCurrent] = useState(country);
    const handleSelection = (selected) => {
        setCurrent(selected);
        setCountry(selected);
    };
    return (
        <FormControl fullWidth variant="filled">
            <InputLabel id="countryLabel">Country</InputLabel>
            <Select
                labelId="countryLabel"
                disabled={disabled}
                label="Country"
                value={loading ? '' : current || defaultCountry.code}
                className={customClasses}
                onChange={(e) => {
                    handleSelection(e.target.value);
                }}
            >
                {(loading && defaultCountry?.code) ||
                    (!countries && (
                        <MenuItem
                            key={defaultCountry.code}
                            value={defaultCountry.code}
                            name={defaultCountry.name}
                        >
                            {defaultCountry.name}
                        </MenuItem>
                    ))}
                {!loading &&
                    countries?.length > 0 &&
                    countries.map((item) => (
                        <MenuItem
                            key={item.name}
                            value={item.code}
                            name={item.name}
                        >
                            {item.name}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
};

CountrySelector.propTypes = {
    country: PropTypes.string,
    setCountry: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    customClasses: PropTypes.string
};

CountrySelector.defaultProps = {
    disabled: false,
    country: undefined,
    customClasses: undefined
};

export default CountrySelector;
