import { SET_LOCALIZATION_DATA } from '@/constants';

export default (state = {}, action) => {
    switch (action.type) {
        case SET_LOCALIZATION_DATA: {
            return {
                ...action.localization,
                countries: [...action.localization.countries]
            };
        }
        default: {
            return state;
        }
    }
};
