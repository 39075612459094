import { drawerSlice } from '@/state/features/drawerSlice';
import { listingSlice } from '@/state/features/listingSlice';
import { makeDocumentSlice } from '@/state/features/makeDocumentSlice';
import { modalSlice } from '@/state/features/modalSlice';
import { userSlice } from '@/state/features/userSlice';

export const { closeDrawer, closeAllDrawers, openDrawer, updateDrawer } =
    drawerSlice.actions;

export const {
    fetchListingItems,
    overwriteListingItems,
    noListingItems,
    resetListingItems
} = listingSlice.actions;

export const {
    toggleDiscount,
    saveNotes,
    saveCustomLabel,
    toggleShipping,
    setBilledClient,
    setDueDate,
    setDocumentTerms,
    setDocumentState,
    resetDocument,
    updatedocumentIds,
    overwriteDocument,
    setDocumentErrors,
    resetDocumentErrors,
    resetBillToErrors,
    resetBillFromErrors,
    addProductToDocument,
    removeProductFromDocument,
    updateDocumentProduct,
    updateDocumentProducts,
    selectDocumentProduct,
    setDocumentReminders,
    setDocumentStatus,
    updateDocumentTotals,
    setVoidDocumentPending,
    setVoidDocumentComplete,
    setInvoiceAsUnpaidClientMethod,
    setDocumentTags,
    removeDocumentTag,
    setRecurringDetails
} = makeDocumentSlice.actions;

export const { closeAllModals, closeModal, setModal } = modalSlice.actions;

export const {
    fetchUser,
    updateUser,
    fetchProducts,
    overwriteProducts,
    addProduct,
    updateProduct,
    deleteProduct,
    fetchClients,
    overwriteClients,
    addClient,
    updateClient,
    deleteClient,
    createBusiness,
    setActiveBusiness,
    setActiveBusinessPending,
    setActiveBusinessComplete,
    updateLogo,
    setUpdateLogoPending,
    setUpdateLogoComplete,
    deleteLogo,
    setDeleteLogoPending,
    setDeleteLogoComplete,
    updateBusinessProfile,
    updateBusinessSettings,
    updateBusinessAddress,
    createTax,
    overwriteTaxes,
    updateTax,
    deleteTax,
    createDiscount,
    updateDiscounts,
    updateDiscount,
    updateDiscountsPending,
    updateDiscountsComplete,
    deleteDiscount,
    createTag,
    overwriteTags,
    updateTag,
    deleteTag,
    createNote,
    overwriteNotes,
    updateNote,
    deleteNote,
    createFile,
    overwriteFiles,
    updateFile,
    deleteFile,
    loggedIn,
    addConnection,
    removeConnection,
    setAllConnections,
    resetState,
    updateSubscription,
    setTemporaryUser,
    clearTemporaryStorage,
    setTemporaryStorage,
    prepareTemporaryStorage,
    fetchDocument,
    documentStoppedLoading,
    fetchNotificationCount,
    reduceNotificationCount,
    increaseNotificationCount,
    clearNotificationCount
} = userSlice.actions;
