import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { WarnConfirmDialog } from '@/components/common';
import ButtonLoading from '@/components/common/ButtonLoading';
import { SubscriptionActive } from '@/resources/icons';
import { useSubscription } from '@/hooks';
import { updateSubscriptionProduct } from '@/modules/subscriptionManager';
import { ctaClicked as segmentCtaClicked } from '@/modules/segment/segmentManager';
import useStyles from './styles';

const CancelDowngradeButton = ({ className, isUpgrade }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [renewModalOpen, setRenewModalOpen] = useState(false);

    const { chargify } = useSubscription();

    const productHandle = chargify?.product?.productHandle;
    const handleUndoCancelSubscription = async () => {
        setLoading(true);
        await updateSubscriptionProduct(productHandle);
        segmentCtaClicked({
            destination: 'Subscription',
            text: `Cancel ${isUpgrade ? 'Upgrade' : 'Downgrade'}`,
            location: 'Subscription Card',
            type: 'button',
            url: window.location.href
        });
    };

    const handleClicked = () => {
        setRenewModalOpen(true);
    };

    return (
        <>
            <ButtonLoading
                variant="contained"
                color="primary"
                fullWidth
                className={className}
                loading={loading}
                disableOnLoading
                onClick={handleClicked}
            >
                {`Cancel ${isUpgrade ? 'Upgrade' : 'Downgrade'}`}
            </ButtonLoading>
            <WarnConfirmDialog
                open={renewModalOpen}
                customIcon={<SubscriptionActive className={classes.icon} />}
                title={`Cancel ${isUpgrade ? 'Upgrade' : 'Downgrade'}`}
                message="Your subscription will continue on the current plan."
                onCancel={() => {
                    setRenewModalOpen(false);
                }}
                onConfirm={() => {
                    handleUndoCancelSubscription();
                }}
                buttonLoading={loading}
                confirmColor="#357abc"
                confirmText="Continue"
            />
        </>
    );
};

CancelDowngradeButton.propTypes = {
    className: PropTypes.string,
    isUpgrade: PropTypes.bool
};

CancelDowngradeButton.defaultProps = {
    className: undefined,
    isUpgrade: false
};

export default CancelDowngradeButton;
