import React from 'react';
import PropTypes from 'prop-types';
import { Stepper, Step, StepLabel, StepConnector } from '@mui/material';
import Check from '@mui/icons-material/Check';
import useStyles from './styles';

const CustomStepperIcons = ({ active, completed, icon }) => {
    const classes = useStyles();

    return (
        <div
            className={`${classes.icon} ${active ? classes.iconActive : ''} ${
                completed ? classes.iconCompleted : ''
            }`}
        >
            {completed ? <Check data-testid="stepper-check" /> : icon}
        </div>
    );
};

const StepperHead = ({ activeStep, steps }) => {
    const classes = useStyles();

    CustomStepperIcons.propTypes = {
        active: PropTypes.bool.isRequired,
        completed: PropTypes.bool.isRequired,
        icon: PropTypes.node.isRequired
    };

    return (
        <Stepper
            activeStep={activeStep}
            alternativeLabel
            className={classes.stepper}
            connector={<StepConnector className={classes.stepConnector} />}
        >
            {steps.map((step, index) => (
                <Step key={step} expanded>
                    <StepLabel StepIconComponent={CustomStepperIcons}>
                        <span
                            className={
                                activeStep === index
                                    ? classes.activeLabel
                                    : classes.inactiveLabel
                            }
                        >
                            {step}
                        </span>
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
};

StepperHead.propTypes = {
    activeStep: PropTypes.number,
    steps: PropTypes.arrayOf(PropTypes.string)
};

StepperHead.defaultProps = {
    activeStep: 0,
    steps: []
};

export default StepperHead;
