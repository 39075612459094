import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocalization, useLocaleCountry } from '@/hooks';
import PureAddressBlock from './PureAddressBlock';

export const AddressInputBlock = ({
    address,
    allowFreeForm,
    disabled,
    setAddress
}) => {
    // As we are not using the component to change the locale address format
    // we need to hold this as a separate state. It will allow us to change
    // things for clients without effecting the main business locale.
    const localeData = useLocaleCountry();

    // Destructure address, and add default user country if doesnt exist.
    const newAddress = { ...address };
    if (!newAddress.country) {
        newAddress.country = localeData?.code || 'US';
    }

    const [addressFormat, setAddressFormat] = useState(
        localeData?.addressFormat
    );

    const { countries } = useLocalization();

    useEffect(() => {
        if (address?.country && countries) {
            // Get the selected country object from the localization list.
            const selectedCountryObject = countries.find(
                ({ code }) => address?.country === code
            );

            let formatToSet = selectedCountryObject.addressFormat;
            // If freeform is not allowed, and Country has freeform format
            // force to US.
            if (!allowFreeForm && formatToSet === 0) {
                formatToSet = 1;
            }

            // Set the address format.
            setAddressFormat(formatToSet);
        }
    }, [address?.country, allowFreeForm, countries]);

    return (
        <PureAddressBlock
            address={newAddress}
            addressFormat={addressFormat}
            disabled={disabled}
            setAddress={setAddress}
        />
    );
};

AddressInputBlock.propTypes = {
    address: PropTypes.shape({
        city: PropTypes.string,
        country: PropTypes.string,
        customAddress: PropTypes.string,
        line1: PropTypes.string,
        line2: PropTypes.string,
        postalCode: PropTypes.string,
        state: PropTypes.string
    }),
    allowFreeForm: PropTypes.bool,
    disabled: PropTypes.bool,
    setAddress: PropTypes.func.isRequired
};

AddressInputBlock.defaultProps = {
    address: {
        city: '',
        country: '',
        customAddress: '',
        line1: '',
        line2: '',
        postalCode: '',
        state: ''
    },
    allowFreeForm: true,
    disabled: false
};
