import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom-v5-compat';
import { useDispatch, useSelector } from 'react-redux';

import {
    deleteTag,
    getPaginatedTags
} from '@/modules/dataWrangler/dataWrangler';
import { useToastNotification, useUrlParams } from '@/hooks';
import { openDrawer } from '@/state/actions';
import { DeleteIcon } from '@/resources/icons';

import WarnConfirmDialog from '../../WarnConfirmDialog';
import ActionIconWrapper from '../ActionIconWrapper';

/**
 * @param {string} type Type of item (i.e. Product, Service, Discount, etc)
 * @param {string} itemId ID of the item being passed in
 * @param {arrayOf(object)} tags array of tag objects
 */

export const TagsIcon = ({ type, itemId, tags }) => {
    const dispatch = useDispatch();

    const getItemIdName = () => {
        if (type === 'invoice' || type === 'estimate' || type === 'receipt') {
            return 'documentId';
        }

        if (type === 'service') {
            return 'productId';
        }

        return `${type}Id`;
    };

    const listingItems = useSelector((state) => state?.listing?.items);
    const item = listingItems?.find(
        (itemData) => itemData?.[getItemIdName()] === itemId
    );

    const handleClick = () => {
        dispatch(
            openDrawer({
                id: 'tags',
                type,
                item,
                edit: true
            })
        );
    };

    return (
        <ActionIconWrapper
            action="tags"
            label="Tags"
            onClick={handleClick}
            tooltip="Edit Tags"
            count={tags.length}
            tags={tags}
        />
    );
};

TagsIcon.propTypes = {
    type: PropTypes.string.isRequired,
    itemId: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            color: PropTypes.string,
            numberOfTimesUsed: PropTypes.number,
            usageByCategory: PropTypes.object,
            businessId: PropTypes.string,
            tagId: PropTypes.string,
            createdAt: PropTypes.string,
            updatedAt: PropTypes.string
        })
    ).isRequired
};

/**
 * Edit Icon for Tag with dispatch attached
 * @param {string} id ID of the tag to edit.
 */
export const EditTagIcon = ({ id }) => {
    const dispatch = useDispatch();

    const currentTag = useSelector((state) =>
        state.user?.activeBusiness?.tags?.find(
            (element) => element.tagId === id
        )
    );

    const handleClick = () => {
        if (currentTag)
            dispatch(
                openDrawer({
                    id: 'createTags',
                    tag: currentTag,
                    edit: true
                })
            );
    };

    return (
        <ActionIconWrapper
            action="edit"
            label="Edit"
            onClick={handleClick}
            tooltip="Edit Tag"
        />
    );
};

EditTagIcon.propTypes = {
    id: PropTypes.string.isRequired
};

/**
 * Delete Icon for Tag with toast
 * @param {string} id ID of the tag to delete.
 * @param {string} tag Name of the tag to delete
 */
export const DeleteTagIcon = ({ id, tag }) => {
    const [dialogActive, setDialogAction] = useState(false);
    const { triggerToast } = useToastNotification();
    const params = useParams();
    const { getUrlParams } = useUrlParams();

    const handleClick = () => {
        setDialogAction(true);
    };

    const handleConfirmation = async () => {
        const currentUrlParams = getUrlParams();
        setDialogAction(false);
        await deleteTag(id);

        // If we're on the client page, we only want to return the tags
        // for that client.
        if (params?.clientId) {
            currentUrlParams.client = params.clientId;
        }

        getPaginatedTags({ ...currentUrlParams });
        triggerToast({
            message: `Tag "${tag}" Deleted`,
            action: 'delete',
            icon: <DeleteIcon />
        });
    };

    const handleCancellation = () => {
        setDialogAction(false);
    };

    return (
        <>
            <ActionIconWrapper
                action="delete"
                label="Delete"
                onClick={() => {
                    handleClick();
                }}
                tooltip="Delete Tag"
            />
            <WarnConfirmDialog
                onCancel={() => handleCancellation()}
                onConfirm={() => handleConfirmation()}
                message={`Are you sure you want to delete "${tag}"?`}
                cancelText="Cancel"
                confirmText="Delete Tag"
                open={dialogActive}
            />
        </>
    );
};

DeleteTagIcon.propTypes = {
    id: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired
};
