import React, { useRef, useState, lazy, Suspense } from 'react';
import { Button, Popper, Paper, ClickAwayListener, Fade } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useStyles from './styles';

const CreateButtonMenu = lazy(() => import('./CreateButtonMenu'));

const CreateButton = () => {
    const classes = useStyles();
    const menuContainerRef = useRef();
    const [open, setOpen] = useState(false);
    const [arrowRef, setArrowRef] = useState(null);
    const onMenuClose = () => setOpen(false);

    return (
        <>
            <ClickAwayListener onClickAway={onMenuClose}>
                <Button
                    ref={menuContainerRef}
                    aria-describedby="create-button-menu"
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    className={classes.makeInvoiceButton}
                    onClick={() => setOpen(true)}
                    classes={{ startIcon: classes.startIcon }}
                    data-cy="titlebar-create-button"
                    aria-label="Create"
                >
                    <span className={classes.makeInvoiceButtonText}>
                        Create
                    </span>
                </Button>
            </ClickAwayListener>

            <Popper
                transition
                id="create-button-menu"
                open={open}
                anchorEl={menuContainerRef.current}
                onClose={onMenuClose}
                placement="bottom-end"
                className={classes.popover}
                data-cy="create-button-popover"
                modifiers={[
                    {
                        name: 'arrow',
                        enabled: true,
                        options: {
                            element: arrowRef
                        }
                    }
                ]}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={250}>
                        <Paper elevation={4} className={classes.paper}>
                            <Suspense
                                fallback={
                                    <div
                                        style={{
                                            width: 214
                                        }}
                                    />
                                }
                            >
                                <CreateButtonMenu closeEvent={onMenuClose} />
                            </Suspense>
                            <span className={classes.arrow} ref={setArrowRef} />
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </>
    );
};

export default CreateButton;
