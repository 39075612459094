import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    deleteProduct,
    getPaginatedProducts
} from '@/modules/dataWrangler/dataWrangler';
import { DeleteIcon } from '@/resources/icons';
import { openDrawer } from '@/state/actions';
import { useToastNotification, useUrlParams } from '@/hooks';

import WarnConfirmDialog from '../../WarnConfirmDialog';
import ActionIconWrapper from '../ActionIconWrapper';
/**
 * Edit Icon for Service with dispatch attached
 * @param {string} id ID of the service to edit.
 */
export const EditServiceIcon = ({ id }) => {
    const dispatch = useDispatch();

    const currentService = useSelector((state) =>
        state.user?.activeBusiness?.products?.find(
            (element) => element.productId === id
        )
    );

    const handleClick = () => {
        dispatch(
            openDrawer({
                id: 'service',
                service: currentService,
                edit: true
            })
        );
    };

    return (
        <ActionIconWrapper
            action="edit"
            label="Edit"
            onClick={handleClick}
            tooltip="Edit Service"
        />
    );
};

EditServiceIcon.propTypes = {
    id: PropTypes.string.isRequired
};

/**
 * Delete Icon for Service with dispatch attached
 * @param {string} id ID of the product to delete.
 */
export const DeleteServiceIcon = ({ id, service }) => {
    const [dialogActive, setDialogAction] = useState(false);
    const { triggerToast } = useToastNotification();
    const { getUrlParams } = useUrlParams();

    const handleClick = () => {
        setDialogAction(true);
    };

    const handleConfirmation = async () => {
        const currentUrlParams = getUrlParams();
        setDialogAction(false);
        await deleteProduct(id);
        getPaginatedProducts({ type: 'service', args: currentUrlParams });
        triggerToast({
            message: `"${service}" Deleted`,
            action: 'delete',
            icon: <DeleteIcon />
        });
    };

    const handleCancellation = () => {
        setDialogAction(false);
    };

    return (
        <>
            <ActionIconWrapper
                action="delete"
                label="Delete"
                onClick={() => {
                    handleClick();
                }}
                tooltip="Delete Service"
            />
            <WarnConfirmDialog
                onCancel={() => handleCancellation()}
                onConfirm={() => handleConfirmation()}
                message={`Are you sure you want to delete "${service}"?`}
                cancelText="Cancel"
                confirmText="Delete Service"
                open={dialogActive}
            />
        </>
    );
};

DeleteServiceIcon.propTypes = {
    id: PropTypes.string.isRequired,
    service: PropTypes.string.isRequired
};
