import makeStyles from '@mui/styles/makeStyles';

const button = (theme, primary, noColumnReverse) => ({
    height: 44,
    fontWeight: 600,
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(12),
    padding: 8,
    [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(14),
        backgroundColor: primary
            ? theme.palette.primary.main
            : theme.palette.background.paper,
        border: !primary && '1px solid #E0E5EE'
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '1rem'
    },
    [theme.breakpoints.up('xl')]: {
        margin: 0,
        width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
        backgroundColor: '#fff',
        height: 'auto',
        padding: 0,
        color: theme.palette.text.secondary,
        display: 'flex',
        fontWeight: 400,
        flexDirection: noColumnReverse ? 'column' : 'column-reverse',
        justifyContent: 'center',
        '& .MuiButton-endIcon': {
            alignItems: 'center',
            backgroundColor: primary
                ? theme.palette.primary.main
                : theme.palette.imBrand.lightBlue,
            borderRadius: '50%',
            display: 'flex',
            height: theme.typography.pxToRem(40),
            margin: 0,
            justifyContent: 'center',
            width: theme.typography.pxToRem(40)
        },
        '& .MuiButton-startIcon': {
            alignItems: 'center',
            backgroundColor: primary
                ? theme.palette.primary.main
                : theme.palette.imBrand.lightBlue,
            borderRadius: '50%',
            display: 'flex',
            height: theme.typography.pxToRem(40),
            margin: 0,
            justifyContent: 'center',
            width: theme.typography.pxToRem(40)
        },
        '&:hover': {
            backgroundColor: '#fff'
        }
    }
});

export default makeStyles((theme) => ({
    sidebar: {
        [theme.breakpoints.down('xl')]: {
            display: 'flex',
            justifyContent: 'center'
        }
    },
    container: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('xl')]: {
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'flex-start',
            padding: theme.spacing(1.5, 0, 1.5, 1.5),
            width: '80%'
        },
        [theme.breakpoints.down('lg')]: {
            width: '100%'
        },
        [theme.breakpoints.up('xl')]: {
            padding: theme.spacing(0, 0, 0, 3),
            position: 'sticky',
            top: theme.typography.pxToRem(86),
            width: theme.typography.pxToRem(240)
        }
    },
    section: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(2),
        flex: 1,
        [theme.breakpoints.down('xl')]: {
            marginBottom: 0,
            marginRight: theme.spacing(1.5)
        }
    },
    switchSection: {
        margin: theme.spacing(3, 0)
    },
    switches: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(0.5)
    },
    mobileToggleWrap: {
        padding: theme.spacing(2),
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(5, 2),
            width: theme.typography.pxToRem(300)
        }
    },
    popOverButton: {
        fontWeight: 600,
        textTransform: 'none',
        fontSize: theme.typography.pxToRem(16),
        padding: theme.spacing(1),
        minWidth: theme.typography.pxToRem(190),
        border: `1px solid ${theme.palette.neutral.contrastText}`,
        color: theme.palette.neutral.contrastText
    },
    negativePopOverButton: {
        fontWeight: 600,
        textTransform: 'none',
        fontSize: theme.typography.pxToRem(16),
        padding: theme.spacing(1),
        color: theme.palette.actionColors.negative,
        background: 'none',
        borderColor: theme.palette.actionColors.negative,
        borderRadius: 4,
        minWidth: theme.typography.pxToRem(190),
        '&:hover': {
            borderColor: theme.palette.text.red
        }
    },
    primaryButton: button(theme, true),
    button: {
        ...button(theme, false),
        '&:disabled': {
            color: theme.palette.text.secondary,
            backgroundColor: `#fff !important`
        }
    },
    negativeButton: {
        color: theme.palette.actionColors.negative,
        background: 'none',
        borderColor: theme.palette.actionColors.negative,
        borderRadius: 4,
        '&:hover': {
            borderColor: theme.palette.text.red
        },
        '& .MuiButton-endIcon': {
            backgroundColor: '#FEE2E2'
        },
        '& .MuiButton-startIcon': {
            backgroundColor: '#FEE2E2'
        },
        [theme.breakpoints.down('sm')]: {
            border: 'none',
            '&:hover': {
                border: 'none'
            }
        }
    },
    approveButton: {
        [theme.breakpoints.up('sm')]: {
            color: theme.palette.secondary.main,
            background: theme.palette.actionColors.positive,
            '&:hover': {
                background: '#005532'
            }
        }
    },
    approvePopOverButton: {
        fontWeight: 600,
        textTransform: 'none',
        fontSize: theme.typography.pxToRem(16),
        padding: theme.spacing(1),
        color: theme.palette.secondary.main,
        background: theme.palette.actionColors.positive,
        '&:hover': {
            background: '#005532'
        }
    },
    buttonApproveDeclineSvg: {
        width: 40,
        height: 40,
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    buttonSendSvg: {
        fill: theme.palette.sidebarSvg.active,
        width: 17,
        height: 15
    },
    buttonRecurring: {
        ...button(theme, false, true),
        '&:disabled': {
            color: theme.palette.text.secondary,
            backgroundColor: `#fff !important`
        }
    },
    buttonRecurringSvg: {
        width: 16,
        height: 16,
        [theme.breakpoints.down('sm')]: {
            width: 24,
            height: 24
        }
    },
    buttonSaveSvg: {
        fill: theme.palette.imBrand.midBlueHover,
        width: 24,
        height: 24,
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    buttonCopyLinkSvg: {
        fill: theme.palette.sidebarSvg.inactive,
        width: 24,
        height: 24,
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    buttonDownloadSvg: {
        fill: theme.palette.sidebarSvg.inactive,
        width: 24,
        height: 24,
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    buttonPreviewSvg: {
        fill: theme.palette.sidebarSvg.inactive,
        width: 27,
        height: 17,
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    buttonVoidSvg: {
        width: 27,
        height: 27,
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    buttonDeleteSvg: {
        '& path': {
            fill: '#991B1B'
        },
        width: 20,
        height: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    buttonMarkUnpaidSvg: {
        '& path': {
            fill: '#991B1B'
        },
        width: 20,
        height: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    buttonOptionsSvg: {
        fill: theme.palette.sidebarSvg.inactive,
        width: 24,
        height: 6,
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    buttonAddPaymentSvg: {
        '& path': {
            fill: theme.palette.imBrand.midBlueHover
        },
        width: 24,
        height: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    switchLabel: {
        fontWeight: 600,
        fontSize: '.9rem',
        letterSpacing: '.04em'
    },
    switch: {
        marginRight: theme.typography.pxToRem(-10)
    },
    shippingSwitchLabel: {
        color: ({ shippingDisabled }) =>
            shippingDisabled ? '#8C92A4' : theme.palette.text.primary
    },
    selectLabel: {
        color: theme.palette.primary.main,
        fontWeight: 600
    },
    selectBox: {
        backgroundColor: theme.palette.secondary.main,
        fontWeight: 600,
        color: '#656B7D'
    },
    desktopToggles: {
        display: 'none',
        [theme.breakpoints.up('xl')]: {
            display: 'block'
        }
    },
    smallerOptions: {
        display: 'block',
        width: '100%',
        [theme.breakpoints.up('xl')]: {
            display: 'none'
        }
    },
    desktopState: {
        display: 'none',
        [theme.breakpoints.up('xl')]: {
            display: 'block'
        }
    },
    desktopEstimateActionStateContainer: {
        display: 'none',
        [theme.breakpoints.up('xl')]: {
            display: 'block',
            borderTop: '1px solid #DEDEE7',
            marginTop: theme.typography.pxToRem(25),
            marginBottom: 0,
            paddingTop: theme.typography.pxToRem(24)
        }
    },
    desktopEstimateActionStateContainerLocked: {
        display: 'none',
        [theme.breakpoints.up('xl')]: {
            display: 'block',
            borderTop: 'none',
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0
        }
    },
    hideCloseButton: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    },
    popOver: {
        transform: 'translate(0,-66px)'
    },
    skeleton: {
        height: '70px !important',
        width: '208px'
    },
    reminderButtonCount: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontWeight: 600,
        borderRadius: '50%',
        width: 20,
        height: 20,
        padding: 0
    },
    receiptLowerSidebarContainer: {
        flex: 1,
        [theme.breakpoints.up('xl')]: {
            borderTop: '1px solid #DEDEE7',
            marginTop: theme.typography.pxToRem(9),
            marginBottom: 0,
            paddingTop: theme.typography.pxToRem(24)
        }
    },
    estimateConversionButton: {
        width: '100%',
        [theme.breakpoints.up('xl')]: {
            borderTop: '1px solid #DEDEE7',
            marginTop: theme.typography.pxToRem(9),
            marginBottom: 0,
            paddingTop: theme.typography.pxToRem(24)
        }
    },
    buttonConversionSVG: {
        width: 24,
        height: 24
    },
    conversionTextUnavailable: {
        color: theme.palette.text.disabled
    },
    convertModalIcon: {
        width: 91,
        height: 91
    },
    sizer: {
        display: 'block',
        width: 60,
        height: 61,
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            height: 44
        }
    }
}));
