import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom-v5-compat';
import useStyles from './styles';

const EmptyState = ({
    image,
    content,
    buttonText,
    buttonLink,
    onClick,
    subContent
}) => {
    const classes = useStyles();

    return (
        <div className={classes.wrap}>
            <div className={classes.image}>{image}</div>
            {content && (
                <Typography className={classes.content}>{content}</Typography>
            )}
            {subContent && (
                <Typography className={classes.subContent}>
                    {subContent}
                </Typography>
            )}
            {buttonText && onClick && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClick}
                    className={classes.button}
                >
                    {buttonText}
                </Button>
            )}
            {buttonText && buttonLink && (
                <Button
                    component={Link}
                    variant="contained"
                    color="primary"
                    to={buttonLink}
                    className={classes.button}
                >
                    {buttonText}
                </Button>
            )}
        </div>
    );
};

EmptyState.propTypes = {
    image: PropTypes.element,
    content: PropTypes.string,
    buttonText: PropTypes.string,
    buttonLink: PropTypes.string,
    onClick: PropTypes.func,
    subContent: PropTypes.string
};

EmptyState.defaultProps = {
    image: null,
    content: null,
    buttonText: null,
    buttonLink: null,
    onClick: null,
    subContent: null
};

/**
 * Template component for empty states on pages such as Invoices, Estimates,
 * Clients, and Products.
 *
 * If any of these props are not included, they will not populate the page and
 * the component will still render.
 * @param {element} image Image to be displayed on the page.
 * @param {string} content Content to be displayed under the image.
 * @param {string} buttonText Text for the button
 * @param {string} buttonLink Relative url for the button.
 * @param {func} OnClick Provide an onclick function to the button.
 * @param {bool} hidePaper Option to remove the Paper component.
 */
const PageEmptyState = ({
    image,
    content,
    buttonText,
    buttonLink,
    onClick,
    subContent,
    hidePaper
}) =>
    hidePaper ? (
        <EmptyState
            image={image}
            content={content}
            buttonText={buttonText}
            buttonLink={buttonLink}
            onClick={onClick}
            subContent={subContent}
        />
    ) : (
        <Paper elevation={0} variant="outlined">
            <EmptyState
                image={image}
                content={content}
                buttonText={buttonText}
                buttonLink={buttonLink}
                onClick={onClick}
                subContent={subContent}
            />
        </Paper>
    );

PageEmptyState.propTypes = {
    image: PropTypes.element,
    content: PropTypes.string,
    buttonText: PropTypes.string,
    buttonLink: PropTypes.string,
    onClick: PropTypes.func,
    subContent: PropTypes.string,
    hidePaper: PropTypes.bool
};

PageEmptyState.defaultProps = {
    image: null,
    content: null,
    buttonText: null,
    buttonLink: null,
    onClick: null,
    subContent: null,
    hidePaper: false
};

export default PageEmptyState;
