/* eslint import/no-cycle: "off"  */
import store from '@/state/store';

import { globalPageCount } from '@/config';

import {
    fetchListingItems,
    setDocumentState,
    noListingItems,
    overwriteListingItems,
    documentStoppedLoading,
    fetchDocument,
    setDocumentReminders
} from '@/state/actions';

import {
    convertRecurringFromApiSetLocalization,
    createSaveableObject,
    filterArgFlattener
} from '@/util';

import axiosAuthenticated from '../authenticatedRequestor';
import * as segmentManager from '../segment/segmentManager';

export const createRecurringInvoice = async (invoice) => {
    const { activeBusiness } = store.getState().user;
    const tagIds = invoice?.tags?.map((tag) => tag.tagId) || [];

    try {
        const response = await axiosAuthenticated.post(
            `/businesses/${activeBusiness.businessId}/recurring-invoices`,
            {
                ...invoice,
                tagIds,
                design: activeBusiness.documentCustomizations.design,
                disablePaymentStamp:
                    activeBusiness.documentCustomizations.disablePaymentStamp,
                logo: activeBusiness.logo || ''
            }
        );

        const newInvoice = convertRecurringFromApiSetLocalization(
            response.data
        );

        return newInvoice;
    } catch {
        return false;
    }
};

export const duplicateRecurringInvoice = async (invoiceId) => {
    // Get the active business.
    const { activeBusiness } = store.getState().user;
    const { businessId } = activeBusiness;

    // Get the specific recurring invoice to be duplicated.
    const res = await axiosAuthenticated.get(
        `/businesses/${businessId}/recurring-invoices/${invoiceId}`
    );

    const foundInvoice = res?.data;

    if (foundInvoice) {
        const convertedInvoice =
            convertRecurringFromApiSetLocalization(foundInvoice);
        const invoice = { ...convertedInvoice };

        // firstSendDate & lastSendDate - if in the past set to today
        if (invoice.firstSendDate < new Date()) {
            invoice.firstSendDate = new Date();
        }

        if (invoice.lastSendDate < new Date()) {
            invoice.lastSendDate = new Date();
        }

        // Pass the new invoice object to API.
        const newInvoice = await createRecurringInvoice(
            createSaveableObject({
                type: 'recurring-invoice',
                currentObject: invoice,
                activeBusiness
            })
        );

        await segmentManager.documentStarted('recurring-invoice');

        return newInvoice;
    }

    return false;
};

export const getRecurringInvoices = async (args) => {
    if (store.getState().user.temporaryUser) {
        store.dispatch(noListingItems({ type: 'recurring-invoice' }));
        return;
    }

    store.dispatch(fetchListingItems({ type: 'recurring-invoice' }));

    const { businessId } = store.getState().user.activeBusiness;

    const allArgs = { pageSize: globalPageCount, ...filterArgFlattener(args) };
    const response = await axiosAuthenticated.get(
        `/businesses/${businessId}/recurring-invoices`,
        { params: { sortOrder: 'desc', sortType: 'createdAt', ...allArgs } }
    );

    const { items, itemsMatchingRequest, pageSize, totalItems } = response.data;

    // Destructure into flatter object
    const listingItems = items.map((invoice) =>
        convertRecurringFromApiSetLocalization(invoice)
    );

    const totalPages = Math.ceil(itemsMatchingRequest / pageSize);

    store.dispatch(
        overwriteListingItems({
            type: 'recurring-invoice',
            items: listingItems,
            totalPages,
            totalItems
        })
    );
};

export const getRecurringInvoiceById = async (documentId) => {
    if (store.getState().user.temporaryUser) {
        return false;
    }

    store.dispatch(fetchDocument());

    const { businessId } = store.getState().user?.activeBusiness;

    const res = await axiosAuthenticated.get(
        `/businesses/${businessId}/recurring-invoices/${documentId}`
    );

    const { state } = res.data;

    if (state === 'deleted') {
        // eslint-disable-next-line no-throw-literal
        throw 'recurring invoice has been deleted';
    }

    const newRecurringInvoice = convertRecurringFromApiSetLocalization(
        res.data
    );

    store.dispatch(documentStoppedLoading());

    return newRecurringInvoice;
};

export const updateRecurringInvoice = async (invoice) => {
    const { businessId } = store.getState().user.activeBusiness;

    await axiosAuthenticated.put(
        `/businesses/${businessId}/recurring-invoices/${invoice.documentId}`,
        invoice
    );

    return true;
};

export const deleteRecurringInvoice = async (documentId) => {
    const { businessId } = store.getState().user.activeBusiness;

    await axiosAuthenticated.delete(
        `/businesses/${businessId}/recurring-invoices/${documentId}`
    );
};

export const startRecurringInvoice = async (documentId) => {
    const { businessId } = store.getState().user.activeBusiness;

    try {
        const response = await axiosAuthenticated.post(
            `/businesses/${businessId}/recurring-invoices/${documentId}/start`
        );

        const newInvoice = convertRecurringFromApiSetLocalization(
            response.data
        );

        return newInvoice;
    } catch {
        return false;
    }
};

export const stopRecurringInvoice = async (documentId) => {
    const { businessId } = store.getState().user.activeBusiness;

    try {
        const response = await axiosAuthenticated.post(
            `/businesses/${businessId}/recurring-invoices/${documentId}/stop`
        );

        const newInvoice = convertRecurringFromApiSetLocalization(
            response.data
        );

        store.dispatch(
            setDocumentState({ document: newInvoice, locked: true })
        );

        return newInvoice;
    } catch {
        return false;
    }
};

/**
 * @param {string} documentId Id for this document
 * @param {array} reminders object of reminders associated with this document
 */
export const updateLockedRecurringInvoiceReminders = async ({
    documentId,
    reminders
}) => {
    const { businessId } = store.getState().user.activeBusiness;

    await axiosAuthenticated.put(
        `/businesses/${businessId}/recurring-invoices/${documentId}/reminders`,
        reminders
    );

    store.dispatch(setDocumentReminders(reminders));
};
