import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    wrap: {
        width: theme.typography.pxToRem(200),
        margin: `${theme.typography.pxToRem(60)} auto 0 auto`,
        [theme.breakpoints.up('sm')]: {
            width: theme.typography.pxToRem(300)
        }
    }
}));
