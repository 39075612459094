import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Button } from '@mui/material';

import useStyles from './styles';

/**
 * Builds on top of the standard Button component provided
 * by Material UI.
 *
 * Shows a loading icon on the right of the button when the
 * loading prop is set to true. Also adds accessible loading
 * text and aria roles for screen reading technology.
 *
 * It takes all standard props that the Button
 * would usually take, plus the below.
 * @param {mixed} children String or elements to be passed inside the Button.
 * @param {bool} disabled Is the Button disabled.
 * @param {bool} disableOnLoading Should Button be disabled when loading.
 * @param {bool} loading Should the button show loading animation.
 * @param {string} spinnerColor What is the spinner colour: primary/secondary
 */
const ButtonLoading = ({
    children,
    disabled,
    disableOnLoading,
    loading,
    spinnerColor,
    centerSpinner,
    ...rest
}) => {
    const classes = useStyles({ centerSpinner });
    return (
        <Button disabled={(disableOnLoading && loading) || disabled} {...rest}>
            {children}
            {loading && (
                <>
                    <CircularProgress
                        className={classes.spinner}
                        color={spinnerColor}
                        size={20}
                    />
                    <span
                        className={classes.accessibleLoader}
                        role="alert"
                        aria-busy="true"
                    >
                        loading...
                    </span>
                </>
            )}
        </Button>
    );
};

ButtonLoading.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.node
    ]),
    disabled: PropTypes.bool,
    disableOnLoading: PropTypes.bool,
    loading: PropTypes.bool,
    spinnerColor: PropTypes.string,
    centerSpinner: PropTypes.bool
};

ButtonLoading.defaultProps = {
    children: '',
    disabled: false,
    disableOnLoading: false,
    loading: false,
    spinnerColor: 'secondary',
    centerSpinner: false
};

export default ButtonLoading;
