import { format } from 'date-fns';

export const addDaysToDate = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

/**
 * Returns the current date as an ISO string.
 */
export const getCurrentDateString = () => new Date(Date.now()).toISOString();

/**
 *
 * @param {string} dateToCheck A date string formatted with .toISOString()
 * @returns A boolean of whether or not the date passed in is past the current date
 */
export const checkIfDateIsPassed = (dateToCheck) => {
    const today = getCurrentDateString();
    return Boolean(today > dateToCheck);
};

/**
 * Returns the current date/time file friendly string.
 * @returns {string} Example: 2020-01-01-12-00-00
 */
export const getCurrentDateFileFriendlyString = () => {
    const date = new Date();
    const formattedDate = format(date, 'yyyy-MM-dd-HH-mm-ss');
    return formattedDate;
};
