import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom-v5-compat';
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';

import {
    updateClient,
    duplicateEstimate,
    shareEstimate,
    getDocumentById,
    getAllEstimates
} from '@/modules/dataWrangler/dataWrangler';
import {
    SendIcon,
    DuplicateIcon,
    DuplicateIconPopup,
    DownloadIcon,
    ConvertIconGreen
} from '@/resources/icons';
import { PDF_CREATED } from '@/constants';
import { delayAwait, downloadPdf } from '@/util';
import { LottieDownloadLoading, LottieSendingLoading } from '@/lottie';
import { clientModel, clientDefaultModel } from '@/models';
import { useToastNotification, useSubscription, useUrlParams } from '@/hooks';
import { useConvertEstimate } from '@/hooks/useSidebarActions';

import NoEmailModal from '../../NoEmailModal';
import LottieModal from '../../LottieModal';
import WarnConfirmDialog from '../../WarnConfirmDialog';
import ActionIconWrapper from '../ActionIconWrapper';

const useStyles = makeStyles(() => ({
    send: {
        width: 19,
        height: 16
    },
    cleanIcon: { fill: 'none !important' },
    convertModalIcon: { width: 91, height: 91 }
}));

/**
 * Send estimate with dispatch attached
 * @param {object} client Client object of client to send to.
 * @param {string} id ID of the estimate to send.
 */
export const SendEstimateIcon = ({ client, id }) => {
    let timeoutManager;
    const [modalActive, setModal] = useState(false);
    const [showNoEmailModal, setShowNoEmailModal] = useState(false);
    const { triggerToast } = useToastNotification();
    const params = useParams();
    const { getUrlParams } = useUrlParams();
    const classes = useStyles();

    useEffect(() => clearTimeout(timeoutManager), [timeoutManager]);

    const manageEmailSend = async (email) => {
        const startTime = Date.now();
        const currentUrlParams = getUrlParams();
        setModal(true);
        const success = await shareEstimate(id, 'email', email);
        await delayAwait(startTime, 2000);
        if (success) {
            setModal(false);

            // If we're on the client page, we only want to return the estimates
            // for that client.
            if (params?.clientId) {
                currentUrlParams.client = params.clientId;
                currentUrlParams.prefilters = 'clientId';
            }

            getAllEstimates(currentUrlParams);
            triggerToast({
                message: `Estimate #${
                    success.document?.customLabel || success.referenceNumber
                } Sent`,
                action: 'send',
                icon: <SendIcon className={classes.send} />
            });
        }
    };

    const sendEstimate = async () => {
        if (!client.emailAddress) {
            setShowNoEmailModal(true);
        } else {
            manageEmailSend();
        }
    };

    return (
        <>
            <ActionIconWrapper
                action="send"
                label="Send"
                listOnTablet
                onClick={() => {
                    sendEstimate(id);
                }}
                tooltip="Send Estimate"
            />
            <LottieModal
                title="Sending Estimate..."
                backgroundColor="#F5F9FF"
                open={modalActive}
            >
                <LottieSendingLoading />
            </LottieModal>
            <NoEmailModal
                open={showNoEmailModal}
                onClose={async (emailAddress, saveToClient) => {
                    if (emailAddress === undefined) {
                        setShowNoEmailModal(false);
                        return;
                    }
                    setShowNoEmailModal(false);

                    if (saveToClient) {
                        const updatedClient = {
                            ...client,
                            emailAddress
                        };
                        updateClient(updatedClient);
                    }

                    manageEmailSend(emailAddress);
                }}
                type="estimate"
            />
        </>
    );
};

SendEstimateIcon.propTypes = {
    id: PropTypes.string.isRequired,
    client: clientModel
};

SendEstimateIcon.defaultProps = clientDefaultModel;

/**
 * View Icon with link attached.
 * @param {string} id ID of the Estimate to view.
 */
export const ViewEstimateIcon = ({ id }) => (
    <ActionIconWrapper
        action="view"
        label="View"
        link={`/estimate/${id}`}
        tooltip="View Estimate"
    />
);

ViewEstimateIcon.propTypes = {
    id: PropTypes.string.isRequired
};

/**
 * Download Estimate Icon with dispatch attached
 * @param {string} id ID of the estimate to download.
 * @param {string} label Name to append to "estimate-XXX.pdf".
 */
export const DownloadEstimateIcon = ({ id, label }) => {
    const [modalActive, setModal] = useState(false);
    const { triggerToast } = useToastNotification();
    const params = useParams();
    const { getUrlParams } = useUrlParams();
    let timeoutManager;

    useEffect(() => clearTimeout(timeoutManager), [timeoutManager]);

    const downloadEstimate = async () => {
        const startTime = Date.now();
        const currentUrlParams = getUrlParams();
        setModal(true);
        const res = await shareEstimate(id, 'download');
        await delayAwait(startTime, 2000);
        setModal(false);
        if (res?.status === PDF_CREATED) {
            downloadPdf({ name: `estimate-${label}`, base64: res.pdf });

            // If we're on the client page, we only want to return the estimates
            // for that client.
            if (params?.clientId) {
                currentUrlParams.client = params.clientId;
                currentUrlParams.prefilters = 'clientId';
            }

            getAllEstimates(currentUrlParams);
            triggerToast({
                message: `Estimate Downloaded`,
                action: 'saved',
                icon: <DownloadIcon />
            });
        }
    };

    return (
        <>
            <ActionIconWrapper
                action="download"
                label="Download"
                listOnTablet
                onClick={() => {
                    downloadEstimate();
                }}
                tooltip="Download Estimate"
            />
            <LottieModal
                title="Downloading Estimate..."
                backgroundColor="#F5F9FF"
                open={modalActive}
            >
                <LottieDownloadLoading />
            </LottieModal>
        </>
    );
};

DownloadEstimateIcon.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};

/**
 * Edit Estimate Icon with dispatch attached
 * @param {string} id ID of the estimate to edit.
 */
export const EditEstimateIcon = ({ id }) => (
    <ActionIconWrapper
        action="edit"
        label="Edit"
        link={`/estimate/${id}`}
        tooltip="Edit Estimate"
    />
);

EditEstimateIcon.propTypes = {
    id: PropTypes.string.isRequired
};

/**
 * Duplicate Estimate Icon with dispatch attached
 * @param {string} id ID of the estimate to duplicate.
 * @param {string} label Estimate number or custom label to duplicate
 */
export const DuplicateEstimateIcon = ({ id, label }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const subscriptionData = useSubscription();

    const [duplicateDialogActive, setDuplicateDialogActive] = useState(false);

    const handleClick = () => {
        const subInfo = subscriptionData?.components?.document;

        const blockDocumentCreation =
            !subInfo?.enabled ||
            (subInfo?.limited && subInfo?.amountRemaining < 1);

        if (blockDocumentCreation) {
            // If the user has no more documents remaining, force them
            // to subscription page.
            navigate('/settings/subscription');
        } else {
            setDuplicateDialogActive(true);
        }
    };

    const { triggerToast } = useToastNotification();

    const handleConfirmation = async () => {
        setDuplicateDialogActive(false);

        const newEstimate = await duplicateEstimate(id);

        if (newEstimate) {
            triggerToast({
                message: `Estimate ${label} Duplicated`,
                action: 'duplicate',
                icon: <DuplicateIcon />
            });

            setTimeout(() => {
                navigate(`/estimate/${newEstimate.documentId}`);
            }, 1000);
        }
    };

    const handleCancellation = () => {
        setDuplicateDialogActive(false);
    };

    return (
        <>
            <ActionIconWrapper
                action="duplicate"
                label="Duplicate"
                listOnTablet
                onClick={() => {
                    handleClick(id);
                }}
                tooltip="Duplicate Estimate"
            />
            <WarnConfirmDialog
                onCancel={() => handleCancellation()}
                onConfirm={() => handleConfirmation()}
                customIcon={<DuplicateIconPopup />}
                confirmColor={theme.palette.primary.main}
                message="Are you sure you want to duplicate this estimate?"
                cancelText="Cancel"
                confirmText="Duplicate Estimate"
                open={duplicateDialogActive}
            />
        </>
    );
};

DuplicateEstimateIcon.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};

/**
 * Convert Estimate to Invoice icon with dispatch attached
 * @param {string} id ID of the estimate to convert.
 * @param {string} documentState documentState of estimate to convert.
 */
export const ConvertEstimateIcon = ({ id, documentState }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const subscriptionData = useSubscription();
    const convertEstimate = useConvertEstimate();
    const classes = useStyles();

    const [convertDialogActive, setConvertDialogActive] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);

    const handleClick = () => {
        const subInfo = subscriptionData?.components?.document;

        const blockDocumentCreation =
            !subInfo?.enabled ||
            (subInfo?.limited && subInfo?.amountRemaining < 1);

        if (blockDocumentCreation) {
            // If the user has no more documents, remaining force them
            // to subscription page.
            navigate('/settings/subscription');
        } else {
            setConvertDialogActive(true);
        }
    };

    const handleConfirmation = async () => {
        setButtonLoading(true);

        const estimate = await getDocumentById(id);

        await convertEstimate(estimate);

        setConvertDialogActive(false);
        setButtonLoading(false);
    };

    const handleCancellation = () => {
        setConvertDialogActive(false);
    };

    return documentState === 'approved' ? (
        <>
            <ActionIconWrapper
                action="convert"
                label="Copy To Invoice"
                listOnTablet
                onClick={handleClick}
                tooltip="Copy To Invoice"
                documentState={documentState}
            />
            <WarnConfirmDialog
                onCancel={() => handleCancellation()}
                onConfirm={() => handleConfirmation()}
                customIcon={
                    <ConvertIconGreen className={classes.convertModalIcon} />
                }
                confirmColor={theme.palette.primary.main}
                message="Are you sure you want to copy this Estimate into an Invoice?"
                cancelText="Cancel"
                confirmText="Copy To Invoice"
                open={convertDialogActive}
                buttonLoading={buttonLoading}
            />
        </>
    ) : (
        <ActionIconWrapper
            action="convert"
            buttonDisabled
            label="Copy To Invoice"
            listOnTablet
            onClick={() => {}}
            tooltip="Only approved Estimates can be copied into an Invoice"
        />
    );
};

ConvertEstimateIcon.propTypes = {
    id: PropTypes.string.isRequired,
    documentState: PropTypes.string.isRequired
};
