import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { FormControlLabel, Typography } from '@mui/material';
import {
    DrawerBody,
    DrawerHeading,
    DrawerFooter,
    UncontrolledGenericDrawer
} from '@/components/common';
import { Checkbox } from '@/components/rhf-mui';
import { useDrawers, useToastNotification } from '@/hooks';
import { ReminderBell, SaveIcon } from '@/resources/icons';
import { closeDrawer, setDocumentReminders } from '@/state/actions';
import { updateLockedDocumentReminders } from '@/modules/dataWrangler/document';
import { reminderOptions } from '@/config';
import { updateLockedRecurringInvoiceReminders } from '@/modules/dataWrangler/recurring-invoices';
import useStyles from './styles';

const CheckboxOptions = ({ allOptions, disable, type }) => {
    const classes = useStyles();

    return reminderOptions[type].map(({ name, slug }) => {
        // Check if this option is already selected.
        const checked = Boolean(allOptions[slug]);

        const disableCheckbox = disable && !checked;

        return (
            <FormControlLabel
                label={<Typography variant="body2">{name}</Typography>}
                control={<Checkbox name={slug} />}
                classes={{ root: classes.checkboxOptions }}
                disabled={disableCheckbox}
                key={slug}
            />
        );
    });
};

CheckboxOptions.propTypes = {
    type: PropTypes.string.isRequired
};

const RemindersDrawer = ({ recurring }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { getDrawer } = useDrawers();
    const { triggerToast } = useToastNotification();
    const [saving, setSaving] = useState(false);

    const remindersDrawer = getDrawer('reminders');
    const drawerOpen = Boolean(remindersDrawer);

    const reminders = useMemo(
        () => remindersDrawer?.item?.reminders || [],
        [remindersDrawer]
    );

    const documentLocked = remindersDrawer?.locked || false;

    const methods = useForm({
        mode: 'onSubmit',
        defaultValues: reminders
    });

    const { handleSubmit, setValue, watch, reset } = methods;

    // If there are 3 or more reminders, disable all inputs.
    const currentSelection = watch();
    const disableInputs =
        Object.keys(currentSelection).filter((key) => Boolean(watch(key)))
            .length >= 3;

    useEffect(() => {
        if (drawerOpen) {
            // Reset form values.
            Object.keys(reminders).forEach((key) => {
                setValue(key, reminders[key]);
            });
        }
    }, [drawerOpen, reminders, setValue]);

    const handleCloseDrawer = () => {
        dispatch(closeDrawer('reminders'));
        reset({});
    };

    const submitForm = async (data) => {
        const cleanData = {};

        // Convert all values to boolean.
        Object.keys(data).forEach((key) => {
            cleanData[key] = Boolean(data[key]);
        });

        setSaving(true);

        if (documentLocked) {
            if (recurring) {
                await updateLockedRecurringInvoiceReminders({
                    documentId: remindersDrawer?.item?.documentId,
                    reminders: cleanData
                });
            } else {
                // Use API directly, as everything else is locked.
                await updateLockedDocumentReminders({
                    documentId: remindersDrawer?.item?.documentId,
                    reminders: cleanData
                });
            }

            triggerToast({
                message: `Updated reminders`,
                action: 'saved',
                icon: <SaveIcon />
            });
        } else {
            // Just update redux, user will save further down the flow.
            dispatch(setDocumentReminders(cleanData));
        }

        setSaving(false);
        handleCloseDrawer();
    };

    return (
        <UncontrolledGenericDrawer
            open={drawerOpen}
            close={handleCloseDrawer}
            id="reminders"
        >
            <FormProvider {...methods}>
                <form
                    onSubmit={handleSubmit(submitForm)}
                    className={classes.remindersDrawerContainer}
                >
                    <DrawerHeading
                        startAdornment={<ReminderBell />}
                        title="Reminders"
                    />
                    <DrawerBody className={classes.remindersDrawerBody}>
                        <Typography
                            variant="h3"
                            className={classes.reminderSubTitle}
                        >
                            Upcoming
                        </Typography>
                        <CheckboxOptions
                            allOptions={currentSelection}
                            type="upcoming"
                            disable={disableInputs}
                        />
                        <Typography
                            variant="h3"
                            className={classes.reminderSubTitle}
                        >
                            Past Due
                        </Typography>
                        <CheckboxOptions
                            allOptions={currentSelection}
                            type="pastDue"
                            disable={disableInputs}
                        />
                        {disableInputs && (
                            <div className={classes.alert}>
                                Maximum 3 reminders per invoice
                            </div>
                        )}
                    </DrawerBody>
                    <DrawerFooter
                        acceptOptions={{
                            label: 'Save',
                            type: 'submit'
                        }}
                        cancelOptions={{
                            label: 'Cancel',
                            action: handleCloseDrawer
                        }}
                        saving={saving}
                    />
                </form>
            </FormProvider>
        </UncontrolledGenericDrawer>
    );
};

export default RemindersDrawer;

RemindersDrawer.propTypes = {
    recurring: PropTypes.bool
};

RemindersDrawer.defaultProps = {
    recurring: false
};
