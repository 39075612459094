import React from 'react';
import { paymentMethods } from '@/config';
import { usePaymentConnections } from '@/hooks';
import PaymentMethodAccordion from './PaymentMethodAccordion';

const PaymentMethods = () => {
    const { connections, preferredPaymentConnections } =
        usePaymentConnections();

    const availableMerchants = paymentMethods.filter(
        (method) => method.canBeConnected
    );

    const zelleConnectionIds = connections.reduce((zelleIds, connection) => {
        if (connection?.application?.includes('zelle')) {
            zelleIds.push(connection?.connectionId);
        }
        return zelleIds;
    }, []);

    const findConnection = (application) => {
        if (application !== 'zelle') {
            return connections.find(
                (item) => item?.application === application
            );
        }

        const zelleConnections = connections.filter((item) =>
            item?.application?.includes('zelle')
        );

        // return an enabled Zelle connection, or if one does not exist return the first Zelle connection matched
        return (
            zelleConnections.find((item) => item.connected && item.enabled) ||
            zelleConnections[0]
        );
    };

    const isMerchantVerified = (merchant) => {
        const connection = findConnection(merchant?.slug);
        if (
            connection?.connectionType === 'qrcode' ||
            connection?.connectionType === 'text'
        ) {
            return Boolean(connection?.connected && connection?.enabled);
        }
        if (connection?.connectionId === '') {
            return false;
        }
        return Boolean(connection?.applicationAccountId && connection?.enabled);
    };

    const isPaymentChannelEnabled = (merchant, paymentChannel) => {
        let acceptedType = 'acceptedCards';
        if (paymentChannel === 'digitalWallets') {
            acceptedType = 'acceptedWallets';
        } else if (paymentChannel === 'otherConnections') {
            acceptedType = 'isDigitalWallet';
        } else if (paymentChannel === 'bankTransfer') {
            acceptedType = 'acceptedBanks';
        }

        // The channel is allowed if "isDigitalWallet"
        // or if it has payment options of the accepted type
        const channelAllowed =
            merchant?.[acceptedType] === true ||
            merchant?.[acceptedType]?.length !== 0;

        if (!channelAllowed) {
            return false;
        }

        const merchantConnection = findConnection(merchant?.slug);

        // If payment option is within "otherConnections"
        // find if the option exists in the preferred connection array
        if (paymentChannel === 'otherConnections') {
            const isMerchantChannelEnabled = Boolean(
                preferredPaymentConnections.otherConnections?.find(
                    (connection) =>
                        // If the merchant is Zelle, we must check if any one of the three Zelle connections is enabled
                        merchant.slug === 'zelle'
                            ? zelleConnectionIds.includes(connection)
                            : merchantConnection?.connectionId === connection
                )
            );
            // need to fix this to account for different zelle connections -- 3 different possibilities for findConnection yet only one will show in preferred payment connections
            return merchantConnection?.connectionId && isMerchantChannelEnabled;
        }

        // Otherwise return if the preferred payment connection matches the connectionId
        return (
            merchantConnection?.connectionId &&
            merchantConnection?.connectionId ===
                preferredPaymentConnections[paymentChannel]
        );
    };

    return (
        <>
            {availableMerchants.map((merchant) => (
                <PaymentMethodAccordion
                    key={merchant.id}
                    active={isMerchantVerified(merchant)}
                    merchant={merchant}
                    connections={connections}
                    preferredPaymentConnections={preferredPaymentConnections}
                    paymentChannels={[
                        {
                            name: 'Credit Cards',
                            channelAllowed:
                                merchant?.acceptedCards.length !== 0,
                            enabled: isPaymentChannelEnabled(
                                merchant,
                                'creditCard'
                            )
                        },
                        {
                            name: 'Digital Wallets',
                            channelAllowed:
                                merchant?.acceptedWallets.length !== 0,
                            enabled: isPaymentChannelEnabled(
                                merchant,
                                'digitalWallet'
                            )
                        },
                        {
                            name: 'Bank Transfers',
                            channelAllowed:
                                merchant?.acceptedBanks.length !== 0,
                            enabled: isPaymentChannelEnabled(
                                merchant,
                                'bankTransfer'
                            )
                        },
                        {
                            name: 'Digital Wallet',
                            channelAllowed: merchant?.isDigitalWallet,
                            enabled: isPaymentChannelEnabled(
                                merchant,
                                'otherConnections'
                            )
                        }
                    ]}
                />
            ))}
        </>
    );
};

export default PaymentMethods;
