import store from '@/state/store';
import { getCurrencyLocalizationData } from '@/util/localizationHelpers';

export const convertDocumentFromApi = ({ currentObject }) => {
    const products = currentObject?.products || [];
    const totals = currentObject?.totals || [];
    const balanceDue = currentObject?.balanceDue || 0;
    const balancePaid = currentObject?.balancePaid || 0;
    const tags = currentObject?.tags || [];

    const cleanTotals = {
        discount: totals?.discount || 0,
        shipping: totals?.shipping || 0,
        subtotal: totals?.subtotal || 0,
        tax: totals?.tax || 0,
        total: totals?.total || 0
    };

    const productsWithTaxAndDiscountData = products.map((item) => {
        const {
            discounts,
            lineId,
            name,
            price,
            product,
            productId,
            quantity,
            taxes,
            total,
            totalDiscount,
            totalTax
        } = item;

        const description = item?.description || product?.description || '';

        return {
            description,
            discounts,
            lineId,
            name,
            price,
            productId,
            quantity,
            taxes,
            total,
            totalTax,
            totalDiscount
        };
    });

    const result = {
        ...currentObject,
        balanceDue,
        balancePaid,
        products: productsWithTaxAndDiscountData,
        totals: cleanTotals,
        tags
    };

    if (result.transactions) {
        result.transactions =
            currentObject.transactions?.map((transaction) => ({
                ...transaction,
                amount: transaction.amount
            })) || [];
    }

    return result;
};

export const convertDocumentFromApiSetLocalization = (data) => {
    const { localization } = store.getState();
    const locale =
        store.getState().user.activeBusiness?.businessSettings?.locale;

    const {
        balanceDue,
        balancePaid,
        documentId,
        documentState,
        documentType,
        referenceNumber,
        transactions,
        document,
        link,
        tags,
        markedPaidByClientMethod
    } = data;

    const productsWithLineId = document?.products?.map((item, index) => ({
        ...item,
        lineId: index
    }));

    return convertDocumentFromApi({
        currentObject: {
            ...document,
            markedPaidByClientMethod,
            balanceDue,
            balancePaid,
            documentId,
            documentState,
            documentType,
            referenceNumber,
            recurringInvoiceId: data?.recurringInvoiceId,
            amountSent: data?.amountSent,
            transactions,
            link,
            signature: data?.signature,
            products: productsWithLineId,
            localizationSettings: {
                currency: getCurrencyLocalizationData(
                    localization,
                    document.currency,
                    locale
                )
            },
            tags
        }
    });
};

export const convertRecurringFromApiSetLocalization = (data) => {
    const { localization } = store.getState();
    const locale =
        store.getState().user.activeBusiness?.businessSettings?.locale;

    const { currency, state, products } = data;

    const productsWithLineId = products?.map((item, index) => ({
        ...item,
        lineId: index
    }));

    // Remove all data that is not required.
    const cleanData = { ...data };
    delete cleanData.state;

    return convertDocumentFromApi({
        currentObject: {
            ...cleanData,
            balanceDue: cleanData.totals.total,
            documentState: state,
            signature: data?.signature,
            products: productsWithLineId,
            localizationSettings: {
                currency: getCurrencyLocalizationData(
                    localization,
                    currency,
                    locale
                )
            }
        }
    });
};
