import React from 'react';
import {
    ButtonBase,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import useStyles from './styles';

const MenuListButton = ({
    color,
    icon,
    label,
    onClick,
    parent,
    toggleActive
}) => {
    const toggleRotate = toggleActive === label;
    const classes = useStyles({ color, toggleRotate, parent });

    return (
        <ListItem className={classes.listItem} component="li">
            <ButtonBase className={classes.listItemLink} onClick={onClick}>
                <ListItemIcon className={classes.listItemIcon}>
                    {icon}
                </ListItemIcon>
                <ListItemText disableTypography primary={label} />
                <ExpandMoreIcon className={classes.toggle} />
            </ButtonBase>
        </ListItem>
    );
};

MenuListButton.propTypes = {
    color: PropTypes.string,
    icon: PropTypes.node,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    parent: PropTypes.bool,
    toggleActive: PropTypes.string
};

MenuListButton.defaultProps = {
    color: null,
    icon: null,
    onClick: null,
    parent: false,
    toggleActive: ''
};

export default MenuListButton;
