import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@mui/material';
import { DisabledInputField } from '@/components/common';
import { titleCase } from '@/util';
import { saveCustomLabel } from '@/state/actions';

const DocumentNumber = memo(({ documentNumber, documentType }) => {
    const dispatch = useDispatch();
    const [error, setError] = useState('');

    const handleChange = (newCustomLabel) => {
        let updatedCustomLabel;

        // If input is over maximum length, truncate and set error text, otherwise clear any error text
        if (newCustomLabel.length > 10) {
            setError('Maximum Number of Characters Reached');
            updatedCustomLabel = newCustomLabel.substring(0, 10);
        } else {
            setError('');
            updatedCustomLabel = newCustomLabel;
        }

        // Only update if character is a letter, number, or dash
        if (!/[^A-Za-z0-9-]/g.test(newCustomLabel)) {
            const uppercaseCustomLabel = updatedCustomLabel.toUpperCase();
            dispatch(saveCustomLabel(uppercaseCustomLabel));
        }
    };

    const setPlaceholderText = (type) => {
        switch (type) {
            case 'estimate':
                return 'EST001';
            case 'receipt':
                return 'REC001';
            default:
                return 'INV001';
        }
    };

    if (documentType === 'recurring-invoice') {
        return (
            <DisabledInputField
                tooltip="Recurring invoice numbers
are auto generated when sent"
            >
                Invoice Number (auto generated)
            </DisabledInputField>
        );
    }

    return (
        <Grid container direction="column" id="make-invoice-reference-number">
            <TextField
                label={`${titleCase(documentType)} Number`}
                id={`${documentType}number`}
                placeholder={setPlaceholderText(documentType)}
                onChange={(e) => handleChange(e.target.value)}
                onBlur={() => setError('')}
                value={documentNumber}
                error={Boolean(error)}
                helperText={error}
            />
        </Grid>
    );
});

DocumentNumber.propTypes = {
    documentNumber: PropTypes.string,
    documentType: PropTypes.string.isRequired
};

DocumentNumber.defaultProps = {
    documentNumber: ''
};

export default DocumentNumber;
