import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { SpilledCoffee } from '@/resources/images';
import useStyles from './styles';

/**
 * Generic dialog component. Handles own state and is purely to inform users
 * of single action errors/successes.
 * @param {string} confirmText Text to show in confirm button.
 * @param {elem} customIcon React component icon to replace default icon.
 * Falls back to error spilled coffee SVG.
 * @param {bool} iconTop If the icon is to be positioned at the very to of
 * the component.
 * @param {string/elem} message Message to show in dialog box. Can also take
 * React components to allow formatting. Custom components must be styled externally.
 * @param {bool} opaqueBackground Set background to solid white colour.
 * @param {bool} open If the dialog is open or closed.
 * @param {bool} onConfirm Function to fire when user presses button.
 * @param {string} title title to show in dialog box.
 */
const GenericDialog = ({
    confirmText,
    customIcon,
    iconTop,
    message,
    opaqueBackground,
    open,
    onConfirm,
    title
}) => {
    const classes = useStyles({ iconTop });

    let backgroundProps;
    if (opaqueBackground) {
        backgroundProps = { style: { backgroundColor: '#fff' } };
    }

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            disableEscapeKeyDown
            maxWidth="md"
            BackdropProps={backgroundProps}
            classes={{
                root: classes.root
            }}
        >
            <div className={classes.wrap}>
                <div className={classes.icon}>
                    {customIcon || <SpilledCoffee />}
                </div>

                <div className={classes.contentWrap}>
                    <h2 id="alert-dialog-title" className={classes.title}>
                        {title}
                    </h2>
                    {message && (
                        <div className={classes.messageWrap}>
                            <div
                                data-testid="generic-dialog-message"
                                className={classes.message}
                            >
                                {message}
                            </div>
                        </div>
                    )}
                </div>

                {onConfirm && (
                    <DialogActions className={classes.buttons}>
                        <Button
                            onClick={onConfirm}
                            className={classes.button}
                            autoFocus
                            variant="contained"
                            color="primary"
                        >
                            {confirmText}
                        </Button>
                    </DialogActions>
                )}
            </div>
        </Dialog>
    );
};

GenericDialog.propTypes = {
    confirmText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    customIcon: PropTypes.node,
    iconTop: PropTypes.bool,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    opaqueBackground: PropTypes.bool,
    open: PropTypes.bool,
    onConfirm: PropTypes.func,
    title: PropTypes.string.isRequired
};

GenericDialog.defaultProps = {
    confirmText: 'Confirm',
    customIcon: null,
    iconTop: false,
    message: null,
    opaqueBackground: false,
    open: false,
    onConfirm: null
};

export default GenericDialog;
