import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    colorSwatchItem: {
        display: 'inline-block'
    },
    button: {
        padding: 0
    },
    buttonEdge: {
        margin: 0
    },
    colorSwatch: {
        width: theme.typography.pxToRem(46),
        height: theme.typography.pxToRem(46)
    }
}));
