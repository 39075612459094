export const titleCase = (str) =>
    str &&
    str
        .toLowerCase()
        .split(' ')
        .map((subString) =>
            subString.length > 0
                ? subString[0].toUpperCase() + subString.slice(1)
                : subString
        )
        .join(' ');
