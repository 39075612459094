import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'react-phone-number-input/style.css';
import PhoneInput, {
    getCountryCallingCode,
    parsePhoneNumber,
    formatPhoneNumber
} from 'react-phone-number-input';
import { InputAdornment, Typography } from '@mui/material';
import PhoneNumberCountrySelect from '@/components/common/PhoneNumberCountrySelect';
import { useShowInternationalOptions } from '@/hooks';
import TextRef from './TextRef';

const PhoneNumberInput = ({
    id,
    value,
    defaultCountry,
    label,
    onChange,
    setDefaultPhoneCountry,
    defaultValue,
    ...rest
}) => {
    const showInternationalOptions = useShowInternationalOptions();
    const [callingCode, setCallingCode] = useState(defaultCountry);
    const phoneData = parsePhoneNumber(defaultValue || '');

    const updateValidation = () =>
        onChange('phoneNumber', formatPhoneNumber(value));

    return (
        <PhoneInput
            id={id}
            value={value}
            label={label}
            inputComponent={TextRef}
            defaultCountry={defaultCountry}
            international={false}
            initialValueFormat="national"
            countrySelectComponent={PhoneNumberCountrySelect}
            countrySelectProps={{
                defaultCountry,
                setCallingCode,
                setDefaultPhoneCountry,
                updateValidation
            }}
            onChange={onChange}
            InputProps={
                showInternationalOptions
                    ? {
                          startAdornment: (
                              <InputAdornment
                                  position="start"
                                  disablePointerEvents
                                  style={{ marginTop: 18 }}
                              >
                                  <Typography variant="body1">
                                      {`+${
                                          phoneData?.countryCallingCode ||
                                          getCountryCallingCode(
                                              callingCode || defaultCountry
                                          )
                                      }`}
                                  </Typography>
                              </InputAdornment>
                          )
                      }
                    : undefined
            }
            {...rest}
        />
    );
};

PhoneNumberInput.propTypes = {
    value: PropTypes.string,
    id: PropTypes.string,
    defaultCountry: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    setDefaultPhoneCountry: PropTypes.func,
    defaultValue: PropTypes.string.isRequired
};

PhoneNumberInput.defaultProps = {
    value: '',
    id: 'phoneNumber',
    defaultCountry: 'US',
    label: 'Phone Number',
    onChange: () => {},
    setDefaultPhoneCountry: () => {}
};

export default PhoneNumberInput;
