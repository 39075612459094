import React from 'react';
import PropTypes from 'prop-types';
import DocumentIconPicker from '../DocumentIconPicker';
import useStyles from './styles';

const DocumentTypePill = ({ type, checked }) => {
    const classes = useStyles({
        checked
    });

    const label = type.charAt(0).toUpperCase() + type.slice(1);

    return (
        <span className={classes.span}>
            <span className={classes.svgWrap}>
                <DocumentIconPicker type={type} small />
            </span>
            {label.replace('-', ' ')}
        </span>
    );
};

DocumentTypePill.propTypes = {
    type: PropTypes.string.isRequired,
    checked: PropTypes.bool
};

DocumentTypePill.defaultProps = {
    checked: false
};

export default DocumentTypePill;
