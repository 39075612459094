import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, TextField as MuiTextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { LockIcon } from '@/resources/icons';
import useStyles from './styles';

/**
 * This component wraps the MUI TextField in a React Hook Forms Controller reducing
 * complexity.
 *
 * To use
 * ------
 * Make sure that the component is placed in a form which is wrapped in a FormProvider.
 *
 * OnChange
 * --------
 * If you need to use onChange for the input, this can be set up outside of this component
 * using the React Hook Form 'watch' function. Hooking into onChange directly will break
 * the component.
 *
 * @link: https://react-hook-form.com/
 *
 * @param {string} defaultValue The default value to render the input field with. This can also be set in useForm.
 * @param {string} id The component ID. If ignored will be set by 'name'.
 * @param {string} name The component name. This will be used by React Hook Forms when returning data.
 * @param {object} rules Any rules such as 'isRequired', or validation.
 * @param {bool} locked A locked state for the component. Will disable the textfield and will add a locked icon, but will not update to a "disabled" style otherwise.
 * @param {props} rest Any other props that you want to pass across to the component.
 */
const TextField = ({ defaultValue, id, name, rules, locked, ...rest }) => {
    const classes = useStyles();
    return (
        <Controller
            name={name}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field, fieldState: { error } }) => (
                <MuiTextField
                    {...field}
                    {...rest}
                    id={id || name}
                    helperText={error?.message}
                    error={Boolean(error?.message)}
                    InputProps={{
                        ...rest?.InputProps,
                        className: locked
                            ? classes.locked
                            : rest?.InputProps?.className,
                        endAdornment: locked ? (
                            <InputAdornment position="end" tabIndex="-1">
                                <LockIcon className={classes.lockIcon} />
                            </InputAdornment>
                        ) : (
                            rest?.InputProps?.endAdornment
                        )
                    }}
                    disabled={rest?.disabled || locked}
                />
            )}
        />
    );
};

TextField.propTypes = {
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    rules: PropTypes.shape({}),
    locked: PropTypes.bool
};

TextField.defaultProps = {
    defaultValue: '',
    id: '',
    rules: {},
    locked: false
};

export default TextField;
