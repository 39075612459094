import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import Divider from '@/components/common/Divider';
import {
    toWholeCurrency,
    formatCurrency,
    formatPercentage,
    getTaxesAndDiscountsByName
} from '@/util';
import useStyles from './styles';

const TotalsDisplay = ({
    balanceDue,
    balancePaid,
    currency,
    locale,
    products,
    shippingFee,
    subTotal,
    documentType
}) => {
    const classes = useStyles();

    const { discountByName, taxByName } = getTaxesAndDiscountsByName(products);

    const taxList = Object.keys(taxByName);
    const discountList = Object.keys(discountByName);

    const buildDiscountString = (discountName) => {
        if (discountByName[discountName].type === 'percent') {
            const discountAmount = toWholeCurrency(
                discountByName[discountName].amount
            );

            const formattedPercentage = formatPercentage({
                number: discountAmount,
                locale
            });

            return `${discountName} (${formattedPercentage})`;
        }

        const currencyValue = formatCurrency({
            amount: toWholeCurrency(discountByName[discountName].value),
            currency,
            locale
        });

        return `${discountName} (${currencyValue})`;
    };

    return (
        <Grid
            container
            direction="column"
            spacing={1}
            aria-atomic
            aria-live="polite"
            data-cy="totals-container"
            data-testid="totals-container"
        >
            <Grid
                container
                item
                justifyContent="space-between"
                alignItems="center"
                className={classes.gridRowItem}
            >
                <Grid item align="left">
                    <Typography className={classes.valueLabelTypography}>
                        Subtotal
                    </Typography>
                </Grid>
                <Grid item align="right">
                    <Typography className={classes.valueTypography}>
                        {formatCurrency({
                            amount: toWholeCurrency(subTotal),
                            currency,
                            locale
                        })}
                    </Typography>
                </Grid>
            </Grid>

            {discountList.length > 0 && (
                <Grid
                    container
                    item
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.gridRowItem}
                >
                    <Grid item align="left">
                        <Typography className={classes.valueLabelTypography}>
                            Discount
                        </Typography>
                    </Grid>
                </Grid>
            )}

            {discountList.length > 0 && (
                <Grid item xs>
                    <Grid container direction="column" spacing={0}>
                        {discountList.map((discountName) => {
                            const discountValue =
                                discountByName[discountName].value;

                            return (
                                <Grid
                                    key={
                                        discountByName[discountName].discountId
                                    }
                                    container
                                    item
                                    justifyContent="space-between"
                                    alignItems="center"
                                    className={classes.gridRowItem}
                                >
                                    <Grid
                                        item
                                        className={classes.totalsBreakdownText}
                                        align="left"
                                    >
                                        <Typography
                                            variant="body2"
                                            className={
                                                classes.totalsBreakdownTextTypography
                                            }
                                        >
                                            {buildDiscountString(discountName)}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        className={classes.totalsBreakdownText}
                                        align="right"
                                    >
                                        <Typography
                                            variant="body2"
                                            className={
                                                classes.totalsBreakdownTextTypography
                                            }
                                        >
                                            {`-${formatCurrency({
                                                amount: toWholeCurrency(
                                                    discountValue
                                                ),
                                                currency,
                                                locale
                                            })}`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            )}

            {shippingFee > 0 && (
                <Grid
                    container
                    item
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.gridRowItem}
                >
                    <Grid item align="left">
                        <Typography className={classes.valueLabelTypography}>
                            Shipping
                        </Typography>
                    </Grid>
                    <Grid item align="right">
                        <Typography className={classes.valueTypography}>
                            {formatCurrency({
                                amount: shippingFee,
                                currency,
                                locale
                            })}
                        </Typography>
                    </Grid>
                </Grid>
            )}

            {taxList.length > 0 && (
                <Grid
                    container
                    item
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.gridRowItem}
                >
                    <Grid item align="left">
                        <Typography className={classes.valueLabelTypography}>
                            Tax
                        </Typography>
                    </Grid>
                </Grid>
            )}

            {taxList.length > 0 && (
                <Grid item xs>
                    <Grid container direction="column" spacing={0}>
                        {taxList.map((taxName) => {
                            const taxValue = taxByName[taxName].value;
                            const taxAmount = toWholeCurrency(
                                taxByName[taxName].amount
                            );

                            const formattedTaxPercentage = formatPercentage({
                                number: taxAmount,
                                locale
                            });

                            return (
                                <Grid
                                    key={taxByName[taxName].taxId}
                                    container
                                    item
                                    justifyContent="space-between"
                                    alignItems="center"
                                    className={classes.gridRowItem}
                                >
                                    <Grid
                                        item
                                        className={classes.totalsBreakdownText}
                                        align="left"
                                    >
                                        <Typography
                                            variant="body2"
                                            className={
                                                classes.totalsBreakdownTextTypography
                                            }
                                        >
                                            {taxName} ({formattedTaxPercentage})
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        className={classes.totalsBreakdownText}
                                        align="right"
                                    >
                                        <Typography
                                            variant="body2"
                                            className={
                                                classes.totalsBreakdownTextTypography
                                            }
                                        >
                                            {formatCurrency({
                                                amount: toWholeCurrency(
                                                    taxValue
                                                ),
                                                currency,
                                                locale
                                            })}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            )}

            {balancePaid > 0 && (
                <>
                    <Grid
                        container
                        item
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item align="left">
                            <Typography className={classes.totalTypography}>
                                Total
                            </Typography>
                        </Grid>
                        <Grid item align="right">
                            <Typography
                                className={classes.totalBalanceDue}
                                data-testid="total-balance-due"
                                data-cy="total-balance-due"
                            >
                                {formatCurrency({
                                    amount: toWholeCurrency(
                                        balanceDue + balancePaid
                                    ),
                                    currency,
                                    locale
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.gridRowItem}
                    >
                        <Grid item align="left">
                            <Typography
                                className={classes.valueLabelTypography}
                            >
                                Payments
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        <Grid container direction="column" spacing={0}>
                            <Grid
                                container
                                item
                                justifyContent="space-between"
                                alignItems="center"
                                className={classes.gridRowItem}
                            >
                                <Grid
                                    item
                                    className={classes.totalsBreakdownText}
                                    align="left"
                                >
                                    <Typography
                                        variant="body2"
                                        className={
                                            classes.totalsBreakdownTextTypography
                                        }
                                    >
                                        Amount Paid
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    className={classes.totalsBreakdownText}
                                    align="right"
                                >
                                    <Typography
                                        variant="body2"
                                        className={
                                            classes.totalsBreakdownTextTypography
                                        }
                                    >
                                        -
                                        {formatCurrency({
                                            amount: toWholeCurrency(
                                                balancePaid
                                            ),
                                            currency,
                                            locale
                                        })}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
            <>
                <div className={classes.dividerContainer}>
                    <Divider />
                </div>
                <Grid
                    container
                    item
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item align="left">
                        <Typography className={classes.totalTypography}>
                            {documentType === 'estimate'
                                ? 'Estimate Total'
                                : 'Balance Due'}
                        </Typography>
                    </Grid>
                    <Grid item align="right">
                        <Typography
                            className={classes.totalBalanceDue}
                            data-testid="total-balance-due"
                            data-cy="total-balance-due"
                        >
                            {formatCurrency({
                                amount: toWholeCurrency(balanceDue),
                                currency,
                                locale
                            })}
                        </Typography>
                    </Grid>
                </Grid>
            </>
        </Grid>
    );
};

TotalsDisplay.propTypes = {
    balanceDue: PropTypes.number,
    balancePaid: PropTypes.number,
    currency: PropTypes.string,
    locale: PropTypes.string,
    products: PropTypes.arrayOf(PropTypes.object),
    shippingFee: PropTypes.number,
    subTotal: PropTypes.number,
    documentType: PropTypes.string
};

TotalsDisplay.defaultProps = {
    balanceDue: 0,
    balancePaid: 0,
    currency: 'USD',
    locale: 'en-US',
    products: [],
    shippingFee: 0,
    subTotal: 0,
    documentType: ''
};

export default TotalsDisplay;
