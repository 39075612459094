import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    button: {
        width: 100,
        [theme.breakpoints.down('md')]: {
            margin: '4px 0px 8px 0px'
        }
    },
    mobileSpacer: {
        height: 32,
        width: 100
    }
}));
