import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid, TextField, Typography } from '@mui/material';
import { formatPhoneNumber } from '@/util';
import useStyles from '../styles';

const ConfirmStep = ({ merchant, zelleTextfields }) => {
    const classes = useStyles();
    const qrCodeURL = useSelector((state) => state?.qrUpload?.imageURL);

    return (
        <Grid item container xs={12} spacing={2}>
            {zelleTextfields?.emailAddress !== '' && (
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        readOnly
                        label="Email Address"
                        value={zelleTextfields.emailAddress}
                        className={classes.nonClickableTextField}
                    >
                        {zelleTextfields.emailAddress}
                    </TextField>
                </Grid>
            )}

            {zelleTextfields?.phoneNumber !== '' && (
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        readOnly
                        label="Phone Number"
                        value={formatPhoneNumber({
                            phoneNumber:
                                zelleTextfields.phoneNumber.length > 1 &&
                                zelleTextfields.phoneNumber.length < 18
                                    ? `+1${zelleTextfields.phoneNumber}`
                                    : zelleTextfields.phoneNumber
                        })}
                        className={classes.nonClickableTextField}
                    >
                        {formatPhoneNumber({
                            phoneNumber:
                                zelleTextfields.phoneNumber.length > 1 &&
                                zelleTextfields.phoneNumber.length < 18
                                    ? `+1${zelleTextfields.phoneNumber}`
                                    : zelleTextfields.phoneNumber
                        })}
                    </TextField>
                </Grid>
            )}

            {qrCodeURL !== '' && (
                <>
                    <Grid item container justifyContent="center">
                        <img
                            src={qrCodeURL}
                            alt="Uploaded QR Code"
                            className={classes.uploadedCodeImage}
                        />
                    </Grid>
                    <Grid item>
                        <Typography
                            variant="body2"
                            className={classes.contentBody}
                        >
                            {`Your ${merchant.name} code has been successfully verified.`}
                        </Typography>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

ConfirmStep.propTypes = {
    merchant: PropTypes.object.isRequired,
    zelleTextfields: PropTypes.object
};

ConfirmStep.defaultProps = {
    zelleTextfields: {}
};

export default ConfirmStep;
