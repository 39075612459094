import React from 'react';
import PropTypes from 'prop-types';
import { useCurrency } from '@/hooks';
import { Radio, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { RadioGroup } from '@/components/rhf-mui';
import makeStyles from './styles';

const RadioButton = ({ checked, radioIcon, radioText }) => {
    const classes = makeStyles(checked);
    return (
        <div className={classes.radio}>
            <span className={classes.radioIcon}>{radioIcon}</span>
            <Typography className={classes.radioText}>{radioText}</Typography>
        </div>
    );
};

RadioButton.defaultProps = {
    checked: false
};

RadioButton.propTypes = {
    checked: PropTypes.bool,
    radioIcon: PropTypes.string.isRequired,
    radioText: PropTypes.string.isRequired
};

const Type = () => {
    const classes = makeStyles();

    const { symbol } = useCurrency();

    let radioSymbol = symbol;
    if (radioSymbol.length > 1) {
        radioSymbol = '$';
    }

    return (
        <Grid item container xs={12} className={classes.toggleWrap}>
            <RadioGroup name="type" className={classes.radioGroup} row>
                <Grid item xs={6}>
                    <Radio
                        value="percent"
                        color="secondary"
                        inputProps={{ 'aria-label': 'percent' }}
                        className={classes.radioButton}
                        icon={<RadioButton radioIcon="%" radioText="Percent" />}
                        checkedIcon={
                            <RadioButton
                                checked
                                radioIcon="%"
                                radioText="Percent"
                            />
                        }
                        disableRipple
                    />
                </Grid>
                <Grid item xs={6}>
                    <Radio
                        value="flat"
                        color="secondary"
                        inputProps={{ 'aria-label': 'flat' }}
                        className={classes.radioButton}
                        icon={
                            <RadioButton
                                radioIcon={radioSymbol}
                                radioText="Flat Rate"
                            />
                        }
                        checkedIcon={
                            <RadioButton
                                checked
                                radioIcon={radioSymbol}
                                radioText="Flat Rate"
                            />
                        }
                        disableRipple
                    />
                </Grid>
            </RadioGroup>
        </Grid>
    );
};

export default Type;
