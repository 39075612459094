import { configureStore } from '@reduxjs/toolkit';
// import { setupListeners } from '@reduxjs/toolkit/query/react';
import * as Sentry from '@sentry/react';

// Old Reducers (to be migrated)
import couponData from '@/reducers/couponData';
import cropper from '@/reducers/cropper';
import errors from '@/reducers/errors';
import localization from '@/reducers/localization';
import networkStatus from '@/reducers/networkStatus';
import qrUpload from '@/reducers/qrUpload';
import subscriptionProducts from '@/reducers/subscriptionProducts';

// New Reducers
import drawers from './features/drawerSlice';
import listing from './features/listingSlice';
import makeInvoice from './features/makeDocumentSlice';
import modals from './features/modalSlice';
import user from './features/userSlice';

const isProduction = process.env.NODE_ENV === 'production';

const reducer = {
    couponData,
    cropper,
    errors,
    drawers,
    localization,
    listing,
    makeInvoice,
    modals,
    networkStatus,
    qrUpload,
    subscriptionProducts,
    user
};

// Exported for tests.
export const baseReducer = reducer;

/**
 * Set up as a function to be used in both Production and Jest tests.
 * Allows us to pass in preloadedState. Kick off tests from a start
 * point AND future proofing for SSR.
 * @link https://redux-toolkit.js.org/api/configureStore
 */
export const createStore = ({ preloadedState = undefined } = {}) =>
    configureStore({
        devTools: !isProduction,
        enhancers: isProduction && [Sentry.createReduxEnhancer()],
        preloadedState,
        reducer: baseReducer
    });

// Create store to be used in production Redux provider.
const store = createStore();

// // For RTK Query
// setupListeners(store.dispatch);

export default store;
