/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, Typography, Grid } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
// import { addressLookup } from '@/modules/addressVerification';
import { titleCase } from '@/util';

export const AddressTitles = ({ city, primaryLine, state, zipCode }) => (
    <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
    >
        <Typography variant="body1">{titleCase(primaryLine)}</Typography>
        <Typography variant="body2">
            {titleCase(city)}, {state} {titleCase(zipCode)}
        </Typography>
    </Grid>
);

AddressTitles.propTypes = {
    city: PropTypes.string,
    primaryLine: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string
};

AddressTitles.defaultProps = {
    city: '',
    primaryLine: '',
    state: '',
    zipCode: ''
};

const AddressAutofill = ({
    // country,
    controlled,
    controlledValue,
    disabled,
    id,
    initialValue,
    label,
    name,
    onAddressSelect,
    register,
    updatedValue
}) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [stateValue, setStateValue] = useState(initialValue || '');

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
        if (controlled) {
            setStateValue(controlledValue);
        } else {
            setStateValue(initialValue);
        }
    }, [open, controlledValue, controlled, initialValue]);

    // COMMENT OUT AUTOFILL TO PREVENT API CALLS
    // NOT USING LOB/AUTOFILL ANY MORE
    const handleAutofill = async () => {
        // If user is in the US, then handle autofill.
        // if (country === 'US') {
        //     const isValid = /^(?!.*( {2}|_|\.))[\w .]+$/gi.test(v);
        //     if (isValid && v.length >= 2) {
        //         const suggestedAddresses = await addressLookup(v);
        //         setOptions(suggestedAddresses || []);
        //     }
        // }
    };

    const handleAddressSelection = (value) => {
        const type = typeof value;

        if (type === 'object') {
            onAddressSelect(
                {
                    ...value,
                    primary_line: titleCase(value.primary_line),
                    city: titleCase(value.city)
                },
                stateValue
            );
        } else {
            updatedValue(
                type === 'string' ? value : titleCase(value.primary_line)
            );
        }

        setStateValue(
            type === 'string' ? value : titleCase(value.primary_line)
        );
    };

    const checkSelectReason = (reason, updateState, value) =>
        reason === 'input' && updateState(value);

    return (
        <Autocomplete
            freeSolo
            disableClearable
            id={id}
            name={name}
            open={open}
            disabled={disabled}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionLabel={(option) => `${option.primary_line}`}
            renderOption={(
                props,
                { city, primary_line, state, zip_code, key }
            ) => (
                <li {...props} key={`${key}-${props.id}`}>
                    <AddressTitles
                        city={city}
                        primaryLine={primary_line}
                        state={state}
                        zipCode={zip_code}
                    />
                </li>
            )}
            options={options}
            onChange={(_, newValue) => {
                handleAddressSelection(newValue);
            }}
            inputValue={stateValue}
            onInputChange={(_, value, reason) => {
                checkSelectReason(reason, setStateValue, value);

                handleAutofill(value);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    disabled={disabled}
                    onChange={(e) => updatedValue(e.target.value)}
                    id={id || name}
                    name={name}
                    inputRef={register}
                />
            )}
        />
    );
};

AddressAutofill.propTypes = {
    // country: PropTypes.string.isRequired,
    controlled: PropTypes.bool,
    controlledValue: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    initialValue: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onAddressSelect: PropTypes.func.isRequired,
    register: PropTypes.func,
    updatedValue: PropTypes.func
};

AddressAutofill.defaultProps = {
    controlled: false,
    controlledValue: undefined,
    disabled: false,
    id: 'addressAutocomplete',
    initialValue: '',
    label: '',
    register: () => {},
    updatedValue: () => {}
};

export default AddressAutofill;
