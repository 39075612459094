import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
    inlineLink: {
        minWidth: 'inherit',
        padding: 0,
        fontSize: 'inherit',
        lineHeight: 'inherit',
        verticalAlign: 'inherit',
        textDecoration: (disableUnderline) =>
            disableUnderline ? 'none' : 'underline',
        '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: (disableUnderline) =>
                disableUnderline ? 'underline' : 'none'
        }
    }
}));
