/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { defaultDocument } from '@/config/defaultDocument';

const sortByName = (el1, el2) => {
    const name1 = el1.name.toUpperCase();
    const name2 = el2.name.toUpperCase();

    if (name1 > name2) {
        return 1;
    }
    if (name1 < name2) {
        return -1;
    }

    return 0;
};

const initialState = {
    activeBusiness: {
        businessId: 'default',
        businessProfile: {},
        businessSettings: {
            country: 'US',
            currency: 'USD',
            locale: 'en-US',
            timezone: 'America/New_York',
            language: 'en',
            showInternationalOptions: false
        },
        documentCustomizations: {
            design: '#4A5057',
            invoiceNotes: '',
            estimateNotes: '',
            receiptNotes: '',
            invoiceTerms: 0,
            estimateTerms: 0,
            disableIMBranding: false
        },
        localeData: {
            country: {
                name: 'United States of America',
                code: 'US',
                addressFormat: 1
            },
            currency: {
                code: 'USD',
                name: 'United States Dollar',
                position: 'before',
                stripeEnabled: true,
                stripeMin: 50,
                symbol: '$',
                decimalPlaces: 2
            },
            language: {
                name: 'English',
                code: 'en'
            },
            timezone: {
                name: 'America/New_York'
            }
        }
    },
    notificationCount: 0,
    temporaryUser: true,
    temporaryStorage: {}
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        fetchUser: (state) => {
            state.loadingUser = true;
        },
        updateUser: (state, action) => ({
            ...state,
            ...action.payload,
            loadingUser: false
        }),
        fetchProducts: (state) => {
            state.loadingProducts = true;
        },
        overwriteProducts: (state, action) => {
            state.activeBusiness.products = action.payload;
            state.loadingProducts = false;
        },
        addProduct: (state, action) => {
            const products = state.activeBusiness.products
                ? [...state.activeBusiness.products]
                : [];

            products.push(action.payload);
            products.sort(sortByName);

            state.activeBusiness.products = products;
        },
        updateProduct: (state, action) => {
            const products = [...state.activeBusiness.products];

            const index = products.findIndex(
                (element) => element.productId === action.payload.productId
            );

            if (index > -1) {
                products[index] = action.payload;
            }

            products.sort(sortByName);

            state.activeBusiness.products = products;
        },
        deleteProduct: (state, action) => {
            const products = [...state.activeBusiness.products];

            const index = products.findIndex(
                (element) => element.productId === action.payload
            );

            if (index >= 0) {
                products.splice(index, 1);
            }

            state.activeBusiness.products = products;
        },
        fetchClients: (state) => {
            state.loadingClients = true;
        },
        overwriteClients: (state, action) => {
            state.activeBusiness.clients = action.payload.sort(sortByName);
            state.loadingClients = false;
        },
        addClient: (state, action) => {
            const clients = state.activeBusiness.clients
                ? [...state.activeBusiness.clients]
                : [];

            clients.push(action.payload);
            clients.sort(sortByName);

            state.activeBusiness.clients = clients;
        },
        updateClient: (state, action) => {
            const clients = state.activeBusiness.clients
                ? [...state.activeBusiness.clients]
                : [];

            const index = clients.findIndex(
                (element) => element.clientId === action.payload.clientId
            );

            if (index > -1) {
                clients[index] = {
                    ...clients[index],
                    ...action.payload
                };
            }

            clients.sort(sortByName);

            state.activeBusiness.clients = clients;
        },
        deleteClient: (state, action) => {
            const clients = state.activeBusiness.clients
                ? [...state.activeBusiness.clients]
                : [];

            const index = clients.findIndex(
                (element) => element.clientId === action.payload
            );

            if (index >= 0) {
                clients.splice(index, 1);
            }

            state.activeBusiness.clients = clients;
        },
        createBusiness: (state, action) => {
            const businesses = state.businesses ? [...state.businesses] : [];

            businesses.push(action.payload);
            businesses.sort(sortByName);

            state.businesses = businesses;
        },
        setActiveBusiness: (state, action) => {
            state.activeBusiness = {
                ...action.payload,
                businessProfile: {
                    ...action.payload.businessProfile,
                    address: {
                        ...action.payload.businessProfile?.address,
                        country:
                            action.payload.businessProfile?.address?.country ||
                            'US'
                    }
                }
            };
        },
        setActiveBusinessPending: (state) => {
            state.activeBusinessPending = true;
        },
        setActiveBusinessComplete: (state) => {
            state.activeBusinessPending = false;
        },
        updateLogo: (state, action) => {
            state.activeBusiness.logo = action.payload;
        },
        setUpdateLogoPending: (state) => {
            state.activeBusiness.updateLogoPending = true;
        },
        setUpdateLogoComplete: (state) => {
            state.activeBusiness.updateLogoPending = false;
        },
        deleteLogo: (state) => {
            state.activeBusiness.logo = '';
        },
        setDeleteLogoPending: (state) => {
            state.activeBusiness.deleteLogoPending = true;
        },
        setDeleteLogoComplete: (state) => {
            state.activeBusiness.deleteLogoPending = false;
        },
        updateBusinessProfile: (state, action) => {
            state.activeBusiness.businessProfile = {
                ...state.activeBusiness.businessProfile,
                ...action.payload
            };
        },
        updateBusinessSettings: (state, action) => {
            state.activeBusiness.businessSettings = {
                ...state.activeBusiness.businessSettings,
                ...action.payload
            };
        },
        updateBusinessAddress: (state, action) => {
            state.activeBusiness.businessProfile.address = {
                ...state.activeBusiness.businessProfile.address,
                ...action.payload
            };
        },
        createTax: (state, action) => {
            const taxes = state.activeBusiness.taxes
                ? [...state.activeBusiness.taxes]
                : [];

            taxes.push(action.payload);
            taxes.sort(sortByName);

            state.activeBusiness.taxes = taxes;
        },
        overwriteTaxes: (state, action) => {
            state.activeBusiness.taxes = action.payload;
        },
        updateTax: (state, action) => {
            const taxes = state.activeBusiness.taxes
                ? [...state.activeBusiness.taxes]
                : [];

            const index = taxes.findIndex(
                (element) => element.taxId === action.payload.taxId
            );

            if (index > -1) {
                taxes[index] = action.payload;
            }

            taxes.sort(sortByName);

            state.activeBusiness.taxes = taxes;
        },
        deleteTax: (state, action) => {
            const taxes = state.activeBusiness.taxes
                ? [...state.activeBusiness.taxes]
                : [];

            const index = taxes.findIndex(
                (element) => element.taxId === action.payload
            );

            if (index >= 0) {
                taxes.splice(index, 1);
            }

            state.activeBusiness.taxes = taxes;
        },
        createDiscount: (state, action) => {
            const discounts = state.activeBusiness.discounts
                ? [...state.activeBusiness.discounts]
                : [];

            discounts.push(action.payload);

            discounts.sort(sortByName);

            state.activeBusiness.discounts = discounts;
        },
        updateDiscounts: (state, action) => {
            state.activeBusiness.discounts = action.payload;
            state.discountListPending = false;
        },
        updateDiscount: (state, action) => {
            const discounts = state.activeBusiness.discounts
                ? [...state.activeBusiness.discounts]
                : [];
            const updatedDiscount = action.payload;

            const discountIndex = discounts.findIndex(
                (item) => item.discountId === updatedDiscount.discountId
            );

            if (discountIndex > -1) {
                discounts[discountIndex] = updatedDiscount;
            }

            discounts.sort(sortByName);

            state.activeBusiness.discounts = discounts;
        },
        updateDiscountsPending: (state) => {
            state.discountListPending = true;
        },
        updateDiscountsComplete: (state) => {
            state.discountListPending = false;
        },
        deleteDiscount: (state, action) => {
            const discounts = state.activeBusiness.discounts
                ? [...state.activeBusiness.discounts]
                : [];

            const index = discounts.findIndex(
                (discount) => discount.discountId === action.payload
            );

            if (index > -1) discounts.splice(index, 1);

            state.activeBusiness.discounts = discounts;
        },
        createTag: (state, action) => {
            const tags = state.activeBusiness.tags
                ? [...state.activeBusiness.tags]
                : [];

            tags.push(action.payload);
            tags.sort(sortByName);

            state.activeBusiness.tags = tags;
        },
        overwriteTags: (state, action) => {
            state.activeBusiness.tags = action.payload;
        },
        updateTag: (state, action) => {
            const tags = state.activeBusiness.tags
                ? [...state.activeBusiness.tags]
                : [];

            const index = tags.findIndex(
                (element) => element.tagId === action.payload.tagId
            );

            if (index > -1) {
                tags[index] = action.payload;
            }

            tags.sort(sortByName);

            state.activeBusiness.tags = tags;
        },
        deleteTag: (state, action) => {
            const tags = state.activeBusiness.tags
                ? [...state.activeBusiness.tags]
                : [];

            const index = tags.findIndex(
                (element) => element.tagId === action.payload
            );

            if (index > -1) {
                tags.splice(index, 1);
            }

            state.activeBusiness.tags = tags;
        },
        createNote: (state, action) => {
            const notes = state.activeBusiness.notes
                ? [...state.activeBusiness.notes]
                : [];

            notes.push(action.payload);

            state.activeBusiness.notes = notes;
        },
        overwriteNotes: (state, action) => {
            state.activeBusiness.notes = action.payload;
        },
        updateNote: (state, action) => {
            const notes = state.activeBusiness.notes
                ? [...state.activeBusiness.notes]
                : [];

            const index = notes.findIndex(
                (element) => element.noteId === action.payload.noteId
            );

            if (index > -1) {
                notes[index] = action.payload;
            }

            state.activeBusiness.notes = notes;
        },
        deleteNote: (state, action) => {
            const notes = state.activeBusiness.notes
                ? [...state.activeBusiness.notes]
                : [];

            const index = notes.findIndex(
                (element) => element.noteId === action.payload
            );

            if (index > -1) {
                notes.splice(index, 1);
            }

            state.activeBusiness.notes = notes;
        },
        createFile: (state, action) => {
            const files = state.activeBusiness.files
                ? [...state.activeBusiness.files]
                : [];

            files.push(action.payload);

            state.activeBusiness.files = files;
        },
        overwriteFiles: (state, action) => {
            state.activeBusiness.files = action.payload;
        },
        updateFile: (state, action) => {
            const files = state.activeBusiness.files
                ? [...state.activeBusiness.files]
                : [];

            const index = files.findIndex(
                (element) => element.fileId === action.payload.fileId
            );

            if (index > -1) {
                files[index] = action.payload;
            }

            state.activeBusiness.files = files;
        },
        deleteFile: (state, action) => {
            const files = state.activeBusiness.files
                ? [...state.activeBusiness.files]
                : [];

            const index = files.findIndex(
                (element) => element.fileId === action.payload
            );

            if (index > -1) {
                files.splice(index, 1);
            }

            state.activeBusiness.files = files;
        },
        loggedIn: (state, action) => {
            state.userLoggedIn = action.payload;
        },
        addConnection: (state, action) => {
            const connections = state.activeBusiness.connections
                ? [...state.activeBusiness.connections]
                : [];

            connections.push(action.payload);

            state.activeBusiness.connections = connections;
        },
        removeConnection: (state, action) => {
            state.activeBusiness.connections =
                state.activeBusiness.connections.filter(
                    (connection) => connection.application !== action.payload
                );
        },
        setAllConnections: (state, action) => {
            state.activeBusiness.connections = action.payload;
        },
        resetState: () => initialState,
        updateSubscription: (state, action) => {
            state.activeBusiness.subscription = action.payload;
        },
        setTemporaryUser: (state, action) => {
            state.temporaryUser = action.payload;
        },
        clearTemporaryStorage: (state) => {
            state.temporaryStorage = {};
        },
        setTemporaryStorage: (state, action) => {
            let { data } = action.payload;

            if (action.payload.needsInitialDocumentState) {
                data = {
                    ...defaultDocument,
                    ...data
                };
            }

            state.temporaryStorage = {
                [action.payload.component]: data,
                documentType: action.payload.component
            };
        },
        prepareTemporaryStorage: (state) => {
            if (Object.keys(state.temporaryStorage).length > 0) {
                state.temporaryStorage.inject = true;
            }
        },
        fetchDocument: (state) => {
            state.loadingDocument = true;
        },
        documentStoppedLoading: (state) => {
            state.loadingDocument = false;
        },
        fetchNotificationCount: (state, action) => {
            state.notificationCount = action.payload;
        },
        reduceNotificationCount: (state) => {
            state.notificationCount -= 1;
        },
        increaseNotificationCount: (state) => {
            state.notificationCount += 1;
        },
        clearNotificationCount: (state) => {
            state.notificationCount = 0;
        }
    }
});

export const {
    fetchUser,
    updateUser,
    fetchProducts,
    overwriteProducts,
    addProduct,
    updateProduct,
    deleteProduct,
    fetchClients,
    overwriteClients,
    addClient,
    updateClient,
    deleteClient,
    createBusiness,
    setActiveBusiness,
    setActiveBusinessPending,
    setActiveBusinessComplete,
    updateLogo,
    setUpdateLogoPending,
    setUpdateLogoComplete,
    deleteLogo,
    setDeleteLogoPending,
    setDeleteLogoComplete,
    updateBusinessProfile,
    updateBusinessSettings,
    updateBusinessAddress,
    createTax,
    overwriteTaxes,
    updateTax,
    deleteTax,
    createDiscount,
    updateDiscounts,
    updateDiscount,
    updateDiscountsPending,
    updateDiscountsComplete,
    deleteDiscount,
    createTag,
    overwriteTags,
    updateTag,
    deleteTag,
    createNote,
    overwriteNotes,
    updateNote,
    deleteNote,
    createFile,
    overwriteFiles,
    updateFile,
    deleteFile,
    loggedIn,
    addConnection,
    removeConnection,
    setAllConnections,
    resetState,
    updateSubscription,
    setTemporaryUser,
    clearTemporaryStorage,
    setTemporaryStorage,
    prepareTemporaryStorage,
    fetchDocument,
    documentStoppedLoading,
    fetchNotificationCount
} = userSlice.actions;

export default userSlice.reducer;
