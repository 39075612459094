export const getLocaleFile = async ({ locale, setPickedLocale, localeRef }) => {
    // This webpack option stops all of the date-fns files being imported and chunked.
    const localeToSet = await import(
        /* webpackMode: "lazy", webpackChunkName: "df-[index]", webpackExclude: /_lib/ */
        `date-fns/locale/${locale}/index.js`
    );

    setPickedLocale(localeToSet.default);

    // eslint-disable-next-line no-param-reassign
    localeRef.current = localeToSet.default.code;
};
