import React from 'react';
import { Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import Tab from './Tab';
import useStyles from './styles';

/**
 * A simple way to implement the head of a tabbed page. Pass in an
 * array of objects (shown below), and it will manage the active
 * states and page routes.
 * @param {array} items An array of objects outlining the tabs required.
 * [{
 *    color: Hex colour,
 *    exact: boolean,
 *    title: string,
 *    icon: node (svg),
 *    iconActive: node (svg),
 *    path: string
 * }]
 * @param {string} pathname Current pathname as provided by Location.
 */
const TabBar = ({ items, pathname, customClasses, isSettingsPage }) => {
    const getActiveItemColor = () => {
        const activeItem = items.find((item) => item.path === pathname);
        if (activeItem) {
            return activeItem.color;
        }
        return '#656b7d';
    };

    const classes = useStyles({ tabColor: getActiveItemColor() });

    return (
        <Tabs
            classes={
                isSettingsPage
                    ? { root: classes.root, indicator: classes.indicator }
                    : customClasses
            }
            TabIndicatorProps={{ children: <span /> }}
            value={pathname}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            aria-label={`${
                isSettingsPage ? 'account settings' : 'client'
            } menu`}
        >
            {items.map((item) => (
                <Tab
                    key={`tab-${item.title}`}
                    active={item.path === pathname}
                    color={item.color}
                    icon={item.icon}
                    iconActive={item.iconActive}
                    title={item.title}
                    to={item.path}
                    value={item.path}
                    customTabStyles={customClasses.clientTabStyles}
                    isSettingsPage={isSettingsPage}
                />
            ))}
        </Tabs>
    );
};

TabBar.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    pathname: PropTypes.string,
    customClasses: PropTypes.object,
    isSettingsPage: PropTypes.bool
};

TabBar.defaultProps = {
    items: [{}],
    pathname: null,
    customClasses: {},
    isSettingsPage: true
};

export default TabBar;
