import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
/**
 * @param {mixed} children - The children to wrap.
 * @param {boolean} removeWrap - If true, the component will not be wrapped.
 * @returns {JSX.Element} - The wrapped component.
 */
const WrapWithPaper = ({ children, removeWrap }) => {
    if (removeWrap) {
        return children;
    }
    return <Paper variant="outlined">{children}</Paper>;
};
WrapWithPaper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.node
    ]).isRequired,
    removeWrap: PropTypes.bool
};
WrapWithPaper.defaultProps = {
    removeWrap: false
};
export default WrapWithPaper;
