import React from 'react';
import { Grid } from '@mui/material';
import { Layout } from '@/containers';
import ItemRoute from '@/pageComponents/Items';

const Products = () => (
    <Layout>
        <Grid container direction="row">
            <Grid item xs={12}>
                <ItemRoute pathname="/items/products" />
            </Grid>
        </Grid>
    </Layout>
);

export default Products;
