import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

const PostalCode = ({ addressFormat, ...rest }) => {
    let postCodeLabel = 'Zip Code';

    if (addressFormat === 2) {
        postCodeLabel = 'Postal Code';
    }

    if (addressFormat === 3) {
        postCodeLabel = 'Post Code';
    }

    return <TextField {...rest} label={postCodeLabel} />;
};

PostalCode.propTypes = {
    addressFormat: PropTypes.number
};

PostalCode.defaultProps = { addressFormat: 1 };

export default PostalCode;
