import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    sectionCaptionWrap: {
        width: '100%',
        paddingLeft: '8px'
    },
    sectionCaption: {
        marginTop: theme.typography.pxToRem(20),
        fontSize: theme.typography.pxToRem(11),
        textTransform: 'uppercase',
        color: '#909397',
        fontWeight: 400
    },
    blockerContainer: {
        marginBottom: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.common.white,
        border: 'none',
        minHeight: '60vh',
        position: 'relative'
    },
    sectionContainerMobile: {
        [theme.breakpoints.only('xs')]: {
            backgroundColor: 'white',
            padding: theme.spacing(1),
            paddingTop: theme.spacing(1),
            marginBottom: theme.spacing(2.5)
        }
    },
    switchLabel: {
        marginRight: 20
    },
    switchContainer: {
        maxWidth: '450px !important'
    },
    pageContainer: {},
    PaymentMethodsContainer: {
        maxWidth: theme.typography.pxToRem(1308)
    },
    saveButton: {
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.typography.pxToRem(10)
        }
    },
    saveButtonDisabled: {
        background: `${theme.palette.primary.main} !important`,
        opacity: '0.5',
        color: `${theme.palette.common.white} !important`
    },
    saveButtonContainer: {
        [theme.breakpoints.only('xs')]: {
            backgroundColor: theme.palette.common.white,
            position: 'fixed',
            bottom: 0,
            left: 0,
            padding: `8px 8px calc(env(safe-area-inset-bottom) + 8px) 8px !important`,
            zIndex: 10,
            filter: 'drop-shadow(0 -4px 10px rgba(53, 55, 61, 0.08))',
            textAlign: 'center',
            margin: '0 auto',
            display: 'block'
        }
    },
    subtitles: {
        ...theme.typography.body2
    },
    mobileHeader: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(0, 0, 2)
    },
    mobileIcon: {
        height: theme.typography.pxToRem(24),
        marginRight: theme.typography.pxToRem(10),
        width: theme.typography.pxToRem(24)
    },
    mobileTitle: {
        fontSize: theme.typography.pxToRem(20),
        color: ({ color }) => color,
        fontWeight: 700,
        margin: 0,
        [theme.breakpoints.up('lg')]: {
            fontSize: theme.typography.pxToRem(26),
            fontWeight: 600
        }
    },
    tabbar: {
        marginTop: 10
    },
    instructions: {
        textAlign: 'center'
    },
    footer: {
        marginTop: 20
    },
    settingsPaper: {
        marginBottom: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.common.white,
        border: '1px solid rgba(0, 0, 0, 0.12)',
        minHeight: '80vh',
        position: 'relative'
    },
    genericSettingsDivider: {
        backgroundColor: theme.palette.background.default,
        height: 1,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(1)
        }
    },
    dividerBottomMargin: {
        marginBottom: theme.typography.pxToRem(20)
    },
    accountSettingsButtonRowContainer: {
        maxWidth: 'inherit',
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.typography.pxToRem(20),
            padding: `0 ${theme.typography.pxToRem(
                16
            )} 0 ${theme.typography.pxToRem(16)}`
        }
    },
    cio_message: {
        width: '100%'
    }
}));
