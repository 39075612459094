import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    radioButtonTop: {
        padding: 0,
        width: '100%'
    },
    radioButtonBottom: {
        padding: 0,
        width: '100%',
        marginBottom: -9
    },
    radioContainer: {
        maxHeight: theme.typography.pxToRem(67),
        [theme.breakpoints.between(600, 768)]: {
            maxWidth: '20%'
        }
    },
    valueField: {
        [theme.breakpoints.between(600, 768)]: {
            maxWidth: '20%'
        }
    }
}));
