import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    totals: {
        width: '100%',
        maxWidth: 290,
        marginLeft: 'auto',
        wordBreak: 'break-word'
    },
    total: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    taxDiscount: {
        display: 'flex',
        justifyContent: 'space-between',
        color: theme.palette.text.secondary,
        paddingLeft: 20,
        marginTop: 5
    },
    breakdownDesktop: {
        display: 'none',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    }
}));
