// Data to set the head of the table.
const tableHead = [
    {
        id: 'notificationType',
        label: 'Event',
        sort: false
    },
    {
        id: 'parentLabel',
        label: 'Document',
        sort: false
    },
    {
        id: 'createdAt',
        label: 'DATE/TIME',
        sort: false
    },
    {
        id: 'markasread',
        label: '',
        sort: false
    }
];

export default tableHead;
