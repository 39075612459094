import React from 'react';
import { Grid } from '@mui/material';
import { useDrawers } from '@/hooks';
import { ACTIVITY_BASE_PARAM } from '@/constants';

import {
    ActivityTypeMultiSelect,
    Date,
    FilterForm,
    DocumentTypeCheckbox
} from '../formComponents';

const ActivityFilterDrawer = () => {
    const { isDrawerActive } = useDrawers();
    const drawerOpen = isDrawerActive('activityFilter');

    const documentTypes = [
        'invoice',
        'recurring-invoice',
        'estimate',
        'discount',
        'receipt',
        'product',
        'service',
        'tax',
        'tag'
    ];

    const defaultValues = {
        parentType: {
            invoice: false,
            estimate: false,
            receipt: false,
            product: false,
            service: false,
            tax: false,
            tag: false,
            discount: false
        },
        createdAt: {
            min: '',
            max: ''
        },
        activities: []
    };

    return (
        <FilterForm
            defaultValues={defaultValues}
            id="FilterActivity"
            open={drawerOpen}
            paramBase={ACTIVITY_BASE_PARAM}
            title="Activity"
            type="activity"
        >
            <Grid item>
                <DocumentTypeCheckbox documentTypes={documentTypes} />
            </Grid>
            <Grid item>
                <Date heading="Created At" id="createdAt" disableFuture />
            </Grid>
            <Grid item>
                <ActivityTypeMultiSelect />
            </Grid>
        </FilterForm>
    );
};

export default ActivityFilterDrawer;
