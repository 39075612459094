import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { TextField } from '@mui/material';
import { forcePositiveNumberInput, currencySymbolAdornment } from '@/util';
import { useMountEffect, useCurrency } from '@/hooks';

const FormatedNumberField = ({
    item,
    applyFieldToItem,
    label,
    maxLength,
    format,
    placeHolder,
    value
}) => {
    const allowedCharsRegex = /[0-9|.]+/;
    const [dirty, setDirty] = useState(false);
    const [textFieldValue, setTextFieldValue] = useState('');
    const [selectedId, setSelectedId] = useState(item.productId);

    const { symbol, position } = useCurrency();

    useMountEffect(() => {
        if (item.productId !== undefined) {
            setDirty(true);
            setTextFieldValue(format(value));
        }
    });

    useEffect(() => {
        if (item.productId !== selectedId) {
            setDirty(true);
            setSelectedId(item.productId);
            setTextFieldValue(format(value));
        }
    }, [item.productId, format, value, selectedId]);

    const handleKeyDown = (event) => {
        forcePositiveNumberInput(event);
        if (
            !(
                allowedCharsRegex.test(event.key) ||
                event.key === 'Backspace' ||
                event.key === 'Delete' ||
                event.key === 'Tab' ||
                event.key.includes('Arrow')
            )
        ) {
            event.preventDefault();
        }
    };

    const getTruncatedStringValue = (num) => {
        try {
            return num.toString().match(/^\$?\.?[\d,]+(?:\.\d{0,2})?/)[0];
        } catch {
            return '';
        }
    };

    const handleChange = (event) => {
        setDirty(true);
        const truncatedString = getTruncatedStringValue(event.target.value);
        const num = numeral(truncatedString).value();
        setTextFieldValue(event.target.value);
        applyFieldToItem(num);
    };

    const handleBlur = (event) => {
        const truncatedString = getTruncatedStringValue(event.target.value);
        const num = numeral(truncatedString).value();
        setTextFieldValue(format(num));
        applyFieldToItem(num);
    };

    return (
        <TextField
            InputProps={
                label === 'Price'
                    ? currencySymbolAdornment({ position, symbol })
                    : {}
            }
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
                'aria-label': label,
                maxLength,
                min: 0,
                inputMode: 'decimal'
            }}
            fullWidth
            variant="filled"
            value={dirty ? textFieldValue : ''}
            id={`line-item-${label.toLowerCase()}-field${item.lineId}`}
            label={label}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={placeHolder}
        />
    );
};

FormatedNumberField.propTypes = {
    item: PropTypes.object.isRequired,
    applyFieldToItem: PropTypes.func.isRequired,
    format: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    placeHolder: PropTypes.string.isRequired,
    maxLength: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

export default FormatedNumberField;
