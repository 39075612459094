import { parse, stringify } from 'qs';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { removeEmptyFromObject } from '@/util';

export const useUrlParams = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const stringParams = searchParams.toString();

    /**
     * Gets specific search params from the URL when the URL updates.
     * @param {array} watch An array of strings. Top level param names to be watched.
     * @info https://reactrouter.com/en/main/hooks/use-search-params
     *
     * Example use:
     * To watch the search and client params
     * useGetUrlParams({ watch:['s', 'client'] });
     *
     * To watch sub table params
     * useGetUrlParams({ base:'tablename', watch:['key'] });
     *
     * To note:
     * It only returns strings. You will need to convert any numbers yourself.
     */
    const getUrlParams = ({ base, watch } = {}) => {
        let searchObject = parse(stringParams, {
            allowDots: true,
            ignoreQueryPrefix: true
        });

        // If no watch arguments are passed, return all params.
        if (typeof watch === 'undefined' || watch.length === 0) {
            if (typeof base !== 'undefined') {
                // If the base is not found, return an empty object.
                if (typeof searchObject[base] === 'undefined') {
                    return {};
                }

                return searchObject[base];
            }

            return searchObject;
        }

        const requiredObject = {};

        // If a base is passed, we need to dig deeper.
        if (typeof base !== 'undefined') {
            // If the base is not found, return an empty object.
            if (typeof searchObject[base] === 'undefined') {
                return {};
            }

            // If the base is found, set the search object to the base.
            searchObject = searchObject[base];
        }

        // Loop through the search object, find the ones we care about.
        // Kick the rest.
        Object.keys(searchObject).forEach((key) => {
            if (watch.includes(key)) {
                requiredObject[key] = searchObject[key];
            }
        });

        return requiredObject;
    };

    /**
     * Sets params on the current URL.
     * Ability to not overwrite/remove specific params if required.
     * @param {object} params An object of params to be passed onto the URL.
     * @param {object} ignore An object of params to be left untouched. If a param is
     * passed in that is the same as ignore - ignore will be kept
     *
     * Example of use:
     * setUrlParams({ params: null, ignore: ['mockkey'] });
     * or
     * setUrlParams({ params:{ some:params }, ignore: ['mockkey']});
     *
     * To set sub table params
     * setUrlParams({ base:'tablename', params:{ some:params }, ignore: ['mockkey'] });
     */
    const setUrlParams = ({ base, params, ignore } = {}) => {
        const currentSearchObject = parse(stringParams, {
            allowDots: true,
            ignoreQueryPrefix: true
        });

        let newSearchObject = {};

        // Set up the new search object with passed params.
        if (typeof base !== 'undefined') {
            newSearchObject[base] = params ? { ...params } : {};
        } else {
            newSearchObject = params ? { ...params } : {};
        }

        // If we want to keep some old params, this re-adds and/or overwrites the new ones with original data.
        if (typeof ignore !== 'undefined' && ignore.length > 0) {
            // If a base is passed, we need to dig deeper.
            if (
                typeof base !== 'undefined' &&
                typeof currentSearchObject[base] !== 'undefined'
            ) {
                // If the base is found, set the search object to the base.
                const baseObject = currentSearchObject[base];

                // Loop through the search object, find the ones we care about.
                // Kick the rest.
                Object.keys(baseObject).forEach((key) => {
                    if (ignore.includes(key)) {
                        newSearchObject[base][key] = baseObject[key];
                    }
                });

                // Add other params not in the base.
                Object.keys(currentSearchObject).forEach((key) => {
                    if (key !== base) {
                        newSearchObject[key] = currentSearchObject[key];
                    }
                });
            } else {
                // If no base.

                // Loop through the search object, find the ones we care about.
                // Kick the rest.
                Object.keys(currentSearchObject).forEach((key) => {
                    if (ignore.includes(key)) {
                        newSearchObject[key] = currentSearchObject[key];
                    }
                });
            }
        }

        // Strip out all empty values from the search object.
        const cleanSearchObject = removeEmptyFromObject(newSearchObject);

        const newUrlString = stringify(cleanSearchObject, {
            allowDots: true
        });

        // Check to make sure params are different.
        if (stringParams !== newUrlString) {
            setSearchParams(newUrlString);
        }
    };

    return { getUrlParams, setUrlParams };
};
