import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

const State = ({ addressFormat, ...rest }) => {
    let stateLabel = 'State';

    if (addressFormat === 3) {
        stateLabel = 'County';
    }

    return <TextField {...rest} label={stateLabel} />;
};

State.propTypes = {
    addressFormat: PropTypes.number
};

State.defaultProps = { addressFormat: 1 };

export default State;
