import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    accordionContainer: {
        border: `1px solid ${theme.palette.actionColors.tags}`,
        borderRadius: 3,
        backgroundColor: theme.palette.common.white,
        marginBottom: theme.spacing(2),
        '&::before': {
            backgroundColor: 'transparent'
        }
    },
    expandIcon: {
        width: 30,
        height: 30
    },
    iconContainer: {
        width: 24,
        height: 24
    },
    accordionTitle: {
        fontSize: theme.typography.pxToRem(26),
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            marginRight: theme.spacing(1),
            fontSize: theme.typography.pxToRem(20)
        }
    },
    tagPreviewButton: {
        backgroundColor: theme.palette.common.lightGray,
        color: '#334155',
        minWidth: 'fit-content',
        fontSize: theme.typography.pxToRem(12),
        padding: '3px 10px',
        [theme.breakpoints.up('md')]: {
            padding: '6px 10px'
        }
    },
    iconHeight: {
        width: 24,
        height: 24
    },
    iconStroke: {
        display: 'flex',
        justifyContent: 'center',
        '& svg > path': {
            stroke: theme.palette.imBrand.midBlue,
            strokeWidth: 3
        }
    },
    addTagButton: {
        whiteSpace: 'nowrap',
        width: 157,
        marginRight: theme.spacing(3),
        '&:hover > *': {
            '& svg > path': {
                stroke: theme.palette.imBrand.midBlueHover,
                strokeWidth: 3
            }
        }
    },
    smScreenButton: {
        height: 40,
        width: 40,
        borderRadius: 100,
        marginRight: theme.spacing(1),
        '& svg > path': {
            stroke: theme.palette.imBrand.midBlue,
            strokeWidth: 3
        },
        '&:hover > svg > path': {
            stroke: theme.palette.imBrand.midBlueHover,
            strokeWidth: 3
        }
    },
    accordionDetail: {
        borderTop: '1px solid #F5F5FA',
        padding: `${theme.spacing(1)} ${theme.spacing(2)} 5px 11px`,
        [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing(2)}`
        }
    }
}));
