import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom-v5-compat';
import { useSelector } from 'react-redux';

import { RecurringIcon } from '@/resources/icons';

import useStyles from '../../styles';

const InvoiceViewParentRecurring = () => {
    const classes = useStyles();

    const recurringParentId = useSelector(
        (state) => state?.makeInvoice?.recurringInvoiceId
    );

    if (!recurringParentId) return null;

    return (
        <div className={classes.section}>
            <Button
                fullWidth
                className={classes.buttonRecurring}
                variant="contained"
                color="secondary"
                size="large"
                startIcon={
                    <RecurringIcon className={classes.buttonRecurringSvg} />
                }
                to={`/recurring-invoice/${recurringParentId}`}
                component={Link}
            >
                View Series
            </Button>
        </div>
    );
};

export default InvoiceViewParentRecurring;
