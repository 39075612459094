import React from 'react';
import { openDrawer } from '@/state/actions';
import {
    MobileItemTags,
    TableActionsWrap,
    TagsIcon
} from '@/components/common';
import { VALUE_PERCENTAGE_TYPE } from '@/constants';
import store from '@/state/store';
import { formatCurrency, toWholeCurrency, formatPercentage } from '@/util';

// Process array of objects into a stripped down version
// that only has required data so is faster to process.
export const dataProcessor = (data) =>
    data.map(({ discountId, name, type, value, tags }) => ({
        id: discountId,
        discountName: name,
        type,
        value,
        tags
    }));

export const generateComponents = ({
    array,
    currency,
    classes,
    locale,
    isMobile
}) => {
    const { items } = store.getState().listing;

    const handleClick = (id) => {
        const discount = items?.find((item) => item.discountId === id);

        if (discount) {
            store.dispatch(
                openDrawer({
                    id: 'discount',
                    discount,
                    edit: true
                })
            );
        }
    };

    return array.map(({ id, discountName, type, value, tags }) => ({
        id,
        discountName,
        type: <span className={classes.valueType}>{type}</span>,
        amount:
            type === VALUE_PERCENTAGE_TYPE
                ? formatPercentage({
                      number: toWholeCurrency(parseFloat(value)),
                      locale
                  })
                : formatCurrency({
                      currency,
                      amount: toWholeCurrency(parseFloat(value)),
                      locale
                  }),
        tags: isMobile ? (
            <MobileItemTags type="discount" itemId={id} tags={tags} />
        ) : (
            <TagsIcon type="discount" itemId={id} tags={tags} />
        ),
        actions: discountName && (
            <TableActionsWrap
                id={id}
                open="editDiscount"
                rest={['deleteBusinessDiscount']}
                toPass={{ discount: discountName }}
            />
        ),

        primaryClick: () => handleClick(id)
    }));
};
