import React from 'react';
import {
    CashAppIcon,
    CashPaymentIcon,
    CheckPaymentIcon,
    // PaypalIcon,
    OtherPaymentIcon,
    StripeIcon,
    SquareIcon,
    VenmoIcon,
    ZelleIcon
} from '@/resources/icons';
import {
    ACHDirectDebit,
    ACHSmall,
    ACHWireTransfer,
    AffirmRound,
    AfterpayRound,
    AmexRound,
    ApplePayRound,
    CashAppRound,
    DiscoverRound,
    GooglePayRound,
    MastercardRound,
    // PayPalRound,
    // PayPalPayLaterRound,
    UnionPayRound,
    VisaRound,
    VenmoRound
} from '@/resources/icons/payment';
import stripePreviewSrc from '@/resources/images/paymentPreviews/stripe-payment-preview.jpg';
import SquarePreviewSvg from '@/resources/images/paymentPreviews/square-payment-preview.svg';
import zellePreviewSrc from '@/resources/images/paymentPreviews/zelle-payment-preview.jpg';
import venmoPreviewSrc from '@/resources/images/venmo/example-venmo-code.jpg';
import cashAppPreviewSrc from '@/resources/images/cashapp/example-cash-app-code.jpg';
import VenmoUploadInstructions from '@/pageComponents/Settings/PaymentMethods/QRCodeVerification/instructions/VenmoUploadInstructions';
import CashAppUploadInstructions from '@/pageComponents/Settings/PaymentMethods/QRCodeVerification/instructions/CashAppUploadInstructions';
import ZelleUploadInstructions from '@/pageComponents/Settings/PaymentMethods/QRCodeVerification/instructions/ZelleUploadInstructions';
import ZelleExampleCode from '@/resources/images/zelle/zelle-example-code.jpg';

const cards = {
    visa: {
        icon: <VisaRound />,
        name: 'Visa',
        id: 1,
        slug: 'visa'
    },
    mastercard: {
        icon: <MastercardRound />,
        name: 'Mastercard',
        id: 2,
        slug: 'mastercard'
    },
    discover: {
        icon: <DiscoverRound />,
        name: 'Discover',
        id: 3,
        slug: 'discover'
    },
    unionPay: {
        icon: <UnionPayRound />,
        name: 'Union Pay',
        id: 4,
        slug: 'union-pay'
    },
    amex: {
        icon: <AmexRound />,
        name: 'American Express',
        id: 6,
        slug: 'american-express'
    }
};

const wallets = {
    affirm: {
        icon: <AffirmRound />,
        name: 'Affirm',
        id: 1,
        payLater: true,
        tagLine: 'Buy now, pay monthly',
        slug: 'affirm'
    },
    afterpay: {
        icon: <AfterpayRound />,
        name: 'Afterpay',
        id: 2,
        payLater: true,
        tagLine: '4 payments, every two weeks',
        slug: 'afterpay'
    },
    applepay: {
        icon: <ApplePayRound />,
        name: 'Apple Pay',
        id: 3,
        showInCreditCardOption: true,
        slug: 'apple-pay'
    },
    cashapp: {
        icon: <CashAppRound />,
        name: 'Cash App',
        id: 4,
        slug: 'cashapp'
    },
    googlepay: {
        icon: <GooglePayRound />,
        name: 'Google Pay',
        id: 5,
        showInCreditCardOption: true,
        slug: 'google-pay'
    },
    // paypal: {
    //     icon: <PayPalRound />,
    //     name: 'PayPal',
    //     id: 7
    // },
    // paypalPayLater: {
    //     icon: <PayPalPayLaterRound />,
    //     name: 'PayPal PayLater',
    //     id: 8,
    //     payLater: true
    // },
    venmo: {
        icon: <VenmoRound />,
        name: 'Venmo',
        id: 9,
        slug: 'venmo'
    }
};

const banks = {
    achdirectdebit: {
        icon: <ACHDirectDebit />,
        name: 'ACH Direct Debit',
        id: 1,
        smallIcon: <ACHSmall />,
        slug: 'ach-direct-debit'
    },
    achwiretransfer: {
        icon: <ACHWireTransfer />,
        name: 'ACH Wire Transfer',
        id: 2,
        smallIcon: <ACHSmall />,
        slug: 'ach-wire-transfer'
    }
};

export const paymentMethods = [
    {
        id: 1,
        slug: 'square',
        name: 'Square',
        icon: <SquareIcon />,
        isMerchant: true,
        canBeConnected: true,
        dashboardURL: 'https://squareup.com/dashboard/',
        acceptedCards: [
            cards.visa,
            cards.mastercard,
            cards.amex,
            cards.discover
        ],
        acceptedWallets: [
            wallets.applepay,
            wallets.cashapp,
            wallets.googlepay,
            wallets.afterpay
        ],
        acceptedBanks: [banks.achwiretransfer]
    },
    {
        id: 2,
        slug: 'stripe',
        name: 'Stripe',
        icon: <StripeIcon />,
        isMerchant: true,
        canBeConnected: true,
        dashboardURL: 'https://dashboard.stripe.com/',
        acceptedCards: [
            cards.visa,
            cards.mastercard,
            cards.amex,
            cards.discover
        ],
        acceptedWallets: [wallets.affirm, wallets.afterpay],
        acceptedBanks: [banks.achdirectdebit]
    },
    // {
    //     id: 3,
    //     slug: 'paypal',
    //     name: 'PayPal',
    //     icon: <PaypalIcon />,
    //     isMerchant: true,
    //     canBeConnected: true,
    //     acceptedCards: [
    //         cards.visa,
    //         cards.mastercard,
    //         cards.amex,
    //         cards.discover
    //     ],
    //     acceptedWallets: [
    //         wallets.paypal,
    //         wallets.paypalPayLater,
    //         wallets.venmo
    //     ],
    //     acceptedBanks: []
    // },
    {
        id: 4,
        name: 'Venmo',
        slug: 'venmo',
        isMerchant: true,
        canBeConnected: true,
        baseLink: [
            'https://venmo.com/code?user_id=',
            'https://www.paypal.com/qrcodes/venmocs/'
        ],
        supportUrl:
            'https://support.invoicemaker.com/hc/en-us/articles/4403205927195-How-to-Accept-Payment-via-Venmo',
        icon: <VenmoIcon />,
        acceptedCards: [],
        acceptedWallets: [],
        acceptedBanks: [],
        isDigitalWallet: true
    },
    {
        id: 5,
        name: 'Cash',
        slug: 'cash',
        icon: <CashPaymentIcon />
    },
    {
        id: 6,
        name: 'Check',
        slug: 'check',
        icon: <CheckPaymentIcon />
    },
    {
        id: 7,
        name: 'Other',
        slug: 'other',
        icon: <OtherPaymentIcon />
    },
    {
        id: 8,
        name: 'Cash App',
        slug: 'cashapp',
        isMerchant: true,
        canBeConnected: true,
        baseLink: ['cash.app/$'],
        supportUrl:
            'https://support.invoicemaker.com/hc/en-us/articles/4403205936923-How-To-Accept-Payment-via-Cash-App',
        icon: <CashAppIcon />,
        acceptedCards: [],
        acceptedWallets: [],
        acceptedBanks: [],
        isDigitalWallet: true
    },
    {
        id: 9,
        name: 'Direct Debit',
        slug: 'directdebit',
        icon: <ACHSmall />,
        isMerchant: true
    },
    {
        id: 10,
        name: 'Zelle',
        slug: 'zelle',
        isMerchant: true,
        isDigitalWallet: true,
        canBeConnected: true,
        baseLink: ['https://enroll.zellepay.com/qr-codes?data='],
        supportUrl:
            'https://support.invoicemaker.com/hc/en-us/articles/11204694449179',
        icon: <ZelleIcon />,
        acceptedCards: [],
        acceptedWallets: [],
        acceptedBanks: []
    }
];

export const paymentMethodPreviewData = {
    stripe: {
        src: stripePreviewSrc,
        alt: 'Stripe payment preview',
        message:
            'When your customers select the credit card option via Stripe, they will be presented with this pop-up to enter their credit card information.'
    },
    square: {
        src: SquarePreviewSvg,
        alt: 'Square payment preview',
        message:
            'When your customers select the credit card option via Square, they will be presented with this pop-up to enter their credit card information.'
    },
    // paypal: {
    //     src: stripePreviewSrc,
    //     alt: 'PayPal payment preview',
    //     message:
    //         'When your customers select the credit card option via PayPal, they will be presented with this pop-up to enter their credit card information.'
    // },
    venmo: {
        src: venmoPreviewSrc,
        alt: 'Venmo payment preview',
        instructions: VenmoUploadInstructions,
        message:
            'When your customers select the venmo option, they will be presented with your venmo QR code.'
    },
    cashapp: {
        src: cashAppPreviewSrc,
        alt: 'Cash App payment preview',
        instructions: CashAppUploadInstructions,
        message:
            'When your customers select the Cash App option, they will be presented with your Cash App QR code.'
    },
    zelle: {
        src: zellePreviewSrc,
        alt: 'Zelle payment preview',
        instructions: ZelleUploadInstructions,
        qrExample: ZelleExampleCode,
        message:
            'When your clients select Zelle, we will display your Zelle recipient credentials for them to use.'
    }
};

export const stripeCapabilities = [
    'card_payments',
    'us_bank_account_ach_payments',
    'affirm_payments',
    'afterpay_clearpay_payments'
];

export const stripeMethodCapabilityHash = {
    visa: 'card_payments',
    mastercard: 'card_payments',
    discover: 'card_payments',
    'american-express': 'card_payments',
    'union-pay': 'card_payments',
    affirm: 'affirm_payments',
    afterpay: 'afterpay_clearpay_payments',
    'ach-direct-debit': 'us_bank_account_ach_payments'
};
