import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    wrap: {
        marginTop: theme.typography.pxToRem(10),
        width: theme.typography.pxToRem(200),
        margin: 'auto',
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.typography.pxToRem(60),
            width: theme.typography.pxToRem(300)
        }
    }
}));
