import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    drawer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        minHeight: `calc(100% - ${theme.typography.pxToRem(125)})`
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
    }
}));
