import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    containerDiv: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    upperThird: {
        marginBottom: theme.typography.pxToRem(10),
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.typography.pxToRem(40)
        }
    },
    smallStateView: {
        display: 'block',
        [theme.breakpoints.up('xl')]: {
            display: 'none'
        }
    },
    makeInvoiceInnerContainer: {
        [theme.breakpoints.down('xl')]: {
            marginBottom: 10
        }
    },
    makeInvoiceContainer: {
        marginBottom: 0,
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.typography.pxToRem(30)
        },
        [theme.breakpoints.up('xl')]: {
            display: 'grid',
            gridTemplateColumns: '1fr 240px',
            gridTemplateRows: 'auto',
            gridColumnGap: 5,
            gridRowGap: 10,
            gridTemplateAreas: "'content sidebar'",
            marginBottom: 0
        }
    },
    makeInvoiceContent: {
        gridArea: 'content',
        padding: theme.spacing(2),
        overflow: 'hidden',
        [theme.breakpoints.down('xl')]: {
            minHeight: 'unset'
        }
    },
    sidebar: {
        backgroundColor: 'white',
        display: 'block',
        position: 'fixed',
        borderTop: '1px solid #DBDFEA',
        left: 0,
        right: 0,
        bottom: 0,
        paddingBottom: 'env(safe-area-inset-bottom)',
        zIndex: 1000,
        [theme.breakpoints.up('xl')]: {
            border: 0,
            backgroundColor: theme.palette.background.default,
            position: 'static',
            gridArea: 'sidebar',
            padding: 0
        }
    },
    buttonText: {
        fontWeight: 600
    },
    newItemButtonGrid: {
        marginBottom: 30
    },
    timelineContainer: {
        padding: theme.spacing(2)
    },
    totalsContainer: {
        paddingTop: '7%',
        display: 'flex',
        flexDirection: 'column-reverse',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        [theme.breakpoints.only('sm')]: {
            minWidth: 325,
            maxWidth: 325,
            paddingRight: 15
        },
        [theme.breakpoints.only('md')]: {
            minWidth: 345,
            maxWidth: 345,
            paddingRight: 35
        },
        [theme.breakpoints.up('lg')]: {
            minWidth: '48%',
            maxWidth: '48%',
            paddingRight: 35
        }
    },
    notesContainer: {
        marginLeft: theme.spacing(3)
    },
    editingNotesLabel: {
        marginBottom: theme.spacing(0.5)
    },
    cropperContainer: {
        paddingLeft: '0 !important',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 'inherit !important'
        }
    },
    cropper: {
        display: 'flex',
        justifyContent: 'flex-start',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-end'
        }
    },
    cropperEditing: {
        marginRight: theme.spacing(7),
        display: 'flex',
        justifyContent: 'flex-start',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-end'
        }
    },
    datePickerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end'
    },
    billingContainer: {
        marginTop: 'auto',
        [theme.breakpoints.up('sm')]: { maxWidth: '360px' }
    },
    billingContainerLocked: { marginTop: 'auto' },
    disableBottomPadding: {
        paddingBottom: '0px !important'
    },
    upperThirdRightColumnContainer: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: `${theme.spacing(2)} !important`,
            justifyContent: 'center',
            paddingLeft: `${theme.spacing(3)} !important`,
            paddingRight: `${theme.spacing(3)} !important`
        }
    },
    cio_message: {
        width: '100%'
    },
    bottomBlocks: {
        gap: '10px'
    }
}));
