const isObject = (item) =>
    item && typeof item === 'object' && !Array.isArray(item);

/**
 * Deeply combines two objects
 *
 * @param  {object} originalObject The object to be combined with lesser importance.
 * @param  {object} overwriteObject The object to be combined with more importance. Matched values in this object will overwrite those in the originalObject.
 * @return {object} Deeply combined object.
 */

export const deepCombineObjects = (originalObject, overwriteObject) => {
    if (!isObject(originalObject) || !isObject(overwriteObject)) {
        return originalObject;
    }

    const combinedObject = { ...originalObject };
    Object.keys(overwriteObject).forEach((key) => {
        if (
            combinedObject[key] &&
            isObject(combinedObject[key]) &&
            isObject(overwriteObject[key])
        ) {
            combinedObject[key] = deepCombineObjects(
                combinedObject[key],
                overwriteObject[key]
            );
        } else {
            combinedObject[key] = overwriteObject[key];
        }
    });

    return combinedObject;
};
