import { NETWORK_STATUS } from '@/constants';

export default (state = { connected: true, timeToRetry: 0 }, action) => {
    switch (action.type) {
        case NETWORK_STATUS: {
            return {
                connected: action.connected,
                timeToRetry: action.timeToRetry
            };
        }
        default: {
            return state;
        }
    }
};
