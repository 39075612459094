import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    notice: {
        backgroundColor: '#E7F3FF',
        color: '#1E377A',
        typography: theme.typography.fontFamily
    },
    noticeWrapper: {
        width: '100%'
    },
    svg: {
        width: theme.typography.pxToRem(30),
        height: theme.typography.pxToRem(35),
        paddingRight: theme.typography.pxToRem(13),
        marginLeft: theme.typography.pxToRem(11),
        minWidth: theme.typography.pxToRem(30),
        verticalAlign: 'middle'
    }
}));
