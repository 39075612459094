import React from 'react';
import {
    AccountActive,
    PaymentMethodsActive,
    ClientsActive,
    DiscountActive,
    EstimatesActive,
    // FeatureRequestActive,
    // FeatureRequestDropdown,
    InvoicesActive,
    DocumentDefaultsActive,
    SubscriptionActive,
    // ReportsActive,
    LogoutRed,
    ProductsActive,
    ProfileActivity,
    ReceiptsActive,
    RecurringIcon,
    SettingsActive,
    ServicesActive,
    TagsActive,
    TaxesActive
} from '@/resources/icons';
import { openDrawer } from '@/state/actions';
import { logoutCurrentUser } from '@/modules/loginManager';

export const accountMenuItems = [
    // {
    //     title: 'Feature Request',
    //     path: '/settings/feature-request',
    //     icon: <FeatureRequestDropdown />
    // },
    {
        title: 'Activity',
        icon: <ProfileActivity />,
        path: '/activity'
    },
    {
        title: 'Logout',
        path: '/',
        icon: <LogoutRed />,
        onClick: logoutCurrentUser
    }
];

export const clientMenuItems = [
    {
        title: 'Client Information',
        path: '/clients/:clientId',
        color: '#357ABC'
    },
    // {
    //     title: 'Notes',
    //     path: '/clients/:clientId/notes',
    // },
    {
        title: 'Tags',
        path: '/clients/:clientId/tags',
        color: '#357ABC'
    },
    {
        title: 'Invoices',
        path: '/clients/:clientId/invoices',
        color: '#357ABC'
    },
    {
        title: 'Estimates',
        path: '/clients/:clientId/estimates',
        color: '#357ABC'
    },
    {
        title: 'Receipts',
        path: '/clients/:clientId/receipts',
        color: '#357ABC'
    },
    {
        title: 'Activity',
        path: '/clients/:clientId/activity',
        color: '#357ABC'
    },
    {
        title: 'Recurring Invoices',
        path: '/clients/:clientId/recurring-invoices',
        color: '#357ABC'
    }
];

export const createButtonMenuItems = [
    {
        slug: 'invoice',
        label: 'Invoice',
        color: '#0EC78A',
        icon: <InvoicesActive />
    },
    {
        slug: 'recurring-invoice',
        label: 'Recurring Invoice',
        color: '#0EC78A',
        icon: <RecurringIcon />
    },
    {
        slug: 'estimate',
        label: 'Estimate',
        color: '#7D01BA',
        icon: <EstimatesActive />
    },
    {
        slug: 'receipt',
        label: 'Receipt',
        color: '#00834E',
        icon: <ReceiptsActive />
    },
    {
        slug: 'client',
        label: 'Client',
        color: '#1a4d98',
        icon: <ClientsActive />,
        action: () => openDrawer({ id: 'client', title: 'New Client' })
    },
    {
        slug: 'product',
        label: 'Product',
        color: '#7267b1',
        icon: <ProductsActive />,
        action: () => openDrawer('product')
    },
    {
        slug: 'service',
        label: 'Service',
        color: '#357abc',
        icon: <ServicesActive />,
        action: () => openDrawer('service')
    },
    {
        slug: 'tax',
        label: 'Tax',
        color: '#aa6600',
        icon: <TaxesActive />,
        action: () => openDrawer('tax')
    },
    {
        slug: 'discount',
        label: 'Discount',
        color: '#127f83',
        icon: <DiscountActive />,
        action: () => openDrawer('discount')
    },
    {
        slug: 'tag',
        label: 'Tag',
        color: '#5096DA',
        icon: <TagsActive />,
        action: () => openDrawer('createTags')
    }
];

export const settingsMenuItems = [
    {
        color: '#B16625',
        end: true,
        title: 'Account',
        icon: <AccountActive />,
        path: '/settings'
    },
    {
        color: '#B16625',
        end: true,
        title: 'Payment Methods',
        icon: <PaymentMethodsActive />,
        path: '/settings/payment-methods'
    },
    {
        color: '#B16625',
        end: true,
        title: 'Document Defaults',
        icon: <DocumentDefaultsActive />,
        path: '/settings/document-defaults'
    },
    {
        color: '#B16625',
        end: true,
        title: 'Subscription',
        icon: <SubscriptionActive />,
        path: '/settings/subscription'
    }
];

export const itemsMenuItems = [
    {
        end: true,
        title: 'Products',
        path: '/items/products',
        color: '#7267B1',
        slug: 'products',
        icon: <ProductsActive />
    },
    {
        end: true,
        title: 'Services',
        path: '/items/services',
        color: '#1E377A',
        slug: 'services',
        icon: <ServicesActive />
    },
    {
        end: true,
        title: 'Taxes',
        path: '/items/taxes',
        color: '#AA6600',
        slug: 'taxes',
        icon: <TaxesActive />
    },
    {
        end: true,
        title: 'Discounts',
        path: '/items/discounts',
        color: '#127F83',
        slug: 'discounts',
        icon: <DiscountActive />
    },
    {
        end: true,
        title: 'Tags',
        path: '/items/tags',
        color: '#5096DA',
        slug: 'tags',
        icon: <TagsActive />
    }
];

export const mainMenuItems = [
    {
        title: 'Invoices',
        path: '/invoices',
        color: '#0EC78A',
        icon: <InvoicesActive />
    },
    {
        title: 'Recurring Invoices',
        path: '/recurring-invoices',
        color: '#0EC78A',
        icon: <RecurringIcon />
    },
    {
        title: 'Estimates',
        path: '/estimates',
        color: '#7D01BA',
        icon: <EstimatesActive />
    },
    {
        title: 'Receipts',
        path: '/receipts',
        color: '#00834E',
        icon: <ReceiptsActive />
    },
    {
        title: 'Clients',
        path: '/clients',
        color: '#1A4D98',
        icon: <ClientsActive />
    },
    // {
    //     title: 'Reports',
    //     path: '/reports',
    //     color: '#AE2828',
    //     icon: <ReportsActive />
    // },
    {
        title: 'Items',
        path: '/items',
        color: '#7267B1',
        icon: <ProductsActive />,
        children: itemsMenuItems
    },
    {
        title: 'Settings',
        path: '/settings',
        color: '#EB9251',
        icon: <SettingsActive />,
        children: settingsMenuItems
    }
];
