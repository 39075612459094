import makeStyles from '@mui/styles/makeStyles';

const titlebar = 64;
const titlebarGap = 127;

export default makeStyles((theme) => ({
    wrapper: {
        minHeight: `calc(100vh - ${titlebar}px - ${titlebarGap}px)`,
        margin: theme.spacing(0, 0, 2, 0),
        position: 'relative'
    }
}));
