import store from '@/state/store';
import { fetchUser, updateUser } from '@/state/actions';

// eslint-disable-next-line import/no-cycle
import axiosAuthenticated from '../authenticatedRequestor';
import axiosUnauthenticated from '../unauthenticatedRequestor';

export const getUser = async () => {
    const currentUser = store.getState().user;

    if (currentUser.userLoggedIn) {
        store.dispatch(fetchUser());

        const response = await axiosAuthenticated.get('/user');
        store.dispatch(updateUser(response?.data));

        return response?.data;
    }
    return false;
};

export const updateUserSettings = async (newSettings) => {
    const baseSettings = {
        showToolTips: true,
        showEditLockedOnActionPrompt: true,
        enableEmailNotifications: true
    };

    const settings = { ...baseSettings, ...newSettings };

    try {
        const response = await axiosAuthenticated.put('/user', {
            settings
        });
        store.dispatch(updateUser(response?.data));
        return response?.data;
    } catch (err) {
        throw err.response.data.message;
    }
};

export const updateUserEmailAddress = async (emailAddress, password) => {
    try {
        return await axiosAuthenticated.put('/user/email', {
            emailAddress,
            password
        });
    } catch (err) {
        throw err.response.data.message;
    }
};

export const createUnregisteredUser = async (business) => {
    try {
        const response = await axiosUnauthenticated.post(
            `/user/unregistered`,
            business
        );
        return response.data;
    } catch (err) {
        return false;
    }
};

export const deleteAccount = async (password) => {
    try {
        return await axiosAuthenticated.post('/user/delete', {
            password
        });
    } catch (err) {
        throw (
            err?.response?.data?.message ||
            'Something went wrong. Please try again later.'
        );
    }
};
