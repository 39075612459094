import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import {
    ListItem,
    Grid,
    Typography,
    useTheme,
    Collapse,
    IconButton,
    Button,
    Divider
} from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    calculateProductSubtotal,
    toWholeCurrency,
    formatCurrency
} from '@/util';

import {
    CloseIcon,
    Chevron,
    FrictionHandleIcon,
    DeleteIcon
} from '@/resources/icons';

import {
    updateDocumentProduct,
    removeProductFromDocument,
    setDocumentErrors
} from '@/state/actions';

import { useCurrency, useLocale } from '@/hooks';
import {
    Description,
    ItemName,
    Price,
    Quantity,
    Totals,
    TaxAndDiscountButtons,
    TaxLine,
    DiscountLine
} from '../Components';
import styles from './styles';

const useStyles = makeStyles(styles);

const LineItem = ({
    item,
    listItemProps,
    dragHandleProps,
    allowDeletion,
    isDragging
}) => {
    const theme = useTheme();
    const { code } = useCurrency({ isInvoice: true });
    const locale = useLocale();

    const documentColor =
        useSelector(
            (state) =>
                state?.user?.activeBusiness?.documentCustomizations?.design
        ) || theme.palette.text.secondary;

    const makeInvoiceErrors = useSelector((state) => state.makeInvoice?.errors);

    const productErrors = makeInvoiceErrors?.products;

    const [expanded, setExpanded] = useState(true);

    const classes = useStyles({
        draggableStyle: listItemProps.style,
        isDragging,
        documentColor,
        expanded
    });

    const dispatch = useDispatch();

    const dispatchRemoveProductFromInvoice = () =>
        dispatch(removeProductFromDocument(item.lineId));

    const applyFieldToItem = (property, value) => {
        const updatedItem = { ...item, [property]: value };
        dispatch(updateDocumentProduct(updatedItem));
    };

    const total = calculateProductSubtotal(item);

    const clearErrors = (lineId, itemId, itemLabel) => {
        if (!productErrors?.[lineId]?.[itemLabel]?.[itemId]) {
            return;
        }

        const newErrors = {
            ...makeInvoiceErrors,
            products: {
                ...productErrors,
                [lineId]: {
                    ...productErrors[lineId],
                    [itemLabel]: {
                        ...productErrors[lineId][itemLabel],
                        [itemId]: undefined
                    }
                }
            }
        };

        dispatch(setDocumentErrors(newErrors));
    };

    return (
        <ListItem {...listItemProps} className={classes.item} disableGutters>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <div className={classes.itemHandle} {...dragHandleProps}>
                <FrictionHandleIcon
                    className={classes.lineItemFrictionHandle}
                />
            </div>
            <div className={classes.lineItemRootGrid}>
                <div
                    className={classes.mobileLineItemHeader}
                    {...dragHandleProps}
                >
                    <div className={classes.mobileLineItemAligner}>
                        <FrictionHandleIcon
                            className={classes.mobileLineItemFrictionHandle}
                        />
                        <Typography
                            variant="body2"
                            className={classes.mobileLineItemHeaderTitle}
                        >
                            {item?.name || 'Line Item'}
                        </Typography>
                        <Typography
                            variant="h5"
                            className={classes.mobileHeaderTotal}
                        >
                            {`${formatCurrency({
                                locale,
                                currency: code,
                                amount: toWholeCurrency(total)
                            })}`}
                        </Typography>
                    </div>
                    <IconButton
                        id={`line-item-expand-chevron-${item.lineId}`}
                        data-testid={`line-item-expand-chevron-${item.lineId}`}
                        className={classes.expandChevronButton}
                        onClick={() => setExpanded(!expanded)}
                        size="large"
                    >
                        <Chevron
                            className={
                                expanded
                                    ? classes.expandChevron
                                    : classes.unexpandChevron
                            }
                        />
                    </IconButton>
                </div>

                <Collapse
                    in={expanded}
                    classes={{ wrapperInner: classes.wrapperInner }}
                    id={`line-item-collapse-${item.lineId}`}
                    data-testid={`line-item-collapse-${item.lineId}`}
                >
                    <Grid item container spacing={1}>
                        <Grid item lg={6} xs={12}>
                            <ItemName
                                item={item}
                                locale={locale}
                                code={code}
                                applyFieldToItem={applyFieldToItem}
                            />
                        </Grid>
                        <Grid item lg={3} xs={6}>
                            <Price
                                item={item}
                                applyFieldToItem={applyFieldToItem}
                            />
                        </Grid>
                        <Grid item lg={3} xs={6}>
                            <Quantity
                                item={item}
                                applyFieldToItem={applyFieldToItem}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Description
                                applyFieldToItem={applyFieldToItem}
                                item={item}
                            />
                        </Grid>

                        <Grid item xs={12} className={classes.taxDiscountWrap}>
                            {item?.taxes?.map((tax) => (
                                <div key={tax.taxId}>
                                    <Divider
                                        className={classes.taxDiscountDivider}
                                    />
                                    <TaxLine
                                        taxItem={tax}
                                        selectedTaxes={item?.taxes}
                                        applyFieldToItem={applyFieldToItem}
                                        error={
                                            productErrors?.[item?.lineId]
                                                ?.taxes?.[tax.taxId]?.name || ''
                                        }
                                        clearErrors={() =>
                                            clearErrors(
                                                item?.lineId,
                                                tax.taxId,
                                                'taxes'
                                            )
                                        }
                                    />
                                </div>
                            ))}
                            {item?.discounts?.map((discount) => (
                                <div key={discount.discountId}>
                                    <Divider
                                        className={classes.taxDiscountDivider}
                                    />
                                    <DiscountLine
                                        applyFieldToItem={applyFieldToItem}
                                        selectedDiscounts={item?.discounts}
                                        discountItem={discount}
                                        error={
                                            productErrors?.[item?.lineId]
                                                ?.discounts?.[
                                                discount.discountId
                                            ]?.name || ''
                                        }
                                        clearErrors={() =>
                                            clearErrors(
                                                item?.lineId,
                                                discount.discountId,
                                                'discounts'
                                            )
                                        }
                                    />
                                </div>
                            ))}
                        </Grid>
                    </Grid>

                    <div className={classes.rightWrap}>
                        <Totals item={item} />
                        <TaxAndDiscountButtons
                            applyFieldToItem={applyFieldToItem}
                            selectedDiscounts={item?.discounts}
                            selectedTaxes={item?.taxes}
                        />
                    </div>

                    {allowDeletion && (
                        <Button
                            startIcon={<DeleteIcon />}
                            type="button"
                            onClick={dispatchRemoveProductFromInvoice}
                            variant="outlined"
                            fullWidth
                            className={classes.removeItemButton}
                        >
                            <Typography
                                color="textSecondary"
                                className={classes.removeItemButtonText}
                            >
                                Remove Item
                            </Typography>
                        </Button>
                    )}
                </Collapse>
            </div>

            {allowDeletion && (
                <button
                    type="button"
                    className={classes.deleteDesktop}
                    id={`line-item-delete-button-${item.lineId}`}
                    onClick={dispatchRemoveProductFromInvoice}
                    aria-label="Delete Line Item"
                >
                    <CloseIcon />
                </button>
            )}
        </ListItem>
    );
};

LineItem.propTypes = {
    item: PropTypes.object.isRequired,
    listItemProps: PropTypes.object.isRequired,
    dragHandleProps: PropTypes.object.isRequired,
    allowDeletion: PropTypes.bool,
    isDragging: PropTypes.bool
};

LineItem.defaultProps = {
    allowDeletion: true,
    isDragging: false
};

export default LineItem;
