import React from 'react';
import { Grid, Typography } from '@mui/material';
import {
    VenmoExportButton,
    VenmoScanButton,
    VenmoTopBar
} from '@/resources/images/venmo';
import useStyles from '../styles';

const VenmoUploadInstructions = () => {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="column"
            className={classes.merchantInstructionsListContainer}
        >
            <Grid item>
                <Typography variant="body2" color="textSecondary">
                    To export code image from Venmo
                </Typography>
            </Grid>
            <ol className={classes.instructionList}>
                <Typography
                    component="li"
                    variant="body2"
                    color="textSecondary"
                    className={classes.uploadInstructions}
                >
                    Tap the &quot;scan&quot; button at the bottom of the screen.
                    <VenmoScanButton className={classes.venmoScanButton} />
                </Typography>
                <Typography
                    component="li"
                    variant="body2"
                    color="textSecondary"
                    className={classes.uploadInstructions}
                >
                    Tap &quot;venmo me&quot; in the top tab bar.
                    <VenmoTopBar className={classes.venmoTopBar} />
                </Typography>
                <Typography
                    component="li"
                    variant="body2"
                    color="textSecondary"
                    className={classes.uploadInstructions}
                >
                    You should see your code image - tap the export icon and
                    choose where you want to save the image.
                    <VenmoExportButton className={classes.venmoExportButton} />
                </Typography>
                <Typography
                    component="li"
                    variant="body2"
                    color="textSecondary"
                    className={classes.uploadInstructions}
                >
                    Upload your saved code image on this page.
                </Typography>
            </ol>
        </Grid>
    );
};

export default VenmoUploadInstructions;
