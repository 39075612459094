import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    taxDiscountContainer: {
        marginBottom: -6,
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.typography.pxToRem(15)
        }
    },
    addIcon: {
        filter: 'invert(42%) sepia(12%) saturate(554%) hue-rotate(188deg) brightness(95%) contrast(88%);',
        width: '14px'
    },
    button: {
        padding: '5px 10px 5px 10px',
        borderColor: '#DBDFEA'
    },
    add: {
        marginLeft: theme.typography.pxToRem(6),
        color: theme.palette.common.black
    },
    accessibleWord: theme.visuallyHidden
}));
