import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select, InputLabel, FormControl, MenuItem } from '@mui/material';
import { useLocalization, useCurrency } from '@/hooks';

/**
 * Currency Selector dropdown.
 * @param {string} currency Currently selected currency.
 * @param {bool} disabled if the prop should be disabled
 * @param {func} setCurrency Set the currency using this function.
 * @param {string} customClasses Custom className styles from the parent component.
 */
const CurrencySelector = ({
    currency,
    setCurrency,
    customClasses,
    disabled
}) => {
    const { loading, currencies } = useLocalization();

    // Default or currently selected locale.
    // This is only shown when waiting for currency data to load.
    const defaultCurrency = useCurrency();

    // This state management allows us to handle the selected state inside the
    // component, as we don't know what happens outside.
    const [current, setCurrent] = useState(currency);
    const handleSelection = (selected) => {
        setCurrent(selected);
        setCurrency(selected);
    };
    return (
        <FormControl fullWidth variant="filled">
            <InputLabel id="currencyLabel">Currency</InputLabel>
            <Select
                labelId="currencyLabel"
                disabled={disabled}
                label="Currency"
                value={!loading && current ? current : defaultCurrency.code}
                className={customClasses}
                onChange={(e) => {
                    handleSelection(e.target.value);
                }}
            >
                {loading && (
                    <MenuItem
                        key={defaultCurrency.code}
                        value={defaultCurrency.code}
                        name={defaultCurrency.name}
                    >
                        {defaultCurrency.name}
                    </MenuItem>
                )}
                {!loading &&
                    currencies.map((item) => (
                        <MenuItem
                            key={item.name}
                            value={item.code}
                            name={item.name}
                        >
                            {item.name}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
};

CurrencySelector.propTypes = {
    currency: PropTypes.string,
    setCurrency: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    customClasses: PropTypes.string
};

CurrencySelector.defaultProps = {
    disabled: false,
    currency: undefined,
    customClasses: undefined
};

export default CurrencySelector;
