import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

/**
 * Title and icon to introduce pages/sections.
 * @param {element} icon React component for SVG image.
 * @param {string} title Name of the section.
 */
const PageTitle = ({ icon, title }) => {
    const classes = useStyles();
    return (
        <div className={classes.wrap}>
            {icon && <div className={classes.icon}>{icon}</div>}
            <h2 className={classes.title}>{title}</h2>
        </div>
    );
};

PageTitle.propTypes = {
    icon: PropTypes.element,
    title: PropTypes.string
};

PageTitle.defaultProps = {
    icon: null,
    title: null
};

export default PageTitle;
